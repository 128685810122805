import React, { FunctionComponent, useEffect, useState } from 'react';

import DashboardFacilitiesSubMenu from 'components/Submenu/DashboardFacilitiesSubMenu';
import FacilitieCard from 'components/FacilitieCard';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import { DashboardContainer } from 'styled/components/view';
import { FacilitiesContainer } from 'styled/components/facilitieCard';

import { DateSpan } from 'utils/timePeriod';

import { FaciliteItem } from 'components/FacilitieCard/types';

import { useMetricsData } from 'hooks/api/useMetricsData';

const DashboardViewFacilities: FunctionComponent = () => {
    const [period, setPeriod] = useState<string>(DateSpan.CURRENT_MONTH);
    const [facilities, setFacilities] = useState<FaciliteItem[]>([]);

    const { data, isLoading } = useMetricsData(period);

    useEffect(() => {
        if (data) {
            setFacilities(data);
        }
    }, [data]);

    return (
        <>
            <DashboardFacilitiesSubMenu action={period} onSelectAction={setPeriod} />

            <DashboardContainer>
                {isLoading && <LoadingOverlay dark={true} />}
                {!isLoading && facilities.length && (
                    <FacilitiesContainer>
                        {facilities.map(f => (
                            <FacilitieCard key={f.id} facilitie={f} />
                        ))}
                    </FacilitiesContainer>
                )}
            </DashboardContainer>
        </>
    );
};

export default DashboardViewFacilities;
