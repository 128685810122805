import React, { useEffect, useReducer } from 'react';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import SensorDataPane from 'components/DataPane/sensors/SensorsDataPane';
import SensorsSubMenu from 'components/Submenu/SensorsSubMenu';
import SensorSelector from 'components/SensorSelector';
import { SpaceSuggestion } from 'components/SearchAutoComplete';

import { useTenant } from 'context/TenantContext';
import { useAllSensorsData } from 'hooks/api/useAllSensorsData';

import { SensorSelection, Space } from '../types';
import { SensorOverviewAction, SensorOverviewState } from './types';
import { KPIType, Sensor, SensorType } from 'types';
import { getLevelOfDepth } from '../energy/EnergyStatisticsView';

const SensorsView: React.FunctionComponent = () => {
    const { getBuildingData } = useTenant();
    const { data, isLoading } = useAllSensorsData();
    const [state, dispatch] = useReducer(sensorOverviewReducer, initialState);
    const { selectedSensors, equipOverview, suggestions, roomsDepth } = state;

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                sensorData: data,
            });
        }
    }, [data]);

    return (
        <>
            <SensorsSubMenu
                suggestions={suggestions}
                onSearch={selectedSpace =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedSpace: selectedSpace,
                    })
                }
            />

            {isLoading && <LoadingOverlay />}

            {Boolean(equipOverview.length) && (
                <SensorSelector
                    levels={roomsDepth}
                    equips={equipOverview}
                    onEquipSelect={(selectAction, roomId) => dispatch({ type: selectAction, spaceId: roomId })}
                    collapsed={Boolean(selectedSensors.length)}
                />
            )}
            {Boolean(selectedSensors.length) && <SensorDataPane sensors={selectedSensors} />}
        </>
    );
};

export default SensorsView;

export const initialState: SensorOverviewState = {
    buildingStructure: [],
    suggestions: [],
    sensors: [],
    equipOverview: [],
    selectedSensors: [],
    roomsDepth: 5,
};

const emptyList = { spaces: [] as Space[], selectedSpace: '' };

const sensorOverviewReducer = (state: SensorOverviewState, action: SensorOverviewAction): SensorOverviewState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const filteredSensors = action.sensorData.filter(
                s => s.type !== SensorType.ELECTRICITY_DAILY && s.type !== SensorType.ELECTRICITY_MONTHLY
            );

            const sensorSelection = localStorage.getItem('sensor-selection');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;
            const selectedSpace = getSelectedSpace(parsedSelection);

            const filteredBuildingData = action.buildingData.filter(building =>
                Boolean(filteredSensors.filter(f => f.buildingRef === building.id).length)
            );

            const selectedBuilding = filteredBuildingData.find(i => i.id === parsedSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === parsedSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId4
            );

            filteredBuildingData.forEach(building => {
                building.hasError = Boolean(
                    filteredSensors
                        .filter(s => s.buildingRef === building.id)
                        .filter(s => s.latestKpi.kpi === KPIType.INSUFFICIENT).length
                );

                recursiveErrorCheck(building.rooms, filteredSensors);
            });

            filteredBuildingData.forEach(building => {
                recursiveModuleSensorCheck(building.rooms, action.sensorData);
            });

            filteredBuildingData.forEach(building => {
                recursiveFilterRooms(building);
            });

            const suggestions: SpaceSuggestion[] = [];
            filteredBuildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);

                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            const roomsDepth = getLevelOfDepth(filteredBuildingData);

            return {
                ...state,
                roomsDepth,
                suggestions: suggestions,
                sensors: filteredSensors,
                buildingStructure: filteredBuildingData,
                equipOverview: [
                    {
                        spaces: filteredBuildingData || [],
                        selectedSpace: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId2 ? parsedSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId3 ? parsedSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId4 ? parsedSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId5 ? parsedSelection.selectedId5 : '',
                    },
                    {
                        spaces: selectedFifthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId6 ? parsedSelection.selectedId6 : '',
                    },
                ],
                selectedSensors: filteredSensors.filter(s => s.roomRef === selectedSpace && s.latestKpi.value !== null),
            };
        }
        case 'SELECT_SPACE1': {
            localStorage.setItem(
                'sensor-selection',
                JSON.stringify({
                    selectedId1: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedSensors: [],
            };
        }
        case 'SELECT_SPACE2': {
            localStorage.setItem(
                'sensor-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    {
                        spaces: state.equipOverview[1].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
            };
        }
        case 'SELECT_SPACE3': {
            localStorage.setItem(
                'sensor-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    {
                        spaces: state.equipOverview[2].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
            };
        }
        case 'SELECT_SPACE4': {
            localStorage.setItem(
                'sensor-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    {
                        spaces: state.equipOverview[3].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
            };
        }
        case 'SELECT_SPACE5': {
            localStorage.setItem(
                'sensor-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    {
                        spaces: state.equipOverview[4].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[4].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
            };
        }
        case 'SELECT_SPACE6': {
            localStorage.setItem(
                'sensor-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: state.equipOverview[4].selectedSpace,
                    selectedId6: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    state.equipOverview[4],
                    {
                        spaces: state.equipOverview[5].spaces,
                        selectedSpace: action.spaceId,
                    },
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);
            const selectedSpace = getSelectedSpace(sensorSelection);
            localStorage.setItem('sensor-selection', JSON.stringify(sensorSelection));

            const selectedBuilding = state.buildingStructure.find(i => i.id === sensorSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === sensorSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId4
            );
            return {
                ...state,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId2 ? sensorSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId3 ? sensorSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId4 ? sensorSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId5 ? sensorSelection.selectedId5 : '',
                    },
                    {
                        spaces: selectedFifthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId6 ? sensorSelection.selectedId6 : '',
                    },
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === selectedSpace && s.latestKpi.value !== null),
            };
        }
    }
};

export const createSensorSelection = (ids: string[]) => {
    const sensorSelection: SensorSelection = {
        selectedId1: '',
        selectedId2: '',
        selectedId3: '',
        selectedId4: '',
        selectedId5: '',
        selectedId6: '',
    };
    if (!ids) {
        return sensorSelection;
    }
    if (ids[0]) {
        sensorSelection['selectedId1'] = ids[0];
    }
    if (ids[1]) {
        sensorSelection['selectedId2'] = ids[1];
    }
    if (ids[2]) {
        sensorSelection['selectedId3'] = ids[2];
    }
    if (ids[3]) {
        sensorSelection['selectedId4'] = ids[3];
    }
    if (ids[4]) {
        sensorSelection['selectedId5'] = ids[4];
    }
    if (ids[5]) {
        sensorSelection['selectedId6'] = ids[5];
    }
    return sensorSelection;
};

export const getSelectedSpace = (spaces: SensorSelection | null) => {
    if (!spaces) {
        return '';
    }
    if (spaces.selectedId6) {
        return spaces.selectedId6;
    }
    if (spaces.selectedId5) {
        return spaces.selectedId5;
    }
    if (spaces.selectedId4) {
        return spaces.selectedId4;
    }
    if (spaces.selectedId3) {
        return spaces.selectedId3;
    }
    if (spaces.selectedId2) {
        return spaces.selectedId2;
    }
    return '';
};

const recursiveErrorCheck = (spaces: Space[], sensors: Sensor[]) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        r.sensors = sensors.filter(s => s.roomRef === r.id);

        if (r.sensors?.findIndex(s => s.latestKpi.kpi === KPIType.INSUFFICIENT) !== -1) {
            r.hasError = true;
        }
        if (hasChildErrorCheck(r.rooms, sensors, false)) {
            r.hasError = true;
        }

        recursiveErrorCheck(r.rooms, sensors);
    });
};

const recursiveCreateSuggestions = (
    spaces: Space[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        const i = [...ids];
        i.push(r.id);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.rooms, suggestions, i, l);
    });
};

const hasChildErrorCheck = (spaces: Space[], sensors: Sensor[], hasError: boolean) => {
    let error = hasError;
    if (spaces.length === 0 || error) {
        return error;
    }
    spaces.forEach(r => {
        r.sensors = sensors.filter(s => s.roomRef === r.id);
        if (r.sensors?.findIndex(s => s.latestKpi.kpi === KPIType.INSUFFICIENT) !== -1) {
            error = true;
        }
        error = hasChildErrorCheck(r.rooms, sensors, error);
    });

    return error;
};

const recursiveModuleSensorCheck = (spaces: Space[], sensors: Sensor[]) => {
    if (spaces.length === 0) {
        return;
    }

    spaces.forEach(r => {
        if (sensors.findIndex(f => f.roomRef === r.id) !== -1) {
            r.hasModuleSensor = true;
        }
        if (hasChildModuleSensorCheck(r.rooms, sensors, false)) {
            r.hasModuleSensor = true;
        }

        recursiveModuleSensorCheck(r.rooms, sensors);
    });
};

const hasChildModuleSensorCheck = (spaces: Space[], sensors: Sensor[], hasModuleSensor: boolean) => {
    let error = hasModuleSensor;
    if (spaces.length === 0 || error) {
        return error;
    }
    spaces.forEach(r => {
        if (sensors.findIndex(f => f.roomRef === r.id) !== -1) {
            error = true;
        }
        error = hasChildModuleSensorCheck(r.rooms, sensors, error);
    });

    return error;
};

const recursiveFilterRooms = (space: Space) => {
    if (space.rooms.length === 0) {
        return;
    }
    space.rooms = space.rooms.filter(space => space.hasModuleSensor);

    space.rooms.forEach(s => recursiveFilterRooms(s));
};
