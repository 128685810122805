import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { ModuleType, SensorAverage, SensorAvgFilter } from 'types';

export const useAverageData = (
    id: string,
    moduleType: ModuleType,
    averageFilter: SensorAvgFilter,
    isBarChart: boolean
) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['averageSensor', id, moduleType, averageFilter, isBarChart],
        async ({ signal }) => {
            const { data } = await axiosClient.get<SensorAverage[]>(
                createDataAverageUrl(moduleType, id, averageFilter, isBarChart),
                { signal }
            );

            return data;
        },
        {
            enabled: Boolean(id),
        }
    );
};

export const createDataAverageUrl = (type: ModuleType, id: string, filter: SensorAvgFilter, isBarChart: boolean) => {
    if (isBarChart && (type === ModuleType.OCCUPANCY || type === ModuleType.WATER || type === ModuleType.ENERGY)) {
        return config.sensorDataAverageTotal([id], filter);
    }

    return config.sensorDataAverage(id, filter);
};
