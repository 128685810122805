import React from 'react';

import { useTranslation } from 'react-i18next';
import { theme } from 'styled/Theme';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendButtonIcon, ToggleLegend } from 'styled/components/dataPane';

import legendIcon from '../../../../../assets/icons/i-circle-default.svg';

import { KpiUnit } from 'types';
import { numberFormatter } from '../energyStatistics.helper';

interface InfoHeaderProp {
    totalConsumption: number;
    totalEmission: number;
    showTotal: boolean;
    showMeter1: boolean;
    meter1Name?: string;
    showMeter2: boolean;
    meter2Name?: string;
    showMeter3: boolean;
    meter3Name?: string;
    showMeter4: boolean;
    meter4Name?: string;
    showMeter5: boolean;
    meter5Name?: string;
    onShowTotal: (showTotal: boolean) => void;
    onShowMeter1?: (showMeter1: boolean) => void;
    onShowMeter2?: (showMeter2: boolean) => void;
    onShowMeter3?: (showMeter3: boolean) => void;
    onShowMeter4?: (showMeter4: boolean) => void;
    onShowMeter5?: (showMeter5: boolean) => void;
}
const ElectricityConsumptionHeader: React.FunctionComponent<InfoHeaderProp> = ({
    totalConsumption,
    totalEmission,
    showTotal,
    showMeter1,
    meter1Name = 'meter1',
    showMeter2,
    meter2Name = 'meter2',
    showMeter3,
    meter3Name = 'meter3',
    showMeter4,
    meter4Name = 'meter4',
    showMeter5,
    meter5Name = 'meter5',
    onShowTotal,
    onShowMeter1,
    onShowMeter2,
    onShowMeter3,
    onShowMeter4,
    onShowMeter5,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>
                    {`${numberFormatter(totalConsumption, 2)} ${totalConsumption > 999 ? KpiUnit.MWH : KpiUnit.KWH}`}
                </H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.totalElectricity')}
                    <br />
                    {t('infoHeader.common.consumption')}
                </CaptionLefWhite>
            </div>
            <div>
                <H2White>
                    {`${numberFormatter(totalEmission, 2)} ${totalEmission > 999 ? KpiUnit.T : KpiUnit.KG} CO2`}
                </H2White>
                <CaptionLefWhite>{t('infoHeader.energy.totalEmission')}</CaptionLefWhite>
            </div>
            <div>
                <ToggleLegend active={showTotal} onClick={() => onShowTotal(!showTotal)}>
                    <span>
                        <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergy} />
                    </span>
                    <CaptionLefWhite>{t('infoHeader.energy.electricityConsumption')}</CaptionLefWhite>
                </ToggleLegend>
                {onShowMeter1 && (
                    <ToggleLegend title={meter1Name} active={showMeter1} onClick={() => onShowMeter1(!showMeter1)}>
                        <span>
                            <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergyMediumL} />
                        </span>
                        <CaptionLefWhite>{meter1Name}</CaptionLefWhite>
                    </ToggleLegend>
                )}
                {onShowMeter2 && (
                    <ToggleLegend title={meter2Name} active={showMeter2} onClick={() => onShowMeter2(!showMeter2)}>
                        <span>
                            <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergyL} />
                        </span>
                        <CaptionLefWhite>{meter2Name}</CaptionLefWhite>
                    </ToggleLegend>
                )}
                {onShowMeter3 && (
                    <ToggleLegend title={meter3Name} active={showMeter3} onClick={() => onShowMeter3(!showMeter3)}>
                        <span>
                            <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergyRosybrown} />
                        </span>
                        <CaptionLefWhite>{meter3Name}</CaptionLefWhite>
                    </ToggleLegend>
                )}
                {onShowMeter4 && (
                    <ToggleLegend title={meter4Name} active={showMeter4} onClick={() => onShowMeter4(!showMeter4)}>
                        <span>
                            <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergyBrown} />
                        </span>
                        <CaptionLefWhite>{meter4Name}</CaptionLefWhite>
                    </ToggleLegend>
                )}
                {onShowMeter5 && (
                    <ToggleLegend title={meter5Name} active={showMeter5} onClick={() => onShowMeter5(!showMeter5)}>
                        <span>
                            <LegendButtonIcon src={legendIcon} color={theme.colors.greyD} />
                        </span>
                        <CaptionLefWhite>{meter5Name}</CaptionLefWhite>
                    </ToggleLegend>
                )}
            </div>
        </>
    );
};

export default ElectricityConsumptionHeader;
