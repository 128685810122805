import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import EBSTitleMenu from 'components/DataPane/ebs/EBSTitleMenu';
import EBSTextArea from 'components/EBSWizard/EBSTextArea';
import ConsumptionScatter from 'components/Graph/ebs/ConsumptionScatter';
import GasVsElectricityScatter from 'components/Graph/ebs/GasVsElectricityScatter';
import ShowApproxHeader from './ShowApproxHeader';
import ConsumptionLegenda from './ConsumptionLegenda';
import ComparisonLegenda from './ComparisonLegenda';

import { EBSContentWrapper, EBSGraphWrapper } from 'styled/components/dataPane';
import { HideOnPrint } from 'styled/components/ebs';

import { EBSElecTempGasGraphData } from 'components/Graph/types';
import { ExplanatoryText } from '../types';

interface Props {
    elecTempGasGraph: EBSElecTempGasGraphData;
    explanatoryText: ExplanatoryText;
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const InteractionView: React.FunctionComponent<Props> = ({ elecTempGasGraph, explanatoryText, onChangeText }) => {
    const { t } = useTranslation();
    const [showTrendConsumption, setShowTrendConsumption] = useState(true);
    const [showTrendComparison, setShowTrendComparison] = useState(true);
    const [showOutsideOpeningHours, setShowOutsideOpeningHours] = useState(false);

    return (
        <>
            <EBSTitleMenu title={t('energy.ebs.interaction.interactionTitle')} />

            <EBSTitleMenu title={t('energy.ebs.interaction.energyVsTemperature')} />
            <EBSContentWrapper>
                <EBSGraphWrapper>
                    <>
                        <HideOnPrint>
                            <ShowApproxHeader
                                showApprox={showTrendConsumption}
                                onToggle={() => setShowTrendConsumption(!showTrendConsumption)}
                            />
                        </HideOnPrint>
                        <ConsumptionScatter
                            graphData={elecTempGasGraph}
                            showOutsideOpeningHours={showOutsideOpeningHours}
                            showTrend={showTrendConsumption}
                        />
                        <ConsumptionLegenda
                            showOutsideOpeningHours={showOutsideOpeningHours}
                            onDisplayOutsideOpeningHours={() => setShowOutsideOpeningHours(!showOutsideOpeningHours)}
                        />
                    </>
                    <EBSTextArea
                        text={explanatoryText.interactionUsage}
                        name={'interactionUsage'}
                        label={t('energy.ebs.textarea.addAnalysis')}
                        onChangeText={onChangeText}
                    />
                </EBSGraphWrapper>
            </EBSContentWrapper>
            {Boolean(elecTempGasGraph.electricityData.length) && Boolean(elecTempGasGraph.gasData.length) && (
                <>
                    <EBSTitleMenu title={t('energy.ebs.interaction.energyVsGas')} />
                    <EBSContentWrapper>
                        <EBSGraphWrapper>
                            <HideOnPrint>
                                <ShowApproxHeader
                                    showApprox={showTrendComparison}
                                    onToggle={() => setShowTrendComparison(!showTrendComparison)}
                                />
                            </HideOnPrint>
                            <GasVsElectricityScatter showTrend={showTrendComparison} graphData={elecTempGasGraph} />
                            <ComparisonLegenda />
                            <EBSTextArea
                                text={explanatoryText.interactionComparison}
                                name={'interactionComparison'}
                                label={t('energy.ebs.textarea.addAnalysis')}
                                onChangeText={onChangeText}
                            />
                        </EBSGraphWrapper>
                    </EBSContentWrapper>
                </>
            )}
        </>
    );
};

export default InteractionView;
