import React from 'react';
import { routes } from 'routing/routes';

import { useTranslation } from 'react-i18next';

import LinkButton from 'components/Button/LinkButton';

import { H2, BodyLeft } from 'styled/components/text';

import arrowLeftIcon from '../../assets/icons/icons-g-arr-left.svg';

const ReturnToLogin: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <H2>{t('login.buildingInsight')}</H2>
            <BodyLeft>{t('login.byUnica')}</BodyLeft>
            <br />
            <br />
            <br />
            <br />
            <br />
            <LinkButton to={routes.login} text={t('login.backToLogin')} icon={arrowLeftIcon} />
            <br />
            <br />
        </>
    );
};

export default ReturnToLogin;
