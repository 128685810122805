import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigationItem } from './index';
import { SecondaryButton, Icon } from '../../styled/components/buttons';

interface MenuItemProps {
    navItem: NavigationItem;
}

const MenuItem: React.FunctionComponent<MenuItemProps> = ({ navItem }) => {
    const navigate = useNavigate();

    return (
        <SecondaryButton
            active={navItem.active}
            module={navItem.moduleType}
            visuallyDisabled={!navItem.enabled}
            onClick={() => {
                navigate(navItem.path);
            }}
        >
            <Icon
                $disabled={false}
                $active={navItem.active}
                $visuallyDisabled={!navItem.enabled}
                src={navItem.icon ? navItem.icon : ''}
            />
            {navItem.label}
        </SecondaryButton>
    );
};

export default MenuItem;
