import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import airCircle from '../../../../assets/icons/i-circle-a-purple-to-red.svg';
import averageCircle from '../../../../assets/icons/i-circle-a-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';

export interface InfoHeaderProp {
    sensorValue: string;
    isLoading?: boolean;
}
const TemperatureInfo: React.FunctionComponent<InfoHeaderProp> = ({ sensorValue, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();
    const Celsius = '\u{2103}';

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.current')} <br />
                    {t('infoHeader.air.temperature')}
                </CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={airCircle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: Celsius })}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.common.loadingAverage', { unit: Celsius })}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.common.averageOverTime', { unit: Celsius })}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default TemperatureInfo;
