import React from 'react';

import IncidentsDataPane from 'components/DataPane/incidents/IncidentsDataPane';
import { navItemsOccupancy } from 'components/Submenu/ModulesSubMenu';
import IssuesOverview from 'components/IssuesOverview';
import OccupancyIncidentsGraph from 'components/Graph/incidents/MotionIncidentsGraph';

import { Incident, ModuleType } from 'types';
import { IncidentGraphData } from 'components/Graph/types';

const OccupancyIssuesView: React.FunctionComponent = () => {
    return (
        <IssuesOverview
            module={ModuleType.OCCUPANCY}
            subNavItems={navItemsOccupancy}
            renderDataPane={(incident: Incident) => {
                return (
                    <IncidentsDataPane
                        incident={incident}
                        renderGraph={(graphData: IncidentGraphData, isBarChart: boolean) => {
                            return <OccupancyIncidentsGraph graphData={graphData} isBarChart={isBarChart} />;
                        }}
                    />
                );
            }}
        />
    );
};

export default OccupancyIssuesView;
