interface Duration {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export const createDuration = (duration: string): Duration => {
    const mappedDuration = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };

    if (duration.length > 8) {
        mappedDuration.days = parseInt(duration);
    }
    mappedDuration.hours = parseInt(duration.slice(-8, 6));
    mappedDuration.minutes = parseInt(duration.slice(-5, -3));
    mappedDuration.seconds = parseInt(duration.slice(-2));

    return mappedDuration;
};

export const formatDuration = (duration: string) => {
    if (duration.length > 8) {
        const timeSpan = parseInt(duration);

        return `${timeSpan}d`;
    } else if (duration.substring(0, 2) !== '00') {
        const timeSpan = parseInt(duration.substring(0, 2));

        return `${timeSpan}h`;
    } else if (duration.substring(3, 5) !== '00') {
        const timeSpan = parseInt(duration.substring(3, 5));

        return `${timeSpan}m`;
    }
    return undefined;
};

export const formatDurationExtended = (duration: string) => {
    if (duration.length > 8) {
        const days = parseInt(duration);
        const hours = parseInt(duration.substring(2, 4));

        return hours > 0 ? `${days}d ${hours}h` : `${days}d`;
    } else if (duration.substring(0, 2) !== '00') {
        const hours = parseInt(duration.substring(0, 2));
        const minutes = parseInt(duration.substring(3, 5));

        return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
    } else if (duration.substring(3, 5) !== '00') {
        const minutes = parseInt(duration.substring(3, 5));
        const seconds = parseInt(duration.substring(6, 8));

        return seconds > 0 ? `${minutes}m ${seconds}s` : `${minutes}m`;
    }
    return '-';
};
