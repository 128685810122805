import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { isAfter, max, min } from 'date-fns';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';

import { ButtonGroupItem } from 'components/ButtonGroup';
import FeedbackTable from 'components/Table/FeedbackTable';
import DataPaneMenu from 'components/DataPane/DataPaneMenu';
import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import OverviewChart from 'components/Graph/statistics/feedback/OverviewChart';
import LineGraph from 'components/Graph/statistics/feedback/LineGraph';
import DataPaneTitleMenu from 'components/DataPane/DataPaneTitleMenu';
import MetricFeedbackChart from 'components/Graph/statistics/feedback/MetricFeedbackChart';
import OverviewHeader from './infoHeader/OverviewHeader';
import MetricHeader from './infoHeader/MetricHeader';

import { FeedbackTablesWrapper, FeedbackTableWrapper } from 'styled/components/table';
import { ContentWrapper, InfoHeader, FeedbackGraphWrapper, FeedbackPane } from 'styled/components/dataPane';

import { createFromToDate, DateSpan, lastMonth, now } from 'utils/timePeriod';

import { useFeedbackSensorData } from 'hooks/api/useMultiSensorsAverageData';

import { createGraphData, createFeedbackGraphData } from './feedbackStatistics.helper';
import { DataPaneAction, DatapaneState, Feedback, MetricFeedback, MetricType } from './types';
import { ModuleType, Sensor, SensorType } from 'types';

import temperatureIcon from '../../../../assets/icons/icon-tooltip-temperature.svg';
import airQualityIcon from '../../../../assets/icons/icon-tooltip-humidity.svg';
import lightingIcon from '../../../../assets/icons/icon-tooltip-luminocity.svg';
import noiseIcon from '../../../../assets/icons/icon-tooltip-noise.svg';
import messagesIcon from '../../../../assets/icons/icon-feedback-edit.svg';
import feedbackIcon from '../../../../assets/icons/i-m-feedback.svg';

const feedbackButtonItems: ButtonGroupItem[] = [
    { label: i18n.t('common.time.week'), action: DateSpan.WEEK },
    { label: i18n.t('common.time.month'), action: DateSpan.MONTH },
    { label: i18n.t('common.time.year'), action: DateSpan.YEAR },
];

interface FeedbackStatsDataPaneProps {
    selectedRoom: string;
    feedbackSensors: Sensor[];
    feedback: Feedback[];
    onExpand: (isExpanded: boolean) => void;
}
const FeedbackStatsDataPane: React.FunctionComponent<FeedbackStatsDataPaneProps> = ({
    feedback,
    feedbackSensors,
    selectedRoom,
    onExpand,
}) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [state, dispatch] = useReducer(dataPaneReducer, initialState);
    const {
        action,
        customDate,
        selectedPeriod,
        feedbackGraphData,
        temperatureDataParams,
        co2SensorDataParams,
        lightningDataParams,
        selectedTemperatureSensors,
        selectedCo2Sensors,
        selectedLightningSensors,
        temperatureGraphData,
        co2GraphData,
        lightningGraphData,
        evenFreeFormatData,
        oddFreeFormatData,
    } = state;

    const { data: temperatureData, isLoading: temperatureIsLoading } = useFeedbackSensorData(temperatureDataParams);

    const { data: co2Data, isLoading: co2IsLoading } = useFeedbackSensorData(co2SensorDataParams);

    const { data: lightningData, isLoading: lightningIsLoading } = useFeedbackSensorData(lightningDataParams);

    useEffect(() => {
        if (temperatureData) {
            dispatch({ type: 'SET_TEMPERATURE_DATA', temperatureData });
        }
    }, [temperatureData]);

    useEffect(() => {
        if (co2Data) {
            dispatch({ type: 'SET_CO2_DATA', co2Data });
        }
    }, [co2Data]);

    useEffect(() => {
        if (lightningData) {
            dispatch({ type: 'SET_LIGHTNING_DATA', lightningData });
        }
    }, [lightningData]);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_SENSOR_SELECTION',
            feedbackSensors: feedbackSensors,
        });
    }, [feedbackSensors]);

    useEffect(() => {
        if (feedback.length) {
            const { from } = action === DateSpan.CUSTOM ? customDate : createFromToDate(selectedPeriod);
            const filteredFeedbackData = feedback
                .filter(f => f.roomId === selectedRoom)
                .filter(f => isAfter(new Date(f.createdUtc), new Date(from)));
            dispatch({ type: 'SET_FEEDBACK_DATA', feedbackData: filteredFeedbackData });
        }
    }, [selectedRoom, feedback, state.action]);

    const hasFeedback = useCallback(
        (metric: MetricType) => {
            if (feedbackGraphData?.data) {
                const feedback = feedbackGraphData.data.filter(feedback => feedback[metric] > 0);

                return feedback.length > 0;
            }

            return false;
        },
        [feedbackGraphData]
    );

    const expand = useCallback(
        (expand: boolean) => {
            setIsExpanded(expand);
            onExpand(expand);
        },
        [onExpand]
    );

    const dateLastFeedbackReceived = useCallback(() => {
        const dates: Date[] = [];
        feedback.filter(f => f.roomId === selectedRoom).forEach(f => dates.push(new Date(f.createdUtc)));

        return max(dates);
    }, [selectedRoom, feedback]);

    const dateFirstFeedbackReceived = useCallback(() => {
        const dates: Date[] = [];
        feedback.filter(f => f.roomId === selectedRoom).forEach(f => dates.push(new Date(f.createdUtc)));

        return min(dates);
    }, [selectedRoom, feedback]);

    return (
        <FeedbackPane isExpanded={isExpanded}>
            <DataPaneMenu
                customDate={customDate}
                title={t('feedback.datapane.overview')}
                icon={feedbackIcon}
                buttonGroupItems={feedbackButtonItems}
                selectedAction={action}
                onSelectAction={(action, timespan, customDate) =>
                    dispatch({
                        type: 'CHANGE_DATE_SPAN',
                        action,
                        timespan,
                        customDate: customDate || { from: '', to: '' },
                    })
                }
                allowCustomPeriod={false}
                module={ModuleType.FEEDBACK}
                canExpand={Boolean(feedbackGraphData?.data.length)}
                expand={Boolean(isExpanded && feedbackGraphData?.data.length)}
                onExpand={expand}
            />

            <ContentWrapper>
                {feedbackGraphData?.data.length === 0 && (
                    <NoDataMessage
                        firstDataReceivedDate={dateFirstFeedbackReceived()}
                        lastDataReceivedDate={dateLastFeedbackReceived()}
                    />
                )}
                {feedbackGraphData?.data.length && (
                    <>
                        <InfoHeader>
                            <OverviewHeader feedback={feedbackGraphData.data} />
                        </InfoHeader>
                        <FeedbackGraphWrapper>
                            <OverviewChart graphData={feedbackGraphData} />
                        </FeedbackGraphWrapper>
                    </>
                )}
            </ContentWrapper>
            {isExpanded && hasFeedback(MetricFeedback.TEMPERATURE) && (
                <>
                    <DataPaneTitleMenu title={t('feedback.datapane.temperatureRating')} icon={temperatureIcon} />
                    <ContentWrapper>
                        {feedbackGraphData?.data.length && (
                            <>
                                <InfoHeader>
                                    <MetricHeader
                                        feedback={feedbackGraphData.data}
                                        metric={MetricFeedback.TEMPERATURE}
                                    />
                                </InfoHeader>
                                <FeedbackGraphWrapper>
                                    <MetricFeedbackChart
                                        metric={MetricFeedback.TEMPERATURE}
                                        graphData={feedbackGraphData}
                                    />
                                    {temperatureGraphData?.data.length && (
                                        <LineGraph
                                            sensor={selectedTemperatureSensors[0]}
                                            graphData={temperatureGraphData}
                                        />
                                    )}
                                </FeedbackGraphWrapper>
                            </>
                        )}
                        {(temperatureIsLoading || temperatureGraphData === null) && (
                            <LoadingOverlay dark={true} isSmall={true} />
                        )}
                    </ContentWrapper>
                </>
            )}
            {isExpanded && hasFeedback(MetricFeedback.CO2) && (
                <>
                    <DataPaneTitleMenu title={t('feedback.datapane.airQualityRating')} icon={airQualityIcon} />
                    <ContentWrapper>
                        {feedbackGraphData?.data.length && (
                            <>
                                <InfoHeader>
                                    <MetricHeader feedback={feedbackGraphData.data} metric={MetricFeedback.CO2} />
                                </InfoHeader>
                                <FeedbackGraphWrapper>
                                    <MetricFeedbackChart metric={MetricFeedback.CO2} graphData={feedbackGraphData} />
                                    {co2GraphData?.data.length && (
                                        <LineGraph sensor={selectedCo2Sensors[0]} graphData={co2GraphData} />
                                    )}
                                </FeedbackGraphWrapper>
                            </>
                        )}
                        {(co2IsLoading || co2GraphData === null) && <LoadingOverlay dark={true} isSmall={true} />}
                    </ContentWrapper>
                </>
            )}
            {isExpanded && hasFeedback(MetricFeedback.LIGHT_INTENSITY) && (
                <>
                    <DataPaneTitleMenu title={t('feedback.datapane.lightingRating')} icon={lightingIcon} />
                    <ContentWrapper>
                        {feedbackGraphData?.data.length && (
                            <>
                                <InfoHeader>
                                    <MetricHeader
                                        feedback={feedbackGraphData.data}
                                        metric={MetricFeedback.LIGHT_INTENSITY}
                                    />
                                </InfoHeader>
                                <FeedbackGraphWrapper>
                                    <MetricFeedbackChart
                                        metric={MetricFeedback.LIGHT_INTENSITY}
                                        graphData={feedbackGraphData}
                                    />
                                    {lightningGraphData?.data.length && (
                                        <LineGraph
                                            sensor={selectedLightningSensors[0]}
                                            graphData={lightningGraphData}
                                        />
                                    )}
                                </FeedbackGraphWrapper>
                            </>
                        )}
                        {(lightningIsLoading || lightningGraphData === null) && (
                            <LoadingOverlay dark={true} isSmall={true} />
                        )}
                    </ContentWrapper>
                </>
            )}
            {isExpanded && hasFeedback(MetricFeedback.NOISE_LEVEL) && (
                <>
                    <DataPaneTitleMenu title={t('feedback.datapane.noiseRating')} icon={noiseIcon} />
                    <ContentWrapper>
                        {feedbackGraphData?.data.length && (
                            <>
                                <InfoHeader>
                                    <MetricHeader
                                        feedback={feedbackGraphData.data}
                                        metric={MetricFeedback.NOISE_LEVEL}
                                    />
                                </InfoHeader>
                                <FeedbackGraphWrapper>
                                    <MetricFeedbackChart
                                        metric={MetricFeedback.NOISE_LEVEL}
                                        graphData={feedbackGraphData}
                                    />
                                </FeedbackGraphWrapper>
                            </>
                        )}
                    </ContentWrapper>
                </>
            )}

            {isExpanded && evenFreeFormatData.length && (
                <>
                    <DataPaneTitleMenu title={t('feedback.datapane.messages')} icon={messagesIcon} />
                    <ContentWrapper>
                        <FeedbackTablesWrapper>
                            <FeedbackTableWrapper>
                                <FeedbackTable feedback={evenFreeFormatData} />
                            </FeedbackTableWrapper>
                            <FeedbackTableWrapper>
                                <FeedbackTable feedback={oddFreeFormatData} />
                            </FeedbackTableWrapper>
                        </FeedbackTablesWrapper>
                    </ContentWrapper>
                </>
            )}
        </FeedbackPane>
    );
};

export default FeedbackStatsDataPane;

const initialState: DatapaneState = {
    feedbackDataParams: {
        from: lastMonth,
        to: now,
        ids: [],
    },
    temperatureDataParams: {
        from: '',
        to: '',
        ids: [],
    },
    co2SensorDataParams: {
        from: '',
        to: '',
        ids: [],
    },
    lightningDataParams: {
        from: '',
        to: '',
        ids: [],
    },
    selectedPeriod: DateSpan.MONTH,
    selectedFeedbackSensors: [],
    selectedTemperatureSensors: [],
    selectedCo2Sensors: [],
    selectedLightningSensors: [],
    feedbackGraphData: null,
    temperatureGraphData: null,
    co2GraphData: null,
    lightningGraphData: null,
    evenFreeFormatData: [],
    oddFreeFormatData: [],
    action: DateSpan.MONTH,
    customDate: {
        from: '',
        to: '',
    },
};

const dataPaneReducer = (state: DatapaneState, action: DataPaneAction): DatapaneState => {
    switch (action.type) {
        case 'SET_FEEDBACK_DATA': {
            const graph = createFeedbackGraphData(action.feedbackData, state.selectedPeriod);
            const filteredFreeFormat = action.feedbackData.filter(ff => ff.metric.message !== null);
            const sortedFreeFormat = filteredFreeFormat.sort((a, b) => {
                return new Date(b.createdUtc).valueOf() - new Date(a.createdUtc).valueOf();
            });

            return {
                ...state,
                feedbackGraphData: graph,
                evenFreeFormatData: sortedFreeFormat.filter((_, index) => index % 2 === 0),
                oddFreeFormatData: sortedFreeFormat.filter((_, index) => index % 2 === 1),
            };
        }
        case 'SET_TEMPERATURE_DATA': {
            const temperatureGraphData = createGraphData(action.temperatureData, state.selectedPeriod);
            return {
                ...state,
                temperatureGraphData: temperatureGraphData,
            };
        }
        case 'SET_CO2_DATA': {
            const co2GraphData = createGraphData(action.co2Data, state.selectedPeriod);
            return {
                ...state,
                co2GraphData: co2GraphData,
            };
        }
        case 'SET_LIGHTNING_DATA': {
            const lightningGraphData = createGraphData(action.lightningData, state.selectedPeriod);
            return {
                ...state,
                lightningGraphData: lightningGraphData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : state.customDate;

            return {
                ...state,
                customDate,
                action: action.action,
                selectedPeriod: action.timespan,
                temperatureGraphData: null,
                co2GraphData: null,
                lightningGraphData: null,
                feedbackDataParams: {
                    to,
                    from,
                    ids: [],
                },
                temperatureDataParams: { ...state.temperatureDataParams, from, to },
                co2SensorDataParams: { ...state.co2SensorDataParams, from, to },
                lightningDataParams: { ...state.lightningDataParams, from, to },
            };
        }
        case 'CHANGE_SENSOR_SELECTION': {
            const selectedTemperatureSensors = action.feedbackSensors.filter(s => s.type === SensorType.TEMPERATURE);
            const selectedCo2Sensors = action.feedbackSensors.filter(s => s.type === SensorType.CO2);
            const selectedLightningSensors = action.feedbackSensors.filter(s => s.type === SensorType.ILLUMINANCE);

            const { from, to } =
                state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.selectedPeriod);

            return {
                ...state,
                temperatureGraphData: null,
                co2GraphData: null,
                lightningGraphData: null,
                selectedTemperatureSensors: selectedTemperatureSensors,
                selectedCo2Sensors: selectedCo2Sensors,
                selectedLightningSensors: selectedLightningSensors,

                temperatureDataParams: {
                    from,
                    to,
                    ids: selectedTemperatureSensors.map(sensor => sensor.id),
                },
                co2SensorDataParams: {
                    from,
                    to,
                    ids: selectedCo2Sensors.map(sensor => sensor.id),
                },
                lightningDataParams: {
                    from,
                    to,
                    ids: selectedLightningSensors.map(sensor => sensor.id),
                },
            };
        }
    }
};
