import { GraphData } from 'components/Graph/types';
import { Sensor, SensorAverage } from 'types';
import { DateSpan, DateRange } from 'utils/timePeriod';

export interface FeedbackDataParam {
    from: string;
    to: string;
    ids: string[];
}

enum GeneralFeeling {
    POOR = 'Poor',
    GOOD = 'Good',
    GREAT = 'Great',
}

export enum MetricFeedback {
    TEMPERATURE = 'temperature',
    CO2 = 'co2',
    HUMIDITY = 'humidity',
    NOISE_LEVEL = 'noiseLevel',
    LIGHT_INTENSITY = 'lightIntensity',
}

export type MetricType = 'co2' | 'humidity' | 'noiseLevel' | 'lightIntensity' | 'temperature';

export type FeedbackMetric = {
    temperature: number | null;
    co2: number | null;
    humidity: number | null;
    noiseLevel: number | null;
    lightIntensity: number | null;
    message: string | null;
};

export type Feedback = {
    buildingId: string;
    createdUtc: string;
    generalFeeling: GeneralFeeling;
    id: string;
    locationId: string;
    roomId: string;
    metric: FeedbackMetric;
    type: string;
};

export type MergedFeedback = {
    date: number;
    temperature: number[];
    co2: number[];
    humidity: number[];
    noiseLevel: number[];
    lightIntensity: number[];
    messages: string[];
    totalFeedback: number;
};

export type FeedbackTimeserie = {
    timestamp: number;
    temperature: number;
    temperatureTotalFeedback: number;
    co2: number;
    co2TotalFeedback: number;
    humidity: number;
    humidityTotalFeedback: number;
    noiseLevel: number;
    noiseLevelTotalFeedback: number;
    lightIntensity: number;
    lightIntensityTotalFeedback: number;
    messages?: string[];
    totalFeedback: number;
};

export interface FeedbackGraphData {
    data: FeedbackTimeserie[];
    timespan: string;
    tickValues: number[];
}

export type DatapaneState = {
    feedbackDataParams: FeedbackDataParam;
    temperatureDataParams: FeedbackDataParam;
    co2SensorDataParams: FeedbackDataParam;
    lightningDataParams: FeedbackDataParam;
    feedbackGraphData: FeedbackGraphData | null;
    selectedFeedbackSensors: Sensor[];
    selectedTemperatureSensors: Sensor[];
    selectedCo2Sensors: Sensor[];
    selectedLightningSensors: Sensor[];
    temperatureGraphData: GraphData | null;
    co2GraphData: GraphData | null;
    lightningGraphData: GraphData | null;
    evenFreeFormatData: Feedback[];
    oddFreeFormatData: Feedback[];
    selectedPeriod: DateSpan;
    customDate: DateRange;
    action: string;
};

export type DataPaneAction =
    | { type: 'SET_TEMPERATURE_DATA'; temperatureData: SensorAverage[] }
    | { type: 'SET_CO2_DATA'; co2Data: SensorAverage[] }
    | { type: 'SET_LIGHTNING_DATA'; lightningData: SensorAverage[] }
    | { type: 'SET_FEEDBACK_DATA'; feedbackData: Feedback[] }
    | { type: 'CHANGE_DATE_SPAN'; action: string; timespan: DateSpan; customDate: DateRange }
    | { type: 'CHANGE_SENSOR_SELECTION'; feedbackSensors: Sensor[] };
