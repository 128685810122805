import React, { useState, FunctionComponent, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'routing/routes';

import { useTranslation } from 'react-i18next';

import MenuItem from './MenuItem';
import { ModuleType } from 'types';
import SideMenu from 'components/SideMenu';

import { MenuIcon } from '../../styled/components/buttons';
import { TopNavBar, TitleBar, ModulesBar, MenuBar, InactiveWrapper } from '../../styled/components/navigation';
import { getIcon } from 'styled/Theme';

import useOnClickOutside from 'hooks/useOnClickOutside';
import { allModules } from 'context/TenantContext';

import unicaIcon from '../../assets/icons/by-unica.svg';
import burgerIcon from '../../assets/icons/icons-burger.svg';

export interface NavigationItem {
    label: string;
    path: string;
    active: boolean;
    enabled: boolean;
    icon?: string;
    moduleType?: ModuleType;
}

interface NavigationBarProps {
    modules: ModuleType[];
}

const NavigationBar: FunctionComponent<NavigationBarProps> = ({ modules }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const ref = useRef(null);

    const createNavigationItem = (module: ModuleType): NavigationItem => {
        const path = modules.includes(module)
            ? `/${module.toLocaleLowerCase()}/statistics`
            : `/${module.toLocaleLowerCase()}/preview`;
        const active = location.pathname === path;
        const label = t(`navigation.item${module}`);
        const icon = getIcon(module);
        const moduleType = module;
        const enabled = modules.includes(module);

        return {
            label,
            active,
            enabled,
            path,
            icon,
            moduleType,
        };
    };

    const handleClickOutside = () => {
        setShowMenu(false);
    };

    useOnClickOutside(ref, handleClickOutside);

    useEffect(() => {
        const currentLocation = location.pathname;

        setMenuItems(items => [
            ...items.map(item => {
                item.active = currentLocation.includes(item.path.replace('/statistics', ''));

                return item;
            }),
        ]);
    }, [location.pathname]);

    const navigationItems: Array<NavigationItem> = allModules.map(createNavigationItem);
    const [menuItems, setMenuItems] = useState<Array<NavigationItem>>(navigationItems);

    return (
        <>
            <TopNavBar>
                <TitleBar onClick={() => navigate(routes.dashboard.facilities)}>
                    <img src={unicaIcon} alt="Building Insight by Unica" />
                </TitleBar>
                <ModulesBar>
                    {menuItems
                        .filter(item => item.enabled)
                        .map((m, index) => (
                            <MenuItem navItem={m} key={index} />
                        ))}
                    <InactiveWrapper>
                        {menuItems
                            .filter(item => !item.enabled)
                            .map((m, index) => (
                                <MenuItem navItem={m} key={index} />
                            ))}
                    </InactiveWrapper>
                </ModulesBar>
                <MenuBar active={showMenu} onClick={() => setShowMenu(!showMenu)}>
                    <MenuIcon $disabled={false} $active={showMenu} src={burgerIcon} />
                </MenuBar>
            </TopNavBar>
            {showMenu ? (
                <div ref={ref}>
                    <SideMenu hideSideMenu={() => setShowMenu(false)} />
                </div>
            ) : null}
        </>
    );
};

export default NavigationBar;
