import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { BagData, KpiUnit, Sensor, SensorAverage, SensorAvgFilter, WeatherData } from 'types';
import { Space } from 'views/authenticated/types';

import { lastYear, twoYearsBack } from '../../components/EBSWizard/ebs.helpers';

const startDate = new Date(lastYear, 0, 1, 0, 0, 1).toISOString();
const endDate = new Date(lastYear, 11, 31, 23, 59, 59).toISOString();
const startDateYearBefore = new Date(twoYearsBack, 0, 1, 0, 0, 1).toISOString();
const endDateYearBefore = new Date(twoYearsBack, 11, 31, 23, 59, 59).toISOString();

export const useEBSData = (
    building: Space,
    consumptionSensors: Sensor[],
    gasSensors: Sensor[],
    productionSensors: Sensor[],
    feedinSensors: Sensor[]
) => {
    const { axiosClient } = useAxiosClient();
    const electricityIds = consumptionSensors.map(s => s.id);
    const gasIds = gasSensors.map(s => s.id);
    const productionIds = productionSensors.map(s => s.id);
    const feedinIds = feedinSensors.map(s => s.id);

    return useQuery(
        ['ebs', building.id, electricityIds, gasIds, productionIds, feedinIds],
        async ({
            signal,
        }): Promise<{
            bag: BagData | null;
            weather: WeatherData[];
            electricity: SensorAverage[];
            electricityHistoric: SensorAverage[];
            gas: SensorAverage[];
            gasHistoric: SensorAverage[];
            production: SensorAverage[];
            productionHistoric: SensorAverage[];
            feedin: SensorAverage[];
            feedinHistoric: SensorAverage[];
        }> => {
            const promiseArray = createEndpoints(
                building,
                consumptionSensors.map(s => s.id),
                gasSensors.map(s => s.id),
                productionSensors.map(s => s.id),
                feedinSensors.map(s => s.id)
            ).map(url => axiosClient.get(url, { signal }));
            const result = await Promise.all(promiseArray);

            let electricity = (result[2]?.data as SensorAverage[]) || [];
            let electricityHistoric = (result[3]?.data as SensorAverage[]) || [];
            if (consumptionSensors.some(s => s.unit === KpiUnit.WH)) {
                electricity = electricity.map(d => ({ value: Number((d.value / 1000).toFixed(2)), field: d.field }));
                electricityHistoric = electricityHistoric.map(d => ({
                    value: Number((d.value / 1000).toFixed(2)),
                    field: d.field,
                }));
            }

            let production = (result[6]?.data as SensorAverage[]) || [];
            let productionHistoric = (result[7]?.data as SensorAverage[]) || [];
            if (productionSensors.some(s => s.unit === KpiUnit.WH)) {
                production = production.map(d => ({ value: Number((d.value / 1000).toFixed(2)), field: d.field }));
                productionHistoric = productionHistoric.map(d => ({
                    value: Number((d.value / 1000).toFixed(2)),
                    field: d.field,
                }));
            }

            let feedin = (result[8]?.data as SensorAverage[]) || [];
            let feedinHistoric = (result[9]?.data as SensorAverage[]) || [];
            if (feedinSensors.some(s => s.unit === KpiUnit.WH)) {
                feedin = feedin.map(d => ({ value: Number((d.value / 1000).toFixed(2)), field: d.field }));
                feedinHistoric = feedinHistoric.map(d => ({
                    value: Number((d.value / 1000).toFixed(2)),
                    field: d.field,
                }));
            }

            return {
                bag: (result[0]?.data[0] as BagData) || null,
                weather: (result[1]?.data.data as WeatherData[]) || [],
                electricity,
                electricityHistoric,
                gas: (result[4]?.data as SensorAverage[]) || [],
                gasHistoric: (result[5]?.data as SensorAverage[]) || [],
                production,
                productionHistoric,
                feedin,
                feedinHistoric,
            };
        }
    );
};

const createEndpoints = (
    building: Space,
    electricityIds: string[],
    gasIds: string[],
    productionIds: string[],
    feedinIds: string[]
): string[] => {
    const urlArray: string[] = [];

    if (building.postCode && building.buildingNumber) {
        urlArray[0] = config.bagEnergy(building.postCode, building.buildingNumber);
    }

    if (building.openWeather) {
        urlArray[1] = config.temperature(building.openWeather, startDate, endDate);
    }

    if (electricityIds.length) {
        urlArray[2] = config.sensorDataSumTotal(electricityIds, SensorAvgFilter.HOUR_OF_DAY, startDate, endDate);
        urlArray[3] = config.sensorDataSumTotal(
            electricityIds,
            SensorAvgFilter.YEAR,
            startDateYearBefore,
            endDateYearBefore
        );
    }

    if (gasIds.length) {
        urlArray[4] = config.sensorDataSumTotal(gasIds, SensorAvgFilter.HOUR_OF_DAY, startDate, endDate);
        urlArray[5] = config.sensorDataSumTotal(gasIds, SensorAvgFilter.YEAR, startDateYearBefore, endDateYearBefore);
    }

    if (productionIds.length) {
        urlArray[6] = config.sensorDataSumTotal(productionIds, SensorAvgFilter.HOUR_OF_DAY, startDate, endDate);
        urlArray[7] = config.sensorDataSumTotal(
            productionIds,
            SensorAvgFilter.YEAR,
            startDateYearBefore,
            endDateYearBefore
        );
    }

    if (feedinIds.length) {
        urlArray[8] = config.sensorDataSumTotal(feedinIds, SensorAvgFilter.HOUR_OF_DAY, startDate, endDate);
        urlArray[9] = config.sensorDataSumTotal(
            feedinIds,
            SensorAvgFilter.YEAR,
            startDateYearBefore,
            endDateYearBefore
        );
    }

    return urlArray;
};
