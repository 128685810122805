import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import { VictoryChart, VictoryAxis, VictoryTooltip, VictoryBar, VictoryZoomContainer } from 'victory';
import { format } from 'date-fns';

import TooltipBar from 'components/Graph/Tooltip/TooltipBar';

import { OccupancyGraphData } from 'components/DataPane/statistics/energy/types';

import { useResize } from 'hooks/useResize';

import { monthDayFormat } from 'utils/timePeriod';

import {
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
    barChartDomainPadding,
    barChart3AxisPadding,
} from '../../graph.styles';
import { KpiUnit, ModuleType } from 'types';
import {
    formatTick,
    getMaxSensorValue,
    normalizedTickValues,
    normalizeYValue,
    percentageValues,
    setBarConfig3Axis,
    sumValues,
} from 'components/Graph/graph.helpers';
import { SensorValue } from 'components/Graph/types';

interface GraphProps {
    graphData: OccupancyGraphData;
    showGas: boolean;
    showElectricity: boolean;
    showOccupancy: boolean;
}
const OccupancyConsumptionGraph: React.FunctionComponent<GraphProps> = ({
    graphData,
    showGas,
    showElectricity,
    showOccupancy,
}) => {
    const dimension = useResize();
    const barConfig = setBarConfig3Axis(graphData.timespan);

    const summedElectricityData = useMemo(() => sumValues(graphData.electricityData, graphData.timespan), [graphData]);
    const summedGasData = useMemo(() => sumValues(graphData.gasData, graphData.timespan), [graphData]);
    const summedOccupancy = useMemo(() => percentageValues(graphData.occupancyData, graphData.timespan), [graphData]);

    const maxElectricityData = useMemo(() => getMaxSensorValue(summedElectricityData), [summedElectricityData]);
    const maxGasData = useMemo(() => getMaxSensorValue(summedGasData), [summedGasData]);
    const maxOccupancyData = useMemo(() => getMaxSensorValue(summedOccupancy), [summedOccupancy]);

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={barChartDomainPadding}
                padding={barChart3AxisPadding}
                containerComponent={<VictoryZoomContainer zoomDimension="x" minimumZoom={{ x: 1, y: 0.005 }} />}
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number) =>
                        index % graphData.tickAmount == 0 ? format(new Date(t * 1000), graphData.tickFormat) : ''
                    }
                    style={XYAxis}
                />

                {/* Gas Y-Axis */}
                <VictoryAxis
                    offsetX={80}
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) => formatTick(t, i, summedGasData, KpiUnit.M3)}
                    dependentAxis={true}
                    style={
                        showGas
                            ? XYAxis
                            : {
                                  ...XYAxis,
                                  tickLabels: { fill: theme.colors.greyD },
                                  axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                              }
                    }
                />

                {/* Energy Y-axis */}
                <VictoryAxis
                    offsetX={40}
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) => formatTick(t, i, summedElectricityData, KpiUnit.KWH)}
                    dependentAxis={true}
                    style={
                        showElectricity
                            ? XYAxis
                            : {
                                  ...XYAxis,
                                  tickLabels: { fill: theme.colors.greyD },
                                  axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                              }
                    }
                />

                {/* Occupancy Y-axis */}
                <VictoryAxis
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) => formatTick(t, i, summedOccupancy, KpiUnit.PERCENTAGE)}
                    dependentAxis={true}
                    style={
                        showOccupancy
                            ? XYAxis
                            : {
                                  ...XYAxis,
                                  tickLabels: { fill: theme.colors.greyD },
                                  axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                              }
                    }
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date x axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {Boolean(graphData.electricityData.length) && showElectricity && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar module={ModuleType.ENERGY} title={'Electricity'} unit={KpiUnit.KWH} />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mEnergy,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxElectricityData)}
                        data={summedElectricityData}
                    />
                )}

                {Boolean(graphData.occupancyData.length) && showOccupancy && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={'Occupancy'}
                                        unit={KpiUnit.PERCENTAGE}
                                    />
                                }
                            />
                        }
                        animate={{ duration: 500, easing: 'cubicOut' }}
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.white,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxOccupancyData)}
                        data={summedOccupancy}
                    />
                )}

                {Boolean(graphData.gasData.length) && showGas && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar module={ModuleType.ENERGY} title={'Gas'} unit={KpiUnit.M3} />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mWater,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxGasData)}
                        data={summedGasData}
                    />
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(OccupancyConsumptionGraph);
