import React, { useState, useMemo } from 'react';

import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import TemperatureConsumptionGraph from 'components/Graph/statistics/energy/TemperatureConsumptionGraph';
import TemperatureConsumptionHeader from './infoHeader/TemperatureConsumptionHeader';

import { ContentWrapper, InfoHeader, LoadingWrapper, SensorGraphWrapper } from 'styled/components/dataPane';

import { TemperatureGraphData } from './types';

import { getFirstDataTimestamp, getLastKpiUpdateTimestamp, hourSpan, summedValue } from './energyStatistics.helper';
import { Sensor } from 'types';

interface TemperatureConsumptionProps {
    graphData: TemperatureGraphData | null;
    sensors: Sensor[];
    isLoadingConsumption: boolean;
    isLoadingTemperature: boolean;
}
const TemperatureConsumption: React.FunctionComponent<TemperatureConsumptionProps> = ({
    graphData,
    sensors,
    isLoadingConsumption,
    isLoadingTemperature,
}) => {
    const [showGas, setShowGas] = useState(Boolean(graphData?.gasData.length));
    const [showElectricity, setShowElectricity] = useState(true);
    const [showTemperature, setShowTemperature] = useState(true);

    const avgValue = useMemo(() => calculateAvgValues(graphData), [graphData]);
    const lastUpdate = getLastKpiUpdateTimestamp(sensors);
    const firstData = getFirstDataTimestamp(sensors);

    return (
        <>
            <ContentWrapper>
                {isLoadingConsumption && graphData === null && (
                    <LoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption &&
                    graphData?.electricityData.length === 0 &&
                    graphData?.gasData.length === 0 && (
                        <LoadingWrapper>
                            <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                        </LoadingWrapper>
                    )}
                {!isLoadingConsumption &&
                    (Boolean(graphData?.electricityData.length) || Boolean(graphData?.gasData.length)) && (
                        <>
                            <InfoHeader>
                                <TemperatureConsumptionHeader
                                    electricityAvg={avgValue.electricity}
                                    gasAvg={avgValue.gas}
                                    temperatureAvg={avgValue.temperature}
                                    hasGasData={Boolean(graphData.gasData.length)}
                                    temperatureLoading={isLoadingTemperature}
                                    showGas={showGas}
                                    onShowGas={setShowGas}
                                    showElectricity={showElectricity}
                                    onShowElectricity={setShowElectricity}
                                    showTemperature={showTemperature}
                                    onShowTemperature={setShowTemperature}
                                    isInGigajoules={graphData.isInGigajoules}
                                />
                            </InfoHeader>
                            <SensorGraphWrapper>
                                <TemperatureConsumptionGraph
                                    graphData={graphData}
                                    showGas={showGas}
                                    showElectricity={showElectricity}
                                    showTemperature={showTemperature}
                                />
                            </SensorGraphWrapper>
                        </>
                    )}
            </ContentWrapper>
        </>
    );
};

export default TemperatureConsumption;

const calculateAvgValues = (graphData: TemperatureGraphData | null) => {
    if (!graphData) {
        return {
            electricity: 0,
            gas: 0,
            temperature: 0,
        };
    }
    const totalKWH = summedValue(graphData.electricityData, 1);
    const totalM3 = summedValue(graphData.gasData, 1);
    const totalTemperature = summedValue(graphData.temperatureData, 1);
    const totalHours = hourSpan(graphData.tickValues);

    return {
        electricity: totalKWH ? +(totalKWH / totalHours).toFixed(1) : 0,
        gas: totalM3 ? +(totalM3 / totalHours).toFixed(1) : 0,
        temperature: totalTemperature ? +(totalTemperature / graphData.temperatureData.length).toFixed(1) : 0,
    };
};
