import React from 'react';

import { Spinner, Cube, Cube2 } from 'styled/components/loadingOverlay';

interface LoadingOverlayProps {
    isSmall?: boolean;
    dark?: boolean;
}
const LoadingOverlay: React.FunctionComponent<LoadingOverlayProps> = ({ isSmall = false, dark = false }) => {
    return (
        <Spinner>
            <Cube dark={dark} isSmall={isSmall} />
            <Cube2 dark={dark} isSmall={isSmall} />
        </Spinner>
    );
};

export default LoadingOverlay;
