import styled, { css } from 'styled-components';
import { theme } from 'styled/Theme';

export const Wrapper = styled.div`
    display: column;
    flex: 1;

    > div {
        border-bottom: 1px solid ${theme.colors.grey};
        &:last-child {
            border-bottom: 0;
        }
    }
`;

export const TableBox = styled.div`
    border-right: 1px solid ${theme.colors.bg};
    display: flex;
    align-items: center;
    width: 75px;
    height: 50px;
`;

export const TableHeader = styled(TableBox)`
    width: 65px;
    height: 50px;
    padding-left: 10px;
`;

export const TableContent = styled.input`
    box-sizing: border-box;
    width: 75px;
    height: 50px;
    padding: 0px;
    margin: 0px;
    border: solid 2px;
    ${({ disabled }) =>
        disabled
            ? css`
                  background-color: ${theme.colors.bg};
                  border-color: ${theme.colors.bg};
              `
            : css`
                  background-color: ${theme.colors.white};
                  border-color: ${theme.colors.white};
              `};
    font-weight: bold;
    text-indent: 10px;
    :invalid {
        border-color: ${theme.colors.negative};
    }
    :disabled {
        color: ${theme.colors.text};
    }
`;
export const Row = styled.div`
    display: flex;
`;
