import { getUnixTime } from 'date-fns';

import { DateRange, DateSpan, isDaysTimespan } from 'utils/timePeriod';

import { SensorAverage } from 'types';
import { GraphData } from 'components/Graph/types';
import {
    mapAverageByDayOfWeek,
    mapAverageByHourOfDayOfWeek,
    deduplicateValues,
    createTickValues,
    calcAxisDates,
    isMapAveragesByDay,
    mapSensorValue,
    getMax,
    getMin,
} from 'components/Graph/graph.helpers';

export const createGraphData = (
    data: SensorAverage[],
    averages: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange,
    isBarChart: boolean = false
) => {
    const sensorData = data.map(mapSensorValue);

    const averageSensorData = averages
        ? sensorData.map(d => {
              return isMapAveragesByDay(timespan, isBarChart)
                  ? mapAverageByDayOfWeek(d, averages)
                  : mapAverageByHourOfDayOfWeek(d, averages);
          })
        : [];

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const timeFormat = isMapAveragesByDay(timespan, isBarChart) ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';

    const graphData: GraphData = {
        data: sensorData,
        averageData: deduplicateValues(averageSensorData, timeFormat),
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
    };
    return graphData;
};

export const createMotionGraphData = (
    data: SensorAverage[],
    motionData: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange
) => {
    const sensorData = data.map(mapSensorValue);

    const motionSensorData = motionData
        ? motionData.map(d => ({
              value: d.value,
              timestamp: getUnixTime(new Date(d.field)),
          }))
        : [];

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: GraphData = {
        data: sensorData,
        averageData: motionSensorData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
    };

    return graphData;
};
