import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorSubType } from 'types';
import { MeterData } from 'views/authenticated/types';

export const useMeterData = (sensorSubType: SensorSubType, enabled = true) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['submetersBySensorSubType', sensorSubType],
        async ({ signal }) => {
            const { data } = await axiosClient.get<MeterData>(config.meters(sensorSubType), {
                signal,
            });
            return data;
        },
        {
            enabled: enabled,
        }
    );
};
