import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { Incident, IncidentData, ModuleType } from 'types';

import { allModules } from 'context/TenantContext';

export const useAllIncidentsData = (modules: ModuleType[], period: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['allIncidents', modules, period],
        async ({ signal }): Promise<Incident[]> => {
            const promiseArray = createPeriodEndPoints(modules, period).map(url => axiosClient.get(url, { signal }));
            const result = await Promise.all(promiseArray);

            return mergeAndSortResult(result);
        },
        {
            enabled: Boolean(modules.filter(m => m !== ModuleType.FEEDBACK).length),
        }
    );
};

const createPeriodEndPoints = (modules: ModuleType[], timePeriod: string) => {
    const enabledModules: string[] = [];
    allModules.forEach(m => {
        if (modules.includes(m) && m !== ModuleType.FEEDBACK) {
            enabledModules.push(config.incidents(m, timePeriod));
        }
    });
    return enabledModules;
};

const mergeAndSortResult = (result: AxiosResponse<IncidentData>[]) => {
    const mergedArray: Incident[] = [];
    result.forEach(r => {
        if (r.data && r.data.data) {
            mergedArray.push(...r.data.data);
        }
    });

    return mergedArray.sort((a, b) => {
        return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();
    });
};
