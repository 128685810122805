import React, { useEffect, useReducer } from 'react';

import AirStatisticsSideMenu from 'components/SideMenu/AirStatisticsSideMenu';
import SensorSelectorAir from 'components/SensorSelectorAir';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { SpaceSuggestion } from 'components/SearchAutoComplete';
import { KPIOverview } from 'components/SensorSelectorAir/types';
import AirSensorDataPane from 'components/DataPane/statistics/air/AirSensorsDataPane';
import ModulesSubMenu, { navItemsAir } from 'components/Submenu/ModulesSubMenu';

import { AirSensorWrapper } from 'styled/components/sensors';

import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';
import { useAirMetricData } from 'hooks/api/useAirMetricData';

import { AirMetricsAction, AirMetricsQualityAction, ModuleType, Sensor, SensorType } from 'types';
import { AirSensorListAction, AirSensorOverviewState } from './types';

const AirStatisticsView: React.FunctionComponent = () => {
    const { data } = useSensorsByModulesData([ModuleType.AIR]);
    const [state, dispatch] = useReducer(airStatisticsReducer, initialState);
    const { selectedSensors, selectedSpace, airSpaces, airSpacesCount, airMetric, airMetricQuality, suggestions } =
        state;

    const { data: metricData, isLoading: metricIsLoading } = useAirMetricData(airMetric, airMetricQuality);

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'INIT_SENSORS',
                sensorData: data[0],
            });
        }
    }, [data]);

    useEffect(() => {
        if (metricData) {
            dispatch({
                type: 'SET_SPACES_DATA',
                spaces: metricData.overview,
                spacesCount: metricData.overviewCount,
            });
        }
    }, [metricData]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsAir}
                suggestions={suggestions}
                onSearch={selectedSpace =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedSpace: selectedSpace,
                    })
                }
            />

            <AirSensorWrapper collapsed={Boolean(selectedSensors.length)}>
                <>
                    {metricIsLoading ? (
                        <LoadingOverlay />
                    ) : (
                        <SensorSelectorAir
                            airSpaces={airSpaces}
                            airSpacesCount={airSpacesCount}
                            metricType={airMetric}
                            selectedSpace={selectedSpace}
                            onSelectSpace={selectedSpace => {
                                dispatch({
                                    type: 'SELECT_SPACE',
                                    space: selectedSpace,
                                });
                            }}
                        />
                    )}
                    <AirStatisticsSideMenu
                        metricAction={airMetric}
                        qualityAction={airMetricQuality}
                        onSelectMetricAction={airMetric => {
                            dispatch({
                                type: 'SELECT_AIR_METRIC_FILTER',
                                airMetricAction: airMetric,
                            });
                        }}
                        onSelectMetricQualityAction={airMetricQuality => {
                            dispatch({
                                type: 'SELECT_AIR_METRIC_QUALITY_FILTER',
                                airMetricQualityAction: airMetricQuality,
                            });
                        }}
                    />
                </>
            </AirSensorWrapper>

            {Boolean(selectedSensors.length) && (
                <AirSensorDataPane
                    sensors={selectedSensors}
                    metric={airMetric}
                    buildingId={selectedSpace.building}
                    roomId={selectedSpace.lowLevelRoom || selectedSpace.midLevelRoom || selectedSpace.highLevelRoom}
                />
            )}
        </>
    );
};

export default AirStatisticsView;

const initialState: AirSensorOverviewState = {
    suggestions: [],
    sensors: [],
    selectedSensors: [],
    airSpaces: [],
    airSpacesCount: { InSufficient: 0, Moderate: 0, Sufficient: 0 },
    selectedSpace: { building: '', highLevelRoom: '', midLevelRoom: '', lowLevelRoom: '' },
    airMetric: AirMetricsAction.TEMPERATURE,
    airMetricQuality: AirMetricsQualityAction.ANY,
};

const airStatisticsReducer = (state: AirSensorOverviewState, action: AirSensorListAction): AirSensorOverviewState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorData = filterOutIlluminanceSensors(action.sensorData);

            return {
                ...state,
                sensors: sensorData,
                selectedSensors: [],
            };
        }
        case 'SET_SPACES_DATA': {
            const filteredBuildingData = action.spaces.filter(space => space.averageKpi);

            filteredBuildingData.forEach(recursiveFilterRooms);

            const building = filteredBuildingData.find(kpi => kpi.id === state.selectedSpace.building);
            const highLevelRoom = building?.rooms.find(kpi => kpi.id === state.selectedSpace.highLevelRoom);
            const midLevelRoom = highLevelRoom?.rooms.find(kpi => kpi.id === state.selectedSpace.midLevelRoom);
            const lowLevelRoom = midLevelRoom?.rooms.find(kpi => kpi.id === state.selectedSpace.lowLevelRoom);

            const suggestions: SpaceSuggestion[] = [];
            filteredBuildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);

                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            return {
                ...state,
                suggestions,
                airSpaces: filteredBuildingData,
                airSpacesCount: action.spacesCount,
                selectedSpace: {
                    building: building?.id || '',
                    highLevelRoom: highLevelRoom?.id || '',
                    midLevelRoom: midLevelRoom?.id || '',
                    lowLevelRoom: lowLevelRoom?.id || '',
                },
                selectedSensors: state.sensors.filter(
                    s => s.roomRef === (lowLevelRoom?.id || midLevelRoom?.id || highLevelRoom?.id)
                ),
            };
        }
        case 'SELECT_SPACE': {
            return {
                ...state,
                selectedSensors: state.sensors.filter(
                    s =>
                        s.roomRef ===
                        (action.space.lowLevelRoom || action.space.midLevelRoom || action.space.highLevelRoom)
                ),
                selectedSpace: action.space,
            };
        }
        case 'SELECT_AIR_METRIC_FILTER': {
            return {
                ...state,
                airMetric: action.airMetricAction,
            };
        }
        case 'SELECT_AIR_METRIC_QUALITY_FILTER': {
            return {
                ...state,
                airMetricQuality: action.airMetricQualityAction,
            };
        }
        case 'SEARCH_SENSOR': {
            const selectedSpace = {
                building: action.selectedSpace.ids[0] || '',
                highLevelRoom: action.selectedSpace.ids[1] || '',
                midLevelRoom: action.selectedSpace.ids[2] || '',
                lowLevelRoom: action.selectedSpace.ids[3] || '',
            };
            return {
                ...state,
                selectedSpace,
                selectedSensors: state.sensors.filter(
                    s =>
                        s.roomRef ===
                        (selectedSpace.lowLevelRoom || selectedSpace.midLevelRoom || selectedSpace.highLevelRoom)
                ),
            };
        }
    }
};

const filterOutIlluminanceSensors = (sensors: Sensor[]) => {
    // filter out illuminance, since they have no kpi's
    sensors = sensors.filter(
        x => x.type === SensorType.TEMPERATURE || x.type === SensorType.HUMIDITY || x.type === SensorType.CO2
    );
    return sensors;
};

const recursiveCreateSuggestions = (
    spaces: KPIOverview[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        const i = [...ids];
        i.push(r.id);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.rooms, suggestions, i, l);
    });
};

const recursiveFilterRooms = (room: KPIOverview) => {
    if (room.rooms.length === 0) {
        return;
    }
    room.rooms = room.rooms.filter(room => room.averageKpi);

    room.rooms.forEach(recursiveFilterRooms);
};
