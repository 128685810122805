import React from 'react';

import { FacilitiesBarWrapper, PaneIconBarWrapper, SubBarWrapperBlack } from 'styled/components/navigation';
import { DataPaneIcon, MenuIcon } from 'styled/components/buttons';
import { H3White } from 'styled/components/text';
import { TitleWrapper, IconWrapper } from 'styled/components/dataPane';
import barChartIcon from '../../assets/icons/icons-barchart.svg';
import lineChartIcon from '../../assets/icons/icons-linechart.svg';

interface DataPaneTitleMenuProps {
    icon: string;
    title: string;
    canToggleGraph?: boolean;
    isBarChart?: boolean;
    onToggleGraph?: (expand: boolean) => void;
}

const DataPaneTitleMenu: React.FunctionComponent<DataPaneTitleMenuProps> = ({ icon, title, canToggleGraph, isBarChart, onToggleGraph, }) => {
    return (
        <SubBarWrapperBlack>
            <TitleWrapper>
                <IconWrapper>
                    <DataPaneIcon $disabled={false} $active={true} src={icon} />
                </IconWrapper>
                <H3White>{title}</H3White>
            </TitleWrapper>
            <FacilitiesBarWrapper />

            {canToggleGraph && (
                <PaneIconBarWrapper onClick={() => onToggleGraph && onToggleGraph(!isBarChart)}>
                    <MenuIcon $disabled={false} $active={true} src={isBarChart ? lineChartIcon : barChartIcon} />
                </PaneIconBarWrapper>
            )}
        </SubBarWrapperBlack>
    );
};

export default DataPaneTitleMenu;
