import {
    addDays,
    addHours,
    endOfHour,
    endOfDay,
    format,
    getUnixTime,
    isAfter,
    startOfDay,
    startOfHour,
    isToday,
    subDays,
    subWeeks,
    subMonths,
    addWeeks,
} from 'date-fns';
import { theme } from 'styled/Theme';

import { BarConfig, BarConfigFeedback, SensorValue, TickRange } from 'components/Graph/types';
import { SensorAverage, KpiUnit, SensorData, SensorType } from 'types';

import { DateRange, DateSpan, isDaysTimespan, isWeeksTimespan } from 'utils/timePeriod';

export const normalizedTickValues = [0.2, 0.4, 0.6, 0.8, 1];
export const normalizedNegativeTickValues = [-1, -0.8, -0.6, -0.4, -0.2, 0, 0.2, 0.4, 0.6, 0.8, 1];

export const mapAverageByHourOfDayOfWeek = (d: SensorValue, a: SensorAverage[]): SensorValue => {
    const hour = new Date(d.timestamp * 1000).getUTCHours();
    const day = new Date(d.timestamp * 1000).getDay();
    const dayOfWeek = day === 0 ? 7 : day;
    const hourOfDayOfWeek = `${dayOfWeek}.${('0' + hour).slice(-2)}`;

    return {
        value: a.find(e => e.field === hourOfDayOfWeek)?.value || 0,
        timestamp: d.timestamp,
    };
};

export const mapAverageByDayOfWeek = (d: SensorValue, a: SensorAverage[]) => {
    const day = new Date(d.timestamp * 1000).getDay();
    const dayOfWeek = day === 0 ? 7 : day;

    return {
        value: a.find(e => e.field === dayOfWeek.toString())?.value || 0,
        timestamp: d.timestamp,
    };
};

export const mapAverageByDayOfMonth = (d: SensorValue, a: SensorAverage[]) => {
    const dayOfMonth = new Date(d.timestamp * 1000).getDate();
    const matchingAverage = a.find(e => {
        return parseInt(e.field) === dayOfMonth;
    });
    return {
        value: matchingAverage?.value || 0,
        timestamp: d.timestamp,
    };
};

export const deduplicateValues = (sensorData: SensorValue[], timeFormat: string) => {
    const skipIfAlreadyFound = (sensors: SensorValue[], sensor: SensorValue) => {
        const currentSensorTimestamp = format(new Date(sensor.timestamp * 1000), timeFormat);
        const lastSensorTimestamp = sensors.length
            ? format(new Date(sensors[sensors.length - 1].timestamp * 1000), timeFormat)
            : '';

        return currentSensorTimestamp === lastSensorTimestamp ? sensors : sensors.concat(sensor);
    };
    const deduplicatedSensorValues = sensorData.reduce(skipIfAlreadyFound, []);

    return deduplicatedSensorValues;
};

const determineIncrementStep = (timespan: string): ((tick: Date) => Date) => {
    if (isDaysTimespan(timespan)) return (tick: Date) => addHours(tick, 1);
    if (isWeeksTimespan(timespan)) return (tick: Date) => addDays(tick, 1);
    return (tick: Date) => addWeeks(tick, 1);
};

export const createIncidentTickValues = (from: string, to: string, timespan: string): number[] => {
    const lastTickTimestamp = isDaysTimespan(timespan) ? endOfHour(new Date(to)) : endOfDay(new Date(to));
    const tickValues: number[] = [];

    let tickTimestamp = isDaysTimespan(timespan) ? startOfHour(new Date(from)) : startOfDay(new Date(from));
    while (isAfter(lastTickTimestamp, tickTimestamp)) {
        tickValues.push(getUnixTime(tickTimestamp));
        tickTimestamp = isDaysTimespan(timespan) ? addHours(tickTimestamp, 1) : addDays(tickTimestamp, 1);
    }
    return tickValues;
};

export const createTickValues = (action: string, timespan: string, customDate: DateRange): number[] => {
    const tickValues: number[] = [];
    const firstLastTick = getFirstLastTick(action, customDate);
    const { lastTick } = firstLastTick;
    let { firstTick } = firstLastTick;
    const incrementStep = determineIncrementStep(timespan);

    while (isAfter(lastTick, firstTick)) {
        tickValues.push(getUnixTime(firstTick));
        firstTick = incrementStep(firstTick);
    }
    return tickValues;
};

const getFirstLastTick = (action: string, customDate: DateRange): TickRange => {
    switch (action) {
        case DateSpan.ONE_DAY:
            return { firstTick: startOfDay(subDays(new Date(), 1)), lastTick: endOfHour(addHours(new Date(), 1)) };
        case DateSpan.TWO_DAYS:
            return { firstTick: startOfDay(subDays(new Date(), 2)), lastTick: endOfHour(addHours(new Date(), 1)) };
        case DateSpan.WEEK:
            return { firstTick: startOfDay(subWeeks(new Date(), 1)), lastTick: endOfDay(addDays(new Date(), 1)) };
        case DateSpan.MONTH:
            return { firstTick: startOfDay(subMonths(new Date(), 1)), lastTick: endOfDay(addDays(new Date(), 1)) };
        case DateSpan.CUSTOM:
            return getCustomFirstLastTick(customDate);
        default:
            return { firstTick: new Date(), lastTick: new Date() };
    }
};

const getCustomFirstLastTick = (date: DateRange): TickRange => {
    const firstTick = startOfDay(new Date(date.from));
    const lastTick = isToday(new Date(date.to)) ? addHours(new Date(), 1) : endOfDay(addDays(new Date(date.to), 1));

    return { firstTick, lastTick };
};

export const calcAxisDates = (ticks: number[]): number[] => {
    const axisArray: number[] = [];

    ticks.forEach(t => (new Date(t * 1000).getHours() === 0 ? axisArray.push(t) : t));

    const firstItemHour = new Date(ticks[0] * 1000).getHours();
    if (firstItemHour === 0) {
        axisArray.shift();
    }

    return axisArray;
};

export const isMapAveragesByDay = (timespan: string, isBarChart: boolean = false) => {
    return isBarChart && timespan === DateSpan.MONTH;
};

export const averageValues = (sensorData: SensorValue[], timespan: string) => {
    const timeFormat = timespan === DateSpan.MONTH ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';
    const clonedArray = sensorData.map(a => {
        return { ...a };
    });
    const counterArray: number[] = [];

    const groupByTimestamp = (sensors: SensorValue[], sensor: SensorValue) => {
        const currentSensorTimestamp = format(new Date(sensor.timestamp * 1000), timeFormat);
        const lastSensorTimestamp = sensors.length
            ? format(new Date(sensors[sensors.length - 1].timestamp * 1000), timeFormat)
            : '';
        if (currentSensorTimestamp === lastSensorTimestamp) {
            const lastSensorIndex = sensors.length - 1;
            sensors[lastSensorIndex].value = sensors[lastSensorIndex].value + sensor.value;
            counterArray[counterArray.length - 1] = counterArray[counterArray.length - 1] + 1;
            return sensors;
        }
        counterArray.push(1);
        return sensors.concat(sensor);
    };

    const averageSensorValues = clonedArray
        .reduce(groupByTimestamp, [])
        .map((sensor, index) => ({ value: sensor.value / counterArray[index], timestamp: sensor.timestamp }));
    return averageSensorValues;
};

export const sumValues = (sensorData: SensorValue[], timespan: string) => {
    const timeFormat = timespan === DateSpan.MONTH ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';
    const clonedArray = sensorData.map(a => {
        return { ...a };
    });

    const groupByTimestamp = (sensors: SensorValue[], sensor: SensorValue) => {
        const currentSensorTimestamp = format(new Date(sensor.timestamp * 1000), timeFormat);
        const lastSensorTimestamp = sensors.length
            ? format(new Date(sensors[sensors.length - 1].timestamp * 1000), timeFormat)
            : '';
        if (currentSensorTimestamp === lastSensorTimestamp) {
            const lastSensorIndex = sensors.length - 1;
            sensors[lastSensorIndex].value = sensors[lastSensorIndex].value + sensor.value;
            return sensors;
        }

        const s = {
            value: sensor.value,
            timestamp:
                timespan === DateSpan.MONTH ? getUnixTime(startOfDay(sensor.timestamp * 1000)) : sensor.timestamp,
        };
        return sensors.concat(s);
    };

    const summedSensorValues = clonedArray.reduce(groupByTimestamp, []);

    return summedSensorValues;
};

export const percentageValues = (sensorData: SensorValue[], timespan: string) => {
    const timeFormat = timespan === DateSpan.MONTH ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';
    const clonedArray = sensorData.map(a => {
        return { ...a };
    });

    const skipIfAlreadyFound = (sensors: SensorValue[], sensor: SensorValue) => {
        const currentSensorTimestamp = format(new Date(sensor.timestamp * 1000), timeFormat);
        const lastSensorTimestamp = sensors.length
            ? format(new Date(sensors[sensors.length - 1].timestamp * 1000), timeFormat)
            : '';
        if (currentSensorTimestamp === lastSensorTimestamp) {
            const lastSensorIndex = sensors.length - 1;
            sensors[lastSensorIndex].value = sensors[lastSensorIndex].value + sensor.value;
            return sensors;
        }
        return sensors.concat(sensor);
    };

    const summedSensorValues = clonedArray
        .reduce(skipIfAlreadyFound, [])
        .map(day => (timespan === DateSpan.MONTH ? { value: day.value / 24, timestamp: day.timestamp } : day));

    return summedSensorValues;
};

export const setBarConfig = (timespan: string): BarConfig => {
    switch (timespan) {
        case DateSpan.ONE_DAY:
            return { spaceBetween: 380, width: 7, radius: 2 };
        case DateSpan.TWO_DAYS:
            return { spaceBetween: 600, width: 7, radius: 2 };
        case DateSpan.WEEK:
            return { spaceBetween: 600, width: 2, radius: 2 };
        case DateSpan.MONTH:
            return { spaceBetween: 8500, width: 7, radius: 3 };
        case DateSpan.YEAR:
            return { spaceBetween: 20000, width: 1.5, radius: 1 };
        default:
            return { spaceBetween: 400, width: 7, radius: 2 };
    }
};

export const setBarConfigFeedback = (timespan: string): BarConfigFeedback => {
    switch (timespan) {
        case DateSpan.WEEK:
            return { spaceOutside: 7000, spaceBetween: 2600, width: 7, radius: 3 };
        case DateSpan.MONTH:
            return { spaceOutside: 28000, spaceBetween: 10000, width: 7, radius: 3 };
        case DateSpan.YEAR:
            return { spaceOutside: 400000, spaceBetween: 150000, width: 7, radius: 3 };
        default:
            return { spaceOutside: 7000, spaceBetween: 2600, width: 7, radius: 3 };
    }
};

export const formatXAxisTickValue = (
    t: number,
    index: number,
    ticks: number[],
    tickAmount: number,
    tickFormat: string
) => (index % tickAmount !== 0 || ticks.length - 1 === index ? '' : format(new Date(t * 1000), tickFormat));

export const formatXAxisIncidentTickValue = (t: number, index: number, tickAmount: number, tickFormat: string) =>
    index % tickAmount !== 0 ? '' : format(new Date(t * 1000), tickFormat);

export const setBarConfig3Axis = (timespan: string): BarConfig => {
    switch (timespan) {
        case DateSpan.ONE_DAY:
            return { spaceBetween: 700, width: 5, radius: 2 };
        case DateSpan.TWO_DAYS:
            return { spaceBetween: 800, width: 3.5, radius: 2 };
        case DateSpan.WEEK:
            return { spaceBetween: 1000, width: 1, radius: 1 };
        case DateSpan.MONTH:
            return { spaceBetween: 15000, width: 5, radius: 2 };
        case DateSpan.YEAR:
            return { spaceBetween: 20000, width: 1.5, radius: 1 };
        default:
            return { spaceBetween: 400, width: 5, radius: 2 };
    }
};

export const getMaxSensorValue = (sensorData: SensorValue[]) => {
    return Math.max(...sensorData.map(d => d.value));
};

const getLargestSensorValueToPositive = (sensorData: SensorValue[]) => {
    const max = Math.max(...sensorData.map(d => d.value));
    const min = Math.abs(Math.min(...sensorData.map(d => d.value)));
    return max > min ? max : min;
};

export const normalizeYValue = (y: number, maxSensorValue: number) => {
    return maxSensorValue === 0 ? 0 : y / maxSensorValue;
};

export const formatTick = (t: number, i: number, sensorData: SensorValue[], unit?: string) => {
    const maxSensorValue = getMaxSensorValue(sensorData);
    const decimal = maxSensorValue > 10 ? 0 : 1;
    if (!sensorData.length) {
        return i === normalizedTickValues.length - 1 ? unit : 0;
    }
    return i === normalizedTickValues.length - 1 && unit ? unit : (t * maxSensorValue).toFixed(decimal);
};

export const formatTemperatureTick = (t: number, i: number, sensorData: SensorValue[], unit?: string) => {
    const maxSensorValue = getLargestSensorValueToPositive(sensorData);
    const decimal = maxSensorValue > 10 ? 0 : 1;
    if (!sensorData.length) {
        return i === normalizedTickValues.length - 1 ? unit : 0;
    }
    if (sensorData.find(d => d.value < 0)) {
        return i === normalizedNegativeTickValues.length - 1 && unit ? unit : (t * maxSensorValue).toFixed(decimal);
    }
    return i === normalizedTickValues.length - 1 && unit ? unit : (t * maxSensorValue).toFixed(decimal);
};

export const formatWaterTick = (t: number, i: number, sensorData: SensorValue[]) => {
    return i === normalizedTickValues.length - 1 ? KpiUnit.L : (1000 * t * getMaxSensorValue(sensorData)).toFixed();
};

export const getNormalizedTemperatureTick = (sensorData: SensorValue[]) => {
    return sensorData.find(d => d.value < 0) ? normalizedNegativeTickValues : normalizedTickValues;
};

export const mapSensorValue = (sensor: SensorAverage | SensorData): SensorValue => {
    return {
        value: sensor.value,
        timestamp: getUnixTime(new Date(date(sensor))),
    };
};

export const mapSensorValueWithLabel = (sensor: SensorAverage | SensorData, label: string): SensorValue => {
    return {
        name: label,
        value: sensor.value,
        timestamp: getUnixTime(new Date(date(sensor))),
    };
};

export const getMin = (data: SensorValue[]) => {
    return data.length ? data.reduce((prev, curr) => (prev.value < curr.value ? prev : curr)).value : 0;
};

export const getMax = <T extends { value: number }>(data: T[]): number => {
    return data.length ? data.reduce((prev, curr) => (prev.value > curr.value ? prev : curr)).value : 0;
};

const date = (timeserie: SensorData | SensorAverage) => {
    if ('time' in timeserie) {
        return timeserie.time;
    }
    return timeserie.field;
};

export const getLineColor = (type: SensorType, min: number, max: number) => {
    switch (type) {
        case SensorType.CCQ:
            return getColorGradientScore(min, max, CCQ.minimum, CCQ.good);
        case SensorType.TEMP:
        case SensorType.TEMPERATURE: {
            return getColorGradientMinMax(
                min,
                max,
                TEMPERATURE.minGood,
                TEMPERATURE.maxGood,
                TEMPERATURE.minAverage,
                TEMPERATURE.maxAverage
            );
        }
        case SensorType.HUMIDITY: {
            return getColorGradientMinMax(
                min,
                max,
                HUMIDITY.minGood,
                HUMIDITY.maxGood,
                HUMIDITY.minAverage,
                HUMIDITY.maxAverage
            );
        }
        case SensorType.CO2: {
            return getColorGradient(min, max, CO2.maxGood, CO2.maxAverage);
        }
        case SensorType.BATTERY_VOLTAGE:
            return theme.colors.mCleanL;
        case SensorType.ILLUMINANCE:
            return theme.colors.mEnergyL;
        case SensorType.WATER:
            return theme.colors.mWater;
        case SensorType.MOVEMENT:
        case SensorType.MOTION:
            return theme.colors.mOccup;
        case SensorType.CURRENT:
        case SensorType.CURRENT_INDEX:
        case SensorType.ELECTRICITY:
            return theme.colors.mEnergy;
        case SensorType.HEATING:
            return theme.colors.mOccup;
        default:
            return theme.colors.grey;
    }
};

const getColorGradientMinMax = (
    min: number,
    max: number,
    minGood: number,
    maxGood: number,
    minAverage: number,
    maxAverage: number
) => {
    // Purple
    if (max <= maxGood && min >= minGood) return theme.colors.air;
    // Red
    if (max <= minAverage) return theme.colors.negative;
    // Red
    if (min >= maxAverage) return theme.colors.negative;
    // Yellow
    if (min >= maxGood && max <= maxAverage) return theme.colors.mEnergy;
    // Yellow
    if (min >= minAverage && max <= minGood) return theme.colors.mEnergy;
    // Red Yellow
    if (max >= maxAverage && min >= maxGood && min <= maxAverage) return 'url(#lineGradientRedYellow)';
    // Yellow Red
    if (min <= minAverage && max <= minGood && max >= minAverage) return 'url(#lineGradientYellowRed)';
    // Yellow Purple Yellow
    if (max >= maxGood && max <= maxAverage && min <= minGood && min >= minAverage)
        return 'url(#lineGradientYellowPurpleYellow)';
    // Yellow Purple
    if (min >= minGood && min <= maxGood && max >= maxGood && max <= maxAverage)
        return 'url(#lineGradientYellowPurple)';
    // Purple Yellow
    if (max >= minGood && max <= maxGood && min <= minGood && min >= minAverage)
        return 'url(#lineGradientPurpleYellow)';
    // Red Yellow Purple
    if (min >= minGood && min <= maxGood && max >= maxAverage) return 'url(#lineGradientRedYellowPurple)';
    // Purple Yellow Red
    if (max >= minGood && max <= maxGood && min <= minAverage) return 'url(#lineGradientPurpleYellowRed)';
    // Red Yellow Purple Yellow
    if (max >= maxAverage && min <= minGood && min >= minAverage) return 'url(#lineGradientRedYellowPurpleYellow)';
    // Yellow Purple Yellow Red
    if (max >= maxGood && max <= maxAverage && min <= minAverage) return 'url(#lineGradientYellowPurpleYellowRed)';
    // Red Yellow Purple Yellow Red
    if (max >= maxAverage && min <= minAverage) return 'url(#lineGradientRedYellowPurpleYellowRed)';
};

const getColorGradient = (min: number, max: number, maxGood: number, maxAverage: number) => {
    // Red
    if (min >= maxAverage) return theme.colors.negative;
    // Purple
    if (max <= maxGood) return theme.colors.air;
    // Yellow
    if (min >= maxGood && max <= maxAverage) return theme.colors.mEnergyL;
    // Red Yellow
    if (min >= maxGood && min <= maxAverage && max >= maxAverage) return 'url(#lineGradientRedYellow)';
    // Yellow Purple
    if (min <= maxGood && max >= maxGood && max <= maxAverage) return 'url(#lineGradientYellowPurple)';
    // Red Yellow Purple
    if (min <= maxGood && max >= maxAverage) return 'url(#lineGradientRedYellowPurple)';
};

const getColorGradientScore = (min: number, max: number, minimum: number, good: number) => {
    // Red
    if (min >= good) return theme.colors.air;
    // Purple
    if (max < minimum) return theme.colors.negative;
    // Yellow
    if (min >= minimum && max < good) return theme.colors.mEnergyL;
    // Yellow Purple
    if (min >= minimum && min < good && max >= good) return 'url(#lineGradientPurpleYellow)';
    // Red Yellow
    if (min < minimum && max >= minimum && max < good) return 'url(#lineGradientRedYellow)';
    // Red Yellow Purple
    if (min < minimum && max >= good) return 'url(#lineGradientPurpleYellowRed)';
};

export const HUMIDITY = {
    maxAverage: 70,
    maxGood: 60,
    minGood: 40,
    minAverage: 30,
};

export const TEMPERATURE = {
    maxAverage: 24,
    maxGood: 22,
    minGood: 20,
    minAverage: 18,
};

export const CO2 = {
    maxAverage: 1200,
    maxGood: 800,
};

export const CCQ = {
    good: 8,
    minimum: 5.5,
};

export const getBarColor = (type: SensorType, value: number) => {
    switch (type) {
        case SensorType.CCQ:
            return getScoreColor(value);
        case SensorType.TEMP:
        case SensorType.TEMPERATURE: {
            return getColorMinMax(
                value,
                TEMPERATURE.minGood,
                TEMPERATURE.maxGood,
                TEMPERATURE.minAverage,
                TEMPERATURE.maxAverage
            );
        }
        case SensorType.HUMIDITY: {
            return getColorMinMax(value, HUMIDITY.minGood, HUMIDITY.maxGood, HUMIDITY.minAverage, HUMIDITY.maxAverage);
        }
        case SensorType.CO2: {
            return getColor(value, CO2.maxGood, CO2.maxAverage);
        }
        case SensorType.BATTERY_VOLTAGE:
            return theme.colors.mCleanL;
        case SensorType.ILLUMINANCE:
            return theme.colors.mEnergyL;
        case SensorType.WATER:
            return theme.colors.mWater;
        case SensorType.MOVEMENT:
        case SensorType.MOTION:
            return theme.colors.mOccup;
        case SensorType.ELECTRICITY:
            return theme.colors.mEnergy;
        default:
            return theme.colors.grey;
    }
};

const getScoreColor = (value: number) => {
    // Red
    if (value >= CCQ.good) return theme.colors.air;
    // Purple
    else if (value < CCQ.minimum) return theme.colors.negative;
    // Yellow
    return theme.colors.mEnergyL;
};

const getColorMinMax = (value: number, minGood: number, maxGood: number, minAverage: number, maxAverage: number) => {
    // Purple
    if (value <= maxGood && value >= minGood) return theme.colors.air;
    // Red
    if (value <= minAverage || value >= maxAverage) return theme.colors.negative;
    // Yellow
    return theme.colors.mEnergy;
};

const getColor = (value: number, maxGood: number, maxAverage: number) => {
    // Red
    if (value >= maxAverage) return theme.colors.negative;
    // Purple
    if (value <= maxGood) return theme.colors.air;
    // Yellow
    return theme.colors.mEnergyL;
};

export const getAvgLineColor = (type: SensorType) => {
    switch (type) {
        case SensorType.TEMP:
        case SensorType.TEMPERATURE:
        case SensorType.HUMIDITY:
        case SensorType.CO2:
            return theme.colors.airLTransparent;
        case SensorType.ILLUMINANCE:
            return theme.colors.mOccupL;
        case SensorType.CURRENT:
        case SensorType.CURRENT_INDEX:
        case SensorType.ELECTRICITY:
            return theme.colors.mEnergyL;
        case SensorType.HEATING:
            return theme.colors.mOccupL;
        case SensorType.WATER:
            return theme.colors.mWaterL;
        case SensorType.MOVEMENT:
        case SensorType.MOTION:
            return theme.colors.mOccupL;
        default:
            return theme.colors.airLTransparent;
    }
};
