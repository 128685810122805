import React from 'react';

import { useTranslation } from 'react-i18next';

import EBSTitleMenu from 'components/DataPane/ebs/EBSTitleMenu';
import HolidaysDataPane from 'components/DataPane/ebs/HolidaysDataPane';
import SeasonDataPane from 'components/DataPane/ebs/SeasonDataPane';
import EBSTextArea from './EBSTextArea';

import { ContentContainer, EBSWizardContent, PageBreak, DisplayOnPrint } from 'styled/components/ebs';
import { BodyLeftWhite } from 'styled/components/text';

import { SensorAverage, SensorType } from 'types';
import { ExplanatoryText } from './types';

interface Props {
    consumptionData: SensorAverage[];
    explanatoryText: ExplanatoryText;
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const GasView: React.FunctionComponent<Props> = ({ consumptionData, explanatoryText, onChangeText }) => {
    const { t } = useTranslation();

    return (
        <>
            <ContentContainer>
                <EBSTitleMenu title={t('energy.ebs.gas.title')} />
                {consumptionData.length ? (
                    <>
                        <SeasonDataPane
                            title={t('energy.ebs.gas.winter')}
                            data={consumptionData}
                            week={4}
                            sensorType={SensorType.GAS}
                        >
                            <EBSTextArea
                                text={explanatoryText.gasWinter}
                                name={'gasWinter'}
                                label={t('energy.ebs.textarea.addAnalysis')}
                                onChangeText={onChangeText}
                            />
                        </SeasonDataPane>
                        <SeasonDataPane
                            title={t('energy.ebs.gas.spring')}
                            data={consumptionData}
                            week={17}
                            sensorType={SensorType.GAS}
                        >
                            <EBSTextArea
                                text={explanatoryText.gasSpring}
                                name={'gasSpring'}
                                label={t('energy.ebs.textarea.addAnalysis')}
                                onChangeText={onChangeText}
                            />
                        </SeasonDataPane>

                        <PageBreak />
                        <DisplayOnPrint>
                            <EBSTitleMenu title={t('energy.ebs.gas.title')} />
                        </DisplayOnPrint>
                        <SeasonDataPane
                            title={t('energy.ebs.gas.summer')}
                            data={consumptionData}
                            week={32}
                            sensorType={SensorType.GAS}
                        >
                            <EBSTextArea
                                text={explanatoryText.gasSummer}
                                name={'gasSummer'}
                                label={t('energy.ebs.textarea.addAnalysis')}
                                onChangeText={onChangeText}
                            />
                        </SeasonDataPane>
                        <SeasonDataPane
                            title={t('energy.ebs.gas.autumn')}
                            data={consumptionData}
                            week={45}
                            sensorType={SensorType.GAS}
                        >
                            <EBSTextArea
                                text={explanatoryText.gasAutumn}
                                name={'gasAutumn'}
                                label={t('energy.ebs.textarea.addAnalysis')}
                                onChangeText={onChangeText}
                            />
                        </SeasonDataPane>

                        <PageBreak />
                        <DisplayOnPrint>
                            <EBSTitleMenu title={t('energy.ebs.gas.title')} />
                        </DisplayOnPrint>
                        <HolidaysDataPane data={consumptionData} title={t('energy.ebs.gas.holidays')}>
                            <EBSTextArea
                                text={explanatoryText.gasHolidays}
                                name={'gasHolidays'}
                                label={t('energy.ebs.textarea.analysis')}
                                onChangeText={onChangeText}
                            />
                        </HolidaysDataPane>
                    </>
                ) : (
                    <EBSWizardContent>
                        <BodyLeftWhite>{t('energy.ebs.gas.noGasData')}</BodyLeftWhite>
                    </EBSWizardContent>
                )}
            </ContentContainer>
        </>
    );
};

export default GasView;
