import React from 'react';

import { useTranslation } from 'react-i18next';
import { SubMenuButton } from 'styled/components/buttons';

import { StyledErrorMessage } from 'styled/components/errorBoundary';

import { ErrorCode } from './types';

interface Props {
    onReturn: () => void;
}
const NotFound: React.FunctionComponent<Props> = ({ onReturn }) => {
    const { t } = useTranslation();

    return (
        <StyledErrorMessage>
            <h1>{ErrorCode.NOT_FOUND}</h1>
            <h2>{t('errorBoundary.notFound.title')}</h2>
            <p>{t('errorBoundary.notFound.text')}</p>
            <p>{t('errorBoundary.notFound.supportText')}</p>
            <SubMenuButton active={true} onClick={onReturn}>
                {t('errorBoundary.homeButton')}
            </SubMenuButton>
        </StyledErrorMessage>
    );
};

export default NotFound;
