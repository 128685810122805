import React from 'react';

import { useTranslation } from 'react-i18next';

import { H3White } from 'styled/components/text';

const ErrorMessage: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return <H3White>{t('datapane.error')}</H3White>;
};

export default ErrorMessage;
