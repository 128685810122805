import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
    BreadCrumbsWrapper,
    SearchWrapper,
    SearchInput,
    Wrapper,
    RemoveInputIcon,
} from 'styled/components/searchAutoComplete';

import { useTranslation } from 'react-i18next';

import Suggestions from './Suggestions';

import useOnClickOutside from 'hooks/useOnClickOutside';

import { IconBarWrapper, SearchBarWrapper } from 'styled/components/navigation';
import { MenuIcon } from 'styled/components/buttons';

import searchIcon from '../../assets/icons/i-search.svg';
import closeIcon from '../../assets/icons/i-close.svg';

export interface SpaceSuggestion {
    name: string;
    ids: string[];
    location: string[];
}

interface SearchAutoCompleteProps {
    suggestions: SpaceSuggestion[];
    onSearch: (suggestion: SpaceSuggestion) => void;
    onClearSearch?: () => void;
    breadCrumbs?: string[];
}
const SearchAutoComplete: React.FunctionComponent<SearchAutoCompleteProps> = ({
    suggestions,
    onSearch,
    onClearSearch,
    breadCrumbs = [],
}) => {
    const [showSearch, setShowSearch] = useState(false);
    const [filteredSuggestions, setFilteredSuggestions] = useState<Array<SpaceSuggestion>>([]);
    const [activSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState('');
    const componentRef = useRef(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const handleClickOutside = useCallback(() => {
        setShowSuggestions(false);
    }, []);

    useOnClickOutside(componentRef, handleClickOutside);

    const filterSuggestions = useCallback(
        (input: string) => {
            const unLinked = suggestions.filter(
                suggestion => suggestion.name.toLowerCase().indexOf(input.toLowerCase()) > -1
            );
            setFilteredSuggestions(unLinked);
        },
        [suggestions]
    );

    const onChange = useCallback(
        (e: React.FormEvent<HTMLInputElement>) => {
            const userInput = e.currentTarget.value;

            filterSuggestions(userInput);
            setInput(userInput);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
        },
        [filterSuggestions]
    );

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const suggestionsLength = filteredSuggestions.length;
        if (e.key === 'Enter') {
            if (!suggestionsLength || !input) {
                return;
            }
            e.currentTarget.blur();
            onSelectSuggestion(activSuggestionIndex);
        } else if (e.code === 'ArrowUp') {
            setActiveSuggestionIndex(value => (value === 0 ? value : value - 1));
        } else if (e.code === 'ArrowDown') {
            setActiveSuggestionIndex(value => (value === suggestionsLength - 1 ? value : value + 1));
        }
    };

    const onSelectSuggestion = useCallback(
        (index: number) => {
            onSearch(filteredSuggestions[index]);
            setShowSuggestions(false);
            setInput(filteredSuggestions[index].name);
            filterSuggestions(filteredSuggestions[index].name);
        },
        [onSearch, filterSuggestions, filteredSuggestions]
    );

    const resetSearch = useCallback(() => {
        setInput('');
        inputRef.current?.focus();
        onClearSearch && onClearSearch();
    }, [onClearSearch]);

    useEffect(() => {
        resetSearch();
        setShowSearch(false);
    }, [suggestions]);

    return (
        <Wrapper>
            {showSearch ? (
                <SearchBarWrapper ref={componentRef}>
                    <SearchWrapper>
                        <SearchInput
                            placeholder={t('search.placeholder')}
                            ref={inputRef}
                            type="text"
                            onChange={onChange}
                            onKeyDown={onKeyDown}
                            value={input}
                            onFocus={() => {
                                setShowSuggestions(true);
                            }}
                            autoFocus={true}
                        />
                        {input && <RemoveInputIcon src={closeIcon} onClick={resetSearch} />}
                    </SearchWrapper>
                    {showSuggestions && input && (
                        <Suggestions
                            suggestionIndex={activSuggestionIndex}
                            filteredSuggestions={filteredSuggestions}
                            onSelectSuggestion={onSelectSuggestion}
                        />
                    )}
                </SearchBarWrapper>
            ) : (
                <BreadCrumbsWrapper>{breadCrumbs.join('\xa0\xa0>\xa0\xa0')}</BreadCrumbsWrapper>
            )}
            <IconBarWrapper
                active={showSearch}
                onClick={() => {
                    showSearch && onClearSearch && onClearSearch();
                    setShowSearch(!showSearch);
                }}
            >
                <MenuIcon $disabled={false} $active={showSearch} src={searchIcon} />
            </IconBarWrapper>
        </Wrapper>
    );
};

export default SearchAutoComplete;
