import styled from 'styled-components';
import { theme } from 'styled/Theme';

import hexagonBackground from '../../assets/icons/cubes-tile-repeat.svg';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    background-image: url(${hexagonBackground});
`;

export const MainView = styled.div`
    background-color: ${theme.colors.bg};
    display: flex;
    flex: 1;
    border-left: 1px solid ${theme.colors.grey};
    margin-left: 160px;
    flex-direction: column;
`;

export const ProfileTopView = styled(MainView)`
    padding: 30px 49px 30px 49px;
`;

export const DashboardContainer = styled.div`
    padding: 30px;
    flex-direction: column;
    flex: 1;
    background: ${theme.colors.text};
    display: flex;
`;

export const FooterWrapper = styled.div`
    min-height: 80px;
    display: flex;
    align-items: center;
    border-top: 1px solid ${theme.colors.greyD};
    background-color: ${theme.colors.text};
    color: ${theme.colors.white};
`;

export const LoginWrapper = styled.div`
    margin: 160px 0px 0px 50px;
    width: 420px;
`;

export const ProfileContentWrapper = styled.div`
    margin: 30px 50px;
    width: 420px;
`;

export const ManagementContentWrapper = styled.div`
    width: 420px;
`;

export const CompanyContentWrapper = styled.div`
    margin: 30px 50px;
    width: 600px;
`;

export const TitleWrapper = styled.div`
    margin: 15px 0 0 30px;
`;

export const PanelsWrapper = styled.div`
    display: flex;
    border-left: solid 1px ${theme.colors.grey};
`;

export const RadioButtonsPanelWrapper = styled.div`
    display: flex;
    margin-left: 30px;
    div {
        p {
            margin-top: 16px;
        }
    }
`;
