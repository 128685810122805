import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';
import MainWrapper from 'components/Container/MainWrapper';
import ProfileSubMenu from 'components/Submenu/ProfileSubMenu';
import TextInput from 'components/TextInput';

import { zxcvbn, ZxcvbnOptions } from '@zxcvbn-ts/core';
import zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import zxcvbnNlBePackage from '@zxcvbn-ts/language-nl-be';

import { CaptionLeft, CaptionLeftNegative, H2White, H3Bold, LeadLeft } from 'styled/components/text';
import {
    Strong,
    Fair,
    Poor,
    ProfileFullName,
    ProfileNameCircle,
    ProfileNameWrapper,
    PasswordStrengthWrapper,
    PasswordStrengtLabel,
} from 'styled/components/profile';
import { ProfileContentWrapper } from 'styled/components/view';
import { SubmitButton } from 'styled/components/buttons';

import { useUser } from 'context/UserContext';
import { usePasswordMutation } from 'hooks/api/mutate/usePasswordMutation';

import { config } from '../../../config';

interface ResetPasswordValidationErrors {
    passwordCurrent: string;
    passwordNew: string;
    passwordNewValidate: string;
    passwordCheck: string;
    passwordStrength: string;
}

const MeView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { userState } = useUser();
    const { mutate, isLoading } = usePasswordMutation();

    const [passwordReset, setPasswordReset] = useState(false);
    const [email, setEmail] = useState(userState?.email || '');
    const [passwordScore, setPasswordScore] = useState(0);
    const [passwordCurrent, setPasswordCurrent] = useState('');
    const [passwordNew, setPasswordNew] = useState('');
    const [passwordNewValidate, setPasswordNewValidate] = useState('');
    const [validationErrors, setValidationErrors] = useState<ResetPasswordValidationErrors>({
        passwordCurrent: '',
        passwordNew: '',
        passwordNewValidate: '',
        passwordCheck: '',
        passwordStrength: '',
    });
    const [errorMessage, setErrorMessage] = useState('');

    const options = {
        translations: zxcvbnNlBePackage.translations,
        dictionary: {
            ...zxcvbnCommonPackage.dictionary,
            ...zxcvbnNlBePackage.dictionary,
        },
    };
    ZxcvbnOptions.setOptions(options);

    useEffect(() => {
        const getPasswordScore = async () => {
            const result = await zxcvbn(passwordNew);

            setPasswordScore(result.score);
        };

        getPasswordScore();
    }, [passwordNew]);

    const validateForm = () => {
        const errors = getValidationErrors();

        setValidationErrors(errors);

        return Object.values(errors).filter(e => e !== '').length === 0;
    };

    const resetPassword = () => {
        if (!passwordReset || validateForm()) {
            setPasswordReset(!passwordReset);
        }
    };

    const getValidationErrors = () => {
        const errors: ResetPasswordValidationErrors = {
            passwordCurrent: '',
            passwordNew: '',
            passwordNewValidate: '',
            passwordCheck: '',
            passwordStrength: '',
        };
        if (!passwordCurrent || !passwordNew || !passwordNewValidate) {
            errors.passwordCurrent = passwordCurrent ? '' : t('login.required');
            errors.passwordNew = passwordNew ? '' : t('login.required');
            errors.passwordNewValidate = passwordNewValidate ? '' : t('login.required');
        }
        if (passwordNew && passwordScore < 3) {
            errors.passwordStrength = t('login.poorPasswordError');
        }
        if (passwordNew && passwordNewValidate && passwordNew !== passwordNewValidate) {
            errors.passwordCheck = t('login.passwordMatchError');
        }

        return errors;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            const payload = {
                client_id: config.oauth.clientId,
                client_secret: config.oauth.clientSecret,
                email: email,
                oldPassword: passwordCurrent,
                newPassword: passwordNew,
                newPassword2: passwordNewValidate,
            };
            mutate(payload, {
                onSuccess: () => {
                    setPasswordReset(false);
                },
                onError: error => {
                    if (error.response.status) {
                        setErrorMessage(t('login.currentPasswordError'));
                    } else {
                        setErrorMessage(t('login.generalError'));
                    }
                },
            });
        }
    };

    return (
        <>
            <ProfileSubMenu item={t('navigation.sideMenu.itemProfile')} />

            <MainWrapper>
                <ProfileContentWrapper>
                    <ProfileNameWrapper>
                        <ProfileNameCircle>
                            <H2White>
                                {userState?.given_name.charAt(0)}
                                {userState?.family_name.charAt(0)}
                            </H2White>
                        </ProfileNameCircle>
                        <ProfileFullName>
                            <H3Bold>
                                {userState?.given_name} {userState?.family_name}
                            </H3Bold>
                        </ProfileFullName>
                    </ProfileNameWrapper>
                    <br />
                    <TextInput
                        disabled={true}
                        label={t('login.email')}
                        input={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                    />

                    <CaptionLeft>{t('login.changeOrDeleteHelperText')}</CaptionLeft>
                    <br />
                    {!passwordReset ? (
                        <SubmitButton disabled={false} onClick={resetPassword}>
                            {t('login.submitChangePassword')}
                        </SubmitButton>
                    ) : (
                        <>
                            <LeadLeft>{t('login.changePasswordTitle')}</LeadLeft>
                            <form onSubmit={handleSubmit}>
                                <TextInput
                                    type={'password'}
                                    label={t('login.currentPassword')}
                                    input={passwordCurrent}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setPasswordCurrent(e.target.value);
                                    }}
                                    hasError={Boolean(validationErrors.passwordCurrent)}
                                />
                                {validationErrors.passwordCurrent ? (
                                    <CaptionLeftNegative>{validationErrors.passwordCurrent}</CaptionLeftNegative>
                                ) : null}
                                <TextInput
                                    type={'password'}
                                    label={t('login.newPassword')}
                                    input={passwordNew}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setPasswordNew(e.target.value);
                                    }}
                                    hasError={Boolean(validationErrors.passwordCheck || validationErrors.passwordNew)}
                                />
                                {validationErrors.passwordNew ? (
                                    <CaptionLeftNegative>{validationErrors.passwordNew}</CaptionLeftNegative>
                                ) : null}
                                {validationErrors.passwordStrength ? (
                                    <CaptionLeftNegative>{validationErrors.passwordStrength}</CaptionLeftNegative>
                                ) : null}
                                <TextInput
                                    type={'password'}
                                    label={t('login.repeatPassword')}
                                    input={passwordNewValidate}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setPasswordNewValidate(e.target.value);
                                    }}
                                    hasError={Boolean(
                                        validationErrors.passwordCheck || validationErrors.passwordNewValidate
                                    )}
                                />
                                {validationErrors.passwordNewValidate ? (
                                    <CaptionLeftNegative>{validationErrors.passwordNewValidate}</CaptionLeftNegative>
                                ) : null}
                                {validationErrors.passwordCheck ? (
                                    <CaptionLeftNegative>{validationErrors.passwordCheck}</CaptionLeftNegative>
                                ) : null}
                                {errorMessage ? <CaptionLeftNegative>{errorMessage}</CaptionLeftNegative> : null}
                                <PasswordStrengthWrapper>
                                    <Poor passwordLength={passwordNew.length} score={passwordScore} />
                                    <Fair passwordLength={passwordNew.length} score={passwordScore} />
                                    <Strong passwordLength={passwordNew.length} score={passwordScore} />
                                    <PasswordStrengtLabel passwordLength={passwordNew.length} score={passwordScore} />
                                </PasswordStrengthWrapper>
                                <SubmitButton disabled={isLoading} type={'submit'}>
                                    {t('login.submitChangePassword')}
                                </SubmitButton>
                            </form>
                        </>
                    )}
                </ProfileContentWrapper>
            </MainWrapper>

            <Footer />
        </>
    );
};

export default MeView;
