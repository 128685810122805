import React from 'react';

import { useTranslation } from 'react-i18next';

import { PercentageBar, PercentageIndicator, RoomTrackerWrapper } from 'styled/components/roomTracker';
import { CaptionLeftGrey, H2White, LeadLeftWhite } from 'styled/components/text';

interface RoomTrackerProps {
    currentOccupancy: number;
    currentOccupancyHour: string;
}
const RoomTracker: React.FunctionComponent<RoomTrackerProps> = ({ currentOccupancy, currentOccupancyHour }) => {
    const { t } = useTranslation();

    return (
        <RoomTrackerWrapper>
            <LeadLeftWhite>{t('roomTracker.now')}</LeadLeftWhite>
            <PercentageIndicator>
                <PercentageBar percentage={currentOccupancy} />
            </PercentageIndicator>
            <H2White>{currentOccupancy}%</H2White>
            <CaptionLeftGrey>{t('roomTracker.occupied')}</CaptionLeftGrey>
            <CaptionLeftGrey>{t('roomTracker.updatedAt', { timestamp: currentOccupancyHour })}</CaptionLeftGrey>
        </RoomTrackerWrapper>
    );
};

export default RoomTracker;
