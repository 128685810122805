import styled from 'styled-components';

export const DisplayRow = styled.div`
    display: flex;
    justify-content: flex-end;

    p:nth-child(2) {
        width: 220px;
    }
`;

export const Column = styled.div`
    display: flex;
    gap: 5px;
`;
