import { SpaceSuggestion } from 'components/SearchAutoComplete';
import { KpiUnit, Sensor, SensorType } from 'types';
import { DateSpan, DateRange } from 'utils/timePeriod';
import { EnergyConstants, EquipList, Meter, MeterList, SensorLookup, Space } from '../types';
import { NavigationItem } from 'components/NavigationBar';

export interface EnergyMeterState {
    buildingConstants: BuildingConstants;
    buildingStructure: Space[];
    city: string;
    suggestions: SpaceSuggestion[];
    meterStructure: Meter[];
    selectedMeter: string;
    sensors: Sensor[];
    meterOverview: MeterList[];
    selectedMainElectricitySensors: Sensor[];
    selectedSubElectricitySensors: Sensor[];
    ewattchMeta: MetaDataEwattch[];
    selectedEwattchSensors: MetaDataEwattch[];
    breadCrumbs: string[];
    from: string;
    to: string;
    usageByMeter: RoomUsage[];
}

export interface EnergyEBSState {
    selectedBuilding: Space;
    suggestions: SpaceSuggestion[];
    sensors: Sensor[];
    equipOverview: EquipList;
    consumptionSensors: Sensor[];
    gasSensors: Sensor[];
    productionSensors: Sensor[];
    feedinSensors: Sensor[];
    navigationItems: NavigationItem[];
    isLoading: boolean;
}

export interface EnergyGACSState {
    selectedBuilding: Space;
    selectedGroup: Space;
    buildingStructure: Space[];
    suggestions: SpaceSuggestion[];
    equipOverview: EquipList[];
    action: string;
    timespan: DateSpan;
    customDate: DateRange;
    navigationItems: NavigationItem[];
    isLoading: boolean;
}

export interface EnergyStatsState {
    energySensors: Sensor[];
    occupancySensors: Sensor[];
    buildingData: Space[];
    buildingConstants: BuildingConstants;
    city: string;
    suggestions: SpaceSuggestion[];
    buildingStructure: Space[];
    selectedRoom: string;
    sensors: Sensor[];
    equipOverview: EquipList[];
    selectedGasSensors: Sensor[];
    selectedMainElectricitySensors: Sensor[];
    selectedSubElectricitySensors: Sensor[];
    selectedProductionSensors: Sensor[];
    selectedFeedinSensors: Sensor[];
    selectedOccupancySensors: Sensor[];
    ewattchMeta: MetaDataEwattch[];
    selectedEwattchSensors: MetaDataEwattch[];
    breadCrumbs: string[];
    from: string;
    to: string;
    selectedSensorType: SensorType;
    hasCoolingHeatingSensors: boolean;
    sensorLookup: SensorLookup;
    roomsDepth: number;
    isLoading: boolean;
    navigationItems: NavigationItem[];
    isBuilding: boolean;
    usageByRoom: RoomUsage[];
    usageByBuilding: BuildingUsage[];
    selectedBuildingId: string;
}

export type BuildingConstants = {
    gasConstants: EnergyConstants;
    electricityConstants: EnergyConstants;
};

export type EnergyMeterAction =
    | { type: 'SELECT_SPACE1'; meterId: string }
    | { type: 'SELECT_SPACE2'; meterId: string }
    | { type: 'SELECT_SPACE3'; meterId: string }
    | { type: 'SELECT_SPACE4'; meterId: string }
    | { type: 'SELECT_SPACE5'; meterId: string }
    | { type: 'SELECT_SPACE6'; meterId: string }
    | {
          type: 'INIT_SENSORS';
          meterData: Meter[];
          sensorDataEnergy: Sensor[];
          buildingData: Space[];
      }
    | { type: 'SEARCH_SENSOR'; selectedMeter: SpaceSuggestion }
    | { type: 'ADD_USAGE_DATA'; meterData: Meter[] }
    | { type: 'CHANGE_DATE_SPAN'; action: string; timespan: DateSpan; customDate: DateRange };

export type EnergyStatsAction =
    | { type: 'SELECT_SPACE1'; spaceId: string }
    | { type: 'SELECT_SPACE2'; spaceId: string }
    | { type: 'SELECT_SPACE3'; spaceId: string }
    | { type: 'SELECT_SPACE4'; spaceId: string }
    | { type: 'SELECT_SPACE5'; spaceId: string }
    | { type: 'SELECT_SPACE6'; spaceId: string }
    | {
          type: 'INIT_SENSORS';
          buildingData: Space[];
          sensorDataEnergy: Sensor[];
          sensorDataOccupancy: Sensor[];
          ewattchMeta: MetaDataEwattch[];
      }
    | { type: 'SEARCH_SENSOR'; selectedSpace: SpaceSuggestion }
    | { type: 'ADD_USAGE_DATA'; buildingData: Space[]; usageType: UsageType; buildingUsage?: BuildingUsage[] }
    | { type: 'CHANGE_DATE_SPAN'; action: string; timespan: DateSpan; customDate: DateRange }
    | { type: 'SELECT_SENSOR_TYPE'; selectedType: SensorType };

export type EnergyEBSAction =
    | { type: 'SELECT_SPACE'; spaceId: string }
    | {
          type: 'INIT_SENSORS';
          buildingData: Space[];
          sensorDataEnergy: Sensor[];
      }
    | { type: 'SEARCH_SENSOR'; selectedSpace: SpaceSuggestion }
    | { type: 'SELECT_PAGE'; selectedPage: number };

export type EnergyGACSAction =
    | { type: 'SELECT_SPACE1'; spaceId: string }
    | { type: 'SELECT_SPACE2'; spaceId: string }
    | {
          type: 'INIT_SENSORS';
          buildingData: Space[];
          boilerMetaData: MetaDataBoiler[];
          sensorDataEnergy: Sensor[];
          selectedBuilding: Space;
          buildingStructure: Space[];
          action: string;
          timespan: DateSpan;
      }
    | { type: 'SEARCH_SENSOR'; selectedSpace: SpaceSuggestion }
    | { type: 'SELECT_PAGE'; selectedPage: number }
    | { type: 'CHANGE_DATE_SPAN'; action: string; timespan: DateSpan; customDate: DateRange };

export type MetaDataBoiler = {
    siteId: string;
    equipId: string;
    siteName: string;
    equipName: string;
    sensors: Sensor[];
};

export type MetaDataEwattch = {
    equipId: string;
    equipName: string;
    sensorId: string;
    siteId: string;
    siteName: string;
    rows: EwattchSensor[];
};

type EwattchSensor = {
    dis: string;
    equipRef: string;
    his: string;
    id: string;
    kind: string;
    modifiedDate_internal__: string;
    point: string;
    sensorId: string;
    siteRef: string;
    tenantId: string;
    type: SensorType;
    tz: string;
    unit: KpiUnit;
};

export type EwattchData = {
    location: string;
    measurements: EwattchTimeserie[];
    sensorId: string;
    timezone: string;
    unit: KpiUnit;
};

export type EwattchTimeserie = {
    t: string;
    v: number;
};

export interface Consumption {
    parentId: string | null;
    id: string;
    value: number;
    level: number;
}

export enum UsageType {
    BUILDING = 'Building',
    ROOM = 'Room',
}

export interface RoomUsage {
    value: number;
    id: string;
}

export interface BuildingUsage {
    id: string;
    production: number;
    consumption: number;
    feedin: number;
}

export interface TotalUsage {
    consumption: number;
    production: number;
    feedin: number;
    totalUsage: number;
}
