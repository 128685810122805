import { OccupancyScore, OccupancyScoreAvg, Sensor, SensorAverage, SensorAvgFilter, SensorData } from 'types';
import { GraphData } from 'components/Graph/types';
import { SpaceIndicator } from 'components/SpaceIndicator';
import { DateRange, DateSpan } from 'utils/timePeriod';

interface SensorDataParam {
    averageFilter: SensorAvgFilter;
    from: string;
    to: string;
    ids: string[];
}

export type DatapaneState = {
    motionSensorDataParams: SensorDataParam;
    action: string;
    motionGraphData: GraphData | null;
    selectedMotionSensors: Sensor[];
    selectedOccupancyBuilding: string;
    selectedOccupancyRoom: string;
};

export interface OccupancyDataParam {
    from: string;
    to: string;
    averageFrom: string;
    averageTo: string;
    selectedOccupancyBuilding: string;
    selectedOccupancyRoom: string;
    averageFilter: SensorAvgFilter;
}

export type OccupancyDatapaneState = {
    occupancyDataParams: OccupancyDataParam;
    action: string;
    occupancyData: OccupancyScore[];
    averageOccupancyData: OccupancyScoreAvg[];
    motionGraphData: GraphData | null;
    rawWeekOverviewData: OccupancyScoreAvg[];
    weekOverviewData: SensorAverage[];
    mostUsedSpaces: SpaceIndicator[];
    leastUsedSpaces: SpaceIndicator[];
    averageUsage: number;
    indicatorSpaceId: string;
    currentOccupancy: number;
    currentOccupancyHour: string;
    customDate: DateRange;
    timespan: DateSpan;
    isBarChart: boolean;
    countGraphData: GraphData | null;
    selectedSensor: Sensor | null;
};

export type DataPaneAction =
    | {
          type: 'SET_DATA';
          data: OccupancyScore[];
          averageData: OccupancyScoreAvg[];
          countData: SensorData[];
          averageCountData: SensorAverage[];
          openClosedHours: WeekOpenClosed;
          occupancySensors: Sensor[];
      }
    | {
          type: 'SET_WEEK_OVERVIEW_DATA';
          weekOverviewData: OccupancyScoreAvg[];
      }
    | { type: 'SELECT_INDICATOR'; id: string; openClosedHours: WeekOpenClosed }
    | { type: 'CHANGE_DATE_SPAN'; action: string; timespan: DateSpan; customDate: DateRange }
    | { type: 'CHANGE_BUILDING_SELECTION'; selectedOccupancyBuilding: string }
    | { type: 'CHANGE_ROOM_SELECTION'; selectedOccupancyRoom: string }
    | { type: 'TOGGLE_CHART'; isBarChart: boolean };

export type WeekOpenClosed = [
    DayOpenClosed,
    DayOpenClosed,
    DayOpenClosed,
    DayOpenClosed,
    DayOpenClosed,
    DayOpenClosed,
    DayOpenClosed
];

export type DayOpenClosed = [
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed,
    IsOpenClosed
];

export enum IsOpenClosed {
    OPEN = 'Open',
    CLOSED = 'Closed',
}
