import { useMutation } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';
import { PasswordPayload } from 'views/authenticated/profile/types';

export const usePasswordMutation = () => {
    const { axiosClient } = useAxiosClient();

    return useMutation<AxiosResponse, AxiosError, PasswordPayload>(payload => {
        return axiosClient.post(config.passwordChange, payload);
    });
};
