import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorAverage } from 'types';
import { SensorDataParam } from 'components/DataPane/statistics/water/types';

export const useSensorsAverageTotalData = (sensorDataParams: SensorDataParam) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['sensorsAverageTotal', sensorDataParams.ids, sensorDataParams.averageFilter],
        async ({ signal }) => {
            const { data } = await axiosClient.get<SensorAverage[]>(
                config.sensorDataAverageTotal(sensorDataParams.ids, sensorDataParams.averageFilter),
                {
                    signal,
                }
            );

            return data;
        }
    );
};
