import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import waterCircle from '../../../../../assets/icons/i-circle-water.svg';
import occupancyCircle from '../../../../../assets/icons/i-circle-o-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';

interface InfoHeaderProp {
    consumption: string;
    isLoading?: boolean;
}
const WaterOccupancy: React.FunctionComponent<InfoHeaderProp> = ({ consumption, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{consumption}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.totalUsage')} <br />
                </CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={waterCircle} />
                <CaptionLefWhite>{t('infoHeader.water.currentUsage')}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={occupancyCircle} />
                        <CaptionLefWhite>{t('infoHeader.occupancy.loadingCurrentMotions')}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={occupancyCircle} />
                        <CaptionLefWhite>{t('infoHeader.occupancy.currentMotions')}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default WaterOccupancy;
