import styled from 'styled-components';
import { theme } from 'styled/Theme';

interface Indicator {
    percentage: number;
}
export const Indicator = styled.div<Indicator>`
    width: 10px;
    height: 10px;
    border-radius: 2px;
    background-color: ${({ percentage }) => getColorByPercentage(percentage)};
`;

export const IndicatorWrapper = styled.div`
    display: flex;
    gap: 3px;
    margin-left: 10px;
    margin-right: 7px;
`;

export const SpaceIndicatorWrapper = styled.div`
    position: relative;
    height: 30px;
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: ${theme.colors.white};
    background: ${theme.colors.text};
    cursor: pointer;
`;

export const PercentageWrapper = styled.div`
    min-width: 34px;
`;

export const NameWrapper = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

export const SpaceWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 180px;
    width: 540px;
    flex-wrap: wrap;
`;

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SpaceIndicatorOverview = styled.div`
    display: flex;
    gap: 30px;
    justify-content: flex-end;
`;

export const SpaceIndicatorTitle = styled.div`
    margin-left: 102px;
`;

interface DisabledOverlay {
    isDisabled: boolean;
}
export const DisabledOverlay = styled.div<DisabledOverlay>`
    position: absolute;
    height: 30px;
    width: 270px;
    background: black;
    opacity: 0.3;
    background: ${theme.colors.text};
    display: ${({ isDisabled }) => (isDisabled ? 'block' : 'none')};
`;

const getColorByPercentage = (percentage: number) => {
    if (percentage === 0) {
        return theme.colors.greyD;
    }
    if (percentage < 10) {
        return theme.colors.positiveL;
    }
    if (percentage < 20) {
        return theme.colors.occ10;
    }
    if (percentage < 30) {
        return theme.colors.occ20;
    }
    if (percentage < 40) {
        return theme.colors.occ30;
    }
    if (percentage < 50) {
        return theme.colors.occ40;
    }
    if (percentage < 60) {
        return theme.colors.occ50;
    }
    if (percentage < 70) {
        return theme.colors.occ60;
    }
    if (percentage < 80) {
        return theme.colors.occ70;
    }
    if (percentage < 90) {
        return theme.colors.occ80;
    }
    if (percentage < 100) {
        return theme.colors.occ90;
    }
    return theme.colors.mOccup;
};
