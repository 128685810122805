import React from 'react';

import { useTranslation } from 'react-i18next';

import LegendaItem from './LegendaItem';

import { HideOnPrint, WrapperLegenda } from 'styled/components/ebs';
import { theme } from 'styled/Theme';

interface Props {
    showOutsideOpeningHours: boolean;
    onDisplayOutsideOpeningHours: () => void;
}
const ConsumptionLegenda: React.FunctionComponent<Props> = ({
    showOutsideOpeningHours,
    onDisplayOutsideOpeningHours,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <br />
            <WrapperLegenda>
                <LegendaItem color={theme.colors.scatterColor1}>{t('energy.ebs.interaction.openingHours')}</LegendaItem>
                {showOutsideOpeningHours ? (
                    <LegendaItem
                        isSelected={showOutsideOpeningHours}
                        color={theme.colors.scatterColor2}
                        onClick={onDisplayOutsideOpeningHours}
                    >
                        {t('energy.ebs.interaction.outsideOpeningHours')}
                    </LegendaItem>
                ) : (
                    <>
                        <HideOnPrint>
                            <LegendaItem
                                isSelected={showOutsideOpeningHours}
                                color={theme.colors.scatterColor2}
                                onClick={onDisplayOutsideOpeningHours}
                            >
                                {t('energy.ebs.interaction.outsideOpeningHours')}
                            </LegendaItem>
                        </HideOnPrint>
                        <div />
                    </>
                )}
            </WrapperLegenda>
        </>
    );
};

export default ConsumptionLegenda;
