import styled from 'styled-components';

import smiley from '../../assets/images/sad_smiley.webp';

export const StyledErrorBoundary = styled.div`
    position: relative;
    height: 100vh;

    > div {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        max-width: 560px;
        width: 100%;
        padding-left: 160px;
        line-height: 1.1;
    }
`;

export const SmileyBackground = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    width: 140px;
    height: 140px;
    background-image: url(${smiley});
    background-size: cover;

    &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-transform: scale(2.4);
        -ms-transform: scale(2.4);
        transform: scale(2.4);
        border-radius: 50%;
        background-color: #f2f5f8;
        z-index: -1;
    }
}
`;

export const StyledErrorMessage = styled.div`
    h1 {
        font-size: 65px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0px;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 21px;
        font-weight: 400;
        text-transform: uppercase;
        margin: 0px;
    }
`;
