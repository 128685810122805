import React from 'react';

import { useTranslation } from 'react-i18next';

import HolidaysDataPane from 'components/DataPane/ebs/HolidaysDataPane';
import SeasonDataPane from 'components/DataPane/ebs/SeasonDataPane';
import EBSTitleMenu from 'components/DataPane/ebs/EBSTitleMenu';
import EBSTextArea from './EBSTextArea';

import { ContentContainer, PageBreak, DisplayOnPrint } from 'styled/components/ebs';
import { SensorAverage, SensorType } from 'types';
import { ExplanatoryText } from './types';

interface Props {
    consumptionData: SensorAverage[];
    explanatoryText: ExplanatoryText;
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const ElectricityView: React.FunctionComponent<Props> = ({ consumptionData, explanatoryText, onChangeText }) => {
    const { t } = useTranslation();

    return (
        <>
            <ContentContainer>
                <EBSTitleMenu title={t('energy.ebs.electricity.title')} />
                <SeasonDataPane
                    title={t('energy.ebs.electricity.winter')}
                    data={consumptionData}
                    week={4}
                    sensorType={SensorType.ELECTRICITY}
                >
                    <EBSTextArea
                        text={explanatoryText.electricityWinter}
                        name={'electricityWinter'}
                        label={t('energy.ebs.textarea.addAnalysis')}
                        onChangeText={onChangeText}
                    />
                </SeasonDataPane>
                <SeasonDataPane
                    title={t('energy.ebs.electricity.spring')}
                    data={consumptionData}
                    week={17}
                    sensorType={SensorType.ELECTRICITY}
                >
                    <EBSTextArea
                        text={explanatoryText.electricitySpring}
                        name={'electricitySpring'}
                        label={t('energy.ebs.textarea.addAnalysis')}
                        onChangeText={onChangeText}
                    />
                </SeasonDataPane>

                <PageBreak />
                <DisplayOnPrint>
                    <EBSTitleMenu title={t('energy.ebs.electricity.title')} />
                </DisplayOnPrint>
                <SeasonDataPane
                    title={t('energy.ebs.electricity.summer')}
                    data={consumptionData}
                    week={32}
                    sensorType={SensorType.ELECTRICITY}
                >
                    <EBSTextArea
                        text={explanatoryText.electricitySummer}
                        name={'electricitySummer'}
                        label={t('energy.ebs.textarea.addAnalysis')}
                        onChangeText={onChangeText}
                    />
                </SeasonDataPane>
                <SeasonDataPane
                    title={t('energy.ebs.electricity.autumn')}
                    data={consumptionData}
                    week={45}
                    sensorType={SensorType.ELECTRICITY}
                >
                    <EBSTextArea
                        text={explanatoryText.electricityAutumn}
                        name={'electricityAutumn'}
                        label={t('energy.ebs.textarea.addAnalysis')}
                        onChangeText={onChangeText}
                    />
                </SeasonDataPane>

                <PageBreak />
                <DisplayOnPrint>
                    <EBSTitleMenu title={t('energy.ebs.electricity.title')} />
                </DisplayOnPrint>
                <HolidaysDataPane data={consumptionData} title={t('energy.ebs.electricity.holidays')}>
                    <EBSTextArea
                        text={explanatoryText.electricityHolidays}
                        name={'electricityHolidays'}
                        label={t('energy.ebs.textarea.addAnalysis')}
                        onChangeText={onChangeText}
                    />
                </HolidaysDataPane>
            </ContentContainer>
        </>
    );
};

export default ElectricityView;
