import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    VictoryLine,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryArea,
    VictoryBar,
    VictoryStack,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipBar from 'components/Graph/Tooltip/TooltipBar';

import { useResize } from 'hooks/useResize';

import { monthDayFormat } from 'utils/timePeriod';

import {
    lineChartDomainPadding,
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
    barChartPadding,
} from '../../graph.styles';

import { KpiUnit, ModuleType } from 'types';
import { formatXAxisTickValue, setBarConfig, sumValues } from 'components/Graph/graph.helpers';
import { ElectricityGraphData } from 'components/DataPane/statistics/energy/types';
import TooltipTop5 from 'components/Graph/Tooltip/TooltipTop5';
import { SensorValue } from 'components/Graph/types';

interface ElectricityConsumptionGraphProps {
    graphData: ElectricityGraphData;
    isBarChart: boolean;
    showTotal: boolean;
    showMeter1: boolean;
    showMeter2: boolean;
    showMeter3: boolean;
    showMeter4: boolean;
    showMeter5: boolean;
}

const ElectricityConsumptionGraph: React.FunctionComponent<ElectricityConsumptionGraphProps> = ({
    graphData,
    isBarChart,
    showTotal,
    showMeter1,
    showMeter2,
    showMeter3,
    showMeter4,
    showMeter5,
}) => {
    const dimension = useResize();
    const barConfig = setBarConfig(graphData.timespan);
    const summedTotalData = useMemo(() => sumValues(graphData.totalElectricityData, graphData.timespan), [graphData]);
    const summedMeter1Data = useMemo(() => sumValues(graphData.meter1, graphData.timespan), [graphData]);
    const summedMeter2Data = useMemo(() => sumValues(graphData.meter2, graphData.timespan), [graphData]);
    const summedMeter3Data = useMemo(() => sumValues(graphData.meter3, graphData.timespan), [graphData]);
    const summedMeter4Data = useMemo(() => sumValues(graphData.meter4, graphData.timespan), [graphData]);
    const summedMeter5Data = useMemo(() => sumValues(graphData.meter5, graphData.timespan), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={lineChartDomainPadding}
                padding={barChartPadding}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -230 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipTop5 height={dimension.height} timespan={graphData.timespan} />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Cost Y-axis */}
                <VictoryAxis
                    tickFormat={(t: number, i: number, ticks: number[]) => (i === ticks.length - 1 ? KpiUnit.KWH : t)}
                    dependentAxis={true}
                    style={XYAxis}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {isBarChart && showTotal && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar module={ModuleType.ENERGY} title={'Total'} unit={KpiUnit.KWH} />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mEnergy,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y="value"
                        data={summedTotalData}
                    />
                )}
                {isBarChart && Boolean(graphData.meter1.length) && (
                    <VictoryStack>
                        {Boolean(graphData.meter5.length) && showMeter5 && (
                            <VictoryBar
                                labels={() => ''}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutComponent={
                                            <TooltipBar
                                                module={ModuleType.ENERGY}
                                                title={graphData.meter5[0].name}
                                                unit={KpiUnit.KWH}
                                            />
                                        }
                                    />
                                }
                                animate={{ duration: 500, easing: 'cubicOut' }}
                                cornerRadius={barConfig.radius}
                                barWidth={barConfig.width}
                                style={{
                                    data: { fill: theme.colors.greyD },
                                }}
                                x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                                y="value"
                                data={summedMeter5Data}
                            />
                        )}
                        {Boolean(graphData.meter4.length) && showMeter4 && (
                            <VictoryBar
                                labels={() => ''}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutComponent={
                                            <TooltipBar
                                                module={ModuleType.ENERGY}
                                                title={graphData.meter4[0].name}
                                                unit={KpiUnit.KWH}
                                            />
                                        }
                                    />
                                }
                                animate={{ duration: 500, easing: 'cubicOut' }}
                                cornerRadius={barConfig.radius}
                                barWidth={barConfig.width}
                                style={{
                                    data: { fill: theme.colors.mEnergyBrown },
                                }}
                                x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                                y="value"
                                data={summedMeter4Data}
                            />
                        )}
                        {Boolean(graphData.meter3.length) && showMeter3 && (
                            <VictoryBar
                                labels={() => ''}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutComponent={
                                            <TooltipBar
                                                module={ModuleType.ENERGY}
                                                title={graphData.meter3[0].name}
                                                unit={KpiUnit.KWH}
                                            />
                                        }
                                    />
                                }
                                animate={{ duration: 500, easing: 'cubicOut' }}
                                cornerRadius={barConfig.radius}
                                barWidth={barConfig.width}
                                style={{
                                    data: { fill: theme.colors.mEnergyRosybrown },
                                }}
                                x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                                y="value"
                                data={summedMeter3Data}
                            />
                        )}
                        {Boolean(graphData.meter2.length) && showMeter2 && (
                            <VictoryBar
                                labels={() => ''}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutComponent={
                                            <TooltipBar
                                                module={ModuleType.ENERGY}
                                                title={graphData.meter2[0].name}
                                                unit={KpiUnit.KWH}
                                            />
                                        }
                                    />
                                }
                                animate={{ duration: 500, easing: 'cubicOut' }}
                                cornerRadius={barConfig.radius}
                                barWidth={barConfig.width}
                                style={{
                                    data: { fill: theme.colors.mEnergyL },
                                }}
                                x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                                y="value"
                                data={summedMeter2Data}
                            />
                        )}
                        {Boolean(graphData.meter1.length) && showMeter1 && (
                            <VictoryBar
                                labels={() => ''}
                                labelComponent={
                                    <VictoryTooltip
                                        flyoutComponent={
                                            <TooltipBar
                                                module={ModuleType.ENERGY}
                                                title={graphData.meter1[0].name}
                                                unit={KpiUnit.KWH}
                                            />
                                        }
                                    />
                                }
                                animate={{ duration: 500, easing: 'cubicOut' }}
                                cornerRadius={barConfig.radius}
                                barWidth={barConfig.width}
                                style={{
                                    data: { fill: theme.colors.mEnergyMediumL },
                                }}
                                x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                                y="value"
                                data={summedMeter1Data}
                            />
                        )}
                    </VictoryStack>
                )}
                {!isBarChart && showTotal && (
                    <VictoryLine
                        name="total"
                        interpolation="monotoneX"
                        animate={true}
                        x="timestamp"
                        y="value"
                        style={{
                            data: {
                                stroke: theme.colors.mEnergy,
                            },
                        }}
                        data={graphData.totalElectricityData}
                    />
                )}
                {!isBarChart && Boolean(graphData.meter1.length) && (
                    <VictoryStack>
                        {Boolean(graphData.meter5.length) && showMeter5 && (
                            <VictoryArea
                                name="meter5"
                                interpolation="monotoneX"
                                x="timestamp"
                                y="value"
                                style={{
                                    data: {
                                        stroke: theme.colors.greyD,
                                        strokeWidth: 2,
                                        fill: theme.colors.greyDTransparent,
                                    },
                                    labels: {
                                        fill: 'black',
                                    },
                                }}
                                data={graphData.meter5}
                            />
                        )}
                        {Boolean(graphData.meter4.length) && showMeter4 && (
                            <VictoryArea
                                name="meter4"
                                interpolation="monotoneX"
                                x="timestamp"
                                y="value"
                                style={{
                                    data: {
                                        stroke: theme.colors.mEnergyBrown,
                                        strokeWidth: 2,
                                        fill: theme.colors.mEnergyBrownTransparent,
                                    },
                                    labels: {
                                        fill: 'black',
                                    },
                                }}
                                data={graphData.meter4}
                            />
                        )}
                        {Boolean(graphData.meter3.length) && showMeter3 && (
                            <VictoryArea
                                name="meter3"
                                interpolation="monotoneX"
                                x="timestamp"
                                y="value"
                                style={{
                                    data: {
                                        stroke: theme.colors.mEnergyRosybrown,
                                        strokeWidth: 2,
                                        fill: theme.colors.mEnergyRosybrownTransparent,
                                    },
                                    labels: {
                                        fill: 'black',
                                    },
                                }}
                                data={graphData.meter3}
                            />
                        )}
                        {Boolean(graphData.meter2.length) && showMeter2 && (
                            <VictoryArea
                                name="meter2"
                                interpolation="monotoneX"
                                x="timestamp"
                                y="value"
                                style={{
                                    data: {
                                        stroke: theme.colors.mEnergyL,
                                        strokeWidth: 2,
                                        fill: theme.colors.mEnergyLTransparent,
                                    },
                                    labels: {
                                        fill: 'black',
                                    },
                                }}
                                data={graphData.meter2}
                            />
                        )}
                        {Boolean(graphData.meter1.length) && showMeter1 && (
                            <VictoryArea
                                name="meter1"
                                interpolation="monotoneX"
                                x="timestamp"
                                y="value"
                                style={{
                                    data: {
                                        stroke: theme.colors.mEnergyMediumL,
                                        strokeWidth: 2,
                                        fill: theme.colors.mEnergyMediumLTransparent,
                                    },
                                    labels: {
                                        fill: 'black',
                                    },
                                }}
                                data={graphData.meter1}
                            />
                        )}
                    </VictoryStack>
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(ElectricityConsumptionGraph);
