import React from 'react';

import { theme } from 'styled/Theme';
import { VictoryPie } from 'victory';
import i18n from '../../../../i18n';

import { PieGraphWrapper, PieWrapper, StyledList } from 'styled/components/energyPie';
import { CircleIcon } from 'styled/components/statisticsHeader';

import energyIcon from '../../../../assets/icons/i-circle.svg';
import { Top5SensorData } from './types';
import { summedValue } from './energyStatistics.helper';
import { KpiUnit } from 'types';

const PieDimension = 175;

const pieColors = [
    theme.colors.mEnergy,
    theme.colors.mEnergyMediumL,
    theme.colors.mEnergyL,
    theme.colors.mEnergyRosybrown,
    theme.colors.mEnergyLTransparent,
    theme.colors.greyD,
];

interface PieData {
    name: string;
    percentage: number;
    totalKWH: number;
}

interface EnergiePie {
    totalConsumption: number;
    top5Meters: Top5SensorData[];
    isGJ: boolean;
}
const EnergyPie: React.FunctionComponent<EnergiePie> = ({ totalConsumption, top5Meters, isGJ }) => {
    const pieData = mapTop5Meters(top5Meters, totalConsumption);

    return (
        <PieWrapper>
            <StyledList>
                {pieData.map((p, i) => {
                    return (
                        <li key={i}>
                            <span>
                                <CircleIcon src={energyIcon} $isSmall={true} color={pieColors[i]} />
                            </span>
                            {p.name} - {`${p.totalKWH} ${isGJ ? KpiUnit.GJ : KpiUnit.KWH}`}
                        </li>
                    );
                })}
            </StyledList>
            <PieGraphWrapper>
                <svg width={PieDimension} height={PieDimension}>
                    <VictoryPie
                        standalone={false}
                        padding={0}
                        height={PieDimension}
                        width={PieDimension}
                        style={{
                            labels: { fill: theme.colors.white, fontSize: 10, fontFamily: "'Roboto'" },
                        }}
                        x="percentage"
                        y="totalKWH"
                        colorScale={[
                            theme.colors.mEnergy,
                            theme.colors.mEnergyMediumL,
                            theme.colors.mEnergyL,
                            theme.colors.mEnergyRosybrown,
                            theme.colors.mEnergyLTransparent,
                            theme.colors.greyD,
                        ]}
                        labelRadius={60}
                        labels={({ datum }: { datum: PieData }) => `${datum.percentage}%`}
                        data={pieData}
                    />
                </svg>
            </PieGraphWrapper>
        </PieWrapper>
    );
};

export default EnergyPie;

const mapTop5Meters = (top5Meters: Top5SensorData[], totalConsumption: number): PieData[] => {
    const pieData: PieData[] = [];
    let restConsumption = totalConsumption;

    top5Meters.forEach(m => {
        const totalKWH = summedValue(m.data);
        pieData.push({ percentage: +((100 * totalKWH) / totalConsumption).toFixed(), name: m.name, totalKWH });
        restConsumption = restConsumption - totalKWH;
    });

    if (100 - pieData.reduce((p, c) => p + c.percentage, 0) > 0) {
        // add difference between totalconsumption vs top5 as 'overige' to pieChart
        pieData.push({
            percentage: 100 - pieData.reduce((p, c) => p + c.percentage, 0),
            name: i18n.t('common.other'),
            totalKWH: restConsumption,
        });
    }
    return pieData;
};
