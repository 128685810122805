import React from 'react';

import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip, VictoryScatter, VictoryLabel } from 'victory';
import { format } from 'date-fns';

import { useResize } from 'hooks/useResize';

import { feedbackBarChartPadding, closingAxis, closingXYAxis, XYAxis } from '../../graph.styles';

import { setBarConfigFeedback } from 'components/Graph/graph.helpers';

import { FeedbackGraphData, FeedbackTimeserie } from 'components/DataPane/statistics/feedback/types';
import { theme } from 'styled/Theme';
import { DateSpan } from 'utils/timePeriod';
import TooltipFeedback from 'components/Graph/Tooltip/TooltipFeedback';
import { SensorValue } from 'components/Graph/types';

export interface BarChartProps {
    graphData: FeedbackGraphData;
}
const OverviewChart: React.FunctionComponent<BarChartProps> = ({ graphData }) => {
    const dimension = useResize();
    const barConfig = setBarConfigFeedback(graphData.timespan);

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.2}
                domainPadding={{ x: [20, 0] }}
                padding={feedbackBarChartPadding}
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatTick(t, ticks, index, graphData.timespan)
                    }
                    style={XYAxis}
                />
                {/* Score Y-axis */}
                <VictoryAxis
                    tickValues={[0, 1, 2, 3, 4, 5]}
                    tickFormat={(t: number) => (t === 0 ? '' : t)}
                    dependentAxis={true}
                    style={XYAxis}
                />

                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            dx={d => {
                                const mousePosition = d.x;
                                return mousePosition && mousePosition > dimension.width / 2 ? -205 : 5;
                            }}
                            flyoutComponent={<TooltipFeedback dateSpan={graphData.timespan} />}
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: `${theme.colors.mFeedback}` },
                    }}
                    y="noiseLevel"
                    x={(datum: SensorValue) => datum.timestamp + barConfig.spaceOutside}
                    data={graphData.data}
                />
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            dx={d => {
                                const mousePosition = d.x;
                                return mousePosition && mousePosition > dimension.width / 2 ? -205 : 5;
                            }}
                            flyoutComponent={<TooltipFeedback dateSpan={graphData.timespan} />}
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: `${theme.colors.mMobility}` },
                    }}
                    y="temperature"
                    x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                    data={graphData.data}
                />
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            dx={d => {
                                const mousePosition = d.x;
                                return mousePosition && mousePosition > dimension.width / 2 ? -205 : 5;
                            }}
                            flyoutComponent={<TooltipFeedback dateSpan={graphData.timespan} />}
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: `${theme.colors.mEnergy}` },
                    }}
                    y="lightIntensity"
                    x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                    data={graphData.data}
                />
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            dx={d => {
                                const mousePosition = d.x;
                                return mousePosition && mousePosition > dimension.width / 2 ? -205 : 5;
                            }}
                            flyoutComponent={<TooltipFeedback dateSpan={graphData.timespan} />}
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: `${theme.colors.mWater}` },
                    }}
                    y="co2"
                    x={(datum: SensorValue) => datum.timestamp - barConfig.spaceOutside}
                    data={graphData.data}
                />

                <VictoryScatter
                    style={{
                        data: { fill: theme.colors.mFeedbackL, fillOpacity: 0.7 },
                        labels: { fill: theme.colors.white, fontSize: 10, fontFamily: "'Roboto'" },
                    }}
                    labels={({ datum }: { datum: FeedbackTimeserie }) => datum.totalFeedback}
                    labelComponent={<VictoryLabel dy={5} />}
                    size={10}
                    data={graphData.data
                        .filter(feedback => feedback.totalFeedback > 1)
                        .map(feedback => ({
                            totalFeedback: feedback.totalFeedback,
                            x: feedback.timestamp,
                            y: 2.5,
                        }))}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />
            </VictoryChart>
        </>
    );
};

export default React.memo(OverviewChart);

const formatTick = (t: number, ticks: number[], index: number, timespan: string) => {
    if (index === ticks.length - 1) {
        return '';
    }
    if (timespan === DateSpan.YEAR) {
        const month = format(new Date(t * 1000), 'MMM');
        return month === 'Jan' ? format(new Date(t * 1000), 'yyyy') : month;
    }

    const dayOfWeek = format(new Date(t * 1000), 'd');
    return dayOfWeek === '1' ? format(new Date(t * 1000), 'MMM') : dayOfWeek;
};
