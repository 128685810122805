import SVG from 'react-inlinesvg';
import styled from 'styled-components';
import { theme } from 'styled/Theme';

import { AirMetricsAction, KPIType } from 'types';

interface BaseSpaceItem {
    isSelected: boolean;
    isActive: boolean;
}
export const BaseSpaceItem = styled.div<BaseSpaceItem>`
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    height: 40px;
    padding: 0px 15px;
    color: ${({ isActive }) => (isActive ? theme.colors.white : theme.colors.text)};
    background-color: ${({ isActive }) => (isActive ? theme.colors.text : theme.colors.white)};
    font-weight: ${({ isActive, isSelected }) => (isActive || isSelected ? 'bold' : 'normal')};
    p:first-child {
        padding-right: 14px;
    }
`;

export const BuildingItem = styled(BaseSpaceItem)`
    margin-block: 2px;
    z-index: 99;
`;

export const RoomItem = styled(BaseSpaceItem)`
    margin-left: 15px;
    top: 21px;
`;

interface BorderWrapper {
    zIndex: number;
}
export const BorderWrapper = styled.div<BorderWrapper>`
    border: solid ${theme.colors.text} 2px;
    border-right: none;
    border-top: none;
    position: relative;
    top: -20px;
    z-index: ${({ zIndex }) => zIndex};
`;

export const RoomsWrapper = styled.div`
    margin-block: 6px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    min-height: 100%;
    min-width: 240px;
    gap: 30px;
`;

export const CountWrapper = styled.div`
    margin-top: 20px;
    border-left: solid 1px ${theme.colors.grey};
`;

export const Count = styled.div`
    display: flex;
    align-items: center;

    p {
        margin-block: 6px;
    }

    p:first-child {
        text-align: end;
        width: 30px;
    }
`;

export const KPIIcon = styled(SVG)`
    width: 10px;
    height: 10px;
    margin-inline: 6px;
`;

export const KPICountDisplayWrapper = styled.div`
    margin-top: 14px;
    flex: 1;
`;

export const SensorSelectorAirWrapper = styled.div`
    flex: 2;
    overflow-y: auto;
    overflow-x: hidden;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

interface KPIValue {
    KPIStatus: string;
    isActive: boolean;
}
export const StyledKPIValue = styled.p<KPIValue>`
    font-size: 14px;
    font-weight: bold;
    color: ${({ KPIStatus, isActive }) => (isActive ? theme.colors.white : getKPIColor(KPIStatus))};
`;

export const getMetricUnit = (metric: string) => {
    switch (metric) {
        case AirMetricsAction.CO2:
            return 'ppm';
        case AirMetricsAction.TEMPERATURE:
            return '°C';
        case AirMetricsAction.RELATIVE_HUMIDITY:
            return '%';
    }
};

const getKPIColor = (KPIStatus: string) => {
    switch (KPIStatus) {
        case KPIType.INSUFFICIENT:
            return theme.colors.negativeL;
        case KPIType.MODERATE:
            return theme.colors.mEnergy;
        case KPIType.SUFFICIENT:
            return theme.colors.air;
        default:
            return theme.colors.text;
    }
};
