import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon } from 'styled/components/dataPane';

import defaultCircle from '../../../../assets/icons/i-circle-default-average.svg';
import { KpiUnit } from 'types';

interface InfoHeaderProp {
    sensorValue: string;
}
const BatteryInfo: React.FunctionComponent<InfoHeaderProp> = ({ sensorValue }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.current')}
                    <br />
                    {t('infoHeader.energy.voltage')}
                </CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={defaultCircle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: KpiUnit.V })}</CaptionLefWhite>
            </div>
        </>
    );
};

export default BatteryInfo;
