import React from 'react';

import { useTranslation } from 'react-i18next';
import { theme } from 'styled/Theme';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendButtonIcon, ToggleLegend } from 'styled/components/dataPane';

import legendIcon from '../../../../../assets/icons/i-circle-default.svg';

import { KpiUnit } from 'types';
import { numberFormatter } from '../energyStatistics.helper';

interface InfoHeaderProp {
    production: number;
    feedin: number;
    billableConsumption: number;
    isLoading?: boolean;
    showConsumption: boolean;
    showProduction: boolean;
    showFeedin: boolean;
    onShowConsumption: (showConsumption: boolean) => void;
    onShowProduction: (showProduction: boolean) => void;
    onShowFeedin: (showFeedin: boolean) => void;
}
const EnergyProduction: React.FunctionComponent<InfoHeaderProp> = ({
    production,
    feedin,
    billableConsumption,
    showConsumption,
    onShowConsumption,
    showProduction,
    onShowProduction,
    showFeedin,
    onShowFeedin,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{`${numberFormatter(billableConsumption + production + feedin, 2)} ${
                    billableConsumption + production + feedin > 999 ? KpiUnit.MWH : KpiUnit.KWH
                }`}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.actual')}
                    <br />
                    {t('infoHeader.energy.usage')}
                </CaptionLefWhite>
            </div>

            <div>
                <H2White>
                    {`${numberFormatter(billableConsumption, 2)} ${
                        billableConsumption > 999 ? KpiUnit.MWH : KpiUnit.KWH
                    }`}
                </H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.billable')}
                    <br />
                    {t('infoHeader.energy.usage')}
                </CaptionLefWhite>
            </div>
            <div>
                <H2White>{`${numberFormatter(production, 2)} ${production > 999 ? KpiUnit.MWH : KpiUnit.KWH}`}</H2White>
                <CaptionLefWhite>{t('infoHeader.energy.produced')}</CaptionLefWhite>
            </div>
            <div>
                <H2White>{`${feedin} ${KpiUnit.KWH}`}</H2White>
                <CaptionLefWhite>{t('infoHeader.energy.feedin')}</CaptionLefWhite>
            </div>
            <div>
                <ToggleLegend active={showConsumption} onClick={() => onShowConsumption(!showConsumption)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergy} />
                    <CaptionLefWhite>{t('infoHeader.energy.energyUsage')}</CaptionLefWhite>
                </ToggleLegend>
                <ToggleLegend active={showProduction} onClick={() => onShowProduction(!showProduction)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergyL} />
                    <CaptionLefWhite>{t('infoHeader.energy.energyProduction', { unit: KpiUnit.KWH })}</CaptionLefWhite>
                </ToggleLegend>
                <ToggleLegend active={showFeedin} onClick={() => onShowFeedin(!showFeedin)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergyRosybrown} />
                    <CaptionLefWhite>{t('infoHeader.energy.energyFeedin', { unit: KpiUnit.KWH })}</CaptionLefWhite>
                </ToggleLegend>
            </div>
        </>
    );
};

export default EnergyProduction;
