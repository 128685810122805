import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const StyledToggle = styled.div`
    width: 40px;
    height: 16px;
    position: relative;
    display: flex;

    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    span {
        position: absolute;
        cursor: pointer;
        height: 6px;
        top: 5px;
        left: 0;
        right: 0;
        bottom: 0;
        background: ${theme.colors.ebsColor6};
        transition: 0.3s;
        border-radius: 30px;
    }

    span:before {
        border: 1px solid ${theme.colors.greyD};
        position: absolute;
        top: -5px;
        content: '';
        height: 14px;
        width: 14px;
        background-color: ${theme.colors.ebsColor6};
        border-radius: 3px;
        transition: 0.3s;
    }

    input:checked + span {
        background-color: ${theme.colors.mEnergy};
    }

    input:checked + span:before {
        background-color: ${theme.colors.mEnergy};
        transform: translateX(24px);
    }
`;
