import styled from 'styled-components';
import { theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';

interface SuggestionItemInterface {
    active: boolean;
}
export const SuggestionItem = styled.li<SuggestionItemInterface>`
    background: ${({ active }) => (active ? theme.colors.bg : 'white')};
    padding: 9px 10px;
    cursor: pointer;

    &:hover {
        background: ${theme.colors.bg};
    }

    em {
        font-size: 11px;
    }
`;

export const SearchWrapper = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    padding: 0;
    margin: 0;
    align-items: center;
`;

export const RemoveInputIcon = styled(SVG)`
    cursor: pointer;
    padding: 10px;
    position: absolute;
    right: 10px;
`;

export const SuggestionWrapper = styled.div`
    border: 1px solid ${theme.colors.grey};
    border-top: none;
    padding-top: 1px;
    max-height: 250px;
    overflow: auto;
    border-radius: 0px 0px 5px 5px;

    ul {
        margin: 0px;
        list-style-type: none;
        padding-inline: 0px;
    }

    div {
        background: white;
        margin-top: 1px;
        padding: 10px;
    }
`;

export const SearchInput = styled.input`
    padding-right: 48px;
    width: 100%;
    border: none;
    padding-left: 10px;
    height: 58px;
    ::placeholder {
        color: ${theme.colors.grey};
    }
`;

export const BreadCrumbsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;
`;

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: end;
    z-index: 200;
`;
