import React, { useEffect, useReducer } from 'react';

import { useTranslation } from 'react-i18next';

import IncidentsContentWrapper from 'components/Container/IncidentsContentWrapper';
import IncidentsTable from 'components/Table/IncidentsTable';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import SensorsSubMenu from 'components/Submenu/SensorsSubMenu';
import SensorsIssuesSubMenu from 'components/Submenu/SensorsIssuesSubMenu';
import SensorsIncidentsDataPane from 'components/DataPane/incidents/SensorsIncidentsDataPane';

import { H3 } from 'styled/components/text';

import { useSensorIncidentsData } from 'hooks/api/useSensorIncidentsData';

import { AnomalySubTypeSensor } from 'types';
import { SensorsIssuesAction, SensorsIssuesState } from './types';
import { createFromToDate, DateSpan } from 'utils/timePeriod';
import { createSuggestions, findIncident, searchedIncidents } from 'components/IssuesOverview/issues.helpers';

const SensorsIssuesView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [state, dispatch] = useReducer(sensorIssueReducer, initialState);
    const {
        dataParams,
        customDate,
        filteredIncidents,
        selectedIncident,
        selectedIncidentAction,
        selectedPeriodAction,
        suggestions,
    } = state;

    const { data, isLoading, isError } = useSensorIncidentsData(dataParams.from, dataParams.to);

    useEffect(() => {
        if (data) {
            dispatch({ type: 'SET_INCIDENT_DATA', incidents: data });
        }
    }, [data]);

    return (
        <>
            <SensorsSubMenu
                suggestions={suggestions}
                onSearch={selectedSpace => dispatch({ type: 'SEARCH_INCIDENTS', selectedSpace })}
            />
            <SensorsIssuesSubMenu
                selectedIncidentAction={selectedIncidentAction}
                onSelectIncidentAction={action => dispatch({ type: 'SET_INCIDENT_FILTER', incidentAction: action })}
                selectedPeriodAction={selectedPeriodAction}
                onSelectPeriodAction={(action, customDate) =>
                    dispatch({
                        type: 'SET_TIMESPAN',
                        periodAction: action,
                        customDate: customDate || { from: '', to: '' },
                    })
                }
                customDate={customDate}
            />
            <IncidentsContentWrapper>
                <H3>{t('view.sensors.incidents')}</H3>
            </IncidentsContentWrapper>

            {isError && <div>{t('view.incidents.error')}</div>}

            {isLoading ? (
                <LoadingOverlay />
            ) : (
                <IncidentsTable
                    incidents={filteredIncidents}
                    handleSelect={(id: string, timestamp: string) =>
                        dispatch({
                            type: 'SET_INCIDENT',
                            selectedIncident: findIncident(filteredIncidents, id, timestamp),
                        })
                    }
                    moduleIncidents={false}
                />
            )}

            {selectedIncident && <SensorsIncidentsDataPane incident={selectedIncident} />}
        </>
    );
};

export default SensorsIssuesView;

const initialState: SensorsIssuesState = {
    dataParams: {
        from: '',
        to: '',
    },
    selectedIncidentAction: AnomalySubTypeSensor.ANY,
    selectedPeriodAction: DateSpan.EVER,
    suggestions: [],
    incidents: [],
    selectedIncident: null,
    filteredIncidents: [],
    incident: null,
    customDate: {
        from: '',
        to: '',
    },
};

const sensorIssueReducer = (state: SensorsIssuesState, action: SensorsIssuesAction): SensorsIssuesState => {
    switch (action.type) {
        case 'SET_INCIDENT_FILTER': {
            const filteredIncidents =
                action.incidentAction === AnomalySubTypeSensor.ANY
                    ? state.incidents
                    : state.incidents.filter(i => i.anomalySubType === action.incidentAction);

            const suggestions = createSuggestions(filteredIncidents);
            return {
                ...state,
                selectedIncidentAction: action.incidentAction,
                filteredIncidents,
                suggestions,
                selectedIncident: null,
            };
        }
        case 'SET_TIMESPAN': {
            const { from } =
                action.periodAction === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.periodAction);
            const customDate = action.periodAction === DateSpan.CUSTOM ? action.customDate : state.customDate;

            return {
                ...state,
                selectedPeriodAction: action.periodAction,
                customDate,
                dataParams: {
                    from,
                    to: action.periodAction === DateSpan.CUSTOM ? action.customDate.to : '',
                },
            };
        }
        case 'SET_INCIDENT_DATA': {
            const filteredIncidents =
                state.selectedIncidentAction === AnomalySubTypeSensor.ANY
                    ? action.incidents
                    : action.incidents.filter(i => i.anomalySubType === state.selectedIncidentAction);

            const suggestions = createSuggestions(filteredIncidents);

            return {
                ...state,
                incidents: action.incidents,
                filteredIncidents,
                suggestions,
            };
        }
        case 'SET_INCIDENT': {
            return {
                ...state,
                selectedIncident: action.selectedIncident,
            };
        }
        case 'SEARCH_INCIDENTS': {
            const filteredIncidents = searchedIncidents(state.filteredIncidents, action.selectedSpace);
            return {
                ...state,
                filteredIncidents,
            };
        }
    }
};
