import React from 'react';

import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';

import {
    BorderedContainer,
    CaptionPreview,
    EnergyContainer,
    GetContainer,
    HeaderContainer,
    HeaderGrid,
    HeartIcon,
    HexaconBackground,
    Icon,
    IconContainer,
    IconWrapper,
    ImageContainer,
    IncidentGrid,
    IncidentWrapper,
    IncidentsContainer,
    ModuleContainer,
    ModuleIcon,
    ModuleText,
    ModuleTitle,
    PreviewGrid,
    PreviewLead,
    PreviewText,
    SoonSpan,
    SupportBackground,
    SupportContentContainer,
    SupportGrid,
    StatsContainer,
    StatisticsContainer,
    StatisticGrid,
} from 'styled/components/preview';
import { H3White } from 'styled/components/text';

import sunIcon from '../../../assets/icons/icon-tooltip-luminocity.svg';
import energyIcon from '../../../assets/icons/i-m-energy.svg';
import energyHeart from '../../../assets/icons/heart-energy-preview.svg';
import incidentPreview1 from '../../../assets/images/preview/e-preview-1.webp';
import incidentPreview2 from '../../../assets/images/preview/e-preview-2.webp';
import energyScreen from '../../../assets/images/preview/e-preview-screen.webp';
import moduleIcon from '../../../assets/icons/preview-module-icon-energy.svg';

import { ModuleType } from 'types';

import { config } from 'config';

const EnergyPreviewView: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderGrid>
                <GetContainer />
                <EnergyContainer>
                    <ModuleTitle module={ModuleType.ENERGY}>Energy</ModuleTitle>
                </EnergyContainer>
            </HeaderGrid>
            <PreviewGrid>
                <SupportGrid>
                    <IconContainer>
                        <SupportBackground />
                        <IconWrapper>
                            <ModuleIcon src={moduleIcon} />
                        </IconWrapper>
                    </IconContainer>
                    <ModuleContainer>
                        <ModuleText>module</ModuleText>
                    </ModuleContainer>
                    <SupportContentContainer>
                        <PreviewText>{t('preview.energy.support')}</PreviewText>
                        <PreviewText>{t('preview.support1')}</PreviewText>
                        <PreviewText>
                            {t('preview.support2')} <span style={{ fontWeight: 'bold' }}>{config.profile.mail}</span>
                        </PreviewText>
                    </SupportContentContainer>
                </SupportGrid>
                <IncidentGrid>
                    <HeaderContainer>
                        <H3White>{t('preview.incidentHeader')}</H3White>
                    </HeaderContainer>
                    <BorderedContainer />
                    <IncidentsContainer>
                        <IncidentWrapper>
                            <img src={incidentPreview1} alt={''} width="290" height="150" />
                            <div>
                                <PreviewLead>
                                    <Icon src={energyIcon} />
                                    {t('preview.energy.incidents.header1')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.energy.incidents.content1')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                        <IncidentWrapper>
                            <img src={incidentPreview2} alt={''} width="290" height="150" />
                            <div>
                                <PreviewLead>
                                    <Icon src={energyIcon} />
                                    <SoonSpan>{t('preview.soon')}</SoonSpan>
                                    {t('preview.energy.incidents.header2')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.energy.incidents.content2')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                    </IncidentsContainer>
                    <StatsContainer>
                        <div>
                            <CaptionPreview>{t('preview.upTo')}</CaptionPreview>
                            <ModuleTitle module={ModuleType.ENERGY}>
                                12 <span style={{ fontSize: '18px' }}>%</span>
                            </ModuleTitle>
                            <PreviewText>{t('preview.savingCosts')}</PreviewText>
                        </div>
                        <div>
                            <CaptionPreview>{t('preview.atTheMoment')}</CaptionPreview>
                            <ModuleTitle module={ModuleType.ENERGY}>
                                84 <span style={{ fontSize: '18px' }}>{t('preview.buildings')}</span>
                            </ModuleTitle>
                            <PreviewText>{t('preview.connectedBuildings')}</PreviewText>
                        </div>
                        <div>
                            <HeartIcon src={energyHeart} />
                            <PreviewText>{t('preview.energy.stats.reduceCO2')}</PreviewText>
                        </div>
                    </StatsContainer>
                </IncidentGrid>
                <StatisticGrid>
                    <HeaderContainer>
                        <H3White>{t('preview.energy.statistics.title')}</H3White>
                    </HeaderContainer>
                    <ImageContainer>
                        <img src={energyScreen} alt={'energy module screen'} width={740} />
                    </ImageContainer>
                    <StatisticsContainer>
                        <div>
                            <PreviewLead>
                                <Icon src={energyIcon} />
                                {t('preview.energy.statistics.header1')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.energy.statistics.content1')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={sunIcon} />
                                {t('preview.energy.statistics.header2')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.energy.statistics.content2')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={energyIcon} />
                                <SoonSpan>{t('preview.soon')}</SoonSpan>
                                {t('preview.energy.statistics.header3')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.energy.statistics.content3')}</PreviewText>
                        </div>
                    </StatisticsContainer>
                </StatisticGrid>
                <HexaconBackground />
            </PreviewGrid>
            <Footer />
        </>
    );
};

export default EnergyPreviewView;
