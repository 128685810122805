import { useMutation } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';
import { FeedbackPayload } from 'views/authenticated/profile/types';

export const useFeedbackMutation = () => {
    const { axiosClient } = useAxiosClient();

    return useMutation((payload: FeedbackPayload) => {
        return axiosClient.post(config.sendFeedback, payload);
    });
};
