import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    VictoryArea,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryBar,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { useResize } from 'hooks/useResize';

import { monthDayFormat } from 'utils/timePeriod';

import {
    barChartPadding,
    closingAxis,
    closingXYAxis,
    dateAxis,
    lineChartDomainPadding,
    XYAxis,
} from '../../graph.styles';
import { SensorValue } from '../../types';
import TooltipIncident from 'components/Graph/Tooltip/TooltipIncident';
import TooltipBar from 'components/Graph/Tooltip/TooltipBar';
import { KpiUnit, ModuleType } from 'types';
import { formatXAxisTickValue, setBarConfig, sumValues } from 'components/Graph/graph.helpers';
import { ProductionGraphData } from 'components/DataPane/statistics/energy/types';

export interface LineGraphProps {
    graphData: ProductionGraphData;
    isBarChart: boolean;
    showConsumption: boolean;
    showProduction: boolean;
    showFeedin: boolean;
}
const ProductionGraph: React.FunctionComponent<LineGraphProps> = ({
    graphData,
    isBarChart,
    showConsumption,
    showProduction,
    showFeedin,
}) => {
    const { t } = useTranslation();
    const dimension = useResize();
    const barConfig = setBarConfig(graphData.timespan);
    const summedConsumptionData = useMemo(() => sumValues(graphData.consumptionData, graphData.timespan), [graphData]);
    const summedProductionData = useMemo(() => sumValues(graphData.productionData, graphData.timespan), [graphData]);
    const summedFeedinData = useMemo(() => sumValues(graphData.feedinData, graphData.timespan), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={lineChartDomainPadding}
                padding={barChartPadding}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            voronoiBlacklist={['production', 'feedin']}
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipIncident
                                            module={ModuleType.ENERGY}
                                            unit={KpiUnit.KWH}
                                            height={dimension.height}
                                            averageData={showProduction ? graphData.productionData : []}
                                            supportData={showFeedin ? graphData.feedinData : []}
                                            titleCurrent={t('tooltip.consumption')}
                                            titleAverage={t('tooltip.production')}
                                            titleSupport={t('tooltip.feedin')}
                                            timespan={graphData.timespan}
                                        />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />

                {/* Y-axis */}
                <VictoryAxis
                    dependentAxis={true}
                    tickFormat={(t: number, i: number, ticks: number[]) => (i === ticks.length - 1 ? KpiUnit.KWH : t)}
                    style={XYAxis}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {isBarChart && showConsumption && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={t('tooltip.consumption')}
                                        unit={KpiUnit.KWH}
                                    />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mEnergy,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y="value"
                        data={summedConsumptionData}
                    />
                )}
                {isBarChart && showProduction && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={t('tooltip.production')}
                                        unit={KpiUnit.KWH}
                                    />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.mEnergyL },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y="value"
                        data={summedProductionData}
                    />
                )}
                {isBarChart && showFeedin && graphData.feedinData.length > 0 && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={t('tooltip.feedin')}
                                        unit={KpiUnit.KWH}
                                    />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.mEnergyRosybrown },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y="value"
                        data={summedFeedinData}
                    />
                )}
                {!isBarChart && showConsumption && (
                    <VictoryArea
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: {
                                stroke: theme.colors.mEnergy,
                                strokeWidth: 2,
                                fill: theme.colors.mEnergyTransparent,
                            },
                        }}
                        data={graphData.consumptionData}
                    />
                )}
                {!isBarChart && showProduction && (
                    <VictoryArea
                        name="production"
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: {
                                stroke: theme.colors.mEnergyL,
                                strokeWidth: 2,
                                fill: theme.colors.mEnergyLTransparent,
                            },
                        }}
                        data={graphData.productionData}
                    />
                )}
                {!isBarChart && showFeedin && graphData.feedinData.length > 0 && (
                    <VictoryArea
                        name="feedin"
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: {
                                stroke: theme.colors.mEnergyRosybrown,
                                strokeWidth: 2,
                                fill: theme.colors.mEnergyRosybrownTransparent,
                            },
                        }}
                        data={graphData.feedinData}
                    />
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(ProductionGraph);
