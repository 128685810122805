import styled, { css } from 'styled-components';
import { theme, getColor, getLightColor } from 'styled/Theme';
import SVG from 'react-inlinesvg';
import { ModuleType } from 'types';

interface DefaultButton {
    active?: boolean;
}
export const DefaultButton = styled.button<DefaultButton>`
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    border: none;
    border-radius: 4px;
    height: 40px;
    padding: 0 15px;
    margin: 10px 10px 10px 0;
`;

export const SmallButton = styled(DefaultButton)`
    height: 25px;
    padding: 0px 10px;
    margin: 0;
    background-color: ${theme.colors.grey};
    color: ${theme.colors.text};
`;

interface ButtonInterface extends DefaultButton {
    inverted?: boolean;
}
export const Button = styled(DefaultButton)<ButtonInterface>`
    background-color: ${theme.colors.text};
    ${({ active, inverted, theme }) => {
        if (inverted) {
            return css`
                background-color: ${active ? theme.colors.grey : theme.colors.greyD};
                color: ${active ? theme.colors.text : theme.colors.white};
            `;
        }
        return css`
            background-color: ${active ? theme.colors.text : theme.colors.grey};
            color: ${active ? theme.colors.white : theme.colors.text};
        `;
    }};
`;

interface ModuleButtonInterface extends DefaultButton {
    module?: ModuleType;
    visuallyDisabled?: boolean;
}
export const SecondaryButton = styled(DefaultButton)<ModuleButtonInterface>`
    ${({ active, module }) =>
        active
            ? css`
                  background-color: ${getColor(module)};
              `
            : css`
                  background: none;
              `};
    color: ${({ disabled, active, visuallyDisabled, theme }) =>
        disabled
            ? theme.colors.grey
            : active
            ? theme.colors.white
            : visuallyDisabled
            ? theme.colors.grey
            : theme.colors.text};
    font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
`;

export const SubMenuButton = styled(SecondaryButton)`
    font-weight: bold;
    background-color: ${({ active, module }) => (active ? getColor(module) : theme.colors.grey)};
    color: ${({ disabled, active, theme }) =>
        disabled ? theme.colors.white : active ? theme.colors.white : theme.colors.text};
`;

export const DataPaneButton = styled(SecondaryButton)`
    background-color: ${({ active, module }) => (active ? getLightColor(module) : theme.colors.greyD)};
    color: ${({ active, theme }) => (active ? theme.colors.text : 'white')};
`;

export const MenuButton = styled(DefaultButton)`
    margin: 0px;
    border-radius: 0px;
    min-height: 65px;
    display: flex;
    align-items: center;
    min-width: 260px;
    background: ${({ active, theme }) => (active ? 'white' : theme.colors.text)};
    color: ${({ active, theme }) => (active ? theme.colors.text : theme.colors.white)};
    padding-left: 60px;
`;

export const SubmitButton = styled(DefaultButton)`
    width: 100%;
    height: 50px;
    padding: 0px;
    margin: 0px;
    color: ${theme.colors.white};
    ${({ disabled }) =>
        disabled
            ? css`
                  background: ${theme.colors.grey};
              `
            : css`
                  background: ${theme.colors.text};
              `}
`;

export const InvertedSubmitButton = styled(DefaultButton)`
    width: 100%;
    height: 50px;
    padding: 0px;
    margin: 0px;
    ${({ disabled }) =>
        disabled
            ? css`
                  color: ${theme.colors.text};
                  background: ${theme.colors.grey};
              `
            : css`
                  color: ${theme.colors.white};
                  background: ${theme.colors.greyD};
              `}
`;

export const MultiSelectButton = styled(SecondaryButton)`
    border-radius: 99px;
    font-weight: bold;
    background-color: ${({ disabled, active, module }) =>
        disabled
            ? active
                ? getLightColor(module)
                : theme.colors.grey
            : active
            ? getColor(module)
            : theme.colors.grey};
    color: ${({ disabled, active, theme }) =>
        disabled ? theme.colors.white : active ? theme.colors.white : theme.colors.text};
`;

interface IconInterface {
    $active: boolean;
    $disabled: boolean;
    $visuallyDisabled?: boolean;
    module?: string;
}
export const Icon = styled(SVG)<IconInterface>`
    width: 12px;
    height: 12px;
    margin-right: 6px;
    & path {
        fill: ${({ $disabled, $active, $visuallyDisabled, theme }) => {
            return $disabled
                ? theme.colors.grey
                : $active
                ? 'white'
                : $visuallyDisabled
                ? theme.colors.grey
                : theme.colors.text;
        }};
    }
`;

export const MenuIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    margin: auto;
`;

export const DataPaneIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    margin: auto;
`;

export const MenuIconBlack = styled(MenuIcon)`
    & path {
        fill: ${({ $active, theme }) => {
            return $active ? theme.colors.text : theme.colors.grey;
        }};
    }
`;

export const SortIcon = styled(SVG)`
    width: 10px;
    height: 14.9px;
    margin: 1px 0 0.1px 5px;
    padding: 1.2px 1px 0.8px 1px;
    object-fit: contain;
`;

export const EmptySortIcon = styled(SortIcon)`
    & path {
        fill: ${({ theme }) => {
            return theme.colors.grey;
        }};
    }
`;

export const AirSensorsTotalsButton = styled(DefaultButton)`
    background-color: ${theme.colors.white};
    width: 45px;
    height: 36px;
    margin: 4px;
`;

export const GoodTotalsButton = styled(AirSensorsTotalsButton)`
    color: ${theme.colors.air};
`;
export const AcceptableTotalsButton = styled(AirSensorsTotalsButton)`
    color: ${theme.colors.mEnergy};
`;
export const PoorTotalsButton = styled(AirSensorsTotalsButton)`
    color: ${theme.colors.negative};
`;
export const UnknownTotalsButton = styled(AirSensorsTotalsButton)`
    color: ${theme.colors.grey};
`;
export const MultiSelectIcon = styled(SVG)`
    width: 8px;
    height: 8px;
    margin-left: 6px;
    border-radius: 99px;
`;

export const RadioButton = styled(DefaultButton)`
    display: flex;
    width: 117px;
    height: 30px;
    padding: 0;
    align-items: center;
    justify-content: left;
    border-radius: 4px;
    font-weight: bold;
    background-color: ${({ active }) => (active ? theme.colors.text : theme.colors.grey)};
    color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.text)};
`;

export const RadioIcon = styled.span<DefaultButton>`
    min-width: 11px;
    min-height: 11px;
    border: 1px solid ${props => props.theme.colors.white};
    border-radius: 50%;
    margin-right: 9px;
    margin-left: 11px;
    background-color: ${({ active }) => (active ? theme.colors.text : theme.colors.white)};
`;

interface RadioButtonIcon {
    $active: boolean;
}
export const RadioButtonIcon = styled(SVG)<RadioButtonIcon>`
    width: 15px;
    height: 15px;
    margin-left: 11px;
    & path {
        fill: ${({ $active, theme }) => {
            return $active ? theme.colors.white : theme.colors.text;
        }};
    }
`;

export const EBSWizardButton = styled(DefaultButton)`
    margin: 0px;
    background-color: ${theme.colors.mEnergy};
    color: ${theme.colors.text};
    height: 50px;
`;

export const EBSSingleButton = styled(EBSWizardButton)`
    width: 480px;
    background-color: ${({ active }) => (active ? theme.colors.mEnergy : theme.colors.greyD)};
    color: ${({ active }) => (active ? theme.colors.greyD : theme.colors.white)};
`;

export const EBSPreviousButton = styled(EBSWizardButton)`
    width: 230px;
    background-color: ${theme.colors.greyD};
    color: ${theme.colors.white};
`;

export const EBSNextButton = styled(EBSWizardButton)`
    width: 230px;
`;
