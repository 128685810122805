import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { Score } from 'types';

export const useAirRoomScoreAverageData = (buildingId: string, roomId: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['roomScoreAverage', buildingId, roomId],
        async ({ signal }) => {
            const { data } = await axiosClient.get<Score[]>(config.airRoomScoresHourAvg(buildingId, roomId), {
                signal,
            });

            return data;
        },
        {
            enabled: Boolean(buildingId) && Boolean(roomId),
        }
    );
};
