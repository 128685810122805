import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { H2White } from 'styled/components/text';
import { LegendaIcon, LegendaText } from 'styled/components/infoHeader';

import poorCircle from '../../../../../assets/icons/icon-feedback-poor.svg';
import acceptableCircle from '../../../../../assets/icons/i-circle-e-average.svg';
import goodCircle from '../../../../../assets/icons/i-circle-noise.svg';

import { FeedbackTimeserie, MetricFeedback, MetricType } from '../types';

const scoreScale = 5;

interface InfoHeaderProp {
    feedback: FeedbackTimeserie[];
    metric: MetricFeedback;
}
const MetricHeader: React.FunctionComponent<InfoHeaderProp> = ({ feedback, metric }) => {
    const { t } = useTranslation();

    const numberOfFeedback = useMemo(() => calculateNumberOfMetricFeedback(feedback, metric), [feedback, metric]);

    const averageScore = useMemo(() => calculateAverage(feedback, metric), [feedback, metric]);

    return (
        <>
            <div>
                <H2White>{`${averageScore} ${t('infoHeader.feedback.outOf')} ${scoreScale}`}</H2White>
                <LegendaText>
                    {t('infoHeader.feedback.average')} <br />
                    {t('infoHeader.feedback.rating')}
                </LegendaText>
            </div>
            <div>
                <H2White>{numberOfFeedback}</H2White>
                <LegendaText>
                    {t('infoHeader.feedback.respondents')} <br />
                    {t('infoHeader.feedback.overPeriod')}
                </LegendaText>
            </div>
            <div>
                <LegendaIcon src={goodCircle} />
                <LegendaText>{t('infoHeader.feedback.good')}</LegendaText>
                <LegendaIcon src={acceptableCircle} />
                <LegendaText>{t('infoHeader.feedback.acceptable')}</LegendaText>
                <LegendaIcon src={poorCircle} />
                <LegendaText>{t('infoHeader.feedback.poor')}</LegendaText>
            </div>
        </>
    );
};

export default MetricHeader;

const calculateNumberOfMetricFeedback = (feedback: FeedbackTimeserie[], metric: MetricFeedback) => {
    return feedback.map(f => f[`${metric}TotalFeedback`]).reduce((prev, curr) => prev + curr, 0);
};

const calculateAverage = (feedback: FeedbackTimeserie[], metricType: MetricType) => {
    const filteredFeedback = feedback.filter(f => f[metricType] !== 0);

    const summedFeedback = filteredFeedback.length
        ? feedback
              .filter(f => f[metricType] !== 0)
              .map(f => f[metricType])
              .reduce((prev, curr) => prev + curr, 0)
        : 0;

    return filteredFeedback.length ? Number((summedFeedback / filteredFeedback.length).toFixed(1)) : summedFeedback;
};
