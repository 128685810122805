import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorAverage } from 'types';
import { SensorDataParam } from 'components/DataPane/statistics/air/types';

export const useAirSensorAverageData = (dataParam: SensorDataParam) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['airSensorAverage', dataParam.id, dataParam.averageFilter],
        async ({ signal }) => {
            const { data } = await axiosClient.get<SensorAverage[]>(
                config.sensorDataAverage(dataParam.id, dataParam.averageFilter),
                { signal }
            );

            return data;
        },
        {
            enabled: Boolean(dataParam.id) && dataParam.id !== '-1',
        }
    );
};
