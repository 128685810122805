import React from 'react';

import BatteryInfo from './InfoHeader/BatteryInfo';
import CO2Info from './InfoHeader/CO2Info';
import HumidityInfo from './InfoHeader/HumidityInfo';
import IlluminanceInfo from './InfoHeader/IlluminanceInfo';
import TemperatureInfo from './InfoHeader/TemperatureInfo';
import MotionInfo from './InfoHeader/MotionInfo';
import CCQInfo from './InfoHeader/CCQInfo';
import EnergyConsumptionInfo from './InfoHeader/EnergyConsumptionInfo';
import EnergyProductionInfo from './InfoHeader/EnergyProductionInfo';
import UnkownInfo from './InfoHeader/UnknownInfo';

import { DateRange, DateSpan, isDaysTimespan } from 'utils/timePeriod';

import { SensorData, SensorAverage, ModuleType, SensorType, Sensor, SensorAvgFilter, SensorSubType } from 'types';
import { GraphData } from 'components/Graph/types';
import {
    mapAverageByDayOfWeek,
    mapAverageByHourOfDayOfWeek,
    deduplicateValues,
    isMapAveragesByDay,
    calcAxisDates,
    mapSensorValue,
    getMax,
    getMin,
    createTickValues,
} from 'components/Graph/graph.helpers';
import WaterInfo from './InfoHeader/WaterInfo';
import MovementInfo from './InfoHeader/MovementInfo';

export const getSensorInfoHeader = (selectedSensor: Sensor, sensorValue: string, loading: boolean) => {
    switch (selectedSensor.type) {
        case SensorType.CO2:
            return <CO2Info sensorValue={sensorValue} isLoading={loading} />;
        case SensorType.BATTERY_VOLTAGE:
            return <BatteryInfo sensorValue={sensorValue} />;
        case SensorType.HUMIDITY:
            return <HumidityInfo sensorValue={sensorValue} isLoading={loading} />;
        case SensorType.ILLUMINANCE:
            return <IlluminanceInfo sensorValue={sensorValue} isLoading={loading} />;
        case SensorType.TEMP:
        case SensorType.TEMPERATURE:
            return <TemperatureInfo sensorValue={sensorValue} isLoading={loading} />;
        case SensorType.MOTION:
        case SensorType.MOVEMENT:
            switch (selectedSensor.subType) {
                case SensorSubType.EMPTY || null:
                    return <MovementInfo sensorValue={sensorValue} isLoading={loading} />;
                case SensorSubType.COUNT:
                    return <MotionInfo sensorValue={sensorValue} isLoading={true} />;
                default:
                    return <UnkownInfo sensorValue={sensorValue} modificationDate={selectedSensor.modificationDate} />;
            }
        case SensorType.CCQ:
            return <CCQInfo sensorValue={sensorValue} />;
        case SensorType.WATER:
            return <WaterInfo sensorValue={sensorValue} isLoading={loading} />;
        case SensorType.CURRENT:
        case SensorType.CURRENT_INDEX:
            return <EnergyConsumptionInfo sensorValue={sensorValue} unit={selectedSensor.unit} isLoading={loading} />;
        case SensorType.HEATING:
            return <EnergyConsumptionInfo sensorValue={sensorValue} unit={selectedSensor.unit} isLoading={loading} />;
        case SensorType.ELECTRICITY:
            switch (selectedSensor.subType) {
                case SensorSubType.CONSUMPTION:
                    return (
                        <EnergyConsumptionInfo
                            sensorValue={sensorValue}
                            unit={selectedSensor.unit}
                            isLoading={loading}
                        />
                    );
                case SensorSubType.PRODUCTION:
                    return (
                        <EnergyProductionInfo
                            sensorValue={sensorValue}
                            unit={selectedSensor.unit}
                            isLoading={loading}
                        />
                    );
                default:
                    return <UnkownInfo sensorValue={sensorValue} modificationDate={selectedSensor.modificationDate} />;
            }
        default:
            return <UnkownInfo sensorValue={sensorValue} modificationDate={selectedSensor.modificationDate} />;
    }
};

export const getModule = (type: SensorType) => {
    switch (type) {
        case SensorType.CCQ:
        case SensorType.CO2:
        case SensorType.HUMIDITY:
        case SensorType.TEMP:
        case SensorType.ILLUMINANCE:
        case SensorType.TEMPERATURE:
            return ModuleType.AIR;
        case SensorType.CURRENT:
        case SensorType.CURRENT_INDEX:
        case SensorType.ELEC_KWH:
        case SensorType.ELECTRICITY:
        case SensorType.GAS:
        case SensorType.HEATING:
        case SensorType.COOLING:
            return ModuleType.ENERGY;
        case SensorType.MOTION:
        case SensorType.MOVEMENT:
        case SensorType.LIGHT_INTENSITY:
        case SensorType.NOISE_LEVEL:
            return ModuleType.OCCUPANCY;
        case SensorType.WATER:
            return ModuleType.WATER;
        case SensorType.BATTERY_VOLTAGE:
        default:
            return ModuleType.SENSORS;
    }
};

export const sumSensorValues = (sensorType: string, sensorSubType: string) => {
    return (
        sensorType === SensorType.MOVEMENT ||
        (sensorType === SensorType.MOTION && sensorSubType !== SensorSubType.COUNT) ||
        sensorType === SensorType.WATER
    );
};

export const showDefaultBarChart = (selectedSensor: Sensor) => {
    return sumSensorValues(selectedSensor.type, selectedSensor.subType);
};

export const createSensorGraphData = (
    data: SensorData[],
    timespan: string,
    action: string,
    customDate: DateRange,
    averages?: SensorAverage[],
    isBarChart: boolean = false
) => {
    const sensorData = data.map(mapSensorValue);

    const mapAveragesByDay = isMapAveragesByDay(timespan, isBarChart);
    const mapMonthAveragesByDay = timespan === DateSpan.YEAR;

    // change once we decide which filter to use for barcharts
    const averageSensorData = averages
        ? sensorData.map(d => {
              return mapAveragesByDay
                  ? mapAverageByDayOfWeek(d, averages)
                  : mapMonthAveragesByDay
                  ? mapAverageByDayOfWeek(d, averages)
                  : mapAverageByHourOfDayOfWeek(d, averages);
          })
        : [];

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const timeFormat = mapAveragesByDay || mapMonthAveragesByDay ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';
    const graphData: GraphData = {
        data: sensorData,
        averageData: deduplicateValues(averageSensorData, timeFormat),
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
    };

    return graphData;
};

export const getAvgFilter = (timeSpan: string, isBarChart: boolean) => {
    // change once we decide which filter to use for barcharts
    return isMapAveragesByDay(timeSpan, isBarChart)
        ? SensorAvgFilter.DAY_OF_WEEK
        : timeSpan === DateSpan.YEAR
        ? SensorAvgFilter.DAY_OF_WEEK
        : SensorAvgFilter.HOUR_OF_DAY_OF_WEEK;
};
