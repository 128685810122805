import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const WorkingHoursHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface WorkingHoursHelperProps {
    isValid: boolean;
}
export const WorkingHoursHelper = styled.div<WorkingHoursHelperProps>`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    color: ${({ isValid }) => (isValid ? theme.colors.text : theme.colors.negativeL)};
    p {
        margin: 5px;
    }
    p:first-child {
        margin-top: 10px;
    }
    p:not(:first-child) {
        margin-left: 15px;
    }
`;

export const LoadingBox = styled.div`
    display: flex;
    width: 100%;
    height: 200px;
`;
