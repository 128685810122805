import React, { FunctionComponent, useEffect, useReducer } from 'react';

import DataPaneMenu from '../../DataPaneMenu';
import SensorKpiMenu from '../../sensors/SensorKpiMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import LineGraphAverages from 'components/Graph/sensors/LineGraphAverages';
import BarChartAverages from 'components/Graph/sensors/BarChartAverages';
import NoDataMessage from 'components/DataPane/NoDataMessage';

import {
    ContentWrapper,
    SensorGraphWrapper,
    InfoHeader,
    SensorsPane,
    getSensorTitle,
} from '../../../../styled/components/dataPane';
import { getSensorIcon, getSensorValue } from 'styled/components/sensors';

import { useSensorData } from 'hooks/api/useSensorData';
import { useAirSensorAverageData } from 'hooks/api/useAirSensorAverageData';
import { useAirRoomScoreData } from 'hooks/api/useAirRoomScoreData';
import { useAirRoomScoreAverageData } from 'hooks/api/useAirRoomScoreAverageData';

import { isEmpty } from 'utils/isEmptyObject';
import { createFromToDate, DateSpan } from 'utils/timePeriod';

import { Sensor, SensorAvgFilter, SensorType, KPI, KpiUnit, ModuleType, Score } from 'types';

import {
    getSensorInfoHeader,
    createSensorGraphData,
    getAvgFilter,
    showDefaultBarChart,
} from '../../sensors/sensors.helpers';
import { DataPaneAction, DataPaneState } from './types';

interface SensorDataPaneProps {
    sensors: Sensor[];
    metric: string;
    buildingId: string;
    roomId: string;
}
const AirSensorDataPane: FunctionComponent<SensorDataPaneProps> = ({ sensors, metric, buildingId, roomId }) => {
    const [state, dispatch] = useReducer(dataPaneReducer, initialState);
    const { action, customDate, timespan, selectedSensor, sensorDataParams, graphData, isBarChart } = state;

    const { data, isLoading, isError } = useSensorData(
        sensorDataParams,
        Boolean(sensorDataParams.id) && sensorDataParams.id !== '-1'
    );
    const { data: averageData, isLoading: averageIsLoading } = useAirSensorAverageData(sensorDataParams);
    const { data: roomScoreData, isLoading: roomScoreIsLoading } = useAirRoomScoreData(sensorDataParams);
    const { data: roomScoreAverageData } = useAirRoomScoreAverageData(buildingId, roomId);

    useEffect(() => {
        if (data) {
            dispatch({ type: 'SET_GRAPH_DATA', data, averageData: averageData || undefined });
        } else if (roomScoreData) {
            dispatch({ type: 'SET_SCORE_GRAPH_DATA', data: roomScoreData });
        }
    }, [data, averageData, roomScoreData]);

    useEffect(() => {
        if (roomScoreAverageData?.length) {
            addRoomScoreSensor(buildingId, roomId, sensors, roomScoreAverageData);
        }
    }, [roomScoreAverageData]);

    useEffect(() => {
        dispatch({ type: 'INIT_SENSOR_SELECTION', sensors, metric, roomId });
    }, [sensors]);

    const onToggleGraph = (isBarChart: boolean) => {
        dispatch({ type: 'TOGGLE_CHART', isBarChart });
    };

    return (
        <SensorsPane>
            {!isEmpty(selectedSensor) && (
                <>
                    <SensorKpiMenu
                        sensors={sensors}
                        selectedSensor={selectedSensor}
                        onSelectSensor={sensor => dispatch({ type: 'CHANGE_SENSOR', selectedSensor: sensor })}
                    />
                    <DataPaneMenu
                        customDate={customDate}
                        title={getSensorTitle(selectedSensor.type)}
                        icon={getSensorIcon(selectedSensor.type)}
                        selectedAction={action}
                        onSelectAction={(action, timespan, customDate) =>
                            dispatch({
                                type: 'CHANGE_DATE_SPAN',
                                action,
                                timespan,
                                customDate: customDate || { from: '', to: '' },
                            })
                        }
                        module={ModuleType.AIR}
                        canToggleGraph={true}
                        isBarChart={isBarChart}
                        onToggleGraph={onToggleGraph}
                    />
                </>
            )}

            <ContentWrapper>
                {(isLoading || roomScoreIsLoading || graphData === null) && !isError && (
                    <LoadingOverlay dark={true} isSmall={true} />
                )}
                {!isLoading && !roomScoreIsLoading && graphData?.data.length === 0 && (
                    <NoDataMessage
                        firstDataReceivedDate={selectedSensor.dataFlowStart}
                        lastDataReceivedDate={selectedSensor.latestKpi.time}
                    />
                )}
                {!isLoading && !roomScoreIsLoading && graphData?.data.length && (
                    <>
                        <InfoHeader>
                            {getSensorInfoHeader(
                                selectedSensor,
                                getSensorValue(selectedSensor, graphData.data, timespan),
                                averageIsLoading
                            )}
                        </InfoHeader>
                        <SensorGraphWrapper>
                            {isBarChart ? (
                                <BarChartAverages sensor={selectedSensor} graphData={graphData} />
                            ) : (
                                <LineGraphAverages sensor={selectedSensor} graphData={graphData} />
                            )}
                        </SensorGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </SensorsPane>
    );
};

export default AirSensorDataPane;

const initialState: DataPaneState = {
    sensorDataParams: {
        averageFilter: SensorAvgFilter.HOUR,
        from: '',
        to: '',
        id: '',
        roomId: '',
    },
    graphData: null,
    selectedSensor: {} as Sensor,
    action: DateSpan.TWO_DAYS,
    isBarChart: false,
    timespan: DateSpan.TWO_DAYS,
    customDate: {
        from: '',
        to: '',
    },
};

const dataPaneReducer = (state: DataPaneState, action: DataPaneAction): DataPaneState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const graphData = createSensorGraphData(
                action.data,
                state.timespan,
                state.action,
                state.customDate,
                action.averageData,
                state.isBarChart
            );

            return {
                ...state,
                graphData,
            };
        }
        case 'SET_SCORE_GRAPH_DATA': {
            const timeserie = action.data.map(item => ({
                time: item.time,
                value: item.score,
            }));
            const graphData = createSensorGraphData(
                timeserie,
                state.timespan,
                state.action,
                state.customDate,
                undefined,
                state.isBarChart
            );

            return {
                ...state,
                graphData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const averageFilter = getAvgFilter(action.timespan, state.isBarChart);
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : state.customDate;

            return {
                ...state,
                action: action.action,
                timespan: action.timespan,
                customDate,
                graphData: null,
                sensorDataParams: { ...state.sensorDataParams, from, to, averageFilter },
            };
        }
        case 'INIT_SENSOR_SELECTION': {
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);
            const selectedSensor = action.sensors.find(s => s.type === action.metric) || action.sensors[0];

            const isBarChart = showDefaultBarChart(selectedSensor);

            return {
                ...state,
                selectedSensor: selectedSensor,
                isBarChart: isBarChart,
                graphData: null,
                sensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, isBarChart),
                    from,
                    to,
                    id: selectedSensor.id,
                    roomId: selectedSensor.roomRef,
                },
            };
        }
        case 'CHANGE_SENSOR': {
            const isBarChart = showDefaultBarChart(action.selectedSensor);
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            return {
                ...state,
                selectedSensor: action.selectedSensor,
                isBarChart: isBarChart,
                graphData: null,
                sensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, isBarChart),
                    from,
                    to,
                    id: action.selectedSensor.id,
                    roomId: action.selectedSensor.roomRef,
                },
            };
        }
        case 'TOGGLE_CHART': {
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            return {
                ...state,
                isBarChart: action.isBarChart,
                graphData: null,
                sensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, action.isBarChart),
                    from,
                    to,
                    id: state.selectedSensor.id,
                    roomId: state.selectedSensor.roomRef,
                },
            };
        }
    }
};

const addRoomScoreSensor = (buildingId: string, roomId: string, sensors: Sensor[], roomScoreAverageData: Score[]) => {
    if (sensors.findIndex(x => x.roomRef === roomScoreAverageData[0].roomId && x.type === SensorType.CCQ) === -1) {
        // Treat CCQ like normal sensor
        const sensor = {} as Sensor;
        sensor.id = '-1';
        sensor.buildingRef = buildingId;
        sensor.roomRef = roomId;
        sensor.type = SensorType.CCQ;
        sensor.unit = KpiUnit.SCORE;
        sensor.latestKpi = {} as KPI;
        const score = roomScoreAverageData.find(s => s.roomId === roomId);
        sensor.latestKpi.time = '';
        sensor.dataFlowStart = '';
        sensor.latestKpi.value = score && score.data.length ? score.data[0].score : 0;
        sensor.dataFlowStart = '';
        sensors.push(sensor);
    }
};
