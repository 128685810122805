import { useEffect, useState } from 'react';

const getDimension = () => ({ width: window.innerWidth, height: window.innerHeight });

export const useResize = () => {
    const [dimension, setDimension] = useState(getDimension());

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setDimension(getDimension()), 500);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return dimension;
};
