import React from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryLine,
    VictoryTooltip,
    VictoryChart,
    VictoryAxis,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
} from 'victory';
import { format } from 'date-fns';

import TooltipSensor from '../Tooltip/TooltipSensor';

import { useResize } from 'hooks/useResize';

import { Sensor, SensorType } from 'types';
import {
    closingAxis,
    closingXYAxis,
    dateAxis,
    lineChartDomainPadding,
    lineChartPadding,
    XYAxis,
} from '../graph.styles';
import { monthDayFormat } from 'utils/timePeriod';
import { formatXAxisTickValue, getAvgLineColor, getLineColor } from '../graph.helpers';
import { GraphData } from '../types';

interface LineGraphProps {
    graphData: GraphData;
    sensor: Sensor;
}
const LineGraphAverages: React.FunctionComponent<LineGraphProps> = ({ graphData, sensor }) => {
    const dimension = useResize();
    const maxViewHeight = 0.29;

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <svg style={{ height: 0, display: 'block' }}>
                <defs>
                    <linearGradient id="lineGradientRedYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.negative} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowRed" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="90%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="35%" stopColor={theme.colors.air} />
                        <stop offset="65%" stopColor={theme.colors.air} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurple" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="40%" stopColor={theme.colors.air} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellow" x2="0%" y2="100%">
                        <stop offset="60%" stopColor={theme.colors.air} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurple" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="77%" stopColor={theme.colors.air} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="23%" stopColor={theme.colors.air} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="95%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="60%" stopColor={theme.colors.air} />
                        <stop offset="80%" stopColor={theme.colors.air} />
                        <stop offset="94%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="20%" stopColor={theme.colors.air} />
                        <stop offset="40%" stopColor={theme.colors.air} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="20%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="50%" stopColor={theme.colors.air} />
                        <stop offset="80%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * maxViewHeight}
                domainPadding={lineChartDomainPadding}
                padding={lineChartPadding}
                containerComponent={
                    <VictoryZoomVoronoiContainer
                        voronoiBlacklist={['average']}
                        mouseFollowTooltips={false}
                        voronoiDimension="x"
                        zoomDimension="x"
                        minimumZoom={{ x: 1, y: 0.005 }}
                        labels={() => ' '}
                        labelComponent={
                            <VictoryTooltip
                                dx={d => {
                                    const mousePosition = d.x;
                                    return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                }}
                                flyoutComponent={
                                    <TooltipSensor
                                        sensor={sensor}
                                        height={dimension.height}
                                        averageData={graphData.averageData}
                                        timespan={graphData.timespan}
                                    />
                                }
                            />
                        }
                    />
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Y-axis */}
                <VictoryAxis
                    dependentAxis={true}
                    crossAxis={false}
                    style={XYAxis}
                    tickValues={sensor.type === SensorType.CCQ ? [2, 4, 6, 8, 10] : undefined}
                    tickFormat={(t: number) => (sensor.type === SensorType.WATER ? (t * 1000).toFixed() : t)}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {sensor.type !== SensorType.BATTERY_VOLTAGE && (
                    <VictoryLine
                        animate={true}
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        name="average"
                        style={{
                            data: { stroke: getAvgLineColor(sensor.type) },
                        }}
                        data={graphData.averageData}
                    />
                )}
                <VictoryLine
                    interpolation="monotoneX"
                    x="timestamp"
                    y="value"
                    style={{
                        data: { stroke: getLineColor(sensor.type, graphData.min, graphData.max) },
                    }}
                    data={graphData.data}
                />
            </VictoryChart>
        </>
    );
};

export default React.memo(LineGraphAverages);
