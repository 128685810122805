import React from 'react';

import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

import { BodyLeft } from 'styled/components/text';
import { PanelsWrapper, RadioButtonsPanelWrapper } from 'styled/components/view';

import { AirMetricsAction, AirMetricsQualityAction } from 'types';

import RadioButtonGroup, { RadioButtonGroupItem } from 'components/RadioButtonGroup';

import temperatureIcon from '../../assets/icons/i-s-temperature.svg';
import humidityIcon from '../../assets/icons/i-s-humidity.svg';
import CO2Icon from '../../assets/icons/i-s-co-2.svg';

export const airMetricQualitiesButtonItems: RadioButtonGroupItem[] = [
    { label: i18n.t('subMenu.airStatistics.any'), action: AirMetricsQualityAction.ANY },
    { label: i18n.t('subMenu.airStatistics.good'), action: AirMetricsQualityAction.GOOD },
    { label: i18n.t('subMenu.airStatistics.acceptable'), action: AirMetricsQualityAction.ACCEPTABLE },
    { label: i18n.t('subMenu.airStatistics.poor'), action: AirMetricsQualityAction.POOR },
];

export const airMetricsButtonItems: RadioButtonGroupItem[] = [
    { label: '', action: AirMetricsAction.TEMPERATURE, icon: temperatureIcon },
    { label: '', action: AirMetricsAction.RELATIVE_HUMIDITY, icon: humidityIcon },
    { label: '', action: AirMetricsAction.CO2, icon: CO2Icon },
];

interface AirStatisticsSideMenu {
    qualityAction: string;
    metricAction: string;
    onSelectMetricAction: (action: string) => void;
    onSelectMetricQualityAction: (action: string) => void;
}

const AirStatisticsSideMenu: React.FunctionComponent<AirStatisticsSideMenu> = ({
    metricAction,
    qualityAction,
    onSelectMetricAction,
    onSelectMetricQualityAction,
}) => {
    const { t } = useTranslation();

    return (
        <PanelsWrapper>
            <RadioButtonsPanelWrapper>
                <div><BodyLeft>{t('subMenu.airStatistics.metric')}:</BodyLeft></div>
                <div><RadioButtonGroup
                    radioButtonGroupItems={airMetricsButtonItems}
                    selectedItem={metricAction}
                    selectedAction={onSelectMetricAction}
                /></div>
            </RadioButtonsPanelWrapper>
            <RadioButtonsPanelWrapper>
                <div><BodyLeft>{t('subMenu.airStatistics.metricValue')}:</BodyLeft></div>
                <div><RadioButtonGroup
                    radioButtonGroupItems={airMetricQualitiesButtonItems}
                    selectedItem={qualityAction}
                    selectedAction={onSelectMetricQualityAction}
                /></div>
            </RadioButtonsPanelWrapper>
        </PanelsWrapper>
    );
};

export default AirStatisticsSideMenu;
