import React from 'react';

import { format, subMonths, subWeeks, isSameMonth, subDays } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { CaptionLefWhite, H2White } from 'styled/components/text';
import { WeekOverviewWrapper, PercentageBar, PercentageLegenda } from 'styled/components/infoHeader';

import { DateRange, DateSpan } from 'utils/timePeriod';

const dayFormat = 'dd';
const monthDayFormat = 'MMM dd';

interface WeekOverviewHeader {
    customDate: DateRange;
    action: string;
}
const WeekOverviewHeader: React.FunctionComponent<WeekOverviewHeader> = ({ action, customDate }) => {
    const { t } = useTranslation();

    return (
        <WeekOverviewWrapper>
            <div>
                <H2White>{getTimeRange(action, customDate)}</H2White>
            </div>
            <PercentageLegenda>
                <CaptionLefWhite>{`${t('infoHeader.occupancy.occupancy')}: 5%`}</CaptionLefWhite>
                <PercentageBar />
                <CaptionLefWhite>100%</CaptionLefWhite>
            </PercentageLegenda>
        </WeekOverviewWrapper>
    );
};

export default WeekOverviewHeader;

const getTimeRange = (action: string, customDate: DateRange) => {
    let firstDate: Date;
    let secondDate = new Date();
    switch (action) {
        case DateSpan.ONE_DAY:
            firstDate = subDays(secondDate, 1);
            break;
        case DateSpan.TWO_DAYS:
            firstDate = subDays(secondDate, 2);
            break;
        case DateSpan.WEEK:
            firstDate = subWeeks(secondDate, 1);
            break;
        case DateSpan.MONTH:
            firstDate = subMonths(secondDate, 1);
            break;
        case DateSpan.CUSTOM:
            firstDate = new Date(customDate.from);
            secondDate = new Date(customDate.to);
            break;
    }

    return `${format(firstDate, monthDayFormat)} - ${format(secondDate, dateFormat(firstDate, secondDate))}`;
};

const dateFormat = (firstDate: Date, secondDate: Date) => {
    return isSameMonth(firstDate, secondDate) ? dayFormat : monthDayFormat;
};
