import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { ScoreData } from 'types';
import { SensorDataParam } from 'components/DataPane/statistics/air/types';

export const useAirRoomScoreData = (dataParams: SensorDataParam) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['roomScore', dataParams.id, dataParams.from, dataParams.to],
        async ({ signal }) => {
            const { data } = await axiosClient.get<ScoreData[]>(
                config.airRoomScores(dataParams.roomId, dataParams.from, dataParams.to),
                { signal }
            );

            return data;
        },
        {
            enabled: Boolean(dataParams.roomId),
        }
    );
};
