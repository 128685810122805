import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import { useTranslation } from 'react-i18next';

import { TooltipCard, Top5Span } from 'styled/components/tooltip';
import { CircleIcon } from 'styled/components/statisticsHeader';
import { CaptionLefWhite } from 'styled/components/text';

import { KpiUnit, ModuleType } from 'types';

import energyIcon from '../../../assets/icons/i-circle.svg';
import { createTimestamp } from 'utils/timePeriod';

const colors = [
    theme.colors.mEnergy,
    theme.colors.mEnergyMediumL,
    theme.colors.mEnergyL,
    theme.colors.mEnergyRosybrown,
    theme.colors.mEnergyBrown,
    theme.colors.greyD,
];

interface TooltipInterface {
    activePoints?: any[];
    x?: number;
    y?: number;
    dx?: number;
    dy?: number;
    height?: number;
    width?: number;
    timespan?: string;
}
const TooltipTop5: React.FunctionComponent<TooltipInterface> = ({
    activePoints,
    x = 0,
    dx = 0,
    height = 0,
    timespan = '',
}) => {
    const { t } = useTranslation();
    const maxHeightAxisIndicator = height * 0.27;

    const maxWidth = useMemo(() => {
        const maxName = activePoints?.reduce(
            (max, point) => ((point.name?.length || 0) > max.length ? point.name : max),
            ''
        );
        return Math.max(200, Math.min(500, maxName.length * 6 + 70));
    }, [activePoints]);

    return activePoints && activePoints.filter(p => p.value).length > 0 ? (
        <g style={{ pointerEvents: 'none' }}>
            <line
                x1={x}
                x2={x}
                y1="0"
                y2={maxHeightAxisIndicator}
                style={{ stroke: theme.colors.grey, strokeWidth: '0.8', fill: theme.colors.grey }}
            />
            {/* To display correct colors and values a loop is not possible since array length changes */}
            <foreignObject x={dx + x} y={10} width={maxWidth + 'px'} height={'100%'}>
                <>
                    <TooltipCard module={ModuleType.ENERGY}>
                        {[activePoints[0]].map(p => {
                            return <Top5Header key={'time'} timestamp={p.timestamp} timespan={timespan} />;
                        })}
                    </TooltipCard>
                    <TooltipCard module={ModuleType.ENERGY}>
                        {activePoints
                            .filter(p => p.childName === 'total')
                            .map(p => {
                                return (
                                    <Top5Label
                                        key={p.childName}
                                        name={t('infoHeader.common.total')}
                                        value={p.value}
                                        color={colors[0]}
                                    />
                                );
                            })}
                        {activePoints
                            .filter(p => p.childName === 'meter1')
                            .map(p => {
                                if (p.value) {
                                    return (
                                        <Top5Label key={p.childName} name={p.name} value={p.value} color={colors[1]} />
                                    );
                                }
                            })}
                        {activePoints
                            .filter(p => p.childName === 'meter2')
                            .map(p => {
                                if (p.value) {
                                    return (
                                        <Top5Label key={p.childName} name={p.name} value={p.value} color={colors[2]} />
                                    );
                                }
                            })}
                        {activePoints
                            .filter(p => p.childName === 'meter3')
                            .map(p => {
                                if (p.value) {
                                    return (
                                        <Top5Label key={p.childName} name={p.name} value={p.value} color={colors[3]} />
                                    );
                                }
                            })}
                        {activePoints
                            .filter(p => p.childName === 'meter4')
                            .map(p => {
                                if (p.value) {
                                    return (
                                        <Top5Label key={p.childName} name={p.name} value={p.value} color={colors[4]} />
                                    );
                                }
                            })}
                        {activePoints
                            .filter(p => p.childName === 'meter5')
                            .map(p => {
                                if (p.value) {
                                    return (
                                        <Top5Label key={p.childName} name={p.name} value={p.value} color={colors[5]} />
                                    );
                                }
                            })}
                    </TooltipCard>
                </>
            </foreignObject>
        </g>
    ) : null;
};

export default TooltipTop5;

interface Top5Label {
    name: string;
    value: number;
    color: string;
}
const Top5Label: React.FunctionComponent<Top5Label> = ({ name, value, color }) => {
    return (
        <div>
            <CaptionLefWhite>
                <Top5Span>
                    {`${value.toFixed(2)} ${KpiUnit.KWH}   `}
                    <CircleIcon src={energyIcon} $isSmall={true} color={color} />
                </Top5Span>
                {`${name}`}
            </CaptionLefWhite>
        </div>
    );
};

interface Top5Header {
    timestamp: number;
    timespan?: string;
}
const Top5Header: React.FunctionComponent<Top5Header> = ({ timestamp, timespan = '' }) => {
    const { t } = useTranslation();

    const formattedTime = useMemo(() => createTimestamp(timestamp, timespan), [timestamp]);

    return (
        <div>
            <CaptionLefWhite style={{ marginBlock: '5px' }}>
                <Top5Span>{`${t('tooltip.times')}: ${formattedTime}`}</Top5Span>
            </CaptionLefWhite>
        </div>
    );
};
