import React, { useEffect, useReducer, useState } from 'react';

import ModulesSubMenu, { navItemsWater } from 'components/Submenu/ModulesSubMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import BuildingSelector from 'components/BuildingSelector';
import { SpaceSuggestion } from 'components/SearchAutoComplete';
import WaterStatsDataPane from 'components/DataPane/statistics/water/WaterStatsDataPane';

import { useTenant } from 'context/TenantContext';
import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';

import { ModuleType, SensorType } from 'types';
import { SensorSelection } from '../types';
import { WaterStatsState, WaterStatsAction } from './types';

const WaterStatisticsView: React.FunctionComponent = () => {
    const { getBuildingData } = useTenant();
    const [state, dispatch] = useReducer(WaterStatisticsReducer, initialState);
    const { data, isLoading } = useSensorsByModulesData([ModuleType.WATER, ModuleType.OCCUPANCY]);
    const [isExpanded, setIsExpanded] = useState(false);
    const { selectedWaterSensors, breadCrumbs, selectedMotionSensors, equipOverview, suggestions } = state;

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                waterSensorData: data[0],
                occupancySensorData: data[1],
            });
        }
    }, [data]);

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsWater}
                suggestions={suggestions}
                breadCrumbs={isExpanded ? breadCrumbs : []}
                onSearch={selectedSpace =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedSpace: selectedSpace,
                    })
                }
            />
            {isLoading && <LoadingOverlay />}

            {Boolean(equipOverview.spaces.length) && (
                <BuildingSelector
                    buildings={equipOverview}
                    onBuildingSelect={roomId => dispatch({ type: 'SELECT_SPACE', spaceId: roomId })}
                    collapsed={Boolean(selectedWaterSensors.length)}
                />
            )}
            {Boolean(selectedWaterSensors.length) && (
                <WaterStatsDataPane
                    motionSensors={selectedMotionSensors}
                    waterSensors={selectedWaterSensors}
                    onExpand={setIsExpanded}
                />
            )}
        </>
    );
};

export default WaterStatisticsView;

export const initialState: WaterStatsState = {
    suggestions: [],
    sensors: [],
    equipOverview: {
        spaces: [],
        selectedSpace: '',
    },
    selectedWaterSensors: [],
    selectedMotionSensors: [],
    breadCrumbs: [],
};

const WaterStatisticsReducer = (state: WaterStatsState, action: WaterStatsAction): WaterStatsState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorSelection = localStorage.getItem('water-stats-selection');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;

            const sensors = [...action.waterSensorData, ...action.occupancySensorData];
            const buildingStructure = action.buildingData.filter(building =>
                Boolean(sensors.find(s => s.buildingRef === building.id && s.type === SensorType.WATER && s.isMain))
            );

            const selectedBuilding = buildingStructure.find(i => i.id === parsedSelection?.selectedId1);

            const breadCrumbs = [selectedBuilding?.name].filter((i): i is string => {
                return typeof i === 'string';
            });

            const suggestions: SpaceSuggestion[] = [];
            buildingStructure.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);
            });

            return {
                ...state,
                suggestions: suggestions,
                sensors,
                equipOverview: {
                    spaces: buildingStructure || [],
                    selectedSpace: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                },
                selectedWaterSensors: sensors.filter(
                    s => s.buildingRef === parsedSelection?.selectedId1 && s.type === SensorType.WATER && s.isMain
                ),
                selectedMotionSensors: sensors.filter(
                    s => s.buildingRef === parsedSelection?.selectedId1 && s.type === SensorType.MOTION
                ),
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE': {
            localStorage.setItem(
                'water-stats-selection',
                JSON.stringify({
                    selectedId1: action.spaceId,
                })
            );

            const breadCrumbs = [state.equipOverview.spaces.find(i => i.id === action.spaceId)?.name].filter(
                (i): i is string => {
                    return typeof i === 'string';
                }
            );

            return {
                ...state,
                equipOverview: {
                    spaces: state.equipOverview.spaces,
                    selectedSpace: action.spaceId,
                },
                selectedWaterSensors: state.sensors.filter(
                    s => s.buildingRef === action.spaceId && s.type === SensorType.WATER && s.isMain
                ),
                selectedMotionSensors: state.sensors.filter(
                    s => s.buildingRef === action.spaceId && s.type === SensorType.MOTION
                ),
                breadCrumbs,
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);
            localStorage.setItem('water-stats-selection', JSON.stringify(sensorSelection));

            const breadCrumbs = [
                state.equipOverview.spaces.find(i => i.id === sensorSelection.selectedId1)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                equipOverview: {
                    spaces: state.equipOverview.spaces,
                    selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                },
                selectedWaterSensors: state.sensors.filter(
                    s => s.buildingRef === sensorSelection.selectedId1 && s.type === SensorType.WATER && s.isMain
                ),
                selectedMotionSensors: state.sensors.filter(
                    s => s.buildingRef === sensorSelection.selectedId1 && s.type === SensorType.MOTION
                ),
                breadCrumbs,
            };
        }
    }
};

const createSensorSelection = (ids: string[]) => {
    const sensorSelection: SensorSelection = {
        selectedId1: '',
    };
    if (!ids) {
        return sensorSelection;
    }
    if (ids[0]) {
        sensorSelection['selectedId1'] = ids[0];
    }

    return sensorSelection;
};
