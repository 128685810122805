import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon } from 'styled/components/dataPane';

import airCircle from '../../../../assets/icons/i-circle-a-purple-to-red.svg';

export interface InfoHeaderProp {
    sensorValue: string;
    isLoading?: boolean;
}
const CCQInfo: React.FunctionComponent<InfoHeaderProp> = ({ sensorValue }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.current')} <br />
                    {t('infoHeader.air.climateQuality')}
                </CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={airCircle} />
                <CaptionLefWhite>{t('infoHeader.air.currentClimateQuality')}</CaptionLefWhite>
            </div>
        </>
    );
};

export default CCQInfo;
