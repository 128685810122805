import styled, { css } from 'styled-components';
import { theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';
import { H2 } from './text';

export const Wrapper = styled.div`
    display: flex;
    min-height: 280px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

interface divideEqual {
    divideEqual: boolean;
}
export const ElectricityWrapper = styled.div<divideEqual>`
    flex: ${({ divideEqual }) => (divideEqual ? 1 : 5)};
    padding: 45px 30px 30px 30px;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const CurrentElectricityWrapper = styled.div`
    display: flex;
    width: 100%;
    gap: 10px;
    align-items: center;
`;

export const ElectricityBarWrapper = styled.div`
    flex: 1;
    display: flex;
    gap: 2px;
`;

export const BarWrapper = styled.div`
    display: flex;
    flex: 1;
`;

interface Bar {
    percentage: number;
}
export const BillableWrapper = styled.div<Bar>`
    display: flex;
    flex: ${({ percentage }) => (percentage >= 80 ? '80' : percentage <= 20 ? '20' : percentage)};
    flex-direction: column;
`;

export const SelfSustainableWrapper = styled.div<Bar>`
    display: flex;
    flex: ${({ percentage }) => (percentage >= 80 ? '80' : percentage <= 20 ? '20' : percentage)};
    flex-direction: column;
`;

export const SelfSustainableContent = styled.div`
    flex: 1;
    padding-left: 5px;
`;

export const BillableContent = styled.div`
    flex: 1;
    display: flex;
    justify-content: end;
    padding-right: 5px;
`;

export const BillableBar = styled.div`
    display: flex;
    justify-content: end;
    flex: 1;
    height: 10px;
    font-size: 10px;
    background-color: ${theme.colors.mEnergy};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-right: 5px;
`;

export const SelfSustainableBar = styled.div`
    display: flex;
    flex: 1;
    height: 10px;
    font-size: 10px;
    background-color: ${theme.colors.mMobility};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    padding-left: 5px;
`;

export const ElectricityBar = styled.div`
    height: 10px;
    background-color: ${theme.colors.mEnergy};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const HeatingBar = styled.div`
    height: 10px;
    background-color: ${theme.colors.mOccupL};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const CoolingBar = styled.div`
    height: 10px;
    background-color: ${theme.colors.airLTransparent};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const GasWrapper = styled.div<divideEqual>`
    flex: ${({ divideEqual }) => (divideEqual ? 1 : 3)};
    padding: 45px 30px 30px 30px;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const CurrentGasWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

export const GasBar = styled.div`
    height: 10px;
    background-color: ${theme.colors.mWater};
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const GasBarWrapper = styled.div`
    display: flex;
    width: 50%;
    flex-direction: column;
`;

export const KPI = styled.div`
    position: relative;
    top: 5px;
`;

export const EmisionWrapper = styled.div<divideEqual>`
    ${({ divideEqual }) => {
        return divideEqual
            ? css`
                  flex: 1;
              `
            : css`
                  min-width: 180px;
              `;
    }}
    padding: 45px 30px 30px 30px;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const PieWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 370px;
    padding: 20px 30px 30px 30px;
`;

interface Icon {
    $isLarge?: boolean;
    $isSmall?: boolean;
    color?: string;
}
export const Icon = styled(SVG)<Icon>`
    width: ${({ $isLarge, $isSmall }) => ($isLarge ? '15px' : $isSmall ? '8px' : '12px')};
    height: ${({ $isLarge, $isSmall }) => ($isLarge ? '15px' : $isSmall ? '8px' : '12px')};
    margin-left: ${({ $isLarge }) => ($isLarge ? '8px' : '6px')};
    margin-right: ${({ $isLarge }) => ($isLarge ? '8px' : '6px')};
    & path {
        fill: ${({ color }) => {
            return color ? color : 'white';
        }};
    }
`;

export const CircleIcon = styled(Icon)`
    & circle {
        fill: ${({ color }) => {
            return color ? color : 'white';
        }};
        stroke: ${({ color }) => {
            return color ? color : 'white';
        }};
    }
`;

export const ColoredH2 = styled(H2)`
    font-weight: normal;
    color: ${({ color }) => color};
    span {
        font-size: 14px;
        margin-left: 4px;
    }
`;

export const LargeKPI = styled.p`
    font-family: Roboto;
    font-size: 60px;
    font-weight: normal;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: -1.25px;
    margin: 20px 0 20px 0;
    color: ${theme.colors.white};
`;

export const KPISpan = styled.span`
    font-size: 24px;
    margin-left: 8px;
`;

export const Caption = styled.p`
    font-size: 10px;
    color: ${({ color }) => (color ? color : theme.colors.white)};
    margin: 0px;
    position: relative;
    top: -5px;
`;
