import React from 'react';

import { useTranslation } from 'react-i18next';

import ModulesSubMenu, { navItemsMobility } from 'components/Submenu/ModulesSubMenu';

import { H3 } from 'styled/components/text';

import { ModuleType } from 'types';
import IncidentsContentWrapper from 'components/Container/IncidentsContentWrapper';

const MobilityIssuesView: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <ModulesSubMenu
                subNavItems={navItemsMobility}
                suggestions={[]}
                onSearch={suggestions => console.log(suggestions)}
            />
            <IncidentsContentWrapper>
                <H3>{t('view.incidents.title', { module: ModuleType.MOBILITY })}</H3>
            </IncidentsContentWrapper>
        </>
    );
};

export default MobilityIssuesView;
