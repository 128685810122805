import React from 'react';

import { useTranslation } from 'react-i18next';

import { StepSelectContainer } from 'styled/components/ebs';
import { EBSNextButton, EBSPreviousButton, EBSSingleButton } from 'styled/components/buttons';

import { WizardStep } from './types';

interface Props {
    step: number;
    onSelectStep: (page: number) => void;
    onHandlePrint: () => void;
}
const StepSelector: React.FunctionComponent<Props> = ({ step, onSelectStep, onHandlePrint }) => {
    const { t } = useTranslation();

    return (
        <StepSelectContainer>
            {step === WizardStep.INTRODUCTION && (
                <EBSSingleButton
                    active={true}
                    onClick={() => {
                        onSelectStep(WizardStep.FACILITY);
                    }}
                >
                    {t('energy.ebs.stepper.next')}
                </EBSSingleButton>
            )}
            {step !== WizardStep.INTRODUCTION && step !== WizardStep.PREVIEW && (
                <>
                    <EBSPreviousButton
                        onClick={() => {
                            onSelectStep(step - 1);
                        }}
                    >
                        {t('energy.ebs.stepper.previous')}
                    </EBSPreviousButton>
                    <EBSNextButton
                        onClick={() => {
                            onSelectStep(step + 1);
                        }}
                    >
                        {step !== WizardStep.INTERACTION
                            ? t('energy.ebs.stepper.next')
                            : t('energy.ebs.stepper.preview')}
                    </EBSNextButton>
                </>
            )}
            {step === WizardStep.PREVIEW && (
                <>
                    <EBSPreviousButton
                        onClick={() => {
                            onSelectStep(WizardStep.INTERACTION);
                        }}
                    >
                        {t('energy.ebs.stepper.previous')}
                    </EBSPreviousButton>
                    <EBSNextButton onClick={onHandlePrint}>{t('energy.ebs.stepper.pdf')}</EBSNextButton>
                </>
            )}
        </StepSelectContainer>
    );
};

export default StepSelector;
