import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from 'victory';
import { format } from 'date-fns';

import TooltipBar from '../Tooltip/TooltipBar';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import {
    averageValues,
    setBarConfig,
    sumValues,
    formatXAxisTickValue,
    getAvgLineColor,
    getBarColor,
} from '../graph.helpers';
import { barChartDomainPadding, barChartPadding, dateAxis, XYAxis, closingAxis, closingXYAxis } from '../graph.styles';
import { GraphData, SensorValue } from '../types';
import { Sensor, SensorType } from 'types';
import { getModule, sumSensorValues } from 'components/DataPane/sensors/sensors.helpers';
import { getUnit } from 'styled/components/sensors';

export interface BarChartProps {
    graphData: GraphData;
    sensor: Sensor;
}
const BarChartAverages: React.FunctionComponent<BarChartProps> = ({ sensor, graphData }) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);

    const summedData = useMemo(
        () =>
            sumSensorValues(sensor.type, sensor.subType)
                ? sumValues(graphData.data, graphData.timespan)
                : averageValues(graphData.data, graphData.timespan),
        [graphData, sensor.type]
    );

    return (
        <>
            <svg style={{ height: 0, display: 'block' }}>
                <defs>
                    <linearGradient id="lineGradientRedYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.negative} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowRed" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="90%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="35%" stopColor={theme.colors.air} />
                        <stop offset="65%" stopColor={theme.colors.air} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurple" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="40%" stopColor={theme.colors.air} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellow" x2="0%" y2="100%">
                        <stop offset="60%" stopColor={theme.colors.air} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurple" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="77%" stopColor={theme.colors.air} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="23%" stopColor={theme.colors.air} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="95%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="60%" stopColor={theme.colors.air} />
                        <stop offset="80%" stopColor={theme.colors.air} />
                        <stop offset="94%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="20%" stopColor={theme.colors.air} />
                        <stop offset="40%" stopColor={theme.colors.air} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="20%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="50%" stopColor={theme.colors.air} />
                        <stop offset="80%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                </defs>
            </svg>

            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={barChartDomainPadding}
                padding={barChartPadding}
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />

                {/* Y-axis */}
                <VictoryAxis
                    crossAxis={false}
                    dependentAxis={true}
                    style={XYAxis}
                    tickFormat={(t: number) => (sensor.type === SensorType.WATER ? (t * 1000).toFixed() : t)}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={getModule(sensor.type)}
                                    title={t('tooltip.current')}
                                    unit={getUnit(sensor.unit, sensor.subType)}
                                />
                            }
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: ({ datum }) => getBarColor(sensor.type, datum.value) },
                    }}
                    x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                    y="value"
                    data={summedData}
                />
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={getModule(sensor.type)}
                                    title={t('tooltip.average')}
                                    unit={getUnit(sensor.unit, sensor.subType)}
                                />
                            }
                        />
                    }
                    animate={{ duration: 500, easing: 'cubicOut' }}
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: getAvgLineColor(sensor.type) },
                    }}
                    x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                    y="value"
                    data={graphData.averageData}
                />
            </VictoryChart>
        </>
    );
};

export default React.memo(BarChartAverages);
