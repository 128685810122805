import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import i18n from '../../../i18n';
import { format } from 'date-fns';

import { useTranslation } from 'react-i18next';

import { TooltipCard, TooltipTitle, TooltipContent } from 'styled/components/tooltip';

import { SensorValue } from '../types';
import { ModuleType } from 'types';
import { calcTotal } from './TooltipBar';
import { createTimestamp } from 'utils/timePeriod';

interface TooltipInterface {
    x?: number;
    y?: number;
    dx?: number;
    dy?: number;
    height?: number;
    width?: number;
    datum?: SensorValue;
    averageData: SensorValue[];
    module: ModuleType;
    unit: string;
    titleCurrent?: string;
    titleAverage?: string;
    supportData?: SensorValue[];
    titleSupport?: string;
    timespan?: string;
}
const TooltipIncident: React.FunctionComponent<TooltipInterface> = ({
    x = 0,
    dx = 0,
    height = 0,
    datum = { value: 0, timestamp: 0 },
    averageData,
    supportData = [],
    module,
    unit,
    titleCurrent = i18n.t('tooltip.current'),
    titleAverage = i18n.t('tooltip.average'),
    titleSupport,
    timespan = '',
}) => {
    const { t } = useTranslation();
    const maxHeightAxisIndicator = height * 0.27;

    const total = useMemo(() => calcTotal(datum.value, module), [datum.value]);
    const average = useMemo(
        () => getAverageByTimestamp(datum.timestamp, averageData, module),
        [averageData, datum.timestamp]
    );
    const support = useMemo(
        () => getAverageByTimestamp(datum.timestamp, supportData, module),
        [supportData, datum.timestamp]
    );
    const timestamp = useMemo(() => createTimestamp(datum.timestamp, timespan), [datum.timestamp, timespan]);

    return datum.timestamp > 0 ? (
        <g style={{ pointerEvents: 'none' }}>
            <line
                x1={x}
                x2={x}
                y1="0"
                y2={maxHeightAxisIndicator}
                style={{ stroke: theme.colors.grey, strokeWidth: '0.8', fill: theme.colors.grey }}
            />

            <foreignObject x={dx + x} y={10} width={100} height={'100%'}>
                <>
                    <TooltipCard module={module}>
                        <TooltipTitle>{t('tooltip.times')}</TooltipTitle>
                        <TooltipContent>{timestamp}</TooltipContent>
                    </TooltipCard>
                    <TooltipCard module={module}>
                        <TooltipTitle>{titleCurrent}</TooltipTitle>
                        <TooltipContent>
                            {total}
                            <span>{unit}</span>
                        </TooltipContent>
                    </TooltipCard>
                    {average ? (
                        <TooltipCard module={module}>
                            <TooltipTitle>{titleAverage}</TooltipTitle>
                            <TooltipContent>
                                {average}
                                <span>{unit}</span>
                            </TooltipContent>
                        </TooltipCard>
                    ) : null}
                    {support ? (
                        <TooltipCard module={module}>
                            <TooltipTitle>{titleSupport}</TooltipTitle>
                            <TooltipContent>
                                {support}
                                <span>{unit}</span>
                            </TooltipContent>
                        </TooltipCard>
                    ) : null}
                </>
            </foreignObject>
        </g>
    ) : null;
};

export default TooltipIncident;

const getAverageByTimestamp = (timestamp: number, averageData: SensorValue[], module: string): string => {
    const formattedTimestamp = format(new Date((timestamp || 0) * 1000), 'yyyy-MM-dd HH');
    const numberAverage =
        averageData.find(
            average => format(new Date((average.timestamp || 0) * 1000), 'yyyy-MM-dd HH') === formattedTimestamp
        )?.value || 0;

    let average =
        numberAverage === 0
            ? ''
            : numberAverage === Math.floor(numberAverage)
            ? numberAverage.toFixed(0)
            : numberAverage.toFixed(2);

    if (module === ModuleType.WATER) {
        average = (+average * 1000).toFixed();
    }
    return average;
};
