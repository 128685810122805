import { useEffect, useState } from 'react';

export const useInitialLoad = (isLoading: boolean, initLoadTime = 2200) => {
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        const time = isLoading ? 30000 : initLoadTime;
        const timer = setTimeout(() => {
            setInitialLoad(false);
        }, time);

        return () => clearTimeout(timer);
    }, [isLoading, initLoadTime]);

    return initialLoad;
};
