import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendButtonIcon, PulseLegendButtonIcon, ToggleLegend } from 'styled/components/dataPane';

import { KpiUnit } from 'types';

import legendIcon from '../../../../../assets/icons/i-circle-default.svg';
import { theme } from 'styled/Theme';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { getUnit } from '../energyStatistics.helper';

interface InfoHeaderProp {
    electricityAvg: number;
    gasAvg: number;
    temperatureAvg: number;
    temperatureLoading: boolean;
    hasGasData: boolean;
    showGas: boolean;
    showElectricity: boolean;
    showTemperature: boolean;
    isInGigajoules: boolean;
    onShowGas: (showGas: boolean) => void;
    onShowElectricity: (showElectricity: boolean) => void;
    onShowTemperature: (showTemperature: boolean) => void;
}
const TemperatureConsumptionHeader: React.FunctionComponent<InfoHeaderProp> = ({
    electricityAvg,
    gasAvg,
    temperatureAvg,
    temperatureLoading,
    hasGasData,
    showGas,
    onShowGas,
    showElectricity,
    onShowElectricity,
    showTemperature,
    onShowTemperature,
    isInGigajoules,
}) => {
    const initialLoad = useInitialLoad(Boolean(temperatureLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{`${electricityAvg} ${getUnit(electricityAvg, isInGigajoules)}`}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.consumptionElectricity')}
                    <br />
                    {t('infoHeader.energy.hourlyAvg')}
                </CaptionLefWhite>
            </div>
            {hasGasData && showGas && (
                <>
                    <div>
                        <H2White>{`${gasAvg} ${KpiUnit.M3}`}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.energy.consumptionGas')}
                            <br />
                            {t('infoHeader.energy.hourlyAvg')}
                        </CaptionLefWhite>
                    </div>
                </>
            )}
            {
                <div>
                    <H2White>{`${temperatureAvg} ${KpiUnit.CELSIUS}`}</H2White>
                    <CaptionLefWhite>
                        {t('infoHeader.common.average')}
                        <br />
                        {t('infoHeader.energy.outdoorTemperature')}
                    </CaptionLefWhite>
                </div>
            }
            <div>
                <ToggleLegend active={showElectricity} onClick={() => onShowElectricity(!showElectricity)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergy} />
                    <CaptionLefWhite>{t('infoHeader.energy.electricity')}</CaptionLefWhite>
                </ToggleLegend>
                <ToggleLegend active={showGas} disabled={!hasGasData} onClick={() => hasGasData && onShowGas(!showGas)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mWater} />
                    <CaptionLefWhite>{t('infoHeader.energy.gas')}</CaptionLefWhite>
                </ToggleLegend>
                {initialLoad ? (
                    <ToggleLegend>
                        <PulseLegendButtonIcon src={legendIcon} color={theme.colors.white} />
                        <CaptionLefWhite>{t('infoHeader.energy.loadingOccupancy')}</CaptionLefWhite>
                    </ToggleLegend>
                ) : (
                    <ToggleLegend active={showTemperature} onClick={() => onShowTemperature(!showTemperature)}>
                        <LegendButtonIcon src={legendIcon} color={theme.colors.white} />
                        <CaptionLefWhite>{t('infoHeader.energy.outdoorTemperature')}</CaptionLefWhite>
                    </ToggleLegend>
                )}
            </div>
        </>
    );
};

export default TemperatureConsumptionHeader;
