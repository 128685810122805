import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { OccupancyScore } from 'types';

export const useBuildingOccupancyData = (buildingId: string, from: string, to: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['buildingOccupancy', buildingId, from, to], async ({ signal }) => {
        const { data } = await axiosClient.get<OccupancyScore[]>(config.occupancyBuilding(buildingId, from, to), {
            signal,
        });

        return data;
    });
};
