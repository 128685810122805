import styled from 'styled-components';
import { theme } from 'styled/Theme';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
    color: ${theme.colors.text};
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: bold;
    &:visited {
        color: ${theme.colors.text};
    }
`;
