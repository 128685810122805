import React from 'react';

import { VictoryAxis, VictoryChart, VictoryLine, VictoryScatter } from 'victory';

import { useResize } from 'hooks/useResize';
import { EBSElecTempGasGraphData } from '../types';

import { theme } from 'styled/Theme';
import { closingAxis, XYAxis } from '../graph.styles';
import { KpiUnit } from 'types';

const temperatureTicks = [-15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40];

interface Props {
    graphData: EBSElecTempGasGraphData;
    showOutsideOpeningHours: boolean;
    showTrend: boolean;
}
const ConsumptionScatter: React.FunctionComponent<Props> = ({ graphData, showOutsideOpeningHours, showTrend }) => {
    const dimension = useResize();

    return (
        <VictoryChart
            width={dimension.width * 0.7}
            height={dimension.height * 0.29}
            padding={{ right: 40, left: 40, bottom: 20, top: 20 }}
            minDomain={{ y: 0 }}
        >
            {/* Y-axis */}
            <VictoryAxis
                dependentAxis={true}
                crossAxis={false}
                tickFormat={(t: number, index: number, arr: number[]) =>
                    arr.length - 1 === index ? getValueUnit(graphData) : t
                }
                style={XYAxis}
                axisValue={temperatureTicks[0]}
            />
            {/* Closing axis */}
            <VictoryAxis
                dependentAxis={true}
                style={closingAxis}
                orientation="right"
                axisValue={temperatureTicks[temperatureTicks.length - 1]}
            />
            <VictoryAxis style={closingAxis} orientation="top" />

            {showOutsideOpeningHours && (
                <VictoryScatter
                    x="timestamp"
                    y="value"
                    size={1.5}
                    style={{ data: { fill: theme.colors.scatterColor2 } }}
                    data={
                        graphData.gasData.length
                            ? graphData.gasDataOutsideOpeningHours
                            : graphData.electricityDataOutsideOpeningHours
                    }
                />
            )}

            <VictoryScatter
                x="timestamp"
                y="value"
                size={1.5}
                style={{ data: { fill: theme.colors.scatterColor1 } }}
                data={graphData.gasData.length ? graphData.gasData : graphData.electricityData}
            />

            {Boolean(graphData.gasData.length) && showTrend && (
                <VictoryLine
                    data={
                        showOutsideOpeningHours ? graphData.trendLineGasIncludingOutsideHours : graphData.trendLineGas
                    }
                    style={{
                        data: {
                            stroke: theme.colors.grey,
                        },
                    }}
                />
            )}
            {!graphData.gasData.length && showTrend && (
                <VictoryLine
                    data={
                        showOutsideOpeningHours
                            ? graphData.trendLineElectricityIncludingOutsideHours
                            : graphData.trendLineElectricity
                    }
                    style={{
                        data: {
                            stroke: theme.colors.grey,
                        },
                    }}
                />
            )}

            {/* X-axis */}
            <VictoryAxis
                style={{ ...XYAxis, grid: { stroke: 'none' } }}
                tickValues={temperatureTicks}
                tickFormat={(t: number, index: number, arr: number[]) => (arr.length - 1 === index ? KpiUnit.C : t)}
            />
        </VictoryChart>
    );
};

export default React.memo(ConsumptionScatter);

const getValueUnit = (graphData: EBSElecTempGasGraphData) => {
    return graphData.gasData.length ? KpiUnit.M3 : KpiUnit.KWH;
};
