import React from 'react';

import { addMinutes, addHours, getUnixTime, isAfter } from 'date-fns';
import i18n from '../../../i18n';

import { DateSpan, isDaysTimespan } from 'utils/timePeriod';
import { createDuration } from 'utils/formatDuration';
import {
    Incident,
    AnomalySubTypeAir,
    AnomalySubTypeOccupancy,
    SensorData,
    SensorAverage,
    ModuleType,
    SensorAvgFilter,
    AnomalySubTypeEnergy,
    AnomalySubTypeWater,
    AnomalySubTypeSensor,
    SensorType,
    KpiUnit,
} from 'types';

import RapidTemperature from './infoHeader/RapidTemperature';
import HighTemperature from './infoHeader/HighTemperature';
import AirQuality from './infoHeader/AirQuality';
import MotionDetected from './infoHeader/MotionDetected';
import EnergyAboveAverageConsumption from './infoHeader/EnergyAboveAverageConsumption';
import LowBattery from './infoHeader/LowBattery';
import NoData from './infoHeader/NoData';
import WaterLowOccupancyConsumption from './infoHeader/WaterLowOccupancyConsumption';
import WaterZeroConsumption from './infoHeader/WaterZeroConsumption';
import WaterHighAirTemperature from './infoHeader/WaterHighAirTemperature';
import WaterAboveAverageConsumption from './infoHeader/WaterAboveAverageConsumption';

import {
    mapAverageByDayOfWeek,
    mapAverageByHourOfDayOfWeek,
    deduplicateValues,
    isMapAveragesByDay,
    createIncidentTickValues,
    mapSensorValue,
    getMax,
    getMin,
    calcAxisDates,
} from 'components/Graph/graph.helpers';
import { IncidentGraphData } from 'components/Graph/types';

const calcBrushArea = (timestamp: string, duration: string, to: string): [number, number] => {
    const { days, hours } = createDuration(duration);
    const leftSideBrush = getUnixTime(new Date(timestamp));
    let rightSideBrush;

    if (hours > 1) {
        rightSideBrush = getUnixTime(addHours(addMinutes(new Date(timestamp), 60 * hours), 24 * days));
        const maxRightSideBrush = getUnixTime(new Date(to));

        if (isAfter(rightSideBrush, maxRightSideBrush)) {
            return [leftSideBrush, maxRightSideBrush];
        }
        return [leftSideBrush, rightSideBrush];
    }

    rightSideBrush = getUnixTime(addMinutes(new Date(timestamp), 60));

    return [leftSideBrush, rightSideBrush];
};

export const getIncidentInfoHeader = (incident: Incident, loading: boolean) => {
    switch (incident.anomalySubType) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return <AirQuality incident={incident} isLoading={loading} />;
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return <HighTemperature incident={incident} isLoading={loading} />;
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return <RapidTemperature incident={incident} isLoading={loading} />;

        //ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return <EnergyAboveAverageConsumption incident={incident} isLoading={loading} />;

        //WATER
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return <WaterLowOccupancyConsumption incident={incident} />;
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return <WaterZeroConsumption incident={incident} isLoading={loading} />;
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return <WaterAboveAverageConsumption incident={incident} isLoading={loading} />;
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return <WaterHighAirTemperature incident={incident} />;

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return <MotionDetected incident={incident} isLoading={loading} />;

        case AnomalySubTypeSensor.LOW_BATTERY:
            return <LowBattery incident={incident} isLoading={loading} />;
        case AnomalySubTypeSensor.NO_DATA:
            return <NoData incident={incident} isLoading={loading} />;

        default:
            return 'Unknown';
    }
};

export const getSensorUnit = (type: SensorType) => {
    switch (type) {
        case SensorType.CO2:
            return KpiUnit.PPM;
        case SensorType.TEMPERATURE:
        case SensorType.TEMP:
            return KpiUnit.CELSIUS;
        case SensorType.HUMIDITY:
            return KpiUnit.PERCENTAGE;
        case SensorType.WATER:
            return KpiUnit.LITER;
        case SensorType.KWH:
        case SensorType.ELEC_KWH:
        case SensorType.ELECTRICITY_DAILY:
        case SensorType.ELECTRICITY_MONTHLY:
        case SensorType.ELECTRICITY:
            return KpiUnit.KWH;
        case SensorType.GAS:
            return KpiUnit.M3;
        case SensorType.BATTERY_VOLTAGE:
            return KpiUnit.V;
        case SensorType.MOTION:
        case SensorType.MOVEMENT:
            return i18n.t('infoHeader.occupancy.motions');
        case SensorType.LIGHT_INTENSITY:
        case SensorType.ILLUMINANCE:
            return KpiUnit.LX;
        default:
            return '';
    }
};

export const createIncidentGraphData = (
    data: SensorData[],
    averages: SensorAverage[],
    incident: Incident,
    timespan: string,
    from: string,
    to: string,
    isBarChart: boolean,
    support: SensorData[] | SensorAverage[] = []
) => {
    const sensorData = data.map(mapSensorValue);
    const supportData = support.map(mapSensorValue);

    const averageSensorData = averages.length
        ? sensorData.map(d => {
              return isMapAveragesByDay(timespan, isBarChart)
                  ? mapAverageByDayOfWeek(d, averages)
                  : mapAverageByHourOfDayOfWeek(d, averages);
          })
        : [];

    const tickValues = createIncidentTickValues(from, to, timespan);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;
    const brushArea = calcBrushArea(incident.timestampStart, incident.duration, to);

    const timeFormat = timespan === DateSpan.MONTH && isBarChart ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';

    const graphData: IncidentGraphData = {
        data: sensorData,
        averageData: deduplicateValues(averageSensorData, timeFormat),
        supportData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        brushArea,
        timespan,
        anomalySubtype: incident.anomalySubType,
        type: incident.type,
    };

    return graphData;
};

export const getAvgFilterByModule = (timeSpan: string, isBarChart: boolean = false) => {
    const mapAveragesByDay = isBarChart && timeSpan === DateSpan.MONTH;

    return mapAveragesByDay ? SensorAvgFilter.DAY_OF_WEEK : SensorAvgFilter.HOUR_OF_DAY_OF_WEEK;
};

export const showDefaultBarChart = (module: ModuleType) => {
    return module === ModuleType.OCCUPANCY || module === ModuleType.WATER;
};
