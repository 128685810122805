import React, { useMemo } from 'react';

import { SensorValue } from '../types';
import { TooltipCard, TooltipTitle, TooltipContent } from 'styled/components/tooltip';
import { ModuleType } from 'types';

interface TooltipBarInterface {
    x?: number;
    y?: number;
    dx?: number;
    dy?: number;
    height?: number;
    width?: number;
    datum?: SensorValue;
    title?: string;
    module: ModuleType;
    unit?: string;
}
const TooltipBar: React.FunctionComponent<TooltipBarInterface> = ({
    y = 0,
    x = 0,
    datum = { value: 0, timestamp: 0 },
    title,
    module,
    unit,
}) => {
    const total = useMemo(() => calcTotal(datum.value, module), [datum.value]);

    return (
        <g style={{ pointerEvents: 'none' }}>
            <foreignObject x={x} y={y - 60} width={100} height={'100%'}>
                <>
                    <TooltipCard module={module}>
                        {title && <TooltipTitle>{title}</TooltipTitle>}
                        <TooltipContent>
                            {total}
                            <span>{unit}</span>
                        </TooltipContent>
                    </TooltipCard>
                </>
            </foreignObject>
        </g>
    );
};

export default TooltipBar;

export const calcTotal = (value: number, module: string) => {
    if (!value) {
        return 0;
    }

    if (module === ModuleType.WATER) {
        return (+value.toFixed(4) * 1000).toFixed();
    }

    return value === Math.floor(value) ? value : value.toFixed(2);
};
