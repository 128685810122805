import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { Feedback } from 'components/DataPane/statistics/feedback/types';
import { ModuleType, Sensor } from 'types';

import { createFromToDate, DateSpan } from 'utils/timePeriod';

export const useFeedbackData = () => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['feedback'], async ({ signal }): Promise<{ sensors: Sensor[]; feedback: Feedback[] }> => {
        const promiseArray = [
            config.sensorsListViewByModule(ModuleType.AIR),
            config.feedback(createFromToDate(DateSpan.YEAR).from),
        ].map(url => axiosClient.get(url, { signal }));
        const result = await Promise.all(promiseArray);

        return { sensors: result[0].data, feedback: result[1].data };
    });
};
