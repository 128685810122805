import React, { useMemo, useRef } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Column, useRowSelect, useSortBy, useTable } from 'react-table';

import { FeedbackStyledTable } from 'styled/components/table';

import { Feedback } from 'components/DataPane/statistics/feedback/types';

import { monthDayFormat } from 'utils/timePeriod';

type FeedbackTableData = {
    when: string;
    message: string;
};

interface FeedbackTableProps {
    feedback: Feedback[];
}

const FeedbackTable: React.FunctionComponent<FeedbackTableProps> = ({ feedback }) => {
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();
    const tableData: FeedbackTableData[] = feedback.map(({ createdUtc, metric }) => ({
        when: format(new Date(createdUtc), monthDayFormat),
        message: metric.message || '',
    }));

    const tableHeaders: Column<FeedbackTableData>[] = [
        {
            Header: () => t('table.when'),
            accessor: 'when',
            disableSortBy: true,
        },
        {
            Header: () => t('table.message'),
            accessor: 'message',
            disableSortBy: true,
        },
    ];

    const data = useMemo(() => tableData, [tableData]);

    const columns = useMemo(() => tableHeaders, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<FeedbackTableData>(
        {
            columns,
            data,
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
            autoResetSelectedRows: false,
        },
        useSortBy,
        useRowSelect
    );

    return (
        <FeedbackStyledTable ref={tableWrapperRef}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps({
                                    style: {
                                        height: '50px',
                                        backgroundColor: 'transparent',
                                    },
                                })}
                                key={index}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td {...cell.getCellProps()} key={index}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </FeedbackStyledTable>
    );
};

export default FeedbackTable;
