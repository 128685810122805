import React from 'react';

import { useTranslation } from 'react-i18next';

import { getThreshold } from 'styled/components/card';
import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import waterCircle from '../../../../assets/icons/i-circle-water.svg';
import averageCircle from '../../../../assets/icons/i-circle-water_average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { formatDuration } from 'utils/formatDuration';
import { Incident, KpiUnit } from 'types';

interface InfoHeaderProp {
    incident: Incident;
    isLoading?: boolean;
}
const WaterZeroConsumption: React.FunctionComponent<InfoHeaderProp> = ({ incident, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{getThreshold(incident)}</H2White>
                <CaptionLefWhite>{t('infoHeader.common.detected')}</CaptionLefWhite>
            </div>
            <div>
                {formatDuration(incident.duration) && (
                    <>
                        <H2White>{formatDuration(incident.duration)}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.water.durationOfZero')} <br />
                            {t('infoHeader.water.consumption')}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
            <div>
                <LegendIcon src={waterCircle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: KpiUnit.L })}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.common.loadingAverage', { unit: KpiUnit.L })}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.common.averageOverTime', { unit: KpiUnit.L })}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default WaterZeroConsumption;
