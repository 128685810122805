import React, { FunctionComponent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import Card from 'components/Card';
import DashboardSubMenu from 'components/Submenu/DashboardIssuesSubMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import Paginator, { takeSize } from 'components/Paginator';

import { CardContainer } from 'styled/components/card';
import { DashboardContainer } from 'styled/components/view';
import { H3White } from 'styled/components/text';

import { useTenant } from 'context/TenantContext';
import { useAllIncidentsData } from 'hooks/api/useAllIncidentsData';

import { Incident } from 'types';
import { last24Hours } from 'utils/timePeriod';

const DashboardView: FunctionComponent = () => {
    const { t } = useTranslation();
    const { modules } = useTenant();
    const [skipItems, setSkipItems] = useState(0);
    const [period, setPeriod] = useState(last24Hours);
    const [allIncidents, setAllIncidents] = useState<Array<Incident>>([]);

    const { data, isLoading } = useAllIncidentsData(modules, period);

    useEffect(() => {
        if (data) {
            const itemsToSkip = data.length > skipItems ? skipItems : 0;

            setAllIncidents(data);
            setSkipItems(itemsToSkip);
        }
    }, [data]);

    return (
        <>
            <DashboardSubMenu action={period} onSelectAction={setPeriod} />

            <DashboardContainer>
                {isLoading ? <LoadingOverlay dark={true} /> : null}

                {Boolean(allIncidents.length) && !isLoading && (
                    <>
                        <CardContainer>
                            {allIncidents.slice(skipItems, skipItems + takeSize).map((i, index) => (
                                <Card incident={i} key={index} />
                            ))}
                        </CardContainer>
                        <Paginator totalItems={allIncidents.length} skipItems={skipItems} setSkipItems={setSkipItems} />
                    </>
                )}

                {!allIncidents.length && !isLoading && (
                    <>
                        <H3White>{t('view.dashboard.noIncidents')}</H3White>
                    </>
                )}
            </DashboardContainer>
        </>
    );
};

export default DashboardView;
