import React from 'react';

import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

import ButtonGroup, { ButtonGroupItem } from 'components/ButtonGroup';

import { SubBarWrapper, SubMenuText, SubNavBarWrapper } from 'styled/components/navigation';

import { SensorType } from 'types';

const issueItems: ButtonGroupItem[] = [
    { label: i18n.t('subMenu.energyStatistics.electricity'), action: SensorType.ELECTRICITY },
    { label: i18n.t('subMenu.energyStatistics.cooling'), action: SensorType.COOLING },
    { label: i18n.t('subMenu.energyStatistics.heating'), action: SensorType.HEATING },
];

interface EnergyStatisticsSubMenu {
    selectedSensorType: SensorType;
    onSelectSensorType: (action: SensorType) => void;
}

const EnergyStatisticsSubMenu: React.FunctionComponent<EnergyStatisticsSubMenu> = ({
    selectedSensorType,
    onSelectSensorType,
}) => {
    const { t } = useTranslation();

    return (
        <SubBarWrapper>
            <SubNavBarWrapper>
                <SubMenuText>{t('subMenu.energyStatistics.system')}</SubMenuText>
                <ButtonGroup
                    inverted={false}
                    buttonGroupItems={issueItems}
                    selectedItem={selectedSensorType}
                    selectedAction={action => onSelectSensorType(action as SensorType)}
                />
            </SubNavBarWrapper>
        </SubBarWrapper>
    );
};

export default EnergyStatisticsSubMenu;
