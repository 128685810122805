import React from 'react';

import { useTranslation } from 'react-i18next';

import { getThreshold } from 'styled/components/card';
import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import energyCircle from '../../../../assets/icons/i-circle-e.svg';
import averageCircle from '../../../../assets/icons/i-circle-e-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { formatDuration } from 'utils/formatDuration';
import { Incident, KpiUnit } from 'types';

interface InfoHeaderProp {
    incident: Incident;
    isLoading?: boolean;
}
const EnergyAboveAverageConsumption: React.FunctionComponent<InfoHeaderProp> = ({ incident, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{getThreshold(incident)}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.deviation')}
                    <br />
                    {t('infoHeader.common.detected')}
                </CaptionLefWhite>
            </div>
            <div>
                {formatDuration(incident.duration) && (
                    <>
                        <H2White>{formatDuration(incident.duration)}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.common.total')} <br />
                            {t('infoHeader.common.duration')}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
            <div>
                <LegendIcon src={energyCircle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: KpiUnit.KWH })}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={averageCircle} />
                        <CaptionLefWhite>
                            {t('infoHeader.common.loadingAverage', { unit: KpiUnit.KWH })}
                        </CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={averageCircle} />
                        <CaptionLefWhite>
                            {t('infoHeader.common.averageOverTime', { unit: KpiUnit.KWH })}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default EnergyAboveAverageConsumption;
