import {
    format,
    getUnixTime,
    startOfDay,
    startOfMonth,
    subDays,
    subWeeks,
    subMonths,
    subYears,
    addDays,
    isAfter,
    addMonths,
    isEqual,
} from 'date-fns';

import { DateSpan, isDaysTimespan, isWeeksTimespan } from 'utils/timePeriod';

import { SensorAverage } from 'types';
import { GraphData } from 'components/Graph/types';
import {
    mapAverageByDayOfWeek,
    mapAverageByHourOfDayOfWeek,
    deduplicateValues,
    calcAxisDates,
    getMax,
    getMin,
    mapSensorValue,
} from 'components/Graph/graph.helpers';

import { FeedbackGraphData, FeedbackTimeserie, Feedback, MergedFeedback } from './types';

export const createGraphData = (data: SensorAverage[], timespan: string, averages?: SensorAverage[]) => {
    const sensorData = data.map(mapSensorValue);

    const averageSensorData = averages
        ? sensorData.map(d => {
              return isWeeksTimespan(timespan)
                  ? mapAverageByDayOfWeek(d, averages)
                  : mapAverageByHourOfDayOfWeek(d, averages);
          })
        : [];

    const tickValues = createFeedbackTickValues(timespan);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const timeFormat = timespan === DateSpan.MONTH ? 'yyyy-MM-dd' : 'yyyy-MM-dd HH';

    const graphData: GraphData = {
        data: sensorData,
        averageData: deduplicateValues(averageSensorData, timeFormat),
        tickValues,
        tickAmount,
        tickFormat,
        timespan,
        max,
        min,
        axisDates,
    };
    return graphData;
};
export const createFeedbackGraphData = (data: Feedback[], timespan: string) => {
    const mergedFeedback: MergedFeedback[] = mergeFeedbackData(data, timespan);
    const feedbackTimeserie: FeedbackTimeserie[] = mergedFeedback.map(mapMergedFeedback);

    const tickValues = createFeedbackTickValues(timespan);

    const graphData: FeedbackGraphData = {
        data: feedbackTimeserie,
        timespan,
        tickValues,
    };
    return graphData;
};

const mergeFeedbackData = (feedback: Feedback[], timespan: string) => {
    const timeFormat = isWeeksTimespan(timespan) ? 'yyyy-MM-dd ' : 'yyyy-MM';

    const combineSameTimestamp = (mergedFeedback: MergedFeedback[], feedback: Feedback) => {
        const hasFeedbackMetrics = Boolean(Object.values(feedback.metric).filter(v => v).length);

        if (hasFeedbackMetrics) {
            const currentFeedbackTimestamp = format(new Date(feedback.createdUtc), timeFormat);
            const lastFeedbacTimestamp = mergedFeedback.length
                ? format(new Date(mergedFeedback[mergedFeedback.length - 1].date * 1000), timeFormat)
                : '';

            if (currentFeedbackTimestamp === lastFeedbacTimestamp) {
                const lastFeedbackIndex = mergedFeedback.length - 1;
                mergedFeedback[lastFeedbackIndex] = updateMergedFeedback(mergedFeedback[lastFeedbackIndex], feedback);
                return mergedFeedback;
            }
            const newMergedFeedback = createMergedFeedback(feedback, timespan);
            return mergedFeedback.concat(newMergedFeedback);
        }

        return mergedFeedback;
    };

    const mergedFeedback = feedback.reduce(combineSameTimestamp, []);
    return mergedFeedback;
};

const mapMergedFeedback = (feedback: MergedFeedback) => {
    const { date, temperature, co2, humidity, noiseLevel, lightIntensity, messages, totalFeedback } = feedback;

    return {
        timestamp: date,
        temperature: temperature.length ? averageScores(temperature) : 0,
        temperatureTotalFeedback: temperature.length,
        co2: co2.length ? averageScores(co2) : 0,
        co2TotalFeedback: co2.length,
        humidity: humidity.length ? averageScores(humidity) : 0,
        humidityTotalFeedback: humidity.length,
        noiseLevel: noiseLevel.length ? averageScores(noiseLevel) : 0,
        noiseLevelTotalFeedback: noiseLevel.length,
        lightIntensity: lightIntensity.length ? averageScores(lightIntensity) : 0,
        lightIntensityTotalFeedback: lightIntensity.length,
        messages: messages,
        totalFeedback: totalFeedback,
    };
};

const updateMergedFeedback = (mergedFeedback: MergedFeedback, feedback: Feedback) => {
    const { co2, temperature, humidity, noiseLevel, lightIntensity, message } = feedback.metric;

    const updatedFeedback = {
        ...mergedFeedback,
        temperature: temperature ? mergedFeedback.temperature.concat(temperature) : mergedFeedback.temperature,
        co2: co2 ? mergedFeedback.co2.concat(co2) : mergedFeedback.co2,
        humidity: humidity ? mergedFeedback.humidity.concat(humidity) : mergedFeedback.humidity,
        noiseLevel: noiseLevel ? mergedFeedback.noiseLevel.concat(noiseLevel) : mergedFeedback.noiseLevel,
        lightIntensity: lightIntensity
            ? mergedFeedback.lightIntensity.concat(lightIntensity)
            : mergedFeedback.lightIntensity,
        messages: message ? mergedFeedback.messages.concat(message) : mergedFeedback.messages,
        totalFeedback: ++mergedFeedback.totalFeedback,
    };

    return updatedFeedback;
};

const createMergedFeedback = (feedback: Feedback, timespan: string) => {
    const startOf = timespan === DateSpan.YEAR ? startOfMonth : startOfDay;
    const { co2, temperature, humidity, noiseLevel, lightIntensity, message } = feedback.metric;

    return {
        date: getUnixTime(startOf(new Date(feedback.createdUtc))),
        temperature: temperature ? [temperature] : [],
        co2: co2 ? [co2] : [],
        humidity: humidity ? [humidity] : [],
        noiseLevel: noiseLevel ? [noiseLevel] : [],
        lightIntensity: lightIntensity ? [lightIntensity] : [],
        messages: message ? [message] : [],
        totalFeedback: 1,
    };
};

const averageScores = (scores: number[]) => {
    return Number((scores.reduce((a, b) => a + b, 0) / scores.length).toFixed(1));
};

const createFeedbackTickValues = (timespan: string): number[] => {
    const startOf = timespan === DateSpan.YEAR ? startOfMonth : startOfDay;
    const subtract = timespan === DateSpan.YEAR ? subMonths : subDays;
    const add = timespan === DateSpan.YEAR ? addMonths : addDays;
    const stopDate = getStopDate(timespan);
    const dateTicks = [];

    let currentDate = startOf(add(new Date(), 1));
    while (isAfter(currentDate, stopDate) || isEqual(currentDate, stopDate)) {
        dateTicks.unshift(getUnixTime(new Date(currentDate)));
        currentDate = subtract(new Date(currentDate), 1);
    }

    return dateTicks;
};

const getStopDate = (timespan: string) => {
    const startOf = timespan === DateSpan.YEAR ? startOfMonth : startOfDay;

    switch (timespan) {
        case DateSpan.WEEK:
            return startOf(subWeeks(new Date(), 1));
        case DateSpan.MONTH:
            return startOf(subMonths(new Date(), 1));
        case DateSpan.YEAR:
            return startOf(subYears(new Date(), 1));
        default:
            return startOf(new Date());
    }
};
