import { theme } from 'styled/Theme';
import styled, { css } from 'styled-components';

export const LinkWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
`;

export const PaginatorWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 20px;
`;

export const NavigationButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.colors.grey};
    ${({ disabled }) =>
        disabled
            ? css`
                  visibility: hidden;
              `
            : css`
                  cursor: pointer;
              `};
    height: 40px;
    border-radius: 5px;
    border: 0;
    overflow: hidden;
    padding: 0px;
    padding-inline: 12px;
    font-weight: bold;
    color: ${theme.colors.greyD};
`;

interface PageButtonProps {
    isActive?: boolean;
}

export const PageButton = styled.button<PageButtonProps>`
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 5px;
    border: 0;
    overflow: hidden;
    background-color: ${({ isActive }) => (isActive ? theme.colors.greyD : theme.colors.grey)};
    color: ${({ isActive }) => (isActive ? theme.colors.white : theme.colors.greyD)};
    font-weight: bold;
`;

interface PageTextProps {
    isActive?: boolean;
}

export const PageText = styled.p<PageTextProps>`
    ${({ isActive }) =>
        isActive
            ? css`
                  color: ${theme.colors.white};
              `
            : css`
                  color: ${theme.colors.greyD};
              `};

    margin-inline: 5px;
    font-weight: bold;
`;
