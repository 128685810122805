import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorSubType, SensorType, SpaceSumEnergyConsumption } from 'types';

import { Space } from 'views/authenticated/types';

export const useRoomsUsageData = (buildingStructure: Space[], from: string, to: string, sensorType: SensorType) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['roomUsage', sensorType, from, to],
        async ({ signal }): Promise<{ usage: SpaceSumEnergyConsumption[] }> => {
            const promiseArray = createRoomEndpoints(buildingStructure, from, to, sensorType).map(url =>
                axiosClient.get<SpaceSumEnergyConsumption>(url, { signal })
            );
            const result = await Promise.all(promiseArray);

            return { usage: result.map(r => r.data) };
        },
        {
            enabled: Boolean(buildingStructure.length),
        }
    );
};

const createRoomEndpoints = (
    buildingStructure: Space[],
    from: string,
    to: string,
    sensorType: SensorType
): string[] => {
    const urlArray: string[] = [];

    buildingStructure.forEach(building => {
        urlArray.push(config.energyUsageExtendedDataView(building.id, sensorType, SensorSubType.CONSUMPTION, from, to));
    });
    return urlArray;
};
