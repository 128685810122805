import { createRef, RefObject, useCallback, useEffect, useRef } from 'react';

export const useScollInView = <T extends HTMLElement>(
    data: any[],
    wrapperDiv: RefObject<HTMLDivElement>,
    index: number | undefined
) => {
    const elementsRef = useRef<Array<React.RefObject<T>>>(data.map(() => createRef<T>()));

    useEffect(() => {
        if (data.length > 0 && elementsRef.current.length > 0 && (index === 0 || index)) {
            scrollSelectedItemInView();
        }
    }, [index]);

    const scrollSelectedItemInView = useCallback(() => {
        if (index) {
            const boundingRectWrapper = wrapperDiv.current?.getBoundingClientRect();
            const boundingRect = elementsRef.current[index]?.current?.getBoundingClientRect();
            if (
                (boundingRect && boundingRectWrapper && boundingRect.bottom > boundingRectWrapper.bottom) ||
                (boundingRect && boundingRectWrapper && boundingRect.top < boundingRectWrapper.top)
            ) {
                elementsRef.current[index].current?.scrollIntoView({ block: 'center' });
            }
        }
    }, [index, wrapperDiv]);

    return elementsRef;
};
