import React from 'react';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import ButtonGroup, { ButtonGroupItem } from 'components/ButtonGroup';

import {
    FacilitiesBarWrapper,
    SubBarWrapper,
    SubBarWrapperBlack,
    SubNavBarWrapper,
    SubTitleWrapper,
} from 'styled/components/navigation';
import { BodyLeft, H3 } from 'styled/components/text';

import { lastWeek, last24Hours, lastMonth } from 'utils/timePeriod';
import SubNavigationBar from './SubNavigation/SubNavigationBar';
import { routes } from 'routing/routes';

const dashboardButtonItems: ButtonGroupItem[] = [
    { label: i18n.t('subMenu.dashboard.today'), action: last24Hours },
    { label: i18n.t('subMenu.dashboard.thisWeek'), action: lastWeek },
    { label: i18n.t('subMenu.dashboard.month'), action: lastMonth },
];

const subNavItems = [
    {
        path: routes.dashboard.facilities,
        active: false,
        enabled: true,
        label: i18n.t('subMenu.dashboard.facilities'),
    },
    {
        path: routes.dashboard.issues,
        active: false,
        enabled: true,
        label: i18n.t('subMenu.dashboard.issues'),
    },
];

interface DashBoardSubMenuProps {
    action: string;
    onSelectAction: (date: string) => void;
}

const DashboardSubMenu: React.FunctionComponent<DashBoardSubMenuProps> = ({ action, onSelectAction }) => {
    const { t } = useTranslation();

    return (
        <>
            <SubBarWrapper>
                <SubNavBarWrapper>
                    <SubNavigationBar navigationItems={subNavItems} />
                </SubNavBarWrapper>
            </SubBarWrapper>
            <SubBarWrapperBlack>
                <SubTitleWrapper>
                    <H3>{t('subMenu.dashboard.issuesTitle')}</H3>
                </SubTitleWrapper>
                <FacilitiesBarWrapper>
                    <>
                        <BodyLeft>{t('common.time.period')}</BodyLeft>
                        <ButtonGroup
                            selectedItem={action}
                            inverted={true}
                            buttonGroupItems={dashboardButtonItems}
                            selectedAction={action => {
                                onSelectAction(action);
                            }}
                        />
                    </>
                </FacilitiesBarWrapper>
            </SubBarWrapperBlack>
        </>
    );
};

export default DashboardSubMenu;
