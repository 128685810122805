import React, { useState } from 'react';
import { routes } from 'routing/routes';

import { useTranslation } from 'react-i18next';

import { useAuth } from 'context/AuthContext';

import TextInput from 'components/TextInput';
import MainWrapper from 'components/Container/MainWrapper';
import LinkButton from 'components/Button/LinkButton';

import { H2, BodyLeft, CaptionLeftNegative } from 'styled/components/text';
import { LoginWrapper } from 'styled/components/view';
import { SubmitButton } from 'styled/components/buttons';

import { isValidEmail } from 'utils';

interface LoginValidationErrors {
    password: string;
    email: string;
}

const LoginView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validationErrors, setValidationErrors] = useState<LoginValidationErrors>({ password: '', email: '' });
    const { authState, login, isLoading } = useAuth();

    const validateForm = () => {
        const errors = getValidationErrors();

        setValidationErrors(errors);

        return Object.values(errors).filter(e => e !== '').length === 0;
    };

    const getValidationErrors = () => {
        const errors: LoginValidationErrors = {
            password: '',
            email: '',
        };
        if (!email || !password) {
            errors.email = email ? '' : t('login.required');
            errors.password = password ? '' : t('login.required');
        }

        if (email) {
            const validEmail = isValidEmail(email);
            errors.email = validEmail ? '' : t('login.incorrectEmailFormat');
        }

        return errors;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            login(email, password);
        }
    };

    return (
        <MainWrapper>
            <LoginWrapper>
                <H2>{t('login.buildingInsight')}</H2>
                <BodyLeft>{t('login.byUnica')}</BodyLeft>
                <br />
                <br />
                <br />
                <form onSubmit={handleSubmit}>
                    <TextInput
                        label={t('login.email')}
                        input={email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setEmail(e.target.value);
                        }}
                        hasError={Boolean(authState.error || validationErrors.email)}
                    />
                    {validationErrors.email ? (
                        <CaptionLeftNegative>{validationErrors.email}</CaptionLeftNegative>
                    ) : null}
                    <TextInput
                        label={t('login.password')}
                        input={password}
                        type={'password'}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(e.target.value);
                        }}
                        hasError={Boolean(authState.error || validationErrors.password)}
                    />
                    {validationErrors.password ? (
                        <CaptionLeftNegative>{validationErrors.password}</CaptionLeftNegative>
                    ) : null}
                    {authState.error ? <CaptionLeftNegative>{authState.error}</CaptionLeftNegative> : null}
                    <br />
                    <SubmitButton disabled={isLoading} type={'submit'}>
                        {t('login.submit')}
                    </SubmitButton>
                </form>
                <br />
                <LinkButton to={routes.password_link} text={t('login.forgotPassword')} />
            </LoginWrapper>
        </MainWrapper>
    );
};

export default LoginView;
