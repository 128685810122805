import 'typeface-roboto';
import { ModuleType, SensorType } from 'types';

import airIcon from '../assets/icons/i-m-air.svg';
import eneryIcon from '../assets/icons/i-m-energy.svg';
import mobilityIcon from '../assets/icons/i-m-mobility.svg';
import occupancyIcon from '../assets/icons/i-m-occupancy.svg';
import waterIcon from '../assets/icons/i-m-water.svg';
import cleanIcon from '../assets/icons/i-m-clean.svg';
import feedbackIcon from '../assets/icons/i-m-feedback.svg';

declare module 'styled-components' {
    type Theme = typeof theme;
    export interface DefaultTheme extends Theme {}
}

export const getColor = (module?: ModuleType, sensorType?: SensorType) => {
    switch (module) {
        case ModuleType.AIR:
            return theme.colors.air;
        case ModuleType.ENERGY:
            if (sensorType === SensorType.HEATING) {
                return theme.colors.mOccupL;
            }
            if (sensorType === SensorType.COOLING) {
                return theme.colors.airLTransparent;
            }
            return theme.colors.mEnergy;
        case ModuleType.MOBILITY:
            return theme.colors.mMobility;
        case ModuleType.OCCUPANCY:
            return theme.colors.mOccup;
        case ModuleType.WATER:
            return theme.colors.mWater;
        case ModuleType.SENSORS:
            return theme.colors.bg;
        case ModuleType.FEEDBACK:
            return theme.colors.mFeedback;
        case ModuleType.GAS:
            return theme.colors.mWaterL;
        default:
            return theme.colors.text;
    }
};

export const getLightColor = (module?: ModuleType, sensorType?: SensorType) => {
    switch (module) {
        case ModuleType.AIR:
            return theme.colors.airL;
        case ModuleType.ENERGY:
            if (sensorType === SensorType.HEATING) {
                return theme.colors.mOccupL;
            }
            if (sensorType === SensorType.COOLING) {
                return theme.colors.airLTransparent;
            }
            return theme.colors.mEnergyL;
        case ModuleType.MOBILITY:
            return theme.colors.mMobilityL;
        case ModuleType.OCCUPANCY:
            return theme.colors.mOccupL;
        case ModuleType.WATER:
            return theme.colors.mWaterL;
        case ModuleType.SENSORS:
            return theme.colors.grey;
        case ModuleType.FEEDBACK:
            return theme.colors.mFeedbackL;
        default:
            return theme.colors.grey;
    }
};

export const getTransparentColor = (module?: ModuleType) => {
    switch (module) {
        case ModuleType.AIR:
            return theme.colors.airLTransparent;
        case ModuleType.ENERGY:
            return theme.colors.mEnergyLTransparent;
        case ModuleType.MOBILITY:
            return theme.colors.mMobilityLTransparent;
        case ModuleType.OCCUPANCY:
            return theme.colors.mOccupLTransparent;
        case ModuleType.WATER:
            return theme.colors.mWaterLTransparent;
        case ModuleType.FEEDBACK:
            return theme.colors.mFeedbackLTransparent;
        case ModuleType.SENSORS:
            return theme.colors.airLTransparent;
    }
};

export const getColorByPercentage = (percentage: number) => {
    if (percentage < 5) {
        return theme.colors.greyD;
    }
    if (percentage < 10) {
        return theme.colors.positiveL;
    }
    if (percentage < 15) {
        return theme.colors.occ10;
    }
    if (percentage < 25) {
        return theme.colors.occ20;
    }
    if (percentage < 35) {
        return theme.colors.occ30;
    }
    if (percentage < 45) {
        return theme.colors.occ40;
    }
    if (percentage < 55) {
        return theme.colors.occ50;
    }
    if (percentage < 65) {
        return theme.colors.occ60;
    }
    if (percentage < 75) {
        return theme.colors.occ70;
    }
    if (percentage < 85) {
        return theme.colors.occ80;
    }
    if (percentage < 95) {
        return theme.colors.occ90;
    }
    return theme.colors.mOccup;
};

export function getIcon(module: ModuleType) {
    switch (module) {
        case ModuleType.AIR:
            return airIcon;
        case ModuleType.ENERGY:
            return eneryIcon;
        case ModuleType.MOBILITY:
            return mobilityIcon;
        case ModuleType.OCCUPANCY:
            return occupancyIcon;
        case ModuleType.WATER:
            return waterIcon;
        case ModuleType.SENSORS:
            return cleanIcon;
        case ModuleType.FEEDBACK:
            return feedbackIcon;
    }
}

export const theme = {
    colors: {
        text: '#2a2e33',
        grey: '#d6d6de',
        greyD: '#434951',
        greyDTransparent: 'rgb(67, 73, 81, 0.3)',
        bg: '#f0f2f7',
        white: '#fff',
        mEnergy: '#fda040',
        mEnergyTransparent: 'rgb(253, 160, 64, 0.3)',
        mEnergyL: '#fed9b3',
        mEnergyLTransparent: 'rgb(254, 217, 179, 0.3)',
        mEnergyMediumL: '#febd7a',
        mEnergyMediumLTransparent: 'rgb(254, 189, 122, 0.3)',
        mEnergyRosybrown: '#b49e86',
        mEnergyRosybrownTransparent: 'rgb(180, 158, 134, 0.3)',
        mEnergyBrown: '#685036',
        mEnergyBrownTransparent: 'rgb(104, 80, 54, 0.3)',
        mWater: '#396bd7',
        mWaterL: '#b0c4ef',
        mWaterLTransparent: 'rgb(176,196,239, 0.2)',
        air: '#6f5ce3',
        airL: '#c5bef4',
        airLTransparent: 'rgb(197,190,244, 0.5)',
        mOccup: '#f95a78',
        mOccupL: '#fdbdc9',
        mOccupLTransparent: 'rgb(253,189,201, 0.5)',
        occ10: '#73c7ad',
        occ20: '#96cbae',
        occ30: '#b9d0b0',
        occ40: '#ddd5b2',
        occ50: '#fed9b3',
        occ60: '#fdc5aa',
        occ70: '#fdb2a1',
        occ80: '#fca099',
        occ90: '#fc8c90',
        mMobility: '#10b3c4',
        mMobilityL: '#9fe1e7',
        mMobilityLTransparent: 'rgb(159,225,231, 0.5)',
        mClean: '#059e81',
        mCleanL: '#9bd8cd',
        mCleanLTransparent: 'rgb(155,216,205, 0.5)',
        mFeedback: '#b35bad',
        mFeedbackL: '#e1bdde',
        mFeedbackLTransparent: 'rgb(225, 189, 222, 0.3)',
        negative: '#f3656f',
        negativeL: '#f5848c',
        negativeLHighlight: 'rgba(245, 132, 140, 0.3)',
        positive: '#009c7d',
        positiveL: '#51c2ab',
        ebsColor1: '#6dc5ab',
        ebsColor2: '#e15172',
        ebsColor3: '#e2a850',
        ebsColor4: '#73bd61',
        ebsColor5: '#8484ae',
        ebsColor6: '#afafb8',
        ebsColor7: '#e6e7ec',
        scatterColor1: '#585987',
        scatterColor2: '#dd962c',
    },
    fonts: {
        body: 'Roboto, sans-serif',
    },
    reactDatepicker: {
        daySize: [36, 40],
        fontFamily: 'Roboto, sans-serif',
        colors: {
            accessibility: '#c5bef4',
            selectedDay: '#c5bef4',
            selectedDayHover: '#c5bef4',
            primaryColor: '#6f5ce3',
            white: '#2a2e33',
            normalDayHover: '#c5bef4',
        },
        dayBorderColor: '#2a2e33',
        datepickerBackground: '#2a2e33',
        monthLabelColor: '2a2e33',

        closeHoverColor: '#fff',

        resetDatesIconColor: '#fff',
        resetDatesTextColor: '#fff',

        inputColor: '#fff',
        inputMinHeight: '40px',
        inputFontSize: '14px',

        dateRangeDatepickerWrapperRight: '0px',
        dateRangeDatepickerWrapperLeft: '0px',

        selectDateDateColor: '#fff',
        selectDateDateFontSize: '14px',
    },
};

export enum ColorMode {
    DARK,
    LIGHT,
}
