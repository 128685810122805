import React, { FunctionComponent, useEffect, useReducer } from 'react';

import DataPaneMenu from '../DataPaneMenu';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { IncidentGraphData } from 'components/Graph/types';

import {
    ContentWrapper,
    IncidentGraphWrapper,
    InfoHeader,
    IncidentsPane,
    getAnomalyIcon,
    getIncidentTitle,
} from '../../../styled/components/dataPane';
import { H3White } from 'styled/components/text';

import { isEmpty } from 'utils/isEmptyObject';

import { Incident, SensorAvgFilter } from 'types';
import {
    createIncidentGraphData,
    getAvgFilterByModule,
    getIncidentInfoHeader,
    showDefaultBarChart,
} from './incidents.helpers';
import { WaterIncidentDataAction, WaterIncidentDataState } from './types';
import { createIncidentDateRange, createIncidentFromToDate, DateSpan, twoDays } from 'utils/timePeriod';
import { useWaterSensorData } from 'hooks/api/useWaterSensorData';

interface DataPaneProps {
    incident: Incident;
    renderGraph: (graphData: IncidentGraphData, isBarChart: boolean) => JSX.Element;
}
const WaterIncidentsDataPane: FunctionComponent<DataPaneProps> = ({ incident, renderGraph }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { action, selectedIncident, sensorDataParams, graphData, isBarChart } = state;

    const { data, isLoading } = useWaterSensorData(sensorDataParams, incident);

    useEffect(() => {
        if (data) {
            dispatch({ type: 'SET_GRAPH_DATA', data: data, averageData: [] });
        }
    }, [data]);

    useEffect(() => {
        dispatch({ type: 'CHANGE_INCIDENT', selectedIncident: incident });
    }, [incident]);

    const onToggleGraph = (isBarChart: boolean) => {
        dispatch({ type: 'TOGGLE_CHART', isBarChart });
    };

    return (
        <IncidentsPane>
            {!isEmpty(selectedIncident) && (
                <>
                    <DataPaneMenu
                        title={getIncidentTitle(selectedIncident.anomalySubType)}
                        icon={getAnomalyIcon(selectedIncident.anomalySubType)}
                        selectedAction={action}
                        onSelectAction={timespan => dispatch({ type: 'CHANGE_DATE_SPAN', dataSpan: timespan })}
                        module={selectedIncident.moduleType}
                        canToggleGraph={true}
                        isBarChart={isBarChart}
                        allowCustomPeriod={false}
                        onToggleGraph={onToggleGraph}
                    />
                </>
            )}

            <ContentWrapper>
                {(isLoading || graphData === null) && <LoadingOverlay dark={true} isSmall={true} />}
                {!isLoading && graphData?.data.length === 0 && <H3White>No data for selected period</H3White>}
                {!isLoading && graphData?.data.length && (
                    <>
                        <InfoHeader>{getIncidentInfoHeader(selectedIncident, false)}</InfoHeader>
                        <IncidentGraphWrapper>{renderGraph(graphData, isBarChart)}</IncidentGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </IncidentsPane>
    );
};

export default WaterIncidentsDataPane;

export const initialState: WaterIncidentDataState = {
    data: null,
    sensorDataParams: {
        id: '',
        ids: [],
        from: '',
        to: '',
        averageFilter: SensorAvgFilter.HOUR,
    },
    graphData: null,
    selectedIncident: {} as Incident,
    action: DateSpan.TWO_DAYS,
    isBarChart: false,
};

const reducer = (state: WaterIncidentDataState, action: WaterIncidentDataAction): WaterIncidentDataState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const graphData = createIncidentGraphData(
                action.data,
                action.averageData,
                state.selectedIncident,
                state.action,
                state.sensorDataParams.from,
                state.sensorDataParams.to,
                state.isBarChart
            );

            return {
                ...state,
                data: action.data,
                graphData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const averageFilter = getAvgFilterByModule(action.dataSpan, state.isBarChart);
            const { from, to } = createIncidentFromToDate(action.dataSpan, state.selectedIncident.timestampStart);
            return {
                ...state,
                action: action.dataSpan,
                graphData: null,
                sensorDataParams: { ...state.sensorDataParams, from, to, averageFilter },
            };
        }
        case 'CHANGE_INCIDENT': {
            const isBarChart = showDefaultBarChart(action.selectedIncident.moduleType);

            const sensorDataParams = {
                id: action.selectedIncident.pointId,
                ids: action.selectedIncident.sensorIds || [],
                ...createIncidentDateRange(action.selectedIncident.timestampStart, twoDays),
                averageFilter: getAvgFilterByModule(DateSpan.TWO_DAYS, isBarChart),
            };
            const isSameData = JSON.stringify(sensorDataParams) === JSON.stringify(state.sensorDataParams);

            return {
                ...state,
                action: DateSpan.TWO_DAYS,
                selectedIncident: action.selectedIncident,
                isBarChart,
                graphData: isSameData
                    ? createIncidentGraphData(
                          state.data,
                          [],
                          action.selectedIncident,
                          state.action,
                          state.sensorDataParams.from,
                          state.sensorDataParams.to,
                          state.isBarChart
                      )
                    : null,
                sensorDataParams,
            };
        }
        case 'TOGGLE_CHART': {
            return {
                ...state,
                isBarChart: action.isBarChart,
                graphData: null,
                sensorDataParams: {
                    ...state.sensorDataParams,
                    averageFilter: getAvgFilterByModule(state.action, action.isBarChart),
                },
            };
        }
    }
};
