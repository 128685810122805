import React from 'react';

import { useTranslation } from 'react-i18next';

import { SuggestionItem, SuggestionWrapper } from 'styled/components/searchAutoComplete';
import { SpaceSuggestion } from '.';

export interface SuggestionsListProps {
    filteredSuggestions: SpaceSuggestion[];
    suggestionIndex: number;
    onSelectSuggestion: (index: number) => void;
}
const Suggestions: React.FunctionComponent<SuggestionsListProps> = ({
    filteredSuggestions,
    suggestionIndex,
    onSelectSuggestion,
}) => {
    const { t } = useTranslation();

    return (
        <SuggestionWrapper>
            {filteredSuggestions.length ? (
                <ul>
                    {filteredSuggestions.map((suggestion, index) => (
                        <SuggestionItem
                            active={index === suggestionIndex}
                            key={index}
                            onClick={() => onSelectSuggestion(index)}
                        >
                            {suggestion.name}
                            <br />
                            {suggestion.location.length > 1 ? (
                                <em>{t('search.location', { location: suggestion.location.join(' > ') })}</em>
                            ) : null}
                        </SuggestionItem>
                    ))}
                </ul>
            ) : (
                <div>
                    <em>{t('search.noMatches')}</em>
                </div>
            )}
        </SuggestionWrapper>
    );
};

export default Suggestions;
