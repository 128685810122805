import React from 'react';

import WaterIncidentsDataPane from 'components/DataPane/incidents/WaterIncidentsDataPane';
import IncidentsDataPane from 'components/DataPane/incidents/IncidentsDataPane';
import { navItemsWater } from 'components/Submenu/ModulesSubMenu';
import IssuesOverview from 'components/IssuesOverview';
import WaterIncidentsGraph from 'components/Graph/incidents/WaterIncidentsGraph';
import WaterNoMotionsIncidentGraph from 'components/Graph/incidents/WaterNoMotionIncidentGraph';
import WaterHighTemperatureIncidentGraph from 'components/Graph/incidents/WaterHighTemperatureIncidentGraph';

import { AnomalySubTypeWater, Incident, ModuleType } from 'types';
import { IncidentGraphData } from 'components/Graph/types';

const WaterIssuesView: React.FunctionComponent = () => {
    return (
        <IssuesOverview
            module={ModuleType.WATER}
            subNavItems={navItemsWater}
            renderDataPane={(incident: Incident) => {
                if (
                    incident.anomalySubType === AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION ||
                    incident.anomalySubType === AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE
                ) {
                    return (
                        <WaterIncidentsDataPane
                            incident={incident}
                            renderGraph={(graphData: IncidentGraphData, isBarChart: boolean) => {
                                return incident.anomalySubType ===
                                    AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION ? (
                                    <WaterNoMotionsIncidentGraph graphData={graphData} isBarChart={isBarChart} />
                                ) : (
                                    <WaterHighTemperatureIncidentGraph graphData={graphData} isBarChart={isBarChart} />
                                );
                            }}
                        />
                    );
                }

                return (
                    <IncidentsDataPane
                        incident={incident}
                        renderGraph={(graphData: IncidentGraphData, isBarChart: boolean) => {
                            return <WaterIncidentsGraph graphData={graphData} isBarChart={isBarChart} />;
                        }}
                    />
                );
            }}
        />
    );
};

export default WaterIssuesView;
