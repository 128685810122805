import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { OccupancyScore, Sensor, SensorData, SensorSubType } from 'types';
import { OccupancyDataParam } from 'components/DataPane/statistics/occupancy/types';

export const useOccupancyData = (occupancyParams: OccupancyDataParam, occupancySensors: Sensor[]) => {
    const { axiosClient } = useAxiosClient();

    const selectedOccupancyCountSensors = occupancySensors.filter(
        sensor =>
            sensor.buildingRef === occupancyParams.selectedOccupancyBuilding &&
            sensor.subType === SensorSubType.COUNT &&
            (occupancyParams.selectedOccupancyRoom ? sensor.roomRef === occupancyParams.selectedOccupancyRoom : false)
    );

    return useQuery(
        ['occupancy', occupancyParams],
        async ({ signal }) => {
            let occupancyData: OccupancyScore[] = [];
            let occupancyCountData: SensorData[] = [];

            if (selectedOccupancyCountSensors.length) {
                const { data } = await axiosClient.get<SensorData[]>(
                    config.sensorData(selectedOccupancyCountSensors[0].id, occupancyParams.from, occupancyParams.to),
                    {
                        signal,
                    }
                );
                occupancyCountData = data;
            }
            const { data } = await axiosClient.get<OccupancyScore[]>(getOccupancyEndpoint(occupancyParams), {
                signal,
            });
            occupancyData = data;

            return { occupancyData: occupancyData, occupancyCountData: occupancyCountData };
        },
        {
            enabled: Boolean(occupancyParams.selectedOccupancyBuilding),
        }
    );
};

const getOccupancyEndpoint = (occupancyParams: OccupancyDataParam) => {
    return occupancyParams.selectedOccupancyBuilding && occupancyParams.selectedOccupancyRoom
        ? config.occupancyRoomExpanded(
              occupancyParams.selectedOccupancyBuilding,
              occupancyParams.selectedOccupancyRoom,
              occupancyParams.from,
              occupancyParams.to
          )
        : config.occupancyBuilding(occupancyParams.selectedOccupancyBuilding, occupancyParams.from, occupancyParams.to);
};
