import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { LinkWrapper, NavigationButton, PageButton, PaginatorWrapper } from 'styled/components/pagination';

export const takeSize = 12;
const maxVisiblePages = 5;

const firstIcon = '\u00AB';
const previousIcon = '\u2039';
const nextIcon = '\u203A';
const lastIcon = '\u00BB';

enum PaginatorType {
    FIRST,
    PREVIOUS,
    NEXT,
    LAST,
    PAGE,
}

interface PaginatorInterface {
    skipItems: number;
    totalItems: number;
    setSkipItems(newValue: number): void;
}

const Paginator: React.FunctionComponent<PaginatorInterface> = ({ skipItems, totalItems, setSkipItems }) => {
    const { t } = useTranslation();
    const [pages, setPages] = useState<Array<number>>([]);

    const isPreviousDisabled = (): boolean => {
        return pages.findIndex(page => page === skipItems / takeSize) === 0;
    };

    const isNextDisabled = () => {
        return pages.findIndex(page => page === skipItems / takeSize) === pages.length - 1;
    };

    const calculateSkipParam = (action: PaginatorType, value = takeSize) => {
        switch (action) {
            case PaginatorType.FIRST: {
                setSkipItems(0);
                break;
            }
            case PaginatorType.PREVIOUS: {
                setSkipItems(skipItems - value);
                break;
            }
            case PaginatorType.NEXT: {
                setSkipItems(skipItems + value);
                break;
            }
            case PaginatorType.LAST: {
                const totalPages = Math.floor(totalItems / takeSize);
                setSkipItems(totalPages * takeSize);
                break;
            }
            case PaginatorType.PAGE: {
                setSkipItems(value * takeSize);
                break;
            }
        }
    };

    const createPages = (): Array<number> => {
        const numberOfPages = Math.ceil(totalItems / takeSize);
        const pages = Array.from({ length: numberOfPages }, (_, i) => i);
        if (pages.length > maxVisiblePages) {
            return getPagesSubset(pages);
        }

        return pages;
    };

    const getPagesSubset = (pages: Array<number>) => {
        const maxIndex = 3;
        const activePage = skipItems / takeSize;
        const activePageIndex = pages.indexOf(activePage);
        if (activePageIndex < maxIndex) {
            return pages.slice(0, maxVisiblePages);
        } else if (pages.length - activePageIndex < maxIndex) {
            return pages.slice(-maxVisiblePages);
        }

        return pages.slice(activePageIndex - 2, activePageIndex + 3);
    };

    useEffect(() => {
        setPages(createPages());
    }, [totalItems, skipItems]);

    return (
        <PaginatorWrapper>
            {totalItems > takeSize ? (
                <LinkWrapper>
                    <NavigationButton
                        disabled={isPreviousDisabled()}
                        onClick={() => calculateSkipParam(PaginatorType.FIRST)}
                    >
                        {t('paginator.first', { firstIcon: firstIcon })}
                    </NavigationButton>
                    <NavigationButton
                        disabled={isPreviousDisabled()}
                        onClick={() => calculateSkipParam(PaginatorType.PREVIOUS)}
                    >
                        {t('paginator.previous', { previousIcon: previousIcon })}
                    </NavigationButton>
                    {pages.map((el, index) => (
                        <PageButton
                            key={index}
                            isActive={el === skipItems / takeSize}
                            onClick={() => calculateSkipParam(PaginatorType.PAGE, el)}
                        >
                            {el + 1}
                        </PageButton>
                    ))}
                    <NavigationButton
                        disabled={isNextDisabled()}
                        onClick={() => calculateSkipParam(PaginatorType.NEXT)}
                    >
                        {t('paginator.next', { nextIcon: nextIcon })}
                    </NavigationButton>
                    <NavigationButton
                        disabled={isNextDisabled()}
                        onClick={() => calculateSkipParam(PaginatorType.LAST)}
                    >
                        {t('paginator.last', { lastIcon: lastIcon })}
                    </NavigationButton>
                </LinkWrapper>
            ) : null}
        </PaginatorWrapper>
    );
};

export default Paginator;
