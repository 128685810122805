import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { TooltipCard, FeedbackDate, MetricScore } from 'styled/components/tooltip';
import { ModuleType } from 'types';
import { MetricFeedback, FeedbackTimeserie } from 'components/DataPane/statistics/feedback/types';
import { DateSpan } from 'utils/timePeriod';
import { CaptionLeftGrey } from 'styled/components/text';

const scoreScale = 5;

interface TooltipBarInterface {
    x?: number;
    y?: number;
    dx?: number;
    dy?: number;
    height?: number;
    width?: number;
    dateSpan: string;
    datum?: FeedbackTimeserie;
    metric: MetricFeedback;
}
const TooltipMetricFeedback: React.FunctionComponent<TooltipBarInterface> = ({
    dx = 0,
    x = 0,
    dateSpan,
    metric,
    datum = {
        timestamp: 0,
        temperature: 0,
        co2: 0,
        humidity: 0,
        lightIntensity: 0,
        noiseLevel: 0,
        messages: [],
        totalFeedback: 0,
    },
}) => {
    const { t } = useTranslation();

    return (
        <g style={{ pointerEvents: 'none' }}>
            <foreignObject x={dx + x} y={30} width={200} height={'100%'}>
                <>
                    <TooltipCard module={ModuleType.FEEDBACK}>
                        <FeedbackDate>
                            {t('tooltip.date', { date: formatTime(datum.timestamp, dateSpan) })}
                        </FeedbackDate>
                        <br />
                        <MetricScore>
                            <CaptionLeftGrey>
                                <span>{`${datum[metric]} `}</span>
                                {`${t('tooltip.outOf')} ${scoreScale} `}
                                {t('tooltip.by')}
                                <span>{` ${datum.totalFeedback} `}</span>
                                {datum.totalFeedback > 1 ? t('tooltip.respondents') : t('tooltip.respondent')}
                            </CaptionLeftGrey>
                        </MetricScore>
                    </TooltipCard>
                </>
            </foreignObject>
        </g>
    );
};

export default TooltipMetricFeedback;

const formatTime = (timestamp: number, dateSpan: string) => {
    return dateSpan === DateSpan.YEAR
        ? format(new Date((timestamp || 0) * 1000), 'MMMM yyyy')
        : format(new Date((timestamp || 0) * 1000), 'd MMMM');
};
