import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { IncidentData, ModuleType } from 'types';

import { lastMonth } from 'utils/timePeriod';

export const useIncidentsData = (module: ModuleType) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['incidents', module], async ({ signal }) => {
        const { data } = await axiosClient.get<IncidentData>(config.incidents(module, lastMonth), {
            signal,
        });

        return data.data;
    });
};
