import { calcAxisDates, createTickValues, getMax, getMin } from 'components/Graph/graph.helpers';
import { SensorAverage } from 'types';
import { DateRange, DateSpan, isDaysTimespan } from 'utils/timePeriod';
import { EfficiencyGraphData, ReportGraphData } from './types';
import { SensorValue } from 'components/Graph/types';
import { getUnixTime, parseISO, startOfDay } from 'date-fns';
import { theme } from 'styled/Theme';
import { Space } from 'views/authenticated/types';

export const mapGacsSensorValue = (sensor: SensorAverage): SensorValue => {
    const adjustedDate = startOfDay(parseISO(sensor.field));
    return {
        value: sensor.value,
        timestamp: getUnixTime(adjustedDate),
    };
};

const calculateGradientStops = (min: number, max: number) => {
    const threshold = 95;

    const aboveThreshold = max > threshold;
    const belowThreshold = min < threshold;

    const stops = [];
    if (aboveThreshold) {
        stops.push({ offset: '2%', color: theme.colors.air });
    }
    if (aboveThreshold && belowThreshold) {
        stops.push({ offset: '3%', color: theme.colors.mEnergyL });
    }
    if (belowThreshold) {
        stops.push({ offset: '6%', color: theme.colors.negative });
    }

    return stops;
};

export const createEfficiencyGraphData = (
    data: SensorAverage[],
    action: string,
    timespan: string,
    customDate: DateRange
) => {
    const efficiencyData = data.map(mapGacsSensorValue);
    const tickValues = createTickValues(action, isDaysTimespan(timespan) ? DateSpan.WEEK : timespan, customDate);
    const tickAmount = timespan === DateSpan.YEAR || timespan === DateSpan.MONTH ? 2 : 1;
    const tickFormat = 'MMM dd';
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const max = getMax(efficiencyData);
    const min = getMin(efficiencyData);
    const gradient = calculateGradientStops(min, max);

    const graphData: EfficiencyGraphData = {
        data: efficiencyData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
        gradient,
    };

    return graphData;
};

export const createReportGraphData = (
    data: SensorAverage[][],
    action: string,
    timespan: string,
    customDate: DateRange,
    groups: Space[]
) => {
    const tickValues = createTickValues(action, isDaysTimespan(timespan) ? DateSpan.WEEK : timespan, customDate);
    const tickAmount = timespan === DateSpan.YEAR || timespan === DateSpan.MONTH ? 2 : 1;
    const tickFormat = 'MMM dd';
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphDataItems = data.map((a, i) => {
        const efficiencyData = a.map(mapGacsSensorValue);
        const max = getMax(efficiencyData);
        const min = getMin(efficiencyData);
        const gradient = calculateGradientStops(min, max);
        const group = groups[i];
        const graphData: ReportGraphData = {
            data: efficiencyData,
            tickValues,
            tickAmount,
            tickFormat,
            max,
            min,
            axisDates,
            timespan,
            gradient,
            group,
        };
        return graphData;
    });

    return graphDataItems;
};
