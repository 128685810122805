import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';

import { TableHeader, Row, TableBox, Wrapper, TableContent } from 'styled/components/workingHours';

interface WorkingHoursProps {
    isEditMode: boolean;
    openingHours: Hours;
    editOpeningHours: Hours;
    onChangeHours: (newOpeningHours: Hours) => void;
}
const WorkingHours: React.FunctionComponent<WorkingHoursProps> = ({
    isEditMode,
    openingHours,
    editOpeningHours,
    onChangeHours,
}) => {
    const { t } = useTranslation();

    const handleChange = useCallback(
        (hours: Hours, key: string, fromTo: FromTo) => {
            onChangeHours({
                ...hours,
                [key]: fromTo,
            });
        },
        [onChangeHours]
    );

    const handleBlur = useCallback(
        (value: string, hours: Hours, key: string, fromTo: FromTo) => {
            if ((value.length === 3 || value.length === 4) && value.slice(-3)[0] !== ':') {
                handleChange(hours, key, fromTo);
            }
        },
        [handleChange]
    );

    return (
        <Wrapper>
            <Row>
                <TableHeader />
                <TableHeader>{t('common.time.dayOfWeek.mon')}</TableHeader>
                <TableHeader>{t('common.time.dayOfWeek.tue')}</TableHeader>
                <TableHeader>{t('common.time.dayOfWeek.wed')}</TableHeader>
                <TableHeader>{t('common.time.dayOfWeek.thu')}</TableHeader>
                <TableHeader>{t('common.time.dayOfWeek.fri')}</TableHeader>
                <TableHeader>{t('common.time.dayOfWeek.sat')}</TableHeader>
                <TableHeader>{t('common.time.dayOfWeek.sun')}</TableHeader>
            </Row>
            <Row>
                <TableHeader>{t('company.from')}</TableHeader>
                {orderedDays.map(key => {
                    return (
                        <TableBox key={key}>
                            <TableContent
                                key={key}
                                maxLength={5}
                                type="text"
                                pattern={timeRegex}
                                disabled={!isEditMode}
                                value={
                                    isEditMode
                                        ? editOpeningHours[key as keyof Hours].from
                                        : openingHours[key as keyof Hours].from
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const fromTo = {
                                        from: event.target.value,
                                        to: editOpeningHours[key as keyof Hours].to,
                                    };
                                    handleChange(editOpeningHours, key, fromTo);
                                }}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                    const fromTo = {
                                        from: formatTime(event.target.value),
                                        to: editOpeningHours[key as keyof Hours].to,
                                    };
                                    handleBlur(event.target.value, editOpeningHours, key, fromTo);
                                }}
                            />
                        </TableBox>
                    );
                })}
            </Row>
            <Row>
                <TableHeader>{t('company.till')}</TableHeader>
                {orderedDays.map(key => {
                    return (
                        <TableBox key={key}>
                            <TableContent
                                key={key}
                                maxLength={5}
                                type="text"
                                pattern={timeRegex}
                                disabled={!isEditMode}
                                value={
                                    isEditMode
                                        ? editOpeningHours[key as keyof Hours].to
                                        : openingHours[key as keyof Hours].to
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    const fromTo = {
                                        from: editOpeningHours[key as keyof Hours].from,
                                        to: event.target.value,
                                    };
                                    handleChange(editOpeningHours, key, fromTo);
                                }}
                                onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
                                    const fromTo = {
                                        from: editOpeningHours[key as keyof Hours].from,
                                        to: formatTime(event.target.value),
                                    };
                                    handleBlur(event.target.value, editOpeningHours, key, fromTo);
                                }}
                            />
                        </TableBox>
                    );
                })}
            </Row>
        </Wrapper>
    );
};

export default WorkingHours;

const formatTime = (value: string) => {
    const formattedTime = Array.from(value);
    formattedTime.splice(-2, 0, ':');

    return formattedTime.join('');
};

export interface FromTo {
    from: string;
    to: string;
}

export interface Hours {
    monday: FromTo;
    tuesday: FromTo;
    wednesday: FromTo;
    thursday: FromTo;
    friday: FromTo;
    saturday: FromTo;
    sunday: FromTo;
}

export interface OpeningHours {
    building: string;
    opening_hours: Hours;
}

export interface OpeningHoursPreference {
    opening_hours_preference: OpeningHours[];
}

const timeRegex = '([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]';

const orderedDays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

export const initialOpeningHours: Hours = {
    monday: {
        from: '8:00',
        to: '17:00',
    },
    tuesday: {
        from: '8:00',
        to: '17:00',
    },
    wednesday: {
        from: '8:00',
        to: '17:00',
    },
    thursday: {
        from: '8:00',
        to: '17:00',
    },
    friday: {
        from: '8:00',
        to: '17:00',
    },
    saturday: {
        from: '',
        to: '',
    },
    sunday: {
        from: '',
        to: '',
    },
};

export const getHoursByBuilding = (building: string, opening_hours: OpeningHours[]) => {
    return opening_hours.find(o => o.building === building)?.opening_hours || initialOpeningHours;
};

export const isClosed = (openingHours: FromTo) => {
    return openingHours.from === '' || openingHours.to === '';
};
