import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import DataPaneTitleMenu from '../DataPaneTitleMenu';
import TextInput from 'components/TextInput';

import { InvertedSubmitButton } from 'styled/components/buttons';
import { ContentWrapper, ManagementPane } from 'styled/components/dataPane';
import { CaptionLeftNegative, H3White } from 'styled/components/text';
import { ManagementContentWrapper } from 'styled/components/view';

import { Space } from 'views/authenticated/types';

import { useNameMutation } from 'hooks/api/mutate/useNameMutation';

import managementIcon from '../../../assets/icons/i-m-occupancy.svg';

interface NameValidationErrors {
    name: string;
}

interface Props {
    selectedSpace: Space;
}
const ManagementDataPane: React.FC<Props> = ({ selectedSpace }) => {
    const { mutate, isLoading } = useNameMutation(selectedSpace.id);
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [validationErrors, setValidationErrors] = useState<NameValidationErrors>({ name: '' });

    useEffect(() => {
        if (!isLoading) {
            setName(selectedSpace.name);
        }
    }, [selectedSpace]);

    const validateForm = () => {
        const errors = getValidationErrors();

        setValidationErrors(errors);

        return Object.values(errors).filter(e => e !== '').length === 0;
    };

    const getValidationErrors = () => {
        const errors: NameValidationErrors = {
            name: '',
        };
        if (!name) {
            errors.name = name ? '' : t('management.validation.required');
        }

        if (name) {
            const validName = isValidName(name);
            errors.name = validName ? '' : t('management.validation.invalidNameFormat');
        }

        return errors;
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validateForm()) {
            const payload = {
                name: name,
            };

            mutate(payload);
        }
    };

    return (
        <ManagementPane>
            <DataPaneTitleMenu icon={managementIcon} title={t('management.title', { name: selectedSpace.name })} />
            <ContentWrapper>
                <H3White>{t('management.changeName')}</H3White>
                <ManagementContentWrapper>
                    <form onSubmit={handleSubmit}>
                        <TextInput
                            label={t('management.name')}
                            input={name}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setName(e.target.value);
                            }}
                        />
                        {validationErrors.name ? (
                            <CaptionLeftNegative>{validationErrors.name}</CaptionLeftNegative>
                        ) : null}
                        <br />
                        <InvertedSubmitButton type={'submit'} disabled={isLoading}>
                            {t('management.change')}
                        </InvertedSubmitButton>
                    </form>
                </ManagementContentWrapper>
            </ContentWrapper>
        </ManagementPane>
    );
};

export default ManagementDataPane;

const isValidName = (name: string) => {
    const regex = /^[A-Za-z0-9._\- :]+$/;
    return regex.test(String(name).toLowerCase());
};
