import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryLine,
    VictoryChart,
    VictoryAxis,
    VictoryArea,
    VictoryTooltip,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryBar,
    VictoryLabel,
} from 'victory';
import { format } from 'date-fns';

import TooltipIncident from '../Tooltip/TooltipIncident';
import TooltipBar from '../Tooltip/TooltipBar';

import { getAnomalyUnit } from '../../../styled/components/dataPane';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import { ModuleType } from 'types';
import { closingAxis, closingXYAxis, XYAxis, dateAxis, barChart2AxisPadding } from '../graph.styles';
import {
    formatXAxisIncidentTickValue,
    setBarConfig3Axis,
    sumValues,
    normalizedTickValues,
    formatTick,
    normalizeYValue,
    getMaxSensorValue,
    getLineColor,
} from '../graph.helpers';
import { IncidentGraphProps, SensorValue } from '../types';

const AirQualityIncidentsGraph: React.FunctionComponent<IncidentGraphProps> = ({ graphData, isBarChart }) => {
    const dimension = useResize();
    const { t: translation } = useTranslation();

    const barConfig = setBarConfig3Axis(graphData.timespan);

    const summedOccupancyData = useMemo(() => sumValues(graphData.supportData, graphData.timespan), [graphData]);

    const maxData = useMemo(() => getMaxSensorValue(graphData.data), [graphData]);
    const maxSummedOccupancy = useMemo(() => getMaxSensorValue(summedOccupancyData), [summedOccupancyData]);
    const maxAverage = useMemo(() => getMaxSensorValue(graphData.averageData), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <svg style={{ height: 0, display: 'block' }}>
                <defs>
                    <linearGradient id="lineGradientRedYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.negative} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowRed" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="90%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="35%" stopColor={theme.colors.air} />
                        <stop offset="65%" stopColor={theme.colors.air} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurple" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="40%" stopColor={theme.colors.air} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellow" x2="0%" y2="100%">
                        <stop offset="60%" stopColor={theme.colors.air} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurple" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="77%" stopColor={theme.colors.air} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="23%" stopColor={theme.colors.air} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="95%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="60%" stopColor={theme.colors.air} />
                        <stop offset="80%" stopColor={theme.colors.air} />
                        <stop offset="94%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="20%" stopColor={theme.colors.air} />
                        <stop offset="40%" stopColor={theme.colors.air} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="20%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="50%" stopColor={theme.colors.air} />
                        <stop offset="80%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                padding={barChart2AxisPadding}
                containerComponent={
                    <VictoryZoomVoronoiContainer
                        voronoiBlacklist={['average', 'occupancy']}
                        mouseFollowTooltips={false}
                        voronoiDimension="x"
                        zoomDimension="x"
                        minimumZoom={{ x: 1, y: 0.005 }}
                        labels={() => ' '}
                        labelComponent={
                            <VictoryTooltip
                                dx={d => {
                                    const mousePosition = d.x;
                                    return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                }}
                                flyoutComponent={
                                    <TooltipIncident
                                        module={ModuleType.AIR}
                                        unit={getAnomalyUnit(graphData.anomalySubtype)}
                                        height={dimension.height}
                                        averageData={graphData.averageData}
                                    />
                                }
                            />
                        }
                    />
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    style={XYAxis}
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number) =>
                        formatXAxisIncidentTickValue(t, index, graphData.tickAmount, graphData.tickFormat)
                    }
                />
                {/* Motion Y-axis */}
                <VictoryAxis
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) =>
                        isBarChart ? formatTick(t, i, summedOccupancyData) : formatTick(t, i, graphData.supportData)
                    }
                    label={translation('infoHeader.occupancy.motions')}
                    dependentAxis={true}
                    style={XYAxis}
                    offsetX={40}
                />

                {/* PPM Axis */}
                <VictoryAxis
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) =>
                        formatTick(
                            t,
                            i,
                            [...graphData.data, ...graphData.averageData],
                            getAnomalyUnit(graphData.anomalySubtype)
                        )
                    }
                    dependentAxis={true}
                    style={{ ...XYAxis, axis: { stroke: theme.colors.greyD, strokeWidth: 1 } }}
                    tickLabelComponent={<VictoryLabel verticalAnchor="middle" textAnchor="start" x={50} />}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                <VictoryArea
                    data={[
                        {
                            x: graphData.brushArea[0],
                            y: 0,
                            y0: 1.1,
                        },
                        {
                            x: graphData.brushArea[1],
                            y: 0,
                            y0: 1.1,
                        },
                    ]}
                    style={{
                        data: {
                            fill: theme.colors.airLTransparent,
                        },
                    }}
                />

                <VictoryLine
                    name="average"
                    animate={true}
                    interpolation="monotoneX"
                    x="timestamp"
                    y={(datum: SensorValue) => normalizeYValue(datum.value, maxAverage)}
                    style={{
                        data: { stroke: theme.colors.airLTransparent },
                    }}
                    data={graphData.averageData}
                />
                <VictoryLine
                    interpolation="monotoneX"
                    x="timestamp"
                    y={(datum: SensorValue) => normalizeYValue(datum.value, maxData)}
                    style={{
                        data: { stroke: getLineColor(graphData.type, graphData.min, graphData.max) },
                    }}
                    data={graphData.data}
                />

                <VictoryBar
                    name="occupancy"
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={ModuleType.AIR}
                                    title={translation('infoHeader.occupancy.motions')}
                                />
                            }
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mOccup },
                    }}
                    x="timestamp"
                    y={(datum: SensorValue) => normalizeYValue(datum.value, maxSummedOccupancy)}
                    data={summedOccupancyData}
                />
            </VictoryChart>
        </>
    );
};

export default React.memo(AirQualityIncidentsGraph);
