import React, { useMemo, useRef } from 'react';

import { Column, useRowSelect, useSortBy, useTable, IdType, usePagination, Row } from 'react-table';

import { EmptySortIcon, SortIcon } from 'styled/components/buttons';
import { StyledTable, EmptyTD, EmptyTH } from 'styled/components/table';

import arrowUpIcon from '../../assets/icons/i-arrow-up.svg';
import arrowDownIcon from '../../assets/icons/i-arrow-down.svg';
import Paginator, { takeSize } from 'components/Paginator';
import { useScollInView } from 'hooks/useScollInView';

interface WithId extends Record<string, unknown> {
    id: string;
}

interface TableProps<T extends WithId> {
    selectedRow?: Record<IdType<T>, boolean>;
    tableData: T[];
    tableHeaders: Column<T>[];
    hiddenColumns: string[];
    handleSelect: (id: string, timestamp: string) => void;
}

const Table = <T extends WithId>({
    selectedRow,
    tableData,
    tableHeaders,
    hiddenColumns,
    handleSelect,
}: TableProps<T>) => {
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const rowIndex = useRef<number>(null);

    const data: T[] = useMemo(() => tableData, [tableData]);
    const columns = useMemo<Column<T>[]>(() => tableHeaders, []);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        gotoPage,
        prepareRow,
        toggleAllRowsSelected,
        state: { pageIndex },
    } = useTable<T>(
        {
            columns,
            data,
            initialState: {
                pageIndex: getPageIndex(selectedRow),
                pageSize: takeSize,
                hiddenColumns: hiddenColumns,
                ...(selectedRow && { selectedRowIds: selectedRow }),
                sortBy: [
                    {
                        id: 'timestamp',
                        desc: false,
                    },
                ],
            },
        },
        useSortBy,
        usePagination,
        useRowSelect
    );

    const handleRowSelect = (index: number, row: Row<T>) => {
        rowIndex.current = pageIndex * takeSize + index;
        toggleAllRowsSelected(false);
        row.toggleRowSelected(true);
        handleSelect(row.values.id, row.values.timestampId);
    };

    const elementRefs = useScollInView<HTMLTableRowElement>(tableData, tableWrapperRef, rowIndex.current);

    return (
        <StyledTable ref={tableWrapperRef}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            <EmptyTH />

                            {headerGroup.headers.map((column, index) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                                    {column.render('Header')}
                                    <span>
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <SortIcon src={arrowUpIcon} />
                                            ) : (
                                                <SortIcon src={arrowDownIcon} />
                                            )
                                        ) : column.canSort ? (
                                            <EmptySortIcon src={arrowDownIcon} />
                                        ) : (
                                            ''
                                        )}
                                    </span>
                                </th>
                            ))}
                            <EmptyTH />
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps({
                                    style: {
                                        height: '50px',
                                        backgroundColor: row.isSelected ? 'white' : 'transparent',
                                    },
                                })}
                                onClick={() => handleRowSelect(index, row)}
                                ref={elementRefs.current[pageIndex * takeSize + index]}
                                key={index}
                            >
                                <EmptyTD />
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td {...cell.getCellProps()} key={index}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                                <EmptyTD />
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Paginator
                totalItems={tableData.length}
                skipItems={pageIndex * takeSize}
                setSkipItems={items => gotoPage(items / takeSize)}
            />
        </StyledTable>
    );
};

export default Table;

const getPageIndex = (selectedRow: Record<string, boolean> | undefined) => {
    return selectedRow ? Math.floor(parseInt(Object.keys(selectedRow)[0]) / takeSize) : 0;
};
