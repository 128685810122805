import React, { useMemo } from 'react';

import { VictoryAxis, VictoryChart, VictoryLine, VictoryScatter } from 'victory';

import { useResize } from 'hooks/useResize';
import { EBSElecTempGasGraphData, SensorValue } from '../types';

import { theme } from 'styled/Theme';
import { XYAxis } from '../graph.styles';
import { KpiUnit } from 'types';
import { formatTick, getMaxSensorValue, normalizedTickValues, normalizeYValue } from '../graph.helpers';

const temperatureTicks = [-15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40];

interface Props {
    showTrend: boolean;
    graphData: EBSElecTempGasGraphData;
}
const GasVsElectricityScatter: React.FunctionComponent<Props> = ({ showTrend, graphData }) => {
    const dimension = useResize();

    const maxElectricity = useMemo(() => getMaxSensorValue(graphData.electricityData), [graphData]);
    const maxGas = useMemo(() => getMaxSensorValue(graphData.gasData), [graphData]);

    return (
        <VictoryChart
            width={dimension.width * 0.7}
            height={dimension.height * 0.29}
            padding={{ right: 40, left: 40, bottom: 20, top: 20 }}
            minDomain={{ y: 0 }}
        >
            {/* Y-axis gas */}
            <VictoryAxis
                dependentAxis={true}
                crossAxis={false}
                tickFormat={(t: number, index: number) => formatTick(t, index, graphData.gasData, KpiUnit.M3)}
                style={XYAxis}
                axisValue={temperatureTicks[0]}
                tickValues={normalizedTickValues}
            />

            {/* Y-axis electricity */}
            <VictoryAxis
                orientation="right"
                dependentAxis={true}
                crossAxis={false}
                tickFormat={(t: number, index: number) => formatTick(t, index, graphData.electricityData, KpiUnit.KWH)}
                style={{ ...XYAxis, grid: { strokeWidth: 0 } }}
                axisValue={temperatureTicks[temperatureTicks.length - 1]}
                tickValues={normalizedTickValues}
            />

            <VictoryScatter
                x="timestamp"
                y={(datum: SensorValue) => normalizeYValue(datum.value, maxElectricity)}
                size={1.5}
                style={{ data: { fill: theme.colors.scatterColor1 } }}
                data={graphData.electricityData}
            />
            <VictoryScatter
                x="timestamp"
                y={(datum: SensorValue) => normalizeYValue(datum.value, maxGas)}
                size={1.5}
                style={{ data: { fill: theme.colors.scatterColor2 } }}
                data={graphData.gasData}
            />

            {showTrend && (
                <VictoryLine
                    y={datum => normalizeYValue(datum.y, maxGas)}
                    data={graphData.trendLineGas}
                    style={{
                        data: {
                            stroke: theme.colors.grey,
                        },
                    }}
                />
            )}
            {showTrend && (
                <VictoryLine
                    y={datum => normalizeYValue(datum.y, maxElectricity)}
                    data={graphData.trendLineElectricity}
                    style={{
                        data: {
                            stroke: theme.colors.grey,
                        },
                    }}
                />
            )}

            {/* X-axis */}
            <VictoryAxis
                style={{ ...XYAxis, grid: { stroke: 'none' } }}
                tickValues={temperatureTicks}
                tickFormat={(t: number, index: number, arr: number[]) => (arr.length - 1 === index ? KpiUnit.C : t)}
            />
        </VictoryChart>
    );
};

export default React.memo(GasVsElectricityScatter);

const getValueUnit = (graphData: EBSElecTempGasGraphData) => {
    return graphData.gasData.length ? KpiUnit.M3 : KpiUnit.KWH;
};
