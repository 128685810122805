import React from 'react';

import SearchAutoComplete, { SpaceSuggestion } from 'components/SearchAutoComplete';

import { SubBarWrapper } from 'styled/components/navigation';

interface SensorsSubMenu {
    suggestions: SpaceSuggestion[];
    onSearch: (suggestion: SpaceSuggestion) => void;
    onClearSearch?: () => void;
}
const ManagementSubMenu: React.FunctionComponent<SensorsSubMenu> = ({ suggestions, onSearch, onClearSearch }) => {
    return (
        <SubBarWrapper>
            <SearchAutoComplete
                suggestions={suggestions}
                onSearch={onSearch}
                onClearSearch={() => {
                    onClearSearch && onClearSearch();
                }}
            />
        </SubBarWrapper>
    );
};

export default ManagementSubMenu;
