import axios from 'axios';
import { config } from '../config';
import { AuthData } from '../context/types';

export const client = axios.create({
    baseURL: config.oauth.basePath,
    responseType: 'json',
});

export enum GrantType {
    PASSWORD = 'password',
    REFRESH_TOKEN = 'refresh_token',
    DELEGATION = 'delegation',
}

export const postLogin = (email: string, password: string) => {
    const payload = new URLSearchParams();
    payload.append('client_id', config.oauth.clientId);
    payload.append('client_secret', config.oauth.clientSecret);
    payload.append('grant_type', GrantType.PASSWORD);
    payload.append('username', email);
    payload.append('password', password);

    return client.post<AuthData>(config.oauth.token, payload);
};

export const getPersistance = () => {
    client.defaults.withCredentials = true;
    return client.get<{ auth: AuthData }>(config.oauth.persistence, {
        headers: { Authorization: config.oauth.apiKey },
    });
};

export const postPersistance = (auth: AuthData) => {
    client.post(config.oauth.persistence, { auth }, { headers: { Authorization: config.oauth.apiKey } });
};

export const postRefreshToken = (refreshToken: string, tenantId?: string) => {
    const payload = new URLSearchParams();
    payload.append('client_id', config.oauth.clientId);
    payload.append('client_secret', config.oauth.clientSecret);
    payload.append('grant_type', GrantType.REFRESH_TOKEN);
    payload.append('refresh_token', refreshToken || '');
    payload.append('TenantId', tenantId || '');

    return client.post<AuthData>(config.oauth.token, payload);
};

export const revocateLogin = (refreshToken: string) => {
    const payload = new URLSearchParams();
    payload.append('client_id', config.oauth.clientId);
    payload.append('client_secret', config.oauth.clientSecret);
    payload.append('token_type_hint', GrantType.REFRESH_TOKEN);
    payload.append('token', refreshToken);

    client.post(config.oauth.revocation, payload).then(() => {
        deletePersistance();
    });
};

const deletePersistance = () => {
    client.delete(config.oauth.persistence, {
        headers: { Authorization: config.oauth.apiKey },
    });
};

export const postResetPassword = (email: string) => {
    return client.post(config.oauth.resetPassword, {
        client_id: config.oauth.clientId,
        client_secret: config.oauth.clientSecret,
        email,
    });
};

export const postUpdatePassword = (token: string, password1: string, password2: string) => {
    return client.post(config.oauth.updatePassword, {
        client_id: config.oauth.clientId,
        client_secret: config.oauth.clientSecret,
        token,
        newPassword: password1,
        newPassword2: password2,
    });
};

export const delegateToken = (azToken: string) => {
    const payload = new URLSearchParams();
    payload.append('client_id', config.oauth.clientId);
    payload.append('client_secret', config.oauth.clientSecret);
    payload.append('grant_type', GrantType.DELEGATION);
    payload.append('token', azToken);

    return client.post(config.oauth.token, payload);
};
