import React from 'react';

import { StyledLoadingIndicator } from 'styled/components/loadingIndicator';

const LoadingIndicator: React.FunctionComponent = () => {
    return (
        <StyledLoadingIndicator>
            <div />
            <div />
            <div />
        </StyledLoadingIndicator>
    );
};

export default LoadingIndicator;
