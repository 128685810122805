import React, { useState } from 'react';
import { ColorMode } from 'styled/Theme';

import { StyledTextarea } from '../../styled/components/inputs';

interface TextareaInterface extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    input?: string | number | readonly string[];
    label: string;
    hasError?: boolean;
    mode?: ColorMode;
}
const Textarea: React.FunctionComponent<TextareaInterface> = ({ input, label, mode, hasError = false, ...rest }) => {
    const [hasFocus, setFocus] = useState(false);
    const [isAutoFill, setIsAutoFill] = useState(false);

    const handleAnimation = (e: React.AnimationEvent<HTMLTextAreaElement>) => {
        if (e.animationName === 'bZz') {
            setIsAutoFill(true);
        }
    };

    const handleFocus = () => {
        setFocus(true);
        setIsAutoFill(false);
    };

    return (
        <StyledTextarea mode={mode} active={Boolean(input) || hasFocus || isAutoFill} hasError={hasError}>
            <textarea
                {...rest}
                value={input}
                onAnimationStart={e => handleAnimation(e)}
                onBlur={() => setFocus(false)}
                onFocus={handleFocus}
            />
            <label>{label}</label>
        </StyledTextarea>
    );
};

export default Textarea;
