import React from 'react';

import { useTranslation } from 'react-i18next';
import { theme } from 'styled/Theme';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendButtonIcon, ToggleLegend } from 'styled/components/dataPane';

import legendIcon from '../../../../../assets/icons/i-circle-default.svg';
import { KpiUnit } from 'types';

interface InfoHeaderProp {
    gasConsumption: number;
    hasHistoricData: boolean;
    deviationPercentage: number;
    showGas: boolean;
    onShowGas: (showGas: boolean) => void;
    showGasHistory: boolean;
    onShowGasHistory: (showGasHistory: boolean) => void;
}
const GasConsumptionHeader: React.FunctionComponent<InfoHeaderProp> = ({
    gasConsumption,
    deviationPercentage,
    hasHistoricData,
    showGas,
    showGasHistory,
    onShowGas,
    onShowGasHistory,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{`${gasConsumption} ${KpiUnit.M3}`}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.totalGas')}
                    <br />
                    {t('infoHeader.common.consumption')}
                </CaptionLefWhite>
            </div>
            {hasHistoricData && (
                <div>
                    <H2White>{`${deviationPercentage}${KpiUnit.PERCENTAGE}`}</H2White>
                    <CaptionLefWhite>
                        {t('infoHeader.energy.deviationFrom')}
                        <br />
                        {t('infoHeader.energy.lastYear')}
                    </CaptionLefWhite>
                </div>
            )}
            <div>
                <ToggleLegend active={showGas} onClick={() => onShowGas(!showGas)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mWater} />
                    <CaptionLefWhite>{t('infoHeader.energy.gasConsumption')}</CaptionLefWhite>
                </ToggleLegend>
                {hasHistoricData && (
                    <ToggleLegend active={showGasHistory} onClick={() => onShowGasHistory(!showGasHistory)}>
                        <LegendButtonIcon src={legendIcon} color={theme.colors.airLTransparent} />
                        <CaptionLefWhite>{t('infoHeader.energy.gasConsumptionLastYear')}</CaptionLefWhite>
                    </ToggleLegend>
                )}
            </div>
        </>
    );
};

export default GasConsumptionHeader;
