import React from 'react';

import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

import SubNavigationBar from './SubNavigation/SubNavigationBar';
import SearchAutoComplete, { SpaceSuggestion } from 'components/SearchAutoComplete';

import { SubBarWrapper, SubMenuText, SubNavBarWrapper } from 'styled/components/navigation';
import { routes } from 'routing/routes';

const subNavItems = [
    {
        path: routes.sensors.overview,
        active: false,
        enabled: true,
        label: i18n.t('subMenu.sensors.all'),
    },
    {
        path: routes.sensors.issues,
        active: false,
        enabled: true,
        label: i18n.t('subMenu.sensors.issues'),
    },
];

interface SensorsSubMenu {
    suggestions: SpaceSuggestion[];
    onSearch: (suggestion: SpaceSuggestion) => void;
    onClearSearch?: () => void;
}
const SensorsSubMenu: React.FunctionComponent<SensorsSubMenu> = ({ suggestions, onSearch, onClearSearch }) => {
    const { t } = useTranslation();

    return (
        <SubBarWrapper>
            <SubNavBarWrapper>
                <SubMenuText>{t('subMenu.sensors.sensors')}</SubMenuText>
                <SubNavigationBar navigationItems={subNavItems} />
            </SubNavBarWrapper>
            <SearchAutoComplete
                suggestions={suggestions}
                onSearch={onSearch}
                onClearSearch={() => {
                    onClearSearch && onClearSearch();
                }}
            />
        </SubBarWrapper>
    );
};

export default SensorsSubMenu;
