import React, { useCallback, useEffect, useReducer, useState } from 'react';

import { Feedback } from 'components/DataPane/statistics/feedback/types';
import FeedbackStatsDataPane from 'components/DataPane/statistics/feedback/FeedbackStatsDataPane';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import SearchAutoComplete, { SpaceSuggestion } from 'components/SearchAutoComplete';
import SensorSelector from 'components/SensorSelector';

import { SubBarWrapper } from 'styled/components/navigation';

import { useFeedbackData } from 'hooks/api/useFeedbackData';
import { useTenant } from 'context/TenantContext';

import { SensorSelection, Space } from '../types';
import { FeedbackAction, FeedbackState } from './types';
import { getSelectedSpace, createSensorSelection } from '../sensors/SensorsOverviewView';

const FeedbackStatisticsView: React.FunctionComponent = () => {
    const { getBuildingData } = useTenant();
    const { data, isLoading } = useFeedbackData();
    const [state, dispatch] = useReducer(FeedbackStatisticsReducer, initialState);
    const [isExpanded, setIsExpanded] = useState(false);
    const { feedback, breadCrumbs, selectedSensors, equipOverview, suggestions, selectedRoom } = state;

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                sensorData: data.sensors,
                feedbackData: data.feedback,
            });
        }
    }, [data]);

    const hasFeedback = useCallback(() => {
        return feedback.find(f => f.roomId === selectedRoom);
    }, [feedback, selectedRoom]);

    return (
        <>
            <SubBarWrapper>
                <SearchAutoComplete
                    breadCrumbs={isExpanded && Boolean(selectedRoom) ? breadCrumbs : []}
                    suggestions={suggestions}
                    onSearch={selectedSpace =>
                        dispatch({
                            type: 'SEARCH_SENSOR',
                            selectedSpace: selectedSpace,
                        })
                    }
                />
            </SubBarWrapper>

            {isLoading && <LoadingOverlay />}

            {Boolean(equipOverview.length) && (
                <SensorSelector
                    equips={equipOverview}
                    onEquipSelect={(selectAction, roomId) => dispatch({ type: selectAction, spaceId: roomId })}
                    collapsed={Boolean(hasFeedback())}
                />
            )}
            {Boolean(selectedRoom) && Boolean(feedback.length) && Boolean(hasFeedback()) && (
                <FeedbackStatsDataPane
                    feedback={feedback}
                    selectedRoom={selectedRoom}
                    feedbackSensors={selectedSensors}
                    onExpand={setIsExpanded}
                />
            )}
        </>
    );
};

export default FeedbackStatisticsView;

export const initialState: FeedbackState = {
    buildingStructure: [],
    feedback: [],
    suggestions: [],
    sensors: [],
    equipOverview: [],
    selectedSensors: [],
    selectedRoom: '',
    breadCrumbs: [],
};

const emptyList = { spaces: [] as Space[], selectedSpace: '' };

const FeedbackStatisticsReducer = (state: FeedbackState, action: FeedbackAction): FeedbackState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorSelection = localStorage.getItem('feedback-selection');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;
            const selectedSpace = getSelectedSpace(parsedSelection);

            const filteredFeedbackData = action.feedbackData.filter(
                feedback =>
                    !Object.values(feedback.metric).every(value => {
                        if (value === null) {
                            return true;
                        }

                        return false;
                    })
            );

            const filteredBuildingData = action.buildingData.filter(building =>
                Boolean(filteredFeedbackData.filter(f => f.buildingId === building.id).length)
            );

            filteredBuildingData.forEach(building => {
                recursiveFeedbackCheck(building.rooms, filteredFeedbackData);
            });

            filteredBuildingData.forEach(building => {
                recursiveFilterRooms(building);
            });

            const suggestions: SpaceSuggestion[] = [];
            filteredBuildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);

                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            const selectedBuilding = filteredBuildingData.find(i => i.id === parsedSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === parsedSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId5
            );
            const selectedSixthLevelRoom = selectedFifthLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId6
            );

            const breadCrumbs = [
                selectedBuilding?.name,
                selectedSecondLevelRoom?.name,
                selectedThirdLevelRoom?.name,
                selectedFourthLevelRoom?.name,
                selectedFifthLevelRoom?.name,
                selectedSixthLevelRoom?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: selectedSpace,
                suggestions: suggestions,
                sensors: action.sensorData,
                buildingStructure: filteredBuildingData,
                feedback: filteredFeedbackData,
                equipOverview: [
                    {
                        spaces: filteredBuildingData || [],
                        selectedSpace: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId2 ? parsedSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId3 ? parsedSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId4 ? parsedSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId5 ? parsedSelection.selectedId5 : '',
                    },
                    {
                        spaces: selectedFifthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId6 ? parsedSelection.selectedId6 : '',
                    },
                ],
                selectedSensors: action.sensorData.filter(
                    s => s.roomRef === selectedSpace && s.latestKpi.value !== null
                ),
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE1': {
            localStorage.setItem(
                'feedback-selection',
                JSON.stringify({
                    selectedId1: action.spaceId,
                })
            );

            const breadCrumbs = [state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.name].filter(
                (i): i is string => {
                    return typeof i === 'string';
                }
            );

            return {
                ...state,
                selectedRoom: '',
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedSensors: [],
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE2': {
            localStorage.setItem(
                'feedback-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: action.spaceId,
                equipOverview: [
                    state.equipOverview[0],
                    {
                        spaces: state.equipOverview[1].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE3': {
            localStorage.setItem(
                'feedback-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: action.spaceId,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    {
                        spaces: state.equipOverview[2].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE4': {
            localStorage.setItem(
                'feedback-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[2].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: action.spaceId,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    {
                        spaces: state.equipOverview[3].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
                breadCrumbs,
            };
        }
        case 'SELECT_SPACE5': {
            localStorage.setItem(
                'feedback-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[2].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === state.equipOverview[3].selectedSpace)?.name,
                state.equipOverview[4].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: action.spaceId,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    {
                        spaces: state.equipOverview[4].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[4].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
                breadCrumbs,
            };
        }

        case 'SELECT_SPACE6': {
            localStorage.setItem(
                'feedback-selection',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: state.equipOverview[4].selectedSpace,
                    selectedId6: action.spaceId,
                })
            );

            const breadCrumbs = [
                state.equipOverview[0].spaces.find(i => i.id === state.equipOverview[0].selectedSpace)?.name,
                state.equipOverview[1].spaces.find(i => i.id === state.equipOverview[1].selectedSpace)?.name,
                state.equipOverview[2].spaces.find(i => i.id === state.equipOverview[2].selectedSpace)?.name,
                state.equipOverview[3].spaces.find(i => i.id === state.equipOverview[3].selectedSpace)?.name,
                state.equipOverview[4].spaces.find(i => i.id === state.equipOverview[4].selectedSpace)?.name,
                state.equipOverview[5].spaces.find(i => i.id === action.spaceId)?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: action.spaceId,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    state.equipOverview[4],
                    {
                        spaces: state.equipOverview[5].spaces,
                        selectedSpace: action.spaceId,
                    },
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === action.spaceId && s.latestKpi.value !== null),
                breadCrumbs,
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);
            const selectedSpace = getSelectedSpace(sensorSelection);
            localStorage.setItem('feedback-selection', JSON.stringify(sensorSelection));

            const selectedBuilding = state.buildingStructure.find(i => i.id === sensorSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === sensorSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId5
            );
            const selectedSixthLevelRoom = selectedFifthLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId6
            );

            const breadCrumbs = [
                selectedBuilding?.name,
                selectedSecondLevelRoom?.name,
                selectedThirdLevelRoom?.name,
                selectedFourthLevelRoom?.name,
                selectedFifthLevelRoom?.name,
                selectedSixthLevelRoom?.name,
            ].filter((i): i is string => {
                return typeof i === 'string';
            });

            return {
                ...state,
                selectedRoom: selectedSpace,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId2 ? sensorSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId3 ? sensorSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId4 ? sensorSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId5 ? sensorSelection.selectedId5 : '',
                    },
                    {
                        spaces: selectedFifthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection?.selectedId6 ? sensorSelection.selectedId6 : '',
                    },
                ],
                selectedSensors: state.sensors.filter(s => s.roomRef === selectedSpace && s.latestKpi.value !== null),
                breadCrumbs,
            };
        }
    }
};

const recursiveCreateSuggestions = (
    spaces: Space[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        const i = [...ids];
        i.push(r.id);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.rooms, suggestions, i, l);
    });
};

const recursiveFeedbackCheck = (spaces: Space[], feedback: Feedback[]) => {
    if (spaces.length === 0) {
        return;
    }

    spaces.forEach(r => {
        if (feedback.findIndex(f => f.roomId === r.id) !== -1) {
            r.hasFeedback = true;
        }
        if (hasChildFeedbackCheck(r.rooms, feedback, false)) {
            r.hasFeedback = true;
        }

        recursiveFeedbackCheck(r.rooms, feedback);
    });
};

const hasChildFeedbackCheck = (spaces: Space[], feedback: Feedback[], hasFeedback: boolean) => {
    let error = hasFeedback;
    if (spaces.length === 0 || error) {
        return error;
    }
    spaces.forEach(r => {
        if (feedback.findIndex(f => f.roomId === r.id) !== -1) {
            error = true;
        }
        error = hasChildFeedbackCheck(r.rooms, feedback, error);
    });

    return error;
};

const recursiveFilterRooms = (space: Space) => {
    if (space.rooms.length === 0) {
        return;
    }
    space.rooms = space.rooms.filter(space => space.hasFeedback);

    space.rooms.forEach(s => recursiveFilterRooms(s));
};
