import React from 'react';

import { Button, DataPaneButton } from 'styled/components/buttons';

import { ModuleType } from 'types';

export interface ButtonGroupItem {
    label: string;
    action: string;
}

interface ButtonGroupInterface {
    module?: ModuleType;
    inverted?: boolean;
    buttonGroupItems: ButtonGroupItem[];
    selectedItem: string;
    selectedAction: (action: string) => void;
}

const ButtonGroup: React.FunctionComponent<ButtonGroupInterface> = ({
    module,
    inverted = false,
    buttonGroupItems,
    selectedItem = '',
    selectedAction,
}) => {
    const handleClick = (action: string) => {
        if (selectedItem !== action) {
            selectedAction(action);
        }
    };

    return (
        <>
            {buttonGroupItems.map((button, i) => {
                return module ? (
                    <DataPaneButton
                        module={module}
                        key={i}
                        active={button.action === selectedItem}
                        onClick={() => handleClick(button.action)}
                    >
                        {button.label}
                    </DataPaneButton>
                ) : (
                    <Button
                        inverted={inverted}
                        key={i}
                        active={button.action === selectedItem}
                        onClick={() => handleClick(button.action)}
                    >
                        {button.label}
                    </Button>
                );
            })}
        </>
    );
};

export default ButtonGroup;
