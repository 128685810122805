import React from 'react';

import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';

import {
    AppImageContainer,
    EnergyContainer,
    FeedbackAppContainer,
    FeedbackGrid,
    FeedbackImageContainer,
    FeedbackStatisticsContainer,
    FeedbackStatisticGrid,
    GetContainer,
    HeaderContainer,
    HeaderGrid,
    HexaconBackground,
    Icon,
    IconContainer,
    IconWrapper,
    IncidentWrapper,
    ModuleContainer,
    ModuleIcon,
    ModuleText,
    ModuleTitle,
    PreviewLead,
    PreviewText,
    PreviewGrid,
    SoonSpan,
    SupportBackground,
    SupportContentContainer,
    SupportGrid,
} from 'styled/components/preview';
import { H3White } from 'styled/components/text';

import energyIcon from '../../../assets/icons/i-m-energy.svg';
import appScreen from '../../../assets/images/preview/f-preview-app.webp';
import energyScreen from '../../../assets/images/preview/f-preview-screen.webp';
import moduleIcon from '../../../assets/icons/preview-module-icon-feedback.svg';

import { ModuleType } from 'types';

import { config } from 'config';

const FeedbackPreviewView: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderGrid>
                <GetContainer />
                <EnergyContainer>
                    <ModuleTitle module={ModuleType.FEEDBACK}>Feedback</ModuleTitle>
                </EnergyContainer>
            </HeaderGrid>
            <PreviewGrid>
                <SupportGrid>
                    <IconContainer>
                        <SupportBackground />
                        <IconWrapper>
                            <ModuleIcon src={moduleIcon} />
                        </IconWrapper>
                    </IconContainer>
                    <ModuleContainer>
                        <ModuleText>module</ModuleText>
                    </ModuleContainer>
                    <SupportContentContainer>
                        <PreviewText>{t('preview.feedback.support')}</PreviewText>
                        <PreviewText>{t('preview.support1')}</PreviewText>
                        <PreviewText>
                            {t('preview.support2')} <span style={{ fontWeight: 'bold' }}>{config.profile.mail}</span>
                        </PreviewText>
                    </SupportContentContainer>
                </SupportGrid>
                <FeedbackGrid>
                    <HeaderContainer>
                        <H3White>{t('preview.feedback.app.title')}</H3White>
                    </HeaderContainer>
                    <FeedbackAppContainer>
                        <IncidentWrapper>
                            <div>
                                <PreviewLead>
                                    <Icon src={energyIcon} />
                                    {t('preview.feedback.app.header')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.feedback.app.content')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                    </FeedbackAppContainer>
                    <AppImageContainer>
                        <img src={appScreen} alt={'feedback app'} width={660} />
                    </AppImageContainer>
                </FeedbackGrid>
                <HexaconBackground />
            </PreviewGrid>
            <FeedbackStatisticGrid>
                <FeedbackStatisticsContainer>
                    <div>
                        <PreviewLead>
                            <Icon src={energyIcon} />
                            {t('preview.feedback.statistics.header1')}
                        </PreviewLead>
                        <PreviewText hasPadding={true}>{t('preview.feedback.statistics.content1')}</PreviewText>
                    </div>
                    <div>
                        <PreviewLead>
                            <Icon src={energyIcon} />
                            <SoonSpan>{t('preview.soon')}</SoonSpan>
                            {t('preview.feedback.statistics.header2')}
                        </PreviewLead>
                        <PreviewText hasPadding={true}>{t('preview.feedback.statistics.content2')}</PreviewText>
                    </div>
                </FeedbackStatisticsContainer>
                <FeedbackImageContainer>
                    <img src={energyScreen} alt={'feedback module screen'} width={780} />
                </FeedbackImageContainer>
            </FeedbackStatisticGrid>
            <Footer />
        </>
    );
};

export default FeedbackPreviewView;
