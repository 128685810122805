import styled from 'styled-components';
import { theme } from 'styled/Theme';

import SVG from 'react-inlinesvg';
import { H2 } from './text';

export const LegendaText = styled.p`
    font-size: 10px;
    color: ${theme.colors.grey};
    margin: 0px;
`;

export const LegendaIcon = styled(SVG)`
    width: 12px;
    height: 12px;
    margin-inline: 6px;
`;

export const Icon = styled(SVG)`
    width: 15px;
    height: 15px;
    margin-right: 6px;
    margin-left: 20px;
`;

export const HumidityHeader = styled(H2)`
    color: ${theme.colors.mWater};
`;

export const TemperatureHeader = styled(H2)`
    color: ${theme.colors.mMobility};
`;

export const LightningHeader = styled(H2)`
    color: ${theme.colors.mEnergy};
`;

export const NoiseHeader = styled(H2)`
    color: ${theme.colors.mFeedback};
`;

export const WeekOverviewWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const PercentageLegenda = styled.div`
    display: flex;
    align-items: center;
`;

export const PercentageBar = styled.div`
    width: 140px;
    height: 8px;
    border-radius: 4px;
    background: linear-gradient(
        90deg,
        ${theme.colors.positiveL} 0%,
        ${theme.colors.mEnergyL} 47%,
        ${theme.colors.mEnergyL} 53%,
        ${theme.colors.mOccup} 100%
    );
    margin-inline: 4px;
`;
