import { getColor, theme } from 'styled/Theme';
import styled from 'styled-components';

import SVG from 'react-inlinesvg';

import { BodyLeftWhite, H2White, H2, LeadLeftWhite, CaptionLefWhite } from './text';

import hexagonRepeat from '../../assets/icons/cubes-tile-repeat.svg';

import { ModuleType } from 'types';

export const HeaderGrid = styled.div`
    background-color: ${theme.colors.text};
    display: grid;
    height: 90px;
    grid-template-columns: 290px auto 200px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const PreviewGrid = styled.div`
    background-color: ${theme.colors.text};
    display: grid;
    grid-template-columns: auto 200px;
    grid-template-areas:
        'header background'
        'incident background'
        'statistic background';
`;

export const SupportGrid = styled.div`
    display: grid;
    grid-area: header;
    grid-template-columns: 290px 90px auto;
    height: 140px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const IncidentGrid = styled.div`
    display: grid;
    grid-area: incident;
    grid-template-columns: auto 300px;
    grid-template-rows: [row1-start] 80px [row1-end] auto [row2-end];
    grid-template-areas:
        'header .'
        'main stats';
`;

export const AirIncidentGrid = styled.div`
    display: grid;
    grid-area: incident;
    grid-template-columns: auto;
    grid-template-rows: [row1-start] 80px [row1-end] auto [row2-end];
    grid-template-areas:
        'header'
        'main';
`;

export const StatisticGrid = styled.div`
    display: grid;
    grid-area: statistic;
    grid-template-columns: auto 540px;
    grid-template-rows: [row1-start] 80px [row1-end] auto [row2-end];
    grid-template-areas:
        'header image'
        'main image';
    border-right: 1px solid ${theme.colors.greyD};
`;

export const AirStatisticGrid = styled.div`
    min-height: 1180px;
    display: grid;
    grid-area: statistic;
    grid-template-columns: auto 540px;
    grid-template-rows: [row1-start] 80px [row1-end] min-content [row2-end] 80px [row3-end] auto [row4-end];
    grid-template-areas:
        'headerClimate image'
        'mainClimate .'
        'header .'
        'main .';
    border-right: 1px solid ${theme.colors.greyD};
`;

export const FeedbackStatisticGrid = styled.div`
    display: grid;
    grid-template-columns: auto 780px;
    grid-template-rows: fit-content(600px);
    grid-template-areas: 'main image';
    padding: 60px 0px 0px 30px;
    background-color: ${theme.colors.text};
    border-top: 1px solid ${theme.colors.greyD};
    z-index: 10;
`;

export const FeedbackGrid = styled.div`
    display: grid;
    grid-area: incident;
    grid-template-columns: auto 580px;
    grid-template-rows: [row1-start] 80px [row1-end] auto [row2-end];
    grid-template-areas:
        'header header'
        'main image';
    border-right: 1px solid ${theme.colors.greyD};
`;

export const HexaconBackground = styled.div`
    grid-area: background;
    background-image: url(${hexagonRepeat});
`;

export const GetContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-inline: 30px;
    align-items: center;
    height: 90px;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const EnergyContainer = styled.div`
    display: flex;
    align-items: center;
    padding-inline: 30px;
    border-right: 1px solid ${theme.colors.greyD};
`;

interface ModuleTitle {
    module: ModuleType;
}
export const ModuleTitle = styled(H2)<ModuleTitle>`
    color: ${({ module }) => getColor(module)};
`;

export const ModuleIcon = styled(SVG)`
    width: 38px;
    height: 38px;
    margin: 26px;
`;

export const IconWrapper = styled.div`
    border: 1px solid ${theme.colors.greyD};
    border-top: none;
    border-right: none;
    width: 90px;
    height: 90px;
`;

export const SupportBackground = styled.div`
    background-image: url(${hexagonRepeat});
    background-repeat: repeat;
    height: 90px;
    width: 200px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const IconContainer = styled.div`
    display: flex;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const ModuleContainer = styled.div`
    display: flex;
    align-items: center;
    padding: 0px 30px;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const ModuleText = styled(H2White)`
    writing-mode: vertical-rl;
    text-orientation: sideways;
`;

export const SupportContentContainer = styled.div`
    padding: 30px;
    border-right: 1px solid ${theme.colors.greyD};
`;

export const HeaderContainer = styled.div`
    display: flex;
    grid-area: header;
    align-items: center;
    padding: 0px 30px;
    border-right: 1px solid ${theme.colors.greyD};
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const HeaderClimateContainer = styled.div`
    display: flex;
    grid-area: headerClimate;
    align-items: center;
    padding: 0px 30px;
    border-right: 1px solid ${theme.colors.greyD};
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const BorderedContainer = styled.div`
    border-bottom: 1px solid ${theme.colors.greyD};
    border-right: 1px solid ${theme.colors.greyD};
`;

export const IncidentsContainer = styled.div`
    grid-area: main;
    display: flex;
    gap: 20px;
    padding: 30px 60px 90px 30px;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.greyD};
    border-right: 1px solid ${theme.colors.greyD};
`;

export const AirIncidentsContainer = styled.div`
    grid-area: main;
    display: flex;
    gap: 20px;
    padding: 30px 0px 90px 0px;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.greyD};
    border-right: 1px solid ${theme.colors.greyD};
`;

export const FeedbackAppContainer = styled.div`
    display: flex;
    gap: 20px;
    padding: 30px 100px 60px 30px;
    flex-direction: column;
`;

export const StatsContainer = styled.div`
    display: flex;
    gap: 30px;
    padding: 30px;
    padding-bottom: 90px;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.greyD};
    border-right: 1px solid ${theme.colors.greyD};
`;

export const IncidentWrapper = styled.div`
    display: flex;
    gap: 30px;
`;

export const PreviewLead = styled(LeadLeftWhite)`
    display: flex;
    align-items: center;
    margin-block: 8px;
`;

interface PreviewText {
    hasPadding?: boolean;
}
export const PreviewText = styled(BodyLeftWhite)<PreviewText>`
    margin-right: 0px;
    line-height: 1.7;
    padding-left: ${({ hasPadding }) => (hasPadding ? '18px' : '0px')};
`;

export const SoonSpan = styled.span`
    font-size: 10px;
    font-weight: normal;
    margin-right: 10px;
    padding: 4px 5px;
    border-radius: 4px;
    backdrop-filter: blur(5px);
    background-color: ${theme.colors.mWaterLTransparent};
`;

export const Icon = styled(SVG)`
    min-width: 12px;
    min-height: 12px;
    margin-right: 6px;
    & path {
        fill: ${theme.colors.white};
    }
`;

export const HeartIcon = styled(SVG)`
    min-width: 12px;
    min-height: 12px;
    margin-bottom: 5px;
`;

export const CaptionPreview = styled(CaptionLefWhite)`
    margin-top: 0px;
    margin-bottom: 5px;
`;

export const StatisticsContainer = styled.div`
    grid-area: main;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 140px 60px 30px;
`;

export const ClimateContainer = styled.div`
    grid-area: mainClimate;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px 140px 60px 30px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const FeedbackStatisticsContainer = styled.div`
    grid-area: main;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 0px 100px 60px 0px;
`;

export const AppImageContainer = styled.div`
    grid-area: image;
    position: relative;
    top: -80px;
    height: 290px;
`;

export const ImageContainer = styled.div`
    grid-area: image;
    position: relative;
    top: -60px;
`;

export const ClimateImageContainer = styled.div`
    width: 0px;
    overflow: visible;
`;

export const FeedbackImageContainer = styled.div`
    overflow: hidden;
    grid-area: image;
`;

export const AirImage = styled.img`
    background-color: ${theme.colors.text};
`;

export const CloudImage = styled.img`
    padding: 0px 30px;
`;
