import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { H2White } from 'styled/components/text';
import {
    HumidityHeader,
    Icon,
    LegendaIcon,
    LegendaText,
    LightningHeader,
    NoiseHeader,
    TemperatureHeader,
} from 'styled/components/infoHeader';

import temperatureCircle from '../../../../../assets/icons/i-circle-temperature.svg';
import temperatureIcon from '../../../../../assets/icons/icon-feedback-temperature.svg';
import co2Circle from '../../../../../assets/icons/i-circle-humidity.svg';
import co2Icon from '../../../../../assets/icons/icon-feedback-humidity.svg';
import illuminanceCircle from '../../../../../assets/icons/i-circle-lighting.svg';
import illuminanceIcon from '../../../../../assets/icons/icon-feedback-luminocity.svg';
import noiseCircle from '../../../../../assets/icons/i-circle-noise.svg';
import noiseIcon from '../../../../../assets/icons/icon-feedback-noise.svg';

import { FeedbackTimeserie, MetricFeedback, MetricType } from '../types';

interface InfoHeaderProp {
    feedback: FeedbackTimeserie[];
}
const OverviewHeader: React.FunctionComponent<InfoHeaderProp> = ({ feedback }) => {
    const { t } = useTranslation();

    const numberOfFeedback = useMemo(() => calculateNumberOfFeedback(feedback), [feedback]);

    const averageCo2 = useMemo(() => calculateAverage(feedback, MetricFeedback.CO2), [feedback]);
    const averageLightIntensity = useMemo(() => calculateAverage(feedback, MetricFeedback.LIGHT_INTENSITY), [feedback]);
    const avergeTemperature = useMemo(() => calculateAverage(feedback, MetricFeedback.TEMPERATURE), [feedback]);
    const averageNoise = useMemo(() => calculateAverage(feedback, MetricFeedback.NOISE_LEVEL), [feedback]);

    return (
        <>
            <div>
                <H2White>{numberOfFeedback}</H2White>
                <LegendaText>
                    {t('infoHeader.feedback.respondents')} <br />
                    {t('infoHeader.feedback.overPeriod')}
                </LegendaText>
                {Boolean(averageCo2) && (
                    <>
                        <Icon src={co2Icon} />
                        <HumidityHeader>{averageCo2}</HumidityHeader>
                        <LegendaText>
                            {t('infoHeader.feedback.averageCO2')} <br />
                            {t('infoHeader.feedback.rating')}
                        </LegendaText>
                    </>
                )}
                {Boolean(averageLightIntensity) && (
                    <>
                        <Icon src={illuminanceIcon} />
                        <LightningHeader>{averageLightIntensity}</LightningHeader>
                        <LegendaText>
                            {t('infoHeader.feedback.averageLight')} <br />
                            {t('infoHeader.feedback.rating')}
                        </LegendaText>
                    </>
                )}
                {Boolean(avergeTemperature) && (
                    <>
                        <Icon src={temperatureIcon} />
                        <TemperatureHeader>{avergeTemperature}</TemperatureHeader>
                        <LegendaText>
                            {t('infoHeader.feedback.averageTemp')} <br />
                            {t('infoHeader.feedback.rating')}
                        </LegendaText>
                    </>
                )}
                {Boolean(averageNoise) && (
                    <>
                        <Icon src={noiseIcon} />
                        <NoiseHeader>{averageNoise}</NoiseHeader>
                        <LegendaText>
                            {t('infoHeader.feedback.averageNoise')} <br />
                            {t('infoHeader.feedback.rating')}
                        </LegendaText>
                    </>
                )}
            </div>
            <div>
                {Boolean(averageCo2) && (
                    <>
                        <LegendaIcon src={co2Circle} />
                        <LegendaText>{t('infoHeader.feedback.co2')}</LegendaText>
                    </>
                )}
                {Boolean(averageLightIntensity) && (
                    <>
                        <LegendaIcon src={illuminanceCircle} />
                        <LegendaText>{t('infoHeader.feedback.lighting')}</LegendaText>
                    </>
                )}
                {Boolean(avergeTemperature) && (
                    <>
                        <LegendaIcon src={temperatureCircle} />
                        <LegendaText>{t('infoHeader.feedback.temperature')}</LegendaText>
                    </>
                )}
                {Boolean(averageNoise) && (
                    <>
                        <LegendaIcon src={noiseCircle} />
                        <LegendaText>{t('infoHeader.feedback.noise')}</LegendaText>
                    </>
                )}
            </div>
        </>
    );
};

export default OverviewHeader;

const calculateNumberOfFeedback = (feedback: FeedbackTimeserie[]) => {
    return feedback.map(f => f.totalFeedback).reduce((prev, curr) => prev + curr, 0);
};

const calculateAverage = (feedback: FeedbackTimeserie[], metricType: MetricType) => {
    const filteredFeedback = feedback.filter(f => f[metricType] !== 0);

    const summedFeedback = filteredFeedback.length
        ? feedback
              .filter(f => f[metricType] !== 0)
              .map(f => f[metricType])
              .reduce((prev, curr) => prev + curr, 0)
        : 0;

    return filteredFeedback.length ? Number((summedFeedback / filteredFeedback.length).toFixed(1)) : summedFeedback;
};
