import React from 'react';

import IncidentsDataPane from 'components/DataPane/incidents/IncidentsDataPane';
import AirQualityIncidentDataPane from 'components/DataPane/incidents/AirQualityIncidentDataPane';
import { navItemsAir } from 'components/Submenu/ModulesSubMenu';
import AirIncidentsGraph from 'components/Graph/incidents/AirIncidentsGraph';
import AirQualityIncidentsGraph from 'components/Graph/incidents/AirQualityIncidentsGraph';
import { IncidentGraphData } from 'components/Graph/types';
import IssuesOverview from 'components/IssuesOverview';

import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';

import { AnomalySubTypeAir, Incident, ModuleType } from 'types';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

const AirIssuesView: React.FunctionComponent = () => {
    const { data, isLoading } = useSensorsByModulesData([ModuleType.OCCUPANCY]);

    return (
        <>
            {isLoading ? (
                <LoadingOverlay />
            ) : (
                <IssuesOverview
                    module={ModuleType.AIR}
                    subNavItems={navItemsAir}
                    renderDataPane={(incident: Incident) => {
                        if (incident.anomalySubType === AnomalySubTypeAir.AIR_QUALITY) {
                            return (
                                <AirQualityIncidentDataPane
                                    occupancySensors={data[0]}
                                    incident={incident}
                                    renderGraph={(graphData: IncidentGraphData, isBarChart: boolean) => {
                                        return (
                                            <AirQualityIncidentsGraph graphData={graphData} isBarChart={isBarChart} />
                                        );
                                    }}
                                />
                            );
                        }
                        return (
                            <IncidentsDataPane
                                incident={incident}
                                renderGraph={(graphData: IncidentGraphData, isBarChart: boolean) => {
                                    return <AirIncidentsGraph graphData={graphData} isBarChart={isBarChart} />;
                                }}
                            />
                        );
                    }}
                />
            )}
        </>
    );
};

export default AirIssuesView;
