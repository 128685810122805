import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { SensorValue } from '../types';
import { TooltipCard, TooltipTitle, TooltipContent } from 'styled/components/tooltip';
import { ModuleType, Sensor, SensorType } from 'types';
import { getModule } from 'components/DataPane/sensors/sensors.helpers';
import { getUnit } from 'styled/components/sensors';
import { DateSpan } from 'utils/timePeriod';

interface TooltipInterface {
    x?: number;
    y?: number;
    dx?: number;
    dy?: number;
    height?: number;
    width?: number;
    datum?: SensorValue;
    timespan?: string;
    averageData: SensorValue[];
    sensor: Sensor;
}
const TooltipSensor: React.FunctionComponent<TooltipInterface> = ({
    x = 0,
    dx = 0,
    height = 0,
    datum = { value: 0, timestamp: 0 },
    timespan,
    averageData,
    sensor,
}) => {
    const { t } = useTranslation();
    const maxHeightAxisIndicator = height * 0.27;
    const kpiUnit = getUnit(sensor.unit, sensor.subType);
    const module = getModule(sensor.type);

    const average = useMemo(() => getAverageByTimestamp(datum.timestamp, averageData), [averageData, datum.timestamp]);
    const timestamp = useMemo(() => createTimestamp(datum.timestamp, timespan), [datum.timestamp, timespan]);

    return datum.timestamp !== 0 ? (
        <g style={{ pointerEvents: 'none' }}>
            <line
                x1={x}
                x2={x}
                y1="0"
                y2={maxHeightAxisIndicator}
                style={{ stroke: theme.colors.grey, strokeWidth: '0.8', fill: theme.colors.grey }}
            />
            <foreignObject x={dx + x} y={10} width={100} height={'100%'}>
                <>
                    <TooltipCard module={module}>
                        <TooltipTitle>{t('tooltip.times')}</TooltipTitle>
                        <TooltipContent>{timestamp}</TooltipContent>
                    </TooltipCard>
                    <TooltipCard module={module}>
                        <TooltipTitle>{t('tooltip.current')}</TooltipTitle>
                        <TooltipContent>
                            {roundTooltipValue(datum.value, module, sensor.type)}
                            <span>{kpiUnit}</span>
                        </TooltipContent>
                    </TooltipCard>
                    {average && sensor.type !== SensorType.BATTERY_VOLTAGE ? (
                        <TooltipCard module={module}>
                            <TooltipTitle>{t('tooltip.average')}</TooltipTitle>
                            <TooltipContent>
                                {module === ModuleType.WATER ? (+average * 1000).toFixed() : average}
                                <span>{kpiUnit}</span>
                            </TooltipContent>
                        </TooltipCard>
                    ) : null}
                </>
            </foreignObject>
        </g>
    ) : null;
};

export default TooltipSensor;

const getAverageByTimestamp = (timestamp: number, averageData: SensorValue[]) => {
    const formattedTimestamp = format(new Date((timestamp || 0) * 1000), 'yyyy-MM-dd HH');

    return (
        averageData
            .find(average => format(new Date((average.timestamp || 0) * 1000), 'yyyy-MM-dd HH') === formattedTimestamp)
            ?.value.toFixed(2) || ''
    );
};

const createTimestamp = (timestamp: number, timespan?: string): string => {
    const dateTime = new Date((timestamp || 0) * 1000);

    if (timespan === DateSpan.YEAR) return format(dateTime, 'MMM dd');
    return `${dateTime.getHours()}:${('0' + dateTime.getMinutes()).slice(-2)}`;
};

const roundTooltipValue = (value: number, module: ModuleType, sensorType: SensorType) => {
    if (module === ModuleType.WATER) return (value * 1000).toFixed();
    if (sensorType === SensorType.BATTERY_VOLTAGE) return value.toFixed(3);
    if (sensorType === SensorType.HEATING || sensorType === SensorType.COOLING) return value.toFixed(2);
    return value.toFixed(1);
};
