import React from 'react';

import { useTranslation } from 'react-i18next';

import LegendaItem from './LegendaItem';

import { WrapperLegenda } from 'styled/components/ebs';
import { theme } from 'styled/Theme';

const ComparisonLegenda: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <br />
            <WrapperLegenda>
                <LegendaItem color={theme.colors.scatterColor2}>{t('energy.ebs.steps.gas')}</LegendaItem>
                <LegendaItem color={theme.colors.scatterColor1}>{t('energy.ebs.steps.electricity')}</LegendaItem>
            </WrapperLegenda>
        </>
    );
};

export default ComparisonLegenda;
