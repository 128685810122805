import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const ProfileNameCircle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    background-color: ${theme.colors.text};
    border-radius: 50%;
`;

export const ProfileNameCircleSmall = styled(ProfileNameCircle)`
    width: 40px;
    height: 40px;
`;

export const ProfileNameWrapper = styled.div`
    display: flex;
    align-items: center;
`;

export const ProfileFullName = styled.div`
    margin-left: 20px;
`;

export const ChangePassword = styled.div``;

interface PasswordScore {
    score: number;
    passwordLength: number;
}

export const PasswordStrengthWrapper = styled(ProfileNameWrapper)`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15px;
    margin-top: 9px;
`;

export const getScoreColor = (score: number) => {
    switch (score) {
        case 3:
            return theme.colors.mEnergyL;
        case 4:
            return theme.colors.positiveL;
        default:
            return theme.colors.negativeL;
    }
};

export const PasswordStrengtLabel = styled.div<PasswordScore>`
    display: flex;
    align-items: center;
    margin: 0 15px 0 8.1px;
    font-size: 10px;

    color: ${({ score }) => {
        switch (score) {
            case 3:
                return theme.colors.mEnergyL;
            case 4:
                return theme.colors.positiveL;
            default:
                return theme.colors.negativeL;
        }
    }};

    &:after {
        content: '${({ score, passwordLength }) => {
            if (passwordLength > 0) {
                switch (score) {
                    case 3:
                        return 'Fair';
                    case 4:
                        return 'Good';
                    default:
                        return 'Poor';
                }
            }
            return '';
        }}';
    }
`;

export const PasswordStrengthSymbol = styled.div<PasswordScore>`
    width: 20px;
    height: 4px;
    background-color: ${({ score, passwordLength }) => (passwordLength > 0 ? getScoreColor(score) : theme.colors.grey)};
`;

export const Poor = styled(PasswordStrengthSymbol)`
    border-radius: 3px 0 0 3px;
    margin-left: auto;
`;

export const Fair = styled(PasswordStrengthSymbol)`
    margin-inline: 1px;
`;

export const Strong = styled(PasswordStrengthSymbol)`
    border-radius: 0 3px 3px 0;
`;
