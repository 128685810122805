import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { AnomalySubTypeWater, Incident, SensorAvgFilter, SensorData } from 'types';
import { SensorDataParam } from 'components/DataPane/incidents/types';

export const useWaterSensorData = (sensorDataParams: SensorDataParam, incident: Incident) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['waterSensor', sensorDataParams.id, sensorDataParams.from, sensorDataParams.to],
        async ({ signal }) => {
            const { data } = await axiosClient.get<SensorData[]>(createEndPoint(sensorDataParams, incident), {
                signal,
            });

            return data;
        },
        {
            enabled: Boolean(sensorDataParams.ids.length),
        }
    );
};

const createEndPoint = (sensorDataParams: SensorDataParam, incident: Incident) => {
    if (incident.anomalySubType === AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION) {
        return config.sensorDataAverageTotal(
            sensorDataParams.ids || [],
            SensorAvgFilter.HOUR_OF_DAY,
            sensorDataParams.from,
            sensorDataParams.to
        );
    }

    return config.multiSensorsDataAverage(
        sensorDataParams.ids || [],
        SensorAvgFilter.HOUR_OF_DAY,
        sensorDataParams.from,
        sensorDataParams.to
    );
};
