import React, { useRef } from 'react';

import { useScollInView } from 'hooks/useScollInView';

import { SensorListContainer, SensorList, SensorListItem, SensorWrapper } from 'styled/components/sensors';
import { EquipList } from '../../views/authenticated/types';

interface BuildingSelectorProps {
    buildings: EquipList;
    onBuildingSelect: (roomId: string) => void;
    collapsed: boolean;
}
const BuildingSelector: React.FunctionComponent<BuildingSelectorProps> = ({
    buildings,
    onBuildingSelect,
    collapsed,
}) => {
    const sensorWrapperRef = useRef<HTMLDivElement>(null);

    const elementRefs = useScollInView<HTMLLIElement>(
        buildings.spaces,
        sensorWrapperRef,
        buildings.spaces.findIndex(space => space.id === buildings.selectedSpace)
    );

    return (
        <SensorWrapper ref={sensorWrapperRef} collapsed={collapsed}>
            <SensorListContainer>
                <SensorList>
                    {buildings.spaces.map((space, index) => (
                        <SensorListItem
                            ref={elementRefs.current[index]}
                            selected={buildings.selectedSpace === space.id}
                            onClick={() => {
                                // Disable click on same button
                                buildings.selectedSpace === space.id ? null : onBuildingSelect(space.id);
                            }}
                            key={space.id}
                        >
                            <div>
                                <p>{space.name}</p>
                            </div>
                        </SensorListItem>
                    ))}
                </SensorList>
            </SensorListContainer>
        </SensorWrapper>
    );
};

export default BuildingSelector;
