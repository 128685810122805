import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import BillableConsumptionGraph from 'components/Graph/statistics/energy/BillableConsumptionGraph';
import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import { ContentWrapper, InfoHeader, LoadingWrapper, SensorGraphWrapper } from 'styled/components/dataPane';
import { H3White } from 'styled/components/text';

import BillableConsumptionHeader from './infoHeader/BillableConsumptionHeader';
import { getFirstDataTimestamp, getLastKpiUpdateTimestamp, summedValue } from './energyStatistics.helper';

import { Sensor } from 'types';
import { BillableGraphData } from './types';
import { MetaDataEwattch, TotalUsage } from 'views/authenticated/energy/types';

interface BillableConsumptionProps {
    totalUsage: TotalUsage;
    graphData: BillableGraphData | null;
    sensors: Sensor[];
    ewattchSensors: MetaDataEwattch[];
    isLoadingConsumption: boolean;
    isBarChart: boolean;
}
const BillableConsumption: React.FunctionComponent<BillableConsumptionProps> = ({
    totalUsage,
    graphData,
    sensors,
    isLoadingConsumption,
    isBarChart,
}) => {
    const { t } = useTranslation();

    const [showGas, setShowGas] = useState(true);
    const [showElectricity, setShowElectricity] = useState(true);

    const lastUpdate = getLastKpiUpdateTimestamp(sensors);
    const firstData = getFirstDataTimestamp(sensors);

    return (
        <>
            <ContentWrapper>
                {(isLoadingConsumption || graphData === null) && (
                    <LoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption &&
                    graphData &&
                    (Boolean(graphData.rawElectricityData.length) || Boolean(graphData.rawGasData.length)) &&
                    !graphData.electricityData.length &&
                    !graphData.gasData.length && (
                        <LoadingWrapper>
                            <H3White>{t('energy.datapane.noContract')}</H3White>
                        </LoadingWrapper>
                    )}
                {!isLoadingConsumption &&
                    graphData &&
                    graphData.rawElectricityData.length === 0 &&
                    graphData.rawGasData.length === 0 && (
                        <LoadingWrapper>
                            <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                        </LoadingWrapper>
                    )}
                {!isLoadingConsumption &&
                    (Boolean(graphData?.electricityData.length) || Boolean(graphData?.gasData.length)) && (
                        <>
                            <InfoHeader>
                                <BillableConsumptionHeader
                                    electricityConsumption={+totalUsage.consumption.toFixed()}
                                    electricityCost={summedValue(graphData.electricityData)}
                                    gasConsumption={+summedValue(graphData.rawGasData).toFixed()}
                                    gasCost={summedValue(graphData.gasData)}
                                    hasGasData={Boolean(graphData.gasData.length)}
                                    showGas={showGas}
                                    onShowGas={setShowGas}
                                    showElectricity={showElectricity}
                                    onShowElectricity={setShowElectricity}
                                    isInGigajoules={graphData.isInGigajoules}
                                />
                            </InfoHeader>
                            <SensorGraphWrapper>
                                <BillableConsumptionGraph
                                    graphData={graphData}
                                    isBarChart={isBarChart}
                                    showGas={showGas}
                                    showElectricity={showElectricity}
                                />
                            </SensorGraphWrapper>
                        </>
                    )}
            </ContentWrapper>
        </>
    );
};

export default BillableConsumption;
