import React from 'react';

import { Wrapper, MainView } from 'styled/components/view';

interface Props {
    children?: React.ReactNode;
}
const MainWrapper: React.FunctionComponent<Props> = ({ children }) => {
    return (
        <>
            <Wrapper>
                <MainView>{children}</MainView>
            </Wrapper>
        </>
    );
};

export default MainWrapper;
