import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { QueryClient, QueryClientProvider } from 'react-query';

import 'normalize.css';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './i18n';

import { ThemeProvider } from 'styled-components';
import { theme } from 'styled/Theme';

import { AuthProvider } from './context/AuthContext';
import { AxiosClientProvider } from 'context/AxiosClientContext';
import { UserProvider } from 'context/UserContext';

import { GlobalStyle } from 'styled/global';

import Router from './routing/Router';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5, // 5 min
            cacheTime: 1000 * 60 * 10, // 10 min
        },
    },
});

const container = document.getElementById('root');
const root = createRoot(container!);

const AppWrapper: React.FunctionComponent = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <AxiosClientProvider>
                    <UserProvider>
                        <ThemeProvider theme={theme}>
                            <GlobalStyle />
                            <Router />
                        </ThemeProvider>
                    </UserProvider>
                </AxiosClientProvider>
            </AuthProvider>
        </QueryClientProvider>
    );
};

root.render(
    <StrictMode>
        <AppWrapper />
    </StrictMode>
);
