import styled from 'styled-components';
import { getColorByPercentage, theme } from 'styled/Theme';

const maxPercentage = 75;

export const HourList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    color: ${theme.colors.grey};
`;

interface HourItemInterface {
    percentage: number;
}
export const HourItem = styled.div<HourItemInterface>`
    display: flex;
    align-items: center;
    background: ${({ percentage }) => getColorByPercentage(percentage)};
    border-radius: 2px;
    height: 20px;
    color: ${({ percentage }) => (percentage < 5 ? theme.colors.grey : theme.colors.text)};
    font-size: 10px;
    p {
        background: linear-gradient(
            90deg,
            ${theme.colors.text} 1%,
            ${theme.colors.text} 80%,
            ${({ percentage }) => getColorByPercentage(percentage)} 100%
        );
        height: 4px;
        margin-top: 8px;
        width: 100%;
        span {
            font-size: 10px;
            display: inline-block;
            width: ${({ percentage }) => `calc((100% - ${calcPercentage(percentage)}%) - 5px)`};
            background: ${({ percentage }) => getColorByPercentage(percentage)};
            padding-left: 5px;
            position: relative;
            top: -3px;
            left: ${({ percentage }) => `${calcPercentage(percentage)}%`};
        }
    }
`;

export const WeekWrapper = styled.div`
    display: flex;
    gap: 10px;
    flex: 1;
`;

export const DayWrapper = styled.div`
    flex-direction: column;
    display: flex;
    flex: 1;
`;

export const HourLegenda = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1px;
    padding-top: 37px;
    padding-right: 2px;
`;

export const Hour = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: end;
    p {
        background: ${theme.colors.grey};
        height: 1px;
        margin-top: 8px;
        width: 100%;
        margin-inline: 0px;
        span {
            width: 27px;
            background: ${theme.colors.text};
            display: inline-block;
            position: relative;
            top: -5px;
            margin-right: 7px;
            padding-right: 2px;
        }
    }
`;

export const WeekOverviewWrapper = styled.div`
    padding-top: 20px;
    display: flex;
    flex-direction: row;
`;

const calcPercentage = (percentage: number) => {
    if (percentage > maxPercentage) {
        return 80;
    }
    if (percentage < 5) {
        return 0;
    }
    return percentage;
};

export const StyledClosedDay = styled.div`
    font-size: 14px;
    color: white;
    display: flex;
    height: 100%;
    background-color: #434951;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
`;
