import React, { useState } from 'react';

import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import GasConsumptionGraph from 'components/Graph/statistics/energy/GasConsumptionGraph';
import { GraphData, SensorValue } from 'components/Graph/types';

import { ContentWrapper, InfoHeader, LoadingWrapper, SensorGraphWrapper } from 'styled/components/dataPane';

import GasConsumptionHeader from './infoHeader/GasConsumptionHeader';
import { getFirstDataTimestamp, getLastKpiUpdateTimestamp, summedValue } from './energyStatistics.helper';
import { Sensor } from 'types';

interface GasConsumptionProps {
    graphData: GraphData | null;
    sensors: Sensor[];
    isLoadingConsumption: boolean;
    isBarChart: boolean;
}
const GasConsumption: React.FunctionComponent<GasConsumptionProps> = ({
    graphData,
    sensors,
    isLoadingConsumption,
    isBarChart,
}) => {
    const [showGas, setShowGas] = useState(true);
    const [showGasHistory, setShowGasHistory] = useState(true);

    const lastUpdate = getLastKpiUpdateTimestamp(sensors);
    const firstData = getFirstDataTimestamp(sensors);

    return (
        <>
            <ContentWrapper>
                {(isLoadingConsumption || graphData === null) && (
                    <LoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption && graphData?.data.length === 0 && (
                    <LoadingWrapper>
                        <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption && Boolean(graphData?.data.length) && (
                    <>
                        <InfoHeader>
                            <GasConsumptionHeader
                                gasConsumption={summedValue(graphData.data)}
                                hasHistoricData={Boolean(graphData.averageData.length)}
                                deviationPercentage={calcDeviation(graphData.data, graphData.averageData)}
                                showGas={showGas}
                                onShowGas={setShowGas}
                                showGasHistory={showGasHistory}
                                onShowGasHistory={setShowGasHistory}
                            />
                        </InfoHeader>
                        <SensorGraphWrapper>
                            <GasConsumptionGraph
                                graphData={graphData}
                                isBarChart={isBarChart}
                                showGas={showGas}
                                showGasHistory={showGasHistory}
                            />
                        </SensorGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </>
    );
};

export default GasConsumption;

const calcDeviation = (gasData: SensorValue[], historicGasData: SensorValue[]): number => {
    const gasUsage = +summedValue(gasData);
    const gasUsageLastYear = +summedValue(historicGasData);

    const percentage = ((gasUsage - gasUsageLastYear) / gasUsageLastYear) * 100;

    return Number.isFinite(percentage) ? +percentage.toFixed(1) : 100;
};
