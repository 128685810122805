import React from 'react';

import { StyledLink } from '../../styled/components/link';
import { Icon } from '../../styled/components/buttons';

interface LinkButtonInterface {
    to: string;
    text: string;
    icon?: string;
}
const LinkButton: React.FunctionComponent<LinkButtonInterface> = ({ to, text, icon }) => {
    return (
        <StyledLink to={to}>
            {icon ? <Icon $disabled={false} $active={false} src={icon} /> : null}
            {text}
        </StyledLink>
    );
};

export default LinkButton;
