import { useQuery } from 'react-query';
import { useAxiosClient } from 'context/AxiosClientContext';
import { config } from 'config';
import { SensorAverage } from 'types';
import { SensorDataParam } from 'components/DataPane/statistics/water/types';

export const useMultiSensorSumTotalData = (sensorDataParams: SensorDataParam, enabled = true) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        [
            'sensorsMultiSumTotal',
            sensorDataParams.ids,
            sensorDataParams.from,
            sensorDataParams.to,
            sensorDataParams.averageFilter,
        ],
        async ({ signal }) => {
            const urls = sensorDataParams.ids.map(id =>
                config.sensorDataSumTotal(
                    [id],
                    sensorDataParams.averageFilter,
                    sensorDataParams.from,
                    sensorDataParams.to
                )
            );

            const requests = urls.map(url => axiosClient.get<SensorAverage[]>(url, { signal }));
            const responses = await Promise.all(requests);
            return responses.map(response => response.data);
        },
        {
            enabled: enabled,
        }
    );
};
