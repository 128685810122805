import { addDays, isSameDay, startOfHour } from 'date-fns';

import { SensorAverage, SensorType, WeatherData } from 'types';
import {
    EBSWeekGraphData,
    EBSHolidayGraphData,
    SensorValue,
    EBSElecTempGasGraphData,
    Linepoint,
} from 'components/Graph/types';
import { getMax } from 'components/Graph/graph.helpers';
import { FromTo, Hours, isClosed } from 'components/WorkingHours';

const hourOfDayTicks = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

export const lastYear = new Date().getFullYear() - 1;
export const twoYearsBack = new Date().getFullYear() - 2;

interface DataObject {
    x: number;
    y: number;
    mult: number;
}
interface TimestampMap {
    [key: string]: SensorValue;
}

export const createEBSWeekGraphData = (data: SensorAverage[], sensorType: SensorType) => {
    const max = getMax(data);

    const graphData: EBSWeekGraphData = {
        monday: data.filter(d => new Date(d.field).getDay() === 1).map(mapSensorValue),
        tuesday: data.filter(d => new Date(d.field).getDay() === 2).map(mapSensorValue),
        wednesday: data.filter(d => new Date(d.field).getDay() === 3).map(mapSensorValue),
        thursday: data.filter(d => new Date(d.field).getDay() === 4).map(mapSensorValue),
        friday: data.filter(d => new Date(d.field).getDay() === 5).map(mapSensorValue),
        saturday: data.filter(d => new Date(d.field).getDay() === 6).map(mapSensorValue),
        sunday: data.filter(d => new Date(d.field).getDay() === 0).map(mapSensorValue),
        tickValues: hourOfDayTicks,
        sensorType,
        max,
    };

    return graphData;
};

export const createEBSHolidaysGraphData = (data: SensorAverage[], sensorType: SensorType) => {
    const [
        newYearsDayDate,
        secondEasternDayDate,
        ascensionDate,
        secondPentecostDayDate,
        firstChristmasDayDate,
        secondChristmasDayDate,
    ] = getHolidays(lastYear);

    const newYear = filterByDate(data, newYearsDayDate);
    const secondEastern = filterByDate(data, secondEasternDayDate);
    const ascension = filterByDate(data, ascensionDate);
    const secondPentecost = filterByDate(data, secondPentecostDayDate);
    const firstChristmas = filterByDate(data, firstChristmasDayDate);
    const secondChristmas = filterByDate(data, secondChristmasDayDate);

    const max = getMax([
        ...newYear,
        ...secondEastern,
        ...ascension,
        ...secondPentecost,
        ...firstChristmas,
        ...secondChristmas,
    ]);

    const graphData: EBSHolidayGraphData = {
        newYear,
        secondEastern,
        ascension,
        secondPentecost,
        firstChristmas,
        secondChristmas,
        tickValues: hourOfDayTicks,
        sensorType,
        max,
    };

    return graphData;
};

export const createElecVsTempGraphData = (
    openingHours: Hours,
    temperature: WeatherData[],
    electricity: SensorAverage[],
    gas: SensorAverage[]
) => {
    const [electricityData, electricityDataOutsideOpeningHours, gasData, gasDataOutsideOpeningHours] =
        mapElecVsTempVsGas(openingHours, temperature, electricity, gas);

    const trendLineElectricity = calcLinearTrendline(electricityData);
    const trendLineElectricityIncludingOutsideHours = calcLinearTrendline([
        ...electricityData,
        ...electricityDataOutsideOpeningHours,
    ]);
    const trendLineGas = calcLinearTrendline(gasData);
    const trendLineGasIncludingOutsideHours = calcLinearTrendline([...gasData, ...gasDataOutsideOpeningHours]);

    const graphData: EBSElecTempGasGraphData = {
        electricityData,
        electricityDataOutsideOpeningHours,
        gasData,
        gasDataOutsideOpeningHours,
        trendLineElectricity,
        trendLineElectricityIncludingOutsideHours,
        trendLineGas,
        trendLineGasIncludingOutsideHours,
    };

    return graphData;
};

const mapSensorValue = (sensor: SensorAverage): SensorValue => {
    return {
        value: sensor.value,
        timestamp: new Date(sensor.field).getHours(),
    };
};

const getHolidays = (year: number): Date[] => {
    const newYearsDay = new Date(year, 0, 1);
    const firstEasternDay = easterDate(year);
    const secondEasternDay = addDays(firstEasternDay, 1);
    const ascension = addDays(firstEasternDay, 39);
    const secondPentecostDay = addDays(firstEasternDay, 50);
    const firstChristmasDay = new Date(year, 11, 25);
    const secondChristmasDay = new Date(year, 11, 26);

    return [newYearsDay, secondEasternDay, ascension, secondPentecostDay, firstChristmasDay, secondChristmasDay];
};

const easterDate = (year: number) => {
    const c = Math.floor(year / 100);
    const n = year - 19 * Math.floor(year / 19);
    const k = Math.floor((c - 17) / 25);
    let i = c - Math.floor(c / 4) - Math.floor((c - k) / 3) + 19 * n + 15;
    i = i - 30 * Math.floor(i / 30);
    i = i - Math.floor(i / 28) * (1 - Math.floor(i / 28) * Math.floor(29 / (i + 1)) * Math.floor((21 - n) / 11));
    let j = year + Math.floor(year / 4) + i + 2 - c + Math.floor(c / 4);
    j = j - 7 * Math.floor(j / 7);
    const l = i - j;
    const month = 3 + Math.floor((l + 40) / 44);
    const day = l + 28 - 31 * Math.floor(month / 4);

    return new Date(year, month - 1, day);
};

export const getDateOfISOWeek = (week: number, year: number) => {
    const simple = new Date(year, 0, 1 + (week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;

    if (dow <= 4) {
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }

    return ISOweekStart;
};

const mapElecVsTempVsGas = (
    openingHours: Hours,
    temperature: WeatherData[],
    electricity: SensorAverage[],
    gas: SensorAverage[]
): SensorValue[][] => {
    const mappedByTimestamp: TimestampMap = {};

    temperature.forEach(t => {
        const hourTimestamp = startOfHour(new Date(t.d)).toISOString();
        const sensorValue = mappedByTimestamp[hourTimestamp];

        if (sensorValue) return;

        mappedByTimestamp[hourTimestamp] = { value: undefined, timestamp: t.t };
    });

    const [electricityData, electricityDataOutsideOpeningHours]: TimestampMap[] = mapByTimestamp(
        electricity,
        mappedByTimestamp,
        openingHours
    );
    const [gasData, gasDataOutsideOpeningHours]: TimestampMap[] = mapByTimestamp(gas, mappedByTimestamp, openingHours);

    return [
        Object.values(electricityData).filter(e => e.value),
        Object.values(electricityDataOutsideOpeningHours).filter(e => e.value),
        Object.values(gasData).filter(g => g.value),
        Object.values(gasDataOutsideOpeningHours).filter(g => g.value),
    ];
};

const mapByTimestamp = (data: SensorAverage[], timestampMap: TimestampMap, openingHours: Hours): TimestampMap[] => {
    const mapDuringOpeningHours = { ...timestampMap };
    const mapOutsideOpeningHours = { ...timestampMap };

    data.forEach(d => {
        const timestamp = startOfHour(new Date(d.field)).toISOString();
        const sensorValue = timestampMap[timestamp];

        if (sensorValue && isDuringOpeningHour(timestamp, openingHours)) {
            mapDuringOpeningHours[timestamp] = { value: d.value, timestamp: sensorValue.timestamp };
        }
        if (sensorValue) {
            mapOutsideOpeningHours[timestamp] = { value: d.value, timestamp: sensorValue.timestamp };
        }
    });

    return [mapDuringOpeningHours, mapOutsideOpeningHours];
};

const isDuringOpeningHour = (timestamp: string, openingHours: Hours) => {
    const date = new Date(timestamp);
    const dayOfWeek = date.getDay();
    const hourOfDay = date.getHours();

    switch (dayOfWeek) {
        case 0:
            return validateTimestamp(openingHours.sunday, hourOfDay);
        case 1:
            return validateTimestamp(openingHours.monday, hourOfDay);
        case 2:
            return validateTimestamp(openingHours.tuesday, hourOfDay);
        case 3:
            return validateTimestamp(openingHours.wednesday, hourOfDay);
        case 4:
            return validateTimestamp(openingHours.thursday, hourOfDay);
        case 5:
            return validateTimestamp(openingHours.friday, hourOfDay);
        case 6:
            return validateTimestamp(openingHours.saturday, hourOfDay);
    }
};

const validateTimestamp = (hours: FromTo, hourOfDay: number) => {
    const startHour = +hours.from.slice(0, -3);
    const endHour = +hours.to.slice(0, -3);

    if (isClosed(hours)) {
        return false;
    }

    if (hourOfDay >= startHour && hourOfDay <= endHour) {
        return true;
    }

    return false;
};

//based on this implementation https://classroom.synonym.com/calculate-trendline-2709.html
const calcLinearTrendline = (data: SensorValue[]): [Linepoint, Linepoint] => {
    if (!data.length) {
        return [
            { x: 0, y: 0 },
            { x: 0, y: 0 },
        ];
    }

    const n = data.length;

    const pts: DataObject[] = [];
    data.forEach(d => {
        const obj: DataObject = {} as DataObject;
        obj.x = d.timestamp;
        obj.y = d.value;
        obj.mult = obj.x * obj.y;
        pts.push(obj);
    });

    let sum = 0;
    let xSum = 0;
    let ySum = 0;
    let sumSq = 0;
    pts.forEach(function (pt) {
        sum = sum + pt.mult;
        xSum = xSum + pt.x;
        ySum = ySum + pt.y;
        sumSq = sumSq + pt.x * pt.x;
    });
    const a = sum * n;
    const b = xSum * ySum;
    const c = sumSq * n;
    const d = xSum * xSum;

    const m = (a - b) / (c - d);

    const e = ySum;

    const f = m * xSum;

    const g = (e - f) / n;

    const minX = data.reduce((prev, curr) => (prev.timestamp < curr.timestamp ? prev : curr)).timestamp;
    const maxX = data.reduce((prev, curr) => (prev.timestamp > curr.timestamp ? prev : curr)).timestamp;

    return [
        {
            x: minX,
            y: m * minX + g,
        },
        {
            x: maxX,
            y: m * maxX + g,
        },
    ];
};

const filterByDate = (data: SensorAverage[], date: Date) => {
    return data.filter(d => isSameDay(new Date(d.field), date)).map(mapSensorValue);
};
