import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

import ModalHeader from './ModalHeader';

import { ModalBody, ModalContent, ModalOverlay } from 'styled/components/modal';

interface Props {
    show: boolean;
    title?: string;
    children?: React.ReactNode;
    onClose: () => void;
}
const Modal: React.FC<Props> = ({ show, title, children, onClose }) => {
    useEffect(() => {
        show ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = null);
    }, [show]);

    return show
        ? ReactDOM.createPortal(
              <ModalOverlay onClick={onClose}>
                  <ModalContent onClick={e => e.stopPropagation()}>
                      <ModalHeader title={title} onClose={onClose} />
                      <ModalBody>{children}</ModalBody>
                  </ModalContent>
              </ModalOverlay>,
              document.getElementById('root')
          )
        : null;
};

export default Modal;
