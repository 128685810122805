import React from 'react';

import styled from 'styled-components';

const TitleWrapper = styled.div`
    box-sizing: border-box;
    align-items: center;
    padding: 30px 1rem 0px;
    width: 100%;
    display: flex;
`;

export const SideColumn = styled.div`
    flex: 1;
    max-width: 160px;
    @media (max-width: 1200px) {
        flex: 0;
    }
`;

export const Content = styled.div`
    align-items: center;
    display: flex;
    flex: 5;
    padding: 0rem 0.5rem;
    @media (max-width: 1200px) {
        padding: 0rem 1.5rem;
    }
`;

interface Props {
    children?: React.ReactNode;
}
const IncidentsContentWrapper: React.FunctionComponent<Props> = ({ children }) => {
    return (
        <TitleWrapper>
            <SideColumn />
            <Content>{children}</Content>
            <SideColumn />
        </TitleWrapper>
    );
};

export default IncidentsContentWrapper;
