import { useMutation, useQueryClient } from 'react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';
import { NamePayload } from 'views/authenticated/profile/types';

export const useNameMutation = (id: string) => {
    const { axiosClient } = useAxiosClient();
    const queryClient = useQueryClient();

    return useMutation<AxiosResponse, AxiosError, NamePayload>(
        payload => {
            return axiosClient.put(config.nameChange(id), payload);
        },
        {
            onSuccess: () => queryClient.invalidateQueries('tenant'),
        }
    );
};
