import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    VictoryLine,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryArea,
    VictoryBar,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipIncident from 'components/Graph/Tooltip/TooltipIncident';
import TooltipBar from 'components/Graph/Tooltip/TooltipBar';
import { GraphData, SensorValue } from 'components/Graph/types';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import {
    lineChartDomainPadding,
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
    barChartPadding,
} from '../../graph.styles';

import { KpiUnit, ModuleType } from 'types';
import { formatXAxisTickValue, setBarConfig, sumValues } from 'components/Graph/graph.helpers';

interface GasConsumptionGraphProps {
    graphData: GraphData;
    isBarChart: boolean;
    showGas: boolean;
    showGasHistory: boolean;
}

const GasConsumptionGraph: React.FunctionComponent<GasConsumptionGraphProps> = ({
    graphData,
    isBarChart,
    showGas,
    showGasHistory,
}) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);
    const summedGasData = useMemo(() => sumValues(graphData.data, graphData.timespan), [graphData]);
    const summedAverageGasData = useMemo(() => sumValues(graphData.averageData, graphData.timespan), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={lineChartDomainPadding}
                padding={barChartPadding}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            voronoiBlacklist={['average']}
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipIncident
                                            module={ModuleType.AIR}
                                            unit={KpiUnit.M3}
                                            height={dimension.height}
                                            averageData={graphData.averageData}
                                            timespan={graphData.timespan}
                                        />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Cost Y-axis */}
                <VictoryAxis
                    tickFormat={(t: number, i: number, ticks: number[]) => (i === ticks.length - 1 ? KpiUnit.M3 : t)}
                    dependentAxis={true}
                    style={XYAxis}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {isBarChart && showGas && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={t('tooltip.current')}
                                        unit={KpiUnit.M3}
                                    />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mWater,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y="value"
                        data={summedGasData}
                    />
                )}
                {isBarChart && showGasHistory && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={t('tooltip.average')}
                                        unit={KpiUnit.M3}
                                    />
                                }
                            />
                        }
                        animate={{ duration: 500, easing: 'cubicOut' }}
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.airLTransparent },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y="value"
                        data={summedAverageGasData}
                    />
                )}
                {!isBarChart && showGas && (
                    <VictoryArea
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: {
                                stroke: theme.colors.mWater,
                                fill: theme.colors.mWaterLTransparent,
                                strokeWidth: 2,
                            },
                        }}
                        data={graphData.data}
                    />
                )}
                {!isBarChart && showGasHistory && (
                    <VictoryLine
                        name="average"
                        animate={true}
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: { stroke: theme.colors.airLTransparent },
                        }}
                        data={graphData.averageData}
                    />
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(GasConsumptionGraph);
