import React from 'react';

import { HourList, HourItem } from 'styled/components/weekOverview';

import { occupancy_working_hours } from './types';

interface DayOverviewProps {
    occupancyByHour: occupancy_working_hours;
}
const DayOverview: React.FunctionComponent<DayOverviewProps> = ({ occupancyByHour }) => {
    return (
        <HourList>
            {occupancyByHour.map((o, index) => (
                <HourItem key={index} percentage={o}>
                    <p>
                        <span>{`${o.toFixed(0)}%`}</span>
                    </p>
                </HourItem>
            ))}
        </HourList>
    );
};

export default DayOverview;
