import React from 'react';
import { MenuIcon } from 'styled/components/buttons';

import { ModalHeaderWrapper, ModalIcon, ModalTitle } from 'styled/components/modal';

import closeIcon from '../../assets/icons/i-close.svg';

interface Props {
    title?: string;
    onClose: () => void;
}
const ModalHeader: React.FunctionComponent<Props> = ({ title, onClose }) => {
    return (
        <ModalHeaderWrapper>
            <ModalTitle>{title}</ModalTitle>
            <ModalIcon onClick={onClose}>
                <MenuIcon $disabled={false} $active={false} src={closeIcon} />
            </ModalIcon>
        </ModalHeaderWrapper>
    );
};

export default ModalHeader;
