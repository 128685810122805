import React from 'react';

import IncidentsDataPane from 'components/DataPane/incidents/IncidentsDataPane';
import { navItemsEnergy } from 'components/Submenu/ModulesSubMenu';
import { IncidentGraphData } from 'components/Graph/types';
import IssuesOverview from 'components/IssuesOverview';
import EnergyIncidentsGraph from 'components/Graph/incidents/EnergyIncidentsGraph';

import { Incident, ModuleType } from 'types';

const EnergyIssuesView: React.FunctionComponent = () => {
    return (
        <IssuesOverview
            module={ModuleType.ENERGY}
            subNavItems={navItemsEnergy}
            renderDataPane={(incident: Incident) => {
                return (
                    <IncidentsDataPane
                        incident={incident}
                        renderGraph={(graphData: IncidentGraphData, isBarChart: boolean) => {
                            return <EnergyIncidentsGraph graphData={graphData} isBarChart={isBarChart} />;
                        }}
                    />
                );
            }}
        />
    );
};

export default EnergyIssuesView;
