import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { OccupancyScoreAvg, Sensor, SensorAverage, SensorAvgFilter, SensorData, SensorSubType } from 'types';
import { OccupancyDataParam } from 'components/DataPane/statistics/occupancy/types';
import { now, threeMonthsAgo } from 'utils/timePeriod';

export const useOccupancyAverageData = (occupancyParams: OccupancyDataParam, occupancySensors: Sensor[]) => {
    const { axiosClient } = useAxiosClient();

    const selectedOccupancyCountSensors = occupancySensors.filter(
        sensor =>
            sensor.buildingRef === occupancyParams.selectedOccupancyBuilding &&
            sensor.subType === SensorSubType.COUNT &&
            (occupancyParams.selectedOccupancyRoom ? sensor.roomRef === occupancyParams.selectedOccupancyRoom : false)
    );

    return useQuery(
        ['occupancyAverage', occupancyParams],
        async ({ signal }) => {
            let occupancyAvgData: OccupancyScoreAvg[] = [];
            let occupancyCountAvgData: SensorAverage[] = [];

            if (selectedOccupancyCountSensors.length) {
                const { data } = await axiosClient.get<SensorAverage[]>(
                    config.sensorDataAverage(selectedOccupancyCountSensors[0].id, occupancyParams.averageFilter),
                    // createDataAverageUrl(
                    //     selectedOccupancyCountSensors[0].id,
                    //     occupancyParams.averageFilter,
                    //     isBarChart
                    // ),
                    {
                        signal,
                    }
                );
                occupancyCountAvgData = data;
            }

            const { data } = await axiosClient.get<OccupancyScoreAvg[]>(getOccupancyAvgEndpoint(occupancyParams), {
                signal,
            });
            occupancyAvgData = data;
            return { occupancyAvgData: occupancyAvgData, occupancyCountAvgData: occupancyCountAvgData };
        },
        {
            enabled: Boolean(occupancyParams.selectedOccupancyBuilding),
        }
    );
};

const getOccupancyAvgEndpoint = (occupancyParams: OccupancyDataParam) => {
    return occupancyParams.selectedOccupancyBuilding && occupancyParams.selectedOccupancyRoom
        ? config.occupancyRoomExpandedAvg(
              occupancyParams.selectedOccupancyBuilding,
              occupancyParams.selectedOccupancyRoom,
              threeMonthsAgo,
              now
          )
        : config.occupancyBuildingAvg(occupancyParams.selectedOccupancyBuilding, threeMonthsAgo, now);
};

const createDataAverageUrl = (id: string, filter: SensorAvgFilter, isBarChart: boolean) => {
    return config.sensorDataAverage(id, filter);
};
