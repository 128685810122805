import { numberFormatter } from 'components/DataPane/statistics/energy/energyStatistics.helper';
import React, { createRef, useEffect, useRef, useCallback } from 'react';

import { SensorListContainer, SensorList, SensorListItem, SensorWrapper } from 'styled/components/sensors';
import { KpiUnit } from 'types';
import { EquipList } from '../../views/authenticated/types';

type DispatchType =
    | 'SELECT_SPACE1'
    | 'SELECT_SPACE2'
    | 'SELECT_SPACE3'
    | 'SELECT_SPACE4'
    | 'SELECT_SPACE5'
    | 'SELECT_SPACE6';
const dispatchType = [
    'SELECT_SPACE1',
    'SELECT_SPACE2',
    'SELECT_SPACE3',
    'SELECT_SPACE4',
    'SELECT_SPACE5',
    'SELECT_SPACE6',
] as const;

interface SensorSelectorProps {
    levels?: number;
    equips: EquipList[];
    onEquipSelect: (selectAction: DispatchType, roomId: string) => void;
    collapsed: boolean;
}
const SensorSelector: React.FunctionComponent<SensorSelectorProps> = ({
    levels = 5,
    equips,
    onEquipSelect,
    collapsed,
}) => {
    const elementsRef = useRef<Array<Array<React.RefObject<HTMLLIElement>>>>(
        equips.map(equip => equip.spaces.map(() => createRef<HTMLLIElement>()))
    );
    const sensorWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (equips.length > 0 && elementsRef.current.length) {
            scrollSelectedItemInView();
        }
    }, [equips]);

    const scrollSelectedItemInView = useCallback(() => {
        const selectedSpaces: Array<number> = [];
        equips.map((spaces, equipIndex) => {
            spaces.spaces.map((space, index) => {
                if (spaces.selectedSpace === space.id) {
                    selectedSpaces[equipIndex] = index;
                }
            });
        });
        selectedSpaces.forEach((value, index) => {
            const boundingRectWrapper = sensorWrapperRef.current?.getBoundingClientRect();
            const boundingRect = elementsRef.current[index][value]?.current?.getBoundingClientRect();
            if (
                (boundingRect && boundingRectWrapper && boundingRect.bottom > boundingRectWrapper.bottom) ||
                (boundingRect && boundingRectWrapper && boundingRect.top < boundingRectWrapper.top)
            ) {
                elementsRef.current[index][value]?.current?.scrollIntoView();
            }
        });
    }, [equips]);

    return (
        <SensorWrapper levels={levels} ref={sensorWrapperRef} collapsed={collapsed}>
            {equips.map((spaces, equipIndex) => {
                return (
                    <SensorListContainer key={equipIndex}>
                        <SensorList>
                            {spaces.spaces.map((space, index) => (
                                <SensorListItem
                                    ref={elementsRef.current[equipIndex][index]}
                                    hasIssue={space.hasError}
                                    selected={spaces.selectedSpace === space.id}
                                    onClick={() => {
                                        // Disable click on same button
                                        spaces.selectedSpace === space.id && !equips[equipIndex + 1].selectedSpace
                                            ? null
                                            : onEquipSelect(dispatchType[equipIndex], space.id);
                                    }}
                                    key={space.id}
                                >
                                    <div title={space.name}>
                                        <p>{space.name}</p>
                                        <p />
                                        {Boolean(space.value || space.value === 0) && (
                                            <p>{accumulatedSpaceValue(space.value)}</p>
                                        )}
                                    </div>
                                </SensorListItem>
                            ))}
                        </SensorList>
                    </SensorListContainer>
                );
            })}
        </SensorWrapper>
    );
};

export default SensorSelector;

const accumulatedSpaceValue = (value: number) => {
    return typeof value === 'number'
        ? ` ${numberFormatter(value, 2)} ${value > 999 ? KpiUnit.MWH : KpiUnit.KWH}`
        : null;
};
