import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NavigationItem } from '../../NavigationBar';
import { SubMenuButton, Icon } from '../../../styled/components/buttons';

interface MenuItemProps {
    navItem: NavigationItem;
}

const SubMenuItem: React.FunctionComponent<MenuItemProps> = ({ navItem }) => {
    const navigate = useNavigate();

    return (
        <SubMenuButton
            active={navItem.active}
            module={navItem.moduleType}
            onClick={() => {
                navigate(navItem.path);
            }}
        >
            <Icon $disabled={false} $active={navItem.active} src={navItem.icon ? navItem.icon : ''} />
            {navItem.label}
        </SubMenuButton>
    );
};

export default SubMenuItem;
