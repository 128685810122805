import React from 'react';

import { useTranslation } from 'react-i18next';

import { DisplayRow } from 'styled/components/energieContract';
import { BodyLeft, LeadLeft } from 'styled/components/text';

import { KpiUnit } from 'types';

import {
    ContractType,
    ElectricityContract,
    EnergyType,
    GasContract,
    isElectricityContract,
    isGasContract,
} from 'views/authenticated/profile/types';

interface Props {
    contract?: GasContract | ElectricityContract;
}
const ViewContract: React.FunctionComponent<Props> = ({ contract }) => {
    const { t } = useTranslation();

    const energyUnit = contract.energyType === EnergyType.GAS ? KpiUnit.M3 : KpiUnit.KWH;

    return (
        <>
            {contract.energyType === EnergyType.ELECTRICITY && (
                <>
                    <DisplayRow>
                        <LeadLeft>{t('company.energyContract.form.contractType')}</LeadLeft>
                        <BodyLeft>{contract.contractType}</BodyLeft>
                    </DisplayRow>
                    <br />
                </>
            )}
            <DisplayRow>
                <LeadLeft>{t('company.energyContract.form.startDate')}</LeadLeft>
                <BodyLeft>{contract.startDate}</BodyLeft>
            </DisplayRow>
            <br />
            <DisplayRow>
                <LeadLeft>{t('company.energyContract.form.endDate')}</LeadLeft>
                <BodyLeft>{contract.endDate}</BodyLeft>
            </DisplayRow>
            <br />
            <DisplayRow>
                <LeadLeft>{t('company.energyContract.form.serviceCost', { unit: energyUnit })}</LeadLeft>
                <BodyLeft>{`€${contract.serviceCost}`}</BodyLeft>
            </DisplayRow>
            <br />
            <DisplayRow>
                <LeadLeft>{t('company.energyContract.form.contractCost', { unit: energyUnit })}</LeadLeft>
                <BodyLeft>{`€${contract.contractCost}`}</BodyLeft>
            </DisplayRow>
            <br />
            {isGasContract(contract) && (
                <>
                    <DisplayRow>
                        <LeadLeft>{t('company.energyContract.form.price')}</LeadLeft>
                        <BodyLeft>{`€${contract.price}`}</BodyLeft>
                    </DisplayRow>
                    <br />
                </>
            )}

            {isElectricityContract(contract) && (
                <>
                    <DisplayRow>
                        <LeadLeft>
                            {contract.contractType === ContractType.CONSTANT
                                ? t('company.energyContract.form.price')
                                : t('company.energyContract.form.lowPrice')}
                        </LeadLeft>
                        <BodyLeft>{`€${contract.lowPrice}`}</BodyLeft>
                    </DisplayRow>
                    <br />
                    {contract.contractType != ContractType.CONSTANT && (
                        <>
                            <DisplayRow>
                                <LeadLeft>{t('company.energyContract.form.highPrice')}</LeadLeft>
                                <BodyLeft>{`€${contract.highPrice}`}</BodyLeft>
                            </DisplayRow>
                            <br />
                        </>
                    )}
                    {contract.contractType === ContractType.PEAK && (
                        <>
                            <DisplayRow>
                                <LeadLeft>{t('company.energyContract.form.startPeak')}</LeadLeft>
                                <BodyLeft>{`${contract.startPeak}:00`}</BodyLeft>
                            </DisplayRow>
                            <br />
                        </>
                    )}
                    {contract.contractType === ContractType.PEAK && (
                        <>
                            <DisplayRow>
                                <LeadLeft>{t('company.energyContract.form.endPeak')}</LeadLeft>
                                <BodyLeft>{`${contract.endPeak}:00`}</BodyLeft>
                            </DisplayRow>
                            <br />
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default ViewContract;
