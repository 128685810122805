import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import NotFound from './NotFound';
import ServerError from './ServerError';

import { SmileyBackground, StyledErrorBoundary } from 'styled/components/errorBoundary';

import { ErrorCode } from './types';
import { routes } from 'routing/routes';

interface PropsInner {
    onReturn: () => void;
    children?: React.ReactNode;
}

interface State {
    hasError: boolean;
    errorCode: number;
}

class ErrorBoundaryInner extends React.Component<PropsInner, State> {
    state: State = {
        hasError: false,
        errorCode: 500,
    };

    static getDerivedStateFromError(e: any) {
        return {
            hasError: true,
            errorCode:
                e.statusCode === ErrorCode.NOT_FOUND || e.response?.status === ErrorCode.NOT_FOUND
                    ? ErrorCode.NOT_FOUND
                    : ErrorCode.SERVER_ERROR,
        };
    }

    render() {
        if (this.state.hasError) {
            return (
                <StyledErrorBoundary>
                    <div>
                        <SmileyBackground />
                        {this.state.errorCode === ErrorCode.NOT_FOUND ? (
                            <NotFound onReturn={this.props.onReturn} />
                        ) : (
                            <ServerError onRefresh={() => this.setState({ hasError: false })} />
                        )}
                    </div>
                </StyledErrorBoundary>
            );
        }

        return this.props.children;
    }
}

interface Props {
    children: React.ReactNode;
}
const ErrorBoundary: React.FunctionComponent<Props> = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <ErrorBoundaryInner key={location.pathname} onReturn={() => navigate(routes.index)}>
            {children}
        </ErrorBoundaryInner>
    );
};

export default ErrorBoundary;
