import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorAverage, SensorAvgFilter, SensorSubType, SensorType } from 'types';

export const useSensorAverageData = (
    id: string,
    type: SensorType,
    subType: SensorSubType,
    filter: SensorAvgFilter,
    isBarChart: boolean
) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['sensorsAverage', id, type, filter, isBarChart],
        async ({ signal }) => {
            const { data } = await axiosClient.get<SensorAverage[]>(
                createDataAverageUrl(id, type, subType, filter, isBarChart),
                { signal }
            );

            return data;
        },
        {
            enabled: Boolean(id),
        }
    );
};

const createDataAverageUrl = (
    id: string,
    type: SensorType,
    subType: SensorSubType,
    filter: SensorAvgFilter,
    isBarChart: boolean
) => {
    if (
        isBarChart &&
        ((type === SensorType.MOTION && subType !== SensorSubType.COUNT) || type === SensorType.MOVEMENT)
    ) {
        return config.sensorDataAverageTotal([id], filter);
    }

    return config.sensorDataAverage(id, filter);
};
