import React, { useState, FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import { NavigationItem } from '../NavigationBar';
import { FooterWrapper } from 'styled/components/view';

import { FooterTitleBar, FooterLinksBar, CopyrightBar } from '../../styled/components/navigation';
import styled from 'styled-components';
import unicaIcon from '../../assets/icons/by-unica-footer.svg';

const StyledExternalLink = styled.a`
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    margin-right: 50px;
`;

const navigationItems: NavigationItem[] = [
    {
        path: 'https://www.unica.nl/',
        active: false,
        enabled: true,
        label: i18n.t('footer.unica'),
    },
    {
        path: 'https://www.unica.nl/privacy_statement',
        active: false,
        enabled: true,
        label: i18n.t('footer.privacy'),
    },
    {
        path: 'https://building-insight.nl/conditions/Unica%20-%20Algemene%20Voorwaarden%20Webshop%20-%2020201130.pdf',
        active: false,
        enabled: true,
        label: i18n.t('footer.terms'),
    },
];

const currentYear = new Date().getFullYear();
const copyright = '\u00a9';

const Footer: FunctionComponent = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [footerItems, setFooterItems] = useState<Array<NavigationItem>>(navigationItems);

    useEffect(() => {
        const currentLocation = location.pathname;
        const updatedMenuItems = footerItems.map(item => {
            item.active = currentLocation === item.path;

            return item;
        });
        setFooterItems([...updatedMenuItems]);
    }, [location.pathname]);

    return (
        <FooterWrapper>
            <FooterTitleBar>
                <img src={unicaIcon} alt="Building Insight by Unica" />
            </FooterTitleBar>
            <FooterLinksBar>
                {footerItems.map((m, index) => (
                    <StyledExternalLink href={m.path} key={index} target="_blank" rel="noreferrer noopener">
                        {m.label}
                    </StyledExternalLink>
                ))}
            </FooterLinksBar>
            <CopyrightBar>
                <p>{t('footer.copyright', { copyright: copyright, currentYear: currentYear })}</p>
            </CopyrightBar>
        </FooterWrapper>
    );
};

export default Footer;
