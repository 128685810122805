import React from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryLine,
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
} from 'victory';
import { format } from 'date-fns';

import TooltipSensor from '../../Tooltip/TooltipSensor';

import { useResize } from 'hooks/useResize';

import { DateSpan } from 'utils/timePeriod';

import { GraphData } from '../../types';
import { Sensor, SensorType } from 'types';
import { closingAxis, closingXYAxis, XYAxis } from '../../graph.styles';
import { getUnit } from 'styled/components/sensors';

export interface LineGraphProps {
    graphData: GraphData;
    sensor: Sensor;
}
const LineGraph: React.FunctionComponent<LineGraphProps> = ({ graphData, sensor }) => {
    const dimension = useResize();
    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <svg style={{ height: 0, display: 'block' }}>
                <defs>
                    <linearGradient id="lineGradientRedYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.negative} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowRed" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="90%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="35%" stopColor={theme.colors.mFeedback} />
                        <stop offset="65%" stopColor={theme.colors.mFeedback} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurple" x2="0%" y2="100%">
                        <stop offset="10%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="40%" stopColor={theme.colors.mFeedback} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellow" x2="0%" y2="100%">
                        <stop offset="60%" stopColor={theme.colors.mFeedback} />
                        <stop offset="90%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurple" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="77%" stopColor={theme.colors.mFeedback} />
                    </linearGradient>
                    <linearGradient id="lineGradientPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="23%" stopColor={theme.colors.mFeedback} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="95%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellow" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="60%" stopColor={theme.colors.mFeedback} />
                        <stop offset="80%" stopColor={theme.colors.mFeedback} />
                        <stop offset="94%" stopColor={theme.colors.mEnergyL} />
                    </linearGradient>
                    <linearGradient id="lineGradientYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="20%" stopColor={theme.colors.mFeedback} />
                        <stop offset="40%" stopColor={theme.colors.mFeedback} />
                        <stop offset="60%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                    <linearGradient id="lineGradientRedYellowPurpleYellowRed" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="20%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="50%" stopColor={theme.colors.mFeedback} />
                        <stop offset="80%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="94%" stopColor={theme.colors.negative} />
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.18}
                domainPadding={{ x: [20, 0], y: 20 }}
                padding={{ bottom: 5, left: 35, right: 0, top: 8 }}
                containerComponent={
                    <VictoryZoomVoronoiContainer
                        mouseFollowTooltips={false}
                        voronoiDimension="x"
                        zoomDimension="x"
                        minimumZoom={{ x: 1, y: 0.005 }}
                        labels={() => ' '}
                        labelComponent={
                            <VictoryTooltip
                                dx={d => {
                                    const mousePosition = d.x;
                                    return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                }}
                                flyoutComponent={
                                    <TooltipSensor
                                        sensor={sensor}
                                        height={dimension.height}
                                        averageData={graphData.averageData}
                                    />
                                }
                            />
                        }
                    />
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatTick(t, ticks, index, graphData.timespan)
                    }
                    style={XYAxis}
                    orientation="top"
                />
                {/* Y-axis */}
                <VictoryAxis
                    dependentAxis={true}
                    style={XYAxis}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        ticks.length - 1 === index ? getUnit(sensor.unit, sensor.subType) : t
                    }
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingXYAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingAxis} orientation="bottom" />

                <VictoryLine
                    interpolation="monotoneX"
                    x="timestamp"
                    y="value"
                    style={{
                        data: { stroke: getLineColor(sensor.type, graphData.min, graphData.max) },
                    }}
                    data={graphData.data}
                />
            </VictoryChart>
        </>
    );
};

export default React.memo(LineGraph);

const formatTick = (t: number, ticks: number[], index: number, timespan: string) => {
    if (index === ticks.length - 1) {
        return '';
    }
    if (timespan === DateSpan.YEAR) {
        const month = format(new Date(t * 1000), 'MMM');
        return month === 'Jan' ? format(new Date(t * 1000), 'yyyy') : month;
    }
    const dayOfWeek = format(new Date(t * 1000), 'd');
    return dayOfWeek === '1' ? format(new Date(t * 1000), 'MMM') : dayOfWeek;
};

const getLineColor = (type: SensorType, min: number, max: number) => {
    switch (type) {
        case SensorType.TEMP:
        case SensorType.TEMPERATURE: {
            return getColorGradientMinMax(
                min,
                max,
                TEMPERATURE.minGood,
                TEMPERATURE.maxGood,
                TEMPERATURE.minAverage,
                TEMPERATURE.maxAverage
            );
        }
        case SensorType.CO2: {
            return getColorGradient(min, max, CO2.maxGood, CO2.maxAverage);
        }
        case SensorType.ILLUMINANCE:
            return theme.colors.mFeedback;
        default:
            return theme.colors.mEnergy;
    }
};

const getColorGradientMinMax = (
    min: number,
    max: number,
    minGood: number,
    maxGood: number,
    minAverage: number,
    maxAverage: number
) => {
    // Purple
    if (max <= maxGood && min >= minGood) return theme.colors.air;
    // Red
    if (max <= minAverage) return theme.colors.negative;
    // Red
    if (min >= maxAverage) return theme.colors.negative;
    // Yellow
    if (min >= maxGood && max <= maxAverage) return theme.colors.mEnergy;
    // Yellow
    if (min >= minAverage && max <= minGood) return theme.colors.mEnergy;
    // Red Yellow
    if (max >= maxAverage && min >= maxGood && min <= maxAverage) return 'url(#lineGradientRedYellow)';
    // Yellow Red
    if (min <= minAverage && max <= minGood && max >= minAverage) return 'url(#lineGradientYellowRed)';
    // Yellow Purple Yellow
    if (max >= maxGood && max <= maxAverage && min <= minGood && min >= minAverage)
        return 'url(#lineGradientYellowPurpleYellow)';
    // Yellow Purple
    if (min >= minGood && min <= maxGood && max >= maxGood && max <= maxAverage)
        return 'url(#lineGradientYellowPurple)';
    // Purple Yellow
    if (max >= minGood && max <= maxGood && min <= minGood && min >= minAverage)
        return 'url(#lineGradientPurpleYellow)';
    // Red Yellow Purple
    if (min >= minGood && min <= maxGood && max >= maxAverage) return 'url(#lineGradientRedYellowPurple)';
    // Purple Yellow Red
    if (max >= minGood && max <= maxGood && min <= minAverage) return 'url(#lineGradientPurpleYellowRed)';
    // Red Yellow Purple Yellow
    if (max >= maxAverage && min <= minGood && min >= minAverage) return 'url(#lineGradientRedYellowPurpleYellow)';
    // Yellow Purple Yellow Red
    if (max >= maxGood && max <= maxAverage && min <= minAverage) return 'url(#lineGradientYellowPurpleYellowRed)';
    // Red Yellow Purple Yellow Red
    if (max >= maxAverage && min <= minAverage) return 'url(#lineGradientRedYellowPurpleYellowRed)';
};

const getColorGradient = (min: number, max: number, maxGood: number, maxAverage: number) => {
    // Red
    if (min >= maxAverage) return theme.colors.negative;
    // Purple
    if (max <= maxGood) return theme.colors.air;
    // Yellow
    if (min >= maxGood && max <= maxAverage) return theme.colors.mEnergyL;
    // Red Yellow
    if (min >= maxGood && min <= maxAverage && max >= maxAverage) return 'url(#lineGradientRedYellow)';
    // Yellow Purple
    if (min <= maxGood && max >= maxGood && max <= maxAverage) return 'url(#lineGradientYellowPurple)';
    // Red Yellow Purple
    if (min <= maxGood && max >= maxAverage) return 'url(#lineGradientRedYellowPurple)';
};

const TEMPERATURE = {
    maxGood: 22,
    maxAverage: 24,
    minGood: 20,
    minAverage: 18,
};

const CO2 = {
    maxGood: 800,
    maxAverage: 1200,
};
