import { useMutation, useQueryClient } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

export const usePreferencesMutation = <T>() => {
    const queryClient = useQueryClient();
    const { axiosClient } = useAxiosClient();

    return useMutation(
        (payload: T) => {
            return axiosClient.post(config.preferences.preferencesTenantPost, { input: payload });
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries('tenant');
            },
        }
    );
};
