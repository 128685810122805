import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { KpiUnit, Sensor, SensorAverage } from 'types';
import { SensorDataParam, Top5SensorData } from 'components/DataPane/statistics/energy/types';
import { summedValue } from 'components/DataPane/statistics/energy/energyStatistics.helper';

export const useTop5Data = (subMeterParams: SensorDataParam, ewattchParams: SensorDataParam, sensors: Sensor[]) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['top5', subMeterParams.ids, ewattchParams.ids, subMeterParams.from, subMeterParams.to],
        async ({ signal }): Promise<Top5SensorData[]> => {
            const promiseArray = createTop5Endpoints(subMeterParams, ewattchParams).map(url =>
                axiosClient.get<SensorAverage[]>(url, { signal })
            );

            const result = await Promise.all(promiseArray);

            const top5 = createTop5(result, sensors);

            return top5;
        },
        {
            enabled: Boolean(subMeterParams.ids.length > 1 || ewattchParams.ids.length > 1),
        }
    );
};

const createTop5 = (result: AxiosResponse<SensorAverage[]>[], sensors: Sensor[]) => {
    const top5: Top5SensorData[] = [];

    result.forEach(r => {
        const possibleId = r.config.url.match(/ids=(?<id>.+?)&/);
        const id = possibleId ? possibleId.groups?.id : '';
        const sensor = sensors.find(s => s.id === id);
        const name = sensor?.name || '';

        const data =
            sensor?.unit === KpiUnit.WH
                ? r.data.map(d => ({ value: Number((d.value / 1000).toFixed(2)), field: d.field }))
                : r.data;

        top5.push({ name, data });
    });

    return top5.length > 5 ? sortTop5(top5).slice(0, 5) : sortTop5(top5);
};

const sortTop5 = (top5: Top5SensorData[]) => {
    return top5.sort((a, b) =>
        summedValue(a.data) > summedValue(b.data) ? -1 : summedValue(a.data) < summedValue(b.data) ? 1 : 0
    );
};

export const createTop5Endpoints = (subMeterParams: SensorDataParam, ewattchParams: SensorDataParam) => {
    const urlArray: string[] = [];

    subMeterParams.ids.forEach((id, i) => {
        urlArray[i] = config.sensorDataSumTotal(
            [id],
            subMeterParams.averageFilter,
            subMeterParams.from,
            subMeterParams.to
        );
    });

    return urlArray;
};
