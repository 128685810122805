import {
    addDays,
    endOfDay,
    endOfHour,
    subHours,
    subDays,
    subWeeks,
    subMonths,
    subYears,
    format,
    startOfDay,
    startOfHour,
    startOfMonth,
    isAfter,
} from 'date-fns';

export enum DateSpan {
    ONE_DAY = 'oneDay',
    TWO_DAYS = 'twoDays',
    WEEK = 'week',
    MONTH = 'month',
    CURRENT_MONTH = 'currentMonth',
    PREVIOUS_MONTH = 'previousMonth',
    YEAR = 'year',
    EVER = 'ever',
    CUSTOM = 'custom',
}

export interface DateRange {
    from: string;
    to: string;
}

export const oneDay = 1;
export const twoDays = 2;
export const week = 7;
export const month = 30;

export const hourFormat = 'HH:mm';
export const dateFormat = 'yyyy-MM-dd';
export const dateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss";
export const monthDayFormat = 'MMM dd';

export const now = format(endOfHour(new Date()), dateTimeFormat);

export const last24Hours = format(subHours(new Date(), 24), dateFormat);

export const lastWeek = format(subWeeks(new Date(), 1), dateFormat);

export const lastMonth = format(subMonths(new Date(), 1), dateFormat);

export const threeMonthsAgo = format(subMonths(new Date(), 3), dateFormat);

export const lastYear = format(subYears(new Date(), 1), dateFormat);

export const isDaysTimespan = (timespan: string) => {
    return timespan === DateSpan.ONE_DAY || timespan === DateSpan.TWO_DAYS;
};

export const isWeeksTimespan = (timespan: string) => {
    return timespan === DateSpan.WEEK || timespan === DateSpan.MONTH;
};

export const createIncidentDateRange = (timestamp: string, daySpan: number): DateRange => {
    const incidentDateTime = new Date(timestamp);
    const currentDateTime = new Date();
    const toDateTime =
        daySpan > 2 ? endOfDay(addDays(incidentDateTime, daySpan)) : endOfHour(addDays(incidentDateTime, daySpan));

    const from =
        daySpan > 2
            ? startOfDay(subDays(incidentDateTime, daySpan)).toISOString()
            : startOfHour(subDays(incidentDateTime, daySpan)).toISOString();
    const to = isAfter(toDateTime, currentDateTime)
        ? endOfHour(currentDateTime).toISOString()
        : toDateTime.toISOString();

    return {
        from,
        to,
    };
};

export const createIncidentFromToDate = (action: string, timestamp: string) => {
    let dateRange: DateRange = { from: '', to: '' };
    switch (action) {
        case DateSpan.ONE_DAY:
            dateRange = createIncidentDateRange(timestamp, oneDay);
            break;
        case DateSpan.TWO_DAYS:
            dateRange = createIncidentDateRange(timestamp, twoDays);
            break;
        case DateSpan.WEEK:
            dateRange = createIncidentDateRange(timestamp, week);
            break;
        case DateSpan.MONTH:
            dateRange = createIncidentDateRange(timestamp, month);
            break;
        default:
            console.error('invalid date span');
    }
    return dateRange;
};

export const createFromToDate = (action: string) => {
    const dateRange: DateRange = { from: '', to: endOfHour(new Date()).toISOString() };
    switch (action) {
        case DateSpan.ONE_DAY:
            dateRange.from = startOfDay(subDays(new Date(), 1)).toISOString();
            break;
        case DateSpan.TWO_DAYS:
            dateRange.from = startOfDay(subDays(new Date(), 2)).toISOString();
            break;
        case DateSpan.WEEK:
            dateRange.from = startOfDay(subDays(new Date(), 7)).toISOString();
            break;
        case DateSpan.MONTH:
            dateRange.from = startOfDay(subMonths(new Date(), 1)).toISOString();
            break;
        case DateSpan.YEAR:
            dateRange.from = startOfMonth(subYears(new Date(), 1)).toISOString();
            break;
        case DateSpan.EVER:
            dateRange.from = '';
            break;
        default:
            console.error('invalid date span');
    }
    return dateRange;
};

export const createTimestamp = (timestamp: number, timespan?: string): string => {
    const dateTime = new Date((timestamp || 0) * 1000);

    if (timespan === DateSpan.YEAR) return format(dateTime, 'MMM dd');
    return `${dateTime.getHours()}:${('0' + dateTime.getMinutes()).slice(-2)}`;
};
