import React from 'react';

import { StyledLegendaItem } from 'styled/components/ebs';
import { CondensedLight } from 'styled/components/text';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isSelected?: boolean;
    children?: React.ReactNode;
}
const LegendaItem: React.FunctionComponent<Props> = ({ isSelected = true, children, ...rest }) => {
    return (
        <StyledLegendaItem className={isSelected ? 'selected' : ''} {...rest}>
            <div />
            <CondensedLight>{children}</CondensedLight>
        </StyledLegendaItem>
    );
};

export default LegendaItem;
