import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryBar,
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryLine,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipBar from '../../Tooltip/TooltipBar';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import { setBarConfig, percentageValues, formatXAxisTickValue } from '../../graph.helpers';
import {
    barChartDomainPadding,
    dateAxis,
    closingAxis,
    closingXYAxis,
    XYAxis,
    barChartPadding,
} from '../../graph.styles';
import { GraphData, SensorValue } from '../../types';
import { KpiUnit, ModuleType } from 'types';
import TooltipIncident from 'components/Graph/Tooltip/TooltipIncident';

export interface BarChartProps {
    graphData: GraphData;
    isBarChart: boolean;
}
const OccupancyChart: React.FunctionComponent<BarChartProps> = ({ graphData, isBarChart }) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <VictoryChart
            width={dimension.width}
            height={dimension.height * 0.29}
            domainPadding={barChartDomainPadding}
            padding={barChartPadding}
            containerComponent={
                !isBarChart ? (
                    <VictoryZoomVoronoiContainer
                        mouseFollowTooltips={false}
                        voronoiDimension="x"
                        zoomDimension="x"
                        minimumZoom={{ x: 1, y: 0.005 }}
                        labels={() => ' '}
                        labelComponent={
                            <VictoryTooltip
                                dx={d => {
                                    const mousePosition = d.x;
                                    return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                }}
                                flyoutComponent={
                                    <TooltipIncident
                                        module={ModuleType.OCCUPANCY}
                                        unit={KpiUnit.PERCENTAGE}
                                        height={dimension.height}
                                        averageData={graphData.averageData}
                                    />
                                }
                            />
                        }
                    />
                ) : (
                    <VictoryContainer />
                )
            }
        >
            {/* X-axis */}
            <VictoryAxis
                tickValues={graphData.tickValues}
                tickFormat={(t: number, index: number, ticks: number[]) =>
                    formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                }
                style={XYAxis}
            />
            {/* Occupancy Y-axis */}
            <VictoryAxis
                tickValues={[0, 25, 50, 75, 100]}
                tickFormat={(t: number) => (t === 100 ? KpiUnit.PERCENTAGE : t === 0 ? '' : t)}
                dependentAxis={true}
                style={XYAxis}
            />

            {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
            <VictoryAxis style={closingAxis} orientation="right" />
            <VictoryAxis style={closingAxis} orientation="top" />
            <VictoryAxis style={closingXYAxis} orientation="left" />
            <VictoryAxis style={closingXYAxis} orientation="bottom" />

            {/* Date Y-axis*/}
            {graphData.axisDates &&
                Boolean(graphData.axisDates.length) &&
                graphData.axisDates.map(axis => (
                    <VictoryAxis
                        axisValue={axis}
                        dependentAxis={true}
                        label={format(new Date(axis * 1000), monthDayFormat)}
                        tickFormat={() => ''}
                        style={dateAxis}
                        key={axis}
                    />
                ))}

            {isBarChart && (
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={ModuleType.OCCUPANCY}
                                    title={t('tooltip.average')}
                                    unit={KpiUnit.PERCENTAGE}
                                />
                            }
                        />
                    }
                    animate={{ duration: 500, easing: 'cubicOut' }}
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mOccupL },
                    }}
                    x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                    y="value"
                    data={graphData.averageData}
                />
            )}
            {isBarChart && (
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={ModuleType.OCCUPANCY}
                                    title={t('tooltip.current')}
                                    unit={KpiUnit.PERCENTAGE}
                                />
                            }
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mOccup },
                    }}
                    x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                    y="value"
                    data={graphData.data}
                />
            )}
            {!isBarChart && (
                <VictoryLine
                    interpolation="monotoneX"
                    x="timestamp"
                    y="value"
                    style={{
                        data: { stroke: theme.colors.mOccup },
                    }}
                    data={graphData.data}
                />
            )}
            {!isBarChart && (
                <VictoryLine
                    name="average"
                    animate={true}
                    interpolation="monotoneX"
                    x="timestamp"
                    y="value"
                    style={{
                        data: { stroke: theme.colors.mOccupL },
                    }}
                    data={graphData.averageData}
                />
            )}
        </VictoryChart>
    );
};

export default React.memo(OccupancyChart);
