import React, { Dispatch, createContext, useState, useCallback, useReducer, useRef, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { Action, AuthState } from './types';

import { delegateToken, getPersistance, postLogin, postPersistance, revocateLogin } from '../services/authService';

const initialState: AuthState = {
    is_fetching: true,
    is_logged_in: false,
    is_authenticated: false,
    error: '',
};

const authReducer = (state: AuthState, action: Action): AuthState => {
    switch (action.type) {
        case 'LOGIN_SUCCESS':
            return {
                ...state,
                is_fetching: false,
                is_logged_in: true,
                error: '',
            };
        case 'LOGIN_ERROR':
            return {
                ...state,
                is_fetching: false,
                is_logged_in: false,
                error: action.errorMessage,
            };
        case 'LOGGED_OUT':
            return {
                is_authenticated: false,
                is_logged_in: false,
                is_fetching: false,
                error: '',
            };
        case 'SET_AUTH':
            return {
                ...state,
                is_authenticated: true,
                is_fetching: true,
            };
    }
};

export interface IAuthProvider {
    authState: AuthState;
    accessToken: React.MutableRefObject<string>;
    refreshToken: React.MutableRefObject<string>;
    expiresAt: React.MutableRefObject<number>;
    isLoading: boolean;
    setIsLoading: Dispatch<React.SetStateAction<boolean>>;
    login: (name: string, password: string) => void;
    logOut: () => void;
    dispatch: Dispatch<Action>;
}

const AuthContext = createContext({} as IAuthProvider);

interface AuthProps {
    children?: React.ReactNode;
}
const AuthProvider: React.FunctionComponent<AuthProps> = ({ children }) => {
    const [isLoading, setIsLoading] = useState(false);
    const refreshToken = useRef('');
    const accessToken = useRef('');
    const expiresAt = useRef(0);
    const [authState, dispatch] = useReducer(authReducer, initialState);
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    useEffect(() => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const id = params.get('id');

        if (id) {
            delegateToken(id)
                .then(result => {
                    const jwtPayload = JSON.parse(window.atob(result.data.access_token.split('.')[1]));
                    expiresAt.current = (jwtPayload.exp - 10) * 1000;
                    accessToken.current = result.data.access_token;
                    refreshToken.current = result.data.refresh_token;
                    dispatch({ type: 'SET_AUTH' });
                })
                .catch(() => {
                    dispatch({
                        type: 'LOGGED_OUT',
                    });
                });
        } else {
            getPersistance()
                .then(result => {
                    const jwtPayload = JSON.parse(window.atob(result.data.auth.access_token.split('.')[1]));
                    expiresAt.current = (jwtPayload.exp - 10) * 1000;
                    accessToken.current = result.data.auth.access_token;
                    refreshToken.current = result.data.auth.refresh_token;
                    dispatch({ type: 'SET_AUTH' });
                })
                .catch(() => {
                    console.log('refresh error');
                    dispatch({
                        type: 'LOGGED_OUT',
                    });
                });
        }
    }, []);

    const login = useCallback(
        async (email: string, password: string) => {
            setIsLoading(true);
            await postLogin(email, password)
                .then(result => {
                    postPersistance(result.data);

                    const jwtPayload = JSON.parse(window.atob(result.data.access_token.split('.')[1]));
                    expiresAt.current = (jwtPayload.exp - 10) * 1000;
                    accessToken.current = result.data.access_token;
                    refreshToken.current = result.data.refresh_token;
                    dispatch({ type: 'SET_AUTH' });
                })
                .catch(e => {
                    const message = e.response?.status === 400 ? t('context.invalid') : t('context.error');
                    dispatch({ type: 'LOGIN_ERROR', errorMessage: message });
                    setIsLoading(false);
                });
        },
        [t]
    );

    const logOut = useCallback(() => {
        queryClient.clear();
        revocateLogin(refreshToken.current);
        dispatch({ type: 'LOGGED_OUT' });
        accessToken.current = '';
        refreshToken.current = '';
        expiresAt.current = 0;
    }, []);

    const authContextValue = {
        authState,
        expiresAt,
        accessToken,
        refreshToken,
        login,
        logOut,
        dispatch,
        isLoading,
        setIsLoading,
    };
    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within a AuthProvider');
    }
    return context;
};

export { AuthProvider, useAuth };
