import styled from 'styled-components';

import { format } from 'date-fns';
import i18n from '../../i18n';
import SVG from 'react-inlinesvg';

import { getColor, getLightColor, getTransparentColor } from 'styled/Theme';

import leakageImage from '../../assets/images/incidents/issue-tile-img-w-leakage.svg';
import legionellaImage from '../../assets/images/incidents/issue-tile-img-w-legionella.svg';
import crowdImage from '../../assets/images/incidents/issue-tile-img-o-crowd.svg';
import movementImage from '../../assets/images/incidents/issue-tile-img-o-movement.svg';
import highEnergyImage from '../../assets/images/incidents/issue-tile-img-e-plot.svg';
import chargingDefectImage from '../../assets/images/incidents/issue-tile-img-m-failure.svg';
import rapidIncreaseImage from '../../assets/images/incidents/issue-tile-img-a-temp-increase.svg';
import rapidDecreaseImage from '../../assets/images/incidents/issue-tile-img-a-temp-decrease.svg';
import highTemperatureImage from '../../assets/images/incidents/issue-tile-img-a-temp.svg';
import lowTemperatureImage from '../../assets/images/incidents/issue-tile-img-a-temp-low.svg';
import highCO2Image from '../../assets/images/incidents/issue-tile-img-a-co-2.svg';
import highHumidityImage from '../../assets/images/incidents/issue-tile-img-a-hum-high.svg';
import lowHumidityImage from '../../assets/images/incidents/issue-tile-img-a-hum-low.svg';

import {
    AnomalySubType,
    AnomalySubTypeAir,
    AnomalySubTypeOccupancy,
    ModuleType,
    Incident,
    AnomalySubTypeEnergy,
    AnomalySubTypeWater,
    AnomalySubTypeSensor,
} from 'types';

import { hourFormat } from 'utils/timePeriod';

export const getTitle = (anomaly: AnomalySubType, value: number, valueBefore?: number) => {
    switch (anomaly) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
            return i18n.t('incidents.airQualityTitle');
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return i18n.t('incidents.abnormalHumidityTitle');
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
            return i18n.t('incidents.abnormalTemperatureTitle');
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return i18n.t('incidents.highTemperatureTitle');
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE: {
            const temperatureChangeMessage =
                value > (valueBefore || 0)
                    ? i18n.t('incidents.rapidTemperatureIncreaseTitle')
                    : i18n.t('incidents.rapidTemperatureDecreaseTitle');
            return temperatureChangeMessage;
        }
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return i18n.t('incidents.energyAboveAverageTitle');
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return i18n.t('incidents.waterLowOccupancyTitle');
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return i18n.t('incidents.waterZeroConsumptionTitle');
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return i18n.t('incidents.waterHighAirTemperatureTitle');
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return i18n.t('incidents.waterAboveAverageTitle');

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return i18n.t('incidents.motionDetectedTitle');

        // SENSORS
        case AnomalySubTypeSensor.LOW_BATTERY:
            return i18n.t('incidents.lowBatteryTitle');
        case AnomalySubTypeSensor.NO_DATA:
            return i18n.t('incidents.noDataTitle');

        default:
            return i18n.t('sensors.unknown');
    }
};

export const getThreshold = (incident: Incident) => {
    const Celsius = '\u{2103}';
    switch (incident.anomalySubType) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
            return `${incident.value}ppm`;
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return i18n.t('incidents.abnormalHumidityTitle');
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
            return i18n.t('incidents.abnormalTemperatureTitle');
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return `${incident.value}${Celsius}`;
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return `${incident.value}${Celsius}`;

        //ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return `${incident.value.toFixed(2)}%`;

        //WATER
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return `${incident.value.toFixed(2)}%`;
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return `${incident.value.toFixed()} ${i18n.t('infoHeader.occupancy.motions')}`;
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return `${incident.value.toFixed(2)}L`;
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return `${incident.value}${Celsius}`;

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return format(new Date(incident.timestamp), hourFormat);

        default:
            return i18n.t('sensors.unknown');
    }
};

export const getSubtitle = (anomaly: AnomalySubType, value: number, valueBefore?: number) => {
    switch (anomaly) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
            return i18n.t('incidents.detected');
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return i18n.t('incidents.detected');
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
            return i18n.t('incidents.detected');
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return i18n.t('incidents.detected');
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE: {
            const temperatureChangeMessage =
                value > (valueBefore || 0)
                    ? i18n.t('incidents.increase15minutes')
                    : i18n.t('incidents.decrease15minutes');
            return temperatureChangeMessage;
        }
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return i18n.t('incidents.detected');

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return '';

        // WATER
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return i18n.t('incidents.detected');
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return i18n.t('incidents.detected');
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return i18n.t('incidents.detected');
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return i18n.t('incidents.detected');
        default:
            return i18n.t('sensors.unknown');
    }
};

export const getAnomalyImage = (anomaly: AnomalySubType, value: number, valueBefore?: number) => {
    switch (anomaly) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
            return highCO2Image;
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return highHumidityImage;
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
            return lowTemperatureImage;
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return highTemperatureImage;
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return value > (valueBefore || 0) ? rapidIncreaseImage : rapidDecreaseImage;

        // ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return highEnergyImage;

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return movementImage;
        //WATER
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return leakageImage;
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return highTemperatureImage;
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return legionellaImage;
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return legionellaImage;

        default:
            console.error(`Invalid AnomalyType in getAnomalyImage: ${anomaly}`);
    }
};

interface BaseCardProps {
    module: ModuleType;
}

interface IncidentCardProps extends BaseCardProps {
    anomolyType: AnomalySubType;
    value: number;
    valueBefore?: number;
}

export const CardItem = styled.div<IncidentCardProps>`
    cursor: pointer;
    width: 310px;
    padding: 20px;
    height: 140px;
    line-height: 150px;
    color: white;
    font-weight: bold;
    font-size: 3em;
    text-align: center;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 255px auto;
    grid-template-rows: 42px 42px auto;
    grid-gap: 20px;
    background-color: ${({ module }) => getTransparentColor(module)};
    background-image: url(${({ anomolyType, value, valueBefore }) => getAnomalyImage(anomolyType, value, valueBefore)});
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
`;

export const CardMiddle = styled.div`
    display: flex;
    height: 28px;
    text-align: left;
    align-items: center;
`;

export const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 26px;
    height: 26px;
    border: 1px solid ${props => props.theme.colors.grey};
    border-radius: 50%;
    margin-right: 9.5px;
`;

export const CardText = styled.div`
    line-height: normal;
    font-weight: normal;
    font-size: 14px;
`;

export const CardTitle = styled(CardText)<BaseCardProps>`
    display: flex;
    align-items: center;
    text-align: left;
    width: 240px;
    color: ${({ module }) => getLightColor(module)};
`;

export const Now = styled(CardText)`
    text-align: right;
    height: 11px;
    font-size: 12px;
    color: ${props => props.theme.colors.negative};
`;

export const CardIssue = styled(CardText)`
    color: ${props => props.theme.colors.text};
    margin-left: 8px;
`;

export const DurationStart = styled(CardText)`
    height: 16px;
    margin-top: 0px;
    padding: 0px;
    text-align: left;
    color: ${props => props.theme.colors.text};
`;

export const Costs = styled(CardText)`
    height: 16px;
    margin: 0 0 0 0;
    padding: 0px;
    text-align: left;
    width: 65px;
    font-weight: bold;
    color: ${props => props.theme.colors.white};
`;

export const IncidentCardIcon = styled(SVG)<BaseCardProps>`
    width: 12px;
    height: 12px;
    & path {
        fill: ${({ module }) => getColor(module)};
    }
`;

export const CardContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    justify-content: center;
    grid-gap: 2rem;
`;
