import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body, html {
      font-family: ${props => props.theme.fonts.body};
      font-size: 14px;
      background-color: ${props => props.theme.colors.bg};
      color: ${props => props.theme.colors.text};
      margin: 0px;
      padding: 0px;
      height: 100%;
  }

  #root {
      height: 100%;
      display: flex;
      flex-flow: column;
  } 

  @media print {
    body {
      -webkit-print-color-adjust: exact;
    }
    background-color: ${props => props.theme.colors.text} !important;
    @page {
        size: A4 portrait;
    }
  }
`;
