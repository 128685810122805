import { ModuleType, SensorAvgFilter, SensorSubType, SensorType } from 'types';

export function getEnv(name: string, defaultValue: string = ''): string {
    return process.env[`REACT_APP_${name}`] || defaultValue;
}

export interface OAuthConfig {
    basePath: string;
    token: string;
    user: string;
    revocation: string;
    clientSecret: string;
    clientId: string;

    resetPassword: string;
    updatePassword: string;

    apiKey: string;

    persistence: string;
}

export interface ADConfig {
    url: string;
    redirectPath: string;
}

export interface ProfileConfig {
    phone: string;
    mail: string;
}

export interface Config {
    // Auth config
    defaultPath: string;
    basePath: string;
    instrumentationKey: string;
    oauth: OAuthConfig;
    ad: ADConfig;

    //Profile config
    profile: ProfileConfig;

    mapping: (code: string) => string;
    registration: (id: string) => string;
    rooms: string;
    roomsDetail: (id: string) => string;
    kpi: {
        getAllOverviewCounts: (metricType: string, kpiType?: string) => string;
        getAllOverview: (metricType: string, kpiType?: string) => string;
    };
    buildings: string;
    buildingsView: string;
    buildingExpandedRooms: string;
    buildingIncludeRooms: string;
    buildingImage: (id: string) => string;
    buildingsDetailView: (id: string) => string;
    buildingsExpandedData: string;
    buildingExpandedRoomsByBuildingId: (buildingId: string) => string;
    roomsDetailView: (buildingId: string, roomId: string) => string;
    roomsListDetailView: (buildingId: string, roomId: string) => string;
    sensorsGraphData: (id: string) => string;
    sensorsListView: string;
    sensorsListViewByModule: (module: string) => string;
    getSensorDetailView: (buildingId: string, roomId: string, dataPointId: string) => string;
    hdata: {
        get: string;
    };
    defaultLocale: string;
    preferences: {
        preferencesTenantPost: string;
        preferencesTenantFetch: (preferences: string[]) => string;
    };
    export: string;
    entities: (type: 'rooms' | 'buildings' | 'sensors', id: string) => string;
    users: string;
    usersAll: string;
    userDetail: (id: string) => string;
    sensors: (id: string) => string;
    modules: string;
    anomaliesOverview: (from: string, to: string) => string;
    metricsOverview: (from: string, to: string) => string;
    metricsOccupancy: (from: string, to: string) => string;
    metricsAir: (from: string, to: string) => string;
    incidents: (module: ModuleType, from: string) => string;
    incidentsSensorsFrom: (from?: string) => string;
    incidentsSensorsForPeriod: (from: string, to: string) => string;
    sensorData: (id: string, startDate: string, endDate: string) => string;
    sensorDataAverage: (id: string, averageFilter: SensorAvgFilter, startDate?: string, endDate?: string) => string;
    sensorDataAverageTotal: (
        ids: string[],
        averageFilter: SensorAvgFilter,
        startDate?: string,
        endDate?: string
    ) => string;
    sensorDataSumTotal: (ids: string[], averageFilter: SensorAvgFilter, startDate?: string, endDate?: string) => string;
    multiSensorsDataAverage: (
        ids: string[],
        averageFilter: SensorAvgFilter,
        startDate?: string,
        endDate?: string
    ) => string;

    airBuildingScoresHourAvg: (buildingId: string) => string;
    airRoomScoresHourAvg: (buildingId: string, roomId: string) => string;
    airRoomScores: (roomId: string, startDate: string, endDate: string) => string;
    passwordChange: string;
    sendFeedback: string;
    nameChange: (id: string) => string;

    occupancyBuilding: (buildingId: string, startDate: string, endDate: string) => string;
    occupancyBuildingAvg: (buildingId: string, startDate: string, endDate: string) => string;
    occupancyBuildingDailyAvg: (buildingId: string, startDate: string, endDate: string) => string;
    occupancyRoomExpanded: (buildingId: string, roomId: string, startDate: string, endDate: string) => string;
    occupancyRoomExpandedAvg: (buildingId: string, roomId: string, startDate: string, endDate: string) => string;
    occupancyRoomExpandedDailyAvg: (buildingId: string, roomId: string, startDate: string, endDate: string) => string;

    feedback: (startDate?: string) => string;

    temperature: (city: string, startDate: string, endDate: string, averageFilter?: SensorAvgFilter) => string;

    energyLabel: (addressId: string) => string;
    energyUsageExtendedDataView: (
        buildingId: string,
        sensortype: SensorType,
        sensorSubType: SensorSubType,
        startDate: string,
        endDate: string
    ) => string;
    energyUsageBuildingDataView: (
        buildingId: string,
        sensortype: SensorType,
        sensorSubType: SensorSubType,
        startDate: string,
        endDate: string
    ) => string;
    bag: (postCode: string, houseNumber: string) => string;
    bagEnergy: (postCode: string, houseNumber: string) => string;
    meters: (sensorSubType: SensorSubType) => string;
    metaDataEwattch: string;
    calculatedDataEwattch: (equipId: string, startDate: string, endDate: string) => string;
    metaDataBoiler: string;
    boilerPerformance: (buildingId: string, startDate: string, endDate: string) => string;
}

export const config: Config = {
    // Auth config
    defaultPath: getEnv('DEFAULT_PATH', '/'),
    basePath: getEnv('BASE_PATH'),
    instrumentationKey: getEnv('APPINSIGHTS_INSTRUMENTATIONKEY'),

    profile: {
        phone: '033-2478080',
        mail: 'buildinginsight@unica.nl',
    },

    oauth: {
        basePath: getEnv('OAUTH_BASE_PATH', 'https://api.co2.building-insight.com/is/'),
        clientSecret: getEnv('OAUTH_CLIENT_SECRET'),
        clientId: getEnv('OAUTH_CLIENT_ID'),
        apiKey: getEnv('OAUTH_API_KEY'),
        token: 'connect/token',
        user: 'connect/userinfo',
        revocation: 'connect/revocation',
        resetPassword: `api/passwords/create-reset-token?uiVersion=${
            getEnv('ENVIRONMENT') === 'canaries' ? 'BuildingInsightsV2' : 'BuildingInsightsQaV2'
        }`,
        updatePassword: 'api/passwords/update',
        persistence: 'api/persistence',
    },

    ad: {
        url: getEnv('AD_URL'),
        redirectPath: getEnv('AD_REDIRECT_PATH', '/ad/redirect'),
    },

    mapping: (code: string) => `/mapping/co2/${code}`,
    registration: (id: string) => `/api/registration/${id}`,
    rooms: '/api/rooms',
    roomsDetail: (id: string) => `api/rooms/${id}`,
    kpi: {
        getAllOverviewCounts: (metricType: string, kpiType?: string) =>
            kpiType ? `api/kpi/counts?type=${metricType}&kpiType=${kpiType}` : `api/kpi/counts?type=${metricType}`,
        getAllOverview: (metricType: string, kpiType?: string) =>
            kpiType ? `api/kpi/all?type=${metricType}&kpiType=${kpiType}` : `api/kpi/all?type=${metricType}`,
    },
    buildings: 'api/buildings',
    sensors: (id: string) => `/api/sensors/${id}`,
    buildingsExpandedData: `api/views/buildings/expand/rooms`,
    buildingExpandedRooms: `/api/views/buildings/v2/expand/rooms`,
    buildingExpandedRoomsByBuildingId: (buildingId: string) => `/api/views/buildings/v2/${buildingId}/expand/rooms`,
    buildingImage: (id: string) => `api/buildings/${id}/image`,
    buildingsView: 'api/views/buildings/v2',
    buildingIncludeRooms: 'api/views/buildings/include/rooms',
    buildingsDetailView: (id: string) => `api/views/buildings/${id}/include/rooms`,
    sensorsListView: `/api/views/buildings/v2/expand/rooms/list/sensors`,
    sensorsListViewByModule: (module: string) => `/api/views/buildings/v2/expand/rooms/list/sensors?type=${module}`,
    roomsDetailView: (buildingId: string, roomId: string) =>
        `api/views/buildings/v2/${buildingId}/expand/rooms/${roomId}`,
    roomsListDetailView: (buildingId: string, roomId: string) =>
        `api/views/buildings/${buildingId}/expand/rooms/${roomId}/list/sensors`,
    sensorsGraphData: (id: string) => `api/views/data/sensors/${id}`,
    getSensorDetailView: (buildingId: string, roomId: string, dataPointId: string) =>
        `/api/views/buildings/${buildingId}/expand/rooms/${roomId}/list/sensors/${dataPointId}`,
    hdata: {
        get: '/hs/hisRead',
    },
    defaultLocale: 'nl',
    preferences: {
        preferencesTenantPost: 'p/preferences/tenant',
        preferencesTenantFetch: (preferences: string[]) =>
            `p/preferences/tenant/fetch?keys=${preferences.join('&keys=')}`,
    },
    export: 'reports/csv/raw',
    entities: (type: 'rooms' | 'buildings' | 'sensors', id: string) => `api/${type}/${id}`,
    usersAll: 'api/users/all',
    users: 'api/users',
    userDetail: (id: string) => `api/users/${id}`,
    modules: 'api/modules',
    anomaliesOverview: (from: string, to: string) =>
        `/api/views/buildings/v2/overview/anomalies?StartDate=${from}&EndDate=${to}`,
    metricsOverview: (from: string, to: string) =>
        `/api/views/buildings/v2/overview/metrics/sum?StartDate=${from}&EndDate=${to}`,
    metricsOccupancy: (from: string, to: string) =>
        `/api/views/buildings/v2/overview/occupancy?StartDate=${from}&EndDate=${to}`,
    metricsAir: (from: string, to: string) => `/api/views/buildings/v2/overview/ccq?StartDate=${from}&EndDate=${to}`,
    incidents: (module: ModuleType, from: string) =>
        `/api/views/modules/${module.toLowerCase()}/from-date?type=Incidents&from=${from}`,
    incidentsSensorsFrom: (from?: string) =>
        from ? `/api/views/sensor/issues/from-date?from=${from}` : `/api/views/sensor/issues/from-date`,
    incidentsSensorsForPeriod: (from: string, to: string) =>
        `/api/views/sensor/issues/for-period?from=${from}&to=${to}`,
    sensorData: (id: string, startDate: string, endDate: string) =>
        `api/views/data/sensors/${id}?Filter.StartDate=${startDate}&Filter.EndDate=${endDate}`,
    sensorDataAverage: (id: string, averageFilter: SensorAvgFilter, startDate?: string, endDate?: string) => {
        const endDateParam = endDate ? '&EndDate=' + endDate : '';
        return startDate
            ? `api/views/data/sensors/${id}/avg?StartDate=${startDate}${endDateParam}&SensorAvgFilter=${averageFilter}`
            : `api/views/data/sensors/${id}/avg?SensorAvgFilter=${averageFilter}`;
    },
    sensorDataAverageTotal: (ids: string[], averageFilter: SensorAvgFilter, startDate?: string, endDate?: string) => {
        return startDate
            ? `api/views/data/sensors/avg/on-total?ids=${ids.join(
                  '&ids='
              )}&StartDate=${startDate}&EndDate=${endDate}&SensorOnTotalFilter=${averageFilter}`
            : `api/views/data/sensors/avg/on-total?ids=${ids.join('&ids=')}&SensorOnTotalFilter=${averageFilter}`;
    },
    sensorDataSumTotal: (ids: string[], averageFilter: SensorAvgFilter, startDate?: string, endDate?: string) => {
        return startDate
            ? `api/views/data/sensors/sum/on-total?ids=${ids.join(
                  '&ids='
              )}&StartDate=${startDate}&EndDate=${endDate}&SensorOnTotalFilter=${averageFilter}`
            : `api/views/data/sensors/sum/on-total?ids=${ids.join('&ids=')}&SensorOnTotalFilter=${averageFilter}`;
    },
    multiSensorsDataAverage: (ids: string[], averageFilter: SensorAvgFilter, startDate?: string, endDate?: string) => {
        return startDate
            ? `api/views/data/sensors/avg?ids=${ids.join(
                  '&ids='
              )}&StartDate=${startDate}&EndDate=${endDate}&SensorAvgFilter=${averageFilter}`
            : `api/views/data/sensors/avg?ids=${ids.join('&ids=')}&SensorAvgFilter=${averageFilter}`;
    },
    airBuildingScoresHourAvg: (buildingId: string) =>
        `/api/views/data/buildings/${buildingId}/air/score/latest/hourAvg`,
    airRoomScoresHourAvg: (buildingId: string, roomId: string) =>
        `/api/views/data/buildings/${buildingId}/rooms/${roomId}/air/score/latest/hourAvg`,
    airRoomScores: (roomId: string, startDate: string, endDate: string) =>
        `/api/views/data/equips/${roomId}/air/score?Filter.StartDate=${startDate}&Filter.EndDate=${endDate}`,

    occupancyBuilding: (buildingId: string, startDate: string, endDate: string) =>
        `/api/views/stats/occupancy/buildings/${buildingId}?StartDate=${startDate}&EndDate=${endDate}`,
    occupancyBuildingAvg: (buildingId: string, startDate: string, endDate: string) =>
        `/api/views/stats/occupancy/buildings/${buildingId}/avg?StartDate=${startDate}&EndDate=${endDate}`,
    occupancyBuildingDailyAvg: (buildingId: string, startDate: string, endDate: string) =>
        `/api/views/stats/occupancy/buildings/${buildingId}/daily/avg?StartDate=${startDate}&EndDate=${endDate}`,
    occupancyRoomExpanded: (buildingId: string, roomId: string, startDate: string, endDate: string) =>
        `/api/views/stats/occupancy/buildings/${buildingId}/room/${roomId}/expanded?StartDate=${startDate}&EndDate=${endDate}`,
    occupancyRoomExpandedAvg: (buildingId: string, roomId: string, startDate: string, endDate: string) =>
        `/api/views/stats/occupancy/buildings/${buildingId}/room/${roomId}/expanded/avg?StartDate=${startDate}&EndDate=${endDate}`,
    occupancyRoomExpandedDailyAvg: (buildingId: string, roomId: string, startDate: string, endDate: string) =>
        `/api/views/stats/occupancy/buildings/${buildingId}/room/${roomId}/expanded/daily/avg?StartDate=${startDate}&EndDate=${endDate}`,
    feedback: (startDate?: string) => {
        return startDate
            ? `/api/views/modules/feedback/for-period?StartDate=${startDate}`
            : `/api/views/modules/feedback/for-period`;
    },
    temperature: (city: string, startDate: string, endDate: string, averageFilter?: SensorAvgFilter) => {
        return averageFilter
            ? `/api/views/weather/for-period-sm-avg?city=${city}&from=${startDate}&to=${endDate}&type=${averageFilter}`
            : `/api/views/weather/for-period-sm?city=${city}&from=${startDate}&to=${endDate}`;
    },

    energyLabel: (addressId: string) => `/api/ep/energy-label?addressId=${addressId}`,

    energyUsageExtendedDataView: (
        buildingId: string,
        sensortype: SensorType,
        sensorSubType: SensorSubType,
        startDate: string,
        endDate: string
    ) =>
        `/api/views/data/energy/buildings/${buildingId}/extended/sum?EnergyType=${sensortype}&EnergySubType=${sensorSubType}&StartDate=${startDate}&EndDate=${endDate}`,
    energyUsageBuildingDataView: (
        buildingId: string,
        sensortype: SensorType,
        sensorSubType: SensorSubType,
        startDate: string,
        endDate: string
    ) =>
        `/api/views/data/energy/buildings/${buildingId}/sum?EnergyType=${sensortype}&EnergySubType=${sensorSubType}&StartDate=${startDate}&EndDate=${endDate}`,

    bag: (postCode: string, houseNumber: string) => `api/bag?postCode=${postCode}&houseNumber=${houseNumber}`,
    bagEnergy: (postCode: string, houseNumber: string) =>
        `api/bag/energy-label?postCode=${postCode}&houseNumber=${houseNumber}`,
    meters: (sensorSubType: SensorSubType) => `api/views/modules/energy/meters?type=${sensorSubType}`,
    passwordChange: 'is/passwords/change',
    sendFeedback: '/api/views/support/notification/email',
    nameChange: (id: string) => `/api/views/management/rename?entityId=${id}`,
    metaDataEwattch: '/api/views/data/energy/ewattch/metadata',
    calculatedDataEwattch: (equipId: string, startDate: string, endDate: string) =>
        `/api/views/data/energy/ewattch/calculated-data?equipId=${equipId}&StartDate=${startDate}&EndDate=${endDate}`,
    metaDataBoiler: '/api/views/data/gacs/metadata/kpi/thermal',
    boilerPerformance: (buildingId: string, startDate: string, endDate: string) =>
        `/api/views/data/gacs/performance?buildingId=${buildingId}&StartDate=${startDate}&EndDate=${endDate}`,
};
