import React, { useState } from 'react';
import { ColorMode } from 'styled/Theme';

import { StyledInput } from '../../styled/components/inputs';

interface TextInputInterface extends React.InputHTMLAttributes<HTMLInputElement> {
    input?: string | number | readonly string[];
    label: string;
    hasError?: boolean;
    mode?: ColorMode;
}
const TextInput: React.FunctionComponent<TextInputInterface> = ({
    input,
    label,
    mode,
    type = 'text',
    hasError,
    ...rest
}) => {
    const [hasFocus, setFocus] = useState(false);
    const [isAutoFill, setIsAutoFill] = useState(false);

    const handleAnimation = (e: React.AnimationEvent<HTMLInputElement>) => {
        if (e.animationName === 'bZz') {
            setIsAutoFill(true);
        }
    };

    const handleFocus = () => {
        setFocus(true);
        setIsAutoFill(false);
    };

    return (
        <StyledInput
            mode={mode}
            active={Boolean(input) || hasFocus || isAutoFill || type === 'date' || input === 0}
            hasError={hasError}
        >
            <input
                {...rest}
                type={type}
                value={input}
                onAnimationStart={e => handleAnimation(e)}
                onBlur={() => setFocus(false)}
                onFocus={handleFocus}
            />
            <label>{label}</label>
        </StyledInput>
    );
};

export default TextInput;
