import React, { useEffect, useReducer } from 'react';

import SensorSelector from 'components/SensorSelector';

import { useTenant } from 'context/TenantContext';
import { ManagementViewAction, ManagementViewState } from './types';
import { SpaceSuggestion } from 'components/SearchAutoComplete';
import { SensorSelection, Space } from '../types';
import { createSensorSelection } from '../sensors/SensorsOverviewView';
import ManagementSubMenu from 'components/Submenu/ManagementSubMenu';
import ManagementDataPane from 'components/DataPane/sensors/ManagementDataPane';
import { getLevelOfDepth } from '../energy/EnergyStatisticsView';

const ManagementView: React.FunctionComponent = () => {
    const { getBuildingData } = useTenant();
    const [state, dispatch] = useReducer(managementViewReducer, initialState);
    const { equipOverview, selectedSpace, suggestions, roomsDepth } = state;

    useEffect(() => {
        dispatch({
            type: 'INIT_SENSORS',
            buildingData: getBuildingData(),
        });
    }, [getBuildingData]);

    return (
        <>
            <ManagementSubMenu
                suggestions={suggestions}
                onSearch={selectedSpace =>
                    dispatch({
                        type: 'SEARCH_SENSOR',
                        selectedSpace: selectedSpace,
                    })
                }
            />
            {Boolean(equipOverview.length) && (
                <SensorSelector
                    levels={roomsDepth}
                    equips={equipOverview}
                    onEquipSelect={(selectAction, roomId) => dispatch({ type: selectAction, spaceId: roomId })}
                    collapsed={Boolean(selectedSpace)}
                />
            )}
            {Boolean(selectedSpace) && <ManagementDataPane selectedSpace={selectedSpace} />}
        </>
    );
};

export default ManagementView;

export const initialState: ManagementViewState = {
    buildingStructure: [],
    selectedSpaceId: '',
    selectedSpace: null,
    equipOverview: [],
    suggestions: [],
    roomsDepth: 5,
};

const emptyList = { spaces: [] as Space[], selectedSpace: '' };

const managementViewReducer = (state: ManagementViewState, action: ManagementViewAction): ManagementViewState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const sensorSelection = localStorage.getItem('management-view');
            const parsedSelection: SensorSelection | null = sensorSelection ? JSON.parse(sensorSelection) : null;

            const selectedBuilding = action.buildingData.find(i => i.id === parsedSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === parsedSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === parsedSelection?.selectedId5
            );
            const suggestions: SpaceSuggestion[] = [];
            action.buildingData.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);

                recursiveCreateSuggestions(building.rooms, suggestions, ids, location);
            });

            const roomsDepth = getLevelOfDepth(action.buildingData);

            return {
                ...state,
                roomsDepth,
                suggestions,
                buildingStructure: action.buildingData,
                equipOverview: [
                    {
                        spaces: action.buildingData || [],
                        selectedSpace: parsedSelection?.selectedId1 ? parsedSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId2 ? parsedSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId3 ? parsedSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId4 ? parsedSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId5 ? parsedSelection.selectedId5 : '',
                    },
                    {
                        spaces: selectedFifthLevelRoom?.rooms || [],
                        selectedSpace: parsedSelection?.selectedId6 ? parsedSelection.selectedId6 : '',
                    },
                ],
                selectedSpace: selectSpace(action.buildingData, parsedSelection),
            };
        }
        case 'SELECT_SPACE1': {
            localStorage.setItem(
                'management-view',
                JSON.stringify({
                    selectedId1: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[0].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedSpaceId: action.spaceId,
                selectedSpace: state.equipOverview[0].spaces.find(i => i.id === action.spaceId),
            };
        }
        case 'SELECT_SPACE2': {
            localStorage.setItem(
                'management-view',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: action.spaceId,
                })
            );
            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    {
                        spaces: state.equipOverview[1].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[1].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                    emptyList,
                ],
                selectedSpaceId: action.spaceId,
                selectedSpace: state.equipOverview[1].spaces.find(i => i.id === action.spaceId),
            };
        }
        case 'SELECT_SPACE3': {
            localStorage.setItem(
                'management-view',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: action.spaceId,
                })
            );

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    {
                        spaces: state.equipOverview[2].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[2].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                    emptyList,
                ],
                selectedSpaceId: action.spaceId,
                selectedSpace: state.equipOverview[2].spaces.find(i => i.id === action.spaceId),
            };
        }
        case 'SELECT_SPACE4': {
            localStorage.setItem(
                'management-view',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: action.spaceId,
                })
            );

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    {
                        spaces: state.equipOverview[3].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[3].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                    emptyList,
                ],
                selectedSpaceId: action.spaceId,
                selectedSpace: state.equipOverview[3].spaces.find(i => i.id === action.spaceId),
            };
        }
        case 'SELECT_SPACE5': {
            localStorage.setItem(
                'management-view',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: action.spaceId,
                })
            );

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    {
                        spaces: state.equipOverview[4].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: state.equipOverview[4].spaces.find(i => i.id === action.spaceId)?.rooms || [],
                        selectedSpace: '',
                    },
                ],
                selectedSpaceId: action.spaceId,
                selectedSpace: state.equipOverview[4].spaces.find(i => i.id === action.spaceId),
            };
        }
        case 'SELECT_SPACE6': {
            localStorage.setItem(
                'management-view',
                JSON.stringify({
                    selectedId1: state.equipOverview[0].selectedSpace,
                    selectedId2: state.equipOverview[1].selectedSpace,
                    selectedId3: state.equipOverview[2].selectedSpace,
                    selectedId4: state.equipOverview[3].selectedSpace,
                    selectedId5: state.equipOverview[4].selectedSpace,
                    selectedId6: action.spaceId,
                })
            );

            return {
                ...state,
                equipOverview: [
                    state.equipOverview[0],
                    state.equipOverview[1],
                    state.equipOverview[2],
                    state.equipOverview[3],
                    state.equipOverview[4],
                    {
                        spaces: state.equipOverview[5].spaces,
                        selectedSpace: action.spaceId,
                    },
                ],
                selectedSpaceId: action.spaceId,
                selectedSpace: state.equipOverview[4].spaces.find(i => i.id === action.spaceId),
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);
            localStorage.setItem('management-view', JSON.stringify(sensorSelection));

            const selectedBuilding = state.buildingStructure.find(i => i.id === sensorSelection?.selectedId1);
            const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === sensorSelection?.selectedId2);
            const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId3
            );
            const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId4
            );
            const selectedFifthLevelRoom = selectedFourthLevelRoom?.rooms.find(
                i => i.id === sensorSelection?.selectedId5
            );

            const selectedSpaceId = action.selectedSpace.ids[action.selectedSpace.ids.length - 1];

            return {
                ...state,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        spaces: selectedBuilding?.rooms || [],
                        selectedSpace: sensorSelection.selectedId2 ? sensorSelection.selectedId2 : '',
                    },
                    {
                        spaces: selectedSecondLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection.selectedId3 ? sensorSelection.selectedId3 : '',
                    },
                    {
                        spaces: selectedThirdLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection.selectedId4 ? sensorSelection.selectedId4 : '',
                    },
                    {
                        spaces: selectedFourthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection.selectedId5 ? sensorSelection.selectedId5 : '',
                    },
                    {
                        spaces: selectedFifthLevelRoom?.rooms || [],
                        selectedSpace: sensorSelection.selectedId6 ? sensorSelection.selectedId6 : '',
                    },
                ],
                selectedSpaceId,
                selectedSpace: selectSpace(state.buildingStructure, sensorSelection),
            };
        }
    }
};

const recursiveCreateSuggestions = (
    spaces: Space[],
    suggestions: SpaceSuggestion[],
    ids: string[],
    locations: string[]
) => {
    if (spaces.length === 0) {
        return;
    }
    spaces.forEach(r => {
        const i = [...ids];
        i.push(r.id);

        const l = [...locations];
        l.push(r.name);

        const suggestion: SpaceSuggestion = {
            name: r.name,
            ids: i,
            location: l,
        };
        suggestions.push(suggestion);

        recursiveCreateSuggestions(r.rooms, suggestions, i, l);
    });
};
const selectSpace = (buildingStructure: Space[], sensorSelection: SensorSelection): Space => {
    const selectedBuilding = buildingStructure.find(i => i.id === sensorSelection?.selectedId1);
    const selectedSecondLevelRoom = selectedBuilding?.rooms.find(i => i.id === sensorSelection?.selectedId2);
    const selectedThirdLevelRoom = selectedSecondLevelRoom?.rooms.find(i => i.id === sensorSelection?.selectedId3);
    const selectedFourthLevelRoom = selectedThirdLevelRoom?.rooms.find(i => i.id === sensorSelection?.selectedId4);
    if (!sensorSelection || !buildingStructure.length) {
        return null;
    }
    if (sensorSelection.selectedId5) {
        return selectedFourthLevelRoom?.rooms.find(i => i.id === sensorSelection.selectedId5);
    }
    if (sensorSelection.selectedId4) {
        return selectedThirdLevelRoom?.rooms.find(i => i.id === sensorSelection.selectedId4);
    }
    if (sensorSelection.selectedId3) {
        return selectedSecondLevelRoom?.rooms.find(i => i.id === sensorSelection.selectedId3);
    }
    if (sensorSelection.selectedId2) {
        return selectedBuilding?.rooms.find(i => i.id === sensorSelection.selectedId2);
    }
    if (sensorSelection.selectedId1) {
        return buildingStructure.find(b => b.id === sensorSelection.selectedId1);
    }
};
