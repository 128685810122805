import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const ModalOverlay = styled.div`
    z-index: 999;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const ModalContent = styled.div`
    border-radius: 3px;
    width: 500px;
    background-color: ${theme.colors.bg};
`;

export const ModalHeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
`;

export const ModalTitle = styled.div`
    padding-inline: 20px;
`;

export const ModalIcon = styled.div`
    display: flex;
    cursor: pointer;
    height: 100%;
    width: 50px;
    border-left: 1px solid ${theme.colors.grey};
`;

export const ModalBody = styled.div`
    max-height: calc(85vh - 50px);
    overflow: auto;
    padding: 20px;
    border-top: 1px solid ${theme.colors.grey};
    border-bottom: 1px solid ${theme.colors.grey};
`;
