import React, { FunctionComponent, useEffect, useMemo, useReducer } from 'react';
import { ContentWrapper, IncidentsPane, InfoHeader, SensorGraphWrapper } from 'styled/components/dataPane';
import { ModuleType, Sensor, SensorAverage } from 'types';
import DataPaneMenu from '../DataPaneMenu';
import { useSensorSumTotalData } from 'hooks/api/useSensorSumTotalData';
import { DateRange, DateSpan } from 'utils/timePeriod';
import { DataPaneAction, DataPaneState } from './types';
import { useTranslation } from 'react-i18next';
import { createEfficiencyGraphData } from './gacs.helpers';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { H3White } from 'styled/components/text';
import EfficiencyGraph from 'components/Graph/gacs/EfficiencyGraph';
import { getSensorParams } from './GACSReportPane';

interface GACSDataPaneProps {
    sensor: Sensor;
    action: string;
    timespan: DateSpan;
    customDate: DateRange;
    onSelectDateAction: (action: string, timespan: DateSpan, customDate: DateRange) => void;
}

const GACSDataPane: FunctionComponent<GACSDataPaneProps> = ({
    sensor,
    action,
    timespan,
    customDate,
    onSelectDateAction,
}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { graphData, average, isBarChart } = state;
    const { t } = useTranslation();
    const sensorParams = useMemo(
        () => getSensorParams(action, timespan, customDate, undefined, [sensor.id]),
        [action, timespan, customDate, sensor]
    );
    const { data: gacsData, isLoading } = useSensorSumTotalData(sensorParams);

    useEffect(() => {
        if (gacsData) {
            dispatch({
                type: 'SET_GRAPH_DATA',
                data: gacsData,
                action,
                timespan,
                customDate,
            });
        }
    }, [gacsData]);

    return (
        <IncidentsPane>
            <DataPaneMenu
                customDate={customDate}
                title={`${t('energy.gacs.datapane.average')} ${average}%`}
                selectedAction={action}
                onSelectAction={onSelectDateAction}
                module={ModuleType.ENERGY}
                allowCustomPeriod={true}
                canToggleGraph={true}
                isBarChart={isBarChart}
                onToggleGraph={isBarChart => dispatch({ type: 'TOGGLE_CHART', isBarChart })}
            />

            <ContentWrapper>
                {(isLoading || graphData === null) && <LoadingOverlay dark={true} isSmall={true} />}
                {!isLoading && graphData?.data.length === 0 && <H3White>No data for selected period</H3White>}
                {!isLoading && graphData?.data.length && (
                    <>
                        <InfoHeader>
                            <H3White style={{ color: 'white' }}>{t('energy.gacs.datapane.title')}</H3White>
                        </InfoHeader>
                        <SensorGraphWrapper>
                            <EfficiencyGraph graphData={graphData} isBarChart={isBarChart} />
                        </SensorGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </IncidentsPane>
    );
};

export default GACSDataPane;

export const initialState: DataPaneState = {
    graphData: null,
    averageData: [],
    average: null,
    isBarChart: false,
};

const reducer = (state: DataPaneState, action: DataPaneAction): DataPaneState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const graphData = createEfficiencyGraphData(action.data, action.action, action.timespan, action.customDate);
            const average = getAverageScore(action.data);
            return { ...state, graphData, average };
        }
        case 'TOGGLE_CHART': {
            return {
                ...state,
                isBarChart: action.isBarChart,
            };
        }
    }
};

const getAverageScore = (data: SensorAverage[]): number => {
    if (data.length === 0) {
        return 0;
    }
    const total = data.reduce((sum, current) => sum + current.value, 0);
    const average = parseFloat((total / data.length).toFixed(2));
    return average;
};
