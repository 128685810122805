import React, { useState } from 'react';

import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import ButtonGroup, { ButtonGroupItem } from 'components/ButtonGroup';
import DateSelector from 'components/DateSelector';

import {
    FacilitiesBarWrapper,
    IconBarWrapper,
    SubBarWrapper,
    SubMenuText,
    SubNavBarWrapper,
} from 'styled/components/navigation';
import { Button, MenuIcon } from 'styled/components/buttons';
import { BodyLeft } from 'styled/components/text';

import { DateSpan, DateRange } from 'utils/timePeriod';

import calendarIcon from '../../assets/icons/icons-g-cal.svg';
import { AnomalySubTypeSensor, ModuleType } from 'types';

const periodItems: ButtonGroupItem[] = [
    { label: i18n.t('common.time.week'), action: DateSpan.WEEK },
    { label: i18n.t('common.time.month'), action: DateSpan.MONTH },
    { label: i18n.t('common.time.ever'), action: DateSpan.EVER },
];

const issueItems: ButtonGroupItem[] = [
    { label: i18n.t('subMenu.sensors.any'), action: AnomalySubTypeSensor.ANY },
    { label: i18n.t('subMenu.sensors.lowBattery'), action: AnomalySubTypeSensor.LOW_BATTERY },
    { label: i18n.t('subMenu.sensors.noData'), action: AnomalySubTypeSensor.NO_DATA },
];

interface SensorsIssuesSubMenu {
    selectedIncidentAction: string;
    onSelectIncidentAction: (action: string) => void;
    selectedPeriodAction: string;
    onSelectPeriodAction: (action: string, customDate?: DateRange) => void;
    customDate?: DateRange;
}

const SensorsIssuesSubMenu: React.FunctionComponent<SensorsIssuesSubMenu> = ({
    selectedIncidentAction,
    onSelectIncidentAction,
    selectedPeriodAction,
    onSelectPeriodAction,
    customDate,
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const { t } = useTranslation();

    return (
        <SubBarWrapper>
            <SubNavBarWrapper>
                <SubMenuText>{t('subMenu.sensors.issue')}</SubMenuText>
                <ButtonGroup
                    inverted={false}
                    buttonGroupItems={issueItems}
                    selectedItem={selectedIncidentAction}
                    selectedAction={action => onSelectIncidentAction(action)}
                />
            </SubNavBarWrapper>
            {!showCalendar && (
                <>
                    <FacilitiesBarWrapper>
                        <BodyLeft>{t('common.time.period')}</BodyLeft>
                        <ButtonGroup
                            inverted={false}
                            buttonGroupItems={periodItems}
                            selectedItem={selectedPeriodAction}
                            selectedAction={action => onSelectPeriodAction(action)}
                        />
                        <Button
                            inverted={false}
                            active={selectedPeriodAction === DateSpan.CUSTOM}
                            onClick={() => {
                                setShowCalendar(!showCalendar);
                            }}
                        >
                            {customDate?.from
                                ? displayCustomPeriod(customDate.from, customDate.to)
                                : t('dateSelector.placeholder')}
                        </Button>
                    </FacilitiesBarWrapper>
                    <IconBarWrapper active={false} onClick={() => setShowCalendar(true)}>
                        <MenuIcon $disabled={false} $active={false} src={calendarIcon} />
                    </IconBarWrapper>
                </>
            )}
            {showCalendar && (
                <DateSelector
                    startDate={customDate?.from ? new Date(customDate.from) : new Date()}
                    endDate={customDate?.to ? new Date(customDate.to) : new Date()}
                    onClose={() => setShowCalendar(false)}
                    moduleType={ModuleType.SENSORS}
                    onDateChange={(startDate, endDate) => {
                        setShowCalendar(false);
                        onSelectPeriodAction(DateSpan.CUSTOM, { from: startDate, to: endDate });
                    }}
                />
            )}
        </SubBarWrapper>
    );
};

export default SensorsIssuesSubMenu;

const displayCustomPeriod = (from: string, to: string): string => {
    return `${format(new Date(from), 'MMM d')} - ${format(new Date(to), 'MMM d, yyyy')}`;
};
