import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { KPICount, KPIOverview } from 'components/SensorSelectorAir/types';

export const useAirMetricData = (airMetric: string, airMetricQuality: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['airMetric', airMetric, airMetricQuality],
        async ({ signal }): Promise<{ overview: KPIOverview[]; overviewCount: KPICount }> => {
            const promiseArray = [
                config.kpi.getAllOverview(airMetric, airMetricQuality),
                config.kpi.getAllOverviewCounts(airMetric, airMetricQuality),
            ].map(url => axiosClient.get(url, { signal }));

            const result = await Promise.all(promiseArray);

            return { overview: result[0].data, overviewCount: result[1].data };
        }
    );
};
