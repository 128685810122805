import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import LoadingIndicator from 'components/LoadingIndicator';
import Toggle from 'components/Toggle';

import { EBSReportWrapper } from 'styled/components/ebs';
import { BodyLeftEBS, BodyLeftWhite } from 'styled/components/text';

import { BagData, KpiUnit } from 'types';
import { Space } from 'views/authenticated/types';

interface Props {
    bagData: BagData;
    building: Space;
    occupancyRate: number;
    occupancyLoading: boolean;
}
const BuildingInfo: React.FunctionComponent<Props> = ({ bagData, building, occupancyRate, occupancyLoading }) => {
    const { t } = useTranslation();
    const [isRental, setRental] = useState(false);

    return (
        <EBSReportWrapper>
            <BodyLeftWhite>{t('energy.ebs.facility.address')}</BodyLeftWhite>
            <BodyLeftWhite>
                {building.street} {building.buildingNumber}, {building.city}, {building.postCode}
            </BodyLeftWhite>

            <BodyLeftWhite>{t('energy.ebs.facility.sourceHolder')}</BodyLeftWhite>
            <BodyLeftWhite>{bagData.woonplaats}</BodyLeftWhite>

            <BodyLeftWhite>{t('energy.ebs.facility.bagId')}</BodyLeftWhite>
            <BodyLeftWhite>{bagData.adresseerbaarObjectIdentificatie}</BodyLeftWhite>

            <BodyLeftWhite>{t('energy.ebs.facility.status')}</BodyLeftWhite>
            <BodyLeftWhite>{bagData.status}</BodyLeftWhite>

            <BodyLeftWhite>{t('energy.ebs.facility.constructionYear')}</BodyLeftWhite>
            <BodyLeftWhite>{bagData.oorspronkelijkBouwjaar}</BodyLeftWhite>

            <BodyLeftWhite>{t('energy.ebs.facility.grossFloorArea')}</BodyLeftWhite>
            <BodyLeftWhite>{bagData.oppervlakte} m²</BodyLeftWhite>

            <BodyLeftWhite>{t('energy.ebs.facility.energyLabel')}</BodyLeftWhite>
            <BodyLeftEBS>{bagData.energy?.[0]?.labelLetter || t('sensors.unknown')}</BodyLeftEBS>

            <BodyLeftWhite>{t('energy.ebs.facility.funtion')}</BodyLeftWhite>
            <BodyLeftEBS>{bagData.gebruiksdoelen.join()}</BodyLeftEBS>

            <BodyLeftWhite>{t('energy.ebs.facility.rental')}</BodyLeftWhite>
            <Toggle toggled={isRental} onToggle={() => setRental(!isRental)} />

            <BodyLeftWhite>{t('energy.ebs.facility.occupancyRate')}</BodyLeftWhite>
            {occupancyLoading ? (
                <LoadingIndicator />
            ) : (
                <BodyLeftEBS>{`${occupancyRate}${KpiUnit.PERCENTAGE}`}</BodyLeftEBS>
            )}
        </EBSReportWrapper>
    );
};

export default BuildingInfo;
