import { EBSElecTempGasGraphData } from 'components/Graph/types';
import { Hours } from 'components/WorkingHours';
import { BagData, OccupancyScore, SensorAverage, WeatherData } from 'types';

export enum WizardStep {
    INTRODUCTION = 1,
    FACILITY = 2,
    ELECTRICITY = 3,
    GAS = 4,
    INTERACTION = 5,
    PREVIEW = 6,
}

export interface ExplanatoryText {
    introduction: string;
    electricityWinter: string;
    electricitySpring: string;
    electricitySummer: string;
    electricityAutumn: string;
    electricityHolidays: string;
    gasWinter: string;
    gasSpring: string;
    gasSummer: string;
    gasAutumn: string;
    gasHolidays: string;
    interactionUsage: string;
    interactionComparison: string;
}

export interface WizardState {
    step: WizardStep;
    selectedOpeningHours: Hours;
    interactionGraph: EBSElecTempGasGraphData;
    bagData: BagData;
    occupancyRate: number;
    comparisonDataLastYear: ComparisonData;
    comparisonDataTwoYearsBack: ComparisonData;
    electricityData: SensorAverage[];
    gasData: SensorAverage[];
    loading: boolean;
    loadingOccupancy: boolean;
    explanatoryText: ExplanatoryText;
}

export type WizardAction =
    | { type: 'SELECT_STEP'; step: WizardStep }
    | {
          type: 'SET_DATA';
          selectedOpeningHours: Hours;
          bag: BagData;
          weather: WeatherData[];
          electricity: SensorAverage[];
          electricityHistoric: SensorAverage[];
          gas: SensorAverage[];
          gasHistoric: SensorAverage[];
          production: SensorAverage[];
          productionHistoric: SensorAverage[];
          feedin: SensorAverage[];
          feedinHistoric: SensorAverage[];
      }
    | { type: 'SET_DATA_OCCUPANCY'; occupancyData: OccupancyScore[] }
    | { type: 'SET_TEXT'; text: React.ChangeEvent<HTMLTextAreaElement> };

export interface ComparisonData {
    electricityConsumption: number;
    electricityProduction: number;
    electricityFeedin: number;
    gasConsumption: number;
}
