import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const H2 = styled.h2`
    margin: 0 0 0 0;
    line-height: normal;
    font-size: 24px;
    font-weight: bold;
`;

export const H2White = styled(H2)`
    color: ${props => props.theme.colors.white};
`;

export const H3 = styled(H2)`
    font-size: 18px;
`;

export const H3White = styled(H3)`
    color: ${props => props.theme.colors.white};
`;

export const H3Bold = styled(H3)`
    font-weight: bold;
`;

export const BodyLeft = styled.p`
    color: ${props => props.color};
    margin: 0 0 0 0;
    line-height: normal;
    margin-right: 20px;
    font-size: 14px;
`;

export const BodyLeftWhite = styled(BodyLeft)`
    color: ${props => props.theme.colors.white};
`;

export const BodyLeftEBS = styled(BodyLeft)`
    color: ${props => props.theme.colors.mEnergy};
`;

export const BodyLeftGrey = styled(BodyLeft)`
    color: ${props => props.theme.colors.grey};
`;

export const BodyLeftWater = styled(BodyLeft)`
    color: ${props => props.theme.colors.mWaterL};
`;

export const BodyLeftOccupancy = styled(BodyLeft)`
    color: ${props => props.theme.colors.mOccupL};
`;

export const BodyLeftMobility = styled(BodyLeft)`
    color: ${props => props.theme.colors.mMobilityL};
`;

export const BodyLeftEnergy = styled(BodyLeft)`
    color: ${props => props.theme.colors.mEnergyL};
`;

export const BodyLeftAir = styled(BodyLeft)`
    color: ${props => props.theme.colors.airL};
`;

export const BodyLeftNegative = styled(BodyLeft)`
    color: ${props => props.theme.colors.negative};
`;

export const BodyRight = styled(BodyLeft)`
    text-align: right;
`;

export const LeadLeft = styled(BodyLeft)`
    font-weight: bold;
`;

export const LeadLeftWhite = styled(LeadLeft)`
    color: ${props => props.theme.colors.white};
`;

export const LeadLeftNegative = styled(LeadLeft)`
    color: ${props => props.theme.colors.negative};
`;

export const Leadcenter = styled(LeadLeft)`
    text-align: center;
`;

export const LeadCenterWhite = styled(Leadcenter)`
    color: ${props => props.theme.colors.white};
    margin-bottom: 10px;
`;

export const LeadcenterNegative = styled(Leadcenter)`
    color: ${props => props.theme.colors.negative};
`;

export const Leadright = styled(LeadLeft)`
    text-align: right;
`;

export const LeadrightNegative = styled(Leadright)`
    color: ${props => props.theme.colors.negative};
`;

export const LeadLeftSpan = styled.span`
    font-size: 14px;
    font-weight: bold;
`;

export const CaptionLeft = styled.p`
    font-size: 10px;
    margin-inline: 15px;
`;

export const CaptionLeftGrey = styled(CaptionLeft)`
    color: ${props => props.theme.colors.grey};
    margin: 0px;
    margin-bottom: 7px;
`;

export const CaptionLefWhite = styled(CaptionLeft)`
    color: ${props => props.theme.colors.white};
    margin-inline: 0px;
`;

export const CaptionLefPositive = styled(CaptionLeft)`
    color: ${props => props.theme.colors.positive};
`;

export const CaptionLeftNegative = styled(CaptionLeft)`
    color: ${props => props.theme.colors.negative};
    margin-block: 9px 0px;
    margin-inline: 15px;
`;

export const Captionright = styled(CaptionLeft)`
    text-align: right;
`;

export const CaptionrightGrey = styled(Captionright)`
    color: ${props => props.theme.colors.grey};
`;

export const CaptionCenterGrey = styled(Captionright)`
    text-align: center;
    color: ${props => props.theme.colors.grey};
`;

export const CondensedLight = styled.p`
    margin: 0px;
    font-size: 12px;
    font-weight: 300;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${theme.colors.ebsColor6};
`;
