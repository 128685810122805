import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { addDays, isSameDay } from 'date-fns';

import EBSTitleMenu from './EBSTitleMenu';
import WeekGraph from 'components/Graph/ebs/WeekGraph';
import WeekHeader from './WeekHeader';

import { EBSContentWrapper, EBSGraphWrapper } from 'styled/components/dataPane';

import { createEBSWeekGraphData, getDateOfISOWeek, lastYear } from '../../EBSWizard/ebs.helpers';
import { EBSWeekGraphData } from 'components/Graph/types';
import { SensorAverage, SensorType } from 'types';
import { BodyLeftWhite } from 'styled/components/text';

interface Props {
    title: string;
    data: SensorAverage[];
    week: number;
    sensorType: SensorType;
    children?: React.ReactNode;
}
const SeasonDataPane: React.FunctionComponent<Props> = ({ title, data, week, sensorType, children }) => {
    const { t } = useTranslation();
    const [weekGraph, setWeekGraph] = useState<EBSWeekGraphData | null>(null);

    useEffect(() => {
        if (data) {
            const weekDate = getDateOfISOWeek(week, lastYear);
            const endOfWeek = addDays(weekDate, 7);
            const indexStartDate = data.findIndex(d => isSameDay(new Date(d.field), weekDate));
            const indexEndDate = data.findIndex(d => isSameDay(new Date(d.field), endOfWeek));
            const dataByWeek = data.slice(indexStartDate, indexEndDate);
            const graph = createEBSWeekGraphData(dataByWeek, sensorType);

            setWeekGraph(graph);
        }
    }, [data]);

    return (
        <>
            <EBSTitleMenu title={title} />
            <EBSContentWrapper>
                <EBSGraphWrapper>
                    <br />
                    <WeekHeader week={week} />
                    {weekGraph && hasData(weekGraph) ? (
                        <>
                            <WeekGraph graphData={weekGraph} />
                            {children}
                        </>
                    ) : (
                        <>
                            <br />
                            <BodyLeftWhite>{t('energy.ebs.graph.noWeekData')}</BodyLeftWhite>
                        </>
                    )}
                </EBSGraphWrapper>
            </EBSContentWrapper>
        </>
    );
};

export default React.memo(SeasonDataPane);

const hasData = (graphData: EBSWeekGraphData) => {
    return (
        graphData.monday.length &&
        graphData.tuesday.length &&
        graphData.wednesday.length &&
        graphData.thursday.length &&
        graphData.friday.length &&
        graphData.saturday.length &&
        graphData.sunday.length
    );
};
