import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryLine,
    VictoryChart,
    VictoryAxis,
    VictoryArea,
    VictoryTooltip,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryBar,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipIncident from '../Tooltip/TooltipIncident';
import TooltipBar from '../Tooltip/TooltipBar';

import { getAnomalyUnit } from '../../../styled/components/dataPane';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import { ModuleType } from 'types';
import { closingAxis, closingXYAxis, XYAxis, dateAxis, lineChartPadding } from '../graph.styles';
import { setBarConfig, getMin, getMax, formatXAxisIncidentTickValue, sumValues } from '../graph.helpers';
import { IncidentGraphProps, SensorValue } from '../types';

const EnergyIncidentsGraph: React.FunctionComponent<IncidentGraphProps> = ({ graphData, isBarChart }) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);

    const summedData = useMemo(() => sumValues(graphData.data, graphData.timespan), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <svg style={{ height: 0, display: 'block' }}>
                <defs>
                    <linearGradient id="airGradient" x2="0%" y2="100%">
                        <stop offset="6%" stopColor={theme.colors.negative} />
                        <stop offset="40%" stopColor={theme.colors.mEnergyL} />
                        <stop offset="77%" stopColor={theme.colors.air} />
                        <stop offset="100%" stopColor={theme.colors.air} />
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                padding={lineChartPadding}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            voronoiBlacklist={['average']}
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipIncident
                                            module={ModuleType.ENERGY}
                                            unit={getAnomalyUnit(graphData.anomalySubtype)}
                                            height={dimension.height}
                                            averageData={graphData.averageData}
                                        />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number) =>
                        formatXAxisIncidentTickValue(t, index, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Y-axis */}
                <VictoryAxis
                    crossAxis={false}
                    dependentAxis={true}
                    tickFormat={(t: number, index: number, arr: number[]) =>
                        arr.length - 1 === index ? getAnomalyUnit(graphData.anomalySubtype) : t
                    }
                    style={XYAxis}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {!isBarChart && (
                    <VictoryLine
                        name="average"
                        animate={true}
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: { stroke: theme.colors.mEnergyL },
                        }}
                        data={graphData.averageData}
                    />
                )}
                {!isBarChart && (
                    <VictoryLine
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: { stroke: theme.colors.mEnergy },
                        }}
                        data={graphData.data}
                    />
                )}

                <VictoryArea
                    data={[
                        {
                            x: graphData.brushArea[0],
                            y: isBarChart ? 0 : getMin([...graphData.data, ...graphData.averageData]) * 0.7,
                            y0: getMax([...graphData.data, ...graphData.averageData]) * 1.1,
                        },
                        {
                            x: graphData.brushArea[1],
                            y: isBarChart ? 0 : getMin([...graphData.data, ...graphData.averageData]) * 0.7,
                            y0: getMax([...graphData.data, ...graphData.averageData]) * 1.1,
                        },
                    ]}
                    style={{
                        data: {
                            fill: theme.colors.mEnergyLTransparent,
                        },
                    }}
                />

                {isBarChart && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={<TooltipBar module={ModuleType.ENERGY} title={t('tooltip.current')} />}
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.mEnergy },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y="value"
                        data={summedData}
                    />
                )}
                {isBarChart && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={<TooltipBar module={ModuleType.ENERGY} title={t('tooltip.average')} />}
                            />
                        }
                        animate={{ duration: 500, easing: 'cubicOut' }}
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.mEnergyL },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y="value"
                        data={graphData.averageData}
                    />
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(EnergyIncidentsGraph);
