import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { OccupancyScoreAvg } from 'types';
import { OccupancyDataParam } from 'components/DataPane/statistics/occupancy/types';

export const useOccupancyWeekData = (occupancyParams: OccupancyDataParam) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['occupancyWeek', occupancyParams],
        async ({ signal }) => {
            const { data } = await axiosClient.get<OccupancyScoreAvg[]>(getWeekOverviewAvgEndpoint(occupancyParams), {
                signal,
            });

            return data;
        },
        {
            enabled: Boolean(occupancyParams.selectedOccupancyBuilding),
        }
    );
};

const getWeekOverviewAvgEndpoint = (occupancyParams: OccupancyDataParam) => {
    return occupancyParams.selectedOccupancyBuilding && occupancyParams.selectedOccupancyRoom
        ? config.occupancyRoomExpandedAvg(
              occupancyParams.selectedOccupancyBuilding,
              occupancyParams.selectedOccupancyRoom,
              occupancyParams.from,
              occupancyParams.to
          )
        : config.occupancyBuildingAvg(
              occupancyParams.selectedOccupancyBuilding,
              occupancyParams.from,
              occupancyParams.to
          );
};
