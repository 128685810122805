import React from 'react';

import { useTranslation } from 'react-i18next';
import { SubMenuButton } from 'styled/components/buttons';

import { StyledErrorMessage } from 'styled/components/errorBoundary';
import { ErrorCode } from './types';

interface Props {
    onRefresh: () => void;
}
const ServerError: React.FunctionComponent<Props> = ({ onRefresh }) => {
    const { t } = useTranslation();

    return (
        <StyledErrorMessage>
            <h1>{ErrorCode.SERVER_ERROR}</h1>
            <h2>{t('errorBoundary.serverError.title')}</h2>
            <p>{t('errorBoundary.serverError.text')}</p>
            <p>{t('errorBoundary.serverError.supportText')}</p>
            <SubMenuButton active={true} onClick={onRefresh}>
                {t('errorBoundary.reloadButton')}
            </SubMenuButton>
        </StyledErrorMessage>
    );
};

export default ServerError;
