import styled from 'styled-components';

export const FilterWrapper = styled.div`
    box-sizing: border-box;
    align-items: center;
    width: 100%;
    display: flex;
    padding: 0px 1rem;
`;

export const FilterContent = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
`;
