import styled, { keyframes, css } from 'styled-components';
import { theme } from 'styled/Theme';

import i18n from '../../i18n';
import SVG from 'react-inlinesvg';

import {
    SensorType,
    AnomalySubType,
    AnomalySubTypeAir,
    AnomalySubTypeOccupancy,
    AnomalySubTypeEnergy,
    AnomalySubTypeWater,
    AnomalySubTypeSensor,
    KpiUnit,
    SensorSubType,
} from 'types';

import temperatureIcon from '../../assets/icons/i-s-temperature.svg';
import co2Icon from '../../assets/icons/i-s-co-2.svg';
import movementIcon from '../../assets/icons/i-s-movement.svg';
import energyIcon from '../../assets/icons/i-m-energy.svg';
import waterIcon from '../../assets/icons/i-m-water.svg';
import { DefaultButton } from './buttons';

const pulse = keyframes`
    0% {
        transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
        transform: scale(1);
		box-shadow: 0 0 0 7px rgba(255, 255, 255, 0);
	}

	100% {
        transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
`;

const slideUp = keyframes`
    from { top: calc(100vh - (31vh + 134px)) }
    to {
      top: 121px;
    }
  }
`;

const slideUpEnergy = keyframes`
    from { top: 340px }
    to {
      top: 121px;
    }
  }
`;

export const Pane = styled.div`
    box-shadow: 0 -3px 6px 0 rgba(42, 46, 51, 0.5);
    display: flex;
    flex-direction: column;
    margin-top: auto;
    width: 100%;
    background-color: ${theme.colors.text};
    z-index: 100;
`;

export const IncidentsPane = styled(Pane)`
    min-height: calc(31vh + 134px);
`;

export const SensorsPane = styled(Pane)`
    min-height: calc(34vh + 170px);
`;

export const ManagementPane = styled(Pane)`
    min-height: calc(31vh);
`;

interface ExpandedPane {
    isExpanded?: boolean;
}
export const StatisticsPane = styled(Pane)<ExpandedPane>`
    min-height: calc(31vh + 134px);
    ${({ isExpanded }) =>
        isExpanded
            ? css`
                  position: absolute;
                  top: 121px;
                  box-shadow: none;
                  min-height: calc(100% - 120px);
                  animation: 0.4s linear 0s ${slideUp};
              `
            : css``};
`;

export const OccupancyStatisticsPane = styled(Pane)<ExpandedPane>`
    min-height: calc(18vh + 137px);
    ${({ isExpanded }) =>
        isExpanded
            ? css`
                  position: absolute;
                  top: 121px;
                  box-shadow: none;
                  min-height: calc(100% - 120px);
                  animation: 0.4s linear 0s ${slideUp};
              `
            : css``};
`;

export const FeedbackPane = styled(Pane)<ExpandedPane>`
    min-height: calc(21vh + 135px);
    ${({ isExpanded }) =>
        isExpanded
            ? css`
                  position: absolute;
                  top: 121px;
                  box-shadow: none;
                  min-height: calc(100% - 120px);
                  animation: 0.4s linear 0s ${slideUp};
              `
            : css``};
`;

export const EnergyStatisticsPane = styled(Pane)<ExpandedPane>`
    min-height: 340px;
    ${({ isExpanded }) =>
        isExpanded
            ? css`
                  position: absolute;
                  top: 121px;
                  box-shadow: none;
                  min-height: calc(100% - 120px);
                  animation: 0.4s linear 0s ${slideUpEnergy};
              `
            : css``};
`;

export const SpacesWrapper = styled.div`
    display: flex;
    gap: 30px;
    justify-content: space-between;
`;

export const InfoHeader = styled.div`
    display: flex;
    div {
        display: flex;
        align-items: center;
        padding-right: 40px;
        h2 {
            padding-right: 6px;
        }
    }
    div:last-child {
        align-items: flex-end;
        padding-right: 0px;
        margin-left: auto;
    }
    img {
        margin: 0px 6px 10px 0px;
        height: 10px;
        width: 10px;
    }
`;

export const TitleWrapper = styled.div`
    margin-left: 30px;
    padding-right: 30px;
    display: flex;
    height: 100%;
    align-items: center;
`;

export const EBSTitleWrapper = styled(TitleWrapper)`
    margin-left: 60px;
`;

export const IconWrapper = styled.div`
    display: flex;
    height: 60px;
    padding-right: 10px;
`;

export const ContentWrapper = styled.div`
    background-color: ${theme.colors.text};
    padding: 15px 30px;
    display: flex;
    flex: 1;
    flex-direction: column;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const EBSContentWrapper = styled(ContentWrapper)`
    padding: 15px 60px;
`;

export const GACSWrapper = styled(ContentWrapper)`
    border-bottom: none;
`;

export const IncidentGraphWrapper = styled.div`
    height: calc(31vh);
`;

export const SensorGraphWrapper = styled.div`
    height: calc(30vh);
`;
export const FeedbackGraphWrapper = styled.div`
    min-height: calc(20vh);
`;

export const EBSGraphWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(28vh);
    min-width: 900px;
    max-width: 80vw;
`;

export const PulseLegendIcon = styled(SVG)`
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    border-radius: 50%;
    width: 9px;
    height: 9px;
    margin: 0px 6px 11px;
    animation: ${pulse} 1.5s infinite;
    transform: scale(1);
    ${({ color }) => {
        if (color) {
            return css`
                & circle {
                    fill: ${color};
                }
            `;
        }
    }}
`;

interface LegendIcon {
    color?: string;
}
export const PulseLegendButtonIcon = styled(PulseLegendIcon)<LegendIcon>`
    margin: 0 6px;
    & circle {
        fill: ${({ color }) => {
            return color ? color : 'white';
        }};
    }
`;

export const LegendIcon = styled(SVG)`
    width: 9px;
    height: 9px;
    margin: 0px 6px 11px;
    ${({ color }) => {
        if (color) {
            return css`
                & circle {
                    fill: ${color};
                }
            `;
        }
    }}
`;

export const LegendButtonIcon = styled(LegendIcon)<LegendIcon>`
    margin: 0 6px;
    & circle {
        fill: ${({ color }) => {
            return color ? color : 'white';
        }};
    }
`;

export const ToggleLegend = styled(DefaultButton)`
    display: flex;
    height: 30px;
    max-width: 120px;
    border-radius: 15px;
    border: 1px solid ${props => props.theme.colors.greyD};
    background: none;
    align-items: center;
    justify-content: left;
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    padding: 0 10px 0 0;
    background-color: ${({ active }) => (active ? theme.colors.greyD : theme.colors.text)};
    p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
`;

export const LoadingWrapper = styled.div`
    display: flex;
    min-height: calc(30vh + 50px);
`;

export const SpaceLoadingWrapper = styled.div`
    display: flex;
    min-height: 215px;
`;

export const WeekLoadingWrapper = styled.div`
    display: flex;
    min-height: 265px;
`;

export const getAnomalyIcon = (anomaly: AnomalySubType) => {
    switch (anomaly) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return co2Icon;
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return temperatureIcon;

        // ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return energyIcon;

        //WATER
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return waterIcon;

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return movementIcon;

        default:
            return 'Unknown';
    }
};

export const getAnomalyUnit = (anomaly: AnomalySubType) => {
    switch (anomaly) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return KpiUnit.PPM;
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return KpiUnit.CELSIUS;

        // ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return KpiUnit.KWH;

        //WATER
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return i18n.t('infoHeader.occupancy.motion');
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return KpiUnit.LITER;
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return KpiUnit.CELSIUS;

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return i18n.t('infoHeader.occupancy.motion');
        case AnomalySubTypeOccupancy.OCCUPANCY_PERCENTAGE:
            return KpiUnit.PERCENTAGE;

        // SENSORS
        case AnomalySubTypeSensor.LOW_BATTERY:
            return KpiUnit.V;
        default:
            return 'Unknown';
    }
};

export const getIncidentTitle = (anomaly: AnomalySubType) => {
    switch (anomaly) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
            return i18n.t('incidents.airQualityTitle');
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return i18n.t('incidents.abnormalHumidityTitle');
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
            return i18n.t('incidents.abnormalTemperatureTitle');
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return i18n.t('incidents.highTemperatureTitle');
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return i18n.t('incidents.rapidTemperatureChangeTitle');

        //ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return i18n.t('incidents.energyAboveAverageTitle');

        //WATER
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return i18n.t('incidents.waterHighAirTemperatureTitle');
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return i18n.t('incidents.waterAboveAverageTitle');
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
            return i18n.t('incidents.waterLowOccupancyTitle');
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return i18n.t('incidents.waterZeroConsumptionTitle');

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return i18n.t('incidents.motionDetectedTitle');

        // Sensors
        case AnomalySubTypeSensor.LOW_BATTERY:
            return i18n.t('incidents.lowBatteryTitle');
        case AnomalySubTypeSensor.NO_DATA:
            return i18n.t('incidents.noDataTitle');

        default:
            return 'Unknown';
    }
};

export const getSensorTitle = (type: SensorType, subtype?: SensorSubType) => {
    switch (type) {
        case SensorType.CCQ:
            return i18n.t('sensors.title.ccq');
        case SensorType.CO2:
            return i18n.t('sensors.title.co2');
        case SensorType.HUMIDITY:
            return i18n.t('sensors.title.humidity');
        case SensorType.TEMP:
        case SensorType.TEMPERATURE:
            return i18n.t('sensors.title.temperature');
        case SensorType.ELEC_KWH:
        case SensorType.ELECTRICITY:
        case SensorType.CURRENT:
        case SensorType.CURRENT_INDEX:
            return i18n.t('sensors.title.energy');
        case SensorType.GAS:
            return i18n.t('sensors.title.gas');
        case SensorType.LIGHT_INTENSITY:
        case SensorType.ILLUMINANCE:
            return i18n.t('sensors.title.illuminance');
        case SensorType.MOTION:
        case SensorType.MOVEMENT:
        case SensorType.NOISE_LEVEL:
            switch (subtype) {
                case SensorSubType.EMPTY || null:
                    return i18n.t('sensors.title.occupancy');
                case SensorSubType.COUNT:
                    return i18n.t('sensors.title.occupancyCount');
                    return 'Titel Iets anders';
                default:
                    return i18n.t('sensors.title.unknown');
            }
        case SensorType.WATER:
            return i18n.t('sensors.title.water');
        case SensorType.BATTERY_VOLTAGE:
            return i18n.t('sensors.title.voltage');
        case SensorType.HEATING:
            return i18n.t('sensors.title.heating');
        case SensorType.COOLING:
            return i18n.t('sensors.title.cooling');
        default:
            return i18n.t('sensors.title.unknown');
    }
};
