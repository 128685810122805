import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import occupancyCircle from '../../../../assets/icons/i-circle-o.svg';
import averageCircle from '../../../../assets/icons/i-circle-o-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';

interface InfoHeaderProp {
    sensorValue: string;
    isLoading?: boolean;
}
const MovementInfo: React.FunctionComponent<InfoHeaderProp> = ({ sensorValue, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.current')}
                    <br />
                    {t('infoHeader.occupancy.motions')}
                </CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={occupancyCircle} />
                <CaptionLefWhite>{t('infoHeader.occupancy.currentMotions')}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.occupancy.loadingAverageMotions')}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.occupancy.avgMotions')}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default MovementInfo;
