import React from 'react';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { BodyLeftNegative, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';
import { theme } from 'styled/Theme';

import circle from '../../../../assets/icons/i-circle-a-purple-to-red.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { Incident, SensorType } from 'types';
import { getSensorUnit } from '../incidents.helpers';

interface InfoHeaderProp {
    incident: Incident;
    isLoading?: boolean;
}
const NoData: React.FunctionComponent<InfoHeaderProp> = ({ incident, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <BodyLeftNegative>
                    {t('infoHeader.common.noData', {
                        timestamp: format(new Date(incident.timestampStart), 'd MMM yyyy HH:mm'),
                    })}
                    <br />
                    {t('infoHeader.common.replace')}
                </BodyLeftNegative>
            </div>
            <div>
                <LegendIcon color={getCircleColor(incident.type)} src={circle} />
                <CaptionLefWhite>
                    {t('infoHeader.common.overTime', { unit: getSensorUnit(incident.type) })}
                </CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon color={theme.colors.airL} src={circle} />
                        <CaptionLefWhite>
                            {t('infoHeader.common.loadingAverage', { unit: getSensorUnit(incident.type) })}
                        </CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon color={theme.colors.airL} src={circle} />
                        <CaptionLefWhite>
                            {t('infoHeader.common.averageOverTime', { unit: getSensorUnit(incident.type) })}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default NoData;

const getCircleColor = (type: SensorType) => {
    switch (type) {
        case SensorType.CO2:
        case SensorType.HUMIDITY:
        case SensorType.TEMP:
        case SensorType.TEMPERATURE:
            return undefined;
        case SensorType.ELEC_KWH:
        case SensorType.ELECTRICITY:
            return theme.colors.mEnergy;
        case SensorType.LIGHT_INTENSITY:
        case SensorType.ILLUMINANCE:
            return theme.colors.mEnergyL;
        case SensorType.MOTION:
        case SensorType.MOVEMENT:
            return theme.colors.mOccup;
        case SensorType.WATER:
            return theme.colors.mWater;
        case SensorType.BATTERY_VOLTAGE:
            return theme.colors.mCleanL;
        default:
            return theme.colors.grey;
    }
};
