import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryBar,
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    VictoryLabel,
    VictoryLine,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipBar from '../../Tooltip/TooltipBar';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import {
    setBarConfig,
    sumValues,
    normalizedTickValues,
    normalizeYValue,
    formatTick,
    formatWaterTick,
    formatXAxisTickValue,
    getMaxSensorValue,
} from '../../graph.helpers';
import {
    barChartDomainPadding,
    barChart2AxisPadding,
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
} from '../../graph.styles';
import { GraphData, SensorValue } from '../../types';
import { KpiUnit, ModuleType } from 'types';
import TooltipIncident from 'components/Graph/Tooltip/TooltipIncident';

export interface BarChartProps {
    graphData: GraphData;
    isBarChart: boolean;
}
const ConsumptionChart: React.FunctionComponent<BarChartProps> = ({ graphData, isBarChart }) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);

    const summedWaterData = useMemo(() => sumValues(graphData.data, graphData.timespan), [graphData]);
    const summedAverageWaterData = useMemo(() => sumValues(graphData.averageData, graphData.timespan), [graphData]);
    const maxWaterDataBar = useMemo(
        () => getMaxSensorValue([...summedWaterData, ...graphData.averageData]),
        [summedWaterData]
    );
    const maxWaterDataLine = useMemo(
        () => getMaxSensorValue([...graphData.data, ...graphData.averageData]),
        [summedWaterData]
    );

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={barChartDomainPadding}
                padding={barChart2AxisPadding}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipIncident
                                            module={ModuleType.WATER}
                                            unit={KpiUnit.LITER}
                                            height={dimension.height}
                                            averageData={graphData.averageData}
                                        />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Cost Y-axis */}
                <VictoryAxis
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) =>
                        isBarChart
                            ? formatTick(t, i, [...summedWaterData, ...graphData.averageData], '€')
                            : formatTick(t, i, [...graphData.data, ...graphData.averageData], '€')
                    }
                    dependentAxis={true}
                    style={{ ...XYAxis, axis: { stroke: theme.colors.greyD, strokeWidth: 1 } }}
                    tickLabelComponent={<VictoryLabel verticalAnchor="middle" textAnchor="start" x={50} />}
                />
                {/* Usage Axis */}
                <VictoryAxis
                    offsetX={40}
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) =>
                        isBarChart
                            ? formatWaterTick(t, i, [...summedWaterData, ...graphData.averageData])
                            : formatWaterTick(t, i, [...graphData.data, ...graphData.averageData])
                    }
                    dependentAxis={true}
                    style={XYAxis}
                />

                {/* Closing axis, also required on bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {isBarChart && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.WATER}
                                        title={t('tooltip.average')}
                                        unit={KpiUnit.LITER}
                                    />
                                }
                            />
                        }
                        animate={{ duration: 500, easing: 'cubicOut' }}
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.mWaterL },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxWaterDataBar)}
                        data={summedAverageWaterData}
                    />
                )}
                {isBarChart && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.WATER}
                                        title={t('tooltip.current')}
                                        unit={KpiUnit.LITER}
                                    />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: { fill: theme.colors.mWater },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxWaterDataBar)}
                        data={summedWaterData}
                    />
                )}
                {!isBarChart && (
                    <VictoryLine
                        interpolation="monotoneX"
                        x="timestamp"
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxWaterDataLine)}
                        style={{
                            data: { stroke: theme.colors.mWater },
                        }}
                        data={graphData.data}
                    />
                )}
                {!isBarChart && (
                    <VictoryLine
                        name="average"
                        animate={true}
                        interpolation="monotoneX"
                        x="timestamp"
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxWaterDataLine)}
                        style={{
                            data: { stroke: theme.colors.mWaterL },
                        }}
                        data={graphData.averageData}
                    />
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(ConsumptionChart);
