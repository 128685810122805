import React from 'react';

import { useTranslation } from 'react-i18next';

import ProgressBar from './ProgressBar';

import { PaneIconBarWrapper, ProgressWrapper } from 'styled/components/navigation';
import { MenuIcon } from 'styled/components/buttons';
import { H3White } from 'styled/components/text';
import { EBSTitleWrapper } from 'styled/components/dataPane';

import closeIcon from '../../../assets/icons/i-close.svg';
import AssistanceHelper from './AssistanceHelper';

interface Props {
    step: number;
    year: number;
    onCloseWizard: () => void;
}
const ProgressHeader: React.FunctionComponent<Props> = ({ step, year, onCloseWizard }) => {
    const { t } = useTranslation();

    return (
        <>
            <ProgressWrapper>
                <EBSTitleWrapper>
                    <H3White>{t('energy.ebs.title', { year: year })}</H3White>
                </EBSTitleWrapper>
                <ProgressBar step={step} />
                <AssistanceHelper />
                <PaneIconBarWrapper onClick={onCloseWizard}>
                    <MenuIcon $disabled={false} $active={true} src={closeIcon} />
                </PaneIconBarWrapper>
            </ProgressWrapper>
        </>
    );
};

export default ProgressHeader;
