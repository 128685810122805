import React, { useMemo, useRef } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Column, useRowSelect, useSortBy, useTable } from 'react-table';

import { ContractStyledTable, TableIcon, TableIconWrapper } from 'styled/components/table';

import { dateFormat } from 'utils/timePeriod';
import { ContractType, ElectricityContract, GasContract } from 'views/authenticated/profile/types';

import viewIcon from '../../assets/icons/i-search.svg';
import deleteIcon from '../../assets/icons/i-close.svg';

type ContractTableData = {
    id: string;
    type: ContractType;
    startDate: string;
    endDate: string;
};

interface ContractTableProps {
    contracts: (ElectricityContract | GasContract)[];
    onDelete: (id: string) => void;
    onView: (id: string) => void;
}

const ContractTable: React.FunctionComponent<ContractTableProps> = ({ contracts, onView, onDelete }) => {
    const tableWrapperRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation();

    const data: ContractTableData[] = useMemo(
        () =>
            contracts
                .map(({ id, contractType, startDate, endDate }) => ({
                    id: id,
                    type: contractType,
                    startDate: format(new Date(startDate), dateFormat),
                    endDate: format(new Date(endDate), dateFormat),
                }))
                .sort((a, b) => new Date(b.endDate).valueOf() - new Date(a.startDate).valueOf()),
        [contracts]
    );

    const columns: Column<ContractTableData>[] = useMemo(
        () => [
            {
                Header: 'Hidden Id',
                accessor: 'id',
            },
            {
                Header: () => t('company.energyContract.table.type'),
                accessor: 'type',
                disableSortBy: true,
            },
            {
                Header: () => t('company.energyContract.table.startDate'),
                accessor: 'startDate',
                disableSortBy: true,
            },
            {
                Header: () => t('company.energyContract.table.endDate'),
                accessor: 'endDate',
                disableSortBy: true,
            },
        ],
        [t]
    );

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable<ContractTableData>(
        {
            columns,
            data,
            initialState: {
                hiddenColumns: ['id'],
            },
            autoResetSortBy: false,
            autoResetExpanded: false,
            autoResetPage: false,
            autoResetSelectedRows: false,
        },
        useSortBy,
        useRowSelect
    );

    return (
        <ContractStyledTable ref={tableWrapperRef}>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                            {headerGroup.headers.map((column, index) => (
                                <th {...column.getHeaderProps(column.getSortByToggleProps())} key={index}>
                                    {column.render('Header')}
                                </th>
                            ))}
                            <th style={{ flex: 0.2 }} />
                            <th style={{ flex: 0.2 }} />
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, index) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps({
                                    style: {
                                        height: '50px',
                                        backgroundColor: 'transparent',
                                    },
                                })}
                                key={index}
                            >
                                {row.cells.map((cell, index) => {
                                    return (
                                        <td {...cell.getCellProps()} key={index}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                                <td style={{ flex: 0.2 }}>
                                    <TableIconWrapper onClick={() => onView(row.values.id)}>
                                        <TableIcon src={viewIcon} />
                                    </TableIconWrapper>
                                </td>
                                <td style={{ flex: 0.2 }}>
                                    <TableIconWrapper onClick={() => onDelete(row.values.id)}>
                                        <TableIcon src={deleteIcon} />
                                    </TableIconWrapper>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </ContractStyledTable>
    );
};

export default ContractTable;
