import React, { useCallback, useState } from 'react';

import { format, isAfter } from 'date-fns';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import TextInput from 'components/TextInput';

import { SubmitButton } from 'styled/components/buttons';
import { StyledSelect } from 'styled/components/inputs';
import { SupportCaptionLeftNegative } from 'styled/components/support';
import { LeadLeft } from 'styled/components/text';

import {
    ContractForm,
    ContractType,
    ElectricityContract,
    EnergyType,
    GasContract,
} from 'views/authenticated/profile/types';
import { KpiUnit } from 'types';

import { dateFormat } from 'utils/timePeriod';
import { Column } from 'styled/components/energieContract';

const hourPattern = '^([0-9]|0[0-9]|1[0-9]|2[0-3])$';
const floatPattern = '^d*(.d+)?$';
const floatRegex = /^\d*(\.\d+)?$/;
const hourRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3])$/;

interface Props {
    building: string;
    currentContract?: GasContract | ElectricityContract;
    onContractSubmit: (contract: GasContract | ElectricityContract) => void;
}
const CreateContract: React.FunctionComponent<Props> = ({ building, onContractSubmit }) => {
    const { t } = useTranslation();
    const [initialSubmit, setInitialSubmit] = useState(false);

    const [contract, setContract] = useState<ContractForm>(createNewContract(building));
    const [errorMessage, setErrorMessage] = useState('');

    const isValid = useCallback(
        (contract: ContractForm): boolean => {
            if (!hourRegex.test(contract.startPeak) || !hourRegex.test(contract.endPeak)) {
                setErrorMessage(t('company.energyContract.form.invalidHour'));
                return false;
            }
            if (
                !floatRegex.test(contract.price) ||
                !floatRegex.test(contract.lowPrice) ||
                !floatRegex.test(contract.highPrice) ||
                !floatRegex.test(contract.serviceCost) ||
                !floatRegex.test(contract.contractCost)
            ) {
                setErrorMessage(t('company.energyContract.form.invalidNumber'));
                return false;
            }
            if (!contract.startDate || !contract.endDate) {
                setErrorMessage(t('company.energyContract.form.required'));
                return false;
            }
            if (!isAfter(new Date(contract.endDate), new Date(contract.startDate))) {
                setErrorMessage(t('company.energyContract.form.invalidDate'));
                return false;
            }
            setErrorMessage('');
            return true;
        },
        [t]
    );

    const submit = useCallback(
        (contract: ContractForm) => {
            setInitialSubmit(true);
            if (isValid(contract)) {
                onContractSubmit(mapContract(contract));
            }
        },
        [onContractSubmit, isValid]
    );

    const energyUnit = contract.energyType === EnergyType.GAS ? KpiUnit.M3 : KpiUnit.KWH;

    return (
        <>
            <div>
                <LeadLeft>{contract.building}</LeadLeft>
            </div>
            <div style={{ paddingBlock: '5px' }}>{t('company.energyContract.form.energyType')}</div>
            <StyledSelect>
                <select
                    value={contract.energyType}
                    onChange={e => {
                        setContract(c => {
                            return {
                                ...c,
                                energyType: e.target.value as EnergyType,
                            };
                        });
                    }}
                >
                    {energyTypes.map(option => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </StyledSelect>
            {contract.energyType === EnergyType.ELECTRICITY && (
                <>
                    <div style={{ paddingBlock: '5px' }}>{t('company.energyContract.form.contractType')}</div>
                    <StyledSelect>
                        <select
                            value={contract.contractType}
                            onChange={e => {
                                setContract(c => {
                                    return {
                                        ...c,
                                        contractType: e.target.value as ContractType,
                                    };
                                });
                            }}
                        >
                            {contractTypes.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </StyledSelect>
                </>
            )}
            <Column>
                <TextInput
                    type="date"
                    input={contract.startDate}
                    label={t('company.energyContract.form.startDate')}
                    onChange={e => {
                        setContract(c => {
                            return {
                                ...c,
                                startDate: e.target.value,
                            };
                        });
                    }}
                />

                <TextInput
                    type="date"
                    input={contract.endDate}
                    label={t('company.energyContract.form.endDate')}
                    onChange={e => {
                        setContract(c => {
                            return {
                                ...c,
                                endDate: e.target.value,
                            };
                        });
                    }}
                />
            </Column>

            {contract.energyType === EnergyType.GAS && (
                <TextInput
                    hasError={initialSubmit && !floatRegex.test(contract.price)}
                    input={contract.price}
                    label={t('company.energyContract.form.price')}
                    onChange={e => {
                        setContract(c => {
                            return {
                                ...c,
                                price: e.target.value,
                            };
                        });
                    }}
                />
            )}
            {contract.energyType === EnergyType.ELECTRICITY && (
                <>
                    <Column>
                        <TextInput
                            hasError={initialSubmit && !floatRegex.test(contract.lowPrice)}
                            input={contract.lowPrice}
                            pattern={floatPattern}
                            label={
                                contract.contractType === ContractType.CONSTANT
                                    ? t('company.energyContract.form.price')
                                    : t('company.energyContract.form.lowPrice')
                            }
                            onChange={e => {
                                setContract(c => {
                                    return {
                                        ...c,
                                        lowPrice: e.target.value,
                                    };
                                });
                            }}
                        />
                        {contract.contractType != ContractType.CONSTANT && (
                            <TextInput
                                hasError={initialSubmit && !floatRegex.test(contract.highPrice)}
                                input={contract.highPrice}
                                label={t('company.energyContract.form.highPrice')}
                                onChange={e => {
                                    setContract(c => {
                                        return {
                                            ...c,
                                            highPrice: e.target.value,
                                        };
                                    });
                                }}
                            />
                        )}
                    </Column>

                    {contract.contractType === ContractType.PEAK && (
                        <Column>
                            <TextInput
                                hasError={initialSubmit && !hourRegex.test(contract.startPeak)}
                                pattern={hourPattern}
                                input={contract.startPeak}
                                label={t('company.energyContract.form.startPeak')}
                                onChange={e => {
                                    setContract(c => {
                                        return {
                                            ...c,
                                            startPeak: e.target.value,
                                        };
                                    });
                                }}
                            />
                            <TextInput
                                hasError={initialSubmit && !hourRegex.test(contract.endPeak)}
                                pattern={hourPattern}
                                input={contract.endPeak}
                                label={t('company.energyContract.form.endPeak')}
                                onChange={e => {
                                    setContract(c => {
                                        return {
                                            ...c,
                                            endPeak: e.target.value,
                                        };
                                    });
                                }}
                            />
                        </Column>
                    )}
                </>
            )}
            <TextInput
                hasError={initialSubmit && !floatRegex.test(contract.serviceCost)}
                input={contract.serviceCost}
                label={t('company.energyContract.form.serviceCost', { unit: energyUnit })}
                onChange={e => {
                    setContract(c => {
                        return {
                            ...c,
                            serviceCost: e.target.value,
                        };
                    });
                }}
            />
            <TextInput
                hasError={initialSubmit && !floatRegex.test(contract.contractCost)}
                input={contract.contractCost}
                label={t('company.energyContract.form.contractCost', { unit: energyUnit })}
                onChange={e => {
                    setContract(c => {
                        return {
                            ...c,
                            contractCost: e.target.value,
                        };
                    });
                }}
            />

            {errorMessage ? (
                <>
                    <br />
                    <SupportCaptionLeftNegative>{errorMessage}</SupportCaptionLeftNegative>
                </>
            ) : null}
            <br />
            <SubmitButton disabled={false} onClick={() => submit(contract)}>
                {t('common.save')}
            </SubmitButton>
        </>
    );
};

export default CreateContract;

const createNewContract = (buildingName: string): ContractForm => {
    return {
        id: crypto.randomUUID(),
        building: buildingName,
        energyType: EnergyType.ELECTRICITY,
        contractType: ContractType.CONSTANT,
        startDate: format(new Date(), dateFormat),
        endDate: format(new Date(), dateFormat),
        price: '0',
        serviceCost: '0',
        contractCost: '0',
        lowPrice: '0',
        highPrice: '0',
        endPeak: '23',
        startPeak: '7',
    };
};

const energyTypes = [
    {
        label: i18n.t('company.energyContract.form.electricity'),
        value: EnergyType.ELECTRICITY,
    },
    {
        label: i18n.t('company.energyContract.form.gas'),
        value: EnergyType.GAS,
    },
];

const contractTypes = [
    {
        label: i18n.t('company.energyContract.form.constant'),
        value: ContractType.CONSTANT,
    },
    {
        label: i18n.t('company.energyContract.form.dynamic'),
        value: ContractType.DYNAMIC,
    },
    {
        label: i18n.t('company.energyContract.form.peak'),
        value: ContractType.PEAK,
    },
];

const mapContract = (contract: ContractForm): GasContract | ElectricityContract => {
    const mappedContract = {
        id: contract.id,
        building: contract.building,
        energyType: contract.energyType,
        contractType: contract.contractType,
        startDate: contract.startDate,
        endDate: contract.endDate,
        serviceCost: Number(contract.serviceCost),
        contractCost: Number(contract.contractCost),
    };

    if (contract.energyType === EnergyType.GAS) {
        const gasContract = {
            ...mappedContract,
            price: Number(contract.price),
        };

        return gasContract;
    }

    if (contract.energyType === EnergyType.ELECTRICITY) {
        if (contract.contractType === ContractType.CONSTANT) {
            const ConstantContract = {
                ...mappedContract,
                lowPrice: Number(contract.lowPrice),
                highPrice: Number(contract.lowPrice),
            };

            return ConstantContract;
        }
        if (contract.contractType === ContractType.PEAK) {
            const PeakContract = {
                ...mappedContract,
                lowPrice: Number(contract.lowPrice),
                highPrice: Number(contract.highPrice),
                startPeak: Number(contract.startPeak),
                endPeak: Number(contract.endPeak),
            };

            return PeakContract;
        }
        if (contract.contractType === ContractType.DYNAMIC) {
            const DynamicContract = {
                ...mappedContract,
                lowPrice: Number(contract.lowPrice),
                highPrice: Number(contract.highPrice),
            };

            return DynamicContract;
        }
    }
};
