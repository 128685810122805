import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryBar,
    VictoryChart,
    VictoryAxis,
    VictoryArea,
    VictoryTooltip,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryLine,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipBar from '../Tooltip/TooltipBar';

import { getAnomalyUnit } from '../../../styled/components/dataPane';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import { formatXAxisIncidentTickValue, getMax, getMin, setBarConfig, sumValues } from '../graph.helpers';
import {
    barChartIncidentDomainPadding,
    barChartPadding,
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
} from '../graph.styles';
import { ModuleType } from 'types';
import TooltipIncident from '../Tooltip/TooltipIncident';
import { IncidentGraphProps, SensorValue } from '../types';

const OccupancyIncidentsGraph: React.FunctionComponent<IncidentGraphProps> = ({ graphData, isBarChart }) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);

    const summedData = useMemo(() => sumValues(graphData.data, graphData.timespan), [graphData]);
    const max = getMax(summedData);
    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );
    return (
        <VictoryChart
            width={dimension.width}
            height={dimension.height * 0.29}
            domainPadding={barChartIncidentDomainPadding}
            padding={barChartPadding}
            containerComponent={
                !isBarChart ? (
                    <VictoryZoomVoronoiContainer
                        mouseFollowTooltips={false}
                        voronoiDimension="x"
                        zoomDimension="x"
                        minimumZoom={{ x: 1, y: 0.005 }}
                        labels={() => ' '}
                        labelComponent={
                            <VictoryTooltip
                                dx={d => {
                                    const mousePosition = d.x;
                                    return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                }}
                                flyoutComponent={
                                    <TooltipIncident
                                        module={ModuleType.OCCUPANCY}
                                        unit={getAnomalyUnit(graphData.anomalySubtype)}
                                        height={dimension.height}
                                        averageData={graphData.averageData}
                                    />
                                }
                            />
                        }
                    />
                ) : (
                    <VictoryContainer />
                )
            }
        >
            {/* X-axis */}
            <VictoryAxis
                tickValues={graphData.tickValues}
                tickFormat={(t: number, index: number) =>
                    formatXAxisIncidentTickValue(t, index, graphData.tickAmount, graphData.tickFormat)
                }
                style={XYAxis}
            />
            {/* Y-axis */}
            <VictoryAxis
                label={getAnomalyUnit(graphData.anomalySubtype)}
                crossAxis={false}
                dependentAxis={true}
                style={XYAxis}
            />

            {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
            <VictoryAxis style={closingAxis} orientation="right" />
            <VictoryAxis style={closingAxis} orientation="top" />
            <VictoryAxis style={closingXYAxis} orientation="left" />
            <VictoryAxis style={closingXYAxis} orientation="bottom" />

            {/* Date Y-axis*/}
            {graphData.axisDates &&
                Boolean(graphData.axisDates.length) &&
                graphData.axisDates.map(axis => (
                    <VictoryAxis
                        axisValue={axis}
                        dependentAxis={true}
                        label={format(new Date(axis * 1000), monthDayFormat)}
                        tickFormat={() => ''}
                        style={dateAxis}
                        key={axis}
                    />
                ))}

            {!isBarChart && (
                <VictoryLine
                    name="average"
                    animate={true}
                    interpolation="monotoneX"
                    x="timestamp"
                    y="value"
                    style={{
                        data: { stroke: theme.colors.mOccupL },
                    }}
                    data={graphData.averageData}
                />
            )}
            {!isBarChart && (
                <VictoryLine
                    interpolation="monotoneX"
                    x="timestamp"
                    y="value"
                    style={{
                        data: { stroke: theme.colors.mOccup },
                    }}
                    data={graphData.data}
                />
            )}

            <VictoryArea
                data={[
                    {
                        x: graphData.brushArea[0],
                        y: isBarChart ? 0 : getMin([...graphData.data, ...graphData.averageData]) * 0.7,
                        y0: isBarChart ? max * 1.1 : getMax([...graphData.data, ...graphData.averageData]) * 1.1,
                    },
                    {
                        x: graphData.brushArea[1],
                        y: isBarChart ? 0 : getMin([...graphData.data, ...graphData.averageData]) * 0.7,
                        y0: isBarChart ? max * 1.1 : getMax([...graphData.data, ...graphData.averageData]) * 1.1,
                    },
                ]}
                style={{
                    data: {
                        fill: theme.colors.mOccupLTransparent,
                    },
                }}
            />

            {isBarChart && (
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={ModuleType.OCCUPANCY}
                                    title={t('tooltip.current')}
                                    unit={t('infoHeader.occupancy.motions')}
                                />
                            }
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mOccup },
                    }}
                    x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                    y="value"
                    data={summedData}
                />
            )}
            {isBarChart && (
                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={ModuleType.OCCUPANCY}
                                    title={t('tooltip.average')}
                                    unit={t('infoHeader.occupancy.motions')}
                                />
                            }
                        />
                    }
                    animate={{ duration: 500, easing: 'cubicOut' }}
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mOccupL },
                    }}
                    x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                    y="value"
                    data={graphData.averageData}
                />
            )}
        </VictoryChart>
    );
};

export default React.memo(OccupancyIncidentsGraph);
