import React, { FunctionComponent, useEffect, useReducer } from 'react';

import DataPaneMenu from '../DataPaneMenu';
import SensorKpiMenu from './SensorKpiMenu';
import NoDataMessage from '../NoDataMessage';
import ErrorMessage from '../ErrorMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import LineGraphAverages from 'components/Graph/sensors/LineGraphAverages';
import BarChartAverages from 'components/Graph/sensors/BarChartAverages';

import {
    ContentWrapper,
    SensorGraphWrapper,
    InfoHeader,
    SensorsPane,
    getSensorTitle,
} from '../../../styled/components/dataPane';
import { getSensorIcon, getSensorValue } from 'styled/components/sensors';

import { createFromToDate, DateRange, DateSpan } from 'utils/timePeriod';
import { SensorDataState, SensorDataAction, SensorDataParam } from './types';
import { ModuleType, Sensor, SensorAvgFilter } from 'types';

import {
    getSensorInfoHeader,
    getModule,
    createSensorGraphData,
    getAvgFilter,
    showDefaultBarChart,
} from './sensors.helpers';
import { isEmpty } from 'utils/isEmptyObject';

import { useSensorData } from 'hooks/api/useSensorData';
import { useSensorAverageData } from 'hooks/api/useSensorAverageData';

interface SensorDataPaneProps {
    sensors: Sensor[];
}
const SensorDataPane: FunctionComponent<SensorDataPaneProps> = ({ sensors }) => {
    const [state, dispatch] = useReducer(sensorDataReducer, initialState);
    const { action, customDate, timespan, selectedSensor, sensorDataParams, graphData, isBarChart } = state;

    const { data, isLoading, isError } = useSensorData(sensorDataParams, Boolean(sensorDataParams.id));

    const { data: averageData, isLoading: averageIsLoading } = useSensorAverageData(
        sensorDataParams.id,
        selectedSensor.type,
        selectedSensor.subType,
        sensorDataParams.averageFilter,
        isBarChart
    );

    useEffect(() => {
        if (data) {
            dispatch({ type: 'SET_GRAPH_DATA', data: data, averageData: averageData || undefined });
        }
    }, [data, averageData]);

    useEffect(() => {
        dispatch({ type: 'INIT_SENSOR_SELECTION', sensors });
    }, [sensors]);

    const onToggleGraph = (isBarChart: boolean) => {
        dispatch({ type: 'TOGGLE_CHART', isBarChart });
    };

    return (
        <SensorsPane>
            {!isEmpty(selectedSensor) && (
                <>
                    <SensorKpiMenu
                        sensors={sensors}
                        selectedSensor={selectedSensor}
                        onSelectSensor={sensor => dispatch({ type: 'CHANGE_SENSOR', selectedSensor: sensor })}
                    />

                    <DataPaneMenu
                        customDate={customDate}
                        title={getSensorTitle(selectedSensor.type, selectedSensor.subType)}
                        icon={getSensorIcon(selectedSensor.type)}
                        selectedAction={action}
                        onSelectAction={(action, timespan, customDate) =>
                            dispatch({
                                type: 'CHANGE_DATE_SPAN',
                                action,
                                timespan,
                                customDate: customDate || { from: '', to: '' },
                            })
                        }
                        module={getModule(selectedSensor.type)}
                        canToggleGraph={true}
                        isBarChart={isBarChart}
                        onToggleGraph={onToggleGraph}
                        graphData={graphData}
                        subTitle={selectedSensor.name}
                    />

                    <ContentWrapper>
                        {(isLoading || graphData === null) && !isError && <LoadingOverlay dark={true} isSmall={true} />}
                        {!isLoading && isError && <ErrorMessage />}
                        {!isLoading && !isError && graphData?.data.length === 0 && (
                            <NoDataMessage
                                firstDataReceivedDate={selectedSensor.dataFlowStart}
                                lastDataReceivedDate={selectedSensor.latestKpi.time}
                            />
                        )}
                        {!isLoading && !isError && graphData?.data.length && (
                            <>
                                <InfoHeader>
                                    {getSensorInfoHeader(
                                        selectedSensor,
                                        getSensorValue(selectedSensor, graphData.data, timespan),
                                        averageIsLoading
                                    )}
                                </InfoHeader>
                                <SensorGraphWrapper>
                                    {isBarChart ? (
                                        <BarChartAverages sensor={selectedSensor} graphData={graphData} />
                                    ) : (
                                        <LineGraphAverages sensor={selectedSensor} graphData={graphData} />
                                    )}
                                </SensorGraphWrapper>
                            </>
                        )}
                    </ContentWrapper>
                </>
            )}
        </SensorsPane>
    );
};

export default SensorDataPane;

const initialState: SensorDataState = {
    sensorDataParams: {
        averageFilter: SensorAvgFilter.HOUR,
        from: '',
        to: '',
        id: '',
    },
    graphData: null,
    selectedSensor: {} as Sensor,
    action: DateSpan.TWO_DAYS,
    isBarChart: false,
    timespan: DateSpan.TWO_DAYS,
    customDate: {
        from: '',
        to: '',
    },
};

const sensorDataReducer = (state: SensorDataState, action: SensorDataAction): SensorDataState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const graphData = createSensorGraphData(
                action.data,
                state.timespan,
                state.action,
                state.customDate,
                action.averageData,
                state.isBarChart
            );

            return {
                ...state,
                graphData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const averageFilter = getAvgFilter(action.timespan, state.isBarChart);
            const sensorAvgFilter = action.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null;
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : state.customDate;
            return {
                ...state,
                timespan: action.timespan,
                action: action.action,
                customDate,
                graphData: null,
                sensorDataParams: { ...state.sensorDataParams, from, to, averageFilter, sensorAvgFilter },
            };
        }
        case 'INIT_SENSOR_SELECTION': {
            const selectedSensor = state.selectedSensor
                ? action.sensors.find(s => s.type === state.selectedSensor.type) || action.sensors[0]
                : action.sensors[0];
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            const isBarChart = showDefaultBarChart(selectedSensor);
            return {
                ...state,
                selectedSensor: selectedSensor,
                isBarChart: isBarChart,
                graphData: null,
                sensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, isBarChart),
                    sensorAvgFilter: state.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                    from,
                    to,
                    id: selectedSensor.id,
                },
            };
        }
        case 'CHANGE_SENSOR': {
            const isBarChart = showDefaultBarChart(action.selectedSensor);
            const resetTimespan =
                getModule(action.selectedSensor.type) !== ModuleType.ENERGY && state.timespan === DateSpan.YEAR;
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            const returnObj = resetTimespan
                ? {
                      customDate: {
                          from: '',
                          to: '',
                      } as DateRange,
                      timespan: DateSpan.TWO_DAYS,
                      action: DateSpan.TWO_DAYS,
                      sensorDataParams: {
                          averageFilter: getAvgFilter(DateSpan.TWO_DAYS, isBarChart),
                          sensorAvgFilter: null,
                          ...createFromToDate(DateSpan.TWO_DAYS),
                          id: action.selectedSensor.id,
                      } as SensorDataParam,
                  }
                : {
                      sensorDataParams: {
                          averageFilter: getAvgFilter(state.timespan, isBarChart),
                          sensorAvgFilter: state.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                          from,
                          to,
                          id: action.selectedSensor.id,
                      } as SensorDataParam,
                  };

            return {
                ...state,
                ...returnObj,
                selectedSensor: action.selectedSensor,
                isBarChart: isBarChart,
                graphData: null,
            };
        }
        case 'TOGGLE_CHART': {
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            return {
                ...state,
                isBarChart: action.isBarChart,
                graphData: null,
                sensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, action.isBarChart),
                    sensorAvgFilter: state.timespan === DateSpan.YEAR ? SensorAvgFilter.DAY : null,
                    from,
                    to,
                    id: state.selectedSensor.id,
                },
            };
        }
    }
};
