import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { IncidentData } from 'types';

export const useSensorIncidentsData = (startDate: string, endDate: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['sensorIncidents', startDate, endDate], async ({ signal }) => {
        const { data } = await axiosClient.get<IncidentData>(createSensorsIncidentsUrl(startDate, endDate), {
            signal,
        });

        return data.data;
    });
};

const createSensorsIncidentsUrl = (from: string, to: string) => {
    if (from && to) {
        return config.incidentsSensorsForPeriod(from, to);
    }

    if (from) {
        return config.incidentsSensorsFrom(from);
    }

    return config.incidentsSensorsFrom();
};
