import React from 'react';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import { useAuth } from '../context/AuthContext';

import UnAuthenticatedRoutes from './UnAuthenticatedRoutes';
import AuthenticatedRoutes from './AuthenticatedRoutes';

import { TenantProvider } from 'context/TenantContext';

export default function Router(): JSX.Element {
    const { authState } = useAuth();

    return authState.is_fetching ? (
        <LoadingOverlay />
    ) : authState.is_logged_in ? (
        <TenantProvider>
            <AuthenticatedRoutes />
        </TenantProvider>
    ) : (
        <UnAuthenticatedRoutes />
    );
}
