import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'routing/routes';

import { useTranslation } from 'react-i18next';

import MainWrapper from 'components/Container/MainWrapper';
import TextInput from 'components/TextInput';

import { LoginWrapper } from 'styled/components/view';
import { H3, BodyLeft, CaptionLeftNegative } from 'styled/components/text';
import { SubmitButton } from 'styled/components/buttons';

import { postUpdatePassword } from '../../../services/authService';

import { isValidPassword } from 'utils';

import ReturnToLogin from 'components/Login/ReturnToLogin';

interface ResetPasswordValidationErrors {
    password1: string;
    password2: string;
    passwordCheck: string;
}

const ResetPasswordView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [passwordReset, setPasswordReset] = useState(false);
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    const [validationErrors, setValidationErrors] = useState<ResetPasswordValidationErrors>({
        password1: '',
        password2: '',
        passwordCheck: '',
    });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const passwordToken = useRef('');
    const isNewPassword = useRef(false);

    useEffect(() => {
        const token = searchParams.get('token');
        const createNew = searchParams.get('createNew');

        if (!token) {
            navigate(routes.login);
            return null;
        }

        passwordToken.current = token;
        isNewPassword.current = createNew ? true : false;
    }, [searchParams, navigate]);

    const validateForm = () => {
        const errors = getValidationErrors();

        setValidationErrors(errors);

        return Object.values(errors).filter(e => e !== '').length === 0;
    };

    const getValidationErrors = () => {
        const errors: ResetPasswordValidationErrors = {
            password1: '',
            password2: '',
            passwordCheck: '',
        };
        if (!password1 || !password2) {
            errors.password1 = password1 ? '' : t('login.required');
            errors.password2 = password2 ? '' : t('login.required');
        }
        if (password1) {
            const validPassword1 = isValidPassword(password1);
            errors.password1 = validPassword1 ? '' : t('login.passwordValidationError');
        }
        if (password2) {
            const validPassword2 = isValidPassword(password2);
            errors.password2 = validPassword2 ? '' : t('login.passwordValidationError');
        }
        if (password1 !== password2) {
            errors.passwordCheck = t('login.passwordMatchError');
        }

        return errors;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setLoading(true);
                setErrorMessage('');

                const result = await postUpdatePassword(passwordToken.current, password1, password2);

                if (result.status === 204 || result.status === 200) {
                    setPasswordReset(true);
                } else {
                    setErrorMessage(result.statusText);
                }
            } catch (e) {
                setErrorMessage(t('login.generalError'));
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <MainWrapper>
            <LoginWrapper>
                <ReturnToLogin />
                {passwordReset ? (
                    <>
                        <>
                            {isNewPassword.current ? (
                                <H3>{t('login.createPasswordSuccess')}</H3>
                            ) : (
                                <H3>{t('login.resetPasswordSuccess')}</H3>
                            )}
                            <BodyLeft>{t('login.returnToLogin')}</BodyLeft>
                        </>
                    </>
                ) : (
                    <>
                        {isNewPassword.current ? (
                            <H3>{t('login.createPasswordTitle')}</H3>
                        ) : (
                            <H3>{t('login.resetPasswordTitle')}</H3>
                        )}
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                type={'password'}
                                label={t('login.newPassword')}
                                input={password1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPassword1(e.target.value);
                                }}
                                hasError={Boolean(validationErrors.passwordCheck || validationErrors.password1)}
                            />
                            {validationErrors.password1 ? (
                                <CaptionLeftNegative>{validationErrors.password1}</CaptionLeftNegative>
                            ) : null}
                            <TextInput
                                type={'password'}
                                label={t('login.repeatPassword')}
                                input={password2}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setPassword2(e.target.value);
                                }}
                                hasError={Boolean(validationErrors.passwordCheck || validationErrors.password2)}
                            />
                            {validationErrors.password2 ? (
                                <CaptionLeftNegative>{validationErrors.password2}</CaptionLeftNegative>
                            ) : null}
                            {validationErrors.passwordCheck ? (
                                <CaptionLeftNegative>{validationErrors.passwordCheck}</CaptionLeftNegative>
                            ) : null}
                            {errorMessage ? <CaptionLeftNegative>{errorMessage}</CaptionLeftNegative> : null}
                            <br />
                            <SubmitButton disabled={loading} type={'submit'}>
                                {isNewPassword.current
                                    ? t('login.submitCreatePassword')
                                    : t('login.submitResetPassword')}
                            </SubmitButton>
                        </form>
                    </>
                )}
            </LoginWrapper>
        </MainWrapper>
    );
};

export default ResetPasswordView;
