import React from 'react';

import i18n from '../../../i18n';

import { ProgressWrapper, Progress, ProgressBarWrapper, StepText, StepsWrapper } from 'styled/components/ebs';

import { WizardStep } from '../types';

const steps = [
    i18n.t('energy.ebs.steps.introduction'),
    i18n.t('energy.ebs.steps.facility'),
    i18n.t('energy.ebs.steps.electricity'),
    i18n.t('energy.ebs.steps.gas'),
    i18n.t('energy.ebs.steps.interaction'),
    i18n.t('energy.ebs.steps.preview'),
];

interface Props {
    step: number;
}
const ProgressBar: React.FunctionComponent<Props> = ({ step }) => {
    return (
        <ProgressBarWrapper>
            <ProgressWrapper>
                <Progress width={getWidthByStep(step)} />
            </ProgressWrapper>
            <StepsWrapper>
                {steps.map((s, index) => (
                    <StepText key={index}>{s}</StepText>
                ))}
            </StepsWrapper>
        </ProgressBarWrapper>
    );
};

export default ProgressBar;

const getWidthByStep = (step: number): number => {
    switch (step) {
        case WizardStep.INTRODUCTION:
            return 10;
        case WizardStep.FACILITY:
            return 22;
        case WizardStep.ELECTRICITY:
            return 44;
        case WizardStep.GAS:
            return 62;
        case WizardStep.INTERACTION:
            return 80;
        case WizardStep.PREVIEW:
            return 100;
        default:
            return 0;
    }
};
