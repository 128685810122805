import React from 'react';

import { useTranslation } from 'react-i18next';

import TotalUsage from './TotalUsage';
import BuildingInfo from './BuildingInfo';

import { EBSWizardContent } from 'styled/components/ebs';
import { EBSBarWrapper } from 'styled/components/navigation';
import { BodyLeftWhite, H2 } from 'styled/components/text';

import { Space } from 'views/authenticated/types';
import { BagData } from 'types';
import { ComparisonData } from '../types';

interface Props {
    bagData: BagData;
    building: Space;
    comparisonDataLastYear: ComparisonData;
    comparisonDataTwoYearsBack: ComparisonData;
    occupancyRate: number;
    occupancyLoading: boolean;
}

const FacilityView: React.FunctionComponent<Props> = ({
    bagData,
    building,
    comparisonDataLastYear,
    comparisonDataTwoYearsBack,
    occupancyRate,
    occupancyLoading,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <EBSBarWrapper>
                <H2>{t('energy.ebs.facility.facilityTitle')}</H2>
            </EBSBarWrapper>
            <EBSWizardContent>
                {bagData ? (
                    <BuildingInfo
                        bagData={bagData}
                        building={building}
                        occupancyLoading={occupancyLoading}
                        occupancyRate={occupancyRate}
                    />
                ) : (
                    <BodyLeftWhite>{t('energy.ebs.facility.bagError')}</BodyLeftWhite>
                )}
            </EBSWizardContent>
            <EBSBarWrapper>
                <H2>{t('energy.ebs.facility.totalConsumptionTitle')}</H2>
            </EBSBarWrapper>
            <EBSWizardContent>
                <TotalUsage
                    comparisonDataLastYear={comparisonDataLastYear}
                    comparisonDataTwoYearsBack={comparisonDataTwoYearsBack}
                />
            </EBSWizardContent>
        </>
    );
};

export default FacilityView;
