import { differenceInDays } from 'date-fns';
import i18n from '../../i18n';

export const MAX_DAY_SPAN_ENERGY = 365;
export const MAX_DAY_SPAN = 30;
export const THIS_YEAR = +new Date().getFullYear();
export const THIS_MONTH = +new Date().getMonth() + 1;

export interface WeekDays {
    Monday: string;
    Tuesday: string;
    Wednesday: string;
    Thursday: string;
    Friday: string;
    Saturday: string;
    Sunday: string;
}
export const WEEK_DAYS = {
    Monday: i18n.t('common.time.dayOfWeek.mon'),
    Tuesday: i18n.t('common.time.dayOfWeek.tue'),
    Wednesday: i18n.t('common.time.dayOfWeek.wed'),
    Thursday: i18n.t('common.time.dayOfWeek.thu'),
    Friday: i18n.t('common.time.dayOfWeek.fri'),
    Saturday: i18n.t('common.time.dayOfWeek.sat'),
    Sunday: i18n.t('common.time.dayOfWeek.sun'),
};

export interface CalendarMonths {
    January: string;
    February: string;
    March: string;
    April: string;
    May: string;
    June: string;
    July: string;
    August: string;
    September: string;
    October: string;
    November: string;
    December: string;
}
export const CALENDAR_MONTHS: CalendarMonths = {
    January: i18n.t('common.time.months.jan'),
    February: i18n.t('common.time.months.feb'),
    March: i18n.t('common.time.months.mar'),
    April: i18n.t('common.time.months.apr'),
    May: i18n.t('common.time.months.may'),
    June: i18n.t('common.time.months.jun'),
    July: i18n.t('common.time.months.jul'),
    August: i18n.t('common.time.months.aug'),
    September: i18n.t('common.time.months.sep'),
    October: i18n.t('common.time.months.oct'),
    November: i18n.t('common.time.months.nov'),
    December: i18n.t('common.time.months.dec'),
};

export const CALENDAR_WEEKS = 6;

export const isDate = (date: any) => {
    const isDate = Object.prototype.toString.call(date) === '[object Date]';
    const isValidDate = date && !Number.isNaN(date.valueOf());

    return isDate && isValidDate;
};

export const isSameDay = (date: Date, basedate = new Date()) => {
    if (!(isDate(date) && isDate(basedate))) return false;
    const basedateDate = basedate.getDate();
    const basedateMonth = +basedate.getMonth() + 1;
    const basedateYear = basedate.getFullYear();
    const dateDate = date.getDate();
    const dateMonth = +date.getMonth() + 1;
    const dateYear = date.getFullYear();
    return +basedateDate === +dateDate && +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};

export const isSameMonth = (date: Date, basedate = new Date()) => {
    if (!(isDate(date) && isDate(basedate))) return false;
    const basedateMonth = +basedate.getMonth() + 1;
    const basedateYear = basedate.getFullYear();
    const dateMonth = +date.getMonth() + 1;
    const dateYear = date.getFullYear();
    return +basedateMonth === +dateMonth && +basedateYear === +dateYear;
};

export const isAfterToday = (date: Date) => {
    const today = new Date();

    return date.valueOf() > today.valueOf();
};

export const zeroPad = (value: number, length: number) => {
    return `${value}`.padStart(length, '0');
};

export const getYears = () => {
    const years: number[] = [];
    let firstYear = 2021;

    const currentYear = new Date().getFullYear();
    while (firstYear <= currentYear) {
        years.push(firstYear);
        firstYear++;
    }

    return years;
};

export const isMoreThanMaxDaySpan = (endDate: Date, startDate: Date, maxDaySpan: number) => {
    return differenceInDays(endDate, startDate) > maxDaySpan;
};

export const isBeforeStartDate = (endDate: Date, startDate: Date) => {
    return endDate.valueOf() < startDate.valueOf();
};

export const getMonthDays = (month = THIS_MONTH, year = THIS_YEAR) => {
    const months30 = [4, 6, 9, 11];
    const leapYear = year % 4 === 0;
    return month === 2 ? (leapYear ? 29 : 28) : months30.includes(month) ? 30 : 31;
};

export const getMonthFirstDay = (month = THIS_MONTH, year = THIS_YEAR) => {
    return +new Date(`${year}-${zeroPad(month, 2)}-01`).getDay() + 1;
};

export const getPreviousMonth = (month: number, year: number) => {
    const prevMonth = month > 1 ? month - 1 : 12;
    const prevMonthYear = month > 1 ? year : year - 1;
    return { month: prevMonth, year: prevMonthYear };
};

export const getNextMonth = (month: number, year: number) => {
    const nextMonth = month < 12 ? month + 1 : 1;
    const nextMonthYear = month < 12 ? year : year + 1;
    return { month: nextMonth, year: nextMonthYear };
};

export const calendar = (month = THIS_MONTH, year = THIS_YEAR) => {
    const monthDays = getMonthDays(month, year);
    const monthFirstDay = getMonthFirstDay(month, year);

    const daysFromPrevMonth = monthFirstDay - 2 === -1 ? 6 : monthFirstDay - 2;
    const daysFromNextMonth =
        CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays) > 6
            ? CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays) - 7
            : CALENDAR_WEEKS * 7 - (daysFromPrevMonth + monthDays);

    const { month: prevMonth, year: prevMonthYear } = getPreviousMonth(month, year);
    const { month: nextMonth, year: nextMonthYear } = getNextMonth(month, year);

    const prevMonthDays = getMonthDays(prevMonth, prevMonthYear);

    const prevMonthDates = [...new Array(daysFromPrevMonth)].map((n, index) => {
        const day = index + 1 + (prevMonthDays - daysFromPrevMonth);
        return [prevMonthYear, zeroPad(prevMonth, 2), zeroPad(day, 2)];
    });

    const thisMonthDates = [...new Array(monthDays)].map((n, index) => {
        const day = index + 1;
        return [year, zeroPad(month, 2), zeroPad(day, 2)];
    });

    const nextMonthDates = [...new Array(daysFromNextMonth)].map((n, index) => {
        const day = index + 1;
        return [nextMonthYear, zeroPad(nextMonth, 2), zeroPad(day, 2)];
    });

    return [...prevMonthDates, ...thisMonthDates, ...nextMonthDates];
};
