import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { routes } from 'routing/routes';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import { NavigationItem } from '../NavigationBar';

import { MenuButton, Icon } from 'styled/components/buttons';
import { SideMenuBar } from 'styled/components/navigation';

import logOutIcon from '../../assets/icons/icons-g-logout.svg';
import profileIcon from '../../assets/icons/icons-g-profile.svg';
import managementIcon from '../../assets/icons/i-m-occupancy.svg';
import sensorIcon from '../../assets/icons/icons-g-sensor.svg';

import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';

const navigationItems: NavigationItem[] = [
    {
        path: routes.sensors.overview,
        active: false,
        enabled: true,
        label: i18n.t('navigation.sideMenu.itemSensors'),
        icon: sensorIcon,
    },
    {
        path: routes.profile.company,
        active: false,
        enabled: true,
        label: i18n.t('navigation.sideMenu.itemCompany'),
        icon: profileIcon,
    },
    {
        path: routes.profile.me,
        active: false,
        enabled: true,
        label: i18n.t('navigation.sideMenu.itemProfile'),
        icon: profileIcon,
    },
    {
        path: routes.profile.support,
        active: false,
        enabled: true,
        label: i18n.t('navigation.sideMenu.itemSupport'),
        icon: profileIcon,
    },
    {
        path: routes.profile.management,
        active: false,
        enabled: true,
        label: i18n.t('navigation.sideMenu.itemManagement'),
        icon: managementIcon,
    },
];

interface SideMenuInterface {
    hideSideMenu: () => void;
}

const SideMenu: React.FunctionComponent<SideMenuInterface> = ({ hideSideMenu }) => {
    const { t } = useTranslation();
    const { logOut } = useAuth();
    const { clearUserState } = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const [sideMenuItems, setSideMenuItems] = useState<Array<NavigationItem>>(navigationItems);

    useEffect(() => {
        const currentLocation = location.pathname;

        setSideMenuItems(items => [
            ...items.map(item => {
                item.active = currentLocation.includes(item.path);

                return item;
            }),
        ]);
    }, [location.pathname]);

    const logOff = () => {
        clearUserState();
        logOut();
    };

    return (
        <SideMenuBar>
            {sideMenuItems.map((m, index) => (
                <MenuButton
                    id={`ga-${m.label.toLocaleLowerCase().replace(/\s/g, '')}`}
                    active={m.active}
                    key={index}
                    onClick={() => {
                        hideSideMenu();
                        navigate(m.path);
                    }}
                >
                    <Icon $disabled={false} $active={!m.active} src={m.icon ? m.icon : ''} />
                    {m.label}
                </MenuButton>
            ))}
            <MenuButton id={`ga-uitloggen`} active={false} onClick={logOff}>
                <div>
                    <Icon $disabled={false} $active={true} src={logOutIcon} />
                    {t('navigation.sideMenu.itemLogout')}
                </div>
            </MenuButton>
        </SideMenuBar>
    );
};

export default SideMenu;
