import { differenceInCalendarDays, format, startOfDay, startOfToday } from 'date-fns';
import i18n from '../../i18n';

export const getDate = (timestamp: string) => {
    const incidentDate = startOfDay(new Date(timestamp));
    const today = startOfToday();
    const difference = differenceInCalendarDays(today, incidentDate);

    let date;

    if (difference === 0) {
        date = i18n.t('common.time.today');
    } else if (difference === 1) {
        date = i18n.t('common.time.yesterday');
    } else {
        date = format(new Date(timestamp), 'dd MMM');
    }

    return date;
};
