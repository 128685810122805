import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { Space } from 'views/authenticated/types';
import { ModuleType } from 'types';
import { OpeningHours } from 'components/WorkingHours';
import { isEmpty } from 'utils/isEmptyObject';
import { ElectricityContract, GasContract } from 'views/authenticated/profile/types';
import { MetaDataBoiler, MetaDataEwattch } from 'views/authenticated/energy/types';

const preference_keys = ['opening_hours_preference', 'energy_contract'];

export const useTenantData = () => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['tenant'],
        async ({
            signal,
        }): Promise<{
            spaces: Space[];
            modules: ModuleType[];
            openingHours: OpeningHours[];
            contracts: (GasContract | ElectricityContract)[];
            ewattch: MetaDataEwattch[];
            boiler: MetaDataBoiler[];
        }> => {
            const promiseArray = [
                config.buildingExpandedRooms,
                config.modules,
                config.preferences.preferencesTenantFetch(preference_keys),
                config.metaDataEwattch,
                config.metaDataBoiler,
            ].map(url => axiosClient.get(url, { signal }));

            const result = await Promise.all(promiseArray);

            const buildingData = (result[0]?.data as Space[]) || [];
            const modulesData = (result[1]?.data as ModuleType[]) || [];
            const openingHours = (
                !isEmpty(result[2].data) && result[2].data?.opening_hours_preference
                    ? result[2].data.opening_hours_preference
                    : []
            ) as OpeningHours[];
            const contracts = (
                !isEmpty(result[2].data) && result[2].data?.energy_contract ? result[2].data.energy_contract : []
            ) as (GasContract | ElectricityContract)[];
            const ewattch = (result[3]?.data as MetaDataEwattch[]) || [];
            const boiler = (result[4]?.data as MetaDataBoiler[]) || [];

            return { spaces: buildingData, modules: modulesData, openingHours, contracts, ewattch, boiler };
        }
    );
};
