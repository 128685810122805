import React from 'react';

import { useTranslation } from 'react-i18next';
import { theme } from 'styled/Theme';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendButtonIcon, ToggleLegend } from 'styled/components/dataPane';

import legendIcon from '../../../../../assets/icons/i-circle-default.svg';

import { KpiUnit } from 'types';
import { getUnit, numberFormatter } from '../energyStatistics.helper';

interface InfoHeaderProp {
    electricityConsumption: number;
    electricityCost: number;
    gasConsumption: number;
    gasCost: number;
    hasGasData: boolean;
    showGas: boolean;
    showElectricity: boolean;
    isInGigajoules: boolean;
    onShowGas: (showGas: boolean) => void;
    onShowElectricity: (showElectricity: boolean) => void;
}
const EnergyConsumption: React.FunctionComponent<InfoHeaderProp> = ({
    electricityConsumption,
    electricityCost,
    gasConsumption,
    gasCost,
    hasGasData,
    showGas,
    isInGigajoules,
    onShowGas,
    showElectricity,
    onShowElectricity,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{`${numberFormatter(electricityConsumption, 2)} ${getUnit(
                    electricityConsumption,
                    isInGigajoules
                )}`}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.totalBillable')}
                    <br />
                    {t('infoHeader.common.consumption')}
                </CaptionLefWhite>
            </div>
            <div>
                <H2White>{`${electricityCost} €`}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.energy.electricity')}
                    <br />
                    {t('infoHeader.energy.totalCost')}
                </CaptionLefWhite>
            </div>
            {hasGasData && (
                <>
                    <div>
                        <H2White>{`${gasConsumption} ${KpiUnit.M3}`}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.energy.totalGas')}
                            <br />
                            {t('infoHeader.common.consumption')}
                        </CaptionLefWhite>
                    </div>
                    <div>
                        <H2White>{`${gasCost} €`}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.energy.gas')}
                            <br />
                            {t('infoHeader.energy.totalCost')}
                        </CaptionLefWhite>
                    </div>
                </>
            )}
            <div>
                <ToggleLegend active={showElectricity} onClick={() => onShowElectricity(!showElectricity)}>
                    <LegendButtonIcon src={legendIcon} color={theme.colors.mEnergy} />
                    <CaptionLefWhite>{t('infoHeader.energy.electricity')}</CaptionLefWhite>
                </ToggleLegend>
                {hasGasData && (
                    <ToggleLegend active={showGas} onClick={() => onShowGas(!showGas)}>
                        <LegendButtonIcon src={legendIcon} color={theme.colors.mWater} />
                        <CaptionLefWhite>{t('infoHeader.energy.gas')}</CaptionLefWhite>
                    </ToggleLegend>
                )}
            </div>
        </>
    );
};

export default EnergyConsumption;
