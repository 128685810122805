import { theme } from 'styled/Theme';

export const dateAxis = {
    axisLabel: { fill: theme.colors.bg, padding: 5, fontSize: 10, fontFamily: "'Roboto'" },
    axis: { stroke: theme.colors.grey, strokeWidth: 0.8 },
    grid: { stroke: 'none' },
};

export const XYAxis = {
    grid: { stroke: theme.colors.greyD, strokeWidth: 1 },
    axisLabel: { fill: theme.colors.grey, padding: 40, fontSize: 10, fontFamily: "'Roboto'" },
    tickLabels: { fill: theme.colors.grey, fontSize: 10, fontFamily: "'Roboto'" },
    axis: { stroke: theme.colors.grey, strokeWidth: 1 },
};

export const closingXYAxis = {
    grid: { stroke: 'none' },
    axis: { stroke: theme.colors.grey, strokeWidth: 1 },
    tickLabels: { fill: 'none' },
};

export const closingAxis = {
    grid: { stroke: 'none' },
    axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
    tickLabels: { fill: 'none' },
};

export const barChartPadding = { bottom: 15, left: 45, right: 5, top: 0 };

export const barChart2AxisPadding = { bottom: 15, left: 85, right: 5, top: 0 };

export const barChart3AxisPadding = { bottom: 15, left: 125, right: 5, top: 0 };

export const lineChartPadding = { bottom: 15, left: 50, right: 5, top: 0 };

export const gacsReportChartPadding = { bottom: 20, left: 50, right: 5, top: 5 };

export const barChartDomainPadding = { x: 20, y: 30 };

export const barChartIncidentDomainPadding = { x: 20 };

export const lineChartDomainPadding = { y: 30 };

export const feedbackBarChartPadding = { bottom: 20, left: 35, right: 0, top: 20 };

export const feedbackLineChartPadding = { bottom: 15, left: 35, right: 5, top: 20 };
