import React, { useMemo, useState } from 'react';

import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import OccupancyConsumptionGraph from 'components/Graph/statistics/energy/OccupancyConsumptionGraph';

import { ContentWrapper, InfoHeader, LoadingWrapper, SensorGraphWrapper } from 'styled/components/dataPane';

import { OccupancyGraphData } from './types';
import OccupancyConsumptionHeader from './infoHeader/OccupancyConsumptionHeader';
import { getFirstDataTimestamp, getLastKpiUpdateTimestamp, hourSpan, summedValue } from './energyStatistics.helper';
import { Sensor } from 'types';

interface OccupancyConsumptionProps {
    graphData: OccupancyGraphData | null;
    sensors: Sensor[];
    isLoadingConsumption: boolean;
    isLoadingOccupancy: boolean;
}
const OccupancyConsumption: React.FunctionComponent<OccupancyConsumptionProps> = ({
    graphData,
    sensors,
    isLoadingConsumption,
    isLoadingOccupancy,
}) => {
    const avgValue = useMemo(() => calculateAvgValues(graphData), [graphData]);

    const [showGas, setShowGas] = useState(Boolean(graphData?.gasData.length));
    const [showElectricity, setShowElectricity] = useState(true);
    const [showOccupancy, setShowOccupancy] = useState(true);

    const lastUpdate = getLastKpiUpdateTimestamp(sensors);
    const firstData = getFirstDataTimestamp(sensors);

    return (
        <>
            <ContentWrapper>
                {(isLoadingConsumption || graphData === null) && (
                    <LoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption &&
                    graphData?.electricityData.length === 0 &&
                    graphData?.gasData.length === 0 && (
                        <LoadingWrapper>
                            <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                        </LoadingWrapper>
                    )}
                {!isLoadingConsumption &&
                    (Boolean(graphData?.electricityData.length) || Boolean(graphData?.gasData.length)) && (
                        <>
                            <InfoHeader>
                                <OccupancyConsumptionHeader
                                    electricityAvg={avgValue.electricity}
                                    gasAvg={avgValue.gas}
                                    occupancyAvg={avgValue.occupancy}
                                    hasGasData={Boolean(graphData?.gasData.length)}
                                    occupancyLoading={isLoadingOccupancy}
                                    showGas={showGas}
                                    onShowGas={setShowGas}
                                    showElectricity={showElectricity}
                                    onShowElectricity={setShowElectricity}
                                    showOccupancy={showOccupancy}
                                    onShowOccupancy={setShowOccupancy}
                                />
                            </InfoHeader>
                            <SensorGraphWrapper>
                                <OccupancyConsumptionGraph
                                    graphData={graphData}
                                    showGas={showGas}
                                    showElectricity={showElectricity}
                                    showOccupancy={showOccupancy}
                                />
                            </SensorGraphWrapper>
                        </>
                    )}
            </ContentWrapper>
        </>
    );
};

export default OccupancyConsumption;

const calculateAvgValues = (graphData: OccupancyGraphData | null) => {
    if (!graphData) {
        return {
            electricity: 0,
            gas: 0,
            occupancy: 0,
        };
    }
    const totalKWH = summedValue(graphData.electricityData, 1);
    const totalM3 = summedValue(graphData.gasData, 1);
    const totalPercentage = summedValue(graphData.occupancyData, 1);
    const totalHours = hourSpan(graphData.tickValues);

    return {
        electricity: totalKWH ? +(totalKWH / totalHours).toFixed(1) : 0,
        gas: totalM3 ? +(totalM3 / totalHours).toFixed(1) : 0,
        occupancy: totalPercentage ? +(totalPercentage / totalHours).toFixed(1) : 0,
    };
};
