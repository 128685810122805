import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const GACSContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const ContentContainer = styled.div`
    flex-direction: column;
    background-color: ${theme.colors.bg};
    flex: 1;
    display: flex;
`;

export const ButtonView = styled.div`
    display: flex;
    height: 100px;
    align-items: center;
    padding: 30px;
    background-color: ${theme.colors.text};
`;

export const GraphContainer = styled.div`
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    background-color: transparent;
    width: fit-content;
    gap: 40px;
`;

export const GroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 400px;
    color: black;
`;

export const GroupName = styled.div`
    font-weight: bold;
    min-height: 40px;
`;

export const GACSRightSideMenu = styled.div`
    display: flex;
    margin-left: auto;
    align-items: center;
`;

export const ReportHead = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
