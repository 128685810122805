import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryLine,
    VictoryTooltip,
    VictoryChart,
    VictoryAxis,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryBar,
    VictoryContainer,
    VictoryLabel,
} from 'victory';
import { format } from 'date-fns';

import { useResize } from 'hooks/useResize';

import { KpiUnit, ModuleType } from 'types';
import {
    closingAxis,
    closingXYAxis,
    dateAxis,
    gacsReportChartPadding,
    lineChartDomainPadding,
    XYAxis,
} from '../graph.styles';
import { DateSpan, monthDayFormat } from 'utils/timePeriod';
import { formatXAxisTickValue, setBarConfig, sumValues } from '../graph.helpers';
import { SensorValue } from '../types';
import { EfficiencyGraphData } from 'components/DataPane/gacs/types';
import TooltipIncident from '../Tooltip/TooltipIncident';
import TooltipBar from '../Tooltip/TooltipBar';
import { useTranslation } from 'react-i18next';

interface EfficiencyGraphProps {
    graphData: EfficiencyGraphData;
    isBarChart: boolean;
}
const EfficiencyGraph: React.FunctionComponent<EfficiencyGraphProps> = ({ graphData, isBarChart }) => {
    const { t } = useTranslation();
    const dimension = useResize();
    const maxViewHeight = 0.29;

    const barConfig = setBarConfig(graphData.timespan);
    const summedData = useMemo(() => sumValues(graphData.data, graphData.timespan), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    const gradientId = `timeSeriesGradient-${Math.random().toString(36).substr(2, 9)}`;
    return (
        <>
            <svg style={{ height: 0, display: 'block' }}>
                <defs>
                    <linearGradient id={gradientId} x2="0%" y2="100%">
                        {graphData.gradient.map(stop => (
                            <stop key={stop.color} offset={stop.offset} stopColor={stop.color} />
                        ))}
                    </linearGradient>
                </defs>
            </svg>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * maxViewHeight}
                domainPadding={lineChartDomainPadding}
                padding={gacsReportChartPadding}
                minDomain={{ y: graphData.min }}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            voronoiBlacklist={['average']}
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipIncident
                                            titleCurrent={t('energy.gacs.efficiency')}
                                            timespan={DateSpan.YEAR} // hardcode YEAR to show day instead of hours in tooltip
                                            module={ModuleType.ENERGY}
                                            unit={KpiUnit.PERCENTAGE}
                                            height={dimension.height}
                                            averageData={[]}
                                        />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Y-axis */}
                <VictoryAxis
                    dependentAxis={true}
                    crossAxis={false}
                    style={XYAxis}
                    tickFormat={(t: number) => t + '%'}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                <VictoryAxis
                    dependentAxis={true}
                    style={{
                        axis: { stroke: 'transparent' },
                        ticks: { stroke: 'transparent' },
                        tickLabels: { fill: 'transparent', fontSize: 0 },
                        grid: { stroke: 'transparent' },
                    }}
                    tickValues={[95]}
                    tickFormat={(t: number) => `${t}%`}
                    tickLabelComponent={
                        <VictoryLabel style={{ fill: theme.colors.bg, fontSize: '10px', fontFamily: 'Roboto' }} />
                    }
                />

                {isBarChart && (
                    <VictoryBar
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar
                                        module={ModuleType.ENERGY}
                                        title={t('energy.gacs.efficiency')}
                                        unit={KpiUnit.PERCENTAGE}
                                    />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mWater,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y="value"
                        data={summedData}
                    />
                )}

                {!isBarChart && (
                    <VictoryLine
                        interpolation="monotoneX"
                        x="timestamp"
                        y="value"
                        style={{
                            data: { stroke: `url(#${gradientId})`, strokeWidth: 2 },
                        }}
                        data={graphData.data}
                    />
                )}

                <VictoryLine
                    data={[
                        { x: graphData.tickValues[0], y: 95 },
                        { x: graphData.tickValues[graphData.tickValues.length - 1], y: 95 },
                    ]}
                    style={{
                        data: { stroke: 'white', strokeWidth: 1, strokeLinecap: 'round' },
                    }}
                />
            </VictoryChart>
        </>
    );
};

export default React.memo(EfficiencyGraph);
