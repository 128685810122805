import React from 'react';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { H3White } from 'styled/components/text';

interface LoadingOverlayProps {
    lastDataReceivedDate: string | Date;
    firstDataReceivedDate: string | Date;
}
const NoDataMessage: React.FunctionComponent<LoadingOverlayProps> = ({
    firstDataReceivedDate,
    lastDataReceivedDate,
}) => {
    const { t } = useTranslation();

    return (
        <H3White>
            {t('datapane.noData')}
            <br />
            <br />
            {t('datapane.firstTime', {
                firstDate: lastDataReceivedDate
                    ? format(new Date(firstDataReceivedDate), 'dd-MM-yyyy')
                    : t('datapane.dateUnknown'),
            })}
            <br />
            {t('datapane.lastTime', {
                lastDate: lastDataReceivedDate
                    ? format(new Date(lastDataReceivedDate), 'dd-MM-yyyy')
                    : t('datapane.dateUnknown'),
            })}
        </H3White>
    );
};

export default NoDataMessage;
