import React from 'react';

import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip } from 'victory';
import { format } from 'date-fns';

import { useResize } from 'hooks/useResize';

import { closingAxis, closingXYAxis, XYAxis } from '../../graph.styles';

import { setBarConfigFeedback } from 'components/Graph/graph.helpers';
import TooltipMetricFeedback from 'components/Graph/Tooltip/TooltipMetricFeedback';

import { FeedbackGraphData, MetricFeedback } from 'components/DataPane/statistics/feedback/types';
import { theme } from 'styled/Theme';
import { DateSpan } from 'utils/timePeriod';

export interface BarChartProps {
    metric: MetricFeedback;
    graphData: FeedbackGraphData;
}
const MetricFeedbackChart: React.FunctionComponent<BarChartProps> = ({ graphData, metric }) => {
    const dimension = useResize();
    const barConfig = setBarConfigFeedback(graphData.timespan);

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.18}
                domainPadding={{ x: [20, 0] }}
                padding={{ bottom: 22, left: 35, right: 0, top: 20 }}
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatTick(t, ticks, index, graphData.timespan)
                    }
                    style={XYAxis}
                />
                {/* Score Y-axis */}
                <VictoryAxis
                    tickValues={[0, 1, 2, 3, 4, 5]}
                    tickFormat={(t: number) => (t === 0 ? '' : t)}
                    dependentAxis={true}
                    style={XYAxis}
                />

                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            dx={d => {
                                const mousePosition = d.x;
                                return mousePosition && mousePosition > dimension.width / 2 ? -205 : 5;
                            }}
                            flyoutComponent={<TooltipMetricFeedback metric={metric} dateSpan={graphData.timespan} />}
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: {
                            fill: ({ datum }) =>
                                datum[metric] <= 2
                                    ? theme.colors.negativeL
                                    : datum[metric] <= 4
                                    ? theme.colors.mEnergyL
                                    : theme.colors.mFeedback,
                        },
                    }}
                    y={metric}
                    x="timestamp"
                    data={graphData.data}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />
            </VictoryChart>
        </>
    );
};

export default React.memo(MetricFeedbackChart);

const formatTick = (t: number, ticks: number[], index: number, timespan: string) => {
    if (index === ticks.length - 1) {
        return '';
    }
    if (timespan === DateSpan.YEAR) {
        const month = format(new Date(t * 1000), 'MMM');
        return month === 'Jan' ? format(new Date(t * 1000), 'yyyy') : month;
    }

    const dayOfWeek = format(new Date(t * 1000), 'd');
    return dayOfWeek === '1' ? format(new Date(t * 1000), 'MMM') : dayOfWeek;
};
