import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { Hours, FromTo } from 'components/WorkingHours';

import { CaptionrightGrey, LeadCenterWhite } from 'styled/components/text';
import {
    DayWrapper,
    WeekWrapper,
    HourLegenda,
    Hour,
    StyledClosedDay,
    WeekOverviewWrapper,
} from 'styled/components/weekOverview';
import { SensorAverage } from 'types';

import DayOverview from './DayOverview';
import { days_of_week, occupancy_working_hours } from './types';

interface WeekOverView {
    hoursOfDayOfWeek: SensorAverage[];
    openingHours: Hours;
}
const WeekOverview: React.FunctionComponent<WeekOverView> = ({ hoursOfDayOfWeek, openingHours }) => {
    const occupancy = useMemo(
        () => getValueByHourOfDay(hoursOfDayOfWeek, openingHours),
        [hoursOfDayOfWeek, openingHours]
    );
    const workingHoursLegenda = useMemo(() => createWorkingHoursLegenda(openingHours), [openingHours]);

    return (
        <WeekOverviewWrapper>
            <HourLegenda>
                {workingHoursLegenda.map((hour, i) => (
                    <Hour key={i}>
                        <CaptionrightGrey>
                            <span>{hour}</span>
                        </CaptionrightGrey>
                    </Hour>
                ))}
            </HourLegenda>
            <WeekWrapper>
                {occupancy.map((o, i) => (
                    <DayWrapper key={i}>
                        <LeadCenterWhite>{daysOfWeek[i]}</LeadCenterWhite>
                        {o.length ? <DayOverview occupancyByHour={o} /> : <ClosedDay />}
                    </DayWrapper>
                ))}
            </WeekWrapper>
        </WeekOverviewWrapper>
    );
};

export default WeekOverview;

const ClosedDay: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return <StyledClosedDay>{t('company.closed')}</StyledClosedDay>;
};

const mapHourOfDayOfWeekToUTCOffset = (hourOfDayOfWeek: SensorAverage[]): SensorAverage[] => {
    const offSet = new Date().getTimezoneOffset() / 60;
    if (offSet > 0) {
        return hourOfDayOfWeek.map(h => {
            const hour = +h.field.slice(-2) + offSet;
            const newHour = hour < 0 ? hour + 24 : hour;
            const day = +h.field.slice(0, 1);
            const newDay = hour < 0 ? day - 1 : day;
            return {
                field: `${newDay < 0 ? 7 : newDay}.${`0${newHour}`.slice(-2)}`,
                value: h.value,
            };
        });
    }
    return hourOfDayOfWeek.map(h => {
        const hour = +h.field.slice(-2) + Math.abs(offSet);
        const newHour = hour > 23 ? hour - 24 : hour;
        const day = +h.field.slice(0, 1);
        const newDay = hour > 23 ? day + 1 : day;
        return {
            field: `${newDay > 7 ? 1 : newDay}.${`0${newHour}`.slice(-2)}`,
            value: h.value,
        };
    });
};

const getValueByHourOfDay = (hourOfDayOfWeek: SensorAverage[], openingHours: Hours) => {
    const hourDayWeek = mapHourOfDayOfWeekToUTCOffset(hourOfDayOfWeek);
    const workingHours = getWorkingHours(openingHours);
    const weekOccupancy = createWeekOccupancy(openingHours);

    for (let i = 0; i < weekOccupancy.length; i++) {
        for (let j = 0; j < weekOccupancy[i].length; j++) {
            const dayofWeek = i + 1;
            const hourOfDay = `0${workingHours[j]}`.slice(-2);
            const field = `${dayofWeek}.${hourOfDay}`;

            weekOccupancy[i][j] = hourDayWeek.find(e => e.field === field)?.value || 0;
        }
    }

    return weekOccupancy;
};

const getWorkingHours = (openingHours: Hours) => {
    const [openingHour, closingHour] = getOpeningHours(openingHours);

    return defaultWorkingHours.slice(openingHour, closingHour);
};

const createWorkingHoursLegenda = (openingHours: Hours) => {
    const [openingHour, closingHour] = getOpeningHours(openingHours);

    return legendaHours.slice(openingHour + 1, closingHour);
};

const isClosed = (openingHours: FromTo) => {
    return openingHours.from === '' || openingHours.to === '';
};

const createWeekOccupancy = (openingHours: Hours) => {
    const [openingHour, closingHour] = getOpeningHours(openingHours);

    const weekOccupancy: days_of_week = [[], [], [], [], [], [], []];
    Object.keys(openingHours).forEach(k => {
        switch (k) {
            case 'monday':
                weekOccupancy[0] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            case 'tuesday':
                weekOccupancy[1] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            case 'wednesday':
                weekOccupancy[2] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            case 'thursday':
                weekOccupancy[3] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            case 'friday':
                weekOccupancy[4] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            case 'saturday':
                weekOccupancy[5] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            case 'sunday':
                weekOccupancy[6] = mapWeekDayOccupancy(openingHours[k], openingHour, closingHour);
                return;
            default:
                console.error('unknown day');
        }
    });

    return weekOccupancy;
};

const mapWeekDayOccupancy = (openingHours: FromTo, openingHour: number, closingHour: number) => {
    return isClosed(openingHours) ? [] : defaultWorkingHours.slice(openingHour, closingHour);
};

const getOpeningHours = (openingHours: Hours) => {
    let openingHour: number;
    let closingHour: number;
    Object.keys(openingHours).forEach((k: keyof Hours) => {
        if (!isClosed(openingHours[k])) {
            openingHour =
                openingHour === undefined || +openingHours[k].from.slice(0, -3) < openingHour
                    ? +openingHours[k].from.slice(0, -3)
                    : openingHour;
            +openingHours[k].from.slice(0, -3);
            closingHour =
                closingHour === undefined || +openingHours[k].to.slice(0, -3) > closingHour
                    ? +openingHours[k].to.slice(0, -3)
                    : closingHour;
        }
    });

    return [openingHour, closingHour];
};

const defaultWorkingHours: occupancy_working_hours = [
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const legendaHours = [
    '0:00',
    '1:00',
    '2:00',
    '3:00',
    '4:00',
    '5:00',
    '6:00',
    '7:00',
    '8:00',
    '9:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
    '17:00',
    '18:00',
    '19:00',
    '20:00',
    '21:00',
    '22:00',
    '23:00',
];
