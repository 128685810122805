import React from 'react';

import { useTranslation } from 'react-i18next';

import { LeadLeft } from 'styled/components/text';

import { KPICount } from './types';

import goodCircle from '../../assets/icons/i-circle_good.svg';
import averageCircle from '../../assets/icons/i-circle_acceptable.svg';
import poorCircle from '../../assets/icons/i-circle_poor.svg';
import { Count, CountWrapper, KPIIcon, KPICountDisplayWrapper } from 'styled/components/sensorSelectorAir';

interface KPICountDisplayProps {
    count: KPICount;
}
const KPICountDisplay: React.FunctionComponent<KPICountDisplayProps> = ({ count }) => {
    const { t } = useTranslation();

    return (
        <KPICountDisplayWrapper>
            <LeadLeft>
                {t('sensors.count.spacesSelected', {
                    count: (count.Sufficient || 0) + (count.Moderate || 0) + (count.InSufficient || 0),
                })}
            </LeadLeft>

            <CountWrapper>
                <Count>
                    <p>{count.Sufficient}</p>
                    <KPIIcon src={goodCircle} />
                    <p>{t('sensors.count.good')}</p>
                </Count>

                <Count>
                    <p>{count.Moderate}</p>
                    <KPIIcon src={averageCircle} />
                    <p>{t('sensors.count.acceptable')}</p>
                </Count>
                <Count>
                    <p>{count.InSufficient}</p>
                    <KPIIcon src={poorCircle} />
                    <p>{t('sensors.count.poor')}</p>
                </Count>
            </CountWrapper>
        </KPICountDisplayWrapper>
    );
};

export default KPICountDisplay;
