import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import {
    VictoryChart,
    VictoryAxis,
    VictoryTooltip,
    createContainer,
    VictoryVoronoiContainerProps,
    VictoryZoomContainerProps,
    VictoryStack,
    VictoryArea,
    VictoryBar,
    VictoryContainer,
} from 'victory';
import { format } from 'date-fns';

import TooltipIncident from 'components/Graph/Tooltip/TooltipIncident';
import TooltipBar from 'components/Graph/Tooltip/TooltipBar';

import { BillableGraphData } from 'components/DataPane/statistics/energy/types';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import {
    lineChartDomainPadding,
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
    barChartPadding,
} from '../../graph.styles';
import { KpiUnit, ModuleType } from 'types';
import { formatXAxisTickValue, setBarConfig, sumValues } from 'components/Graph/graph.helpers';
import { SensorValue } from 'components/Graph/types';

export interface LineGraphProps {
    graphData: BillableGraphData;
    isBarChart: boolean;
    showGas: boolean;
    showElectricity: boolean;
}
const BillableConsumptionGraph: React.FunctionComponent<LineGraphProps> = ({
    graphData,
    isBarChart,
    showGas,
    showElectricity,
}) => {
    const dimension = useResize();
    const { t } = useTranslation();

    const barConfig = setBarConfig(graphData.timespan);
    const summedElectricityData = useMemo(() => sumValues(graphData.electricityData, graphData.timespan), [graphData]);
    const summedGasData = useMemo(() => sumValues(graphData.gasData, graphData.timespan), [graphData]);

    const VictoryZoomVoronoiContainer = createContainer<VictoryVoronoiContainerProps, VictoryZoomContainerProps>(
        'zoom',
        'voronoi'
    );

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={lineChartDomainPadding}
                padding={barChartPadding}
                containerComponent={
                    !isBarChart ? (
                        <VictoryZoomVoronoiContainer
                            voronoiBlacklist={['average']}
                            mouseFollowTooltips={false}
                            voronoiDimension="x"
                            zoomDimension="x"
                            minimumZoom={{ x: 1, y: 0.005 }}
                            labels={() => ' '}
                            labelComponent={
                                <VictoryTooltip
                                    dx={d => {
                                        const mousePosition = d.x;
                                        return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                    }}
                                    flyoutComponent={
                                        <TooltipIncident
                                            titleCurrent={t('infoHeader.energy.electricity')}
                                            titleSupport={t('infoHeader.energy.gas')}
                                            module={ModuleType.ENERGY}
                                            unit={KpiUnit.EURO}
                                            height={dimension.height}
                                            averageData={[]}
                                            supportData={graphData.gasData}
                                            timespan={graphData.timespan}
                                        />
                                    }
                                />
                            }
                        />
                    ) : (
                        <VictoryContainer />
                    )
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />
                {/* Cost Y-axis */}
                <VictoryAxis
                    tickFormat={(t: number, i: number, ticks: number[]) => (i === ticks.length - 1 ? '€' : t)}
                    dependentAxis={true}
                    style={XYAxis}
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                <VictoryStack>
                    {isBarChart && Boolean(graphData.gasData.length) && showGas && (
                        <VictoryBar
                            labels={() => ''}
                            labelComponent={
                                <VictoryTooltip
                                    flyoutComponent={
                                        <TooltipBar
                                            module={ModuleType.ENERGY}
                                            title={t('tooltip.current')}
                                            unit={KpiUnit.EURO}
                                        />
                                    }
                                />
                            }
                            cornerRadius={barConfig.radius}
                            barWidth={barConfig.width}
                            style={{
                                data: {
                                    fill: theme.colors.mWater,
                                },
                            }}
                            x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                            y="value"
                            data={summedGasData}
                        />
                    )}
                    {isBarChart && Boolean(graphData.electricityData.length) && showElectricity && (
                        <VictoryBar
                            labels={() => ''}
                            labelComponent={
                                <VictoryTooltip
                                    flyoutComponent={
                                        <TooltipBar
                                            module={ModuleType.ENERGY}
                                            title={t('tooltip.current')}
                                            unit={KpiUnit.EURO}
                                        />
                                    }
                                />
                            }
                            cornerRadius={barConfig.radius}
                            barWidth={barConfig.width}
                            style={{
                                data: {
                                    fill: theme.colors.mEnergy,
                                },
                            }}
                            x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                            y="value"
                            data={summedElectricityData}
                        />
                    )}

                    {!isBarChart && Boolean(graphData.gasData.length) && showGas && (
                        <VictoryArea
                            interpolation="monotoneX"
                            x="timestamp"
                            y="value"
                            style={{
                                data: {
                                    stroke: theme.colors.mWater,
                                    fill: theme.colors.mWaterLTransparent,
                                    strokeWidth: 2,
                                },
                            }}
                            data={graphData.gasData}
                        />
                    )}
                    {!isBarChart && Boolean(graphData.electricityData.length) && showElectricity && (
                        <VictoryArea
                            interpolation="monotoneX"
                            x="timestamp"
                            y="value"
                            style={{
                                data: {
                                    stroke: theme.colors.mEnergy,
                                    fill: theme.colors.mEnergyTransparent,
                                    strokeWidth: 2,
                                },
                            }}
                            data={graphData.electricityData}
                        />
                    )}
                </VictoryStack>
            </VictoryChart>
        </>
    );
};

export default React.memo(BillableConsumptionGraph);
