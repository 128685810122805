import React from 'react';

import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';

import {
    AirImage,
    AirIncidentsContainer,
    AirIncidentGrid,
    AirStatisticGrid,
    ClimateContainer,
    ClimateImageContainer,
    CloudImage,
    EnergyContainer,
    HeaderClimateContainer,
    HeaderContainer,
    HeaderGrid,
    HexaconBackground,
    GetContainer,
    Icon,
    IconContainer,
    IconWrapper,
    IncidentWrapper,
    ImageContainer,
    ModuleContainer,
    ModuleIcon,
    ModuleText,
    ModuleTitle,
    PreviewGrid,
    PreviewLead,
    PreviewText,
    SoonSpan,
    SupportBackground,
    SupportContentContainer,
    SupportGrid,
    StatisticsContainer,
} from 'styled/components/preview';
import { H3White } from 'styled/components/text';

import airIcon from '../../../assets/icons/i-m-air.svg';
import cloudIcon from '../../../assets/icons/preview-cloud-icon-air.svg';
import incidentPreview1 from '../../../assets/images/preview/a-preview-1.webp';
import incidentPreview2 from '../../../assets/images/preview/a-preview-2.webp';
import incidentPreview3 from '../../../assets/images/preview/a-preview-3.webp';
import incidentPreview4 from '../../../assets/images/preview/a-preview-4.webp';
import incidentPreview5 from '../../../assets/images/preview/a-preview-5.webp';
import incidentPreview6 from '../../../assets/images/preview/a-preview-6.webp';
import incidentPreview7 from '../../../assets/images/preview/a-preview-7.webp';
import airClimateChart from '../../../assets/images/preview/a-preview-climate.webp';
import airScreen from '../../../assets/images/preview/a-preview-screen.webp';
import moduleIcon from '../../../assets/icons/preview-module-icon-air.svg';

import { ModuleType } from 'types';

import { config } from 'config';

const AirPreviewView: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderGrid>
                <GetContainer />
                <EnergyContainer>
                    <ModuleTitle module={ModuleType.AIR}>Air</ModuleTitle>
                </EnergyContainer>
            </HeaderGrid>
            <PreviewGrid>
                <HexaconBackground />
                <SupportGrid>
                    <IconContainer>
                        <SupportBackground />
                        <IconWrapper>
                            <ModuleIcon src={moduleIcon} />
                        </IconWrapper>
                    </IconContainer>
                    <ModuleContainer>
                        <ModuleText>module</ModuleText>
                    </ModuleContainer>
                    <SupportContentContainer>
                        <PreviewText>{t('preview.air.support')}</PreviewText>
                        <PreviewText>{t('preview.support1')}</PreviewText>
                        <PreviewText>
                            {t('preview.support2')} <span style={{ fontWeight: 'bold' }}>{config.profile.mail}</span>
                        </PreviewText>
                    </SupportContentContainer>
                </SupportGrid>
                <AirIncidentGrid>
                    <HeaderContainer>
                        <H3White>{t('preview.incidentHeader')}</H3White>
                    </HeaderContainer>
                    <AirIncidentsContainer>
                        <IncidentWrapper style={{ paddingLeft: '30px' }}>
                            <AirImage src={incidentPreview1} alt={''} width="290" height="150" />
                            <AirImage src={incidentPreview2} alt={''} width="290" height="150" />
                            <div style={{ maxWidth: '400px' }}>
                                <PreviewLead>
                                    <Icon src={airIcon} />
                                    {t('preview.air.incidents.header1')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.air.incidents.content1')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                        <IncidentWrapper style={{ position: 'relative', left: '60px' }}>
                            <CloudImage src={cloudIcon} alt={''} width="83" />
                            <AirImage src={incidentPreview3} alt={''} width="290" height="150" />
                            <AirImage src={incidentPreview4} alt={''} width="290" height="150" />
                            <AirImage src={incidentPreview6} alt={''} width="290" height="150" />
                        </IncidentWrapper>
                        <IncidentWrapper style={{ position: 'relative', right: '60px' }}>
                            <AirImage src={incidentPreview5} alt={''} width="290" height="150" />
                            <AirImage src={incidentPreview7} alt={''} width="290" height="150" />
                            <div style={{ maxWidth: '500px' }}>
                                <PreviewLead>
                                    <Icon src={airIcon} />
                                    {t('preview.air.incidents.header2')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.air.incidents.content2')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                    </AirIncidentsContainer>
                </AirIncidentGrid>
                <AirStatisticGrid>
                    <HeaderClimateContainer>
                        <H3White>{t('preview.air.statistics.title1')}</H3White>
                    </HeaderClimateContainer>
                    <ImageContainer>
                        <img src={airScreen} alt={'air module screen'} width={740} />
                    </ImageContainer>
                    <ClimateContainer>
                        <div>
                            <PreviewLead>
                                <Icon src={airIcon} />
                                {t('preview.air.statistics.header1')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.air.statistics.content1')}</PreviewText>
                        </div>
                        <ClimateImageContainer>
                            <img src={airClimateChart} alt={'air module screen'} width={1250} />
                        </ClimateImageContainer>
                    </ClimateContainer>
                    <HeaderContainer>
                        <H3White>{t('preview.air.statistics.title2')}</H3White>
                    </HeaderContainer>
                    <StatisticsContainer>
                        <div>
                            <PreviewLead>
                                <Icon src={airIcon} />
                                {t('preview.air.statistics.header2')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.air.statistics.content2')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={airIcon} />
                                {t('preview.air.statistics.header3')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.air.statistics.content3')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={airIcon} />
                                <SoonSpan>{t('preview.soon')}</SoonSpan>
                                {t('preview.air.statistics.header4')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.air.statistics.content4')}</PreviewText>
                        </div>
                    </StatisticsContainer>
                </AirStatisticGrid>
            </PreviewGrid>
            <Footer />
        </>
    );
};

export default AirPreviewView;
