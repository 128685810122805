import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { StyledSelect } from 'styled/components/inputs';

import { useTenant } from 'context/TenantContext';

import { IncidentsFilter } from './types';
import { FilterContent } from 'styled/components/issues';
import { LeadLeft } from 'styled/components/text';

const ALL_BUILDINGS = 'ALL_BUILDINGS';

interface Props {
    onFilter: (filter: IncidentsFilter) => void;
}
const IssuesFilter: React.FunctionComponent<Props> = ({ onFilter }) => {
    const { t } = useTranslation();
    const { getBuildingData } = useTenant();

    const [selectedBuilding, setSelectedBuilding] = useState(ALL_BUILDINGS);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const filter: IncidentsFilter = {
            selectedBuilding: e.target.value === ALL_BUILDINGS ? '' : e.target.value,
        };
        setSelectedBuilding(e.target.value);
        onFilter(filter);
    };

    return (
        <>
            <FilterContent>
                <LeadLeft>Filter:</LeadLeft>
            </FilterContent>
            <StyledSelect>
                <select value={selectedBuilding} onChange={handleChange}>
                    <option key={'all_buildings'} value={ALL_BUILDINGS}>
                        {t('incidents.allBuildings')}
                    </option>
                    {getBuildingData().map(option => (
                        <option key={option.id} value={option.name}>
                            {option.name}
                        </option>
                    ))}
                </select>
            </StyledSelect>
        </>
    );
};

export default IssuesFilter;
