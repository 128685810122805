import React, { createContext, useEffect, useRef, useState } from 'react';

import axios, { AxiosInstance } from 'axios';

import { postRefreshToken, postPersistance } from 'services/authService';
import { useAuth } from './AuthContext';

import { PromiseQueue } from './types';
import { config } from 'config';

export interface IAxiosClientProvider {
    axiosClient: AxiosInstance;
}

const AxiosClientContext = createContext({} as IAxiosClientProvider);

interface BuildingsProps {
    children?: React.ReactNode;
}
const AxiosClientProvider: React.FunctionComponent<BuildingsProps> = ({ children }) => {
    const { refreshToken, accessToken, expiresAt } = useAuth();
    const [initialLoad, setInitialLoad] = useState(true);
    const axiosClient = useRef<AxiosInstance>(null);
    const isRefreshing = useRef<boolean>(false);
    const failedQueue = useRef<PromiseQueue[]>([]);

    const processQueue = (error: any, token: string) => {
        failedQueue.current.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue.current = [];
    };

    useEffect(() => {
        const axiosInstance = axios.create({
            baseURL: config.basePath,
            responseType: 'json',
        });

        axiosInstance.interceptors.request.use(
            request => {
                if (expiresAt.current && new Date() > new Date(expiresAt.current)) {
                    const originalRequest = request as any;

                    if (isRefreshing.current) {
                        return new Promise((resolve, reject) => {
                            failedQueue.current.push({ resolve, reject });
                        })
                            .then(token => {
                                originalRequest.headers['Authorization'] = 'Bearer ' + token;

                                return originalRequest;
                            })
                            .catch(err => {
                                return Promise.reject(err);
                            });
                    }

                    isRefreshing.current = true;
                    originalRequest._retry = true;

                    return new Promise((resolve, reject) => {
                        postRefreshToken(refreshToken.current)
                            .then(result => {
                                const jwtPayload = JSON.parse(window.atob(result.data.access_token.split('.')[1]));
                                expiresAt.current = (jwtPayload.exp - 10) * 1000;
                                refreshToken.current = result.data.refresh_token;
                                accessToken.current = result.data.access_token;

                                postPersistance(result.data);

                                originalRequest.headers['Authorization'] = `Bearer ${result.data.access_token}`;

                                processQueue(null, result.data.access_token);

                                resolve(originalRequest);
                            })
                            .catch(err => {
                                processQueue(err, null);
                                reject(err);
                            })
                            .finally(() => {
                                isRefreshing.current = false;
                            });
                    });
                }
                if (accessToken.current) {
                    request.headers['Authorization'] = `Bearer ${accessToken.current}`;
                }

                return request;
            },
            error => {
                console.log(error);
                return error;
            }
        );

        axiosClient.current = axiosInstance;
        setInitialLoad(false);
    }, []);

    const contextValue = {
        axiosClient: axiosClient.current,
    };
    return <AxiosClientContext.Provider value={contextValue}>{!initialLoad && children}</AxiosClientContext.Provider>;
};

const useAxiosClient = () => {
    const context = React.useContext(AxiosClientContext);
    if (context === undefined) {
        throw new Error('useAxiosClient must be used within a AxiosClientProvider');
    }
    return context;
};

export { AxiosClientProvider, useAxiosClient };
