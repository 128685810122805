import styled from 'styled-components';
import { theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';
import { CaptionLeft } from './text';

export const SupportInfoWrapper = styled.div`
    gap: 1px;
    margin-top: 15px;
    width: 60%;
    display: flex;
    overflow: hidden;
    height: 40px;
    border-radius: 4px;
    background: ${theme.colors.white};
`;

export const SupportIconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    line-height: 40px;
    text-align: center;
    vertical-align: middle;
    background: ${theme.colors.grey};
`;

export const SupportIconButtonWrapper = styled(SupportIconWrapper)`
    cursor: pointer;
`;

export const SupportIcon = styled(SVG)`
    width: 15px;
    height: 15px;
    line-height: 15px;
`;

export const SupportTextwrapper = styled(SupportIconWrapper)`
    width: 100%;
    height: 40px;
    font-weight: bold;
    padding: 0px 15px;
    color: ${theme.colors.text};
    background: ${theme.colors.grey};
`;

export const DividerBorder = styled.div`
    width: 100%;
    border-bottom: 1px solid ${theme.colors.grey};
`;

export const SupportCaptionLeftNegative = styled(CaptionLeft)`
    color: ${props => props.theme.colors.negative};
`;
