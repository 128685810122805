import { useSearchParams } from 'react-router-dom';

export interface SelectedIncident {
    id: string;
    timestamp: string;
}

export const useIncident = () => {
    const [searchParams] = useSearchParams();

    const idParam = searchParams.get('id');
    const timestampParam = searchParams.get('timestamp');

    const selectedIncident =
        idParam && timestampParam ? { id: idParam, timestamp: timestampParam.replace(' ', '+') } : undefined;

    return { selectedIncident };
};
