import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import ReturnToLogin from 'components/Login/ReturnToLogin';
import MainWrapper from 'components/Container/MainWrapper';
import TextInput from 'components/TextInput';

import { LoginWrapper } from 'styled/components/view';
import { H3, CaptionLeftNegative, BodyLeft, LeadLeftSpan } from 'styled/components/text';
import { SubmitButton } from 'styled/components/buttons';

import { isValidEmail } from 'utils';

import { postResetPassword } from '../../../services/authService';

import { config } from 'config';

interface ResetLinkValidationErrors {
    email: string;
}

const ResetLinkView: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const [linkSent, setLinkSent] = useState(false);
    const [email, setEmail] = useState('');
    const [validationErrors, setValidationErrors] = useState<ResetLinkValidationErrors>({ email: '' });
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const validateForm = () => {
        const errors = getValidationErrors();

        setValidationErrors(errors);

        return Object.values(errors).filter(e => e !== '').length === 0;
    };

    const getValidationErrors = () => {
        const errors: ResetLinkValidationErrors = {
            email: '',
        };

        if (!email) {
            errors.email = t('login.required');
        }

        const validEmail = isValidEmail(email);
        if (email && !validEmail) {
            errors.email = t('login.incorrectEmailFormat');
        }

        return errors;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                setLoading(true);
                setErrorMessage('');

                const result = await postResetPassword(email);

                if (result.status === 200) {
                    setLinkSent(true);
                } else {
                    setErrorMessage(result.statusText);
                }
            } catch {
                setErrorMessage(t('login.generalError'));
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <MainWrapper>
            <LoginWrapper>
                <ReturnToLogin />
                {!linkSent ? (
                    <>
                        <H3>{t('login.resetPasswordTitle')}</H3>
                        <form onSubmit={handleSubmit}>
                            <TextInput
                                label={t('login.email')}
                                input={email}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setEmail(e.target.value);
                                }}
                                hasError={Boolean(validationErrors.email)}
                            />
                            {validationErrors.email ? (
                                <CaptionLeftNegative>{validationErrors.email}</CaptionLeftNegative>
                            ) : null}
                            {errorMessage ? <CaptionLeftNegative>{errorMessage}</CaptionLeftNegative> : null}
                            <br />
                            <SubmitButton disabled={loading} type={'submit'}>
                                {t('login.submitPasswordResetRequest')}
                            </SubmitButton>
                        </form>
                        <br />
                        <BodyLeft>
                            {t('login.resetRequestHelp')}
                            &nbsp;
                            <LeadLeftSpan>{config.profile.mail}</LeadLeftSpan>
                        </BodyLeft>
                    </>
                ) : (
                    <>
                        <H3>{t('login.resetRequestSuccess')}</H3>
                        <BodyLeft>{t('login.resetRequestSuccessMessage')}</BodyLeft>
                    </>
                )}
            </LoginWrapper>
        </MainWrapper>
    );
};

export default ResetLinkView;
