import React, { useEffect, useReducer } from 'react';

import { useTranslation } from 'react-i18next';

import ModulesSubMenu, { navItemsEnergy } from 'components/Submenu/ModulesSubMenu';
import { SpaceSuggestion } from 'components/SearchAutoComplete';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import { useTenant } from 'context/TenantContext';
import { useSensorsByModulesData } from 'hooks/api/useSensorsByModulesData';

import { SensorSelection, Space } from '../types';
import { EnergyGACSAction, EnergyGACSState, MetaDataBoiler } from './types';
import { ModuleType } from 'types';
import SensorSelector from 'components/SensorSelector';
import GACSDataPane from 'components/DataPane/gacs/GACSDataPane';
import GACSReportPane from 'components/DataPane/gacs/GACSReportPane';
import { useParams } from 'react-router-dom';
import { DateRange, DateSpan } from 'utils/timePeriod';
import { routes } from 'routing/routes';

const EnergyGACSView: React.FunctionComponent = () => {
    const { buildingId: routeBuildingId, timespan: routeDateSpan } = useParams<{
        buildingId: string;
        timespan: DateSpan;
    }>();
    const { t } = useTranslation();
    const { getBuildingData, boilerMeta } = useTenant();
    const { data } = useSensorsByModulesData([ModuleType.ENERGY]);
    const [state, dispatch] = useReducer(EnergyGACSReducer, initialState);
    const {
        selectedBuilding,
        selectedGroup,
        equipOverview,
        suggestions,
        action,
        timespan,
        customDate,
        navigationItems,
        isLoading,
    } = state;

    useEffect(() => {
        if (data) {
            const buildingData = getBuildingData();
            let buildingStructure: Space[] = [];
            if (boilerMeta.length) {
                buildingStructure = buildingData.map(building => {
                    const allNestedRooms = flattenRooms(building.rooms);
                    const topLevelRooms = reconstructRooms(allNestedRooms, boilerMeta);
                    return { ...building, rooms: topLevelRooms };
                });
            }

            const preselectedBuilding = routeBuildingId
                ? buildingStructure.find(building => building.id === routeBuildingId)
                : null;

            const dateSpan = routeDateSpan
                ? DateSpan[routeDateSpan.toUpperCase() as keyof typeof DateSpan]
                : DateSpan.TWO_DAYS;

            dispatch({
                type: 'INIT_SENSORS',
                buildingData: getBuildingData(),
                boilerMetaData: boilerMeta,
                sensorDataEnergy: data[0],
                selectedBuilding: preselectedBuilding || selectedBuilding || null,
                timespan: dateSpan || DateSpan.TWO_DAYS,
                action: dateSpan || DateSpan.TWO_DAYS,
                buildingStructure,
            });
            window.history.replaceState({}, document.title, routes.energy.gacs);
        }
    }, [data, boilerMeta]);

    const handleSelectDate = (action: string, timespan: DateSpan, customDate: DateRange) => {
        dispatch({
            type: 'CHANGE_DATE_SPAN',
            action,
            timespan,
            customDate: customDate || { from: '', to: '' },
        });
    };

    return (
        <>
            {isLoading && <LoadingOverlay />}

            {!isLoading && (
                <ModulesSubMenu
                    subNavItems={navigationItems}
                    suggestions={suggestions}
                    breadCrumbs={selectedBuilding ? [selectedBuilding.name, selectedGroup?.name].filter(Boolean) : []}
                    onSearch={selectedSpace =>
                        dispatch({
                            type: 'SEARCH_SENSOR',
                            selectedSpace: selectedSpace,
                        })
                    }
                />
            )}

            {!isLoading && !equipOverview?.[0]?.spaces.length && (
                <div style={{ padding: '30px' }}>
                    <p>{t('energy.gacs.noGACSSensors')}</p>
                    <strong>
                        <p>{t('energy.gacs.noGACSMoreInfo')}</p>
                    </strong>
                </div>
            )}

            {Boolean(equipOverview?.[0]?.spaces.length) && (
                <SensorSelector
                    equips={equipOverview}
                    onEquipSelect={(selectAction, roomId) =>
                        dispatch({ type: selectAction as 'SELECT_SPACE1' | 'SELECT_SPACE2', spaceId: roomId })
                    }
                    collapsed={Boolean(selectedGroup) || Boolean(selectedBuilding)}
                />
            )}

            {selectedGroup ? (
                <>
                    <GACSDataPane
                        sensor={selectedGroup.sensors[0]}
                        action={action}
                        timespan={timespan}
                        customDate={customDate}
                        onSelectDateAction={handleSelectDate}
                    />
                </>
            ) : (
                <>
                    {selectedBuilding && (
                        <GACSReportPane
                            building={selectedBuilding}
                            groups={selectedBuilding.rooms}
                            action={action}
                            timespan={timespan}
                            customDate={customDate}
                            onSelectDateAction={handleSelectDate}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default EnergyGACSView;

export const initialState: EnergyGACSState = {
    selectedBuilding: null,
    selectedGroup: null,
    buildingStructure: [],
    suggestions: [],
    equipOverview: [],
    action: DateSpan.TWO_DAYS,
    timespan: DateSpan.TWO_DAYS,
    customDate: {
        from: '',
        to: '',
    },
    navigationItems: [],
    isLoading: true,
};

const EnergyGACSReducer = (state: EnergyGACSState, action: EnergyGACSAction): EnergyGACSState => {
    switch (action.type) {
        case 'INIT_SENSORS': {
            const suggestions: SpaceSuggestion[] = [];
            action.buildingStructure.forEach(building => {
                const location = [building.name];
                const ids = [building.id];
                const suggestion: SpaceSuggestion = {
                    name: building.name,
                    ids,
                    location,
                };
                suggestions.push(suggestion);
            });

            const showMetersNavItem = action.sensorDataEnergy.find(s => s.subMeterRoomRef !== null);

            return {
                ...state,
                navigationItems: showMetersNavItem
                    ? navItemsEnergy
                    : navItemsEnergy.filter(item => item.path !== routes.energy.submeters),
                action: action.action,
                isLoading: false,
                timespan: action.timespan,
                buildingStructure: action.buildingStructure,
                suggestions: suggestions,
                selectedBuilding: action.selectedBuilding,
                equipOverview: [
                    {
                        spaces: action.buildingStructure || [],
                        selectedSpace: action.selectedBuilding?.id || '',
                    },
                    {
                        spaces: action.selectedBuilding?.rooms || [],
                        selectedSpace: '',
                    },
                ],
            };
        }
        case 'SELECT_SPACE1': {
            const selectedBuilding = state.equipOverview[0].spaces.find(i => i.id === action.spaceId);

            return {
                ...state,
                selectedBuilding: selectedBuilding,
                selectedGroup: null,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: action.spaceId,
                    },
                    {
                        spaces: selectedBuilding.rooms,
                        selectedSpace: null,
                    },
                    {
                        spaces: [],
                        selectedSpace: '',
                    },
                ],
            };
        }
        case 'SELECT_SPACE2': {
            const selectedGroup = state.equipOverview[1].spaces.find(i => i.id === action.spaceId);

            return {
                ...state,
                selectedGroup: selectedGroup,
                equipOverview: [
                    state.equipOverview[0],
                    {
                        spaces: state.equipOverview[1].spaces,
                        selectedSpace: selectedGroup.id,
                    },
                    {
                        spaces: [],
                        selectedSpace: '',
                    },
                ],
            };
        }
        case 'SEARCH_SENSOR': {
            const sensorSelection = createSensorSelection(action.selectedSpace.ids);

            const selectedBuilding = state.equipOverview[0].spaces.find(i => i.id === sensorSelection.selectedId1);

            return {
                ...state,
                selectedBuilding: selectedBuilding,
                equipOverview: [
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                    {
                        spaces: state.equipOverview[0].spaces,
                        selectedSpace: sensorSelection?.selectedId1 ? sensorSelection.selectedId1 : '',
                    },
                ],
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : { from: '', to: '' };

            return {
                ...state,
                action: action.action,
                timespan: action.timespan,
                customDate,
            };
        }
    }
};

const createSensorSelection = (ids: string[]) => {
    const sensorSelection: SensorSelection = {
        selectedId1: '',
    };
    if (!ids) {
        return sensorSelection;
    }
    if (ids[0]) {
        sensorSelection['selectedId1'] = ids[0];
    }

    return sensorSelection;
};

const flattenRooms = (rooms: Space[], parent: Space | null = null): Space[] => {
    return rooms.reduce<Space[]>((acc, room) => {
        const clonedRoom = { ...room, parent, rooms: [] as any };
        acc.push(clonedRoom, ...flattenRooms(room.rooms, clonedRoom));
        return acc;
    }, []);
};

const reconstructRooms = (flattenedRooms: Space[], boilerMetaData: MetaDataBoiler[]): Space[] => {
    const markedRooms = flattenedRooms.map(room => ({
        ...room,
        sensors: boilerMetaData.find(meta => meta.equipId === room.id)?.sensors || [],
    }));
    const topLevelRooms = markedRooms
        .filter(room => room.sensors.length)
        .map(room => ({
            ...room,
            parent: undefined,
            moveToTop: undefined,
        }));

    return topLevelRooms;
};
