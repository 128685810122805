import React from 'react';

import { RadioButton, RadioButtonIcon, RadioIcon } from 'styled/components/buttons';

export interface RadioButtonGroupItem {
    label: string;
    action: string;
    icon?: string;
}

interface RadioButtonGroupInterface {
    radioButtonGroupItems: RadioButtonGroupItem[];
    selectedItem: string;
    selectedAction: (action: string) => void;
}

const RadioButtonGroup: React.FunctionComponent<RadioButtonGroupInterface> = ({
    radioButtonGroupItems,
    selectedItem = '',
    selectedAction,
}) => {
    const handleClick = (action: string) => {
        if (selectedItem !== action) {
            selectedAction(action);
        }
    };
    return (
        <>
            {radioButtonGroupItems.map((rb, i) => (
                <RadioButton key={i} active={rb.action === selectedItem} onClick={() => handleClick(rb.action)}>
                    {<RadioIcon active={rb.action === selectedItem} />}
                    {rb.label}
                    {rb.icon && <RadioButtonIcon $active={rb.action === selectedItem} src={rb.icon} />}
                </RadioButton>
            ))}
        </>
    );
};

export default RadioButtonGroup;
