import React, { FC, createContext, useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { useAuth } from 'context/AuthContext';
import { useAxiosClient } from './AxiosClientContext';

import { User } from './types';
import { config } from 'config';

declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}

export interface IUserProvider {
    clearUserState: () => void;
    userState: User | null;
}

const UserContext = createContext({} as IUserProvider);

interface UserProps {
    children?: React.ReactNode;
}
const UserProvider: FC<UserProps> = ({ children }) => {
    const { axiosClient } = useAxiosClient();
    const { dispatch, authState, setIsLoading } = useAuth();
    const [userState, setUserState] = useState<User | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        const fetchUser = () => {
            axiosClient
                .get<User>(config.oauth.user, {
                    baseURL: config.oauth.basePath,
                })
                .then(result => {
                    setUserState(result.data);
                    dispatch({ type: 'LOGIN_SUCCESS' });
                    window.dataLayer.push({
                        userId: result.data.sub,
                    });
                })
                .catch(() => {
                    dispatch({ type: 'LOGIN_ERROR', errorMessage: t('context.error') });
                })
                .finally(() => setIsLoading(false));
        };
        if (authState.is_authenticated && !userState) {
            fetchUser();
        }
    }, [authState.is_authenticated]);

    const clearUserState = () => {
        setUserState(null);
    };

    const userContextValue = {
        clearUserState,
        userState,
    };
    return <UserContext.Provider value={userContextValue}>{children}</UserContext.Provider>;
};

const useUser = () => {
    const context = React.useContext(UserContext);
    if (context === undefined) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export { UserProvider, useUser };
