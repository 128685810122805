import React, { FunctionComponent, useState, useEffect, useMemo, useReducer, useCallback } from 'react';

import { addDays, differenceInDays, endOfDay, format, max, min, startOfDay } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DataPaneMenu from '../../DataPaneMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import OccupancyChart from 'components/Graph/statistics/occupancy/OccupancyChart';
import DataPaneTitleMenu from 'components/DataPane/DataPaneTitleMenu';
import WeekOverview from 'components/WeekOverview';
import SpaceIndicator, { SpaceIndicator as SpaceIndicatorInterface } from 'components/SpaceIndicator';
import RoomTracker from 'components/RoomTracker';
import Occupancy from './infoHeader/Occupancy';
import WeekOverviewHeader from './infoHeader/WeekOverviewHeader';
import NoDataMessage from 'components/DataPane/NoDataMessage';
import { Hours } from 'components/WorkingHours';

import {
    ContentWrapper,
    SensorGraphWrapper,
    InfoHeader,
    OccupancyStatisticsPane,
    SpacesWrapper,
    LoadingWrapper,
    SpaceLoadingWrapper,
    WeekLoadingWrapper,
} from '../../../../styled/components/dataPane';

import { useOccupancyAverageData } from 'hooks/api/useOccupancyAverageData';
import { useOccupancyWeekData } from 'hooks/api/useOccupancyWeekData';
import { useOccupancyData } from 'hooks/api/useOccupancyData';

import { createFromToDate, DateSpan, DateRange, hourFormat, lastMonth, now } from 'utils/timePeriod';
import {
    createGraphData,
    sumAndAverageOccupancyData,
    sumAndAverageSensorAverage,
    spaceUsage,
    lastWeek,
    getMappedOpeningHours,
} from './occupancyStatistics.helper';

import { DataPaneAction, OccupancyDatapaneState } from './types';
import { SensorAverage, ModuleType, Sensor, SensorAvgFilter, SensorSubType } from 'types';

import occupancyIcon from '../../../../assets/icons/i-m-occupancy.svg';
import movementIcon from '../../../../assets/icons/i-s-movement.svg';
import calenderIcon from '../../../../assets/icons/icons-g-cal.svg';
import { createSensorGraphData } from 'components/DataPane/sensors/sensors.helpers';
import BarChartAverages from 'components/Graph/sensors/BarChartAverages';
import LineGraphAverages from 'components/Graph/sensors/LineGraphAverages';
import { GraphData } from 'components/Graph/types';
import { getAvgFilterByModule } from 'components/DataPane/incidents/incidents.helpers';

interface OccupancyStatsDataPaneProps {
    openingHours: Hours;
    selectedBuilding: string;
    selectedRoom: string;
    occupancySensors: Sensor[];
    onExpand: (isExpanded: boolean) => void;
}
const OccupancyStatsDataPane: FunctionComponent<OccupancyStatsDataPaneProps> = ({
    openingHours,
    selectedBuilding,
    selectedRoom,
    occupancySensors,
    onExpand,
}) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [state, dispatch] = useReducer(dataPaneReducer, initialState);
    const {
        action,
        customDate,
        occupancyDataParams,
        motionGraphData,
        weekOverviewData,
        leastUsedSpaces,
        mostUsedSpaces,
        averageUsage,
        indicatorSpaceId,
        currentOccupancy,
        currentOccupancyHour,
        isBarChart,
        selectedSensor,
        countGraphData,
    } = state;

    const openClosedHours = useMemo(() => getMappedOpeningHours(openingHours), [openingHours]);
    const { data, isLoading } = useOccupancyData(occupancyDataParams, occupancySensors);
    const { data: averageData, isLoading: averageIsLoading } = useOccupancyAverageData(
        occupancyDataParams,
        occupancySensors
    );
    const { data: weekData, isLoading: weekIsLoading } = useOccupancyWeekData(occupancyDataParams);

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'SET_DATA',
                data: data.occupancyData,
                averageData: averageData?.occupancyAvgData || [],
                countData: data.occupancyCountData,
                averageCountData: averageData?.occupancyCountAvgData || [],
                openClosedHours,
                occupancySensors,
            });
        }
    }, [data, averageData]);

    useEffect(() => {
        if (weekData) {
            dispatch({
                type: 'SET_WEEK_OVERVIEW_DATA',
                weekOverviewData: weekData,
            });
        }
    }, [weekData]);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_BUILDING_SELECTION',
            selectedOccupancyBuilding: selectedBuilding,
        });
    }, [selectedBuilding]);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_ROOM_SELECTION',
            selectedOccupancyRoom: selectedRoom,
        });
    }, [selectedRoom]);

    const expand = useCallback(
        (expand: boolean) => {
            setIsExpanded(expand);
            onExpand(expand);
        },
        [onExpand]
    );

    const getLastKpiUpdateTimestamp = useCallback(() => {
        const dates: Date[] = [];
        occupancySensors
            .filter(
                sensor =>
                    sensor.buildingRef === occupancyDataParams.selectedOccupancyBuilding &&
                    (occupancyDataParams.selectedOccupancyRoom
                        ? sensor.roomRef === occupancyDataParams.selectedOccupancyRoom
                        : true)
            )
            .forEach(sensor => dates.push(new Date(sensor.latestKpi.time)));

        return dates.length ? max(dates) : '';
    }, [occupancySensors, occupancyDataParams]);

    const getFirstDataTimestamp = useCallback(() => {
        const dates: Date[] = [];
        occupancySensors
            .filter(
                sensor =>
                    sensor.buildingRef === occupancyDataParams.selectedOccupancyBuilding &&
                    (occupancyDataParams.selectedOccupancyRoom
                        ? sensor.roomRef === occupancyDataParams.selectedOccupancyRoom
                        : true)
            )
            .forEach(sensor => dates.push(new Date(sensor.dataFlowStart)));

        return dates.length ? min(dates) : '';
    }, [occupancySensors, occupancyDataParams]);

    const onToggleGraph = (isBarChart: boolean) => {
        dispatch({ type: 'TOGGLE_CHART', isBarChart });
    };

    return (
        <OccupancyStatisticsPane isExpanded={isExpanded}>
            {(Boolean(occupancyDataParams.selectedOccupancyBuilding) ||
                Boolean(occupancyDataParams.selectedOccupancyRoom)) && (
                <DataPaneMenu
                    customDate={customDate}
                    title={t('occupancy.datapane.occupancyTitle')}
                    icon={occupancyIcon}
                    selectedAction={action}
                    onSelectAction={(action, timespan, customDate) =>
                        dispatch({
                            type: 'CHANGE_DATE_SPAN',
                            action,
                            timespan,
                            customDate: customDate || { from: '', to: '' },
                        })
                    }
                    module={ModuleType.OCCUPANCY}
                    canExpand={true}
                    expand={isExpanded}
                    onExpand={expand}
                />
            )}

            <ContentWrapper>
                {isLoading && (
                    <SpaceLoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </SpaceLoadingWrapper>
                )}
                {!isLoading && mostUsedSpaces.length === 0 && (
                    <SpaceLoadingWrapper>
                        <NoDataMessage
                            firstDataReceivedDate={getFirstDataTimestamp()}
                            lastDataReceivedDate={getLastKpiUpdateTimestamp()}
                        />
                    </SpaceLoadingWrapper>
                )}
                {!isLoading && mostUsedSpaces.length && (
                    <SpacesWrapper>
                        <RoomTracker currentOccupancy={currentOccupancy} currentOccupancyHour={currentOccupancyHour} />
                        <SpaceIndicator
                            leastUsedSpaces={leastUsedSpaces}
                            mostUsedSpaces={mostUsedSpaces}
                            onSpaceSelection={spaceId =>
                                dispatch({
                                    type: 'SELECT_INDICATOR',
                                    id: spaceId,
                                    openClosedHours,
                                })
                            }
                        />
                    </SpacesWrapper>
                )}
            </ContentWrapper>
            {isExpanded && (
                <>
                    <DataPaneTitleMenu
                        title={t('occupancy.datapane.dailyOccupancyTitle')}
                        icon={movementIcon}
                        canToggleGraph={true}
                        isBarChart={isBarChart}
                        onToggleGraph={onToggleGraph}
                    />
                    <ContentWrapper>
                        {(isLoading || motionGraphData === null) && (
                            <LoadingWrapper>
                                <LoadingOverlay dark={true} isSmall={true} />
                            </LoadingWrapper>
                        )}
                        {!isLoading && motionGraphData?.data.length === 0 && (
                            <LoadingWrapper>
                                <NoDataMessage
                                    firstDataReceivedDate={getFirstDataTimestamp()}
                                    lastDataReceivedDate={getLastKpiUpdateTimestamp()}
                                />
                            </LoadingWrapper>
                        )}
                        {!isLoading && motionGraphData?.data.length && (
                            <>
                                <InfoHeader>
                                    {countGraphData?.data.length === 0 ? (
                                        <Occupancy
                                            occupancy={getAverageUsage(averageUsage, indicatorSpaceId, [
                                                ...leastUsedSpaces,
                                                ...mostUsedSpaces,
                                            ])}
                                            isLoading={averageIsLoading}
                                        />
                                    ) : (
                                        <Occupancy
                                            occupancy={getAverageCount(countGraphData)}
                                            isLoading={averageIsLoading}
                                        />
                                    )}
                                </InfoHeader>
                                {countGraphData?.data.length === 0 && (
                                    <SensorGraphWrapper>
                                        <OccupancyChart graphData={motionGraphData} isBarChart={isBarChart} />
                                    </SensorGraphWrapper>
                                )}
                                {countGraphData?.data.length && (
                                    <SensorGraphWrapper>
                                        {isBarChart && countGraphData ? (
                                            <BarChartAverages sensor={selectedSensor} graphData={countGraphData} />
                                        ) : (
                                            <LineGraphAverages sensor={selectedSensor} graphData={countGraphData} />
                                        )}
                                    </SensorGraphWrapper>
                                )}
                            </>
                        )}
                    </ContentWrapper>
                    <DataPaneTitleMenu title={t('occupancy.datapane.busyQuietTitle')} icon={calenderIcon} />
                    <ContentWrapper>
                        {weekIsLoading && (
                            <WeekLoadingWrapper>
                                <LoadingOverlay dark={true} isSmall={true} />
                            </WeekLoadingWrapper>
                        )}
                        {!weekIsLoading && (
                            <>
                                <WeekOverviewHeader customDate={customDate} action={action} />
                                <WeekOverview openingHours={openingHours} hoursOfDayOfWeek={weekOverviewData} />
                            </>
                        )}
                    </ContentWrapper>
                </>
            )}
        </OccupancyStatisticsPane>
    );
};

export default OccupancyStatsDataPane;

const initialState: OccupancyDatapaneState = {
    occupancyDataParams: {
        from: createFromToDate(DateSpan.TWO_DAYS).from,
        to: createFromToDate(DateSpan.TWO_DAYS).to,
        averageFrom: lastWeek,
        averageTo: now,
        selectedOccupancyBuilding: '',
        selectedOccupancyRoom: '',
        averageFilter: SensorAvgFilter.HOUR_OF_DAY_OF_WEEK,
    },
    countGraphData: null,
    selectedSensor: {} as Sensor,
    occupancyData: [],
    averageOccupancyData: [],
    rawWeekOverviewData: [],
    motionGraphData: null,
    weekOverviewData: [],
    action: DateSpan.TWO_DAYS,
    mostUsedSpaces: [],
    leastUsedSpaces: [],
    averageUsage: 0,
    indicatorSpaceId: '',
    currentOccupancy: 0,
    currentOccupancyHour: '00:00',
    isBarChart: true,
    timespan: DateSpan.TWO_DAYS,
    customDate: {
        from: '',
        to: '',
    },
};

const dataPaneReducer = (state: OccupancyDatapaneState, action: DataPaneAction): OccupancyDatapaneState => {
    switch (action.type) {
        case 'SET_DATA': {
            const { leastUsedSpaces, mostUsedSpaces, averageUsage } = spaceUsage(
                action.openClosedHours,
                action.data,
                action.occupancySensors,
                state.indicatorSpaceId
            );
            const selectedOccupancyData = state.indicatorSpaceId
                ? action.data.filter(data => data.roomId === state.indicatorSpaceId)
                : action.data;
            const occupancyData = sumAndAverageOccupancyData(selectedOccupancyData);

            const currentOccupancy =
                occupancyData.length && state.action !== DateSpan.CUSTOM
                    ? Math.ceil(occupancyData[occupancyData.length - 1].occupied)
                    : state.currentOccupancy;
            const currentOccupancyHour =
                occupancyData.length && state.action !== DateSpan.CUSTOM
                    ? format(new Date(occupancyData[occupancyData.length - 1].date), hourFormat)
                    : state.currentOccupancyHour;

            const sensorData = occupancyData.map(d => ({
                value: d.occupied,
                field: d.date,
            })) as SensorAverage[];

            const selectedAverageData = state.indicatorSpaceId
                ? action.averageData.filter(data => data.roomId === state.indicatorSpaceId)
                : action.averageData.filter(data =>
                      selectedOccupancyData.find(occupancyData => occupancyData.roomId === data.roomId)
                  );
            const averageData = sumAndAverageSensorAverage(selectedAverageData);

            const motionGraphData = createGraphData(
                sensorData,
                averageData,
                state.timespan,
                state.action,
                state.customDate,
                action.openClosedHours
            );

            const countGraphData = createSensorGraphData(
                action.countData,
                state.timespan,
                state.action,
                state.customDate,
                action.averageCountData,
                state.isBarChart
            );

            const selectedWeekOverviewData = state.indicatorSpaceId
                ? state.rawWeekOverviewData.filter(data => data.roomId === state.indicatorSpaceId)
                : state.rawWeekOverviewData;
            const weekOverviewData = sumAndAverageSensorAverage(selectedWeekOverviewData);

            const selectedOccupancyCountSensors = action.occupancySensors.filter(
                sensor =>
                    sensor.buildingRef === state.occupancyDataParams.selectedOccupancyBuilding &&
                    sensor.subType === SensorSubType.COUNT &&
                    (state.occupancyDataParams.selectedOccupancyRoom
                        ? sensor.roomRef === state.occupancyDataParams.selectedOccupancyRoom
                        : false)
            );
            const selectedSensor = selectedOccupancyCountSensors.length
                ? selectedOccupancyCountSensors[0]
                : ({} as Sensor);

            const isBarChart = !selectedOccupancyCountSensors.length;
            return {
                ...state,
                occupancyData: action.data,
                averageOccupancyData: action.averageData,
                countGraphData: countGraphData,
                weekOverviewData: weekOverviewData,
                motionGraphData: motionGraphData,
                mostUsedSpaces: mostUsedSpaces,
                leastUsedSpaces: leastUsedSpaces,
                averageUsage: averageUsage,
                currentOccupancy: currentOccupancy,
                currentOccupancyHour: currentOccupancyHour,
                selectedSensor: selectedSensor,
                isBarChart: isBarChart,
            };
        }
        case 'SET_WEEK_OVERVIEW_DATA': {
            const selectedWeekOverviewData = state.indicatorSpaceId
                ? action.weekOverviewData.filter(data => data.roomId === state.indicatorSpaceId)
                : action.weekOverviewData;
            const weekOverviewData = sumAndAverageSensorAverage(selectedWeekOverviewData);

            return {
                ...state,
                rawWeekOverviewData: action.weekOverviewData,
                weekOverviewData: weekOverviewData,
            };
        }
        case 'SELECT_INDICATOR': {
            const spaceId = action.id === state.indicatorSpaceId ? '' : action.id;
            const mostUsedSpaces = state.mostUsedSpaces.map(space => disableSpace(space, spaceId));
            const leastUsedSpaces = state.leastUsedSpaces.map(space => disableSpace(space, spaceId));

            const selectedOccupancyData = spaceId
                ? state.occupancyData.filter(data => data.roomId === spaceId)
                : state.occupancyData;
            const occupancyData = sumAndAverageOccupancyData(selectedOccupancyData);

            const currentOccupancy =
                occupancyData.length && state.action !== DateSpan.CUSTOM
                    ? Math.ceil(occupancyData[occupancyData.length - 1].occupied)
                    : state.currentOccupancy;
            const currentOccupancyHour =
                occupancyData.length && state.action !== DateSpan.CUSTOM
                    ? format(new Date(occupancyData[occupancyData.length - 1].date), hourFormat)
                    : state.currentOccupancyHour;

            const sensorData = occupancyData.map(d => ({
                value: d.occupied,
                field: d.date,
            })) as SensorAverage[];

            const selectedAverageData = spaceId
                ? state.averageOccupancyData.filter(data => data.roomId === spaceId)
                : state.averageOccupancyData.filter(data =>
                      selectedOccupancyData.find(occupancyData => occupancyData.roomId === data.roomId)
                  );
            const averageData = sumAndAverageSensorAverage(selectedAverageData);

            const graphData = createGraphData(
                sensorData,
                averageData,
                state.timespan,
                state.action,
                state.customDate,
                action.openClosedHours
            );

            const selectedWeekOverviewData = spaceId
                ? state.rawWeekOverviewData.filter(data => data.roomId === spaceId)
                : state.rawWeekOverviewData;
            const weekOverviewData = sumAndAverageSensorAverage(selectedWeekOverviewData);

            return {
                ...state,
                indicatorSpaceId: spaceId,
                motionGraphData: graphData,
                weekOverviewData: weekOverviewData,
                mostUsedSpaces,
                leastUsedSpaces,
                currentOccupancy: currentOccupancy,
                currentOccupancyHour: currentOccupancyHour,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : state.customDate;
            const averageFrom =
                action.action === DateSpan.CUSTOM
                    ? getCustomAverageFrom(action.customDate)
                    : action.timespan === DateSpan.MONTH
                    ? lastMonth
                    : lastWeek;
            const averageTo = action.action === DateSpan.CUSTOM ? getCustomAverageTo(action.customDate) : now;
            const averageFilter = getAvgFilterByModule(action.timespan, state.isBarChart);

            return {
                ...state,
                action: action.action,
                timespan: action.timespan,
                customDate,
                motionGraphData: null,
                occupancyDataParams: { ...state.occupancyDataParams, from, to, averageFrom, averageTo, averageFilter },
            };
        }
        case 'CHANGE_BUILDING_SELECTION': {
            return {
                ...state,
                motionGraphData: null,
                indicatorSpaceId: '',
                occupancyData: [],
                averageOccupancyData: [],
                mostUsedSpaces: [],
                leastUsedSpaces: [],
                occupancyDataParams: {
                    ...state.occupancyDataParams,
                    selectedOccupancyBuilding: action.selectedOccupancyBuilding,
                    selectedOccupancyRoom: '',
                },
            };
        }
        case 'CHANGE_ROOM_SELECTION': {
            return {
                ...state,
                motionGraphData: null,
                indicatorSpaceId: '',
                occupancyData: [],
                averageOccupancyData: [],
                mostUsedSpaces: [],
                leastUsedSpaces: [],
                occupancyDataParams: {
                    ...state.occupancyDataParams,
                    selectedOccupancyRoom: action.selectedOccupancyRoom,
                },
            };
        }
        case 'TOGGLE_CHART':
            return {
                ...state,
                isBarChart: action.isBarChart,
            };
    }
};

const disableSpace = (space: SpaceIndicatorInterface, id: string) => {
    return {
        ...space,
        isDisabled: id === '' ? false : space.id !== id,
    };
};

const getCustomAverageFrom = (customDate: DateRange): string => {
    const startDate = new Date(customDate.from);

    if (differenceInDays(new Date(), startDate) < 7) {
        return lastWeek;
    }

    return startOfDay(startDate).toISOString();
};

const getCustomAverageTo = (customDate: DateRange): string => {
    const startDate = new Date(customDate.from);
    const endDate = new Date(customDate.to);

    if (differenceInDays(endDate, startDate) > 6) {
        return customDate.to;
    }

    if (differenceInDays(new Date(), startDate) < 7) {
        return now;
    }

    return endOfDay(addDays(startDate, 7)).toISOString();
};

const getAverageUsage = (averageUsage: number, id: string, spaces: SpaceIndicatorInterface[]) => {
    return id ? `${spaces.find(s => s.id === id).percentage}%` : `${averageUsage.toFixed(0)}%`;
};

const getAverageCount = (countGraphData: GraphData) => {
    const sensorValues = countGraphData.data.map(a => a.value);

    return (sensorValues.reduce((a, b) => a + b, 0) / sensorValues.length).toFixed(1);
};
