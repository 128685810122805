import React from 'react';

import { useTranslation } from 'react-i18next';

import { getThreshold } from 'styled/components/card';
import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon } from 'styled/components/dataPane';

import occupancyCircle from '../../../../assets/icons/i-circle-o.svg';

import { formatDuration } from 'utils/formatDuration';
import { Incident } from 'types';

interface InfoHeaderProp {
    incident: Incident;
}
const WaterLowOccupancyConsumption: React.FunctionComponent<InfoHeaderProp> = ({ incident }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{getThreshold(incident)}</H2White>
                <CaptionLefWhite>{t('infoHeader.common.detected')}</CaptionLefWhite>
            </div>
            <div>
                {formatDuration(incident.duration) && (
                    <>
                        <H2White>{formatDuration(incident.duration)}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.water.durationOfLow')}
                            {t('infoHeader.occupancy.occupancy')} <br />
                        </CaptionLefWhite>
                    </>
                )}
            </div>
            <div>
                <LegendIcon src={occupancyCircle} />
                <CaptionLefWhite>{t('infoHeader.occupancy.occupancy')}</CaptionLefWhite>
            </div>
        </>
    );
};

export default WaterLowOccupancyConsumption;
