import styled, { css, keyframes } from 'styled-components';
import { ColorMode, theme } from 'styled/Theme';
import arrowDownIcon from '../../assets/icons/i-arrow-down.svg';

const onAutoFillStart = keyframes``;
const onAutoFillCancel = keyframes`{}`;

interface InputInterface {
    hasError: boolean;
    active: boolean;
    mode?: ColorMode;
}
export const StyledInput = styled.div<InputInterface>`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;

    input {
        background-color: ${({ mode }) => (mode === ColorMode.DARK ? theme.colors.text : theme.colors.white)};
        padding: 24px 0px 4px 15px;
        width: 100%;
        margin-top: 12px;
        line-height: 25px;
        font-size: 1em;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid ${theme.colors.grey};
        color: ${({ mode }) => (mode === ColorMode.DARK ? theme.colors.grey : theme.colors.text)};
        &:-webkit-credentials-auto-fill-button {
            background-color: white !important;
        }
        :-webkit-autofill {
            animation-name: ${onAutoFillStart};
            transition: background-color 50000s ease-in-out 0s;
        }
        :not(:-webkit-autofill) {
            animation-name: ${onAutoFillCancel};
        }

        ${({ hasError }) => {
            return hasError
                ? css`
                      -webkit-box-shadow: 0 0 0 50px ${theme.colors.negativeLHighlight} inset;
                      border: 1px solid ${theme.colors.negative};
                  `
                : ``;
        }}
    }

    input[type='date']::-webkit-calendar-picker-indicator {
        margin-right: 5px;
    }

    input::-webkit-datetime-edit-fields-wrapper {
        line-height: normal;
    }

    label {
        position: absolute;
        pointer-events: none;
        color: ${theme.colors.grey};
        font-size: 14px;
        line-height: 1;
        left: 15px;
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        transform: translate(0, 36px) scale(1);
        ${({ active, mode, hasError }) =>
            active
                ? css`
                      color: ${hasError
                          ? theme.colors.negative
                          : mode === ColorMode.DARK
                          ? theme.colors.grey
                          : theme.colors.text};
                      transform: translate(0, 27px) scale(0.8);
                  `
                : ``}
    }
`;

export const StyledTextarea = styled.div<InputInterface>`
    position: relative;
    display: flex;
    flex-direction: column;

    textarea {
        background-color: ${({ mode }) => (mode === ColorMode.DARK ? theme.colors.text : theme.colors.white)};
        padding: 24px 0px 4px 15px;
        width: 100%;
        resize: none;
        height: 160px;
        margin-top: 12px;
        line-height: 25px;
        font-size: 1em;
        box-sizing: border-box;
        border-radius: 4px;
        color: ${({ mode }) => (mode === ColorMode.DARK ? theme.colors.grey : theme.colors.text)};
        border: 1px solid ${theme.colors.grey};
        &:-webkit-credentials-auto-fill-button {
            background-color: white !important;
        }
        :-webkit-autofill {
            animation-name: ${onAutoFillStart};
            transition: background-color 50000s ease-in-out 0s;
        }
        :not(:-webkit-autofill) {
            animation-name: ${onAutoFillCancel};
        }
        ${({ hasError }) => {
            return hasError
                ? css`
                      -webkit-box-shadow: 0 0 0 50px ${theme.colors.negativeLHighlight} inset;
                      border: 1px solid ${theme.colors.negative};
                  `
                : ``;
        }}
    }

    label {
        position: absolute;
        pointer-events: none;
        color: ${theme.colors.grey};
        font-size: 14px;
        line-height: 1;
        left: 15px;
        transform-origin: top left;
        transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        transform: translate(0, 36px) scale(1);
        ${({ active, mode, hasError }) =>
            active
                ? css`
                      color: ${hasError
                          ? theme.colors.negative
                          : mode === ColorMode.DARK
                          ? theme.colors.grey
                          : theme.colors.text};
                      transform: translate(0, 27px) scale(0.8);
                  `
                : ``}
    }
`;

export const StyledSelect = styled.div`
    position: relative;

    select {
        padding: 5px 45px 5px 15px;
        font-size: 1.2rem;
        line-height: 1.4rem;
        height: 50px;
        background-color: white;
        width: 100%;
        line-height: 25px;
        font-size: 1em;
        box-sizing: border-box;
        border-radius: 4px;
        border: 1px solid ${theme.colors.grey};
        &:-webkit-credentials-auto-fill-button {
            background-color: white !important;
        }
        :-webkit-autofill {
            animation-name: ${onAutoFillStart};
            transition: background-color 50000s ease-in-out 0s;
        }
        :not(:-webkit-autofill) {
            animation-name: ${onAutoFillCancel};
        }
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-image: url(${arrowDownIcon});
        background-repeat: no-repeat;
        background-position: right 8px center;
    }
`;
