import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorAverage, WeatherResponse } from 'types';

import { TemperatureDataParam } from 'components/DataPane/statistics/energy/types';

export const useTemperatureData = (temperatureParams: TemperatureDataParam) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        [
            'temperature',
            temperatureParams.city,
            temperatureParams.from,
            temperatureParams.to,
            temperatureParams.averageFilter,
        ],
        async ({ signal }): Promise<SensorAverage[]> => {
            const { data } = await axiosClient.get<WeatherResponse>(
                config.temperature(
                    temperatureParams.city,
                    temperatureParams.from,
                    temperatureParams.to,
                    temperatureParams.averageFilter
                ),
                {
                    signal,
                }
            );

            return data.data.map(d => ({
                value: d.t,
                field: d.d,
            }));
        },
        {
            enabled: Boolean(temperatureParams.city),
        }
    );
};
