import { useQuery } from 'react-query';
import { startOfMonth, subMonths, endOfHour, endOfMonth, format } from 'date-fns';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { ModuleType } from 'types';
import { AirMetricsItem, AnomaliesItem, FaciliteItem, Facilitie, MetricsItem } from 'components/FacilitieCard/types';

import { DateSpan, dateFormat } from 'utils/timePeriod';

export const useMetricsData = (period: string) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['metrics', period], async ({ signal }): Promise<FaciliteItem[]> => {
        const promiseArray = createEndpoints(period).map(url => axiosClient.get(url, { signal }));
        const result = await Promise.all(promiseArray);

        const anomalies = (result[0]?.data || []) as AnomaliesItem[];
        const metrics = (result[1]?.data || []) as MetricsItem[];
        const occupancyMetrics = (result[2]?.data || []) as AirMetricsItem[];
        const airMetrics = (result[3]?.data || []) as AirMetricsItem[];

        return merge([...anomalies, ...metrics, ...occupancyMetrics, ...airMetrics]);
    });
};

const createFromToDate = (period: string) => {
    let fromTo;
    switch (period) {
        case DateSpan.CURRENT_MONTH:
            fromTo = {
                from: format(startOfMonth(new Date()), dateFormat),
                to: format(endOfHour(new Date()), dateFormat),
            };
            break;
        case DateSpan.PREVIOUS_MONTH:
            fromTo = {
                from: format(startOfMonth(subMonths(endOfHour(new Date()), 1)), dateFormat),
                to: format(endOfMonth(subMonths(endOfHour(new Date()), 1)), dateFormat),
            };
            break;
        default:
            fromTo = {
                from: format(startOfMonth(new Date()), dateFormat),
                to: format(endOfHour(new Date()), dateFormat),
            };
            break;
    }

    return fromTo;
};

const createEndpoints = (period: string) => {
    return [
        config.anomaliesOverview(createFromToDate(period).from, createFromToDate(period).to),
        config.metricsOverview(createFromToDate(period).from, createFromToDate(period).to),
        config.metricsOccupancy(createFromToDate(period).from, createFromToDate(period).to),
        config.metricsAir(createFromToDate(period).from, createFromToDate(period).to),
    ];
};

const merge = (arrays: Facilitie[]): FaciliteItem[] => {
    const merged: { [key: string]: FaciliteItem } = {};

    arrays.forEach(o => Object.assign((merged[o.id] ??= {} as FaciliteItem), o));

    return Object.values(merged).filter(
        o =>
            o.electricity.consumption ||
            o.gas.consumption ||
            o.occupancyAvg ||
            o.airQualityScore ||
            o.anomalies.filter(a => a.type !== ('SensorIssues' as ModuleType)).length
    );
};
