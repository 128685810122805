import React, { FunctionComponent, useCallback, useEffect, useReducer, useState } from 'react';

import { max, min } from 'date-fns';
import { useTranslation } from 'react-i18next';

import DataPaneMenu from '../../DataPaneMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import ConsumptionChart from 'components/Graph/statistics/water/ConsumptionChart';
import OccupancyChart from 'components/Graph/statistics/water/OccupancyChart';
import DataPaneTitleMenu from 'components/DataPane/DataPaneTitleMenu';
import NoDataMessage from 'components/DataPane/NoDataMessage';
import WaterOccupancy from './infoHeader/WaterOccupancy';
import WaterConsumption from './infoHeader/WaterConsumption';

import { ContentWrapper, SensorGraphWrapper, InfoHeader, StatisticsPane } from '../../../../styled/components/dataPane';

import { createFromToDate, DateSpan } from 'utils/timePeriod';
import { isEmpty } from 'utils/isEmptyObject';
import { createGraphData, createMotionGraphData } from './waterStatistics.helper';
import { getAvgFilter } from 'components/DataPane/sensors/sensors.helpers';

import { useSensorSumTotalData } from 'hooks/api/useSensorSumTotalData';
import { useSensorsAverageTotalData } from 'hooks/api/useSensorAverageTotalData';

import { DataPaneAction, DatapaneState } from './types';
import { Sensor, SensorAvgFilter, ModuleType } from 'types';
import { SensorValue } from 'components/Graph/types';

import waterIcon from '../../../../assets/icons/i-m-water.svg';

interface WaterStatsDataPaneProps {
    waterSensors: Sensor[];
    motionSensors: Sensor[];
    onExpand: (isExpanded: boolean) => void;
}
const WaterStatsDataPane: FunctionComponent<WaterStatsDataPaneProps> = ({ waterSensors, motionSensors, onExpand }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [state, dispatch] = useReducer(dataPaneReducer, initialState);
    const {
        action,
        customDate,
        selectedWaterSensors,
        waterSensorDataParams,
        motionSensorDataParams,
        waterGraphData,
        motionGraphData,
        isBarChart,
    } = state;

    const { data: waterData, isLoading: isWaterLoading } = useSensorSumTotalData({
        ...waterSensorDataParams,
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
    });
    const { data: averageData, isLoading: isAverageLoading } = useSensorsAverageTotalData(waterSensorDataParams);
    const { data: motionData, isLoading: isMotionLoading } = useSensorSumTotalData(motionSensorDataParams, isExpanded);

    useEffect(() => {
        if (waterData) {
            dispatch({ type: 'SET_WATERGRAPH_DATA', waterData, averageData: averageData || [] });
        }
    }, [waterData, averageData]);

    useEffect(() => {
        if (waterData) {
            dispatch({ type: 'SET_MOTIONGRAPH_DATA', waterData, motionData: motionData || [] });
        }
    }, [waterData, motionData]);

    useEffect(() => {
        dispatch({
            type: 'CHANGE_SENSOR_SELECTION',
            selectedWaterSensors: waterSensors,
            selectedMotionSensors: motionSensors,
        });
    }, [waterSensors]);

    const expand = useCallback(
        (expand: boolean) => {
            setIsExpanded(expand);
            onExpand(expand);
        },
        [onExpand]
    );

    const getLastKpiUpdateTimestamp = useCallback(() => {
        const dates: Date[] = [];
        selectedWaterSensors.forEach(sensor => dates.push(new Date(sensor.latestKpi.time)));

        return max(dates);
    }, [selectedWaterSensors]);

    const getFirstDataTimestamp = useCallback(() => {
        const dates: Date[] = [];
        selectedWaterSensors.forEach(sensor => dates.push(new Date(sensor.dataFlowStart)));

        return min(dates);
    }, [selectedWaterSensors]);

    const onToggleGraph = (isBarChart: boolean) => {
        dispatch({ type: 'TOGGLE_CHART', isBarChart });
    };

    return (
        <StatisticsPane isExpanded={isExpanded}>
            {!isEmpty(selectedWaterSensors) && (
                <DataPaneMenu
                    customDate={customDate}
                    title={t('water.datapane.usageTitle')}
                    icon={waterIcon}
                    selectedAction={action}
                    onSelectAction={(action, timespan, customDate) =>
                        dispatch({
                            type: 'CHANGE_DATE_SPAN',
                            action,
                            timespan,
                            customDate: customDate || { from: '', to: '' },
                        })
                    }
                    module={ModuleType.WATER}
                    canExpand={Boolean(motionSensors.length)}
                    expand={isExpanded}
                    onExpand={expand}
                    canToggleGraph={true}
                    isBarChart={isBarChart}
                    onToggleGraph={onToggleGraph}
                />
            )}

            <ContentWrapper>
                {isWaterLoading && <LoadingOverlay dark={true} isSmall={true} />}
                {!isWaterLoading && !waterGraphData?.data.length && (
                    <NoDataMessage
                        firstDataReceivedDate={getFirstDataTimestamp()}
                        lastDataReceivedDate={getLastKpiUpdateTimestamp()}
                    />
                )}
                {!isWaterLoading && waterGraphData?.data.length && (
                    <>
                        <InfoHeader>
                            <WaterConsumption
                                consumption={`${calculateUsage(waterGraphData.data)} L`}
                                isLoading={isAverageLoading}
                            />
                        </InfoHeader>
                        <SensorGraphWrapper>
                            <ConsumptionChart graphData={waterGraphData} isBarChart={isBarChart} />
                        </SensorGraphWrapper>
                    </>
                )}
            </ContentWrapper>
            {isExpanded && (
                <>
                    <DataPaneTitleMenu title={t('water.datapane.occupancyTitle')} icon={waterIcon} />
                    <ContentWrapper>
                        {isWaterLoading && <LoadingOverlay dark={true} isSmall={true} />}
                        {!isWaterLoading && !motionGraphData?.data.length && (
                            <NoDataMessage
                                firstDataReceivedDate={getFirstDataTimestamp()}
                                lastDataReceivedDate={getLastKpiUpdateTimestamp()}
                            />
                        )}
                        {!isWaterLoading && motionGraphData?.data.length && (
                            <>
                                <InfoHeader>
                                    <WaterOccupancy
                                        consumption={`${calculateUsage(motionGraphData.data)} L`}
                                        isLoading={isMotionLoading}
                                    />
                                </InfoHeader>
                                <SensorGraphWrapper>
                                    <OccupancyChart graphData={motionGraphData} />
                                </SensorGraphWrapper>
                            </>
                        )}
                    </ContentWrapper>
                </>
            )}
        </StatisticsPane>
    );
};

export default WaterStatsDataPane;

const initialState: DatapaneState = {
    waterSensorDataParams: {
        averageFilter: SensorAvgFilter.HOUR,
        from: '',
        to: '',
        ids: [],
    },
    motionSensorDataParams: {
        averageFilter: SensorAvgFilter.HOUR_OF_DAY,
        from: '',
        to: '',
        ids: [],
    },
    waterGraphData: null,
    motionGraphData: null,
    selectedWaterSensors: [],
    selectedMotionSensors: [],
    action: DateSpan.TWO_DAYS,
    isBarChart: true,
    timespan: DateSpan.TWO_DAYS,
    customDate: {
        from: '',
        to: '',
    },
};

const dataPaneReducer = (state: DatapaneState, action: DataPaneAction): DatapaneState => {
    switch (action.type) {
        case 'SET_WATERGRAPH_DATA': {
            const waterGraphData = createGraphData(
                action.waterData,
                action.averageData,
                state.timespan,
                state.action,
                state.customDate,
                state.isBarChart
            );
            return {
                ...state,
                waterGraphData,
            };
        }
        case 'SET_MOTIONGRAPH_DATA': {
            const motionGraphData = createMotionGraphData(
                action.waterData,
                action.motionData,
                state.timespan,
                state.action,
                state.customDate
            );
            return {
                ...state,
                motionGraphData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const averageFilter = getAvgFilter(action.timespan, state.isBarChart);
            const { from, to } =
                action.action === DateSpan.CUSTOM ? action.customDate : createFromToDate(action.timespan);
            const customDate = action.action === DateSpan.CUSTOM ? action.customDate : state.customDate;
            return {
                ...state,
                action: action.action,
                timespan: action.timespan,
                customDate,
                waterGraphData: null,
                motionGraphData: null,
                waterSensorDataParams: { ...state.waterSensorDataParams, from, to, averageFilter },
                motionSensorDataParams: { ...state.motionSensorDataParams, from, to },
            };
        }
        case 'CHANGE_SENSOR_SELECTION': {
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            return {
                ...state,
                selectedWaterSensors: action.selectedWaterSensors,
                waterGraphData: null,
                motionGraphData: null,
                waterSensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, state.isBarChart),
                    from,
                    to,
                    ids: action.selectedWaterSensors.map(sensor => sensor.id),
                },
                motionSensorDataParams: {
                    averageFilter: SensorAvgFilter.HOUR_OF_DAY,
                    from,
                    to,
                    ids: action.selectedMotionSensors.map(sensor => sensor.id),
                },
            };
        }
        case 'TOGGLE_CHART': {
            const { from, to } = state.action === DateSpan.CUSTOM ? state.customDate : createFromToDate(state.timespan);

            return {
                ...state,
                isBarChart: action.isBarChart,
                waterGraphData: null,
                waterSensorDataParams: {
                    averageFilter: getAvgFilter(state.timespan, action.isBarChart),
                    from,
                    to,
                    ids: state.selectedWaterSensors.map(sensor => sensor.id),
                },
            };
        }
    }
};

export const calculateUsage = (sensorData: SensorValue[]) => {
    let total = 0;
    for (let i = sensorData.length - 1; i >= 0; i--) {
        total = total + sensorData[i].value;
    }
    return (total * 1000).toFixed();
};
