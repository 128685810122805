import React from 'react';

import { getColor, theme } from 'styled/Theme';
import { useTranslation } from 'react-i18next';

import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import EnergyPie from './EnergyPie';

import {
    BillableWrapper,
    BillableContent,
    BillableBar,
    BarWrapper,
    Caption,
    ColoredH2,
    CurrentGasWrapper,
    CurrentElectricityWrapper,
    ElectricityBar,
    ElectricityBarWrapper,
    ElectricityWrapper,
    EmisionWrapper,
    GasBar,
    GasWrapper,
    GasBarWrapper,
    Icon,
    KPISpan,
    KPI,
    LargeKPI,
    PieWrapper,
    SelfSustainableBar,
    SelfSustainableContent,
    SelfSustainableWrapper,
    Wrapper,
    CoolingBar,
    HeatingBar,
} from 'styled/components/statisticsHeader';
import { BodyLeftWhite, H3White } from 'styled/components/text';
import { ContentWrapper } from 'styled/components/dataPane';

import { KpiUnit, ModuleType, Sensor, SensorType } from 'types';
import { BillableGraphData, ProductionGraphData, Top5SensorData } from './types';
import { BuildingConstants, TotalUsage } from 'views/authenticated/energy/types';

import gasIcon from '../../../../assets/icons/i-s-gas.svg';
import electricityIcon from '../../../../assets/icons/i-m-energy.svg';
import co2Icon from '../../../../assets/icons/icons-sensor-type-s-co-2.svg';
import {
    getFirstDataTimestamp,
    getLastKpiUpdateTimestamp,
    getUnit,
    numberFormatter,
    summedValue,
    totalNumberFormatter,
} from './energyStatistics.helper';
import { getSensorIcon } from 'styled/components/sensors';

interface StatisticsHeaderProps {
    totalUsage: TotalUsage;
    isLoading: boolean;
    isLoadingTop5: boolean;
    top5Meters: Top5SensorData[];
    gasSensors: Sensor[];
    mainElectricitySensors: Sensor[];
    subElectricitySensors: Sensor[];
    billableGraph: BillableGraphData;
    productionGraph: ProductionGraphData;
    constants: BuildingConstants;
}
const StatisticsHeader: React.FunctionComponent<StatisticsHeaderProps> = ({
    totalUsage,
    isLoading,
    isLoadingTop5,
    top5Meters,
    gasSensors,
    mainElectricitySensors,
    subElectricitySensors,
    billableGraph,
    productionGraph,
    constants,
}) => {
    const { t } = useTranslation();

    const sensorType = billableGraph?.sensorType;
    const isInGigajoules = billableGraph?.isInGigajoules;

    const hasSubMeters = Boolean(subElectricitySensors.length > 1);
    const electricityConsumption = (totalUsage && totalUsage.consumption) || 0;
    const electricityProduction = (totalUsage && totalUsage.production) || 0;
    const electricityFeedin = (totalUsage && totalUsage.feedin) || 0;
    const gasConsumption = Math.round(summedValue(billableGraph?.rawGasData || [])) ?? 0;

    console.log(electricityConsumption);
    console.log(electricityProduction);
    console.log(electricityFeedin);

    const elCo2Const = constants.electricityConstants.co2Emission;
    const gasCo2Const = constants.gasConstants.co2Emission;
    const electricityCO2 = Number((electricityConsumption * elCo2Const).toFixed());
    const gasCO2 = Number((summedValue(billableGraph?.rawGasData || []) * gasCo2Const).toFixed());

    const firstData = getFirstDataTimestamp([...mainElectricitySensors, ...gasSensors]);
    const lastUpdate =
        productionGraph?.consumptionData.length || billableGraph?.gasData.length
            ? ''
            : getLastKpiUpdateTimestamp([...mainElectricitySensors, ...gasSensors]);

    return (
        <>
            {(isLoading || !totalUsage) && (
                <Wrapper>
                    <ContentWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </ContentWrapper>
                </Wrapper>
            )}
            {!isLoading && !totalUsage && (
                <Wrapper>
                    <ContentWrapper>
                        <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                    </ContentWrapper>
                </Wrapper>
            )}
            {!isLoading && totalUsage && (
                <Wrapper>
                    <ElectricityWrapper divideEqual={!hasSubMeters}>
                        <>
                            <BodyLeftWhite>
                                <Icon src={getSensorIcon(sensorType)} color={getColor(ModuleType.ENERGY, sensorType)} />
                                {t(`energy.statisticsHeader.${sensorType}`)}
                            </BodyLeftWhite>
                            <LargeKPI>
                                {
                                    +parseFloat(
                                        (
                                            +totalNumberFormatter(
                                                electricityConsumption + electricityProduction + electricityFeedin,
                                                electricityConsumption,
                                                2
                                            ) +
                                            +totalNumberFormatter(
                                                electricityConsumption + electricityProduction + electricityFeedin,
                                                electricityProduction + electricityFeedin,
                                                2
                                            )
                                        ).toFixed(2)
                                    )
                                }
                                <KPISpan>
                                    {getUnit(
                                        electricityConsumption + electricityProduction + electricityFeedin,
                                        isInGigajoules
                                    )}
                                </KPISpan>
                            </LargeKPI>
                            {electricityProduction ? (
                                <CurrentElectricityWrapper>
                                    <KPI>
                                        <ColoredH2 color={theme.colors.mEnergy}>
                                            {numberFormatter(electricityConsumption, 2)}
                                            <span>{getUnit(electricityConsumption, isInGigajoules)}</span>
                                        </ColoredH2>
                                    </KPI>
                                    <ElectricityBarWrapper>
                                        <BillableWrapper
                                            percentage={getPercentage(
                                                electricityConsumption,
                                                electricityConsumption + electricityProduction + electricityFeedin
                                            )}
                                        >
                                            <BillableContent>
                                                <Caption color={theme.colors.mEnergy}>
                                                    {t('energy.statisticsHeader.billable')}
                                                </Caption>
                                            </BillableContent>
                                            <BarWrapper>
                                                <BillableBar>
                                                    {`${getPercentage(
                                                        electricityConsumption,
                                                        electricityConsumption +
                                                            electricityProduction +
                                                            electricityFeedin
                                                    )}%`}
                                                </BillableBar>
                                            </BarWrapper>
                                        </BillableWrapper>
                                        <SelfSustainableWrapper
                                            percentage={
                                                100 -
                                                getPercentage(
                                                    electricityConsumption,
                                                    electricityConsumption + electricityProduction + electricityFeedin
                                                )
                                            }
                                        >
                                            <SelfSustainableContent>
                                                <Caption color={theme.colors.mMobility}>
                                                    {t('energy.statisticsHeader.selfSustainable')}
                                                </Caption>
                                            </SelfSustainableContent>
                                            <BarWrapper>
                                                <SelfSustainableBar>
                                                    {`${
                                                        100 -
                                                        getPercentage(
                                                            electricityConsumption,
                                                            electricityConsumption +
                                                                electricityProduction +
                                                                electricityFeedin
                                                        )
                                                    }%`}
                                                </SelfSustainableBar>
                                            </BarWrapper>
                                        </SelfSustainableWrapper>
                                    </ElectricityBarWrapper>
                                    <KPI>
                                        <ColoredH2 color={theme.colors.mMobility}>
                                            {numberFormatter(electricityProduction + electricityFeedin, 2)}
                                            <span>{getUnit(electricityProduction, isInGigajoules)}</span>
                                        </ColoredH2>
                                    </KPI>
                                </CurrentElectricityWrapper>
                            ) : (
                                <CurrentGasWrapper>
                                    <GasBarWrapper>
                                        <Caption>{t('energy.statisticsHeader.current')}</Caption>
                                        {sensorType === SensorType.HEATING ? (
                                            <HeatingBar />
                                        ) : sensorType === SensorType.COOLING ? (
                                            <CoolingBar />
                                        ) : (
                                            <ElectricityBar />
                                        )}
                                    </GasBarWrapper>
                                    <KPI>
                                        <ColoredH2 color={getColor(ModuleType.ENERGY, sensorType)}>
                                            {numberFormatter(electricityConsumption, 2)}
                                            <span>{getUnit(electricityConsumption, isInGigajoules)}</span>
                                        </ColoredH2>
                                    </KPI>
                                </CurrentGasWrapper>
                            )}
                        </>
                    </ElectricityWrapper>

                    {gasConsumption > 0 && (
                        <GasWrapper divideEqual={!hasSubMeters}>
                            <BodyLeftWhite>
                                <Icon src={gasIcon} color={theme.colors.mWater} />
                                {t('energy.statisticsHeader.gas')}
                            </BodyLeftWhite>
                            <LargeKPI>
                                {gasConsumption}
                                <KPISpan>{KpiUnit.M3}</KPISpan>
                            </LargeKPI>
                            <CurrentGasWrapper>
                                <GasBarWrapper>
                                    <Caption>{t('energy.statisticsHeader.current')}</Caption>
                                    <GasBar />
                                </GasBarWrapper>
                                <KPI>
                                    <ColoredH2 color={theme.colors.mWater}>
                                        {gasConsumption}
                                        <span>{`${KpiUnit.M3}`}</span>
                                    </ColoredH2>
                                </KPI>
                            </CurrentGasWrapper>
                        </GasWrapper>
                    )}

                    {!isInGigajoules && (
                        <EmisionWrapper divideEqual={!hasSubMeters}>
                            <BodyLeftWhite>
                                <Icon src={co2Icon} />
                                {t('energy.statisticsHeader.totalEmission')}
                            </BodyLeftWhite>
                            {elCo2Const || gasCo2Const ? (
                                <>
                                    <LargeKPI>
                                        {numberFormatter(electricityCO2 + gasCO2, 2)}
                                        <KPISpan>{`${
                                            electricityCO2 + gasCO2 > 999 ? KpiUnit.T : KpiUnit.KG
                                        } CO2`}</KPISpan>
                                    </LargeKPI>
                                    <ColoredH2 color={theme.colors.mEnergy}>
                                        <Icon src={electricityIcon} color={theme.colors.mEnergy} $isLarge={true} />
                                        {elCo2Const ? (
                                            <>
                                                {numberFormatter(electricityCO2, 2)}
                                                <span>{`${electricityCO2 > 999 ? KpiUnit.T : KpiUnit.KG} CO2`}</span>
                                            </>
                                        ) : (
                                            <span>{t('energy.statisticsHeader.missingEmissionFactor')}</span>
                                        )}
                                    </ColoredH2>
                                    <br />
                                    {gasCO2 && (
                                        <ColoredH2 color={theme.colors.mWater}>
                                            <Icon src={gasIcon} color={theme.colors.mWater} $isLarge={true} />
                                            {gasCo2Const ? (
                                                <>
                                                    {numberFormatter(gasCO2, 2)}
                                                    <span>{`${gasCO2 > 999 ? KpiUnit.T : KpiUnit.KG} CO2`}</span>
                                                </>
                                            ) : (
                                                <span>{t('energy.statisticsHeader.missingEmissionFactor')}</span>
                                            )}
                                        </ColoredH2>
                                    )}
                                </>
                            ) : (
                                <LargeKPI style={{ fontSize: '30px' }}>
                                    {t('energy.statisticsHeader.missingEmissionFactor')}
                                </LargeKPI>
                            )}
                        </EmisionWrapper>
                    )}
                    {hasSubMeters && (
                        <PieWrapper>
                            {isLoadingTop5 && (
                                <div style={{ margin: 'auto' }}>
                                    <LoadingOverlay dark={true} isSmall={true} />
                                </div>
                            )}
                            {!isLoadingTop5 && !top5Meters.length && (
                                <>
                                    <BodyLeftWhite>{t('energy.statisticsHeader.breakDown')}</BodyLeftWhite>
                                    <div style={{ margin: 'auto' }}>
                                        <H3White>Could not load breakdown</H3White>
                                    </div>
                                </>
                            )}
                            {!isLoadingTop5 && top5Meters.length && (
                                <>
                                    <EnergyPie
                                        totalConsumption={
                                            electricityConsumption + electricityProduction + electricityFeedin
                                        }
                                        top5Meters={top5Meters}
                                        isGJ={isInGigajoules}
                                    />
                                </>
                            )}
                        </PieWrapper>
                    )}
                </Wrapper>
            )}
        </>
    );
};

export default StatisticsHeader;

const getPercentage = (consumption: number, totalConsumption: number): number => {
    return +((100 * consumption) / totalConsumption).toFixed();
};
