import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import { VictoryBar, VictoryChart, VictoryAxis, VictoryTooltip, VictoryLabel } from 'victory';
import { format } from 'date-fns';

import TooltipBar from '../../Tooltip/TooltipBar';

import { useResize } from 'hooks/useResize';
import { useTranslation } from 'react-i18next';

import { monthDayFormat } from 'utils/timePeriod';

import {
    setBarConfig,
    sumValues,
    normalizedTickValues,
    formatTick,
    formatWaterTick,
    normalizeYValue,
    formatXAxisTickValue,
    getMaxSensorValue,
} from '../../graph.helpers';
import {
    barChartDomainPadding,
    barChart2AxisPadding,
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
} from '../../graph.styles';
import { GraphData, SensorValue } from '../../types';
import { KpiUnit, ModuleType } from 'types';
import i18n from 'i18n';

export interface BarChartProps {
    graphData: GraphData;
}
const OccupancyChart: React.FunctionComponent<BarChartProps> = ({ graphData }) => {
    const dimension = useResize();
    const { t } = useTranslation();
    const barConfig = setBarConfig(graphData.timespan);

    const summedWaterData = useMemo(() => sumValues(graphData.data, graphData.timespan), [graphData]);
    const summedMotionData = useMemo(() => sumValues(graphData.averageData, graphData.timespan), [graphData]);

    const maxWaterData = useMemo(() => getMaxSensorValue(summedWaterData), [summedWaterData]);
    const maxMotionData = useMemo(() => getMaxSensorValue(summedMotionData), [summedMotionData]);

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={barChartDomainPadding}
                padding={barChart2AxisPadding}
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number, ticks: number[]) =>
                        formatXAxisTickValue(t, index, ticks, graphData.tickAmount, graphData.tickFormat)
                    }
                    style={XYAxis}
                />

                {/* Usage Y-axis */}
                <VictoryAxis
                    offsetX={40}
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) => formatWaterTick(t, i, [...summedWaterData])}
                    dependentAxis={true}
                    style={XYAxis}
                />

                {/* Motion Y-axis */}
                <VictoryAxis
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) =>
                        formatTick(t, i, summedMotionData, i18n.t('infoHeader.occupancy.motion'))
                    }
                    dependentAxis={true}
                    style={{
                        ...XYAxis,
                        axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                    }}
                    tickLabelComponent={<VictoryLabel textAnchor="start" x={50} />}
                />

                {/* Closing axis, also required on bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date Y-axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar
                                    module={ModuleType.OCCUPANCY}
                                    title={t('tooltip.occupancy')}
                                    unit={t('infoHeader.occupancy.motions')}
                                />
                            }
                        />
                    }
                    animate={{ duration: 500, easing: 'cubicOut' }}
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mOccupL },
                    }}
                    x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                    y={(datum: SensorValue) => normalizeYValue(datum.value, maxMotionData)}
                    data={summedMotionData}
                />

                <VictoryBar
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={
                                <TooltipBar module={ModuleType.WATER} title={t('tooltip.usage')} unit={KpiUnit.L} />
                            }
                        />
                    }
                    cornerRadius={barConfig.radius}
                    barWidth={barConfig.width}
                    style={{
                        data: { fill: theme.colors.mWater },
                    }}
                    x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                    y={(datum: SensorValue) => normalizeYValue(datum.value, maxWaterData)}
                    data={summedWaterData}
                />
            </VictoryChart>
        </>
    );
};

export default React.memo(OccupancyChart);
