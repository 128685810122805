import React, { useState } from 'react';

import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import ButtonGroup, { ButtonGroupItem } from 'components/ButtonGroup';
import DateSelector from 'components/DateSelector';

import {
    CSVLinkWrapper,
    FacilitiesBarWrapper,
    PaneIconBarWrapper,
    SubBarWrapperBlack,
} from 'styled/components/navigation';
import { DataPaneButton, DataPaneIcon, MenuIcon } from 'styled/components/buttons';
import { BodyLeft, CaptionLeft, H3White } from 'styled/components/text';
import { TitleWrapper, IconWrapper } from 'styled/components/dataPane';

import { ModuleType } from 'types';

import { DateSpan, DateRange } from 'utils/timePeriod';

import expandIcon from '../../assets/icons/icons-g-expand.svg';
import collapsIcon from '../../assets/icons/icons-g-collapse.svg';
import barChartIcon from '../../assets/icons/icons-barchart.svg';
import lineChartIcon from '../../assets/icons/icons-linechart.svg';
import calendarIcon from '../../assets/icons/icons-g-cal.svg';
import downloadIcon from '../../assets/icons/icons-download.svg';
import { GraphData } from 'components/Graph/types';

const defaultButtonItems: ButtonGroupItem[] = [
    { label: i18n.t('common.time.1day'), action: DateSpan.ONE_DAY },
    { label: i18n.t('common.time.2days'), action: DateSpan.TWO_DAYS },
    { label: i18n.t('common.time.week'), action: DateSpan.WEEK },
    { label: i18n.t('common.time.month'), action: DateSpan.MONTH },
];

interface DataPaneMenuProps {
    icon?: string;
    title: string;
    selectedAction?: string;
    buttonGroupItems?: ButtonGroupItem[];
    module: ModuleType;
    onSelectAction: (action: string, span: DateSpan, customDate?: DateRange) => void;
    canExpand?: boolean;
    expand?: boolean;
    onExpand?: (expand: boolean) => void;
    canToggleGraph?: boolean;
    isBarChart?: boolean;
    onToggleGraph?: (expand: boolean) => void;
    allowCustomPeriod?: boolean;
    customDate?: DateRange;
    graphData?: GraphData;
    subTitle?: string;
}

const DataPaneMenu: React.FunctionComponent<DataPaneMenuProps> = ({
    icon,
    title,
    selectedAction = '',
    buttonGroupItems = defaultButtonItems,
    module,
    onSelectAction,
    canExpand,
    expand,
    onExpand,
    canToggleGraph,
    isBarChart,
    onToggleGraph,
    customDate,
    allowCustomPeriod = true,
    graphData,
    subTitle,
}) => {
    const [showCalendar, setShowCalendar] = useState(false);
    const { t } = useTranslation();

    return (
        <SubBarWrapperBlack>
            <TitleWrapper>
                {icon && (
                    <IconWrapper>
                        <DataPaneIcon $disabled={false} $active={true} src={icon} />
                    </IconWrapper>
                )}
                <H3White>{title}</H3White>
                {subTitle && <CaptionLeft>{subTitle}</CaptionLeft>}
            </TitleWrapper>
            {!showCalendar && (
                <>
                    <FacilitiesBarWrapper>
                        <BodyLeft>{t('common.time.period')}</BodyLeft>
                        <ButtonGroup
                            module={module}
                            buttonGroupItems={buttonGroupItems}
                            selectedItem={selectedAction}
                            selectedAction={action => onSelectAction(action, action as DateSpan)}
                        />
                        {allowCustomPeriod && (
                            <DataPaneButton
                                active={selectedAction === DateSpan.CUSTOM}
                                module={module}
                                onClick={() => {
                                    setShowCalendar(!showCalendar);
                                }}
                            >
                                {customDate?.from
                                    ? displayCustomPeriod(customDate.from, customDate.to)
                                    : t('dateSelector.placeholder')}
                            </DataPaneButton>
                        )}
                    </FacilitiesBarWrapper>
                    {allowCustomPeriod && (
                        <PaneIconBarWrapper onClick={() => setShowCalendar(true)}>
                            <MenuIcon $disabled={false} $active={true} src={calendarIcon} />
                        </PaneIconBarWrapper>
                    )}
                </>
            )}
            {canExpand && !showCalendar && (
                <PaneIconBarWrapper onClick={() => onExpand && onExpand(!expand)}>
                    <MenuIcon $disabled={false} $active={true} src={expand ? collapsIcon : expandIcon} />
                </PaneIconBarWrapper>
            )}
            {canToggleGraph && !showCalendar && (
                <PaneIconBarWrapper onClick={() => onToggleGraph && onToggleGraph(!isBarChart)}>
                    <MenuIcon $disabled={false} $active={true} src={isBarChart ? lineChartIcon : barChartIcon} />
                </PaneIconBarWrapper>
            )}
            {graphData && (
                <CSVLinkWrapper data={graphData.data} filename={module + '_data.csv'}>
                    <MenuIcon $disabled={false} $active={true} src={downloadIcon} />
                </CSVLinkWrapper>
            )}
            {showCalendar && (
                <DateSelector
                    startDate={customDate?.from ? new Date(customDate.from) : new Date()}
                    endDate={customDate?.to ? new Date(customDate.to) : new Date()}
                    moduleType={module}
                    onClose={() => setShowCalendar(false)}
                    onDateChange={(startDate, endDate, span) => {
                        setShowCalendar(false);
                        onSelectAction(DateSpan.CUSTOM, span, { from: startDate, to: endDate });
                    }}
                />
            )}
        </SubBarWrapperBlack>
    );
};

export default DataPaneMenu;

const displayCustomPeriod = (from: string, to: string): string => {
    return `${format(new Date(from), 'MMM d')} - ${format(new Date(to), 'MMM d, yyyy')}`;
};
