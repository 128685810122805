import { SpaceSuggestion } from 'components/SearchAutoComplete';
import { Incident } from 'types';
import { IncidentsFilter } from './types';

export const findIncident = (
    allIncidents: Incident[],
    incidentId: string | undefined,
    incidentTime: string | undefined
) => {
    if (!incidentTime || !incidentId) {
        return null;
    }
    const incident = allIncidents.find(i => i.pointId === incidentId && i.timestamp === incidentTime);
    return incident || null;
};

export const filterIncidents = (incidents: Incident[], filter: IncidentsFilter) => {
    let filteredIncidents: Incident[] = incidents;

    if (filter.selectedBuilding) {
        filteredIncidents = filteredIncidents.filter(i => i.siteName === filter.selectedBuilding);
    }

    return filteredIncidents;
};

export const searchedIncidents = (incidents: Incident[], space: SpaceSuggestion): Incident[] => {
    const filteredIncidents: Incident[] = [];
    for (const incident of incidents) {
        const lengthEquipsName = incident.equipsName.length;
        const spaceName = lengthEquipsName > 1 ? incident.equipsName[lengthEquipsName - 1] : incident.equipsName[0];

        if (spaceName === space.name) {
            filteredIncidents.push(incident);
        }
    }

    return filteredIncidents;
};

export const createSuggestions = (incidents: Incident[]): SpaceSuggestion[] => {
    const suggestions: SpaceSuggestion[] = [];

    for (const incident of incidents) {
        const newSuggestion: SpaceSuggestion = {
            ids: [incident.pointId],
            location: createLocation(incident),
            name: getSpaceName(incident),
        };
        if (suggestions.length === 0 || suggestions.every(s => s.name !== newSuggestion.name)) {
            suggestions.push(newSuggestion);
        }
    }
    return suggestions;
};

const createLocation = (incident: Incident) => {
    const location = [];
    location.push(incident.siteName);
    incident.equipsName.forEach(name => {
        location.push(name);
    });

    return location;
};

const getSpaceName = (incident: Incident) => {
    const lengthEquipsName = incident.equipsName.length;
    const spaceName =
        lengthEquipsName === 0
            ? ''
            : lengthEquipsName > 1
            ? incident.equipsName[lengthEquipsName - 1]
            : incident.equipsName[0];

    return spaceName;
};
