import TextArea from 'components/TextArea';
import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { HideOnPrint } from 'styled/components/ebs';
import { BodyLeftWhite, LeadLeft } from 'styled/components/text';
import { ColorMode, theme } from 'styled/Theme';
import { ExplanatoryText } from './types';

interface Props {
    label?: string;
    text: string;
    name: keyof ExplanatoryText;
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const EBSTextArea: React.FunctionComponent<Props> = ({ label, text, name, onChangeText }) => {
    const { t } = useTranslation();
    const [isEditMode, setIsEditMode] = useState(false);

    return (
        <>
            <br />
            {Boolean(text) && !isEditMode && (
                <>
                    <BodyLeftWhite>{text}</BodyLeftWhite>
                    <br />
                </>
            )}
            <HideOnPrint>
                {isEditMode && (
                    <>
                        <TextArea
                            name={name}
                            mode={ColorMode.DARK}
                            label={label}
                            input={text}
                            autoFocus={true}
                            onChange={onChangeText}
                        />
                        <br />
                    </>
                )}

                <LeadLeft
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.mEnergy}
                    onClick={() => setIsEditMode(!isEditMode)}
                >
                    {!text && !isEditMode
                        ? t('energy.ebs.textarea.add')
                        : Boolean(text) && !isEditMode
                        ? t('energy.ebs.textarea.edit')
                        : t('energy.ebs.textarea.save')}
                </LeadLeft>
            </HideOnPrint>
        </>
    );
};

export default EBSTextArea;
