import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import {
    ColoredH3,
    EBSElectricityBarWrapper,
    EBSCaption,
    EBSGasBarWrapper,
    EBSKPI,
    EBSReportTotalConsumptionWrapper,
} from 'styled/components/ebs';
import { BodyLeftEBS, BodyLeftWhite } from 'styled/components/text';
import { ElectricityBarWrapper } from 'styled/components/statisticsHeader';
import { theme } from 'styled/Theme';

import { ComparisonData } from '../types';
import { KpiUnit } from 'types';
import { lastYear, twoYearsBack } from '../ebs.helpers';

interface Props {
    comparisonDataLastYear: ComparisonData;
    comparisonDataTwoYearsBack: ComparisonData;
}
const TotalUsage: React.FunctionComponent<Props> = ({ comparisonDataLastYear, comparisonDataTwoYearsBack }) => {
    const { t } = useTranslation();

    const totalElectricityConsumptionLastYear = useMemo(
        () =>
            comparisonDataLastYear.electricityConsumption +
            comparisonDataLastYear.electricityFeedin +
            comparisonDataLastYear.electricityProduction,
        [comparisonDataLastYear]
    );
    const totalElectricityConsumptionTwoYearsBack = useMemo(
        () =>
            comparisonDataTwoYearsBack.electricityConsumption +
            comparisonDataTwoYearsBack.electricityFeedin +
            comparisonDataTwoYearsBack.electricityProduction,
        [comparisonDataTwoYearsBack]
    );

    return (
        <EBSReportTotalConsumptionWrapper>
            <BodyLeftWhite>{t('energy.ebs.facility.electricityConsumption')}</BodyLeftWhite>
            <BodyLeftEBS>
                {`${totalElectricityConsumptionLastYear} 
            ${KpiUnit.KWH}`}
            </BodyLeftEBS>
            <div>
                <ElectricityBarWrapper>
                    <EBSCaption>{twoYearsBack}</EBSCaption>
                    <EBSElectricityBarWrapper
                        percentage={calculatePercentage(
                            totalElectricityConsumptionTwoYearsBack,
                            totalElectricityConsumptionLastYear
                        )}
                    />
                    <EBSKPI>
                        <ColoredH3 color={theme.colors.ebsColor3}>
                            {totalElectricityConsumptionTwoYearsBack}
                            <span>{`${KpiUnit.KWH}`}</span>
                        </ColoredH3>
                    </EBSKPI>
                </ElectricityBarWrapper>
            </div>
            <BodyLeftWhite />
            <BodyLeftEBS />

            <div>
                {totalElectricityConsumptionLastYear > 0 && (
                    <ElectricityBarWrapper>
                        <EBSCaption>{lastYear}</EBSCaption>
                        <EBSElectricityBarWrapper
                            percentage={calculatePercentage(
                                totalElectricityConsumptionLastYear,
                                totalElectricityConsumptionTwoYearsBack
                            )}
                        />
                        <EBSKPI>
                            <ColoredH3 color={theme.colors.ebsColor3}>
                                {totalElectricityConsumptionLastYear}
                                <span>{`${KpiUnit.KWH}`}</span>
                            </ColoredH3>
                        </EBSKPI>
                        {Boolean(totalElectricityConsumptionTwoYearsBack) && (
                            <EBSKPI>
                                <ColoredH3 color={theme.colors.ebsColor6}>
                                    {formatPercentage(
                                        totalElectricityConsumptionLastYear,
                                        totalElectricityConsumptionTwoYearsBack
                                    )}
                                    <span>{`${KpiUnit.PERCENTAGE}`}</span>
                                </ColoredH3>
                            </EBSKPI>
                        )}
                    </ElectricityBarWrapper>
                )}
            </div>

            {Boolean(comparisonDataLastYear.gasConsumption) && (
                <>
                    <BodyLeftWhite>{t('energy.ebs.facility.gasConsumption')}</BodyLeftWhite>
                    <BodyLeftEBS>{`${comparisonDataLastYear.gasConsumption} ${KpiUnit.M3}`}</BodyLeftEBS>

                    <div>
                        {Boolean(comparisonDataTwoYearsBack.gasConsumption) && (
                            <ElectricityBarWrapper>
                                <EBSCaption>{twoYearsBack}</EBSCaption>
                                <EBSGasBarWrapper
                                    percentage={calculatePercentage(
                                        comparisonDataTwoYearsBack.gasConsumption,
                                        comparisonDataLastYear.gasConsumption
                                    )}
                                />
                                <EBSKPI>
                                    <ColoredH3 color={theme.colors.ebsColor5}>
                                        {comparisonDataTwoYearsBack.gasConsumption}
                                        <span>{`${KpiUnit.M3}`}</span>
                                    </ColoredH3>
                                </EBSKPI>
                            </ElectricityBarWrapper>
                        )}
                    </div>
                    <div />
                    <div />
                    <div>
                        <ElectricityBarWrapper>
                            <EBSCaption>{lastYear}</EBSCaption>
                            <EBSGasBarWrapper
                                percentage={calculatePercentage(
                                    comparisonDataLastYear.gasConsumption,
                                    comparisonDataTwoYearsBack.gasConsumption
                                )}
                            />
                            <EBSKPI>
                                <ColoredH3 color={theme.colors.ebsColor5}>
                                    {comparisonDataLastYear.gasConsumption}
                                    <span>{`${KpiUnit.M3}`}</span>
                                </ColoredH3>
                            </EBSKPI>
                            {Boolean(comparisonDataTwoYearsBack.gasConsumption) && (
                                <EBSKPI>
                                    <ColoredH3 color={theme.colors.ebsColor6}>
                                        {formatPercentage(
                                            comparisonDataLastYear.gasConsumption,
                                            comparisonDataTwoYearsBack.gasConsumption
                                        )}
                                        <span>{`${KpiUnit.PERCENTAGE}`}</span>
                                    </ColoredH3>
                                </EBSKPI>
                            )}
                        </ElectricityBarWrapper>
                    </div>
                </>
            )}
        </EBSReportTotalConsumptionWrapper>
    );
};

export default TotalUsage;

const getPercentage = (currentValue: number, newValue: number): number => {
    return Number(((100 * currentValue) / newValue).toFixed());
};

const formatPercentage = (currentValue: number, newValue: number) => {
    return getPercentage(currentValue, newValue) - 100 > 0
        ? '+' + (getPercentage(currentValue, newValue) - 100)
        : getPercentage(currentValue, newValue) - 100;
};

const calculatePercentage = (currentValue: number, newValue: number) => {
    return currentValue > newValue ? 100 : getPercentage(currentValue, newValue);
};
