import React from 'react';

import ElectricityView from './ElectricityView';
import FacilityView from './Facility';
import GasView from './GasView';
import InteractionView from './Interaction/InteractionView';
import IntroductionView from './IntroductionView';

import { PageBreak, StyledPreview } from 'styled/components/ebs';

import { ComparisonData, ExplanatoryText } from './types';
import { BagData, SensorAverage } from 'types';
import { Space } from 'views/authenticated/types';
import { EBSElecTempGasGraphData } from 'components/Graph/types';

interface Props {
    bagData: BagData;
    building: Space;
    comparisonDataLastYear: ComparisonData;
    comparisonDataTwoYearsBack: ComparisonData;
    explanatoryText: ExplanatoryText;
    electricityData: SensorAverage[];
    gasData: SensorAverage[];
    interactionGraph: EBSElecTempGasGraphData;
    occupancyRate: number;
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const Preview = React.forwardRef<HTMLDivElement, Props>(
    (
        {
            bagData,
            building,
            comparisonDataLastYear,
            comparisonDataTwoYearsBack,
            occupancyRate,
            explanatoryText,
            electricityData,
            gasData,
            interactionGraph,
            onChangeText,
        },
        ref
    ) => {
        return (
            <StyledPreview ref={ref}>
                <IntroductionView explanatoryText={explanatoryText} building={building} onChangeText={onChangeText} />
                <PageBreak />

                <FacilityView
                    building={building}
                    bagData={bagData}
                    comparisonDataLastYear={comparisonDataLastYear}
                    comparisonDataTwoYearsBack={comparisonDataTwoYearsBack}
                    occupancyLoading={false}
                    occupancyRate={occupancyRate}
                />
                <PageBreak />

                <ElectricityView
                    explanatoryText={explanatoryText}
                    consumptionData={electricityData}
                    onChangeText={onChangeText}
                />
                <PageBreak />

                <GasView explanatoryText={explanatoryText} consumptionData={gasData} onChangeText={onChangeText} />
                <PageBreak />

                <InteractionView
                    explanatoryText={explanatoryText}
                    elecTempGasGraph={interactionGraph}
                    onChangeText={onChangeText}
                />
                <PageBreak />
                <PageBreak />
            </StyledPreview>
        );
    }
);

Preview.displayName = 'Preview';

export default Preview;
