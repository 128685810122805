import styled from 'styled-components';
import SVG from 'react-inlinesvg';

import { getColor, theme } from 'styled/Theme';
import { ModuleType } from 'types';

export const FacilitieItem = styled.div`
    display: flex;
    flex-direction: column;
    width: 310px;
    border-radius: 4px;
    background-color: ${theme.colors.greyD};
`;

export const FacilitieTitle = styled.div`
    display: flex;
    align-items: center;
    height: 60px;
    color: white;
    border-bottom: ${theme.colors.text} 1px solid;
    padding-inline: 20px;
`;

export const FacilitieContent = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 20px;
    padding: 20px;
`;

interface StatsRowProps {
    module: ModuleType;
}
export const StatsRow = styled.div<StatsRowProps>`
    display: flex;
    align-items: flex-start;
    color: ${({ module }) => getColor(module)};
    h2 {
        font-weight: normal;
    }
    span {
        font-size: 14px;
    }
`;

export const Statistic = styled.div`
    flex: 2.5;
`;

interface AnomalieProps {
    hasAnomalies: boolean;
}
export const Anomalie = styled.div<AnomalieProps>`
    flex: 2;
    h2 {
        opacity: ${({ hasAnomalies }) => (hasAnomalies ? 1 : 0.5)};
    }
`;

export const Icon = styled(SVG)`
    padding: 7px;
    width: 15px;
    height: 15px;
    margin-right: 15px;
    & path {
        fill: ${({ color }) => color};
    }
`;

export const IconWrapper = styled.div`
    display: flex;
`;

export const Caption = styled.p`
    color: ${theme.colors.grey};
    font-size: 10px;
    margin: 0px;
`;

export const FacilitiesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 310px);
    justify-content: center;
    grid-gap: 2rem;
`;
