import React from 'react';

import i18n from '../../i18n';
import { getColor } from 'styled/Theme';

import {
    Anomalie,
    Caption,
    FacilitieContent,
    FacilitieItem,
    FacilitieTitle,
    Icon,
    IconWrapper,
    Statistic,
    StatsRow,
} from 'styled/components/facilitieCard';
import { H2, H3Bold } from 'styled/components/text';

import { KpiUnit, ModuleType } from 'types';
import { FaciliteItem } from './types';

import waterIcon from '../../assets/icons/i-m-water.svg';
import airIcon from '../../assets/icons/i-m-air.svg';
import energyIcon from '../../assets/icons/i-m-energy.svg';
import gasIcon from '../../assets/icons/i-s-gas.svg';
import occupancyIcon from '../../assets/icons/i-m-occupancy.svg';

interface FacilitieCardProps {
    facilitie: FaciliteItem;
}
const FacilitieCard: React.FunctionComponent<FacilitieCardProps> = ({ facilitie }) => {
    const energyAnomalie = facilitie.anomalies.find(f => f.type === ModuleType.ENERGY);
    const waterAnomalie = facilitie.anomalies.find(f => f.type === ModuleType.WATER);
    const airAnomalie = facilitie.anomalies.find(f => f.type === ModuleType.AIR);
    const occupancyAnomalie = facilitie.anomalies.find(f => f.type === ModuleType.OCCUPANCY);

    return (
        <FacilitieItem>
            <FacilitieTitle>
                <H3Bold>{facilitie.name}</H3Bold>
            </FacilitieTitle>
            <FacilitieContent>
                {(Boolean(energyAnomalie) || Boolean(facilitie.electricity?.consumption)) && (
                    <Stats
                        module={ModuleType.ENERGY}
                        icon={energyIcon}
                        anomaliesTotal={energyAnomalie?.total}
                        statistic={facilitie.electricity?.consumption}
                    />
                )}
                {Boolean(facilitie.gas?.consumption) && (
                    <Stats module={ModuleType.GAS} icon={gasIcon} statistic={facilitie.gas?.consumption} />
                )}
                {(Boolean(waterAnomalie) || Boolean(facilitie.water?.consumption)) && (
                    <Stats
                        module={ModuleType.WATER}
                        icon={waterIcon}
                        anomaliesTotal={waterAnomalie?.total}
                        statistic={facilitie.water?.consumption}
                    />
                )}
                {(Boolean(airAnomalie) || Boolean(facilitie.airQualityScore)) && (
                    <Stats
                        module={ModuleType.AIR}
                        icon={airIcon}
                        anomaliesTotal={airAnomalie?.total}
                        statistic={facilitie.airQualityScore}
                    />
                )}
                {(Boolean(occupancyAnomalie) || Boolean(facilitie.occupancyAvg)) && (
                    <Stats
                        module={ModuleType.OCCUPANCY}
                        icon={occupancyIcon}
                        anomaliesTotal={occupancyAnomalie?.total}
                        statistic={facilitie.occupancyAvg}
                    />
                )}
            </FacilitieContent>
        </FacilitieItem>
    );
};

export default FacilitieCard;

interface StatsProps {
    module: ModuleType;
    icon: string;
    anomaliesTotal?: number;
    statistic?: number;
}
const Stats: React.FunctionComponent<StatsProps> = ({ module, icon, anomaliesTotal, statistic }) => {
    return (
        <StatsRow module={module}>
            <IconWrapper>
                <Icon color={getColor(module)} src={icon} />
            </IconWrapper>
            <Statistic>
                <H2>
                    {statistic ? formatStatistic(module, statistic) : 0} <span>{getUnit(module, statistic)}</span>
                </H2>
                <Caption>{getStatisticCaption(module)}</Caption>
            </Statistic>
            {module !== ModuleType.GAS && (
                <Anomalie hasAnomalies={!!anomaliesTotal}>
                    <H2>{anomaliesTotal || 0}</H2>
                    <Caption>{getAnomalieCaption(module)}</Caption>
                </Anomalie>
            )}
        </StatsRow>
    );
};

const getStatisticCaption = (module: ModuleType) => {
    switch (module) {
        case ModuleType.AIR:
            return i18n.t('facilities.statistic.air.caption');
        case ModuleType.WATER:
            return i18n.t('facilities.statistic.water.caption');
        case ModuleType.ENERGY:
            return i18n.t('facilities.statistic.energy.caption');
        case ModuleType.GAS:
            return i18n.t('facilities.statistic.gas.caption');
        case ModuleType.OCCUPANCY:
            return i18n.t('facilities.statistic.occupancy.caption');
        case ModuleType.MOBILITY:
            return i18n.t('facilities.statistic.mobility.caption');
        default:
            return;
    }
};

const getAnomalieCaption = (module: ModuleType) => {
    switch (module) {
        case ModuleType.AIR:
            return i18n.t('facilities.anomalie.air.caption');
        case ModuleType.WATER:
            return i18n.t('facilities.anomalie.water.caption');
        case ModuleType.ENERGY:
            return i18n.t('facilities.anomalie.energy.caption');
        case ModuleType.OCCUPANCY:
            return i18n.t('facilities.anomalie.occupancy.caption');
        case ModuleType.MOBILITY:
            return i18n.t('facilities.anomalie.mobility.caption');
        default:
            return;
    }
};

const getUnit = (module: ModuleType, statistic: number) => {
    switch (module) {
        case ModuleType.AIR:
            return '/10';
        case ModuleType.WATER:
            return KpiUnit.L;
        case ModuleType.ENERGY:
            return statistic > 999 ? KpiUnit.MWH : KpiUnit.KWH;
        case ModuleType.GAS:
            return KpiUnit.M3;
        case ModuleType.OCCUPANCY:
            return KpiUnit.PERCENTAGE;
        case ModuleType.MOBILITY:
            return KpiUnit.PERCENTAGE;
        default:
            return;
    }
};

const formatStatistic = (module: ModuleType, statistic: number): number => {
    switch (module) {
        case ModuleType.ENERGY:
            return statistic > 999 ? Number((statistic / 1000).toFixed(2)) : Number(statistic.toFixed());
        default:
            return +statistic.toFixed(1);
    }
};
