import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { Incident, Sensor, SensorAverage, SensorAvgFilter, SensorData } from 'types';
import { SensorDataParam } from 'components/DataPane/incidents/types';

export const useAirSensorData = (sensorDataParams: SensorDataParam, incident: Incident, occupancySensors: Sensor[]) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['airSensor', sensorDataParams.id, sensorDataParams.from, sensorDataParams.to],
        async ({ signal }): Promise<{ airData: SensorData[]; occupancyData: SensorAverage[] }> => {
            const promiseArray = createEndPoints(sensorDataParams, incident, occupancySensors).map(url =>
                axiosClient.get(url, { signal })
            );
            const result = await Promise.all(promiseArray);

            return { airData: result[0]?.data, occupancyData: result[1]?.data || [] };
        },
        {
            enabled: Boolean(sensorDataParams.id),
        }
    );
};

const createEndPoints = (sensorDataParams: SensorDataParam, incident: Incident, occupancySensors: Sensor[]) => {
    const filteredOccupancySensors = occupancySensors.filter(s => s.roomRef === incident.equipId);
    const urlArray: string[] = [];
    urlArray[0] = config.sensorData(sensorDataParams.id, sensorDataParams.from, sensorDataParams.to);

    if (filteredOccupancySensors.length) {
        const occupancySensorsIds = filteredOccupancySensors.map(f => f.id);
        urlArray[1] = config.sensorDataSumTotal(
            occupancySensorsIds,
            SensorAvgFilter.HOUR_OF_DAY,
            sensorDataParams.from,
            sensorDataParams.to
        );
    }

    return urlArray;
};
