import React, { useState, FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { NavigationItem } from '../../NavigationBar';
import SubMenuItem from './SubMenuItem';

interface SubNavigationBarProps {
    navigationItems: NavigationItem[];
}

const SubNavigationBar: FunctionComponent<SubNavigationBarProps> = ({ navigationItems }) => {
    const location = useLocation();
    const [subMenuItems, setSubMenuItems] = useState<Array<NavigationItem>>(navigationItems);

    useEffect(() => {
        const currentLocation = location.pathname;
        const updatedMenuItems = subMenuItems.map(item => {
            item.active = currentLocation === item.path;

            return item;
        });
        setSubMenuItems([...updatedMenuItems]);
    }, [location.pathname]);

    return (
        <>
            {subMenuItems.map((m, index) => (
                <SubMenuItem navItem={m} key={index} />
            ))}
        </>
    );
};

export default SubNavigationBar;
