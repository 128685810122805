import React from 'react';

import { FacilitiesBarWrapper, PaneIconBarWrapper, SubBarWrapperBlack } from 'styled/components/navigation';
import { MenuIcon } from 'styled/components/buttons';
import { H2White } from 'styled/components/text';
import { EBSTitleWrapper } from 'styled/components/dataPane';

import expandIcon from '../../../assets/icons/icons-g-expand.svg';
import collapsIcon from '../../../assets/icons/icons-g-collapse.svg';

interface DataPaneTitleMenuProps {
    title: string;
    expand?: boolean;
    canExpand?: boolean;
    onExpand?: (expand: boolean) => void;
}

const EBSTitleMenu: React.FunctionComponent<DataPaneTitleMenuProps> = ({ title, expand, canExpand, onExpand }) => {
    return (
        <SubBarWrapperBlack>
            <EBSTitleWrapper>
                <H2White>{title}</H2White>
            </EBSTitleWrapper>
            <FacilitiesBarWrapper />

            {canExpand && (
                <PaneIconBarWrapper onClick={() => onExpand && onExpand(!expand)}>
                    <MenuIcon $disabled={false} $active={true} src={expand ? collapsIcon : expandIcon} />
                </PaneIconBarWrapper>
            )}
        </SubBarWrapperBlack>
    );
};

export default EBSTitleMenu;
