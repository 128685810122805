import React from 'react';

import {
    getSensorIcon,
    SensorTypeIcon,
    SensorTypeWrapper,
    KpiWrapper,
    getUnit,
    SensorValueWrapper,
    SensorKpiWrapper,
} from 'styled/components/sensors';
import { KPIType, Sensor, SensorType } from 'types';

interface SensorKpiMenuProps {
    sensors: Sensor[];
    selectedSensor: Sensor;
    onSelectSensor: (sensor: Sensor) => void;
}
const SensorKpiMenu: React.FunctionComponent<SensorKpiMenuProps> = ({ sensors, selectedSensor, onSelectSensor }) => {
    const handleClick = (item: Sensor) => {
        if (selectedSensor !== item) {
            onSelectSensor(item);
        }
    };

    return (
        <KpiWrapper>
            {sensors.map(item => (
                <SensorKpiWrapper key={item.id} onClick={() => handleClick(item)}>
                    <SensorTypeWrapper sensortype={item.type} $active={item.id === selectedSensor.id}>
                        <SensorTypeIcon
                            sensortype={item.type}
                            $hasError={item.latestKpi.kpi === KPIType.INSUFFICIENT}
                            $active={item.id === selectedSensor.id}
                            src={getSensorIcon(item.type)}
                        />
                    </SensorTypeWrapper>
                    <SensorValueWrapper
                        sensortype={item.type}
                        $hasError={item.latestKpi.kpi === KPIType.INSUFFICIENT}
                        $active={item.id === selectedSensor.id}
                    >
                        {item.type === SensorType.WATER
                            ? (item.latestKpi.value * 1000).toFixed()
                            : item.latestKpi.value}
                        {getUnit(item.unit, item.subType)}
                    </SensorValueWrapper>
                </SensorKpiWrapper>
            ))}
        </KpiWrapper>
    );
};

export default SensorKpiMenu;
