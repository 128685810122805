import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { FeedbackDataParam } from 'components/DataPane/statistics/feedback/types';
import { SensorAverage, SensorAvgFilter } from 'types';

export const useFeedbackSensorData = (feedbackDataParam: FeedbackDataParam) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['feedbackSensor', feedbackDataParam.ids, feedbackDataParam.from, feedbackDataParam.to],
        async ({ signal }) => {
            const { data } = await axiosClient.get<SensorAverage[]>(
                config.multiSensorsDataAverage(
                    feedbackDataParam.ids,
                    SensorAvgFilter.HOUR_OF_DAY,
                    feedbackDataParam.from,
                    feedbackDataParam.to
                ),
                {
                    signal,
                }
            );

            return data;
        },
        {
            enabled: Boolean(feedbackDataParam.ids.length),
        }
    );
};
