import React, { FC, createContext, useState, useEffect } from 'react';

import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import { Space } from 'views/authenticated/types';
import { ModuleType } from 'types';

import { useTenantData } from 'hooks/api/useTenantData';
import { Hours, OpeningHours, OpeningHoursPreference } from 'components/WorkingHours';
import { usePreferencesMutation } from 'hooks/api/mutate/usePreferencesMutation';
import { ContractPreference, ElectricityContract, GasContract } from 'views/authenticated/profile/types';
import { MetaDataBoiler, MetaDataEwattch } from 'views/authenticated/energy/types';

export const allModules = [
    ModuleType.ENERGY,
    ModuleType.AIR,
    ModuleType.WATER,
    ModuleType.OCCUPANCY,
    ModuleType.MOBILITY,
    ModuleType.FEEDBACK,
];

export interface ITenantProvider {
    getBuildingData: () => Space[];
    ewattchMeta: MetaDataEwattch[];
    boilerMeta: MetaDataBoiler[];
    modules: ModuleType[];
    openingHours: OpeningHours[];
    contracts: (ElectricityContract | GasContract)[];
    updateOpeningHours: (selectedBuilding: string, editHours: Hours) => void;
    updateContracts: (contracts: ContractPreference) => void;
    isFetching: boolean;
}

const TenantContext = createContext({} as ITenantProvider);

interface TenantProps {
    children?: React.ReactNode;
}
const TenantProvider: FC<TenantProps> = ({ children }) => {
    const [initialLoading, setInitialLoading] = useState(true);
    const [buildingsState, setBuildingsState] = useState<Space[]>([]);
    const [modules, setModules] = useState<ModuleType[]>([]);
    const [openingHours, setOpeningHours] = useState<OpeningHours[]>([]);
    const [contracts, setContracts] = useState<(GasContract | ElectricityContract)[]>([]);
    const [ewattchMeta, setEwattchMeta] = useState<MetaDataEwattch[]>([]);
    const [boilerMeta, setBoilerMeta] = useState<MetaDataBoiler[]>([]);

    const { mutate } = usePreferencesMutation<OpeningHoursPreference | ContractPreference>();
    const { data, isFetching } = useTenantData();

    useEffect(() => {
        if (data) {
            setBuildingsState(data.spaces);
            setModules(data.modules);
            setOpeningHours(data.openingHours);
            setContracts(data.contracts);
            setEwattchMeta(data.ewattch);
            setBoilerMeta(data.boiler);
            setInitialLoading(false);
        }
    }, [data]);

    const updateOpeningHours = (selectedBuilding: string, editHours: Hours) => {
        const payloadOpeningHours = {
            opening_hours_preference: [
                ...openingHours.filter(o => o.building !== selectedBuilding),
                { building: selectedBuilding, opening_hours: editHours },
            ],
        };
        mutate(payloadOpeningHours);
    };

    const updateContracts = (contracts: ContractPreference) => {
        mutate(contracts);
    };

    const getBuildingData = () => {
        // Deep copy of buildingState to avoid changes to original.
        return JSON.parse(JSON.stringify(buildingsState));
    };

    const tenantContextValue = {
        getBuildingData,
        modules,
        openingHours,
        contracts,
        ewattchMeta,
        boilerMeta,
        updateOpeningHours,
        updateContracts,
        isFetching,
    };
    return (
        <TenantContext.Provider value={tenantContextValue}>
            {initialLoading ? <LoadingOverlay /> : children}
        </TenantContext.Provider>
    );
};

const useTenant = () => {
    const context = React.useContext(TenantContext);
    if (context === undefined) {
        throw new Error('useTenant must be used within a TenantProvider');
    }
    return context;
};

export { TenantProvider, useTenant };
