import React from 'react';

const LineSymbol: React.FunctionComponent = (props: any) => {
    return (
        <line
            x1={props.x - 5}
            y1={props.y}
            x2={props.x + 5}
            y2={props.y}
            style={{ stroke: props.datum.symbol.fill, strokeWidth: 3, opacity: 1, fill: 'none' }}
        />
    );
};

export default LineSymbol;
