import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const PieWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
`;

export const PieGraphWrapper = styled.div`
    padding-top: 20px;
`;

export const SummaryTitlePanel = styled.div`
    font-size: 14px;
`;

export const StyledList = styled.ul`
    max-width: 174px;
    padding: 0px;
    margin-top: auto;
    margin-bottom: auto;
    li {
        color: ${theme.colors.grey};
        font-size: 10px;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
`;
