import React, { useEffect, useState } from 'react';

import { format, differenceInDays, addDays, endOfDay, startOfDay, isToday } from 'date-fns';
import { useTranslation } from 'react-i18next';

import {
    CalenderDay,
    CloseButton,
    CloseIcon,
    DateDivider,
    DateSelectorWrapper,
    DaysWrapper,
    Header,
    MonthsWrapper,
    MonthYear,
    SelectButton,
    SelectIcon,
    WeekDay,
    YearsWrapper,
} from '../../styled/components/dateSelector';
import { DataPaneButton } from 'styled/components/buttons';
import { BodyLeftWhite } from 'styled/components/text';

import { ModuleType } from 'types';

import closeIcon from '../../assets/icons/i-close.svg';
import selectIcon from '../../assets/icons/icons-i-checkmark.svg';

import {
    calendar,
    CalendarMonths,
    CALENDAR_MONTHS,
    getYears,
    isAfterToday,
    isBeforeStartDate,
    isDate,
    isMoreThanMaxDaySpan,
    isSameDay,
    isSameMonth,
    MAX_DAY_SPAN,
    WeekDays,
    WEEK_DAYS,
    MAX_DAY_SPAN_ENERGY,
} from 'components/DateSelector/dateSelector.helper';
import { DateSpan } from 'utils/timePeriod';

const dateFormat = 'd MMM yyyy';

interface DateSelectorProps {
    startDate: Date;
    endDate: Date;
    onClose: () => void;
    onDateChange: (startDate: string, endDate: string, span: DateSpan) => void;
    moduleType?: ModuleType;
}
const DateSelector: React.FunctionComponent<DateSelectorProps> = ({
    startDate,
    endDate,
    onClose,
    onDateChange,
    moduleType,
}) => {
    const { t } = useTranslation();
    const [startDateActive, setStartDateActive] = useState(true);
    const [dateStateStart, setDateStateStart] = useState({
        current: startDate,
        month: startDate.getMonth() + 1,
        year: startDate.getFullYear(),
    });

    const [dateStateEnd, setDateStateEnd] = useState({
        current: endDate,
        month: endDate.getMonth() + 1,
        year: endDate.getFullYear(),
    });
    const maxDaySpan = moduleType === ModuleType.ENERGY ? MAX_DAY_SPAN_ENERGY : MAX_DAY_SPAN;

    useEffect(() => {
        const numberOfDays = differenceInDays(startOfDay(dateStateEnd.current), startOfDay(dateStateStart.current));

        if (numberOfDays > maxDaySpan) {
            const newEndDate = addDays(dateStateStart.current, maxDaySpan);
            setDateStateEnd({
                current: newEndDate,
                month: newEndDate.getMonth() + 1,
                year: newEndDate.getFullYear(),
            });
        }

        if (numberOfDays < 0) {
            setDateStateEnd({
                ...dateStateStart,
            });
        }
    }, [dateStateStart.current]);

    const addDateToState = (date: Date) => {
        const setDateToState = startDateActive ? setDateStateStart : setDateStateEnd;
        const isDateObject = isDate(date);
        const _date = isDateObject ? date : new Date();
        setDateToState({
            current: _date,
            month: _date.getMonth() + 1,
            year: _date.getFullYear(),
        });

        startDateActive && setStartDateActive(false);
    };

    const getCalenderDates = () => {
        const { month, year } = startDateActive ? dateStateStart : dateStateEnd;
        const calendarMonth = month;
        const calendarYear = year;
        return calendar(calendarMonth, calendarYear);
    };

    const renderStartCalenderDay = (date: (string | number)[], i: number) => {
        const { current, month, year } = dateStateStart;
        const _date = new Date(date.join('-'));

        const isCurrent = current && isSameDay(_date, current);
        const inMonth = month && year && isSameMonth(_date, new Date([year, month, 1].join('-')));
        const isDisabled = current && isAfterToday(_date);

        return (
            <CalenderDay
                module={moduleType}
                inMonth={Boolean(inMonth)}
                isCurrent={isCurrent}
                key={i}
                isDisabled={isDisabled}
                onClick={() => !isDisabled && setDate(_date)}
            >
                {_date.getDate()}
            </CalenderDay>
        );
    };

    const renderEndCalenderDay = (date: (string | number)[], i: number) => {
        const { current, month, year } = dateStateEnd;
        const { current: currentStart } = dateStateStart;
        const _date = new Date(date.join('-'));

        const isCurrent = isSameDay(_date, current);
        const inMonth = month && year && isSameMonth(_date, new Date([year, month, 1].join('-')));
        const isDisabled =
            !isCurrent &&
            (isAfterToday(_date) ||
                isMoreThanMaxDaySpan(_date, currentStart, maxDaySpan) ||
                isBeforeStartDate(_date, currentStart));

        return (
            <CalenderDay
                module={moduleType}
                inMonth={Boolean(inMonth)}
                isCurrent={isCurrent}
                key={i}
                isDisabled={isDisabled}
                onClick={() => !isDisabled && setDate(_date)}
            >
                {_date.getDate()}
            </CalenderDay>
        );
    };

    const renderDayLabel = (d: string, i: number) => {
        const dayLabel = WEEK_DAYS[d as keyof WeekDays].toLowerCase();
        return <WeekDay key={i}>{dayLabel}</WeekDay>;
    };

    const renderMonths = (d: string, i: number) => {
        const { month, year } = startDateActive ? dateStateStart : dateStateEnd;
        const monthLabel = CALENDAR_MONTHS[d as keyof CalendarMonths];
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth();
        const isDisabled = i > currentMonth && year >= currentYear;

        return (
            <MonthYear
                onClick={() => !isDisabled && setMonth(i)}
                module={moduleType}
                isDisabled={isDisabled}
                isCurrent={month === i + 1}
                key={i}
            >
                {monthLabel}
            </MonthYear>
        );
    };

    const getEndDate = () => {
        return isToday(dateStateEnd.current)
            ? dateStateEnd.current.toISOString()
            : endOfDay(dateStateEnd.current).toISOString();
    };

    const renderYears = (y: number, i: number) => {
        const { year } = startDateActive ? dateStateStart : dateStateEnd;
        return (
            <MonthYear onClick={() => setYear(y)} module={moduleType} isCurrent={year === y} key={i}>
                {y}
            </MonthYear>
        );
    };

    const setDate = (date: Date) => {
        const { current } = startDateActive ? dateStateStart : dateStateEnd;
        !(current && isSameDay(date, current)) && addDateToState(date);
    };

    const setMonth = (month: number) => {
        if (startDateActive) {
            setDateStateStart({
                ...dateStateStart,
                month: month + 1,
            });
        }
        setDateStateEnd({
            ...dateStateEnd,
            month: month + 1,
        });
    };

    const setYear = (year: number) => {
        if (startDateActive) {
            setDateStateStart({
                ...dateStateStart,
                year,
            });
        }
        setDateStateEnd({
            ...dateStateEnd,
            year,
        });
    };

    return (
        <DateSelectorWrapper>
            <Header>
                <CloseButton onClick={() => onClose()}>
                    <CloseIcon src={closeIcon} />
                </CloseButton>
                <BodyLeftWhite>
                    {t('dateSelector.header', {
                        maxDateSpan: moduleType === ModuleType.ENERGY ? MAX_DAY_SPAN_ENERGY : MAX_DAY_SPAN,
                    })}
                </BodyLeftWhite>
                <DataPaneButton
                    active={startDateActive}
                    onClick={() => {
                        setStartDateActive(true);
                    }}
                    module={moduleType}
                >
                    {format(dateStateStart.current, dateFormat)}
                </DataPaneButton>
                <DateDivider>—</DateDivider>
                <DataPaneButton
                    active={!startDateActive}
                    onClick={() => {
                        setStartDateActive(false);
                    }}
                    module={moduleType}
                >
                    {format(dateStateEnd.current, dateFormat)}
                </DataPaneButton>
                <SelectButton
                    module={moduleType}
                    onClick={() => {
                        const timespan = getTimespan(
                            dateStateStart.current,
                            dateStateEnd.current,
                            moduleType === ModuleType.ENERGY
                        );
                        onDateChange(startOfDay(dateStateStart.current).toISOString(), getEndDate(), timespan);
                    }}
                >
                    <SelectIcon module={moduleType} src={selectIcon} />
                </SelectButton>
            </Header>
            <YearsWrapper>{getYears().map(renderYears)}</YearsWrapper>
            <MonthsWrapper>{Object.keys(CALENDAR_MONTHS).map(renderMonths)}</MonthsWrapper>
            <DaysWrapper>
                {Object.keys(WEEK_DAYS).map(renderDayLabel)}
                {getCalenderDates().map(startDateActive ? renderStartCalenderDay : renderEndCalenderDay)}
            </DaysWrapper>
        </DateSelectorWrapper>
    );
};

export default DateSelector;

const getTimespan = (startDate: Date, endDate: Date, isEnergyModule: boolean) => {
    const dayDifference = differenceInDays(endDate, startDate);

    if (dayDifference < 5) {
        return DateSpan.TWO_DAYS;
    }
    if (dayDifference < 15) {
        return DateSpan.WEEK;
    }

    if (isEnergyModule && dayDifference > 60) {
        return DateSpan.YEAR;
    }

    return DateSpan.MONTH;
};
