export type RouteType = 'air' | 'water' | 'mobility' | 'occupancy' | 'energy';

export const routes = {
    index: '/',
    dashboard: {
        issues: '/dashboard/issues',
        facilities: '/dashboard/facilities',
    },

    // Modules
    air: {
        issues: '/air/issues',
        statistics: '/air/statistics',
        preview: '/air/preview',
    },
    energy: {
        issues: '/energy/issues',
        statistics: '/energy/statistics',
        preview: '/energy/preview',
        ebs: '/energy/ebs',
        submeters: '/energy/submeters',
        gacs: '/energy/gacs',
    },
    water: {
        issues: '/water/issues',
        statistics: '/water/statistics',
        preview: '/water/preview',
    },
    occupancy: {
        issues: '/occupancy/issues',
        statistics: '/occupancy/statistics',
        preview: '/occupancy/preview',
    },
    mobility: {
        issues: '/mobility/issues',
        statistics: '/mobility/statistics',
        preview: '/mobility/preview',
    },
    feedback: {
        statistics: '/feedback/statistics',
        preview: '/feedback/preview',
    },
    sensors: {
        overview: '/sensors/overview',
        issues: '/sensors/issues',
    },

    // Profile
    profile: {
        me: '/profile',
        support: '/support',
        company: '/company',
        management: '/management',
    },

    // Login
    login: '/login',
    password_link: '/password-link',
    password_reset: '/reset',
};
