import { useQuery } from 'react-query';
import { addDays, isLeapYear, subYears } from 'date-fns';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { KpiUnit, Sensor, SensorAverage } from 'types';
import { SensorDataParam } from 'components/DataPane/statistics/energy/types';
import { EnergyData } from './types';
import { EwattchData, EwattchTimeserie } from 'views/authenticated/energy/types';

export const useEnergyData = (
    mainElectricityParams: SensorDataParam,
    ewattchParamas: SensorDataParam,
    gasParams: SensorDataParam,
    productionParams: SensorDataParam,
    feedinParams: SensorDataParam,
    consumptionSensors: Sensor[],
    productionSensors: Sensor[],
    feedinSensors: Sensor[]
) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        ['energy', mainElectricityParams, ewattchParamas, gasParams, productionParams, feedinParams],
        async ({ signal }): Promise<EnergyData> => {
            const promiseArray = createEndpoints(
                mainElectricityParams,
                ewattchParamas,
                gasParams,
                productionParams,
                feedinParams
            ).map(url => axiosClient.get<SensorAverage[]>(url, { signal }));
            const result = await Promise.all(promiseArray);

            let electricity;
            let electricityHistoric;
            if (mainElectricityParams.ids.length) {
                electricity = result[0]?.data || [];
                electricityHistoric = result[1]?.data || [];
            } else {
                electricity = result[0]?.data
                    ? mapToSensorAverage((result[0].data as unknown as EwattchData).measurements)
                    : [];
                electricityHistoric = result[1]?.data
                    ? mapToSensorAverage((result[1].data as unknown as EwattchData).measurements)
                    : [];
            }

            const gas = result[2]?.data || [];
            let gasHistoric = result[3]?.data || [];
            let production = result[4]?.data || [];
            let feedin = result[5]?.data || [];

            const maxPossibleGasTimeFrameFound = maxPossibleTimeFrame([gas]);
            gasHistoric = cutoffArrayBasedOnTimeFrame(gasHistoric, maxPossibleGasTimeFrameFound);

            const maxPossibleTimeFrameFound = maxPossibleTimeFrame([electricity, production, feedin]);
            electricityHistoric = cutoffArrayBasedOnTimeFrame(electricityHistoric, maxPossibleTimeFrameFound);
            electricity = cutoffArrayBasedOnTimeFrame(electricity, maxPossibleTimeFrameFound);
            production = cutoffArrayBasedOnTimeFrame(production, maxPossibleTimeFrameFound);
            feedin = cutoffArrayBasedOnTimeFrame(feedin, maxPossibleTimeFrameFound);

            // Convert all electricy data to kWh and convert all feedin data to negative values.
            if (consumptionSensors.some(s => s.unit === KpiUnit.WH)) {
                electricity = electricity.map(d => ({ value: Number((d.value / 1000).toFixed(2)), field: d.field }));
                electricityHistoric = electricityHistoric.map(d => ({
                    value: Number((d.value / 1000).toFixed(2)),
                    field: d.field,
                }));
            }

            if (productionSensors.some(s => s.unit === KpiUnit.WH)) {
                production = production.map(d => ({ value: Number((d.value / 1000).toFixed(2)), field: d.field }));
            }

            if (feedinSensors.some(s => s.unit === KpiUnit.WH)) {
                feedin = feedin.map(d => {
                    const toNegative = d.value < 0 ? d.value * -1 : d.value;
                    return { value: Number((toNegative / 1000).toFixed(2)), field: d.field };
                });
            }

            const actualElectricityUsage = calcActualElectricityUsage(electricity, production, feedin);

            return {
                actualElectricityUsage,
                electricity,
                electricityHistoric,
                gas,
                gasHistoric,
                production,
                feedin,
            };
        },
        {
            enabled:
                Boolean(mainElectricityParams.ids.length || ewattchParamas.ids.length) || Boolean(gasParams.ids.length),
        }
    );
};

const createEndpoints = (
    mainElectricityParams: SensorDataParam,
    ewattchParamas: SensorDataParam,
    gasParams: SensorDataParam,
    productionParams: SensorDataParam,
    feedinParams: SensorDataParam
): string[] => {
    const urlArray: string[] = [];
    if (mainElectricityParams.ids.length) {
        urlArray[0] = config.sensorDataSumTotal(
            mainElectricityParams.ids,
            mainElectricityParams.averageFilter,
            mainElectricityParams.from,
            mainElectricityParams.to
        );
        urlArray[1] = config.sensorDataSumTotal(
            mainElectricityParams.ids,
            mainElectricityParams.averageFilter,
            getDateOneYearBack(mainElectricityParams.from),
            getDateOneYearBack(mainElectricityParams.to)
        );
    }

    if (mainElectricityParams.ids.length === 0 && ewattchParamas.ids.length) {
        urlArray[0] = config.calculatedDataEwattch(ewattchParamas.ids[0], ewattchParamas.from, ewattchParamas.to);
        urlArray[1] = config.calculatedDataEwattch(
            ewattchParamas.ids[0],
            getDateOneYearBack(ewattchParamas.from),
            getDateOneYearBack(ewattchParamas.to)
        );
    }
    if (gasParams.ids.length) {
        urlArray[2] = config.sensorDataSumTotal(gasParams.ids, gasParams.averageFilter, gasParams.from, gasParams.to);
        urlArray[3] = config.sensorDataSumTotal(
            gasParams.ids,
            gasParams.averageFilter,
            getDateOneYearBack(gasParams.from),
            getDateOneYearBack(gasParams.to)
        );
    }
    if (productionParams.ids.length) {
        urlArray[4] = config.sensorDataSumTotal(
            productionParams.ids,
            productionParams.averageFilter,
            productionParams.from,
            productionParams.to
        );
    }
    if (feedinParams.ids.length) {
        urlArray[5] = config.sensorDataSumTotal(
            feedinParams.ids,
            feedinParams.averageFilter,
            feedinParams.from,
            feedinParams.to
        );
    }
    return urlArray;
};

// This function calculates the date of one year back,
// To match the same day of the week it also takes leapyears in consideration
const getDateOneYearBack = (dateString: string) => {
    const date = new Date(dateString);
    const dateLastYear = subYears(date, 1);

    return (isLeapYear(date) && ((date.getDate() === 29 && date.getMonth() === 1) || date.getMonth() > 1)) ||
        (isLeapYear(dateLastYear) && dateLastYear.getMonth() < 2)
        ? addDays(dateLastYear, 2).toISOString()
        : addDays(dateLastYear, 1).toISOString();
};

const cutoffArrayBasedOnTimeFrame = (
    fullArray: SensorAverage[],
    maxPossibleTimeFrameFound: number
): SensorAverage[] => {
    return fullArray.length > 0
        ? fullArray.filter(e => Date.parse(e.field) <= Date.parse(fullArray[0].field) + maxPossibleTimeFrameFound)
        : [];
};

const maxPossibleTimeFrame = (data: SensorAverage[][]): number => {
    const minDates = data.map(d => (d.length > 0 ? Date.parse(d[0].field) : 0));
    const maxDates = data.map(d => (d.length > 0 ? Date.parse(d[d.length - 1].field) : Number.MAX_VALUE));
    const minDate = Math.min(...minDates);
    const maxDate = Math.min(...maxDates);

    return maxDate > 0 ? maxDate - minDate : 0;
};

const calcActualElectricityUsage = (
    electricityMain: SensorAverage[],
    electricityProd: SensorAverage[],
    electricityFeedin: SensorAverage[]
): SensorAverage[] => {
    const concatElectricityUsage = [...electricityMain, ...electricityProd, ...electricityFeedin];

    const actualElectricityUsage = concatElectricityUsage.reduce((acc, cur, i) => {
        const item = i > 0 && acc.find(({ field }) => field === cur.field);
        if (item) item.value += cur.value;
        else acc.push({ field: cur.field, value: cur.value });
        return acc;
    }, []);

    return actualElectricityUsage;
};

const mapToSensorAverage = (ewattchTimeserie: EwattchTimeserie[]): SensorAverage[] => {
    return ewattchTimeserie.map(e => ({ value: e.v, field: e.t }));
};
