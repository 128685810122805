import React from 'react';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

import ButtonGroup, { ButtonGroupItem } from 'components/ButtonGroup';

import {
    FacilitiesBarWrapper,
    SubBarWrapper,
    SubBarWrapperBlack,
    SubNavBarWrapper,
    SubTitleWrapper,
} from 'styled/components/navigation';
import { BodyLeft, H3 } from 'styled/components/text';

import { DateSpan } from 'utils/timePeriod';
import SubNavigationBar from './SubNavigation/SubNavigationBar';
import { routes } from 'routing/routes';

const monthsOfYear = [
    i18n.t('common.time.months.januari'),
    i18n.t('common.time.months.februari'),
    i18n.t('common.time.months.march'),
    i18n.t('common.time.months.april'),
    i18n.t('common.time.months.may'),
    i18n.t('common.time.months.june'),
    i18n.t('common.time.months.july'),
    i18n.t('common.time.months.august'),
    i18n.t('common.time.months.september'),
    i18n.t('common.time.months.october'),
    i18n.t('common.time.months.november'),
    i18n.t('common.time.months.december'),
];

const monthNumber = new Date().getMonth();

const dashboardButtonItems: ButtonGroupItem[] = [
    {
        label: monthsOfYear[monthNumber === 0 ? 11 : monthNumber - 1],
        action: DateSpan.PREVIOUS_MONTH,
    },
    { label: monthsOfYear[monthNumber], action: DateSpan.CURRENT_MONTH },
];

const subNavItems = [
    {
        path: routes.dashboard.facilities,
        active: false,
        enabled: true,
        label: i18n.t('subMenu.dashboard.facilities'),
    },
    {
        path: routes.dashboard.issues,
        active: false,
        enabled: true,
        label: i18n.t('subMenu.dashboard.issues'),
    },
];

interface DashBoardSubMenuProps {
    action: string;
    onSelectAction: (period: string) => void;
}

const DashboardSubMenu: React.FunctionComponent<DashBoardSubMenuProps> = ({ action, onSelectAction }) => {
    const { t } = useTranslation();

    return (
        <>
            <SubBarWrapper>
                <SubNavBarWrapper>
                    <SubNavigationBar navigationItems={subNavItems} />
                </SubNavBarWrapper>
            </SubBarWrapper>
            <SubBarWrapperBlack>
                <SubTitleWrapper>
                    <H3>{t('subMenu.dashboard.facilitiesTitle')}</H3>
                </SubTitleWrapper>
                <FacilitiesBarWrapper>
                    <>
                        <BodyLeft>{t('common.time.period')}</BodyLeft>
                        <ButtonGroup
                            selectedItem={action}
                            inverted={true}
                            buttonGroupItems={dashboardButtonItems}
                            selectedAction={action => {
                                onSelectAction(action);
                            }}
                        />
                    </>
                </FacilitiesBarWrapper>
            </SubBarWrapperBlack>
        </>
    );
};

export default DashboardSubMenu;
