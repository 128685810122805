import React from 'react';

import { useTranslation } from 'react-i18next';

import { getThreshold } from 'styled/components/card';
import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import occupancyCircle from '../../../../assets/icons/i-circle-o.svg';
import airCircle from '../../../../assets/icons/i-circle-a-purple-to-red.svg';
import averageCircle from '../../../../assets/icons/i-circle-a-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { formatDuration } from 'utils/formatDuration';
import { Incident, KpiUnit } from 'types';

interface InfoHeaderProp {
    incident: Incident;
    isLoading?: boolean;
}
const AirQualityOccupancy: React.FunctionComponent<InfoHeaderProp> = ({ incident, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{getThreshold(incident)}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.maxValue')}
                    <br />
                    {t('infoHeader.common.detected')}
                </CaptionLefWhite>
            </div>
            <div>
                {formatDuration(incident.duration) && (
                    <>
                        <H2White>{formatDuration(incident.duration)}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.common.duration')} <br />
                            {t('infoHeader.air.climateCondition')}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
            <div>
                <LegendIcon src={occupancyCircle} />
                <CaptionLefWhite>{t('infoHeader.occupancy.motion')}</CaptionLefWhite>
                <LegendIcon src={airCircle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: KpiUnit.PPM })}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={averageCircle} />
                        <CaptionLefWhite>
                            {t('infoHeader.common.loadingAverage', { unit: KpiUnit.PPM })}
                        </CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={averageCircle} />
                        <CaptionLefWhite>
                            {t('infoHeader.common.averageOverTime', { unit: KpiUnit.PPM })}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default AirQualityOccupancy;
