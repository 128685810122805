import React, { useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import Modal from 'components/Modal';

import { SubMenuButton } from 'styled/components/buttons';
import { AssistanceWrapper } from 'styled/components/ebs';

import { useFeedbackMutation } from 'hooks/api/mutate/useFeedbackMutation';

import { ModuleType } from 'types';
import { config } from 'config';

import { SupportType } from 'views/authenticated/profile/types';

const AssistanceHelper: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { mutate, isLoading } = useFeedbackMutation();
    const [showModal, setShowModal] = useState(false);

    const handleSubmit = () => {
        const payload = {
            client_id: config.oauth.clientId,
            client_secret: config.oauth.clientSecret,
            type: SupportType.QUESTION,
            header: t('energy.ebs.assistanceHelper.emailHeader'),
            message: t('energy.ebs.assistanceHelper.emailText'),
        };
        mutate(payload, {
            onSuccess: () => {
                setShowModal(false);
            },
        });
    };

    return (
        <AssistanceWrapper>
            <SubMenuButton active={true} module={ModuleType.ENERGY} onClick={() => setShowModal(true)}>
                {t('energy.ebs.assistanceHelper.button')}
            </SubMenuButton>

            <Modal show={showModal} onClose={() => setShowModal(false)} title={t('energy.ebs.assistanceHelper.button')}>
                <div>
                    <Trans i18nKey="energy.ebs.assistanceHelper.text" />
                </div>
                <br />
                <div>
                    <SubMenuButton
                        disabled={isLoading}
                        active={true}
                        module={ModuleType.ENERGY}
                        onClick={() => handleSubmit()}
                    >
                        {t('energy.ebs.assistanceHelper.sendButton')}
                    </SubMenuButton>
                </div>
            </Modal>
        </AssistanceWrapper>
    );
};

export default AssistanceHelper;
