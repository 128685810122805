import styled from 'styled-components';
import { theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';

import i18n from '../../i18n';

import {
    Incident,
    AnomalySubTypeAir,
    AnomalySubTypeOccupancy,
    AnomalySubTypeEnergy,
    AnomalySubTypeWater,
    AnomalySubTypeSensor,
    KpiUnit,
} from 'types';

export const EmptyTD = styled.td`
    flex: 1;
    max-width: 150px;
    @media (max-width: 1200px) {
        flex: 0 !important;
    }
    border-bottom: 0 !important;
`;

export const EmptyTH = styled.th`
    flex: 1;
    max-width: 150px;
    @media (max-width: 1200px) {
        flex: 0 !important;
    }
    border-bottom: 0 !important;
`;

export const NonSortableHeader = styled.div`
    font-weight: normal;
`;

export const StyledTable = styled.div`
    margin-bottom: 7px;
    overflow: auto;
    padding: 0rem 1rem 1rem;
    table {
        width: 100%;
        border-spacing: 0;
        display: flex;
        flex-direction: column;
        thead {
            background: ${theme.colors.bg};
            position: sticky;
            top: 0;
            z-index: 2;
            tr {
                th {
                    border-bottom: 1px solid ${theme.colors.grey};
                }
            }
        }
        tr {
            align-items: center;
            display: flex;
            flex: 1;
            height: 50px;
        }
        th,
        td {
            display: flex;
            align-items: center;
            height: 100%;
            flex: 1;
            padding-right: 20px;
            margin: 0;
            padding: 0 0.5rem;
        }
        tbody {
            tr {
                td {
                    border-bottom: 1px solid ${theme.colors.grey};
                    :last-child {
                        border-bottom: 0;
                    }
                }
                :last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
        td {
            cursor: pointer;
        }
    }
`;

export const AirStatisticsStyledTable = styled(StyledTable)`
    min-height: 20vh;
`;

export const FeedbackStyledTable = styled.div`
    table {
        width: 100%;
        /* border-spacing: 0; */
        thead {
            background: ${theme.colors.text};
            color: ${theme.colors.white};
            tr {
                th {
                    border-bottom: 1px solid ${theme.colors.grey};
                }
                th: nth-child(2) {
                    flex: 1;
                }
            }
        }
        tr {
            align-items: center;
            display: flex;
            flex: 1;
            height: 50px;
        }
        th,
        td {
            display: flex;
            align-items: center;
            height: 100%;
            /* flex: 1; */
            padding-right: 20px;
            margin: 0;
            padding: 0 0.5rem;
            color: ${theme.colors.white};
        }
        tbody {
            tr {
                td {
                    border-bottom: 1px solid ${theme.colors.greyD};
                }
                td: nth-child(2) {
                    flex: 1;
                }
                :last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
`;

export const ContractStyledTable = styled.div`
    table {
        width: 100%;
        tr {
            align-items: center;
            display: flex;
            flex: 1;
            height: 50px;
        }
        th,
        td {
            display: flex;
            align-items: center;
            height: 100%;
            flex: 1;
            padding-right: 20px;
            margin: 0;
            padding: 0 0.5rem;
        }
        tbody {
            tr {
                td {
                    border-top: 1px solid ${theme.colors.grey};
                }
            }
        }
    }
`;

export const TableIcon = styled(SVG)`
    width: 12px;
    height: 12px;
    margin-right: 6px;
    margin: auto;
    & path {
        fill: ${theme.colors.text};
    }
`;

export const TableIconWrapper = styled.div`
    display: flex;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: none;
`;

export const getThreshold = (incident: Incident) => {
    const Celsius = '\u{2103}';
    switch (incident.anomalySubType) {
        // AIR
        case AnomalySubTypeAir.AIR_QUALITY:
            return `${incident.value}ppm`;
        case AnomalySubTypeAir.ABNORMAL_HUMIDITY:
            return `${incident.value}ppm`;
        case AnomalySubTypeAir.ABNORMAL_TEMPERATURE:
            return `${incident.value}${Celsius}`;
        case AnomalySubTypeAir.HIGH_TEMPERATURE:
            return `${incident.value}${Celsius}`;
        case AnomalySubTypeAir.RAPID_TEMPERATURE_INCREASE:
            return `${incident.value}${Celsius}`;

        // ENERGY
        case AnomalySubTypeEnergy.ENERGY_ABOVE_AVERAGE_CONSUMPTION:
            return `${incident.value.toFixed(2)}%`;

        //WATER
        case AnomalySubTypeWater.WATER_ABOVE_AVERAGE_CONSUMPTION:
            return `${incident.value.toFixed(2)}%`;
        case AnomalySubTypeWater.WATER_LOW_OCCUPANCY_CONSUMPTION:
        case AnomalySubTypeWater.WATER_ZERO_CONSUMPTION:
            return `${incident.value}`;
        case AnomalySubTypeWater.WATER_HIGH_AIR_TEMPERATURE:
            return `${incident.value}${Celsius}`;

        // OCCUPANCY
        case AnomalySubTypeOccupancy.MOTION_DETECTED:
            return `${incident.total} `;

        // SENSORS
        case AnomalySubTypeSensor.LOW_BATTERY:
            return `${incident.value.toFixed(2)} ${KpiUnit.V}`;
        case AnomalySubTypeSensor.NO_DATA:
            return i18n.t('sensors.unknown');

        default:
            console.error('Invalid AnomalyType!');
            return i18n.t('sensors.unknown');
    }
};

export const FeedbackTablesWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: flex-start;
`;

export const FeedbackTableWrapper = styled.div`
    flex: 50%;
    margin-right: 30px;
`;
