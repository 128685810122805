import React, { useState } from 'react';

import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import ElectricityConsumptionGraph from 'components/Graph/statistics/energy/ElectricityConsumptionGraph';
import ElectricityConsumptionHeader from './infoHeader/ElectricityConsumptionHeader';

import { ContentWrapper, InfoHeader, LoadingWrapper, SensorGraphWrapper } from 'styled/components/dataPane';

import { ElectricityGraphData } from './types';
import { Sensor } from 'types';
import { BuildingConstants, TotalUsage } from 'views/authenticated/energy/types';
import { getFirstDataTimestamp, getLastKpiUpdateTimestamp } from './energyStatistics.helper';

interface ElectricityConsumptionProps {
    totalUsage: TotalUsage;
    graphData: ElectricityGraphData | null;
    sensors: Sensor[];
    isLoadingConsumption: boolean;
    isBarChart: boolean;
    buildingConstants: BuildingConstants;
}
const ElectricityConsumption: React.FunctionComponent<ElectricityConsumptionProps> = ({
    totalUsage,
    graphData,
    sensors,
    isLoadingConsumption,
    isBarChart,
    buildingConstants,
}) => {
    const [showTotal, setShowTotal] = useState(true);
    const [showMeter1, setShowMeter1] = useState(true);
    const [showMeter2, setShowMeter2] = useState(true);
    const [showMeter3, setShowMeter3] = useState(true);
    const [showMeter4, setShowMeter4] = useState(true);
    const [showMeter5, setShowMeter5] = useState(true);

    const lastUpdate = getLastKpiUpdateTimestamp(sensors);
    const firstData = getFirstDataTimestamp(sensors);
    const totalConsumption = totalUsage.totalUsage || 0;
    const totalEmission = Number(
        ((totalUsage?.consumption || 0) * buildingConstants.electricityConstants.co2Emission).toFixed()
    );

    return (
        <>
            <ContentWrapper>
                {(isLoadingConsumption || graphData === null) && (
                    <LoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption && graphData?.totalElectricityData.length === 0 && (
                    <LoadingWrapper>
                        <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption && Boolean(graphData?.totalElectricityData.length) && (
                    <>
                        <InfoHeader>
                            <ElectricityConsumptionHeader
                                totalConsumption={totalConsumption}
                                totalEmission={totalEmission}
                                showTotal={showTotal}
                                onShowTotal={setShowTotal}
                                showMeter1={showMeter1}
                                meter1Name={graphData.meter1.length ? graphData.meter1[0].name : undefined}
                                onShowMeter1={graphData.meter1.length ? setShowMeter1 : undefined}
                                showMeter2={showMeter2}
                                meter2Name={graphData.meter2.length ? graphData.meter2[0].name : undefined}
                                onShowMeter2={graphData.meter2.length ? setShowMeter2 : undefined}
                                showMeter3={showMeter3}
                                meter3Name={graphData.meter3.length ? graphData.meter3[0].name : undefined}
                                onShowMeter3={graphData.meter3.length ? setShowMeter3 : undefined}
                                showMeter4={showMeter4}
                                meter4Name={graphData.meter4.length ? graphData.meter4[0].name : undefined}
                                onShowMeter4={graphData.meter4.length ? setShowMeter4 : undefined}
                                showMeter5={showMeter5}
                                meter5Name={graphData.meter5.length ? graphData.meter5[0].name : undefined}
                                onShowMeter5={graphData.meter5.length ? setShowMeter5 : undefined}
                            />
                        </InfoHeader>
                        <SensorGraphWrapper>
                            <ElectricityConsumptionGraph
                                graphData={graphData}
                                isBarChart={isBarChart}
                                showTotal={showTotal}
                                showMeter1={showMeter1}
                                showMeter2={showMeter2}
                                showMeter3={showMeter3}
                                showMeter4={showMeter4}
                                showMeter5={showMeter5}
                            />
                        </SensorGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </>
    );
};

export default ElectricityConsumption;
