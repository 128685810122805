import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { OccupancyData, OccupancyScore, Sensor } from 'types';

import { OccupancyDataParam } from 'components/DataPane/statistics/energy/types';
import { sumAndAverageOccupancyData } from 'components/DataPane/statistics/occupancy/occupancyStatistics.helper';

export const useEnergyOccupancyData = (
    occupancyParams: OccupancyDataParam,
    selectedRoom: string,
    sensors: Sensor[]
) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        [
            'energyOccupancy',
            occupancyParams.building,
            occupancyParams.from,
            occupancyParams.to,
            selectedRoom,
            occupancyParams.averageFilter || 'noFiler',
        ],
        async ({ signal }) => {
            if (occupancyParams.averageFilter) {
                const { data } = await axiosClient.get<OccupancyData[]>(
                    getDailyAvgOccupancyEndpoint(occupancyParams, selectedRoom),
                    {
                        signal,
                    }
                );

                return data;
            }

            const { data } = await axiosClient.get<OccupancyScore[]>(
                getOccupancyEndpoint(occupancyParams, selectedRoom),
                {
                    signal,
                }
            );

            const occupancyData = sumAndAverageOccupancyData(data);
            return occupancyData;
        },
        {
            enabled: Boolean(sensors.length) && Boolean(occupancyParams.building),
        }
    );
};

const getDailyAvgOccupancyEndpoint = (occupancyParams: OccupancyDataParam, selectedRoom: string) => {
    return occupancyParams.building && selectedRoom
        ? config.occupancyRoomExpandedDailyAvg(
              occupancyParams.building,
              selectedRoom,
              occupancyParams.from,
              occupancyParams.to
          )
        : config.occupancyBuildingDailyAvg(occupancyParams.building, occupancyParams.from, occupancyParams.to);
};

const getOccupancyEndpoint = (occupancyParams: OccupancyDataParam, selectedRoom: string) => {
    return occupancyParams.building && selectedRoom
        ? config.occupancyRoomExpanded(occupancyParams.building, selectedRoom, occupancyParams.from, occupancyParams.to)
        : config.occupancyBuilding(occupancyParams.building, occupancyParams.from, occupancyParams.to);
};
