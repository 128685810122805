import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { Sensor } from 'types';

export const useAllSensorsData = () => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['allSensors'], async ({ signal }) => {
        const { data } = await axiosClient.get<Sensor[]>(config.sensorsListView, {
            signal,
        });

        return data;
    });
};
