import React from 'react';

import { useTranslation } from 'react-i18next';

import { CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';
import { theme } from 'styled/Theme';

import circle from '../../../../assets/icons/i-circle-default.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { Incident, KpiUnit } from 'types';

interface InfoHeaderProp {
    incident: Incident;
    isLoading?: boolean;
}
const LowBattery: React.FunctionComponent<InfoHeaderProp> = ({ isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div />
            <div />
            <div>
                <LegendIcon color={theme.colors.mCleanL} src={circle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: KpiUnit.V })}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon color={theme.colors.airL} src={circle} />
                        <CaptionLefWhite>{t('infoHeader.common.loadingAverage', { unit: KpiUnit.V })}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon color={theme.colors.airL} src={circle} />
                        <CaptionLefWhite>{t('infoHeader.common.averageOverTime', { unit: KpiUnit.V })}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default LowBattery;
