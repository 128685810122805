import React from 'react';

import { useTranslation } from 'react-i18next';

import { getMetricUnit, StyledKPIValue } from 'styled/components/sensorSelectorAir';
import { AirMetricsAction, KPI } from 'types';

interface KPIValueProps {
    isActive: boolean;
    averageKpi: KPI;
    metricType: string;
}
const KPIValue: React.FunctionComponent<KPIValueProps> = ({ averageKpi, metricType, isActive }) => {
    const { t } = useTranslation();

    return (
        <StyledKPIValue isActive={isActive} KPIStatus={averageKpi.kpi}>
            {averageKpi.value
                ? `${
                      metricType === AirMetricsAction.TEMPERATURE
                          ? averageKpi.value.toFixed(1)
                          : averageKpi.value.toFixed(0)
                  } ${getMetricUnit(metricType)}`
                : t('sensors.unknown')}
        </StyledKPIValue>
    );
};

export default KPIValue;
