import React, { useMemo, useRef } from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { Column, Row } from 'react-table';

import IncidentsContentWrapper from 'components/Container/IncidentsContentWrapper';

import Table from '.';

import { getTitle } from 'styled/components/card';
import { getThreshold } from 'styled/components/table';

import { SelectedIncident } from 'hooks/useIncident';

import { Incident } from 'types';
import { formatDurationExtended } from 'utils/formatDuration';

const createLocationName = (siteName: string, equipsName: string[]) => {
    return `${siteName} > ${equipsName.join(' > ')}`;
};

type IncidentsTableData = {
    //id and timestampId are required to preselect correct row
    id: string;
    timestampId: string;
    where: string;
    duration: string;
    value: string;
    // UTC date-time to be precise
    timestamp: string;
    anomalySubType: string;
};

interface IncidentsTableProps {
    selectedIncident?: SelectedIncident;
    incidents: Incident[];
    moduleIncidents?: boolean;
    handleSelect: (id: string, timestamp: string) => void;
}

const IncidentsTable: React.FunctionComponent<IncidentsTableProps> = ({
    selectedIncident,
    incidents,
    handleSelect,
    moduleIncidents = true,
}) => {
    const { t } = useTranslation();

    const mappedIncidents: IncidentsTableData[] = useMemo(
        () =>
            incidents.map(
                ({
                    pointId,
                    siteName,
                    equipsName,
                    anomalySubType,
                    timestamp,
                    timestampStart,
                    value,
                    valueBefore,
                    duration,
                    total,
                }) => ({
                    id: pointId,
                    timestampId: timestamp,
                    where: createLocationName(siteName, equipsName),
                    anomalySubType: getTitle(anomalySubType, value, valueBefore),
                    timestamp: format(new Date(timestampStart), 'dd MMM yyyy, H:mm'),
                    value: getThreshold({ anomalySubType, value, total } as Incident),
                    duration: formatDurationExtended(duration),
                })
            ),
        [incidents]
    );

    const headers: Column<IncidentsTableData>[] = useMemo(
        () => [
            // id and timestampId are required to preselect correct row
            // Although both are hidden in table
            {
                Header: 'Hidden Id',
                accessor: 'id',
            },
            {
                Header: 'Hidden Timestamp Id',
                accessor: 'timestampId',
            },
            {
                Header: t('table.where') as string,
                accessor: 'where',
            },
            {
                Header: t('table.what') as string,
                accessor: 'anomalySubType',
            },
            {
                Header: t('table.when') as string,
                accessor: 'timestamp',
                sortType: customWhenSort,
            },
            {
                Header: t('table.duration') as string,
                accessor: 'duration',
                sortType: customDurationSort,
            },
            {
                Header: t('table.value') as string,
                accessor: 'value',
                sortType: customValueSort,
            },
        ],
        []
    );

    const selectedRow = useMemo(() => findRow(mappedIncidents, selectedIncident), [mappedIncidents, selectedIncident]);

    return incidents.length ? (
        <Table
            tableHeaders={headers}
            tableData={mappedIncidents}
            handleSelect={handleSelect}
            hiddenColumns={['id', 'timestampId']}
            selectedRow={selectedRow}
        />
    ) : moduleIncidents ? (
        <IncidentsContentWrapper>{t('incidents.noModuleIncidents')}</IncidentsContentWrapper>
    ) : (
        <IncidentsContentWrapper>{t('incidents.noIncidents')}</IncidentsContentWrapper>
    );
};

export default IncidentsTable;

const findRow = (data: IncidentsTableData[], incident?: SelectedIncident) => {
    if (!incident) {
        return;
    }
    const i = data.findIndex(d => d.id === incident.id && d.timestampId === incident.timestamp);
    if (i === -1) {
        return;
    }
    return { [i]: true };
};

const hasNoDuration = (duration: string) => {
    return duration.includes('-');
};

const isDayDuration = (duration: string) => {
    return duration.substring(0, 3).includes('d');
};

const isHourDuration = (duration: string) => {
    return duration.substring(0, 3).includes('h');
};

const isMinuteDuration = (duration: string) => {
    return duration.substring(0, 3).includes('m');
};

const parseAndStripDuration = (duration: string) => {
    return parseInt(duration.replace(/\D/g, ''));
};

const customDurationSort: any = (rowA: Row, rowB: Row, columnId: string) => {
    const valueRowA = rowA.values[columnId];
    const valueRowB = rowB.values[columnId];
    if (hasNoDuration(valueRowA)) {
        return 1;
    }
    if (hasNoDuration(valueRowB)) {
        return -1;
    }
    if (isDayDuration(valueRowA) && !isDayDuration(valueRowB)) return -1;
    if (isDayDuration(valueRowB) && !isDayDuration(valueRowA)) return 1;
    if (isDayDuration(valueRowA) && isDayDuration(valueRowB)) {
        return parseAndStripDuration(valueRowA) > parseAndStripDuration(valueRowB) ? -1 : 1;
    }
    if (isHourDuration(valueRowA) && !isHourDuration(valueRowB)) return -1;
    if (isHourDuration(valueRowA) && isHourDuration(valueRowB)) {
        return parseAndStripDuration(valueRowA) > parseAndStripDuration(valueRowB) ? -1 : 1;
    }
    if (isMinuteDuration(valueRowA) && isMinuteDuration(valueRowB))
        return parseAndStripDuration(valueRowA) > parseAndStripDuration(valueRowB) ? -1 : 1;
};

const customWhenSort: any = (rowA: Row, rowB: Row, columnId: string) => {
    return new Date(rowA.values[columnId]) > new Date(rowB.values[columnId]) ? -1 : 1;
};

const customValueSort: any = (rowA: Row, rowB: Row, columnId: string) => {
    return parseFloat(rowA.values[columnId]) > parseFloat(rowB.values[columnId]) ? -1 : 1;
};
