import styled, { keyframes } from 'styled-components';

const cubeMove = keyframes`
    0% {
        top: 2px;
        height: 16px;
    } 50%, 100% {
        top: 4px;
        height: 12px;
    }
`;

export const StyledLoadingIndicator = styled.div`
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;

    div {
        display: inline-block;
        position: absolute;
        width: 8px;
        background: #fff;
        animation: ${cubeMove} 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    div:nth-child(1) {
        animation-delay: -0.24s;
    }

    div:nth-child(2) {
        left: 12px;
        animation-delay: -0.12s;
    }

    div:nth-child(3) {
        left: 24px;
        animation-delay: 0;
    }
`;
