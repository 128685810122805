import React from 'react';

import i18n from '../../i18n';

import { routes, RouteType } from 'routing/routes';

import SubNavigationBar from 'components/Submenu/SubNavigation/SubNavigationBar';
import { NavigationItem } from 'components/NavigationBar';
import SearchAutoComplete, { SpaceSuggestion } from 'components/SearchAutoComplete';

import { SubBarWrapper, SubNavBarWrapper } from 'styled/components/navigation';

import { ModuleType } from 'types';

const createNavItems = (moduleType: ModuleType): NavigationItem[] => {
    const module = moduleType.toLowerCase() as RouteType;
    return [
        {
            path: routes[module].statistics,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.statistics'),
            moduleType: moduleType,
        },
        {
            path: routes[module].issues,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.issues'),
            moduleType: moduleType,
        },
    ];
};

const createNavItemsEnergy = (): NavigationItem[] => {
    return [
        {
            path: routes.energy.statistics,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.statistics'),
            moduleType: ModuleType.ENERGY,
        },
        {
            path: routes.energy.ebs,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.ebs'),
            moduleType: ModuleType.ENERGY,
        },
        {
            path: routes.energy.issues,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.issues'),
            moduleType: ModuleType.ENERGY,
        },
        {
            path: routes.energy.submeters,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.meters'),
            moduleType: ModuleType.ENERGY,
        },
        {
            path: routes.energy.gacs,
            active: false,
            enabled: true,
            label: i18n.t('subMenu.modules.gacs'),
            moduleType: ModuleType.ENERGY,
        },
    ];
};

export const navItemsAir = createNavItems(ModuleType.AIR);
export const navItemsEnergy = createNavItemsEnergy();
export const navItemsWater = createNavItems(ModuleType.WATER);
export const navItemsOccupancy = createNavItems(ModuleType.OCCUPANCY);
export const navItemsMobility = createNavItems(ModuleType.MOBILITY);

interface ModulesSubMenu {
    subNavItems: NavigationItem[];
    suggestions: SpaceSuggestion[];
    onSearch: (suggestion: SpaceSuggestion) => void;
    onClearSearch?: () => void;
    breadCrumbs?: string[];
}

const ModulesSubMenu: React.FunctionComponent<ModulesSubMenu> = ({
    subNavItems,
    suggestions,
    onSearch,
    onClearSearch,
    breadCrumbs,
}) => {
    return (
        <SubBarWrapper>
            <SubNavBarWrapper>
                <SubNavigationBar navigationItems={subNavItems} />
            </SubNavBarWrapper>
            <SearchAutoComplete
                breadCrumbs={breadCrumbs}
                suggestions={suggestions}
                onSearch={onSearch}
                onClearSearch={() => {
                    onClearSearch && onClearSearch();
                }}
            />
        </SubBarWrapper>
    );
};

export default ModulesSubMenu;
