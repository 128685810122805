import styled from 'styled-components';
import { theme, getColorByPercentage } from 'styled/Theme';

export const RoomTrackerWrapper = styled.div`
    width: 60px;
`;

export const PercentageIndicator = styled.div`
    overflow: hidden;
    width: 60px;
    height: 6px;
    border-radius: 2px;
    background-color: ${theme.colors.greyD};
    margin-block: 10px 5px;
`;

interface PercentageBar {
    percentage: number;
}
export const PercentageBar = styled.div<PercentageBar>`
    height: 6px;
    width: ${({ percentage }) => percentage}%;
    background-color: ${({ percentage }) => getColorByPercentage(percentage)};
`;
