import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { ModuleType, Sensor } from 'types';

export const useSensorsByModulesData = (modules: ModuleType[]) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(['sensorsByModule', modules], async ({ signal }): Promise<Sensor[][]> => {
        const promiseArray = modules
            .map(m => config.sensorsListViewByModule(m))
            .map(url => axiosClient.get<Sensor[]>(url, { signal }));

        const result = await Promise.all(promiseArray);

        return result.map(r => r.data);
    });
};
