import React from 'react';

import { useTranslation } from 'react-i18next';

import Footer from 'components/Footer';

import {
    BorderedContainer,
    CaptionPreview,
    EnergyContainer,
    GetContainer,
    HeaderContainer,
    HeaderGrid,
    HeartIcon,
    HexaconBackground,
    Icon,
    IconContainer,
    IconWrapper,
    IncidentsContainer,
    IncidentGrid,
    IncidentWrapper,
    ImageContainer,
    ModuleContainer,
    ModuleIcon,
    ModuleText,
    ModuleTitle,
    PreviewGrid,
    PreviewLead,
    PreviewText,
    SoonSpan,
    SupportBackground,
    SupportContentContainer,
    SupportGrid,
    StatsContainer,
    StatisticGrid,
    StatisticsContainer,
} from 'styled/components/preview';
import { H3White } from 'styled/components/text';

import occupancyIcon from '../../../assets/icons/i-m-occupancy.svg';
import occupancyHeart from '../../../assets/icons/heart-occupancy-preview.svg';
import incidentPreview1 from '../../../assets/images/preview/o-preview-1.webp';
import incidentPreview2 from '../../../assets/images/preview/o-preview-2.webp';
import occupancyScreen from '../../../assets/images/preview/o-preview-screen.webp';
import moduleIcon from '../../../assets/icons/preview-module-icon-occupancy.svg';

import { ModuleType } from 'types';

import { config } from 'config';

const OccupancyPreviewView: React.FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <>
            <HeaderGrid>
                <GetContainer />
                <EnergyContainer>
                    <ModuleTitle module={ModuleType.OCCUPANCY}>Occupancy</ModuleTitle>
                </EnergyContainer>
            </HeaderGrid>
            <PreviewGrid>
                <SupportGrid>
                    <IconContainer>
                        <SupportBackground />
                        <IconWrapper>
                            <ModuleIcon src={moduleIcon} />
                        </IconWrapper>
                    </IconContainer>
                    <ModuleContainer>
                        <ModuleText>module</ModuleText>
                    </ModuleContainer>
                    <SupportContentContainer>
                        <PreviewText>{t('preview.occupancy.support')}</PreviewText>
                        <PreviewText>{t('preview.support1')}</PreviewText>
                        <PreviewText>
                            {t('preview.support2')} <span style={{ fontWeight: 'bold' }}>{config.profile.mail}</span>
                        </PreviewText>
                    </SupportContentContainer>
                </SupportGrid>
                <IncidentGrid>
                    <HeaderContainer>
                        <H3White>{t('preview.incidentHeader')}</H3White>
                    </HeaderContainer>
                    <BorderedContainer />
                    <IncidentsContainer>
                        <IncidentWrapper>
                            <img src={incidentPreview1} alt={''} width="290" height="150" />
                            <div>
                                <PreviewLead>
                                    <Icon src={occupancyIcon} />
                                    {t('preview.occupancy.incidents.header1')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.occupancy.incidents.content1')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                        <IncidentWrapper>
                            <img src={incidentPreview2} alt={''} width="290" height="150" />
                            <div>
                                <PreviewLead>
                                    <Icon src={occupancyIcon} />
                                    <SoonSpan>{t('preview.soon')}</SoonSpan>
                                    {t('preview.occupancy.incidents.header2')}
                                </PreviewLead>
                                <PreviewText hasPadding={true}>{t('preview.occupancy.incidents.content2')}</PreviewText>
                            </div>
                        </IncidentWrapper>
                    </IncidentsContainer>
                    <StatsContainer>
                        <div>
                            <ModuleTitle module={ModuleType.OCCUPANCY} style={{ fontSize: '18px' }}>
                                {t('preview.occupancy.stats.insights')}
                            </ModuleTitle>
                            <PreviewText>{t('preview.occupancy.stats.activate')}</PreviewText>
                        </div>
                        <div>
                            <CaptionPreview>{t('preview.atTheMoment')}</CaptionPreview>
                            <ModuleTitle module={ModuleType.OCCUPANCY}>
                                84 <span style={{ fontSize: '18px' }}>{t('preview.buildings')}</span>
                            </ModuleTitle>
                            <PreviewText>{t('preview.connectedBuildings')}</PreviewText>
                        </div>
                        <div>
                            <HeartIcon src={occupancyHeart} />
                            <PreviewText>{t('preview.occupancy.stats.communicate')}</PreviewText>
                        </div>
                    </StatsContainer>
                </IncidentGrid>
                <StatisticGrid>
                    <HeaderContainer>
                        <H3White>{t('preview.occupancy.statistics.title')}</H3White>
                    </HeaderContainer>
                    <ImageContainer>
                        <img src={occupancyScreen} alt={'energy module screen'} width={740} />
                    </ImageContainer>
                    <StatisticsContainer>
                        <div>
                            <PreviewLead>
                                <Icon src={occupancyIcon} />
                                {t('preview.occupancy.statistics.header1')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.occupancy.statistics.content1')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={occupancyIcon} />
                                {t('preview.occupancy.statistics.header2')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.occupancy.statistics.content2')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={occupancyIcon} />
                                {t('preview.occupancy.statistics.header3')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.occupancy.statistics.content3')}</PreviewText>
                        </div>
                        <div>
                            <PreviewLead>
                                <Icon src={occupancyIcon} />
                                <SoonSpan>{t('preview.soon')}</SoonSpan>
                                {t('preview.occupancy.statistics.header4')}
                            </PreviewLead>
                            <PreviewText hasPadding={true}>{t('preview.occupancy.statistics.content4')}</PreviewText>
                        </div>
                    </StatisticsContainer>
                </StatisticGrid>
                <HexaconBackground />
            </PreviewGrid>
            <Footer />
        </>
    );
};

export default OccupancyPreviewView;
