import { CSVLink } from 'react-csv';
import styled from 'styled-components';
import { theme } from 'styled/Theme';

export const TopNavBar = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    align-content: flex-start;
    flex-wrap: wrap;
    border-bottom: 1px solid ${theme.colors.grey};
`;

export const TitleBar = styled.div`
    margin-left: 30px;
    height: 100%;
    width: 130px;
    border-right: 1px solid ${theme.colors.grey};

    img {
        margin-top: 24px;
    }
`;

export const FooterTitleBar = styled(TitleBar)`
    border-right: 1px solid ${theme.colors.greyD};

    img {
        margin-top: 35px;
    }
`;

export const SubTitleWrapper = styled.div`
    padding-right: 20px;
    margin-left: 30px;
    display: flex;
    height: 100%;
    min-width: 130px;
    align-items: center;
`;

export const SensorsSubTitleWrapper = styled(SubTitleWrapper)`
    padding-right: 30px;
    border-right: 1px solid ${theme.colors.grey};
    min-width: unset;
`;

export const ContentWrapper = styled.div`
    margin-left: 30px;
`;

export const SubNavBarWrapper = styled(SubTitleWrapper)`
    border-right: 1px solid ${theme.colors.grey};
`;

export const FlexBar = styled.div`
    display: flex;
`;

export const ModulesBar = styled.div`
    display: flex;
    margin-left: 15px;
`;

export const FooterLinksBar = styled(FlexBar)`
    margin-left: 50px;
    color: ${theme.colors.white};
`;

export const SearchBarWrapper = styled(FlexBar)`
    flex: 1;
    margin-left: auto;
    flex-direction: column;
`;

export const FacilitiesBarWrapper = styled(FlexBar)`
    padding-right: 20px;
    margin-left: auto;
    align-items: center;
`;

export const CopyrightBar = styled(FacilitiesBarWrapper)`
    color: ${props => props.theme.colors.grey};
    margin-right: 32px;
`;

interface IconBarWrapperInterface {
    active: boolean;
}
export const IconBarWrapper = styled(FlexBar)<IconBarWrapperInterface>`
    cursor: pointer;
    height: 60px;
    width: 60px;
    background-color: ${({ active, theme }) => (active ? theme.colors.text : 'none')};
    border-left: 1px solid ${theme.colors.grey};
`;

export const IconBarWrapperBlack = styled(IconBarWrapper)`
    background-color: ${({ active, theme }) => (active ? theme.colors.bg : 'none')};
    border-left: 1px solid ${theme.colors.greyD};
`;

export const PaneIconBarWrapper = styled(FlexBar)`
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-left: 1px solid ${theme.colors.greyD};
`;

export const CSVLinkWrapper = styled(CSVLink)`
    display: flex;
    cursor: pointer;
    height: 60px;
    width: 60px;
    border-left: 1px solid ${theme.colors.greyD};
`;

export const MenuBar = styled(IconBarWrapper)`
    margin-left: auto;
`;

export const SideMenuBar = styled.div`
    z-index: 999;
    position: fixed;
    top: 60px;
    right: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
`;

export const SubBarWrapper = styled.div`
    height: 60px;
    display: flex;
    align-content: flex-start;
    border-bottom: 1px solid ${theme.colors.grey};
`;

export const SubBarWrapperBlack = styled(SubBarWrapper)`
    min-height: 60px;
    background-color: ${theme.colors.text};
    color: ${theme.colors.white};
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const ProgressWrapper = styled(SubBarWrapperBlack)`
    position: sticky;
    top: 0px;
    z-index: 99;
`;

export const EBSBarWrapper = styled(SubBarWrapperBlack)`
    padding: 0px 60px;
    align-items: center;
    justify-content: space-between;
`;

export const DateWrapper = styled.div`
    margin-right: 10px;
`;

export const InactiveWrapper = styled.div`
    padding-left: 15px;
    border-left: 1px solid ${theme.colors.grey};
`;

export const SubMenuText = styled.p`
    margin: 0 0 0 0;
    line-height: normal;
    width: 68px;
    font-size: 14px;
`;
