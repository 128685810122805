import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import consumptionCircle from '../../../../assets/icons/i-circle-e.svg';
import productionCircle from '../../../../assets/icons/i-circle-e-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { KpiUnit } from 'types';

interface InfoHeaderProp {
    sensorValue: string;
    unit: KpiUnit;
    isLoading?: boolean;
}
const EnergyConsumptionInfo: React.FunctionComponent<InfoHeaderProp> = ({ sensorValue, unit, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>{t('infoHeader.energy.currentUsage', { unit: unit })}</CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={consumptionCircle} />
                <CaptionLefWhite>{t('infoHeader.energy.currentUsage', { unit: unit })}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={productionCircle} />
                        <CaptionLefWhite>{t('infoHeader.energy.loadingAverage')}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={productionCircle} />
                        <CaptionLefWhite>{t('infoHeader.energy.averageSensorUsage', { unit: unit })}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default EnergyConsumptionInfo;
