import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import occupancyCircle from '../../../../assets/icons/i-circle-o.svg';
import averageCircle from '../../../../assets/icons/i-circle-o-average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';
import { formatDuration } from 'utils/formatDuration';
import { Incident } from 'types';

interface InfoHeaderProp {
    incident: Incident;
    isLoading?: boolean;
}
const MotionDetected: React.FunctionComponent<InfoHeaderProp> = ({ incident, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{incident.total}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.occupancy.motions')}
                    <br />
                    {t('infoHeader.common.total')}
                </CaptionLefWhite>
            </div>
            <div>
                {formatDuration(incident.duration) && (
                    <>
                        <H2White>{formatDuration(incident.duration)}</H2White>
                        <CaptionLefWhite>
                            {t('infoHeader.occupancy.firstAndLast')}
                            <br />
                            {t('infoHeader.occupancy.unexpected')}
                        </CaptionLefWhite>
                    </>
                )}
            </div>
            <div>
                <LegendIcon src={occupancyCircle} />
                <CaptionLefWhite>{t('infoHeader.occupancy.currentMotions')}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.occupancy.loadingAverageMotions')}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={averageCircle} />
                        <CaptionLefWhite>{t('infoHeader.occupancy.avgMotions')}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default MotionDetected;
