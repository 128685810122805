import { useQuery } from 'react-query';

import { useAxiosClient } from 'context/AxiosClientContext';

import { config } from 'config';

import { SensorAverage, SensorData } from 'types';
import { SensorDataParam } from 'components/DataPane/sensors/types';

export const useSensorData = (sensorDataParams: SensorDataParam, enabled = true) => {
    const { axiosClient } = useAxiosClient();

    return useQuery(
        [
            'sensor',
            sensorDataParams.id,
            sensorDataParams.from,
            sensorDataParams.to,
            sensorDataParams.sensorAvgFilter || 'noFilter',
        ],
        async ({ signal }) => {
            const isAggregation = !!sensorDataParams.sensorAvgFilter;

            if (isAggregation) {
                const { data } = await axiosClient.get<SensorAverage[]>(
                    config.sensorDataAverage(
                        sensorDataParams.id,
                        sensorDataParams.sensorAvgFilter,
                        sensorDataParams.from,
                        sensorDataParams.to
                    ),
                    { signal }
                );

                return data.map(({ value, field }) => ({
                    value,
                    time: field,
                }));
            }

            const { data } = await axiosClient.get<SensorData[]>(
                config.sensorData(sensorDataParams.id, sensorDataParams.from, sensorDataParams.to),
                { signal }
            );

            return data;
        },
        {
            enabled: enabled,
        }
    );
};
