import React, { useCallback, useState } from 'react';

import { StyledToggle } from 'styled/components/toggle';

interface Props {
    toggled?: boolean;
    onToggle: (toggle: boolean) => void;
}
const Toggle: React.FunctionComponent<Props> = ({ toggled = false, onToggle }) => {
    const [isToggled, setIsToggled] = useState(toggled);

    const callback = useCallback(() => {
        setIsToggled(!isToggled);
        onToggle(!isToggled);
    }, [isToggled, onToggle]);

    return (
        <StyledToggle>
            <label>
                <input type="checkbox" defaultChecked={toggled} onClick={callback} />
                <span />
            </label>
        </StyledToggle>
    );
};

export default Toggle;
