import React from 'react';

import { useTranslation } from 'react-i18next';
import { VictoryAxis, VictoryChart, VictoryLegend, VictoryLine, VictoryScatter } from 'victory';

import { useResize } from 'hooks/useResize';
import { EBSHolidayGraphData } from '../types';

import { closingAxis, closingXYAxis, XYAxis } from '../graph.styles';
import { theme } from 'styled/Theme';
import { getSensorUnit } from 'components/DataPane/incidents/incidents.helpers';
import LineSymbol from './LineSymbol';

interface Props {
    graphData: EBSHolidayGraphData;
}
const HolidaysGraph: React.FunctionComponent<Props> = ({ graphData }) => {
    const { t } = useTranslation();
    const dimension = useResize();

    return (
        <VictoryChart
            width={dimension.width * 0.7}
            height={dimension.height * 0.28}
            padding={{ right: 200, left: 40, bottom: 20, top: 20 }}
            domain={{ y: [0, graphData.max] }}
        >
            <VictoryLegend
                x={dimension.width * 0.7 - 180}
                style={{ labels: { fontSize: 12, fill: theme.colors.grey } }}
                data={[
                    { name: t('energy.ebs.graph.legend.newYear'), symbol: { fill: theme.colors.ebsColor1 } },
                    { name: t('energy.ebs.graph.legend.secondEastern'), symbol: { fill: theme.colors.ebsColor2 } },
                    { name: t('energy.ebs.graph.legend.ascension'), symbol: { fill: theme.colors.ebsColor3 } },
                    { name: t('energy.ebs.graph.legend.secondPentecost'), symbol: { fill: theme.colors.ebsColor4 } },
                    { name: t('energy.ebs.graph.legend.firstChristmas'), symbol: { fill: theme.colors.ebsColor5 } },
                    { name: t('energy.ebs.graph.legend.secondChristmas'), symbol: { fill: theme.colors.ebsColor6 } },
                ]}
                dataComponent={<LineSymbol />}
            />

            {/* X-axis */}
            <VictoryAxis style={{ ...XYAxis, grid: { stroke: 'none' } }} tickValues={graphData.tickValues} />

            {/* Y-axis */}
            <VictoryAxis
                crossAxis={false}
                dependentAxis={true}
                tickFormat={(t: number, index: number, arr: number[]) =>
                    arr.length - 1 === index ? getSensorUnit(graphData.sensorType) : t
                }
                style={XYAxis}
            />

            {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
            <VictoryAxis style={closingAxis} orientation="right" />
            <VictoryAxis style={closingAxis} orientation="top" />
            <VictoryAxis style={closingXYAxis} orientation="left" />
            <VictoryAxis style={closingXYAxis} orientation="bottom" />

            <VictoryLine
                x="timestamp"
                y="value"
                style={{
                    data: { stroke: theme.colors.ebsColor1 },
                }}
                data={graphData.newYear}
            />
            <VictoryLine
                x="timestamp"
                y="value"
                style={{
                    data: { stroke: theme.colors.ebsColor2 },
                }}
                data={graphData.secondEastern}
            />
            <VictoryLine
                x="timestamp"
                y="value"
                style={{
                    data: { stroke: theme.colors.ebsColor3 },
                }}
                data={graphData.ascension}
            />
            <VictoryLine
                x="timestamp"
                y="value"
                style={{
                    data: { stroke: theme.colors.ebsColor4 },
                }}
                data={graphData.secondPentecost}
            />
            <VictoryLine
                x="timestamp"
                y="value"
                style={{
                    data: { stroke: theme.colors.ebsColor5 },
                }}
                data={graphData.firstChristmas}
            />
            <VictoryLine
                x="timestamp"
                y="value"
                style={{
                    data: { stroke: theme.colors.ebsColor6 },
                }}
                data={graphData.secondChristmas}
            />

            <VictoryScatter
                x="timestamp"
                y="value"
                symbol="square"
                style={{ data: { fill: theme.colors.ebsColor1 } }}
                size={3}
                data={graphData.newYear}
            />
            <VictoryScatter
                x="timestamp"
                y="value"
                symbol="square"
                style={{ data: { fill: theme.colors.ebsColor2 } }}
                size={3}
                data={graphData.secondEastern}
            />
            <VictoryScatter
                x="timestamp"
                y="value"
                symbol="square"
                style={{ data: { fill: theme.colors.ebsColor3 } }}
                size={3}
                data={graphData.ascension}
            />
            <VictoryScatter
                x="timestamp"
                y="value"
                symbol="square"
                style={{ data: { fill: theme.colors.ebsColor4 } }}
                size={3}
                data={graphData.secondPentecost}
            />
            <VictoryScatter
                x="timestamp"
                y="value"
                symbol="square"
                style={{ data: { fill: theme.colors.ebsColor5 } }}
                size={3}
                data={graphData.firstChristmas}
            />
            <VictoryScatter
                x="timestamp"
                y="value"
                symbol="square"
                style={{ data: { fill: theme.colors.ebsColor6 } }}
                size={3}
                data={graphData.secondChristmas}
            />
        </VictoryChart>
    );
};

export default React.memo(HolidaysGraph);
