import React from 'react';

import { useTranslation } from 'react-i18next';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon, PulseLegendIcon } from 'styled/components/dataPane';

import waterCircle from '../../../../assets/icons/i-circle-water.svg';
import waterAverageCircle from '../../../../assets/icons/i-circle-water_average.svg';

import { useInitialLoad } from 'hooks/useInitialLoad';

interface InfoHeaderProp {
    sensorValue: string;
    isLoading?: boolean;
}
const WaterInfo: React.FunctionComponent<InfoHeaderProp> = ({ sensorValue, isLoading }) => {
    const initialLoad = useInitialLoad(Boolean(isLoading));
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>{t('infoHeader.energy.totalUsage')}</CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={waterCircle} />
                <CaptionLefWhite>{t('infoHeader.water.currentUsage')}</CaptionLefWhite>
                {initialLoad ? (
                    <>
                        <PulseLegendIcon src={waterAverageCircle} />
                        <CaptionLefWhite>{t('infoHeader.water.loadingAverage')}</CaptionLefWhite>
                    </>
                ) : (
                    <>
                        <LegendIcon src={waterAverageCircle} />
                        <CaptionLefWhite>{t('infoHeader.water.averageUsage')}</CaptionLefWhite>
                    </>
                )}
            </div>
        </>
    );
};

export default WaterInfo;
