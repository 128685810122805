import styled from 'styled-components';
import { getTransparentColor, theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';

import { ModuleType } from 'types';

import { CaptionLefWhite, H3White } from './text';

interface TooltipCardProps {
    module: ModuleType;
}
export const TooltipCard = styled.div<TooltipCardProps>`
    background-color: ${({ module }) => getTransparentColor(module)}}
    padding: 8px;
    border-radius: 5px;
    margin-bottom: 5px;
    backdrop-filter: blur(5px);
`;

export const TooltipTitle = styled(CaptionLefWhite)`
    margin-block: 0 5px;
    font-weight: 300;
    margin-inline: 0px;
`;

export const FeedbackDate = styled(CaptionLefWhite)`
    font-weight: 300;
    margin: 0px;
`;

export const FeedbackMetric = styled.div`
    align-items: center;
    display: flex;
    h3 {
        margin-left: 3px;
        margin-right: 10px;
        color: ${theme.colors.white};
    }
`;

export const MetricScore = styled.div`
    p {
        margin-bottom: 0px;
    }
    span {
        font-weight: bold;
        font-size: 18px;
        color: ${theme.colors.white};
    }
`;

export const FeedbackMessage = styled(CaptionLefWhite)`
    font-weight: 300;
    margin: 0px;
`;

export const FeedbackIcon = styled(SVG)`
    min-width: 12px;
    min-height: 12px;
`;

export const TooltipContent = styled(H3White)`
    display: flex;
    align-items: center;
    font-size: 14px;

    span {
        font-size: 10px;
        color: white;
        padding-left: 5px;
        font-weight: 300;
    }
`;

export const Top5Span = styled.span`
    display: inline-block;
    text-align: left;
`;
