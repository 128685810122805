import React from 'react';

import { useTranslation } from 'react-i18next';

import Toggle from 'components/Toggle';

import { WrapperInteraction } from 'styled/components/ebs';
import { BodyLeftWhite } from 'styled/components/text';

interface Props {
    showApprox: boolean;
    onToggle: () => void;
}
const ShowApproxHeader: React.FunctionComponent<Props> = ({ showApprox, onToggle }) => {
    const { t } = useTranslation();
    return (
        <WrapperInteraction>
            <BodyLeftWhite>{t('energy.ebs.interaction.showApprox')}</BodyLeftWhite>
            <Toggle toggled={showApprox} onToggle={onToggle} />
        </WrapperInteraction>
    );
};

export default ShowApproxHeader;
