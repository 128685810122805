import React from 'react';

import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { TooltipCard, FeedbackMessage, FeedbackDate, FeedbackMetric, FeedbackIcon } from 'styled/components/tooltip';
import { ModuleType } from 'types';
import { FeedbackTimeserie } from 'components/DataPane/statistics/feedback/types';
import { DateSpan } from 'utils/timePeriod';

import temperatureIcon from '../../../assets/icons/icon-tooltip-temperature.svg';
import co2Icon from '../../../assets/icons/icon-tooltip-humidity.svg';
import illuminanceIcon from '../../../assets/icons/icon-tooltip-luminocity.svg';
import noiseIcon from '../../../assets/icons/icon-tooltip-noise.svg';

interface TooltipBarInterface {
    x?: number;
    y?: number;
    dx?: number;
    dy?: number;
    height?: number;
    width?: number;
    dateSpan: string;
    datum?: FeedbackTimeserie;
}
const TooltipFeedback: React.FunctionComponent<TooltipBarInterface> = ({
    dx = 0,
    x = 0,
    dateSpan,
    datum = {
        timestamp: 0,
        temperature: 0,
        co2: 0,
        humidity: 0,
        lightIntensity: 0,
        noiseLevel: 0,
        messages: [],
        totalFeedback: 0,
    },
}) => {
    const { t } = useTranslation();

    return (
        <g style={{ pointerEvents: 'none' }}>
            <foreignObject x={dx + x} y={30} width={200} height={'100%'}>
                <>
                    <TooltipCard module={ModuleType.FEEDBACK}>
                        <FeedbackDate>
                            {t('tooltip.date', { date: formatTime(datum.timestamp, dateSpan) })}
                        </FeedbackDate>
                        <FeedbackMetric>
                            {Boolean(datum.co2) && (
                                <>
                                    <FeedbackIcon src={co2Icon} />
                                    <h3>{datum.co2}</h3>
                                </>
                            )}
                            {Boolean(datum.lightIntensity) && (
                                <>
                                    <FeedbackIcon src={illuminanceIcon} />
                                    <h3>{datum.lightIntensity}</h3>
                                </>
                            )}
                            {Boolean(datum.temperature) && (
                                <>
                                    <FeedbackIcon src={temperatureIcon} />
                                    <h3>{datum.temperature}</h3>
                                </>
                            )}
                            {Boolean(datum.noiseLevel) && (
                                <>
                                    <FeedbackIcon src={noiseIcon} />
                                    <h3>{datum.noiseLevel}</h3>
                                </>
                            )}
                        </FeedbackMetric>
                        {Boolean(datum.messages?.length) &&
                            datum.messages?.map((message, index) => (
                                <FeedbackMessage key={index}>{message}</FeedbackMessage>
                            ))}
                    </TooltipCard>
                </>
            </foreignObject>
        </g>
    );
};

export default TooltipFeedback;

const formatTime = (timestamp: number, dateSpan: string) => {
    return dateSpan === DateSpan.YEAR
        ? format(new Date((timestamp || 0) * 1000), 'MMMM yyyy')
        : format(new Date((timestamp || 0) * 1000), 'd MMMM');
};
