import React from 'react';

import { FacilitiesBarWrapper, IconBarWrapper, SubBarWrapper, SubTitleWrapper } from 'styled/components/navigation';
import { MenuIcon } from 'styled/components/buttons';
import { H3 } from 'styled/components/text';

import logOutIcon from '../../assets/icons/icons-g-logout.svg';

import { useAuth } from 'context/AuthContext';
import { useUser } from 'context/UserContext';

interface ProfileSubMenuInterface {
    item: string;
}

const ProfileSubMenu: React.FunctionComponent<ProfileSubMenuInterface> = ({ item }) => {
    const { logOut } = useAuth();
    const { clearUserState } = useUser();

    const logOff = () => {
        clearUserState();
        logOut();
    };

    return (
        <SubBarWrapper>
            <SubTitleWrapper>
                <H3>{item}</H3>
            </SubTitleWrapper>
            <FacilitiesBarWrapper />
            <IconBarWrapper active={false} onClick={logOff}>
                <MenuIcon $disabled={false} $active={false} src={logOutIcon} />
            </IconBarWrapper>
        </SubBarWrapper>
    );
};

export default ProfileSubMenu;
