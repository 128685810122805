import React, { useEffect, useState } from 'react';

import HolidaysGraph from 'components/Graph/ebs/HolidaysGraph';
import EBSTitleMenu from './EBSTitleMenu';

import { EBSContentWrapper, EBSGraphWrapper } from 'styled/components/dataPane';

import { createEBSHolidaysGraphData } from '../../EBSWizard/ebs.helpers';
import { EBSHolidayGraphData } from 'components/Graph/types';
import { SensorAverage, SensorType } from 'types';

interface Props {
    data: SensorAverage[];
    title: string;
    children?: React.ReactNode;
}
const HolidaysDataPane: React.FunctionComponent<Props> = ({ data, title, children }) => {
    const [holidayGraph, setHolidayGraph] = useState<EBSHolidayGraphData | null>(null);

    useEffect(() => {
        if (data) {
            const graph = createEBSHolidaysGraphData(data, SensorType.ELECTRICITY);

            setHolidayGraph(graph);
        }
    }, [data]);

    return (
        <>
            <EBSTitleMenu title={title} />

            <EBSContentWrapper>
                <EBSGraphWrapper>
                    {holidayGraph && <HolidaysGraph graphData={holidayGraph} />}
                    {children}
                </EBSGraphWrapper>
            </EBSContentWrapper>
        </>
    );
};

export default React.memo(HolidaysDataPane);
