import React, { useState } from 'react';

import NoDataMessage from 'components/DataPane/NoDataMessage';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import ProductionGraph from 'components/Graph/statistics/energy/ProductionGraph';

import { ContentWrapper, InfoHeader, LoadingWrapper, SensorGraphWrapper } from 'styled/components/dataPane';

import ProductionConsumptionHeader from './infoHeader/ProductionConsumptionHeader';

import { getFirstDataTimestamp, getLastKpiUpdateTimestamp, summedValue } from './energyStatistics.helper';
import { ProductionGraphData } from './types';
import { Sensor } from 'types';
import { TotalUsage } from 'views/authenticated/energy/types';

interface ProductionConsumptionProps {
    totalUsage: TotalUsage;
    graphData: ProductionGraphData | null;
    sensors: Sensor[];
    isLoadingConsumption: boolean;
    isBarChart: boolean;
}
const ProductionConsumption: React.FunctionComponent<ProductionConsumptionProps> = ({
    totalUsage,
    graphData,
    sensors,
    isLoadingConsumption,
    isBarChart,
}) => {
    const [showConsumption, setShowConsumption] = useState(true);
    const [showProduction, setShowProduction] = useState(true);
    const [showFeedin, setShowFeedin] = useState(true);

    const lastUpdate = getLastKpiUpdateTimestamp(sensors);
    const firstData = getFirstDataTimestamp(sensors);

    return (
        <>
            <ContentWrapper>
                {(isLoadingConsumption || graphData === null) && (
                    <LoadingWrapper>
                        <LoadingOverlay dark={true} isSmall={true} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption && graphData?.consumptionData.length === 0 && (
                    <LoadingWrapper>
                        <NoDataMessage firstDataReceivedDate={firstData} lastDataReceivedDate={lastUpdate} />
                    </LoadingWrapper>
                )}
                {!isLoadingConsumption && Boolean(graphData?.consumptionData.length) && (
                    <>
                        <InfoHeader>
                            <ProductionConsumptionHeader
                                production={totalUsage.production || 0}
                                feedin={totalUsage.feedin || 0}
                                billableConsumption={totalUsage.consumption || 0}
                                showConsumption={showConsumption}
                                onShowConsumption={setShowConsumption}
                                showProduction={showProduction}
                                onShowProduction={setShowProduction}
                                showFeedin={showFeedin}
                                onShowFeedin={setShowFeedin}
                            />
                        </InfoHeader>
                        <SensorGraphWrapper>
                            <ProductionGraph
                                graphData={graphData}
                                isBarChart={isBarChart}
                                showConsumption={showConsumption}
                                showProduction={showProduction}
                                showFeedin={showFeedin}
                            />
                        </SensorGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </>
    );
};

export default ProductionConsumption;
