import React from 'react';

import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { H2White, CaptionLefWhite } from 'styled/components/text';
import { LegendIcon } from 'styled/components/dataPane';

import defaultCircle from '../../../../assets/icons/i-circle-unknown.svg';
import averageCircle from '../../../../assets/icons/i-circle-a-average.svg';

interface UnkownInfoHeaderProp {
    sensorValue: string;
    modificationDate: string;
}
const UnkownInfo: React.FunctionComponent<UnkownInfoHeaderProp> = ({ sensorValue, modificationDate }) => {
    const { t } = useTranslation();

    return (
        <>
            <div>
                <H2White>{sensorValue}</H2White>
                <CaptionLefWhite>
                    {t('infoHeader.common.current')}
                    <br />
                    {t('infoHeader.common.value')}
                </CaptionLefWhite>
            </div>
            <div>
                <CaptionLefWhite>
                    {t('infoHeader.common.connected', { date: format(new Date(modificationDate), 'MMM dd, yyyy') })}
                    <br />
                    {t('infoHeader.common.contact')}
                </CaptionLefWhite>
            </div>
            <div>
                <LegendIcon src={defaultCircle} />
                <CaptionLefWhite>{t('infoHeader.common.overTime', { unit: 'unit' })}</CaptionLefWhite>
                <LegendIcon src={averageCircle} />
                <CaptionLefWhite>{t('infoHeader.common.averageOverTime', { unit: 'unit' })}</CaptionLefWhite>
            </div>
        </>
    );
};

export default UnkownInfo;
