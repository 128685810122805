import React, { useCallback, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import ContractTable from 'components/Table/ElectricityContractTable';
import CreateContract from 'components/ContractForm/CreateContract';
import ViewContract from 'components/ContractForm/ViewContract';
import Footer from 'components/Footer';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import MainWrapper from 'components/Container/MainWrapper';
import Modal from 'components/Modal';
import ProfileSubMenu from 'components/Submenu/ProfileSubMenu';
import WorkingHours, { getHoursByBuilding, Hours, initialOpeningHours } from 'components/WorkingHours';

import { CaptionLeft, H3Bold, H3White, LeadLeft } from 'styled/components/text';
import { CompanyContentWrapper } from 'styled/components/view';
import { ProfileNameWrapper, ProfileFullName, ProfileNameCircleSmall } from 'styled/components/profile';
import { DividerBorder } from 'styled/components/support';
import { StyledSelect } from 'styled/components/inputs';
import { LoadingBox, WorkingHoursHeader, WorkingHoursHelper } from 'styled/components/company';
import { SmallButton, SubmitButton } from 'styled/components/buttons';

import { useUser } from 'context/UserContext';
import { useTenant } from 'context/TenantContext';
import { ContractPreference, ElectricityContract, EnergyType, GasContract, ModalType } from './types';

const CompanyView: React.FunctionComponent = () => {
    const selectedContract = useRef<ElectricityContract | GasContract>(null);
    const { t } = useTranslation();
    const { getBuildingData, openingHours, updateOpeningHours, contracts, updateContracts, isFetching } = useTenant();
    const { userState } = useUser();

    const [selectedBuilding, setSelectedBuilding] = useState(getBuildingData()[0].name);
    const [isValid, setIsValid] = useState(true);
    const [selectedHours, setSelectedHours] = useState<Hours>(getHoursByBuilding(selectedBuilding, openingHours));
    const [editHours, setEditHours] = useState<Hours>(getHoursByBuilding(selectedBuilding, openingHours));
    const [isEditMode, setIsEditMode] = useState(false);
    const [showModal, setShowModal] = useState<ModalType>(ModalType.NONE);

    const selectedElectricityContracts =
        contracts.filter(c => c.building === selectedBuilding && c.energyType === EnergyType.ELECTRICITY) || [];
    const selectedGasContracts =
        contracts.filter(c => c.building === selectedBuilding && c.energyType === EnergyType.GAS) || [];

    const handleOpeningHoursSubmit = useCallback(() => {
        if (isValid) {
            updateOpeningHours(selectedBuilding, editHours);
            setIsEditMode(false);
            setSelectedHours(editHours);
        }
    }, [isValid, editHours, selectedBuilding, updateOpeningHours]);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const hours =
            openingHours.length === 0 ? initialOpeningHours : getHoursByBuilding(e.target.value, openingHours);

        setEditHours(hours);
        setSelectedHours(hours);
        setSelectedBuilding(e.target.value);
    };

    const handleClose = () => {
        selectedContract.current = null;
        setShowModal(ModalType.NONE);
    };

    const handelMutation = useCallback(
        (contracts: ContractPreference) => {
            updateContracts(contracts);
            selectedContract.current = null;
            setShowModal(ModalType.NONE);
        },
        [updateContracts]
    );

    const handleDelete = useCallback(() => {
        const updatedContracts = {
            energy_contract: contracts.filter(c => c.id !== selectedContract.current.id),
        };

        handelMutation(updatedContracts);
    }, [handelMutation, contracts]);

    const handleContractSubmit = useCallback(
        (contract: ElectricityContract | GasContract) => {
            const contracten = {
                energy_contract: [...contracts, contract],
            };

            handelMutation(contracten);
        },
        [handelMutation, contracts]
    );

    return (
        <>
            <ProfileSubMenu item={t('navigation.sideMenu.itemCompany')} />

            <MainWrapper>
                <CompanyContentWrapper>
                    <ProfileNameWrapper>
                        <ProfileNameCircleSmall>
                            <H3White>
                                {userState?.Tenants.find(t => t.Id === userState.TenantId)?.Name.charAt(0)}
                            </H3White>
                        </ProfileNameCircleSmall>
                        <ProfileFullName>
                            <H3Bold>{userState?.Tenants.find(t => t.Id === userState.TenantId)?.Name}</H3Bold>
                        </ProfileFullName>
                    </ProfileNameWrapper>
                </CompanyContentWrapper>

                <DividerBorder />

                <CompanyContentWrapper>
                    <H3Bold>{t('company.selectedBuilding')}</H3Bold>
                    <br />

                    <StyledSelect>
                        <select value={selectedBuilding} onChange={handleChange}>
                            {getBuildingData().map(option => (
                                <option key={option.id} value={option.name}>
                                    {option.name}
                                </option>
                            ))}
                        </select>
                    </StyledSelect>
                    <br />
                    <br />

                    <WorkingHoursHeader>
                        <H3Bold>{t('company.workingHours')}</H3Bold>
                        {isEditMode ? (
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <SmallButton onClick={() => setIsEditMode(false)}>{t('common.cancel')}</SmallButton>
                                <SmallButton onClick={handleOpeningHoursSubmit}>{t('common.save')}</SmallButton>
                            </div>
                        ) : (
                            <SmallButton onClick={() => setIsEditMode(true)}>{t('common.edit')}</SmallButton>
                        )}
                    </WorkingHoursHeader>

                    <br />
                    {selectedHours && (
                        <WorkingHours
                            openingHours={selectedHours}
                            editOpeningHours={editHours}
                            isEditMode={isEditMode}
                            onChangeHours={(hours: Hours) => {
                                setIsValid(isOpeningHoursValid(hours));
                                setEditHours(hours);
                            }}
                        />
                    )}
                    {isEditMode && (
                        <WorkingHoursHelper isValid={isValid}>
                            <CaptionLeft>{t('company.workingHoursHelper')}</CaptionLeft>
                            <CaptionLeft>{t('company.formatHelper')}</CaptionLeft>
                            <CaptionLeft>{t('company.openClosedHelper')}</CaptionLeft>
                            <CaptionLeft>{t('company.emptyFieldsHelper')}</CaptionLeft>
                            <CaptionLeft>{t('company.fromToHelper')}</CaptionLeft>
                        </WorkingHoursHelper>
                    )}
                    <br />

                    <WorkingHoursHeader>
                        <H3Bold>{t('company.energyContracts')}</H3Bold>

                        <SmallButton onClick={() => setShowModal(ModalType.CREATE)}>{t('common.add')}</SmallButton>
                    </WorkingHoursHeader>
                    <br />

                    {isFetching && (
                        <LoadingBox>
                            <LoadingOverlay />
                        </LoadingBox>
                    )}

                    {!isFetching && selectedGasContracts.length === 0 && selectedElectricityContracts.length === 0 && (
                        <div>{t('company.noEnergyContracts')}</div>
                    )}

                    {!isFetching && selectedElectricityContracts.length > 0 && (
                        <>
                            <LeadLeft>{t('company.energyContract.table.electricityTitle')}</LeadLeft>
                            <ContractTable
                                contracts={selectedElectricityContracts}
                                onView={id => {
                                    selectedContract.current = selectedElectricityContracts.find(c => c.id === id);
                                    setShowModal(ModalType.VIEW);
                                }}
                                onDelete={id => {
                                    selectedContract.current = selectedElectricityContracts.find(c => c.id === id);
                                    setShowModal(ModalType.DELETE);
                                }}
                            />
                        </>
                    )}
                    {!isFetching && selectedGasContracts.length > 0 && (
                        <>
                            <LeadLeft>{t('company.energyContract.table.gasTitle')}</LeadLeft>
                            <ContractTable
                                contracts={selectedGasContracts}
                                onView={id => {
                                    selectedContract.current = selectedGasContracts.find(c => c.id === id);
                                    setShowModal(ModalType.VIEW);
                                }}
                                onDelete={id => {
                                    selectedContract.current = selectedGasContracts.find(c => c.id === id);
                                    setShowModal(ModalType.DELETE);
                                }}
                            />
                        </>
                    )}

                    <Modal
                        show={[ModalType.CREATE, ModalType.VIEW].includes(showModal)}
                        onClose={handleClose}
                        title={selectedContract.current?.id ? selectedBuilding : t('company.addEnergyContract')}
                    >
                        {showModal === ModalType.VIEW ? (
                            <ViewContract contract={selectedContract.current} />
                        ) : (
                            <CreateContract building={selectedBuilding} onContractSubmit={handleContractSubmit} />
                        )}
                    </Modal>

                    <Modal show={showModal === ModalType.DELETE} onClose={handleClose}>
                        <div>{t('company.confirm')}</div>
                        <br />
                        <SubmitButton disabled={false} onClick={handleDelete}>
                            {t('common.delete')}
                        </SubmitButton>
                    </Modal>
                </CompanyContentWrapper>
            </MainWrapper>

            <Footer />
        </>
    );
};

export default CompanyView;

const isOpeningHoursValid = (editOpeningHours: Hours) => {
    return Object.keys(editOpeningHours).every((e: keyof Hours) => {
        const timeRegex = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return (
            (editOpeningHours[e].from === '' && editOpeningHours[e].to === '') ||
            (editOpeningHours[e].from !== '' &&
                editOpeningHours[e].to !== '' &&
                timeRegex.test(editOpeningHours[e].from) &&
                timeRegex.test(editOpeningHours[e].to) &&
                fromIsAfterTo(editOpeningHours[e].from, editOpeningHours[e].to))
        );
    });
};

const fromIsAfterTo = (from: string, to: string): boolean => {
    const fromHour = Number(from.slice(0, -3));
    const fromMinute = Number(from.slice(-2));
    const toHour = Number(to.slice(0, -3));
    const toMinute = Number(to.slice(-2));

    return toHour > fromHour ? true : toHour === fromHour && toMinute > fromMinute ? true : false;
};
