import React from 'react';

import { useTranslation } from 'react-i18next';

import EBSTextArea from 'components/EBSWizard/EBSTextArea';

import { EBSContentWrapper, EBSGraphWrapper } from 'styled/components/dataPane';
import { BodyLeftEBS, BodyLeftWhite, H2 } from 'styled/components/text';
import { EBSReportWrapper, EBSWizardContent } from 'styled/components/ebs';
import { EBSBarWrapper } from 'styled/components/navigation';

import { useUser } from 'context/UserContext';

import { Space } from 'views/authenticated/types';
import { ExplanatoryText } from './types';

interface Props {
    building: Space;
    explanatoryText: ExplanatoryText;
    onChangeText: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}
const IntroductionView: React.FunctionComponent<Props> = ({ building, explanatoryText, onChangeText }) => {
    const { t } = useTranslation();
    const { userState } = useUser();

    return (
        <>
            <EBSBarWrapper>
                <H2>{t('energy.ebs.introduction.credentialsTitle')}</H2>
            </EBSBarWrapper>
            <EBSWizardContent>
                <EBSReportWrapper>
                    <BodyLeftWhite>{t('energy.ebs.introduction.reportingOrganisation')}</BodyLeftWhite>
                    <BodyLeftEBS>{userState?.Tenants.find(t => t.Id === userState.TenantId)?.Name}</BodyLeftEBS>
                    <BodyLeftWhite>{t('energy.ebs.introduction.facility')}</BodyLeftWhite>
                    <BodyLeftEBS>{building.name}</BodyLeftEBS>
                    <BodyLeftWhite>{t('energy.ebs.introduction.consultant')}</BodyLeftWhite>
                    <BodyLeftEBS>
                        {userState?.given_name} {userState?.family_name}
                    </BodyLeftEBS>
                </EBSReportWrapper>
            </EBSWizardContent>
            <EBSBarWrapper>
                <H2>{t('energy.ebs.introduction.introductionTitle')}</H2>
            </EBSBarWrapper>
            <EBSContentWrapper>
                <EBSGraphWrapper>
                    <EBSTextArea
                        onChangeText={onChangeText}
                        text={explanatoryText.introduction}
                        name={'introduction'}
                    />
                </EBSGraphWrapper>
            </EBSContentWrapper>
        </>
    );
};

export default IntroductionView;
