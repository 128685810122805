import React from 'react';

import { useTranslation } from 'react-i18next';

import {
    DisabledOverlay,
    Indicator,
    IndicatorWrapper,
    NameWrapper,
    PercentageWrapper,
    SpaceIndicatorOverview,
    SpaceIndicatorTitle,
    SpaceIndicatorWrapper,
    SpaceWrapper,
    Wrapper,
} from 'styled/components/spaceIndicator';
import { LeadLeftWhite } from 'styled/components/text';

const arr = [...Array(10)];

export interface SpaceIndicator {
    spaceName: string;
    percentage: number;
    id: string;
    isDisabled: boolean;
}

interface ISpaceIndicator {
    mostUsedSpaces: SpaceIndicator[];
    leastUsedSpaces: SpaceIndicator[];
    onSpaceSelection: (id: string) => void;
}
const SpaceIndicator: React.FunctionComponent<ISpaceIndicator> = ({
    mostUsedSpaces = [],
    leastUsedSpaces = [],
    onSpaceSelection,
}) => {
    const { t } = useTranslation();

    return (
        <SpaceIndicatorOverview>
            <Wrapper>
                {Boolean(mostUsedSpaces.length) && (
                    <SpaceIndicatorTitle>
                        <LeadLeftWhite>{t('spaceIndicator.mostUsed')}</LeadLeftWhite>
                    </SpaceIndicatorTitle>
                )}
                <SpaceWrapper>
                    {mostUsedSpaces.map((space, index) => (
                        <SpaceIndicatorWrapper key={index} onClick={() => onSpaceSelection(space.id)}>
                            <NameWrapper>{space.spaceName}</NameWrapper>
                            <IndicatorWrapper>
                                {arr.map((_, index) => {
                                    return (
                                        <Indicator
                                            key={index}
                                            percentage={space.percentage > index * 10 ? space.percentage : 0}
                                        />
                                    );
                                })}
                            </IndicatorWrapper>
                            <PercentageWrapper>{space.percentage}%</PercentageWrapper>
                            <DisabledOverlay isDisabled={space.isDisabled} />
                        </SpaceIndicatorWrapper>
                    ))}
                </SpaceWrapper>
            </Wrapper>
            <Wrapper>
                {Boolean(leastUsedSpaces.length) && (
                    <SpaceIndicatorTitle>
                        <LeadLeftWhite>{t('spaceIndicator.leastUsed')}</LeadLeftWhite>
                    </SpaceIndicatorTitle>
                )}
                <SpaceWrapper>
                    {leastUsedSpaces.map((space, index) => (
                        <SpaceIndicatorWrapper key={index} onClick={() => onSpaceSelection(space.id)}>
                            <NameWrapper>{space.spaceName}</NameWrapper>
                            <IndicatorWrapper>
                                {arr.map((_, index) => {
                                    return (
                                        <Indicator
                                            key={index}
                                            percentage={space.percentage > index * 10 ? space.percentage : 0}
                                        />
                                    );
                                })}
                            </IndicatorWrapper>
                            <PercentageWrapper>{space.percentage}%</PercentageWrapper>
                            <DisabledOverlay isDisabled={space.isDisabled} />
                        </SpaceIndicatorWrapper>
                    ))}
                </SpaceWrapper>
            </Wrapper>
        </SpaceIndicatorOverview>
    );
};

export default SpaceIndicator;
