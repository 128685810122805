import React, { FunctionComponent, useEffect, useReducer } from 'react';

import DataPaneMenu from '../DataPaneMenu';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';
import { IncidentGraphData } from 'components/Graph/types';
import AirQualityOccupancy from './infoHeader/AirQualityWIthOccupancy';

import {
    ContentWrapper,
    IncidentGraphWrapper,
    InfoHeader,
    IncidentsPane,
    getAnomalyIcon,
    getIncidentTitle,
} from '../../../styled/components/dataPane';
import { H3White } from 'styled/components/text';

import { useAverageData } from 'hooks/api/useAverageData';

import { Incident, Sensor, SensorAvgFilter } from 'types';
import { AirIncidentDataAction, AirIncidentDataState } from './types';

import { isEmpty } from 'utils/isEmptyObject';
import { createIncidentDateRange, createIncidentFromToDate, DateSpan, twoDays } from 'utils/timePeriod';
import { createIncidentGraphData, getAvgFilterByModule, showDefaultBarChart } from './incidents.helpers';
import { useAirSensorData } from 'hooks/api/useAirSensorData';

interface DataPaneProps {
    occupancySensors: Sensor[];
    incident: Incident;
    renderGraph: (graphData: IncidentGraphData, isBarChart: boolean) => JSX.Element;
}
const AirQualityIncidentDataPane: FunctionComponent<DataPaneProps> = ({ incident, occupancySensors, renderGraph }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { action, selectedIncident, sensorDataParams, graphData, isBarChart } = state;

    const { data, isLoading } = useAirSensorData(sensorDataParams, incident, occupancySensors);

    const { data: averageData, isLoading: averageIsLoading } = useAverageData(
        sensorDataParams.id,
        selectedIncident.moduleType,
        sensorDataParams.averageFilter,
        isBarChart
    );

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'SET_GRAPH_DATA',
                data: data.airData,
                averageData: averageData || [],
                occupancyData: data.occupancyData,
            });
        }
    }, [data, averageData]);

    useEffect(() => {
        dispatch({ type: 'CHANGE_INCIDENT', selectedIncident: incident });
    }, [incident]);

    return (
        <IncidentsPane>
            {!isEmpty(selectedIncident) && (
                <DataPaneMenu
                    title={getIncidentTitle(selectedIncident.anomalySubType)}
                    icon={getAnomalyIcon(selectedIncident.anomalySubType)}
                    selectedAction={action}
                    onSelectAction={timespan => dispatch({ type: 'CHANGE_DATE_SPAN', dataSpan: timespan })}
                    module={selectedIncident.moduleType}
                    allowCustomPeriod={false}
                    canToggleGraph={false}
                />
            )}

            <ContentWrapper>
                {(isLoading || graphData === null) && <LoadingOverlay dark={true} isSmall={true} />}
                {!isLoading && graphData?.data.length === 0 && <H3White>No data for selected period</H3White>}
                {!isLoading && graphData?.data.length && (
                    <>
                        <InfoHeader>
                            <AirQualityOccupancy incident={selectedIncident} isLoading={averageIsLoading} />
                        </InfoHeader>
                        <IncidentGraphWrapper>{renderGraph(graphData, isBarChart)}</IncidentGraphWrapper>
                    </>
                )}
            </ContentWrapper>
        </IncidentsPane>
    );
};

export default AirQualityIncidentDataPane;

export const initialState: AirIncidentDataState = {
    data: [],
    averageData: [],
    occupancyData: [],
    sensorDataParams: {
        id: '',
        ids: [],
        from: '',
        to: '',
        averageFilter: SensorAvgFilter.HOUR,
    },
    graphData: null,
    selectedIncident: {} as Incident,
    action: DateSpan.TWO_DAYS,
    isBarChart: false,
};

const reducer = (state: AirIncidentDataState, action: AirIncidentDataAction): AirIncidentDataState => {
    switch (action.type) {
        case 'SET_GRAPH_DATA': {
            const graphData = createIncidentGraphData(
                action.data,
                action.averageData || [],
                state.selectedIncident,
                state.action,
                state.sensorDataParams.from,
                state.sensorDataParams.to,
                state.isBarChart,
                action.occupancyData
            );

            return {
                ...state,
                data: action.data,
                averageData: action.averageData,
                occupancyData: action.occupancyData,
                graphData,
            };
        }
        case 'CHANGE_DATE_SPAN': {
            const averageFilter = getAvgFilterByModule(action.dataSpan, state.isBarChart);
            const { from, to } = createIncidentFromToDate(action.dataSpan, state.selectedIncident.timestampStart);
            return {
                ...state,
                action: action.dataSpan,
                graphData: null,
                sensorDataParams: { ...state.sensorDataParams, from, to, averageFilter },
            };
        }
        case 'CHANGE_INCIDENT': {
            const isBarChart = showDefaultBarChart(action.selectedIncident.moduleType);

            const sensorDataParams = {
                id: action.selectedIncident.pointId,
                ids: action.selectedIncident.sensorIds,
                ...createIncidentDateRange(action.selectedIncident.timestampStart, twoDays),
                averageFilter: getAvgFilterByModule(DateSpan.TWO_DAYS, isBarChart),
            };

            const isSameData = JSON.stringify(sensorDataParams) === JSON.stringify(state.sensorDataParams);

            return {
                ...state,
                action: DateSpan.TWO_DAYS,
                selectedIncident: action.selectedIncident,
                isBarChart,
                graphData: isSameData
                    ? createIncidentGraphData(
                          state.data,
                          state.averageData || [],
                          action.selectedIncident,
                          state.action,
                          state.sensorDataParams.from,
                          state.sensorDataParams.to,
                          state.isBarChart,
                          state.occupancyData
                      )
                    : null,
                sensorDataParams,
            };
        }
        case 'TOGGLE_CHART': {
            return {
                ...state,
                isBarChart: action.isBarChart,
                graphData: null,
                sensorDataParams: {
                    ...state.sensorDataParams,
                    averageFilter: getAvgFilterByModule(state.action, action.isBarChart),
                },
            };
        }
    }
};
