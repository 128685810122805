import React, { useMemo } from 'react';
import { theme } from 'styled/Theme';

import { VictoryChart, VictoryAxis, VictoryTooltip, VictoryBar, VictoryLine, VictoryVoronoiContainer } from 'victory';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import TooltipBar from 'components/Graph/Tooltip/TooltipBar';

import { TemperatureGraphData } from 'components/DataPane/statistics/energy/types';

import { useResize } from 'hooks/useResize';

import { monthDayFormat } from 'utils/timePeriod';

import {
    dateAxis,
    XYAxis,
    closingAxis,
    closingXYAxis,
    barChartDomainPadding,
    barChart3AxisPadding,
} from '../../graph.styles';
import { KpiUnit, ModuleType } from 'types';
import {
    formatTick,
    getMaxSensorValue,
    normalizedTickValues,
    normalizeYValue,
    setBarConfig3Axis,
    sumValues,
    getNormalizedTemperatureTick,
    formatTemperatureTick,
} from 'components/Graph/graph.helpers';
import TooltipIncident from 'components/Graph/Tooltip/TooltipIncident';
import { SensorValue } from 'components/Graph/types';

interface GraphProps {
    graphData: TemperatureGraphData;
    showGas: boolean;
    showElectricity: boolean;
    showTemperature: boolean;
}
const TemperatureConsumptionGraph: React.FunctionComponent<GraphProps> = ({
    graphData,
    showGas,
    showElectricity,
    showTemperature,
}) => {
    const { t } = useTranslation();
    const dimension = useResize();
    const barConfig = setBarConfig3Axis(graphData.timespan);
    const unit = graphData.isInGigajoules ? KpiUnit.GJ : KpiUnit.KWH;

    const summedElectricityData = useMemo(() => sumValues(graphData.electricityData, graphData.timespan), [graphData]);
    const summedGasData = useMemo(() => sumValues(graphData.gasData, graphData.timespan), [graphData]);
    const temperatureData = useMemo(() => graphData.temperatureData, [graphData]);

    const maxElectricityData = useMemo(() => getMaxSensorValue(summedElectricityData), [summedElectricityData]);
    const maxGasData = useMemo(() => getMaxSensorValue(summedGasData), [summedGasData]);
    const maxTemperatureData = useMemo(() => getMaxSensorValue(temperatureData), [temperatureData]);

    return (
        <>
            <VictoryChart
                width={dimension.width}
                height={dimension.height * 0.29}
                domainPadding={barChartDomainPadding}
                padding={barChart3AxisPadding}
                containerComponent={
                    <VictoryVoronoiContainer
                        voronoiBlacklist={['electricityConsumption', 'gasConsumption']}
                        mouseFollowTooltips={false}
                        voronoiDimension="x"
                        labels={() => ' '}
                        labelComponent={
                            <VictoryTooltip
                                dx={d => {
                                    const mousePosition = d.x;
                                    return mousePosition && mousePosition > dimension.width / 2 ? -150 : 30;
                                }}
                                flyoutComponent={
                                    <TooltipIncident
                                        module={ModuleType.ENERGY}
                                        unit={KpiUnit.CELSIUS}
                                        height={dimension.height}
                                        timespan={graphData.timespan}
                                        averageData={[]}
                                        titleCurrent={t('tooltip.temperature')}
                                    />
                                }
                            />
                        }
                    />
                }
            >
                {/* X-axis */}
                <VictoryAxis
                    tickValues={graphData.tickValues}
                    tickFormat={(t: number, index: number) =>
                        index % graphData.tickAmount == 0 ? format(new Date(t * 1000), graphData.tickFormat) : ''
                    }
                    style={XYAxis}
                />

                {/* Gas Y-axis */}
                <VictoryAxis
                    offsetX={80}
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) => formatTick(t, i, summedGasData, KpiUnit.M3)}
                    dependentAxis={true}
                    style={
                        showGas
                            ? XYAxis
                            : {
                                  ...XYAxis,
                                  tickLabels: { fill: theme.colors.greyD },
                                  axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                              }
                    }
                />
                {/* Electricity Y-axis */}
                <VictoryAxis
                    offsetX={40}
                    tickValues={normalizedTickValues}
                    tickFormat={(t: number, i: number) => formatTick(t, i, summedElectricityData, unit)}
                    dependentAxis={true}
                    style={
                        showElectricity
                            ? XYAxis
                            : {
                                  ...XYAxis,
                                  tickLabels: { fill: theme.colors.greyD },
                                  axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                              }
                    }
                />
                {/* Temperature Y-Axis */}
                <VictoryAxis
                    tickValues={getNormalizedTemperatureTick(temperatureData)}
                    tickFormat={(t: number, i: number) => formatTemperatureTick(t, i, temperatureData, KpiUnit.CELSIUS)}
                    dependentAxis={true}
                    style={
                        showTemperature
                            ? XYAxis
                            : {
                                  ...XYAxis,
                                  tickLabels: { fill: theme.colors.greyD },
                                  axis: { stroke: theme.colors.greyD, strokeWidth: 1 },
                              }
                    }
                />

                {/* Closing axis, also required on left and bottom to hide overlapping grid line on Y & X axis */}
                <VictoryAxis style={closingXYAxis} orientation="left" />
                <VictoryAxis style={closingAxis} orientation="right" />
                <VictoryAxis style={closingAxis} orientation="top" />
                <VictoryAxis style={closingXYAxis} orientation="bottom" />

                {/* Date x axis*/}
                {graphData.axisDates &&
                    Boolean(graphData.axisDates.length) &&
                    graphData.axisDates.map(axis => (
                        <VictoryAxis
                            axisValue={axis}
                            dependentAxis={true}
                            label={format(new Date(axis * 1000), monthDayFormat)}
                            tickFormat={() => ''}
                            style={dateAxis}
                            key={axis}
                        />
                    ))}

                {Boolean(graphData.electricityData.length) && showElectricity && (
                    <VictoryBar
                        name="electricityConsumption"
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar module={ModuleType.ENERGY} title={'Current'} unit={unit} />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mEnergy,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp - barConfig.spaceBetween}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxElectricityData)}
                        data={summedElectricityData}
                    />
                )}

                {Boolean(graphData.gasData.length) && showGas && (
                    <VictoryBar
                        name="gasConsumption"
                        labels={() => ''}
                        labelComponent={
                            <VictoryTooltip
                                flyoutComponent={
                                    <TooltipBar module={ModuleType.ENERGY} title={'Current'} unit={KpiUnit.M3} />
                                }
                            />
                        }
                        cornerRadius={barConfig.radius}
                        barWidth={barConfig.width}
                        style={{
                            data: {
                                fill: theme.colors.mWater,
                            },
                        }}
                        x={(datum: SensorValue) => datum.timestamp + barConfig.spaceBetween}
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxGasData)}
                        data={summedGasData}
                    />
                )}

                {Boolean(temperatureData.length) && showTemperature && (
                    <VictoryLine
                        interpolation="monotoneX"
                        x="timestamp"
                        y={(datum: SensorValue) => normalizeYValue(datum.value, maxTemperatureData)}
                        style={{
                            data: { stroke: theme.colors.white },
                        }}
                        data={temperatureData}
                    />
                )}
            </VictoryChart>
        </>
    );
};

export default React.memo(TemperatureConsumptionGraph);
