import styled from 'styled-components';
import { getColor, getLightColor, theme } from 'styled/Theme';
import SVG from 'react-inlinesvg';

import { ModuleType } from 'types';

export const DateSelectorWrapper = styled.div`
    margin-left: auto;
    z-index: 101;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas:
        'header header'
        'years days'
        'months days';
    height: 381px;
    width: 640px;
    color: ${theme.colors.white};
    background-color: ${theme.colors.text};
    border-left: 1px solid ${theme.colors.greyD};
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const Header = styled.div`
    grid-area: header;
    display: flex;
    align-items: center;
    height: 60px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const YearsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: years;
    border-bottom: 1px solid ${theme.colors.greyD};
    height: 79px;
`;

export const MonthsWrapper = styled.div`
    display: grid;
    grid-area: months;
    grid-template: repeat(3, 40px) / repeat(4, 70px);
    height: 220px;
    margin: auto;
`;

export const DaysWrapper = styled.div`
    display: grid;
    grid-template: repeat(7, 40px) / repeat(7, 40px);
    padding: 20px;
    grid-area: days;
    border-left: 1px solid ${theme.colors.greyD};
`;

export const DateDivider = styled.span`
    padding-right: 10px;
`;

export const CloseIcon = styled(SVG)`
    margin: auto;
    & path {
        fill: ${theme.colors.white};
    }
`;

export const SelectIcon = styled(SVG)<ModuleInterface>`
    margin: auto;
    & path {
        fill: ${({ module }) => (module && module !== ModuleType.SENSORS ? theme.colors.white : theme.colors.text)};
    }
`;

export const IconButton = styled.div`
    display: flex;
    width: 60px;
    height: 60px;
    cursor: pointer;
`;

export const CloseButton = styled(IconButton)`
    background-color: ${theme.colors.text};
`;

interface ModuleInterface {
    module?: ModuleType;
}
export const SelectButton = styled(IconButton)<ModuleInterface>`
    margin-left: auto;
    background-color: ${({ module }) => getColor(module)};
`;

interface CalenderDay {
    inMonth: boolean;
    isCurrent: boolean;
    isDisabled: boolean;
    module?: ModuleType;
}
export const CalenderDay = styled.div<CalenderDay>`
    cursor: ${({ isDisabled }) => (isDisabled ? 'cursor' : 'pointer')};
    font-weight: ${({ isCurrent }) => (isCurrent ? 'bold' : 'normal')};
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ isCurrent, module }) => isCurrent && getLightColor(module)};
    border-radius: 4px;
    color: ${({ inMonth, isCurrent, isDisabled }) =>
        isCurrent
            ? theme.colors.text
            : isDisabled
            ? theme.colors.grey
            : inMonth
            ? theme.colors.white
            : theme.colors.grey};
    opacity: ${({ inMonth, isDisabled }) => (!inMonth || isDisabled) && 0.3};
`;

export const WeekDay = styled.div<ModuleInterface>`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ module }) => getLightColor(module)};
`;

interface MonthYear {
    isDisabled?: boolean;
    isCurrent: boolean;
    module?: ModuleType;
}

export const MonthYear = styled.div<MonthYear>`
    cursor: ${({ isDisabled }) => (isDisabled ? 'cursor' : 'pointer')};
    font-weight: ${({ isCurrent }) => (isCurrent ? 'bold' : 'normal')};
    width: 70px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ isCurrent, module }) => isCurrent && getLightColor(module)};
    border-radius: 4px;
    color: ${({ isCurrent, isDisabled }) =>
        isCurrent ? theme.colors.text : isDisabled ? theme.colors.grey : theme.colors.white};
    opacity: ${({ isDisabled }) => isDisabled && 0.3};
`;
