import styled, { css, keyframes } from 'styled-components';
import { theme } from 'styled/Theme';

const cubeMove = keyframes`
    25% { 
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% { 
      transform: translateX(42px) translateY(42px) rotate(-179deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    } 50.1% { 
      transform: translateX(42px) translateY(42px) rotate(-180deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    } 75% { 
      transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    } 100% { 
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
`;

export const Spinner = styled.div`
    margin: auto;
    width: 40px;
    height: 40px;
    position: relative;
`;

interface CubeProps {
    isSmall?: boolean;
    dark?: boolean;
}
export const Cube = styled.div<CubeProps>`
    background-color: ${({ dark }) => (dark ? theme.colors.grey : theme.colors.text)};
    ${({ isSmall }) =>
        isSmall
            ? css`
                  width: 10px;
                  height: 10px;
              `
            : css`
                  width: 15px;
                  height: 15px;
              `};
    position: absolute;
    top: 0;
    left: 0;
    animation: ${cubeMove} 1.8s infinite ease-in-out;
`;

export const Cube2 = styled(Cube)`
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
`;
