import React, { useRef, createRef, useEffect, useCallback, Fragment } from 'react';

import {
    BorderWrapper,
    BuildingItem,
    RoomItem,
    RoomsWrapper,
    Wrapper,
    SensorSelectorAirWrapper,
} from 'styled/components/sensorSelectorAir';

import { KPICount, KPIOverview, SelectedSpace } from './types';

import KPIValue from './KPIValue';
import KPICountDisplay from './KPICountDisplay';

interface SensorSelectorAirProps {
    airSpaces: KPIOverview[];
    airSpacesCount: KPICount;
    metricType: string;
    selectedSpace: SelectedSpace;
    onSelectSpace: (selectedSpace: SelectedSpace) => void;
}
const SensorSelectorAir: React.FunctionComponent<SensorSelectorAirProps> = ({
    airSpaces,
    airSpacesCount,
    metricType,
    selectedSpace,
    onSelectSpace,
}) => {
    const elementRefs = useRef<Array<React.RefObject<HTMLDivElement>>>(
        airSpaces.map(() => createRef<HTMLDivElement>())
    );
    const spacesWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (airSpaces.length > 0 && elementRefs.current.length) {
            scrollSelectedItemInView(selectedSpace.building);
        }
    }, [selectedSpace, airSpaces]);

    const scrollSelectedItemInView = useCallback(
        (buildingId: string) => {
            const index = airSpaces.findIndex(space => space.id === buildingId);

            if (index !== -1) {
                const boundingRectWrapper = spacesWrapperRef.current?.getBoundingClientRect();
                const boundingRect = elementRefs.current[index]?.current?.getBoundingClientRect();

                if (
                    (boundingRect && boundingRectWrapper && boundingRect.bottom > boundingRectWrapper.bottom) ||
                    (boundingRect && boundingRectWrapper && boundingRect.top < boundingRectWrapper.top)
                ) {
                    elementRefs.current[index].current?.scrollIntoView();
                }
            }
        },
        [airSpaces, spacesWrapperRef]
    );

    return (
        <Wrapper ref={spacesWrapperRef}>
            {Boolean(airSpaces.length) && (
                <SensorSelectorAirWrapper>
                    {airSpaces
                        .filter(space => space.averageKpi)
                        .map((d, index) => (
                            <Fragment key={d.id}>
                                <BuildingItem
                                    isActive={selectedSpace.building === d.id && selectedSpace.highLevelRoom === ''}
                                    isSelected={selectedSpace.building === d.id && selectedSpace.highLevelRoom !== ''}
                                    ref={elementRefs.current[index]}
                                    onClick={() => {
                                        d.id === selectedSpace.building && selectedSpace.highLevelRoom === ''
                                            ? onSelectSpace({
                                                  building: '',
                                                  highLevelRoom: '',
                                                  midLevelRoom: '',
                                                  lowLevelRoom: '',
                                              })
                                            : onSelectSpace({
                                                  building: d.id,
                                                  highLevelRoom: '',
                                                  midLevelRoom: '',
                                                  lowLevelRoom: '',
                                              });
                                    }}
                                >
                                    <p>{d.name}</p>
                                    {d.averageKpi && (
                                        <KPIValue
                                            averageKpi={d.averageKpi}
                                            metricType={metricType}
                                            isActive={
                                                d.id === selectedSpace.building && selectedSpace.highLevelRoom === ''
                                            }
                                        />
                                    )}
                                </BuildingItem>
                                {selectedSpace.building === d.id && (
                                    <RoomsWrapper style={{ marginLeft: '15px' }}>
                                        {d.rooms
                                            .filter(room => room.averageKpi)
                                            .map((r, index) => (
                                                <Fragment key={r.id}>
                                                    <BorderWrapper zIndex={d.rooms.length - index + 40}>
                                                        <RoomItem
                                                            isActive={
                                                                selectedSpace.highLevelRoom === r.id &&
                                                                selectedSpace.midLevelRoom === ''
                                                            }
                                                            isSelected={
                                                                selectedSpace.highLevelRoom === r.id &&
                                                                selectedSpace.midLevelRoom !== ''
                                                            }
                                                            onClick={() => {
                                                                r.id === selectedSpace.highLevelRoom &&
                                                                selectedSpace.midLevelRoom === ''
                                                                    ? onSelectSpace({
                                                                          ...selectedSpace,
                                                                          highLevelRoom: '',
                                                                          midLevelRoom: '',
                                                                          lowLevelRoom: '',
                                                                      })
                                                                    : onSelectSpace({
                                                                          ...selectedSpace,
                                                                          highLevelRoom: r.id,
                                                                          midLevelRoom: '',
                                                                          lowLevelRoom: '',
                                                                      });
                                                            }}
                                                        >
                                                            <p>{r.name}</p>
                                                            {r.averageKpi && (
                                                                <KPIValue
                                                                    isActive={
                                                                        r.id === selectedSpace.highLevelRoom &&
                                                                        selectedSpace.midLevelRoom === ''
                                                                    }
                                                                    averageKpi={r.averageKpi}
                                                                    metricType={metricType}
                                                                />
                                                            )}
                                                        </RoomItem>
                                                    </BorderWrapper>
                                                    {selectedSpace.highLevelRoom === r.id &&
                                                        Boolean(r.rooms.length) && (
                                                            <RoomsWrapper style={{ marginLeft: '30px' }}>
                                                                {r.rooms
                                                                    .filter(room => room.averageKpi)
                                                                    .map((p, index) => (
                                                                        <Fragment key={p.id}>
                                                                            <BorderWrapper
                                                                                zIndex={r.rooms.length - index + 20}
                                                                            >
                                                                                <RoomItem
                                                                                    isActive={
                                                                                        selectedSpace.midLevelRoom ===
                                                                                            p.id &&
                                                                                        selectedSpace.lowLevelRoom ===
                                                                                            ''
                                                                                    }
                                                                                    isSelected={
                                                                                        selectedSpace.midLevelRoom ===
                                                                                            p.id &&
                                                                                        selectedSpace.lowLevelRoom !==
                                                                                            ''
                                                                                    }
                                                                                    onClick={() => {
                                                                                        p.id ===
                                                                                            selectedSpace.midLevelRoom &&
                                                                                        selectedSpace.lowLevelRoom ===
                                                                                            ''
                                                                                            ? onSelectSpace({
                                                                                                  ...selectedSpace,
                                                                                                  midLevelRoom: '',
                                                                                                  lowLevelRoom: '',
                                                                                              })
                                                                                            : onSelectSpace({
                                                                                                  ...selectedSpace,
                                                                                                  midLevelRoom: p.id,
                                                                                                  lowLevelRoom: '',
                                                                                              });
                                                                                    }}
                                                                                >
                                                                                    <p>{p.name}</p>
                                                                                    {p.averageKpi && (
                                                                                        <KPIValue
                                                                                            isActive={
                                                                                                p.id ===
                                                                                                    selectedSpace.midLevelRoom &&
                                                                                                selectedSpace.lowLevelRoom ===
                                                                                                    ''
                                                                                            }
                                                                                            averageKpi={p.averageKpi}
                                                                                            metricType={metricType}
                                                                                        />
                                                                                    )}
                                                                                </RoomItem>
                                                                            </BorderWrapper>
                                                                            {selectedSpace.midLevelRoom === p.id &&
                                                                                Boolean(p.rooms.length) && (
                                                                                    <RoomsWrapper
                                                                                        style={{ marginLeft: '45px' }}
                                                                                    >
                                                                                        {p.rooms
                                                                                            .filter(
                                                                                                room => room.averageKpi
                                                                                            )
                                                                                            .map((q, index) => (
                                                                                                <BorderWrapper
                                                                                                    key={q.id}
                                                                                                    zIndex={
                                                                                                        p.rooms.length -
                                                                                                        index
                                                                                                    }
                                                                                                >
                                                                                                    <RoomItem
                                                                                                        isActive={
                                                                                                            selectedSpace.lowLevelRoom ===
                                                                                                            q.id
                                                                                                        }
                                                                                                        isSelected={
                                                                                                            false
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                            onSelectSpace(
                                                                                                                {
                                                                                                                    ...selectedSpace,
                                                                                                                    lowLevelRoom:
                                                                                                                        q.id,
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <p>{q.name}</p>
                                                                                                        {q.averageKpi && (
                                                                                                            <KPIValue
                                                                                                                isActive={
                                                                                                                    q.id ===
                                                                                                                    selectedSpace.lowLevelRoom
                                                                                                                }
                                                                                                                averageKpi={
                                                                                                                    q.averageKpi
                                                                                                                }
                                                                                                                metricType={
                                                                                                                    metricType
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </RoomItem>
                                                                                                </BorderWrapper>
                                                                                            ))}
                                                                                    </RoomsWrapper>
                                                                                )}
                                                                        </Fragment>
                                                                    ))}
                                                            </RoomsWrapper>
                                                        )}
                                                </Fragment>
                                            ))}
                                    </RoomsWrapper>
                                )}
                            </Fragment>
                        ))}
                </SensorSelectorAirWrapper>
            )}
            {airSpacesCount && <KPICountDisplay count={airSpacesCount} />}
        </Wrapper>
    );
};

export default SensorSelectorAir;
