import styled from 'styled-components';

import { theme } from 'styled/Theme';
import { H3 } from './text';

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 100px;
`;

export const WrapperInteraction = styled.div`
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
`;

export const WrapperLegenda = styled.div`
    display: flex;
    justify-content: space-around;
`;

export const ContentContainer = styled.div`
    flex-direction: column;
    background-color: ${theme.colors.text};
    flex: 1;
    display: flex;
`;
export const AssistanceWrapper = styled.div`
    padding: 0px 10px 0px 20px;
    border-left: 1px solid ${theme.colors.greyD};
`;

export const StepSelectContainer = styled.div`
    display: flex;
    gap: 20px;
    min-height: 130px;
    padding: 20px 0px 0px 60px;
    background-color: ${theme.colors.text};
`;

export const EBSContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
`;

export const EBSReportWrapper = styled.div`
    display: grid;
    grid-template-columns: 240px 240px;
    row-gap: 24px;
    width: 480px;
`;

export const EBSReportTotalConsumptionWrapper = styled.div`
    display: grid;
    grid-template-columns: 240px 120px 480px;
    row-gap: 24px;
    width: 960px;
`;

export const EBSWizardContent = styled.div`
    flex: 1;
    background-color: ${theme.colors.text};
    padding: 30px 0px 30px 60px;
    border-bottom: 1px solid ${theme.colors.greyD};
`;

export const EBSBillableBar = styled.div`
    display: flex;
    flex: 1;
    min-height: 14px;
    max-height: 14px;
    background-color: ${theme.colors.ebsColor3};
    padding-right: 5px;
`;

export const EBSSelfSustainableBar = styled.div`
    display: flex;
    flex: 1;
    min-height: 14px;
    max-height: 14px;
    background-color: ${theme.colors.grey};
    padding-left: 5px;
`;

export const EBSGasConsumptionBar = styled.div`
    height: 14px;
    background-color: ${theme.colors.ebsColor5};
`;

export const EBSCaption = styled.div`
    height: 14px;
    color: ${theme.colors.ebsColor6};
    margin-right: 14px;
    line-height: 18px;
`;

export const ColoredH3 = styled(H3)`
    font-weight: normal;
    color: ${({ color }) => color};
    span {
        font-size: 14px;
        margin-left: 4px;
    }
`;

export const EBSKPI = styled.div`
    position: top;
    margin-left: 14px;
`;

export const BarCaption = styled.p`
    font-size: 10px;
    color: ${({ color }) => (color ? color : theme.colors.white)};
    margin: 0px;
    position: relative;
    top: 5px;
`;

export const ProgressBarWrapper = styled.div`
    min-width: 650px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    margin-left: auto;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

interface ProgressProps {
    width: number;
}
export const Progress = styled.div<ProgressProps>`
    height: 100%;
    width: ${props => props.width}%;
    background-color: ${theme.colors.mEnergyL};
    border-radius: 4px;
`;

export const ProgressWrapper = styled.div`
    height: 4px;
    width: 90%;
    background-color: ${theme.colors.greyD};
    border-radius: 4px;
`;

export const StepText = styled.p`
    color: ${theme.colors.ebsColor6};
    margin: 0 0 0 0;
    line-height: normal;
    font-size: 14px;
`;

export const StepsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`;

export const StyledLegendaItem = styled.button`
    display: flex;
    align-items: center;
    height: 30px;
    padding-inline: 10px;
    border: 1px solid ${({ onClick }) => (onClick ? theme.colors.greyD : 'transparent')};
    border-radius: 15px;
    background-color: ${theme.colors.greyD};
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

    &.selected {
        background-color: inherit;
    }

    div {
        width: 18px;
        margin-right: 5px;
        border: solid 2px ${props => props.color};
    }
`;

interface Bar {
    percentage: number;
}
export const EBSElectricityBarWrapper = styled.div<Bar>`
    min-width: 20px;
    max-width: 200px;
    width: ${({ percentage }) => percentage * 2}px;
    background-color: ${theme.colors.ebsColor3};
`;

export const EBSGasBarWrapper = styled.div<Bar>`
    min-width: 20px;
    max-width: 200px;
    width: ${({ percentage }) => percentage * 2}px;
    background-color: ${theme.colors.ebsColor5};
`;

export const StyledPreview = styled.div`
    background-color: ${theme.colors.text};
`;

export const PageBreak = styled.div`
    @media all {
        display: none;
    }

    @media print {
        html,
        body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
        }
    }

    @media print {
        margin-top: 1rem;
        display: block;
        break-after: page;
    }
`;

export const DisplayOnPrint = styled.div`
    display: none;

    @media print {
        padding: 30px;
        display: block;
    }
`;

export const HideOnPrint = styled.div`
    display: block;

    @media print {
        display: none;
    }
`;
