import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { BodyLeftWhite, BodyRight } from 'styled/components/text';
import { Wrapper } from 'styled/components/ebs';
import { theme } from 'styled/Theme';
import { getDateOfISOWeek } from '../../EBSWizard/ebs.helpers';
import { addDays, format } from 'date-fns';

interface Props {
    week: number;
}
const WeekHeader: React.FunctionComponent<Props> = ({ week }) => {
    const { t } = useTranslation();

    const formattedWeek = useMemo(() => formatWeek(week), [week]);

    return (
        <Wrapper>
            <BodyLeftWhite>{t('common.time.week')}</BodyLeftWhite>
            <BodyRight color={theme.colors.mEnergy}>{formattedWeek}</BodyRight>
        </Wrapper>
    );
};

export default WeekHeader;

const formatWeek = (week: number) => {
    const year = new Date().getFullYear() - 1;
    const startDate = getDateOfISOWeek(week, year);
    const endDate = addDays(startDate, 6);

    return `${week}: ${format(startDate, 'MMM d')} - ${endDate.getDate()}`;
};
