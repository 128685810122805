import { format, subWeeks } from 'date-fns';

import { DateRange, DateSpan, dateTimeFormat, isDaysTimespan } from 'utils/timePeriod';

import { SensorAverage, SensorAvgFilter, OccupancyData, OccupancyScore, OccupancyScoreAvg, Sensor } from 'types';
import { GraphData, SensorValue } from 'components/Graph/types';
import {
    mapAverageByHourOfDayOfWeek,
    createTickValues,
    calcAxisDates,
    mapSensorValue,
    getMax,
    getMin,
} from 'components/Graph/graph.helpers';
import { SpaceIndicator } from 'components/SpaceIndicator';
import { Hours, FromTo, isClosed } from 'components/WorkingHours';
import { DayOpenClosed, IsOpenClosed, WeekOpenClosed } from './types';

export const lastWeek = format(subWeeks(new Date(), 1), dateTimeFormat);

export const spaceUsage = (
    openingHours: WeekOpenClosed,
    occupancyScores: OccupancyScore[],
    sensors: Sensor[],
    spaceId: string
) => {
    const spaces: SpaceIndicator[] = [];
    let leastUsedSpaces: SpaceIndicator[] = [];
    let mostUsedSpaces: SpaceIndicator[] = [];

    occupancyScores.forEach(score => {
        if (score.data && score.data.length) {
            const motions: number[] = [];
            score.data.forEach(s => {
                const date = new Date(s.date);
                const day = date.getDay();
                const hour = date.getHours() === 0 ? 23 : date.getHours() - 1;

                openingHours[day][hour] === IsOpenClosed.OPEN && motions.push(s.occupied);
            });
            const totalMotions = motions.reduce((total, sd) => total + sd, 0);
            const avgMotions = motions.length ? totalMotions / motions.length : 0;

            const spaceName = sensors.find(i => i.roomRef === score.roomId)?.roomName || '';

            spaces.push({
                spaceName: spaceName,
                percentage: Math.ceil(avgMotions),
                id: score.roomId,
                isDisabled: spaceId === '' ? false : spaceId !== score.roomId,
            });
        }
    });

    spaces.sort((a, b) => {
        return b.percentage - a.percentage;
    });

    const averageUsage =
        spaces.reduce((previousValue, currentValue) => currentValue.percentage + previousValue, 0) / spaces.length;

    if (spaces.length) {
        mostUsedSpaces = spaces.slice(0, Math.ceil(spaces.length / 2));
        mostUsedSpaces = mostUsedSpaces.length > 12 ? mostUsedSpaces.slice(0, 12) : mostUsedSpaces;

        leastUsedSpaces = spaces.slice(Math.ceil(spaces.length / 2));
        leastUsedSpaces = leastUsedSpaces.length > 12 ? leastUsedSpaces.slice(-12) : leastUsedSpaces;
    }

    return { leastUsedSpaces, mostUsedSpaces, averageUsage };
};

export const sumAndAverageOccupancyData = (occupancyScores: OccupancyScore[]) => {
    let datalength = 0;

    const occupancyData: OccupancyData[] = [];
    occupancyScores.forEach(score => {
        if (score.data && score.data.length) {
            score.data.forEach((data, index) => {
                if (occupancyData[index]) {
                    occupancyData[index].occupied += data.occupied;
                } else {
                    occupancyData[index] = { occupied: data.occupied, date: data.date };
                }
            });
            datalength++;
        }
    });

    occupancyData.forEach(data => {
        data.occupied = data.occupied / datalength;
    });
    return occupancyData;
};

export const sumAndAverageSensorAverage = (occupancyAvgScores: OccupancyScoreAvg[]) => {
    let datalength = 0;

    const sensorAverage: SensorAverage[] = [];
    occupancyAvgScores.forEach(score => {
        if (score.data) {
            score.data.forEach((data, index) => {
                if (sensorAverage[index]) {
                    sensorAverage[index].value += data.value;
                } else {
                    sensorAverage[index] = { value: data.value, field: data.field };
                }
            });
            datalength++;
        }
    });

    sensorAverage.forEach(data => {
        data.value = data.value / datalength;
    });
    return sensorAverage;
};

export const createGraphData = (
    data: SensorAverage[],
    averages: SensorAverage[],
    timespan: string,
    action: string,
    customDate: DateRange,
    openingHours: WeekOpenClosed
) => {
    let sensorData = data.map(mapSensorValue);
    let averageSensorData = averages ? sensorData.map(d => mapAverageByHourOfDayOfWeek(d, averages)) : [];

    if (timespan === DateSpan.MONTH) {
        sensorData = averagePercentageValues(sensorData, openingHours);
        averageSensorData = averagePercentageValues(averageSensorData, openingHours);
    }

    const tickValues = createTickValues(action, timespan, customDate);
    const tickAmount = timespan === DateSpan.WEEK ? 1 : 2;
    const tickFormat = isDaysTimespan(timespan) ? 'HH:mm' : 'MMM dd';
    const max = getMax(sensorData);
    const min = getMin(sensorData);
    const axisDates = isDaysTimespan(timespan) ? calcAxisDates(tickValues) : undefined;

    const graphData: GraphData = {
        data: sensorData,
        averageData: averageSensorData,
        tickValues,
        tickAmount,
        tickFormat,
        max,
        min,
        axisDates,
        timespan,
    };

    return graphData;
};

export const getAvgFilter = (timeSpan: string) => {
    return isDaysTimespan(timeSpan) ? SensorAvgFilter.HOUR_OF_DAY_OF_WEEK : SensorAvgFilter.DAY_OF_WEEK;
};

export const getMappedOpeningHours = (openingHours: Hours) => {
    const openClosedHours: WeekOpenClosed = [
        [...closedDay],
        [...closedDay],
        [...closedDay],
        [...closedDay],
        [...closedDay],
        [...closedDay],
        [...closedDay],
    ];
    Object.keys(openingHours).forEach(k => {
        switch (k) {
            case 'sunday':
                openClosedHours[0] = mapOpeningHours(openingHours[k]);
                return;
            case 'monday':
                openClosedHours[1] = mapOpeningHours(openingHours[k]);
                return;
            case 'tuesday':
                openClosedHours[2] = mapOpeningHours(openingHours[k]);
                return;
            case 'wednesday':
                openClosedHours[3] = mapOpeningHours(openingHours[k]);
                return;
            case 'thursday':
                openClosedHours[4] = mapOpeningHours(openingHours[k]);
                return;
            case 'friday':
                openClosedHours[5] = mapOpeningHours(openingHours[k]);
                return;
            case 'saturday':
                openClosedHours[6] = mapOpeningHours(openingHours[k]);
                return;
            default:
                console.error('unknown day');
        }
    });
    return openClosedHours;
};

const mapOpeningHours = (openingHours: FromTo): DayOpenClosed => {
    return isClosed(openingHours) ? [...closedDay] : setOpeningHours(openingHours);
};

const setOpeningHours = (openingHours: FromTo): DayOpenClosed => {
    const hours: DayOpenClosed = [...closedDay];
    const startHourIndex = +openingHours.from.slice(0, -3);
    const endHourIndex = +openingHours.to.slice(0, -3);
    const openHours = new Array(endHourIndex - startHourIndex).fill(IsOpenClosed.OPEN);

    hours.splice(startHourIndex, openHours.length, ...openHours);

    return hours;
};

const closedDay: DayOpenClosed = [
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
    IsOpenClosed.CLOSED,
];

const averagePercentageValues = (sensorData: SensorValue[], openingHours: WeekOpenClosed) => {
    const timeFormat = 'yyyy-MM-dd';
    const clonedArray = sensorData.map(a => {
        return { ...a };
    });

    const sumIfAlreadyFound = (sensors: SensorValue[], sensor: SensorValue) => {
        const date = new Date(sensor.timestamp * 1000);
        const hour = date.getHours() === 0 ? 23 : date.getHours() - 1;
        const day = date.getDay();

        if (openingHours[day][hour] === IsOpenClosed.CLOSED) {
            return sensors;
        }

        const currentSensorTimestamp = format(date, timeFormat);
        const lastSensorTimestamp = sensors.length
            ? format(new Date(sensors[sensors.length - 1].timestamp * 1000), timeFormat)
            : '';
        if (currentSensorTimestamp === lastSensorTimestamp) {
            const lastSensorIndex = sensors.length - 1;
            sensors[lastSensorIndex].value = sensors[lastSensorIndex].value + sensor.value;
            return sensors;
        }
        return sensors.concat(sensor);
    };

    const summedSensorValues = clonedArray
        .reduce(sumIfAlreadyFound, [])
        .map(day => ({ value: calculateAverage(day, openingHours), timestamp: day.timestamp }));

    return summedSensorValues;
};

const calculateAverage = (sensor: SensorValue, openingHours: WeekOpenClosed) => {
    const date = new Date(sensor.timestamp * 1000);
    const day = date.getDay();
    const average =
        sensor.value && openingHours[day].filter(d => d === IsOpenClosed.OPEN).length
            ? sensor.value / openingHours[day].filter(d => d === IsOpenClosed.OPEN).length
            : 0;
    return average;
};
