import React from 'react';
import { useNavigate } from 'react-router';
import { routes, RouteType } from 'routing/routes';

import { useTranslation } from 'react-i18next';

import {
    CardIssue,
    CardItem,
    CardMiddle,
    CardTitle,
    Circle,
    DurationStart,
    IncidentCardIcon,
    Now,
    getTitle,
    getSubtitle,
    getThreshold,
} from 'styled/components/card';
import { H2White, LeadLeftSpan } from 'styled/components/text';
import { getIcon } from '../../styled/Theme';

import { Incident } from 'types';
import { formatDuration } from 'utils/formatDuration';
import { getDate } from './helpers';

interface CardProps {
    incident: Incident;
}
const Card: React.FunctionComponent<CardProps> = ({ incident }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { anomalySubType, equipsName, moduleType, pointId, timestamp, value, valueBefore } = incident;

    const goToIncident = () => {
        const routeToIncident = `${
            routes[moduleType.toLowerCase() as RouteType].issues
        }?id=${pointId}&timestamp=${timestamp}`;

        navigate(routeToIncident);
    };

    const date = getDate(timestamp);

    return (
        <CardItem
            value={value}
            valueBefore={valueBefore}
            anomolyType={anomalySubType}
            module={moduleType}
            onClick={goToIncident}
        >
            <CardTitle module={moduleType}>
                <Circle>
                    <IncidentCardIcon module={moduleType} src={getIcon(moduleType)} />
                </Circle>
                {getTitle(anomalySubType, value, valueBefore)}
            </CardTitle>
            <Now>{date === t('common.time.today') ? t('common.time.now') : ''}</Now>
            <CardMiddle>
                <H2White>{getThreshold(incident)}</H2White>
                <CardIssue>
                    {getSubtitle(anomalySubType, value, valueBefore)}
                    <br />
                    in <LeadLeftSpan>{equipsName[equipsName.length - 1]}</LeadLeftSpan>
                </CardIssue>
            </CardMiddle>
            <div />
            <DurationStart>
                {formatDuration(incident.duration)} • {date}
            </DurationStart>
        </CardItem>
    );
};

export default Card;
