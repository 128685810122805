import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

import Footer from 'components/Footer';
import MainWrapper from 'components/Container/MainWrapper';
import ProfileSubMenu from 'components/Submenu/ProfileSubMenu';
import TextInput from 'components/TextInput';
import TextArea from 'components/TextArea';

import { SubmitButton } from 'styled/components/buttons';
import { CaptionLefPositive, H3Bold, H3White, LeadLeft } from 'styled/components/text';
import { ProfileContentWrapper } from 'styled/components/view';
import { StyledSelect } from 'styled/components/inputs';
import { ProfileNameWrapper, ProfileFullName, ProfileNameCircleSmall } from 'styled/components/profile';
import {
    DividerBorder,
    SupportInfoWrapper,
    SupportIconWrapper,
    SupportTextwrapper,
    SupportCaptionLeftNegative,
    SupportIcon,
    SupportIconButtonWrapper,
} from 'styled/components/support';

import copyIcon from '../../../assets/icons/icons-copy.svg';
import envelopeIcon from '../../../assets/icons/icons-envelope.svg';
import phoneIcon from '../../../assets/icons/icons-phone.svg';

import { config } from 'config';
import { SupportType } from './types';

import { useFeedbackMutation } from 'hooks/api/mutate/useFeedbackMutation';

const supportTypes = [
    {
        label: i18n.t('support.type.question'),
        value: SupportType.QUESTION,
    },
    {
        label: i18n.t('support.type.moduleActivation'),
        value: SupportType.MODULEACTIVATION,
    },
    {
        label: i18n.t('support.type.bug'),
        value: SupportType.BUG,
    },
    {
        label: i18n.t('support.type.demoRequest'),
        value: SupportType.DEMOREQUEST,
    },
];

const SupportView: React.FunctionComponent = () => {
    const { mutate, isLoading } = useFeedbackMutation();
    const { t } = useTranslation();

    const [title, setHeader] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState(SupportType.QUESTION);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSuccessMessage('');

        const payload = {
            client_id: config.oauth.clientId,
            client_secret: config.oauth.clientSecret,
            type: type,
            header: title,
            message: message,
        };
        mutate(payload, {
            onSuccess: () => {
                setHeader('');
                setMessage('');
                setType(SupportType.QUESTION);
                setErrorMessage('');
                setSuccessMessage(t('support.success'));
                window.dataLayer.push({
                    event: 'form_submission',
                    type: 'feedback',
                });
            },
            onError(error, variables) {
                if (variables.header === '' || variables.message === '') {
                    setErrorMessage(t('support.missingFieldsError'));
                } else {
                    setErrorMessage(t('support.success'));
                }
            },
        });
    };

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setType(e.target.value as SupportType);
    };

    return (
        <>
            <ProfileSubMenu item={t('navigation.sideMenu.itemSupport')} />

            <MainWrapper>
                <ProfileContentWrapper>
                    <ProfileNameWrapper>
                        <ProfileNameCircleSmall>
                            <H3White>CM</H3White>
                        </ProfileNameCircleSmall>
                        <ProfileFullName>
                            <H3Bold>Contract Manager</H3Bold>
                        </ProfileFullName>
                    </ProfileNameWrapper>
                    <br />
                    <SupportInfoWrapper>
                        <SupportIconWrapper>
                            <SupportIcon src={phoneIcon} />
                        </SupportIconWrapper>
                        <SupportTextwrapper>{config.profile.phone}</SupportTextwrapper>
                        <SupportIconButtonWrapper>
                            <SupportIcon
                                src={copyIcon}
                                onClick={() => {
                                    navigator.clipboard.writeText(config.profile.phone);
                                }}
                            />
                        </SupportIconButtonWrapper>
                    </SupportInfoWrapper>
                    <SupportInfoWrapper>
                        <SupportIconWrapper>
                            <SupportIcon src={envelopeIcon} />
                        </SupportIconWrapper>
                        <SupportTextwrapper>{config.profile.mail}</SupportTextwrapper>
                        <SupportIconButtonWrapper>
                            <SupportIcon
                                src={copyIcon}
                                onClick={() => {
                                    navigator.clipboard.writeText(config.profile.mail);
                                }}
                            />
                        </SupportIconButtonWrapper>
                    </SupportInfoWrapper>
                </ProfileContentWrapper>

                <DividerBorder />

                <ProfileContentWrapper>
                    <LeadLeft>{t('support.feedback')}</LeadLeft>
                    <br />
                    <form onSubmit={handleSubmit}>
                        <StyledSelect>
                            <select value={type} onChange={handleChange}>
                                {supportTypes.map(option => (
                                    <option key={option.value} value={option.value}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </StyledSelect>
                        <TextInput
                            label={t('support.title')}
                            input={title}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setHeader(e.target.value);
                            }}
                        />
                        <TextArea
                            label={t('support.message')}
                            input={message}
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                setMessage(e.target.value);
                            }}
                        />
                        {errorMessage ? <SupportCaptionLeftNegative>{errorMessage}</SupportCaptionLeftNegative> : null}
                        {successMessage ? <CaptionLefPositive>{successMessage}</CaptionLefPositive> : null}
                        <br />
                        <SubmitButton disabled={isLoading} type={'submit'}>
                            {t('support.send')}
                        </SubmitButton>
                    </form>
                </ProfileContentWrapper>
            </MainWrapper>

            <Footer />
        </>
    );
};

export default SupportView;
