import React from 'react';
import { BrowserRouter as Router, Navigate, Routes, Route } from 'react-router-dom';

import { routes } from './routes';

// Views
import LoginView from 'views/unAuthenticated/LoginView';
import ResetPasswordView from 'views/unAuthenticated/resetPassword/ResetPasswordView';
import ResetLinkView from 'views/unAuthenticated/resetPassword/ResetLinkView';

export default function UnAuthenticatedRoutes(): JSX.Element {
    return (
        <Router>
            <Routes>
                <Route path={routes.index} element={<Navigate to={routes.login} />} />
                <Route path={routes.login} element={<LoginView />} />
                <Route path={routes.password_reset} element={<ResetPasswordView />} />
                <Route path={routes.password_link} element={<ResetLinkView />} />
                <Route path="*" element={<Navigate to={routes.login} />} />
            </Routes>
        </Router>
    );
}
