import React, { useEffect, useReducer, useRef } from 'react';

import { useReactToPrint } from 'react-to-print';

import IntroductionView from './IntroductionView';
import FacilityView from './Facility';
import ElectricityView from './ElectricityView';
import GasView from './GasView';
import InteractionView from './Interaction/InteractionView';
import ProgressHeader from './ProgressHeader/ProgressHeader';
import StepSelector from './StepSelector';
import Preview from './Preview';
import LoadingOverlay from 'components/LoadingOverlay/LoadingOverlay';

import { ContentContainer, EBSContainer } from 'styled/components/ebs';

import { Space } from 'views/authenticated/types';
import { Sensor } from 'types';
import { ComparisonData, WizardAction, WizardState, WizardStep } from './types';

import { useEBSData } from 'hooks/api/useEBSData';
import { useBuildingOccupancyData } from 'hooks/api/useBuildingOccupancyData';

import { getMappedOpeningHours, spaceUsage } from 'components/DataPane/statistics/occupancy/occupancyStatistics.helper';
import { summedValue } from 'components/DataPane/statistics/energy/energyStatistics.helper';
import { createElecVsTempGraphData, lastYear } from './ebs.helpers';
import { useTenant } from 'context/TenantContext';
import { getHoursByBuilding } from 'components/WorkingHours';

const startDate = new Date(lastYear, 0, 1, 0, 0, 1).toISOString();
const endDate = new Date(lastYear, 11, 31, 23, 59, 59).toISOString();

interface Props {
    building: Space;
    consumptionSensors: Sensor[];
    gasSensors: Sensor[];
    productionSensors: Sensor[];
    feedinSensors: Sensor[];
    closeWizard: () => void;
}

const EBSWizard: React.FunctionComponent<Props> = ({
    building,
    consumptionSensors,
    gasSensors,
    productionSensors,
    feedinSensors,
    closeWizard,
}) => {
    const { openingHours } = useTenant();
    const componentRef = useRef<HTMLDivElement>(null);
    const [state, dispatch] = useReducer(wizardReducer, initialState);
    const {
        bagData,
        comparisonDataLastYear,
        comparisonDataTwoYearsBack,
        explanatoryText,
        interactionGraph,
        electricityData,
        gasData,
        loading,
        loadingOccupancy,
        occupancyRate,
        step,
    } = state;

    const { data } = useEBSData(building, consumptionSensors, gasSensors, productionSensors, feedinSensors);
    const { data: occupancyData } = useBuildingOccupancyData(building.id, startDate, endDate);

    useEffect(() => {
        if (data) {
            dispatch({
                type: 'SET_DATA',
                selectedOpeningHours: getHoursByBuilding(building.name, openingHours),
                bag: data.bag,
                weather: data.weather,
                electricity: data.electricity,
                electricityHistoric: data.electricityHistoric,
                gas: data.gas,
                gasHistoric: data.gasHistoric,
                production: data.production,
                productionHistoric: data.productionHistoric,
                feedin: data.feedin,
                feedinHistoric: data.feedinHistoric,
            });
        }
    }, [data]);

    useEffect(() => {
        if (occupancyData && data) {
            dispatch({
                type: 'SET_DATA_OCCUPANCY',
                occupancyData,
            });
        }
    }, [occupancyData, data]);

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `EBS Report ${building.name} ${lastYear}`,
        removeAfterPrint: true,
    });

    return (
        <EBSContainer>
            <ProgressHeader step={step} year={lastYear} onCloseWizard={() => closeWizard()} />

            <>
                <ContentContainer>
                    {step === WizardStep.INTRODUCTION && (
                        <IntroductionView
                            explanatoryText={explanatoryText}
                            building={building}
                            onChangeText={e => dispatch({ type: 'SET_TEXT', text: e })}
                        />
                    )}
                    {loading && step !== WizardStep.INTRODUCTION ? (
                        <LoadingOverlay dark={true} />
                    ) : (
                        <>
                            {step === WizardStep.FACILITY && (
                                <FacilityView
                                    building={building}
                                    bagData={bagData}
                                    comparisonDataLastYear={comparisonDataLastYear}
                                    comparisonDataTwoYearsBack={comparisonDataTwoYearsBack}
                                    occupancyLoading={loadingOccupancy}
                                    occupancyRate={occupancyRate}
                                />
                            )}
                            {step === WizardStep.ELECTRICITY && (
                                <ElectricityView
                                    explanatoryText={explanatoryText}
                                    consumptionData={electricityData}
                                    onChangeText={e => dispatch({ type: 'SET_TEXT', text: e })}
                                />
                            )}
                            {step === WizardStep.GAS && (
                                <GasView
                                    explanatoryText={explanatoryText}
                                    consumptionData={gasData}
                                    onChangeText={e => dispatch({ type: 'SET_TEXT', text: e })}
                                />
                            )}
                            {step === WizardStep.INTERACTION && (
                                <InteractionView
                                    explanatoryText={explanatoryText}
                                    elecTempGasGraph={interactionGraph}
                                    onChangeText={e => dispatch({ type: 'SET_TEXT', text: e })}
                                />
                            )}
                            {step === WizardStep.PREVIEW && (
                                <Preview
                                    ref={componentRef}
                                    building={building}
                                    bagData={bagData}
                                    comparisonDataLastYear={comparisonDataLastYear}
                                    comparisonDataTwoYearsBack={comparisonDataTwoYearsBack}
                                    explanatoryText={explanatoryText}
                                    electricityData={electricityData}
                                    gasData={gasData}
                                    interactionGraph={interactionGraph}
                                    occupancyRate={occupancyRate}
                                    onChangeText={e => dispatch({ type: 'SET_TEXT', text: e })}
                                />
                            )}
                        </>
                    )}
                </ContentContainer>

                {loading && step !== WizardStep.INTRODUCTION ? null : (
                    <StepSelector
                        step={step}
                        onSelectStep={step => dispatch({ type: 'SELECT_STEP', step })}
                        onHandlePrint={() => handlePrint()}
                    />
                )}
            </>
        </EBSContainer>
    );
};

export default EBSWizard;

const initialState: WizardState = {
    step: WizardStep.INTRODUCTION,
    selectedOpeningHours: null,
    interactionGraph: null,
    bagData: null,
    occupancyRate: 0,
    comparisonDataLastYear: null,
    comparisonDataTwoYearsBack: null,
    electricityData: [],
    gasData: [],
    loading: true,
    loadingOccupancy: true,
    explanatoryText: {
        introduction: '',
        electricityWinter: '',
        electricitySpring: '',
        electricitySummer: '',
        electricityAutumn: '',
        electricityHolidays: '',
        gasWinter: '',
        gasSpring: '',
        gasSummer: '',
        gasAutumn: '',
        gasHolidays: '',
        interactionUsage: '',
        interactionComparison: '',
    },
};

const wizardReducer = (state: WizardState, action: WizardAction): WizardState => {
    switch (action.type) {
        case 'SELECT_STEP':
            window.scrollTo({ top: 121, behavior: 'smooth' });

            return {
                ...state,
                step: action.step,
            };
        case 'SET_DATA': {
            const comparisonDataLastYear: ComparisonData = {
                electricityConsumption: summedValue(action.electricity),
                electricityProduction: summedValue(action.production),
                electricityFeedin: summedValue(action.feedin),
                gasConsumption: summedValue(action.gas),
            };

            const comparisonDataTwoYearsBack: ComparisonData = {
                electricityConsumption: summedValue(action.electricityHistoric),
                electricityProduction: summedValue(action.productionHistoric),
                electricityFeedin: summedValue(action.feedinHistoric),
                gasConsumption: summedValue(action.gasHistoric),
            };

            const interactionGraph = createElecVsTempGraphData(
                action.selectedOpeningHours,
                action.weather,
                action.electricity,
                action.gas
            );
            return {
                ...state,
                interactionGraph,
                selectedOpeningHours: action.selectedOpeningHours,
                bagData: action.bag,
                electricityData: action.electricity,
                gasData: action.gas,
                comparisonDataLastYear,
                comparisonDataTwoYearsBack,
                loading: false,
            };
        }
        case 'SET_DATA_OCCUPANCY': {
            const openingHours = getMappedOpeningHours(state.selectedOpeningHours);
            const { averageUsage } = spaceUsage(openingHours, action.occupancyData, [], '');

            return {
                ...state,
                occupancyRate: +averageUsage.toFixed(),
                loadingOccupancy: false,
            };
        }
        case 'SET_TEXT': {
            return {
                ...state,
                explanatoryText: { ...state.explanatoryText, [action.text.target.name]: action.text.target.value },
            };
        }
    }
};
